import type { ApolloClient, NormalizedCacheObject } from '@apollo/client';
import type {
  MkLocationSuggestion,
  QueryMkLocationSuggestionsArgs,
  CompanyDetailsCompanyReviewLocationsArgs,
  QueryCompanyDetailsArgs,
  CompanyReviewLocation,
} from '@seek/ca-graphql-schema/types';

import type { AppConfig } from '../../config';
import {
  LOCATION_SEARCH_QUERY,
  COMPANY_REVIEW_LOCATIONS_QUERY,
} from './graphqlQueries';

export type LocationSuggestion = Partial<MkLocationSuggestion>;

export type LocationService = ReturnType<typeof createLocationService>;
type CountriesType = QueryMkLocationSuggestionsArgs['countries'];

export const createLocationService = ({
  client,
  config,
}: {
  client: ApolloClient<NormalizedCacheObject>;
  config: AppConfig;
}) => {
  const getLocations = async (
    query: string,
    sessionId: string,
  ): Promise<LocationSuggestion[]> => {
    const LOCATIONS_TO_SUGGEST = 10;

    // Different location kinds for different zones
    const locationKindDefault = ['Suburb', 'SEEKLocation'];
    const locationKindForJobsDB = ['Suburb', 'SEEKArea'];

    const response = await client.query<
      { mkLocationSuggestions: MkLocationSuggestion[] },
      QueryMkLocationSuggestionsArgs
    >({
      query: LOCATION_SEARCH_QUERY,
      variables: {
        searchText: query,
        session: sessionId,
        count: LOCATIONS_TO_SUGGEST,
        kind:
          config.zone === 'asia-1' || config.zone === 'asia-3'
            ? locationKindForJobsDB
            : locationKindDefault,
        zone: config.zone,
        languageCode: config.language,
        countries: [config.country.toUpperCase()] as CountriesType,
      },
    });

    return response.data?.mkLocationSuggestions ?? [];
  };

  const getCompanyReviewLocations = async (variables: {
    companyId: string;
    sort?: boolean;
  }): Promise<CompanyReviewLocation[] | undefined> => {
    const response = await client.query<
      {
        companyDetails: {
          companyReviewLocations: CompanyReviewLocation[] | null;
        };
      },
      CompanyDetailsCompanyReviewLocationsArgs & {
        companyDetailsId: QueryCompanyDetailsArgs['id'];
      }
    >({
      query: COMPANY_REVIEW_LOCATIONS_QUERY,
      variables: {
        sort: variables.sort as CompanyDetailsCompanyReviewLocationsArgs['sort'],
        zone: config.zone,
        locale: config.locale,
        companyDetailsId: variables.companyId,
      },
    });

    return response.data?.companyDetails?.companyReviewLocations ?? undefined;
  };

  return { getLocations, getCompanyReviewLocations };
};
