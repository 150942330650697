
      import { createLanguage, createTranslationFile } from '@vocab/webpack/web';
      
    const translations = createTranslationFile({
      "en": createLanguage(
        require.resolveWeak("./translations.json-en-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJGdWxsIHRpbWUiOiJGdWxsIHRpbWUiLCJQYXJ0IHRpbWUiOiJQYXJ0IHRpbWUiLCJDb250cmFjdCI6IkNvbnRyYWN0IiwiQ2FzdWFsIjoiQ2FzdWFsIiwiVGhlIGNoYWxsZW5nZXMiOiJUaGUgY2hhbGxlbmdlcyIsIkRlc2NyaWJlIHdoYXQgeW91IGRvbid0IGxpa2UiOiJEZXNjcmliZSB3aGF0IHlvdSBkb24ndCBsaWtlIiwiVGhlIGdvb2QgdGhpbmdzIjoiVGhlIGdvb2QgdGhpbmdzIiwiRGVzY3JpYmUgd2hhdCB5b3UgbGlrZSB0aGUgbW9zdCI6IkRlc2NyaWJlIHdoYXQgeW91IGxpa2UgdGhlIG1vc3QiLCJTdW1tYXJpc2UgeW91ciBleHBlcmllbmNlIGluIG9uZSBzZW50ZW5jZSI6IlN1bW1hcmlzZSB5b3VyIGV4cGVyaWVuY2UgaW4gb25lIHNlbnRlbmNlIiwiVGhpcyB3aWxsIGJlIHVzZWQgYXMgdGhlIHRpdGxlIG9mIHlvdXIgcmV2aWV3IjoiVGhpcyB3aWxsIGJlIHVzZWQgYXMgdGhlIHRpdGxlIG9mIHlvdXIgcmV2aWV3IiwiV29yayB0eXBlIjoiV29yayB0eXBlIn0%3D!"),
        () => import(
          /* webpackChunkName: "en-translations" */
          "./translations.json-en-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJGdWxsIHRpbWUiOiJGdWxsIHRpbWUiLCJQYXJ0IHRpbWUiOiJQYXJ0IHRpbWUiLCJDb250cmFjdCI6IkNvbnRyYWN0IiwiQ2FzdWFsIjoiQ2FzdWFsIiwiVGhlIGNoYWxsZW5nZXMiOiJUaGUgY2hhbGxlbmdlcyIsIkRlc2NyaWJlIHdoYXQgeW91IGRvbid0IGxpa2UiOiJEZXNjcmliZSB3aGF0IHlvdSBkb24ndCBsaWtlIiwiVGhlIGdvb2QgdGhpbmdzIjoiVGhlIGdvb2QgdGhpbmdzIiwiRGVzY3JpYmUgd2hhdCB5b3UgbGlrZSB0aGUgbW9zdCI6IkRlc2NyaWJlIHdoYXQgeW91IGxpa2UgdGhlIG1vc3QiLCJTdW1tYXJpc2UgeW91ciBleHBlcmllbmNlIGluIG9uZSBzZW50ZW5jZSI6IlN1bW1hcmlzZSB5b3VyIGV4cGVyaWVuY2UgaW4gb25lIHNlbnRlbmNlIiwiVGhpcyB3aWxsIGJlIHVzZWQgYXMgdGhlIHRpdGxlIG9mIHlvdXIgcmV2aWV3IjoiVGhpcyB3aWxsIGJlIHVzZWQgYXMgdGhlIHRpdGxlIG9mIHlvdXIgcmV2aWV3IiwiV29yayB0eXBlIjoiV29yayB0eXBlIn0%3D!"
        )
      ),
"th": createLanguage(
        require.resolveWeak("./translations.json-th-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJGdWxsIHRpbWUiOiLguJfguLPguIfguLLguJnguYDguJXguYfguKHguYDguKfguKXguLIiLCJQYXJ0IHRpbWUiOiLguJfguLPguIfguLLguJnguJ7guLLguKPguYzguJfguYTguJfguKHguYwiLCJDb250cmFjdCI6IuC4h%2BC4suC4meC4quC4seC4jeC4jeC4suC4iOC5ieC4suC4hyIsIkNhc3VhbCI6IuC4h%2BC4suC4meC4iOC5ieC4suC4h%2BC4iuC4seC5iOC4p%2BC4hOC4o%2BC4suC4pyIsIlRoZSBjaGFsbGVuZ2VzIjoi4LiE4Lin4Liy4Lih4LiX4LmJ4Liy4LiX4Liy4LiiIiwiRGVzY3JpYmUgd2hhdCB5b3UgZG9uJ3QgbGlrZSI6IuC4reC4mOC4tOC4muC4suC4ouC5gOC4geC4teC5iOC4ouC4p%2BC4geC4seC4muC4quC4tOC5iOC4h%2BC4l%2BC4teC5iOC4hOC4uOC4k%2BC5hOC4oeC5iOC4iuC4reC4miIsIlRoZSBnb29kIHRoaW5ncyI6IuC4reC4sOC5hOC4o%2BC4hOC4t%2BC4reC4quC4tOC5iOC4h%2BC4l%2BC4teC5iOC4hOC4uOC4k%2BC4iuC4reC4miIsIkRlc2NyaWJlIHdoYXQgeW91IGxpa2UgdGhlIG1vc3QiOiLguK3guJjguLTguJrguLLguKLguYDguIHguLXguYjguKLguKfguIHguLHguJrguKrguLTguYjguIfguJfguLXguYjguITguLjguJPguIrguK3guJrguKHguLLguIHguJfguLXguYjguKrguLjguJQiLCJTdW1tYXJpc2UgeW91ciBleHBlcmllbmNlIGluIG9uZSBzZW50ZW5jZSI6IuC4quC4o%2BC4uOC4m%2BC4m%2BC4o%2BC4sOC4quC4muC4geC4suC4o%2BC4k%2BC5jOC4geC4suC4o%2BC4l%2BC4s%2BC4h%2BC4suC4meC4l%2BC4teC5iOC4meC4teC5iOC5g%2BC4meC4m%2BC4o%2BC4sOC5guC4ouC4hOC5gOC4lOC4teC4ouC4pyIsIlRoaXMgd2lsbCBiZSB1c2VkIGFzIHRoZSB0aXRsZSBvZiB5b3VyIHJldmlldyI6IuC4guC5ieC4reC4hOC4p%2BC4suC4oeC4meC4teC5ieC4iOC4sOC5g%2BC4iuC5ieC5gOC4m%2BC5h%2BC4meC4iuC4t%2BC5iOC4reC4q%2BC4seC4p%2BC4guC5ieC4reC4geC4suC4o%2BC4o%2BC4teC4p%2BC4tOC4p%2BC4guC4reC4h%2BC4hOC4uOC4kyIsIldvcmsgdHlwZSI6IuC4m%2BC4o%2BC4sOC5gOC4oOC4l%2BC4h%2BC4suC4mSJ9!"),
        () => import(
          /* webpackChunkName: "th-translations" */
          "./translations.json-th-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJGdWxsIHRpbWUiOiLguJfguLPguIfguLLguJnguYDguJXguYfguKHguYDguKfguKXguLIiLCJQYXJ0IHRpbWUiOiLguJfguLPguIfguLLguJnguJ7guLLguKPguYzguJfguYTguJfguKHguYwiLCJDb250cmFjdCI6IuC4h%2BC4suC4meC4quC4seC4jeC4jeC4suC4iOC5ieC4suC4hyIsIkNhc3VhbCI6IuC4h%2BC4suC4meC4iOC5ieC4suC4h%2BC4iuC4seC5iOC4p%2BC4hOC4o%2BC4suC4pyIsIlRoZSBjaGFsbGVuZ2VzIjoi4LiE4Lin4Liy4Lih4LiX4LmJ4Liy4LiX4Liy4LiiIiwiRGVzY3JpYmUgd2hhdCB5b3UgZG9uJ3QgbGlrZSI6IuC4reC4mOC4tOC4muC4suC4ouC5gOC4geC4teC5iOC4ouC4p%2BC4geC4seC4muC4quC4tOC5iOC4h%2BC4l%2BC4teC5iOC4hOC4uOC4k%2BC5hOC4oeC5iOC4iuC4reC4miIsIlRoZSBnb29kIHRoaW5ncyI6IuC4reC4sOC5hOC4o%2BC4hOC4t%2BC4reC4quC4tOC5iOC4h%2BC4l%2BC4teC5iOC4hOC4uOC4k%2BC4iuC4reC4miIsIkRlc2NyaWJlIHdoYXQgeW91IGxpa2UgdGhlIG1vc3QiOiLguK3guJjguLTguJrguLLguKLguYDguIHguLXguYjguKLguKfguIHguLHguJrguKrguLTguYjguIfguJfguLXguYjguITguLjguJPguIrguK3guJrguKHguLLguIHguJfguLXguYjguKrguLjguJQiLCJTdW1tYXJpc2UgeW91ciBleHBlcmllbmNlIGluIG9uZSBzZW50ZW5jZSI6IuC4quC4o%2BC4uOC4m%2BC4m%2BC4o%2BC4sOC4quC4muC4geC4suC4o%2BC4k%2BC5jOC4geC4suC4o%2BC4l%2BC4s%2BC4h%2BC4suC4meC4l%2BC4teC5iOC4meC4teC5iOC5g%2BC4meC4m%2BC4o%2BC4sOC5guC4ouC4hOC5gOC4lOC4teC4ouC4pyIsIlRoaXMgd2lsbCBiZSB1c2VkIGFzIHRoZSB0aXRsZSBvZiB5b3VyIHJldmlldyI6IuC4guC5ieC4reC4hOC4p%2BC4suC4oeC4meC4teC5ieC4iOC4sOC5g%2BC4iuC5ieC5gOC4m%2BC5h%2BC4meC4iuC4t%2BC5iOC4reC4q%2BC4seC4p%2BC4guC5ieC4reC4geC4suC4o%2BC4o%2BC4teC4p%2BC4tOC4p%2BC4guC4reC4h%2BC4hOC4uOC4kyIsIldvcmsgdHlwZSI6IuC4m%2BC4o%2BC4sOC5gOC4oOC4l%2BC4h%2BC4suC4mSJ9!"
        )
      ),
"id": createLanguage(
        require.resolveWeak("./translations.json-id-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJGdWxsIHRpbWUiOiJQdXJuYXdha3R1IiwiUGFydCB0aW1lIjoiUGFydWggd2FrdHUiLCJDb250cmFjdCI6IktvbnRyYWsiLCJDYXN1YWwiOiJQZWtlcmphIGxlcGFzIiwiVGhlIGNoYWxsZW5nZXMiOiJUYW50YW5nYW4geWFuZyBkaWhhZGFwaSIsIkRlc2NyaWJlIHdoYXQgeW91IGRvbid0IGxpa2UiOiJKZWxhc2thbiBhcGEgeWFuZyBrdXJhbmcgQW5kYSBzdWthaSIsIlRoZSBnb29kIHRoaW5ncyI6IkhhbCB5YW5nIGJhaWsiLCJEZXNjcmliZSB3aGF0IHlvdSBsaWtlIHRoZSBtb3N0IjoiQ2VyaXRha2FuIGFwYSB5YW5nIHBhbGluZyBBbmRhIHN1a2FpIiwiU3VtbWFyaXNlIHlvdXIgZXhwZXJpZW5jZSBpbiBvbmUgc2VudGVuY2UiOiJSYW5na3VtIHBlbmdhbGFtYW4gZGFsYW0gc2F0dSBrYWxpbWF0IiwiVGhpcyB3aWxsIGJlIHVzZWQgYXMgdGhlIHRpdGxlIG9mIHlvdXIgcmV2aWV3IjoiQWthbiBkaWd1bmFrYW4gc2ViYWdhaSBqdWR1bCB1bGFzYW4gaW5pIiwiV29yayB0eXBlIjoiSmVuaXMgcGVrZXJqYWFuIn0%3D!"),
        () => import(
          /* webpackChunkName: "id-translations" */
          "./translations.json-id-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJGdWxsIHRpbWUiOiJQdXJuYXdha3R1IiwiUGFydCB0aW1lIjoiUGFydWggd2FrdHUiLCJDb250cmFjdCI6IktvbnRyYWsiLCJDYXN1YWwiOiJQZWtlcmphIGxlcGFzIiwiVGhlIGNoYWxsZW5nZXMiOiJUYW50YW5nYW4geWFuZyBkaWhhZGFwaSIsIkRlc2NyaWJlIHdoYXQgeW91IGRvbid0IGxpa2UiOiJKZWxhc2thbiBhcGEgeWFuZyBrdXJhbmcgQW5kYSBzdWthaSIsIlRoZSBnb29kIHRoaW5ncyI6IkhhbCB5YW5nIGJhaWsiLCJEZXNjcmliZSB3aGF0IHlvdSBsaWtlIHRoZSBtb3N0IjoiQ2VyaXRha2FuIGFwYSB5YW5nIHBhbGluZyBBbmRhIHN1a2FpIiwiU3VtbWFyaXNlIHlvdXIgZXhwZXJpZW5jZSBpbiBvbmUgc2VudGVuY2UiOiJSYW5na3VtIHBlbmdhbGFtYW4gZGFsYW0gc2F0dSBrYWxpbWF0IiwiVGhpcyB3aWxsIGJlIHVzZWQgYXMgdGhlIHRpdGxlIG9mIHlvdXIgcmV2aWV3IjoiQWthbiBkaWd1bmFrYW4gc2ViYWdhaSBqdWR1bCB1bGFzYW4gaW5pIiwiV29yayB0eXBlIjoiSmVuaXMgcGVrZXJqYWFuIn0%3D!"
        )
      ),
"zh": createLanguage(
        require.resolveWeak("./translations.json-zh-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJGdWxsIHRpbWUiOiJGdWxsIHRpbWUiLCJQYXJ0IHRpbWUiOiJQYXJ0IHRpbWUiLCJDb250cmFjdCI6IkNvbnRyYWN0IiwiQ2FzdWFsIjoiQ2FzdWFsIiwiVGhlIGNoYWxsZW5nZXMiOiJUaGUgY2hhbGxlbmdlcyIsIkRlc2NyaWJlIHdoYXQgeW91IGRvbid0IGxpa2UiOiJEZXNjcmliZSB3aGF0IHlvdSBkb24ndCBsaWtlIiwiVGhlIGdvb2QgdGhpbmdzIjoiVGhlIGdvb2QgdGhpbmdzIiwiRGVzY3JpYmUgd2hhdCB5b3UgbGlrZSB0aGUgbW9zdCI6IkRlc2NyaWJlIHdoYXQgeW91IGxpa2UgdGhlIG1vc3QiLCJTdW1tYXJpc2UgeW91ciBleHBlcmllbmNlIGluIG9uZSBzZW50ZW5jZSI6IlN1bW1hcmlzZSB5b3VyIGV4cGVyaWVuY2UgaW4gb25lIHNlbnRlbmNlIiwiVGhpcyB3aWxsIGJlIHVzZWQgYXMgdGhlIHRpdGxlIG9mIHlvdXIgcmV2aWV3IjoiVGhpcyB3aWxsIGJlIHVzZWQgYXMgdGhlIHRpdGxlIG9mIHlvdXIgcmV2aWV3IiwiV29yayB0eXBlIjoiV29yayB0eXBlIn0%3D!"),
        () => import(
          /* webpackChunkName: "zh-translations" */
          "./translations.json-zh-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJGdWxsIHRpbWUiOiJGdWxsIHRpbWUiLCJQYXJ0IHRpbWUiOiJQYXJ0IHRpbWUiLCJDb250cmFjdCI6IkNvbnRyYWN0IiwiQ2FzdWFsIjoiQ2FzdWFsIiwiVGhlIGNoYWxsZW5nZXMiOiJUaGUgY2hhbGxlbmdlcyIsIkRlc2NyaWJlIHdoYXQgeW91IGRvbid0IGxpa2UiOiJEZXNjcmliZSB3aGF0IHlvdSBkb24ndCBsaWtlIiwiVGhlIGdvb2QgdGhpbmdzIjoiVGhlIGdvb2QgdGhpbmdzIiwiRGVzY3JpYmUgd2hhdCB5b3UgbGlrZSB0aGUgbW9zdCI6IkRlc2NyaWJlIHdoYXQgeW91IGxpa2UgdGhlIG1vc3QiLCJTdW1tYXJpc2UgeW91ciBleHBlcmllbmNlIGluIG9uZSBzZW50ZW5jZSI6IlN1bW1hcmlzZSB5b3VyIGV4cGVyaWVuY2UgaW4gb25lIHNlbnRlbmNlIiwiVGhpcyB3aWxsIGJlIHVzZWQgYXMgdGhlIHRpdGxlIG9mIHlvdXIgcmV2aWV3IjoiVGhpcyB3aWxsIGJlIHVzZWQgYXMgdGhlIHRpdGxlIG9mIHlvdXIgcmV2aWV3IiwiV29yayB0eXBlIjoiV29yayB0eXBlIn0%3D!"
        )
      ),
"en-AU": createLanguage(
        require.resolveWeak("./translations.json-en-AU-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJGdWxsIHRpbWUiOiJGdWxsIHRpbWUiLCJQYXJ0IHRpbWUiOiJQYXJ0IHRpbWUiLCJDb250cmFjdCI6IkNvbnRyYWN0IiwiQ2FzdWFsIjoiQ2FzdWFsIiwiVGhlIGNoYWxsZW5nZXMiOiJUaGUgY2hhbGxlbmdlcyIsIkRlc2NyaWJlIHdoYXQgeW91IGRvbid0IGxpa2UiOiJEZXNjcmliZSB3aGF0IHlvdSBkb24ndCBsaWtlIiwiVGhlIGdvb2QgdGhpbmdzIjoiVGhlIGdvb2QgdGhpbmdzIiwiRGVzY3JpYmUgd2hhdCB5b3UgbGlrZSB0aGUgbW9zdCI6IkRlc2NyaWJlIHdoYXQgeW91IGxpa2UgdGhlIG1vc3QiLCJTdW1tYXJpc2UgeW91ciBleHBlcmllbmNlIGluIG9uZSBzZW50ZW5jZSI6IlN1bW1hcmlzZSB5b3VyIGV4cGVyaWVuY2UgaW4gb25lIHNlbnRlbmNlIiwiVGhpcyB3aWxsIGJlIHVzZWQgYXMgdGhlIHRpdGxlIG9mIHlvdXIgcmV2aWV3IjoiVGhpcyB3aWxsIGJlIHVzZWQgYXMgdGhlIHRpdGxlIG9mIHlvdXIgcmV2aWV3IiwiV29yayB0eXBlIjoiV29yayB0eXBlIn0%3D!"),
        () => import(
          /* webpackChunkName: "en-AU-translations" */
          "./translations.json-en-AU-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJGdWxsIHRpbWUiOiJGdWxsIHRpbWUiLCJQYXJ0IHRpbWUiOiJQYXJ0IHRpbWUiLCJDb250cmFjdCI6IkNvbnRyYWN0IiwiQ2FzdWFsIjoiQ2FzdWFsIiwiVGhlIGNoYWxsZW5nZXMiOiJUaGUgY2hhbGxlbmdlcyIsIkRlc2NyaWJlIHdoYXQgeW91IGRvbid0IGxpa2UiOiJEZXNjcmliZSB3aGF0IHlvdSBkb24ndCBsaWtlIiwiVGhlIGdvb2QgdGhpbmdzIjoiVGhlIGdvb2QgdGhpbmdzIiwiRGVzY3JpYmUgd2hhdCB5b3UgbGlrZSB0aGUgbW9zdCI6IkRlc2NyaWJlIHdoYXQgeW91IGxpa2UgdGhlIG1vc3QiLCJTdW1tYXJpc2UgeW91ciBleHBlcmllbmNlIGluIG9uZSBzZW50ZW5jZSI6IlN1bW1hcmlzZSB5b3VyIGV4cGVyaWVuY2UgaW4gb25lIHNlbnRlbmNlIiwiVGhpcyB3aWxsIGJlIHVzZWQgYXMgdGhlIHRpdGxlIG9mIHlvdXIgcmV2aWV3IjoiVGhpcyB3aWxsIGJlIHVzZWQgYXMgdGhlIHRpdGxlIG9mIHlvdXIgcmV2aWV3IiwiV29yayB0eXBlIjoiV29yayB0eXBlIn0%3D!"
        )
      ),
"en-HK": createLanguage(
        require.resolveWeak("./translations.json-en-HK-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJGdWxsIHRpbWUiOiJGdWxsIHRpbWUiLCJQYXJ0IHRpbWUiOiJQYXJ0IHRpbWUiLCJDb250cmFjdCI6IkNvbnRyYWN0IiwiQ2FzdWFsIjoiQ2FzdWFsIiwiVGhlIGNoYWxsZW5nZXMiOiJUaGUgY2hhbGxlbmdlcyIsIkRlc2NyaWJlIHdoYXQgeW91IGRvbid0IGxpa2UiOiJEZXNjcmliZSB3aGF0IHlvdSBkb24ndCBsaWtlIiwiVGhlIGdvb2QgdGhpbmdzIjoiVGhlIGdvb2QgdGhpbmdzIiwiRGVzY3JpYmUgd2hhdCB5b3UgbGlrZSB0aGUgbW9zdCI6IkRlc2NyaWJlIHdoYXQgeW91IGxpa2UgdGhlIG1vc3QiLCJTdW1tYXJpc2UgeW91ciBleHBlcmllbmNlIGluIG9uZSBzZW50ZW5jZSI6IlN1bW1hcmlzZSB5b3VyIGV4cGVyaWVuY2UgaW4gb25lIHNlbnRlbmNlIiwiVGhpcyB3aWxsIGJlIHVzZWQgYXMgdGhlIHRpdGxlIG9mIHlvdXIgcmV2aWV3IjoiVGhpcyB3aWxsIGJlIHVzZWQgYXMgdGhlIHRpdGxlIG9mIHlvdXIgcmV2aWV3IiwiV29yayB0eXBlIjoiV29yayB0eXBlIn0%3D!"),
        () => import(
          /* webpackChunkName: "en-HK-translations" */
          "./translations.json-en-HK-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJGdWxsIHRpbWUiOiJGdWxsIHRpbWUiLCJQYXJ0IHRpbWUiOiJQYXJ0IHRpbWUiLCJDb250cmFjdCI6IkNvbnRyYWN0IiwiQ2FzdWFsIjoiQ2FzdWFsIiwiVGhlIGNoYWxsZW5nZXMiOiJUaGUgY2hhbGxlbmdlcyIsIkRlc2NyaWJlIHdoYXQgeW91IGRvbid0IGxpa2UiOiJEZXNjcmliZSB3aGF0IHlvdSBkb24ndCBsaWtlIiwiVGhlIGdvb2QgdGhpbmdzIjoiVGhlIGdvb2QgdGhpbmdzIiwiRGVzY3JpYmUgd2hhdCB5b3UgbGlrZSB0aGUgbW9zdCI6IkRlc2NyaWJlIHdoYXQgeW91IGxpa2UgdGhlIG1vc3QiLCJTdW1tYXJpc2UgeW91ciBleHBlcmllbmNlIGluIG9uZSBzZW50ZW5jZSI6IlN1bW1hcmlzZSB5b3VyIGV4cGVyaWVuY2UgaW4gb25lIHNlbnRlbmNlIiwiVGhpcyB3aWxsIGJlIHVzZWQgYXMgdGhlIHRpdGxlIG9mIHlvdXIgcmV2aWV3IjoiVGhpcyB3aWxsIGJlIHVzZWQgYXMgdGhlIHRpdGxlIG9mIHlvdXIgcmV2aWV3IiwiV29yayB0eXBlIjoiV29yayB0eXBlIn0%3D!"
        )
      ),
"en-ID": createLanguage(
        require.resolveWeak("./translations.json-en-ID-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJGdWxsIHRpbWUiOiJGdWxsIHRpbWUiLCJQYXJ0IHRpbWUiOiJQYXJ0IHRpbWUiLCJDb250cmFjdCI6IkNvbnRyYWN0IiwiQ2FzdWFsIjoiQ2FzdWFsIiwiVGhlIGNoYWxsZW5nZXMiOiJUaGUgY2hhbGxlbmdlcyIsIkRlc2NyaWJlIHdoYXQgeW91IGRvbid0IGxpa2UiOiJEZXNjcmliZSB3aGF0IHlvdSBkb24ndCBsaWtlIiwiVGhlIGdvb2QgdGhpbmdzIjoiVGhlIGdvb2QgdGhpbmdzIiwiRGVzY3JpYmUgd2hhdCB5b3UgbGlrZSB0aGUgbW9zdCI6IkRlc2NyaWJlIHdoYXQgeW91IGxpa2UgdGhlIG1vc3QiLCJTdW1tYXJpc2UgeW91ciBleHBlcmllbmNlIGluIG9uZSBzZW50ZW5jZSI6IlN1bW1hcmlzZSB5b3VyIGV4cGVyaWVuY2UgaW4gb25lIHNlbnRlbmNlIiwiVGhpcyB3aWxsIGJlIHVzZWQgYXMgdGhlIHRpdGxlIG9mIHlvdXIgcmV2aWV3IjoiVGhpcyB3aWxsIGJlIHVzZWQgYXMgdGhlIHRpdGxlIG9mIHlvdXIgcmV2aWV3IiwiV29yayB0eXBlIjoiV29yayB0eXBlIn0%3D!"),
        () => import(
          /* webpackChunkName: "en-ID-translations" */
          "./translations.json-en-ID-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJGdWxsIHRpbWUiOiJGdWxsIHRpbWUiLCJQYXJ0IHRpbWUiOiJQYXJ0IHRpbWUiLCJDb250cmFjdCI6IkNvbnRyYWN0IiwiQ2FzdWFsIjoiQ2FzdWFsIiwiVGhlIGNoYWxsZW5nZXMiOiJUaGUgY2hhbGxlbmdlcyIsIkRlc2NyaWJlIHdoYXQgeW91IGRvbid0IGxpa2UiOiJEZXNjcmliZSB3aGF0IHlvdSBkb24ndCBsaWtlIiwiVGhlIGdvb2QgdGhpbmdzIjoiVGhlIGdvb2QgdGhpbmdzIiwiRGVzY3JpYmUgd2hhdCB5b3UgbGlrZSB0aGUgbW9zdCI6IkRlc2NyaWJlIHdoYXQgeW91IGxpa2UgdGhlIG1vc3QiLCJTdW1tYXJpc2UgeW91ciBleHBlcmllbmNlIGluIG9uZSBzZW50ZW5jZSI6IlN1bW1hcmlzZSB5b3VyIGV4cGVyaWVuY2UgaW4gb25lIHNlbnRlbmNlIiwiVGhpcyB3aWxsIGJlIHVzZWQgYXMgdGhlIHRpdGxlIG9mIHlvdXIgcmV2aWV3IjoiVGhpcyB3aWxsIGJlIHVzZWQgYXMgdGhlIHRpdGxlIG9mIHlvdXIgcmV2aWV3IiwiV29yayB0eXBlIjoiV29yayB0eXBlIn0%3D!"
        )
      ),
"en-MY": createLanguage(
        require.resolveWeak("./translations.json-en-MY-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJGdWxsIHRpbWUiOiJGdWxsIHRpbWUiLCJQYXJ0IHRpbWUiOiJQYXJ0IHRpbWUiLCJDb250cmFjdCI6IkNvbnRyYWN0IiwiQ2FzdWFsIjoiQ2FzdWFsIiwiVGhlIGNoYWxsZW5nZXMiOiJUaGUgY2hhbGxlbmdlcyIsIkRlc2NyaWJlIHdoYXQgeW91IGRvbid0IGxpa2UiOiJEZXNjcmliZSB3aGF0IHlvdSBkb24ndCBsaWtlIiwiVGhlIGdvb2QgdGhpbmdzIjoiVGhlIGdvb2QgdGhpbmdzIiwiRGVzY3JpYmUgd2hhdCB5b3UgbGlrZSB0aGUgbW9zdCI6IkRlc2NyaWJlIHdoYXQgeW91IGxpa2UgdGhlIG1vc3QiLCJTdW1tYXJpc2UgeW91ciBleHBlcmllbmNlIGluIG9uZSBzZW50ZW5jZSI6IlN1bW1hcmlzZSB5b3VyIGV4cGVyaWVuY2UgaW4gb25lIHNlbnRlbmNlIiwiVGhpcyB3aWxsIGJlIHVzZWQgYXMgdGhlIHRpdGxlIG9mIHlvdXIgcmV2aWV3IjoiVGhpcyB3aWxsIGJlIHVzZWQgYXMgdGhlIHRpdGxlIG9mIHlvdXIgcmV2aWV3IiwiV29yayB0eXBlIjoiV29yayB0eXBlIn0%3D!"),
        () => import(
          /* webpackChunkName: "en-MY-translations" */
          "./translations.json-en-MY-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJGdWxsIHRpbWUiOiJGdWxsIHRpbWUiLCJQYXJ0IHRpbWUiOiJQYXJ0IHRpbWUiLCJDb250cmFjdCI6IkNvbnRyYWN0IiwiQ2FzdWFsIjoiQ2FzdWFsIiwiVGhlIGNoYWxsZW5nZXMiOiJUaGUgY2hhbGxlbmdlcyIsIkRlc2NyaWJlIHdoYXQgeW91IGRvbid0IGxpa2UiOiJEZXNjcmliZSB3aGF0IHlvdSBkb24ndCBsaWtlIiwiVGhlIGdvb2QgdGhpbmdzIjoiVGhlIGdvb2QgdGhpbmdzIiwiRGVzY3JpYmUgd2hhdCB5b3UgbGlrZSB0aGUgbW9zdCI6IkRlc2NyaWJlIHdoYXQgeW91IGxpa2UgdGhlIG1vc3QiLCJTdW1tYXJpc2UgeW91ciBleHBlcmllbmNlIGluIG9uZSBzZW50ZW5jZSI6IlN1bW1hcmlzZSB5b3VyIGV4cGVyaWVuY2UgaW4gb25lIHNlbnRlbmNlIiwiVGhpcyB3aWxsIGJlIHVzZWQgYXMgdGhlIHRpdGxlIG9mIHlvdXIgcmV2aWV3IjoiVGhpcyB3aWxsIGJlIHVzZWQgYXMgdGhlIHRpdGxlIG9mIHlvdXIgcmV2aWV3IiwiV29yayB0eXBlIjoiV29yayB0eXBlIn0%3D!"
        )
      ),
"en-NZ": createLanguage(
        require.resolveWeak("./translations.json-en-NZ-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJGdWxsIHRpbWUiOiJGdWxsIHRpbWUiLCJQYXJ0IHRpbWUiOiJQYXJ0IHRpbWUiLCJDb250cmFjdCI6IkNvbnRyYWN0IiwiQ2FzdWFsIjoiQ2FzdWFsIiwiVGhlIGNoYWxsZW5nZXMiOiJUaGUgY2hhbGxlbmdlcyIsIkRlc2NyaWJlIHdoYXQgeW91IGRvbid0IGxpa2UiOiJEZXNjcmliZSB3aGF0IHlvdSBkb24ndCBsaWtlIiwiVGhlIGdvb2QgdGhpbmdzIjoiVGhlIGdvb2QgdGhpbmdzIiwiRGVzY3JpYmUgd2hhdCB5b3UgbGlrZSB0aGUgbW9zdCI6IkRlc2NyaWJlIHdoYXQgeW91IGxpa2UgdGhlIG1vc3QiLCJTdW1tYXJpc2UgeW91ciBleHBlcmllbmNlIGluIG9uZSBzZW50ZW5jZSI6IlN1bW1hcmlzZSB5b3VyIGV4cGVyaWVuY2UgaW4gb25lIHNlbnRlbmNlIiwiVGhpcyB3aWxsIGJlIHVzZWQgYXMgdGhlIHRpdGxlIG9mIHlvdXIgcmV2aWV3IjoiVGhpcyB3aWxsIGJlIHVzZWQgYXMgdGhlIHRpdGxlIG9mIHlvdXIgcmV2aWV3IiwiV29yayB0eXBlIjoiV29yayB0eXBlIn0%3D!"),
        () => import(
          /* webpackChunkName: "en-NZ-translations" */
          "./translations.json-en-NZ-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJGdWxsIHRpbWUiOiJGdWxsIHRpbWUiLCJQYXJ0IHRpbWUiOiJQYXJ0IHRpbWUiLCJDb250cmFjdCI6IkNvbnRyYWN0IiwiQ2FzdWFsIjoiQ2FzdWFsIiwiVGhlIGNoYWxsZW5nZXMiOiJUaGUgY2hhbGxlbmdlcyIsIkRlc2NyaWJlIHdoYXQgeW91IGRvbid0IGxpa2UiOiJEZXNjcmliZSB3aGF0IHlvdSBkb24ndCBsaWtlIiwiVGhlIGdvb2QgdGhpbmdzIjoiVGhlIGdvb2QgdGhpbmdzIiwiRGVzY3JpYmUgd2hhdCB5b3UgbGlrZSB0aGUgbW9zdCI6IkRlc2NyaWJlIHdoYXQgeW91IGxpa2UgdGhlIG1vc3QiLCJTdW1tYXJpc2UgeW91ciBleHBlcmllbmNlIGluIG9uZSBzZW50ZW5jZSI6IlN1bW1hcmlzZSB5b3VyIGV4cGVyaWVuY2UgaW4gb25lIHNlbnRlbmNlIiwiVGhpcyB3aWxsIGJlIHVzZWQgYXMgdGhlIHRpdGxlIG9mIHlvdXIgcmV2aWV3IjoiVGhpcyB3aWxsIGJlIHVzZWQgYXMgdGhlIHRpdGxlIG9mIHlvdXIgcmV2aWV3IiwiV29yayB0eXBlIjoiV29yayB0eXBlIn0%3D!"
        )
      ),
"en-PH": createLanguage(
        require.resolveWeak("./translations.json-en-PH-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJGdWxsIHRpbWUiOiJGdWxsIHRpbWUiLCJQYXJ0IHRpbWUiOiJQYXJ0IHRpbWUiLCJDb250cmFjdCI6IkNvbnRyYWN0IiwiQ2FzdWFsIjoiQ2FzdWFsIiwiVGhlIGNoYWxsZW5nZXMiOiJUaGUgY2hhbGxlbmdlcyIsIkRlc2NyaWJlIHdoYXQgeW91IGRvbid0IGxpa2UiOiJEZXNjcmliZSB3aGF0IHlvdSBkb24ndCBsaWtlIiwiVGhlIGdvb2QgdGhpbmdzIjoiVGhlIGdvb2QgdGhpbmdzIiwiRGVzY3JpYmUgd2hhdCB5b3UgbGlrZSB0aGUgbW9zdCI6IkRlc2NyaWJlIHdoYXQgeW91IGxpa2UgdGhlIG1vc3QiLCJTdW1tYXJpc2UgeW91ciBleHBlcmllbmNlIGluIG9uZSBzZW50ZW5jZSI6IlN1bW1hcmlzZSB5b3VyIGV4cGVyaWVuY2UgaW4gb25lIHNlbnRlbmNlIiwiVGhpcyB3aWxsIGJlIHVzZWQgYXMgdGhlIHRpdGxlIG9mIHlvdXIgcmV2aWV3IjoiVGhpcyB3aWxsIGJlIHVzZWQgYXMgdGhlIHRpdGxlIG9mIHlvdXIgcmV2aWV3IiwiV29yayB0eXBlIjoiV29yayB0eXBlIn0%3D!"),
        () => import(
          /* webpackChunkName: "en-PH-translations" */
          "./translations.json-en-PH-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJGdWxsIHRpbWUiOiJGdWxsIHRpbWUiLCJQYXJ0IHRpbWUiOiJQYXJ0IHRpbWUiLCJDb250cmFjdCI6IkNvbnRyYWN0IiwiQ2FzdWFsIjoiQ2FzdWFsIiwiVGhlIGNoYWxsZW5nZXMiOiJUaGUgY2hhbGxlbmdlcyIsIkRlc2NyaWJlIHdoYXQgeW91IGRvbid0IGxpa2UiOiJEZXNjcmliZSB3aGF0IHlvdSBkb24ndCBsaWtlIiwiVGhlIGdvb2QgdGhpbmdzIjoiVGhlIGdvb2QgdGhpbmdzIiwiRGVzY3JpYmUgd2hhdCB5b3UgbGlrZSB0aGUgbW9zdCI6IkRlc2NyaWJlIHdoYXQgeW91IGxpa2UgdGhlIG1vc3QiLCJTdW1tYXJpc2UgeW91ciBleHBlcmllbmNlIGluIG9uZSBzZW50ZW5jZSI6IlN1bW1hcmlzZSB5b3VyIGV4cGVyaWVuY2UgaW4gb25lIHNlbnRlbmNlIiwiVGhpcyB3aWxsIGJlIHVzZWQgYXMgdGhlIHRpdGxlIG9mIHlvdXIgcmV2aWV3IjoiVGhpcyB3aWxsIGJlIHVzZWQgYXMgdGhlIHRpdGxlIG9mIHlvdXIgcmV2aWV3IiwiV29yayB0eXBlIjoiV29yayB0eXBlIn0%3D!"
        )
      ),
"en-SG": createLanguage(
        require.resolveWeak("./translations.json-en-SG-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJGdWxsIHRpbWUiOiJGdWxsIHRpbWUiLCJQYXJ0IHRpbWUiOiJQYXJ0IHRpbWUiLCJDb250cmFjdCI6IkNvbnRyYWN0IiwiQ2FzdWFsIjoiQ2FzdWFsIiwiVGhlIGNoYWxsZW5nZXMiOiJUaGUgY2hhbGxlbmdlcyIsIkRlc2NyaWJlIHdoYXQgeW91IGRvbid0IGxpa2UiOiJEZXNjcmliZSB3aGF0IHlvdSBkb24ndCBsaWtlIiwiVGhlIGdvb2QgdGhpbmdzIjoiVGhlIGdvb2QgdGhpbmdzIiwiRGVzY3JpYmUgd2hhdCB5b3UgbGlrZSB0aGUgbW9zdCI6IkRlc2NyaWJlIHdoYXQgeW91IGxpa2UgdGhlIG1vc3QiLCJTdW1tYXJpc2UgeW91ciBleHBlcmllbmNlIGluIG9uZSBzZW50ZW5jZSI6IlN1bW1hcmlzZSB5b3VyIGV4cGVyaWVuY2UgaW4gb25lIHNlbnRlbmNlIiwiVGhpcyB3aWxsIGJlIHVzZWQgYXMgdGhlIHRpdGxlIG9mIHlvdXIgcmV2aWV3IjoiVGhpcyB3aWxsIGJlIHVzZWQgYXMgdGhlIHRpdGxlIG9mIHlvdXIgcmV2aWV3IiwiV29yayB0eXBlIjoiV29yayB0eXBlIn0%3D!"),
        () => import(
          /* webpackChunkName: "en-SG-translations" */
          "./translations.json-en-SG-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJGdWxsIHRpbWUiOiJGdWxsIHRpbWUiLCJQYXJ0IHRpbWUiOiJQYXJ0IHRpbWUiLCJDb250cmFjdCI6IkNvbnRyYWN0IiwiQ2FzdWFsIjoiQ2FzdWFsIiwiVGhlIGNoYWxsZW5nZXMiOiJUaGUgY2hhbGxlbmdlcyIsIkRlc2NyaWJlIHdoYXQgeW91IGRvbid0IGxpa2UiOiJEZXNjcmliZSB3aGF0IHlvdSBkb24ndCBsaWtlIiwiVGhlIGdvb2QgdGhpbmdzIjoiVGhlIGdvb2QgdGhpbmdzIiwiRGVzY3JpYmUgd2hhdCB5b3UgbGlrZSB0aGUgbW9zdCI6IkRlc2NyaWJlIHdoYXQgeW91IGxpa2UgdGhlIG1vc3QiLCJTdW1tYXJpc2UgeW91ciBleHBlcmllbmNlIGluIG9uZSBzZW50ZW5jZSI6IlN1bW1hcmlzZSB5b3VyIGV4cGVyaWVuY2UgaW4gb25lIHNlbnRlbmNlIiwiVGhpcyB3aWxsIGJlIHVzZWQgYXMgdGhlIHRpdGxlIG9mIHlvdXIgcmV2aWV3IjoiVGhpcyB3aWxsIGJlIHVzZWQgYXMgdGhlIHRpdGxlIG9mIHlvdXIgcmV2aWV3IiwiV29yayB0eXBlIjoiV29yayB0eXBlIn0%3D!"
        )
      ),
"en-TH": createLanguage(
        require.resolveWeak("./translations.json-en-TH-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJGdWxsIHRpbWUiOiJGdWxsIHRpbWUiLCJQYXJ0IHRpbWUiOiJQYXJ0IHRpbWUiLCJDb250cmFjdCI6IkNvbnRyYWN0IiwiQ2FzdWFsIjoiQ2FzdWFsIiwiVGhlIGNoYWxsZW5nZXMiOiJUaGUgY2hhbGxlbmdlcyIsIkRlc2NyaWJlIHdoYXQgeW91IGRvbid0IGxpa2UiOiJEZXNjcmliZSB3aGF0IHlvdSBkb24ndCBsaWtlIiwiVGhlIGdvb2QgdGhpbmdzIjoiVGhlIGdvb2QgdGhpbmdzIiwiRGVzY3JpYmUgd2hhdCB5b3UgbGlrZSB0aGUgbW9zdCI6IkRlc2NyaWJlIHdoYXQgeW91IGxpa2UgdGhlIG1vc3QiLCJTdW1tYXJpc2UgeW91ciBleHBlcmllbmNlIGluIG9uZSBzZW50ZW5jZSI6IlN1bW1hcmlzZSB5b3VyIGV4cGVyaWVuY2UgaW4gb25lIHNlbnRlbmNlIiwiVGhpcyB3aWxsIGJlIHVzZWQgYXMgdGhlIHRpdGxlIG9mIHlvdXIgcmV2aWV3IjoiVGhpcyB3aWxsIGJlIHVzZWQgYXMgdGhlIHRpdGxlIG9mIHlvdXIgcmV2aWV3IiwiV29yayB0eXBlIjoiV29yayB0eXBlIn0%3D!"),
        () => import(
          /* webpackChunkName: "en-TH-translations" */
          "./translations.json-en-TH-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJGdWxsIHRpbWUiOiJGdWxsIHRpbWUiLCJQYXJ0IHRpbWUiOiJQYXJ0IHRpbWUiLCJDb250cmFjdCI6IkNvbnRyYWN0IiwiQ2FzdWFsIjoiQ2FzdWFsIiwiVGhlIGNoYWxsZW5nZXMiOiJUaGUgY2hhbGxlbmdlcyIsIkRlc2NyaWJlIHdoYXQgeW91IGRvbid0IGxpa2UiOiJEZXNjcmliZSB3aGF0IHlvdSBkb24ndCBsaWtlIiwiVGhlIGdvb2QgdGhpbmdzIjoiVGhlIGdvb2QgdGhpbmdzIiwiRGVzY3JpYmUgd2hhdCB5b3UgbGlrZSB0aGUgbW9zdCI6IkRlc2NyaWJlIHdoYXQgeW91IGxpa2UgdGhlIG1vc3QiLCJTdW1tYXJpc2UgeW91ciBleHBlcmllbmNlIGluIG9uZSBzZW50ZW5jZSI6IlN1bW1hcmlzZSB5b3VyIGV4cGVyaWVuY2UgaW4gb25lIHNlbnRlbmNlIiwiVGhpcyB3aWxsIGJlIHVzZWQgYXMgdGhlIHRpdGxlIG9mIHlvdXIgcmV2aWV3IjoiVGhpcyB3aWxsIGJlIHVzZWQgYXMgdGhlIHRpdGxlIG9mIHlvdXIgcmV2aWV3IiwiV29yayB0eXBlIjoiV29yayB0eXBlIn0%3D!"
        )
      ),
"th-TH": createLanguage(
        require.resolveWeak("./translations.json-th-TH-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJGdWxsIHRpbWUiOiLguJfguLPguIfguLLguJnguYDguJXguYfguKHguYDguKfguKXguLIiLCJQYXJ0IHRpbWUiOiLguJfguLPguIfguLLguJnguJ7guLLguKPguYzguJfguYTguJfguKHguYwiLCJDb250cmFjdCI6IuC4h%2BC4suC4meC4quC4seC4jeC4jeC4suC4iOC5ieC4suC4hyIsIkNhc3VhbCI6IuC4h%2BC4suC4meC4iOC5ieC4suC4h%2BC4iuC4seC5iOC4p%2BC4hOC4o%2BC4suC4pyIsIlRoZSBjaGFsbGVuZ2VzIjoi4LiE4Lin4Liy4Lih4LiX4LmJ4Liy4LiX4Liy4LiiIiwiRGVzY3JpYmUgd2hhdCB5b3UgZG9uJ3QgbGlrZSI6IuC4reC4mOC4tOC4muC4suC4ouC5gOC4geC4teC5iOC4ouC4p%2BC4geC4seC4muC4quC4tOC5iOC4h%2BC4l%2BC4teC5iOC4hOC4uOC4k%2BC5hOC4oeC5iOC4iuC4reC4miIsIlRoZSBnb29kIHRoaW5ncyI6IuC4reC4sOC5hOC4o%2BC4hOC4t%2BC4reC4quC4tOC5iOC4h%2BC4l%2BC4teC5iOC4hOC4uOC4k%2BC4iuC4reC4miIsIkRlc2NyaWJlIHdoYXQgeW91IGxpa2UgdGhlIG1vc3QiOiLguK3guJjguLTguJrguLLguKLguYDguIHguLXguYjguKLguKfguIHguLHguJrguKrguLTguYjguIfguJfguLXguYjguITguLjguJPguIrguK3guJrguKHguLLguIHguJfguLXguYjguKrguLjguJQiLCJTdW1tYXJpc2UgeW91ciBleHBlcmllbmNlIGluIG9uZSBzZW50ZW5jZSI6IuC4quC4o%2BC4uOC4m%2BC4m%2BC4o%2BC4sOC4quC4muC4geC4suC4o%2BC4k%2BC5jOC4geC4suC4o%2BC4l%2BC4s%2BC4h%2BC4suC4meC4l%2BC4teC5iOC4meC4teC5iOC5g%2BC4meC4m%2BC4o%2BC4sOC5guC4ouC4hOC5gOC4lOC4teC4ouC4pyIsIlRoaXMgd2lsbCBiZSB1c2VkIGFzIHRoZSB0aXRsZSBvZiB5b3VyIHJldmlldyI6IuC4guC5ieC4reC4hOC4p%2BC4suC4oeC4meC4teC5ieC4iOC4sOC5g%2BC4iuC5ieC5gOC4m%2BC5h%2BC4meC4iuC4t%2BC5iOC4reC4q%2BC4seC4p%2BC4guC5ieC4reC4geC4suC4o%2BC4o%2BC4teC4p%2BC4tOC4p%2BC4guC4reC4h%2BC4hOC4uOC4kyIsIldvcmsgdHlwZSI6IuC4m%2BC4o%2BC4sOC5gOC4oOC4l%2BC4h%2BC4suC4mSJ9!"),
        () => import(
          /* webpackChunkName: "th-TH-translations" */
          "./translations.json-th-TH-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJGdWxsIHRpbWUiOiLguJfguLPguIfguLLguJnguYDguJXguYfguKHguYDguKfguKXguLIiLCJQYXJ0IHRpbWUiOiLguJfguLPguIfguLLguJnguJ7guLLguKPguYzguJfguYTguJfguKHguYwiLCJDb250cmFjdCI6IuC4h%2BC4suC4meC4quC4seC4jeC4jeC4suC4iOC5ieC4suC4hyIsIkNhc3VhbCI6IuC4h%2BC4suC4meC4iOC5ieC4suC4h%2BC4iuC4seC5iOC4p%2BC4hOC4o%2BC4suC4pyIsIlRoZSBjaGFsbGVuZ2VzIjoi4LiE4Lin4Liy4Lih4LiX4LmJ4Liy4LiX4Liy4LiiIiwiRGVzY3JpYmUgd2hhdCB5b3UgZG9uJ3QgbGlrZSI6IuC4reC4mOC4tOC4muC4suC4ouC5gOC4geC4teC5iOC4ouC4p%2BC4geC4seC4muC4quC4tOC5iOC4h%2BC4l%2BC4teC5iOC4hOC4uOC4k%2BC5hOC4oeC5iOC4iuC4reC4miIsIlRoZSBnb29kIHRoaW5ncyI6IuC4reC4sOC5hOC4o%2BC4hOC4t%2BC4reC4quC4tOC5iOC4h%2BC4l%2BC4teC5iOC4hOC4uOC4k%2BC4iuC4reC4miIsIkRlc2NyaWJlIHdoYXQgeW91IGxpa2UgdGhlIG1vc3QiOiLguK3guJjguLTguJrguLLguKLguYDguIHguLXguYjguKLguKfguIHguLHguJrguKrguLTguYjguIfguJfguLXguYjguITguLjguJPguIrguK3guJrguKHguLLguIHguJfguLXguYjguKrguLjguJQiLCJTdW1tYXJpc2UgeW91ciBleHBlcmllbmNlIGluIG9uZSBzZW50ZW5jZSI6IuC4quC4o%2BC4uOC4m%2BC4m%2BC4o%2BC4sOC4quC4muC4geC4suC4o%2BC4k%2BC5jOC4geC4suC4o%2BC4l%2BC4s%2BC4h%2BC4suC4meC4l%2BC4teC5iOC4meC4teC5iOC5g%2BC4meC4m%2BC4o%2BC4sOC5guC4ouC4hOC5gOC4lOC4teC4ouC4pyIsIlRoaXMgd2lsbCBiZSB1c2VkIGFzIHRoZSB0aXRsZSBvZiB5b3VyIHJldmlldyI6IuC4guC5ieC4reC4hOC4p%2BC4suC4oeC4meC4teC5ieC4iOC4sOC5g%2BC4iuC5ieC5gOC4m%2BC5h%2BC4meC4iuC4t%2BC5iOC4reC4q%2BC4seC4p%2BC4guC5ieC4reC4geC4suC4o%2BC4o%2BC4teC4p%2BC4tOC4p%2BC4guC4reC4h%2BC4hOC4uOC4kyIsIldvcmsgdHlwZSI6IuC4m%2BC4o%2BC4sOC5gOC4oOC4l%2BC4h%2BC4suC4mSJ9!"
        )
      ),
"id-ID": createLanguage(
        require.resolveWeak("./translations.json-id-ID-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJGdWxsIHRpbWUiOiJQdXJuYXdha3R1IiwiUGFydCB0aW1lIjoiUGFydWggd2FrdHUiLCJDb250cmFjdCI6IktvbnRyYWsiLCJDYXN1YWwiOiJQZWtlcmphIGxlcGFzIiwiVGhlIGNoYWxsZW5nZXMiOiJUYW50YW5nYW4geWFuZyBkaWhhZGFwaSIsIkRlc2NyaWJlIHdoYXQgeW91IGRvbid0IGxpa2UiOiJKZWxhc2thbiBhcGEgeWFuZyBrdXJhbmcgQW5kYSBzdWthaSIsIlRoZSBnb29kIHRoaW5ncyI6IkhhbCB5YW5nIGJhaWsiLCJEZXNjcmliZSB3aGF0IHlvdSBsaWtlIHRoZSBtb3N0IjoiQ2VyaXRha2FuIGFwYSB5YW5nIHBhbGluZyBBbmRhIHN1a2FpIiwiU3VtbWFyaXNlIHlvdXIgZXhwZXJpZW5jZSBpbiBvbmUgc2VudGVuY2UiOiJSYW5na3VtIHBlbmdhbGFtYW4gZGFsYW0gc2F0dSBrYWxpbWF0IiwiVGhpcyB3aWxsIGJlIHVzZWQgYXMgdGhlIHRpdGxlIG9mIHlvdXIgcmV2aWV3IjoiQWthbiBkaWd1bmFrYW4gc2ViYWdhaSBqdWR1bCB1bGFzYW4gaW5pIiwiV29yayB0eXBlIjoiSmVuaXMgcGVrZXJqYWFuIn0%3D!"),
        () => import(
          /* webpackChunkName: "id-ID-translations" */
          "./translations.json-id-ID-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJGdWxsIHRpbWUiOiJQdXJuYXdha3R1IiwiUGFydCB0aW1lIjoiUGFydWggd2FrdHUiLCJDb250cmFjdCI6IktvbnRyYWsiLCJDYXN1YWwiOiJQZWtlcmphIGxlcGFzIiwiVGhlIGNoYWxsZW5nZXMiOiJUYW50YW5nYW4geWFuZyBkaWhhZGFwaSIsIkRlc2NyaWJlIHdoYXQgeW91IGRvbid0IGxpa2UiOiJKZWxhc2thbiBhcGEgeWFuZyBrdXJhbmcgQW5kYSBzdWthaSIsIlRoZSBnb29kIHRoaW5ncyI6IkhhbCB5YW5nIGJhaWsiLCJEZXNjcmliZSB3aGF0IHlvdSBsaWtlIHRoZSBtb3N0IjoiQ2VyaXRha2FuIGFwYSB5YW5nIHBhbGluZyBBbmRhIHN1a2FpIiwiU3VtbWFyaXNlIHlvdXIgZXhwZXJpZW5jZSBpbiBvbmUgc2VudGVuY2UiOiJSYW5na3VtIHBlbmdhbGFtYW4gZGFsYW0gc2F0dSBrYWxpbWF0IiwiVGhpcyB3aWxsIGJlIHVzZWQgYXMgdGhlIHRpdGxlIG9mIHlvdXIgcmV2aWV3IjoiQWthbiBkaWd1bmFrYW4gc2ViYWdhaSBqdWR1bCB1bGFzYW4gaW5pIiwiV29yayB0eXBlIjoiSmVuaXMgcGVrZXJqYWFuIn0%3D!"
        )
      ),
"zh-HK": createLanguage(
        require.resolveWeak("./translations.json-zh-HK-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJGdWxsIHRpbWUiOiJGdWxsIHRpbWUiLCJQYXJ0IHRpbWUiOiJQYXJ0IHRpbWUiLCJDb250cmFjdCI6IkNvbnRyYWN0IiwiQ2FzdWFsIjoiQ2FzdWFsIiwiVGhlIGNoYWxsZW5nZXMiOiJUaGUgY2hhbGxlbmdlcyIsIkRlc2NyaWJlIHdoYXQgeW91IGRvbid0IGxpa2UiOiJEZXNjcmliZSB3aGF0IHlvdSBkb24ndCBsaWtlIiwiVGhlIGdvb2QgdGhpbmdzIjoiVGhlIGdvb2QgdGhpbmdzIiwiRGVzY3JpYmUgd2hhdCB5b3UgbGlrZSB0aGUgbW9zdCI6IkRlc2NyaWJlIHdoYXQgeW91IGxpa2UgdGhlIG1vc3QiLCJTdW1tYXJpc2UgeW91ciBleHBlcmllbmNlIGluIG9uZSBzZW50ZW5jZSI6IlN1bW1hcmlzZSB5b3VyIGV4cGVyaWVuY2UgaW4gb25lIHNlbnRlbmNlIiwiVGhpcyB3aWxsIGJlIHVzZWQgYXMgdGhlIHRpdGxlIG9mIHlvdXIgcmV2aWV3IjoiVGhpcyB3aWxsIGJlIHVzZWQgYXMgdGhlIHRpdGxlIG9mIHlvdXIgcmV2aWV3IiwiV29yayB0eXBlIjoiV29yayB0eXBlIn0%3D!"),
        () => import(
          /* webpackChunkName: "zh-HK-translations" */
          "./translations.json-zh-HK-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJGdWxsIHRpbWUiOiJGdWxsIHRpbWUiLCJQYXJ0IHRpbWUiOiJQYXJ0IHRpbWUiLCJDb250cmFjdCI6IkNvbnRyYWN0IiwiQ2FzdWFsIjoiQ2FzdWFsIiwiVGhlIGNoYWxsZW5nZXMiOiJUaGUgY2hhbGxlbmdlcyIsIkRlc2NyaWJlIHdoYXQgeW91IGRvbid0IGxpa2UiOiJEZXNjcmliZSB3aGF0IHlvdSBkb24ndCBsaWtlIiwiVGhlIGdvb2QgdGhpbmdzIjoiVGhlIGdvb2QgdGhpbmdzIiwiRGVzY3JpYmUgd2hhdCB5b3UgbGlrZSB0aGUgbW9zdCI6IkRlc2NyaWJlIHdoYXQgeW91IGxpa2UgdGhlIG1vc3QiLCJTdW1tYXJpc2UgeW91ciBleHBlcmllbmNlIGluIG9uZSBzZW50ZW5jZSI6IlN1bW1hcmlzZSB5b3VyIGV4cGVyaWVuY2UgaW4gb25lIHNlbnRlbmNlIiwiVGhpcyB3aWxsIGJlIHVzZWQgYXMgdGhlIHRpdGxlIG9mIHlvdXIgcmV2aWV3IjoiVGhpcyB3aWxsIGJlIHVzZWQgYXMgdGhlIHRpdGxlIG9mIHlvdXIgcmV2aWV3IiwiV29yayB0eXBlIjoiV29yayB0eXBlIn0%3D!"
        )
      ),
"en-PSEUDO": createLanguage(
        require.resolveWeak("./translations.json-en-PSEUDO-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJGdWxsIHRpbWUiOiJbxpHHmseax5rGmsaaIOG5r8Osw6zDrG3MguG6veG6veG6vV0iLCJQYXJ0IHRpbWUiOiJbxqTEg8SDxIPFmeG5ryDhua%2FDrMOsw6xtzILhur3hur3hur1dIiwiQ29udHJhY3QiOiJbw4fDtsO2w7bguIHguLXguYnhua%2FFmcSDxIPEg8On4bmvXSIsIkNhc3VhbCI6IlvDh8SDxIPFoceax5rEg8SDxppdIiwiVGhlIGNoYWxsZW5nZXMiOiJb4bmu4bip4bq94bq94bq9IMOn4bipxIPEg8SDxprGmuG6veG6veG6veC4geC4teC5icSj4bq94bq94bq9xaFdIiwiRGVzY3JpYmUgd2hhdCB5b3UgZG9uJ3QgbGlrZSI6IlvhuIrhur3hur3hur3FocOnxZnDrMOsw6zDn%2BG6veG6veG6vSDFteG4qcSDxIPEg%2BG5ryDDvcO9w73DtsO2w7bHmseax5ogxozDtsO2w7bguIHguLXguYkn4bmvIMaaw6zDrMOsxLfhur3hur3hur1dIiwiVGhlIGdvb2QgdGhpbmdzIjoiW%2BG5ruG4qeG6veG6veG6vSDEo8O2w7bDtsO2w7bDtsaMIOG5r%2BG4qcOsw6zDrOC4geC4teC5icSjxaFdIiwiRGVzY3JpYmUgd2hhdCB5b3UgbGlrZSB0aGUgbW9zdCI6IlvhuIrhur3hur3hur3FocOnxZnDrMOsw6zDn%2BG6veG6veG6vSDFteG4qcSDxIPEg%2BG5ryDDvcO9w73DtsO2w7bHmseax5ogxprDrMOsw6zEt%2BG6veG6veG6vSDhua%2FhuKnhur3hur3hur0gbcyCw7bDtsO2xaHhua9dIiwiU3VtbWFyaXNlIHlvdXIgZXhwZXJpZW5jZSBpbiBvbmUgc2VudGVuY2UiOiJbxaDHmseabcyCbcyCxIPEg8WZw6zDrMWh4bq94bq9IMO9w73DtsO2x5rHmsWZIOG6veG6veqtlcal4bq94bq9xZnDrMOs4bq94bq94LiB4Li14LmJw6fhur3hur0gw6zDrOC4geC4teC5iSDDtsO24LiB4Li14LmJ4bq94bq9IMWh4bq94bq94LiB4Li14LmJ4bmv4bq94bq94LiB4Li14LmJw6fhur3hur1dIiwiVGhpcyB3aWxsIGJlIHVzZWQgYXMgdGhlIHRpdGxlIG9mIHlvdXIgcmV2aWV3IjoiW%2BG5ruG4qcOsw6zDrMWhIMW1w6zDrMOsxprGmiDDn%2BG6veG6veG6vSDHmseax5rFoeG6veG6veG6vcaMIMSDxIPEg8WhIOG5r%2BG4qeG6veG6veG6vSDhua%2FDrMOsw6zhua%2FGmuG6veG6veG6vSDDtsO2w7bGkiDDvcO9w73DtsO2w7bHmseax5rFmSDFmeG6veG6veG6veG5vcOsw6zDrOG6veG6veG6vcW1XSIsIldvcmsgdHlwZSI6IlvFtMO2w7bDtsWZxLcg4bmvw73DvcO9xqXhur3hur3hur1dIn0%3D!"),
        () => import(
          /* webpackChunkName: "en-PSEUDO-translations" */
          "./translations.json-en-PSEUDO-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJGdWxsIHRpbWUiOiJbxpHHmseax5rGmsaaIOG5r8Osw6zDrG3MguG6veG6veG6vV0iLCJQYXJ0IHRpbWUiOiJbxqTEg8SDxIPFmeG5ryDhua%2FDrMOsw6xtzILhur3hur3hur1dIiwiQ29udHJhY3QiOiJbw4fDtsO2w7bguIHguLXguYnhua%2FFmcSDxIPEg8On4bmvXSIsIkNhc3VhbCI6IlvDh8SDxIPFoceax5rEg8SDxppdIiwiVGhlIGNoYWxsZW5nZXMiOiJb4bmu4bip4bq94bq94bq9IMOn4bipxIPEg8SDxprGmuG6veG6veG6veC4geC4teC5icSj4bq94bq94bq9xaFdIiwiRGVzY3JpYmUgd2hhdCB5b3UgZG9uJ3QgbGlrZSI6IlvhuIrhur3hur3hur3FocOnxZnDrMOsw6zDn%2BG6veG6veG6vSDFteG4qcSDxIPEg%2BG5ryDDvcO9w73DtsO2w7bHmseax5ogxozDtsO2w7bguIHguLXguYkn4bmvIMaaw6zDrMOsxLfhur3hur3hur1dIiwiVGhlIGdvb2QgdGhpbmdzIjoiW%2BG5ruG4qeG6veG6veG6vSDEo8O2w7bDtsO2w7bDtsaMIOG5r%2BG4qcOsw6zDrOC4geC4teC5icSjxaFdIiwiRGVzY3JpYmUgd2hhdCB5b3UgbGlrZSB0aGUgbW9zdCI6IlvhuIrhur3hur3hur3FocOnxZnDrMOsw6zDn%2BG6veG6veG6vSDFteG4qcSDxIPEg%2BG5ryDDvcO9w73DtsO2w7bHmseax5ogxprDrMOsw6zEt%2BG6veG6veG6vSDhua%2FhuKnhur3hur3hur0gbcyCw7bDtsO2xaHhua9dIiwiU3VtbWFyaXNlIHlvdXIgZXhwZXJpZW5jZSBpbiBvbmUgc2VudGVuY2UiOiJbxaDHmseabcyCbcyCxIPEg8WZw6zDrMWh4bq94bq9IMO9w73DtsO2x5rHmsWZIOG6veG6veqtlcal4bq94bq9xZnDrMOs4bq94bq94LiB4Li14LmJw6fhur3hur0gw6zDrOC4geC4teC5iSDDtsO24LiB4Li14LmJ4bq94bq9IMWh4bq94bq94LiB4Li14LmJ4bmv4bq94bq94LiB4Li14LmJw6fhur3hur1dIiwiVGhpcyB3aWxsIGJlIHVzZWQgYXMgdGhlIHRpdGxlIG9mIHlvdXIgcmV2aWV3IjoiW%2BG5ruG4qcOsw6zDrMWhIMW1w6zDrMOsxprGmiDDn%2BG6veG6veG6vSDHmseax5rFoeG6veG6veG6vcaMIMSDxIPEg8WhIOG5r%2BG4qeG6veG6veG6vSDhua%2FDrMOsw6zhua%2FGmuG6veG6veG6vSDDtsO2w7bGkiDDvcO9w73DtsO2w7bHmseax5rFmSDFmeG6veG6veG6veG5vcOsw6zDrOG6veG6veG6vcW1XSIsIldvcmsgdHlwZSI6IlvFtMO2w7bDtsWZxLcg4bmvw73DvcO9xqXhur3hur3hur1dIn0%3D!"
        )
      )
      });
  
      export { translations as default };
    