import {
  AUTHENTICATION_TYPES,
  type Authentication,
} from '@seek/apac-candidate-header-footer/types';
import { AuthN, getConfig } from '@seek/online-identity';
import React, { useEffect, useState } from 'react';

import { useAppContext, useConfig, useLogger } from '@seek/libs-shared';

import { useService } from './serviceProvider';

export type AuthContextProps =
  | (Authentication & {
      ssoLogin?: () => void;
      userId?: number;
    })
  | undefined;

type AuthProviderProps = React.PropsWithChildren<{
  /** This should only be used for unit testing */
  auth?: AuthContextProps;
}>;

const AuthContext = React.createContext<AuthContextProps>(undefined);

export const AuthProvider = ({ auth, children }: AuthProviderProps) => {
  const { candidateService } = useService();
  const { setUserId } = useAppContext();
  const { environment } = useConfig();

  const { logger } = useLogger();

  const [authStatus, setAuthStatus] = useState<AuthContextProps>({
    authenticationStatus: AUTHENTICATION_TYPES.AUTH_PENDING,
  });

  useEffect(() => {
    const authenticate = async () => {
      try {
        const clientConfig = getConfig('seekAnz-candidate', window.location);

        const authN = new AuthN(clientConfig);

        const ssoLogin = () => authN.login();

        const isAuthenticated = await authN.isAuthenticated();

        if (isAuthenticated) {
          const candidate = await candidateService.getCandidate();

          if (setUserId && candidate.id) {
            setUserId(candidate.id.toString());
          }
          setAuthStatus({
            authenticationStatus: AUTHENTICATION_TYPES.AUTHENTICATED,
            userProfile: {
              firstName: candidate.firstName,
              lastName: candidate.lastName,
              email: candidate.email,
            },
            userId: candidate.id,
          });
        } else {
          setAuthStatus({
            authenticationStatus: AUTHENTICATION_TYPES.UNAUTHENTICATED,
            ssoLogin,
          });
        }
      } catch (error: unknown) {
        logger.error({ error }, 'Authentication failed');

        setAuthStatus({
          authenticationStatus: AUTHENTICATION_TYPES.UNAUTHENTICATED,
        });

        if (window?.location?.href) {
          window.location.href = `${window.origin}/error/forbidden`;
        }
      }
    };
    // This is used for testing purposes
    if (auth) {
      setAuthStatus(auth);
      if (setUserId && auth.userId) {
        setUserId(auth?.userId?.toString());
      }
    } else if (environment === 'local') {
      setAuthStatus({
        authenticationStatus: AUTHENTICATION_TYPES.AUTHENTICATED,
        userProfile: {
          firstName: 'John',
          lastName: 'Doe',
          email: '',
        },
        userId: 123,
        ssoLogin: () => {
          // eslint-disable-next-line no-console
          console.log('SSO login');
        },
      });
      if (setUserId) {
        setUserId('123');
      }
    } else {
      authenticate();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setUserId]);

  return (
    <AuthContext.Provider value={authStatus}>{children}</AuthContext.Provider>
  );
};

export const useAuth = () => {
  const context = React.useContext(AuthContext);

  if (context === undefined) {
    throw new Error('useAuth must be used within a AuthProvider');
  }

  return context;
};
