export const mapErrorMessage = (error: string) => {
  const errorMapping: Record<string, string> = {
    companyName: 'company_name',
    overallRating: 'overall_experience',
    workType: 'work_type',
    jobTitleText: 'job_title',
    fullLocation: 'location_selected',
    workHistoryFrom: 'start_date',
    workHistoryTo: 'end_date',
    workHistory: 'end_date',
    theGoodThings: 'good_things',
    theChallenges: 'challenges',
    title: 'summary',
    isRecommended: 'recommend_to_a_friend',
    salary: 'salary_amount',
    salaryRating: 'salary_rating',
  };

  return errorMapping[error] || error;
};
