declare global {
  interface Window {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    hj: any;
  }
}

export const sendHotjarEvent = (event: string) => {
  window.hj =
    window.hj ||
    function () {
      // eslint-disable-next-line prefer-rest-params
      (window.hj.q = window.hj.q || []).push(arguments);
    };
  window.hj('event', event);
};
