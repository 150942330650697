import {
  type Country,
  type Language,
  type Locale,
  type SiteName,
  createAbsoluteUrl,
  createUrlResolver,
} from '@seek/melways-sites';

import {
  type Environment,
  type Experience,
  type Zone,
  slugifyCompany,
} from '..';

export const buildCommunityGuidelinesURI = ({
  experience,
  locale,
  zone,
}: {
  experience: Experience;
  locale: Locale;
  zone: Zone;
}) => {
  if (experience === 'candidate') {
    switch (zone) {
      case 'asia-1':
        return 'https://help.hk.jobsdb.com/s/article/company-reviews-community-guidelines-DB';
      case 'asia-3':
        return locale === 'th-TH'
          ? 'https://help.th.jobsdb.com/th/s/article/company-reviews-community-guidelines-DB?language=th'
          : 'https://help.th.jobsdb.com/th/s/article/company-reviews-community-guidelines-DB?language=en_US';
      case 'asia-4':
        return locale === 'id-ID'
          ? 'https://help.id.jobstreet.com/id/s/article/Company-reviews-community-guidelines-asia?language=in'
          : 'https://help.id.jobstreet.com/s/article/Company-reviews-community-guidelines-asia?language=en_US';
      case 'asia-5':
        return 'https://help.my.jobstreet.com/s/article/Company-reviews-community-guidelines-asia';
      case 'asia-6':
        return 'https://help.ph.jobstreet.com/s/article/Company-reviews-community-guidelines-asia';
      case 'asia-7':
        return 'https://help.sg.jobstreet.com/s/article/Company-reviews-community-guidelines-asia';
      case 'anz-2':
        return 'https://help.seek.co.nz/s/article/Company-reviews-community-guidelines';
      default:
        return 'https://help.seek.com.au/s/article/Company-reviews-community-guidelines';
    }
  } else {
    switch (zone) {
      case 'asia-1':
        return 'https://help.hk.employer.seek.com/s/article/Company-reviews-community-guidelines-employer-asia';
      case 'asia-3':
        return locale === 'th-TH'
          ? 'https://help.th.employer.seek.com/th/s/article/Company-reviews-community-guidelines-employer-asia?language=th'
          : 'https://help.th.employer.seek.com/s/article/Company-reviews-community-guidelines-employer-asia?language=en_US';
      case 'asia-4':
        return locale === 'id-ID'
          ? 'https://help.id.employer.seek.com/id/s/article/Company-reviews-community-guidelines-employer-asia?language=in'
          : 'https://help.id.employer.seek.com/id/s/article/Company-reviews-community-guidelines-employer-asia';
      case 'asia-5':
        return 'https://help.my.employer.seek.com/s/article/Company-reviews-community-guidelines-employer-asia';
      case 'asia-6':
        return 'https://help.ph.employer.seek.com/s/article/Company-reviews-community-guidelines-employer-asia';
      case 'asia-7':
        return 'https://help.sg.employer.seek.com/s/article/Company-reviews-community-guidelines-employer-asia';
      case 'anz-2':
        return 'https://help.seek.co.nz/employer/s/article/Company-reviews-community-guidelines';
      default:
        return 'https://help.seek.com.au/employer/s/article/Company-reviews-community-guidelines-employer';
    }
  }
};

export const buildEmployerCompanyProfilePromoPageURI = ({
  country,
  environment,
  language,
}: {
  country: Country;
  environment: Environment;
  language: Language;
}) =>
  createAbsoluteUrl({
    country,
    product: 'employer',
    path: '/products/company-profiles',
    staging: environment !== 'production',
    language,
  });

export const buildWriteAReviewURI = ({
  country,
  environment,
  language,
  consolidated,
  params,
}: {
  country: Country;
  environment: Environment;
  language: Language;
  consolidated?: boolean;
  params?: string;
}) =>
  `${createAbsoluteUrl({
    country,
    product: 'candidate',
    path: '/companies/write-a-review',
    staging: environment !== 'production',
    language,
    ...(consolidated ? { alternative: 'subDomainRegion' } : {}),
  })}${params ? `?${params}` : ''}`;

export const buildCandidateCompanyProfileURI = ({
  companyName,
  companyId,
  country,
  language,
  isStaging = false,
}: {
  companyId: string;
  companyName: string;
  country: Country;
  language: Language;
  isStaging?: boolean;
}) => {
  const slug = slugifyCompany(companyName, companyId);

  return createAbsoluteUrl({
    country,
    language,
    staging: isStaging,
    product: 'candidate',
    path: `/companies/${slug}`,
  });
};

export const buildCandidateCompanyProfilesURI = ({
  country,
  language,
  isStaging = false,
}: {
  country: Country;
  language: Language;
  isStaging?: boolean;
}) => createAbsoluteUrl({
    country,
    language,
    staging: isStaging,
    product: 'candidate',
    path: '/companies',
  });

export const buildMelwaysUrlResolverURI = ({
  site,
  language,
  country,
  path,
  queryParams,
  staging = false,
  absolute = false,
}: {
  country: Country;
  language: Language;
  path: string;
  site: SiteName;
  absolute?: boolean;
  queryParams?: Record<string, string>;
  staging?: boolean;
}) => {
  let urlSearchParams: URLSearchParams | undefined;
  if (queryParams) {
    const queryKeys = Object.keys(queryParams);
    for (const key of queryKeys) {
      if (!urlSearchParams) {
        urlSearchParams = new URLSearchParams();
      }
      urlSearchParams.append(key, queryParams[key]);
    }
  }

  // For relative URLs, construct a fake absolute URL to use URL object
  const parsedUrl = new URL(path, 'http://dummy.base');
  if (urlSearchParams) {
    urlSearchParams.forEach((value, key) => {
      parsedUrl.searchParams.append(key, value);
    });
  }
  // Remove trailing slash if exists
  parsedUrl.pathname = parsedUrl.pathname.replace(/\/$/, '');

  const resolveUrl = createUrlResolver({
    site,
    language,
    staging,
  });
  return resolveUrl({
    // Convert back to relative URL (remove protocol, hostname, and port)
    path: `${parsedUrl.pathname}${parsedUrl.search}${parsedUrl.hash}`,
    language,
    country,
    /**
     * The URL will always we absolute if site is equal to either:
     * - 'candidate-seek-root'
     * - 'candidate-jobsdb-root'
     * - 'candidate-jobstreet-root'
     */
    absolute,
  });
};
