
      import { createLanguage, createTranslationFile } from '@vocab/webpack/web';
      
    const translations = createTranslationFile({
      "en": createLanguage(
        require.resolveWeak("./translations.json-en-virtual.json!=!../../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJ0b3RhbCByYXRpbmcgZnJvbSI6InRvdGFsIHJhdGluZyBmcm9tIiwicmV2aWV3cyI6InJldmlld3MiLCJyYXRpbmdzIGluIHRvdGFsIjoicmF0aW5ncyBpbiB0b3RhbCJ9!"),
        () => import(
          /* webpackChunkName: "en-translations" */
          "./translations.json-en-virtual.json!=!../../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJ0b3RhbCByYXRpbmcgZnJvbSI6InRvdGFsIHJhdGluZyBmcm9tIiwicmV2aWV3cyI6InJldmlld3MiLCJyYXRpbmdzIGluIHRvdGFsIjoicmF0aW5ncyBpbiB0b3RhbCJ9!"
        )
      ),
"th": createLanguage(
        require.resolveWeak("./translations.json-th-virtual.json!=!../../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJ0b3RhbCByYXRpbmcgZnJvbSI6IuC4hOC4sOC5geC4meC4meC4l%2BC4seC5ieC4h%2BC4q%2BC4oeC4lOC4iOC4suC4gSIsInJldmlld3MiOiLguKPguLXguKfguLTguKciLCJyYXRpbmdzIGluIHRvdGFsIjoi4LiB4Liy4Lij4LmD4Lir4LmJ4LiE4Liw4LmB4LiZ4LiZ4LiX4Lix4LmJ4LiH4Lir4Lih4LiUIn0%3D!"),
        () => import(
          /* webpackChunkName: "th-translations" */
          "./translations.json-th-virtual.json!=!../../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJ0b3RhbCByYXRpbmcgZnJvbSI6IuC4hOC4sOC5geC4meC4meC4l%2BC4seC5ieC4h%2BC4q%2BC4oeC4lOC4iOC4suC4gSIsInJldmlld3MiOiLguKPguLXguKfguLTguKciLCJyYXRpbmdzIGluIHRvdGFsIjoi4LiB4Liy4Lij4LmD4Lir4LmJ4LiE4Liw4LmB4LiZ4LiZ4LiX4Lix4LmJ4LiH4Lir4Lih4LiUIn0%3D!"
        )
      ),
"id": createLanguage(
        require.resolveWeak("./translations.json-id-virtual.json!=!../../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJ0b3RhbCByYXRpbmcgZnJvbSI6Im5pbGFpIGtlc2VsdXJ1aGFuIGRhcmkiLCJyZXZpZXdzIjoidWxhc2FuIiwicmF0aW5ncyBpbiB0b3RhbCI6InRvdGFsIHBlbmlsYWlhbiJ9!"),
        () => import(
          /* webpackChunkName: "id-translations" */
          "./translations.json-id-virtual.json!=!../../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJ0b3RhbCByYXRpbmcgZnJvbSI6Im5pbGFpIGtlc2VsdXJ1aGFuIGRhcmkiLCJyZXZpZXdzIjoidWxhc2FuIiwicmF0aW5ncyBpbiB0b3RhbCI6InRvdGFsIHBlbmlsYWlhbiJ9!"
        )
      ),
"zh": createLanguage(
        require.resolveWeak("./translations.json-zh-virtual.json!=!../../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJ0b3RhbCByYXRpbmcgZnJvbSI6InRvdGFsIHJhdGluZyBmcm9tIiwicmV2aWV3cyI6InJldmlld3MiLCJyYXRpbmdzIGluIHRvdGFsIjoicmF0aW5ncyBpbiB0b3RhbCJ9!"),
        () => import(
          /* webpackChunkName: "zh-translations" */
          "./translations.json-zh-virtual.json!=!../../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJ0b3RhbCByYXRpbmcgZnJvbSI6InRvdGFsIHJhdGluZyBmcm9tIiwicmV2aWV3cyI6InJldmlld3MiLCJyYXRpbmdzIGluIHRvdGFsIjoicmF0aW5ncyBpbiB0b3RhbCJ9!"
        )
      ),
"en-AU": createLanguage(
        require.resolveWeak("./translations.json-en-AU-virtual.json!=!../../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJ0b3RhbCByYXRpbmcgZnJvbSI6InRvdGFsIHJhdGluZyBmcm9tIiwicmV2aWV3cyI6InJldmlld3MiLCJyYXRpbmdzIGluIHRvdGFsIjoicmF0aW5ncyBpbiB0b3RhbCJ9!"),
        () => import(
          /* webpackChunkName: "en-AU-translations" */
          "./translations.json-en-AU-virtual.json!=!../../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJ0b3RhbCByYXRpbmcgZnJvbSI6InRvdGFsIHJhdGluZyBmcm9tIiwicmV2aWV3cyI6InJldmlld3MiLCJyYXRpbmdzIGluIHRvdGFsIjoicmF0aW5ncyBpbiB0b3RhbCJ9!"
        )
      ),
"en-HK": createLanguage(
        require.resolveWeak("./translations.json-en-HK-virtual.json!=!../../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJ0b3RhbCByYXRpbmcgZnJvbSI6InRvdGFsIHJhdGluZyBmcm9tIiwicmV2aWV3cyI6InJldmlld3MiLCJyYXRpbmdzIGluIHRvdGFsIjoicmF0aW5ncyBpbiB0b3RhbCJ9!"),
        () => import(
          /* webpackChunkName: "en-HK-translations" */
          "./translations.json-en-HK-virtual.json!=!../../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJ0b3RhbCByYXRpbmcgZnJvbSI6InRvdGFsIHJhdGluZyBmcm9tIiwicmV2aWV3cyI6InJldmlld3MiLCJyYXRpbmdzIGluIHRvdGFsIjoicmF0aW5ncyBpbiB0b3RhbCJ9!"
        )
      ),
"en-ID": createLanguage(
        require.resolveWeak("./translations.json-en-ID-virtual.json!=!../../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJ0b3RhbCByYXRpbmcgZnJvbSI6InRvdGFsIHJhdGluZyBmcm9tIiwicmV2aWV3cyI6InJldmlld3MiLCJyYXRpbmdzIGluIHRvdGFsIjoicmF0aW5ncyBpbiB0b3RhbCJ9!"),
        () => import(
          /* webpackChunkName: "en-ID-translations" */
          "./translations.json-en-ID-virtual.json!=!../../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJ0b3RhbCByYXRpbmcgZnJvbSI6InRvdGFsIHJhdGluZyBmcm9tIiwicmV2aWV3cyI6InJldmlld3MiLCJyYXRpbmdzIGluIHRvdGFsIjoicmF0aW5ncyBpbiB0b3RhbCJ9!"
        )
      ),
"en-MY": createLanguage(
        require.resolveWeak("./translations.json-en-MY-virtual.json!=!../../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJ0b3RhbCByYXRpbmcgZnJvbSI6InRvdGFsIHJhdGluZyBmcm9tIiwicmV2aWV3cyI6InJldmlld3MiLCJyYXRpbmdzIGluIHRvdGFsIjoicmF0aW5ncyBpbiB0b3RhbCJ9!"),
        () => import(
          /* webpackChunkName: "en-MY-translations" */
          "./translations.json-en-MY-virtual.json!=!../../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJ0b3RhbCByYXRpbmcgZnJvbSI6InRvdGFsIHJhdGluZyBmcm9tIiwicmV2aWV3cyI6InJldmlld3MiLCJyYXRpbmdzIGluIHRvdGFsIjoicmF0aW5ncyBpbiB0b3RhbCJ9!"
        )
      ),
"en-NZ": createLanguage(
        require.resolveWeak("./translations.json-en-NZ-virtual.json!=!../../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJ0b3RhbCByYXRpbmcgZnJvbSI6InRvdGFsIHJhdGluZyBmcm9tIiwicmV2aWV3cyI6InJldmlld3MiLCJyYXRpbmdzIGluIHRvdGFsIjoicmF0aW5ncyBpbiB0b3RhbCJ9!"),
        () => import(
          /* webpackChunkName: "en-NZ-translations" */
          "./translations.json-en-NZ-virtual.json!=!../../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJ0b3RhbCByYXRpbmcgZnJvbSI6InRvdGFsIHJhdGluZyBmcm9tIiwicmV2aWV3cyI6InJldmlld3MiLCJyYXRpbmdzIGluIHRvdGFsIjoicmF0aW5ncyBpbiB0b3RhbCJ9!"
        )
      ),
"en-PH": createLanguage(
        require.resolveWeak("./translations.json-en-PH-virtual.json!=!../../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJ0b3RhbCByYXRpbmcgZnJvbSI6InRvdGFsIHJhdGluZyBmcm9tIiwicmV2aWV3cyI6InJldmlld3MiLCJyYXRpbmdzIGluIHRvdGFsIjoicmF0aW5ncyBpbiB0b3RhbCJ9!"),
        () => import(
          /* webpackChunkName: "en-PH-translations" */
          "./translations.json-en-PH-virtual.json!=!../../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJ0b3RhbCByYXRpbmcgZnJvbSI6InRvdGFsIHJhdGluZyBmcm9tIiwicmV2aWV3cyI6InJldmlld3MiLCJyYXRpbmdzIGluIHRvdGFsIjoicmF0aW5ncyBpbiB0b3RhbCJ9!"
        )
      ),
"en-SG": createLanguage(
        require.resolveWeak("./translations.json-en-SG-virtual.json!=!../../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJ0b3RhbCByYXRpbmcgZnJvbSI6InRvdGFsIHJhdGluZyBmcm9tIiwicmV2aWV3cyI6InJldmlld3MiLCJyYXRpbmdzIGluIHRvdGFsIjoicmF0aW5ncyBpbiB0b3RhbCJ9!"),
        () => import(
          /* webpackChunkName: "en-SG-translations" */
          "./translations.json-en-SG-virtual.json!=!../../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJ0b3RhbCByYXRpbmcgZnJvbSI6InRvdGFsIHJhdGluZyBmcm9tIiwicmV2aWV3cyI6InJldmlld3MiLCJyYXRpbmdzIGluIHRvdGFsIjoicmF0aW5ncyBpbiB0b3RhbCJ9!"
        )
      ),
"en-TH": createLanguage(
        require.resolveWeak("./translations.json-en-TH-virtual.json!=!../../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJ0b3RhbCByYXRpbmcgZnJvbSI6InRvdGFsIHJhdGluZyBmcm9tIiwicmV2aWV3cyI6InJldmlld3MiLCJyYXRpbmdzIGluIHRvdGFsIjoicmF0aW5ncyBpbiB0b3RhbCJ9!"),
        () => import(
          /* webpackChunkName: "en-TH-translations" */
          "./translations.json-en-TH-virtual.json!=!../../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJ0b3RhbCByYXRpbmcgZnJvbSI6InRvdGFsIHJhdGluZyBmcm9tIiwicmV2aWV3cyI6InJldmlld3MiLCJyYXRpbmdzIGluIHRvdGFsIjoicmF0aW5ncyBpbiB0b3RhbCJ9!"
        )
      ),
"th-TH": createLanguage(
        require.resolveWeak("./translations.json-th-TH-virtual.json!=!../../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJ0b3RhbCByYXRpbmcgZnJvbSI6IuC4hOC4sOC5geC4meC4meC4l%2BC4seC5ieC4h%2BC4q%2BC4oeC4lOC4iOC4suC4gSIsInJldmlld3MiOiLguKPguLXguKfguLTguKciLCJyYXRpbmdzIGluIHRvdGFsIjoi4LiB4Liy4Lij4LmD4Lir4LmJ4LiE4Liw4LmB4LiZ4LiZ4LiX4Lix4LmJ4LiH4Lir4Lih4LiUIn0%3D!"),
        () => import(
          /* webpackChunkName: "th-TH-translations" */
          "./translations.json-th-TH-virtual.json!=!../../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJ0b3RhbCByYXRpbmcgZnJvbSI6IuC4hOC4sOC5geC4meC4meC4l%2BC4seC5ieC4h%2BC4q%2BC4oeC4lOC4iOC4suC4gSIsInJldmlld3MiOiLguKPguLXguKfguLTguKciLCJyYXRpbmdzIGluIHRvdGFsIjoi4LiB4Liy4Lij4LmD4Lir4LmJ4LiE4Liw4LmB4LiZ4LiZ4LiX4Lix4LmJ4LiH4Lir4Lih4LiUIn0%3D!"
        )
      ),
"id-ID": createLanguage(
        require.resolveWeak("./translations.json-id-ID-virtual.json!=!../../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJ0b3RhbCByYXRpbmcgZnJvbSI6Im5pbGFpIGtlc2VsdXJ1aGFuIGRhcmkiLCJyZXZpZXdzIjoidWxhc2FuIiwicmF0aW5ncyBpbiB0b3RhbCI6InRvdGFsIHBlbmlsYWlhbiJ9!"),
        () => import(
          /* webpackChunkName: "id-ID-translations" */
          "./translations.json-id-ID-virtual.json!=!../../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJ0b3RhbCByYXRpbmcgZnJvbSI6Im5pbGFpIGtlc2VsdXJ1aGFuIGRhcmkiLCJyZXZpZXdzIjoidWxhc2FuIiwicmF0aW5ncyBpbiB0b3RhbCI6InRvdGFsIHBlbmlsYWlhbiJ9!"
        )
      ),
"zh-HK": createLanguage(
        require.resolveWeak("./translations.json-zh-HK-virtual.json!=!../../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJ0b3RhbCByYXRpbmcgZnJvbSI6InRvdGFsIHJhdGluZyBmcm9tIiwicmV2aWV3cyI6InJldmlld3MiLCJyYXRpbmdzIGluIHRvdGFsIjoicmF0aW5ncyBpbiB0b3RhbCJ9!"),
        () => import(
          /* webpackChunkName: "zh-HK-translations" */
          "./translations.json-zh-HK-virtual.json!=!../../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJ0b3RhbCByYXRpbmcgZnJvbSI6InRvdGFsIHJhdGluZyBmcm9tIiwicmV2aWV3cyI6InJldmlld3MiLCJyYXRpbmdzIGluIHRvdGFsIjoicmF0aW5ncyBpbiB0b3RhbCJ9!"
        )
      ),
"en-PSEUDO": createLanguage(
        require.resolveWeak("./translations.json-en-PSEUDO-virtual.json!=!../../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJ0b3RhbCByYXRpbmcgZnJvbSI6Ilvhua%2FDtsO2w7bhua%2FEg8SDxIPGmiDFmcSDxIPEg%2BG5r8Osw6zDrOC4geC4teC5icSjIMaSxZnDtsO2w7ZtzIJdIiwicmV2aWV3cyI6IlvFmeG6veG6veG5vcOsw6zhur3hur3FtcWhXSIsInJhdGluZ3MgaW4gdG90YWwiOiJbxZnEg8SDxIPhua%2FDrMOsw6zguIHguLXguYnEo8WhIMOsw6zDrOC4geC4teC5iSDhua%2FDtsO2w7bhua%2FEg8SDxIPGml0ifQ%3D%3D!"),
        () => import(
          /* webpackChunkName: "en-PSEUDO-translations" */
          "./translations.json-en-PSEUDO-virtual.json!=!../../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJ0b3RhbCByYXRpbmcgZnJvbSI6Ilvhua%2FDtsO2w7bhua%2FEg8SDxIPGmiDFmcSDxIPEg%2BG5r8Osw6zDrOC4geC4teC5icSjIMaSxZnDtsO2w7ZtzIJdIiwicmV2aWV3cyI6IlvFmeG6veG6veG5vcOsw6zhur3hur3FtcWhXSIsInJhdGluZ3MgaW4gdG90YWwiOiJbxZnEg8SDxIPhua%2FDrMOsw6zguIHguLXguYnEo8WhIMOsw6zDrOC4geC4teC5iSDhua%2FDtsO2w7bhua%2FEg8SDxIPGml0ifQ%3D%3D!"
        )
      )
      });
  
      export { translations as default };
    