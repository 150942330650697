/* istanbul ignore file */
// this is a mock file for testing
import { DEFAULT_LOGO_URL } from '@seek/libs-shared';

import type {
  LegacyCompanyProfile,
  LegacySearchCompaniesResult,
} from './types';

export const createLegacyCompanyProfileServiceMock = () => {
  const getLegacyCompanyProfile = async (
    _companyId: string,
  ): Promise<LegacyCompanyProfile> => ({
    address: '60 Cremorne St, Cremorne, VIC 3121',
    assets: {
      cover: {
        strategies: {
          jdp_cover:
            'https://image-service-cdn.seek.com.au/73a9ac271ce110bfdc91562ba0619b37f4630a5f/42bf52e76925e2211c3d956332bf254f2157d3de',
        },
        id: '73a9ac271ce110bfdc91562ba0619b37f4630a5f',
        url: 'https://image-service-cdn.seek.com.au/73a9ac271ce110bfdc91562ba0619b37f4630a5f',
      },
      logo: {
        strategies: {
          jdp_logo: DEFAULT_LOGO_URL,
        },
        id: '065e5cdc21f0bd6bdb63f543be9ea6db8bb92b70',
        url: 'https://image-service-cdn.seek.com.au/065e5cdc21f0bd6bdb63f543be9ea6db8bb92b70',
      },
    },
    company_id: '432600',
    company_name: 'SEEK',
    country_iso: 'au',
    culture_values: {
      heading: 'Our SEEK',
      values: [
        {
          text: 'We are passionate about our purpose, our customers and our community.',
          title: 'PASSION',
        },
        {
          text: 'We care about each other and collaborate to achieve together.',
          title: 'TEAM',
        },
        {
          text: 'We execute with excellence and achieve great results.',
          title: 'DELIVERY',
        },
        {
          text: 'We think and act for the long-term.',
          title: 'FUTURE',
        },
      ],
      photo:
        'https://image-service-cdn.seek.com.au/173a452b8e53d9edf217588aaa1c2feee8db9906',
      text: 'Our SEEK is a statement that describes our culture, and what we know to be true for success at SEEK. By using Our SEEK everyday, we deliver on our Purpose, create great value for our customers, and make SEEK an even better place to work.',
    },
    custom_statement: {
      text: [
        'At SEEK we are energised about making our communities even better. In keeping with our purpose of helping people live more fulfilling and productive working lives and to help organisations succeed, we are committed to operating sustainably and to always think and act with long-term goals in mind.',
        'SEEK approaches sustainability via an Environment, Social and Governance (ESG) Framework, with a focus on priorities that deliver social and environmental value for our communities and our people. We support the United Nations Sustainable Development Goals as a blueprint to achieve a better and more sustainable future for all.',
        'For more information see https://www.seek.com.au/about/sustainability/',
      ],
      title: 'Our approach to sustainability',
    },
    featured: {
      sub_heading: 'Best places 2022 awards',
      link: 'https://image-service-cdn.seek.com.au/5a283cb51a07b72a18bd648d0fbdd2e12090bb61',
      text: [
        "Our people are what make SEEK the company it is today, and we're honoured to be recognised as a top 10 Best Places to Work in Tech 2022 by The Australian Financial Review. In 2021, SEEK was named the Best Place to Work Overall and the #1 Best Place to Work in Tech.",
      ],
      link_is_video: false,
    },
    foreign_company_id: '3987',
    gallery: {
      photo_video_gallery: {
        photos: [
          {
            date: 1657861531,
            link: 'https://image-service-cdn.seek.com.au/8050efd72ed53e3c231764909ef04bd5d84e782f',
            title: 'Our new office in Cremorne',
          },
          {
            date: 1657861507,
            link: 'https://image-service-cdn.seek.com.au/9707bf991663ffb09c611fe16f1e3cc142796f5d',
            title: 'SEEK HQ',
          },
          {
            date: 1657861497,
            link: 'https://image-service-cdn.seek.com.au/f725757bfaadb123933eee461a3b7537c7be16f0',
            title: 'SEEK HQ',
          },
          {
            date: 1657861484,
            link: 'https://image-service-cdn.seek.com.au/c5a941b4a9bab2dc94cb1c1189dde2210f5d02af',
            title: 'SEEK HQ',
          },
          {
            date: 1657861458,
            link: 'https://image-service-cdn.seek.com.au/ed212d5e4dcb2d5a3ae8ef137677d688c280f031',
            title: 'Midsumma Pride March 2020',
          },
          {
            date: 1657861445,
            link: 'https://image-service-cdn.seek.com.au/10620888e059667cfa2902357d3f17d60c75ced0',
            title: 'Midsumma Pride March 2020',
          },
          {
            date: 1657860643,
            link: 'https://image-service-cdn.seek.com.au/88136ec8aa79c555211ee720ed7c9cd3babe824f',
            title: 'Hackathon 2022',
          },
          {
            date: 1657860630,
            link: 'https://image-service-cdn.seek.com.au/816aa8e1f313927b0b92cce65a6bde5a50560d1f',
            title: 'Hackathon 2022',
          },
          {
            date: 1657860617,
            link: 'https://image-service-cdn.seek.com.au/8e23642ca5822decdc08d591d7c694ed36fb5c4d',
            title: 'Hackathon 2022',
          },
          {
            date: 1657860600,
            link: 'https://image-service-cdn.seek.com.au/1392686cc727fab65ffc42cd3dc526b3a70f15dd',
            title: 'Hackathon 2022',
          },
          {
            date: 1657860588,
            link: 'https://image-service-cdn.seek.com.au/b2bbd878dbede8712dae31e91434c90c07b7fb97',
            title: 'Hackathon 2022',
          },
          {
            date: 1657860575,
            link: 'https://image-service-cdn.seek.com.au/028a8d7f7c1dcaf6aeef8c26bb6f340362965e8e',
            title: 'Hackathon 2022',
          },
          {
            date: 1641365894,
            link: 'https://image-service-cdn.seek.com.au/39d3238cda46d494c5ff6a0bb3a4fc948b9032df',
            title: 'Celebrations at SEEK!',
          },
          {
            date: 1641365871,
            link: 'https://image-service-cdn.seek.com.au/6dd0f6686bdf37f882c34b468804c8354221e1db',
            title: 'Hackathon 2019',
          },
          {
            date: 1641365864,
            link: 'https://image-service-cdn.seek.com.au/0f4d29c5bf5b151c16f2b9e201e197c44ba01621',
            title: 'Hackathon 2019',
          },
          {
            date: 1641365856,
            link: 'https://image-service-cdn.seek.com.au/f87b48e81a81ec51ce94c44d265ece5b0d26352e',
            title: 'Hackathon 2019',
          },
          {
            date: 1641365847,
            link: 'https://image-service-cdn.seek.com.au/6d9e37276d63066d08dba63a8da0e184cd50e858',
            title: 'Hackathon 2019',
          },
          {
            date: 1641365829,
            link: 'https://image-service-cdn.seek.com.au/df5b83687d4e01fd550d46d7dda3c5ae7cf740d2',
            title: 'Hackathon 2019',
          },
          {
            date: 1641365771,
            link: 'https://image-service-cdn.seek.com.au/b8877331ddfa7a6dbb0c26924a53daaf6867ae6c',
            title: 'Hackathon 2019',
          },
        ],
      },
    },
    industry: 'Advertising, Arts & Media',
    mission_statement: [
      'Our purpose is to help people live more fulfilling and productive working lives and help organisations succeed.',
    ],
    number_of_employees_range: '1,001-5,000',
    overview: [
      'SEEK is a global leader in employment marketplaces, solving real problems for our customers and connecting the right work with the right people. Our platforms are used by approximately 50 million candidates worldwide, we have relationships with over 300,000 hirers and exposure to around 900 million people, which means we really know the markets we are operating in.',
      'With an international presence that spans Australia, New Zealand, South-East Asia, Brazil and Mexico, your career opportunities are many at SEEK.',
    ],
    perks_benefits: [
      {
        text: 'Free kick-start breakfast every morning and free coffee, tea and fresh fruit are available all day in our offices.',
        title: 'Free Breakfast',
      },
      {
        text: '14 weeks paid primary carer leave with parental coaching program and support to transition back to work.',
        title: 'Support for parents',
      },
      {
        text: 'Paid volunteer opportunities with our charity partners, and salary deductions direct to our Small Change workplace giving program.',
        title: 'Community Impact',
      },
      {
        text: 'A range of internal and external opportunities to support SEEKers to reach their full potential.',
        title: 'Learning & Development:',
      },
    ],
    should_display_profile: true,
    specialities: ['Online Employment Classifieds', 'Job Boards', 'Education'],
    website_url: 'https://seek.com.au',
    awards: [
      {
        year: 2022,
        name: 'Best Places to work',
        link: 'https://image-service-cdn.seek.com.au/d396661325772a167de0cd248d7fc4ecdc3c0403',
      },
      {
        year: 2022,
        name: 'Certified carbon neutral for ANZ, Asia & Latin America business operations FY',
        link: 'https://image-service-cdn.seek.com.au/e54df8f98facd86b4e5d76154188b9f4bdf98349',
      },
    ],
    logo_url: DEFAULT_LOGO_URL,
  });

  const getLegacyCompanyProfilePreview = async (
    _companyId: string,
  ): Promise<LegacyCompanyProfile> => ({
    address: '60 Draft Cremorne St, Cremorne, VIC 3121',
    assets: {
      cover: {
        strategies: {
          jdp_cover:
            'https://image-service-cdn.seek.com.au/73a9ac271ce110bfdc91562ba0619b37f4630a5f/42bf52e76925e2211c3d956332bf254f2157d3de',
        },
        id: '73a9ac271ce110bfdc91562ba0619b37f4630a5f',
        url: 'https://image-service-cdn.seek.com.au/73a9ac271ce110bfdc91562ba0619b37f4630a5f',
      },
      logo: {
        strategies: {
          jdp_logo: DEFAULT_LOGO_URL,
        },
        id: '065e5cdc21f0bd6bdb63f543be9ea6db8bb92b70',
        url: 'https://image-service-cdn.seek.com.au/065e5cdc21f0bd6bdb63f543be9ea6db8bb92b70',
      },
    },
    company_id: '432600',
    company_name: 'SEEK draft',
    country_iso: 'au',
    culture_values: {
      heading: 'Our SEEK draft',
      values: [
        {
          text: 'We are passionate about our purpose, our customers and our community.',
          title: 'PASSION',
        },
        {
          text: 'We care about each other and collaborate to achieve together.',
          title: 'TEAM',
        },
        {
          text: 'We execute with excellence and achieve great results.',
          title: 'DELIVERY',
        },
        {
          text: 'We think and act for the long-term.',
          title: 'FUTURE',
        },
      ],
      photo:
        'https://image-service-cdn.seek.com.au/173a452b8e53d9edf217588aaa1c2feee8db9906',
      text: 'Our SEEK is a statement that describes our culture, and what we know to be true for success at SEEK. By using Our SEEK everyday, we deliver on our Purpose, create great value for our customers, and make SEEK an even better place to work.',
    },
    custom_statement: {
      text: [
        'At SEEK draft we are energised about making our communities even better. In keeping with our purpose of helping people live more fulfilling and productive working lives and to help organisations succeed, we are committed to operating sustainably and to always think and act with long-term goals in mind.',
        'SEEK approaches sustainability via an Environment, Social and Governance (ESG) Framework, with a focus on priorities that deliver social and environmental value for our communities and our people. We support the United Nations Sustainable Development Goals as a blueprint to achieve a better and more sustainable future for all.',
        'For more information see https://www.seek.com.au/about/sustainability/',
      ],
      title: 'Our approach to sustainability',
    },
    featured: {
      sub_heading: 'Best places 2022 awards',
      link: 'https://image-service-cdn.seek.com.au/5a283cb51a07b72a18bd648d0fbdd2e12090bb61',
      text: [
        "Our people are what make SEEK the company it is today, and we're honoured to be recognised as a top 10 Best Places to Work in Tech 2022 by The Australian Financial Review. In 2021, SEEK was named the Best Place to Work Overall and the #1 Best Place to Work in Tech.",
      ],
      link_is_video: false,
    },
    foreign_company_id: '3987',
    gallery: {
      photo_video_gallery: {
        photos: [
          {
            date: 1657861531,
            link: 'https://image-service-cdn.seek.com.au/8050efd72ed53e3c231764909ef04bd5d84e782f',
            title: 'Our new office in Cremorne',
          },
          {
            date: 1657861507,
            link: 'https://image-service-cdn.seek.com.au/9707bf991663ffb09c611fe16f1e3cc142796f5d',
            title: 'SEEK HQ',
          },
          {
            date: 1657861497,
            link: 'https://image-service-cdn.seek.com.au/f725757bfaadb123933eee461a3b7537c7be16f0',
            title: 'SEEK HQ',
          },
          {
            date: 1657861484,
            link: 'https://image-service-cdn.seek.com.au/c5a941b4a9bab2dc94cb1c1189dde2210f5d02af',
            title: 'SEEK HQ',
          },
          {
            date: 1657861458,
            link: 'https://image-service-cdn.seek.com.au/ed212d5e4dcb2d5a3ae8ef137677d688c280f031',
            title: 'Midsumma Pride March 2020',
          },
          {
            date: 1657861445,
            link: 'https://image-service-cdn.seek.com.au/10620888e059667cfa2902357d3f17d60c75ced0',
            title: 'Midsumma Pride March 2020',
          },
          {
            date: 1657860643,
            link: 'https://image-service-cdn.seek.com.au/88136ec8aa79c555211ee720ed7c9cd3babe824f',
            title: 'Hackathon 2022',
          },
          {
            date: 1657860630,
            link: 'https://image-service-cdn.seek.com.au/816aa8e1f313927b0b92cce65a6bde5a50560d1f',
            title: 'Hackathon 2022',
          },
          {
            date: 1657860617,
            link: 'https://image-service-cdn.seek.com.au/8e23642ca5822decdc08d591d7c694ed36fb5c4d',
            title: 'Hackathon 2022',
          },
          {
            date: 1657860600,
            link: 'https://image-service-cdn.seek.com.au/1392686cc727fab65ffc42cd3dc526b3a70f15dd',
            title: 'Hackathon 2022',
          },
          {
            date: 1657860588,
            link: 'https://image-service-cdn.seek.com.au/b2bbd878dbede8712dae31e91434c90c07b7fb97',
            title: 'Hackathon 2022',
          },
          {
            date: 1657860575,
            link: 'https://image-service-cdn.seek.com.au/028a8d7f7c1dcaf6aeef8c26bb6f340362965e8e',
            title: 'Hackathon 2022',
          },
          {
            date: 1641365894,
            link: 'https://image-service-cdn.seek.com.au/39d3238cda46d494c5ff6a0bb3a4fc948b9032df',
            title: 'Celebrations at SEEK!',
          },
          {
            date: 1641365871,
            link: 'https://image-service-cdn.seek.com.au/6dd0f6686bdf37f882c34b468804c8354221e1db',
            title: 'Hackathon 2019',
          },
          {
            date: 1641365864,
            link: 'https://image-service-cdn.seek.com.au/0f4d29c5bf5b151c16f2b9e201e197c44ba01621',
            title: 'Hackathon 2019',
          },
          {
            date: 1641365856,
            link: 'https://image-service-cdn.seek.com.au/f87b48e81a81ec51ce94c44d265ece5b0d26352e',
            title: 'Hackathon 2019',
          },
          {
            date: 1641365847,
            link: 'https://image-service-cdn.seek.com.au/6d9e37276d63066d08dba63a8da0e184cd50e858',
            title: 'Hackathon 2019',
          },
          {
            date: 1641365829,
            link: 'https://image-service-cdn.seek.com.au/df5b83687d4e01fd550d46d7dda3c5ae7cf740d2',
            title: 'Hackathon 2019',
          },
          {
            date: 1641365771,
            link: 'https://image-service-cdn.seek.com.au/b8877331ddfa7a6dbb0c26924a53daaf6867ae6c',
            title: 'Hackathon 2019',
          },
        ],
      },
    },
    industry: 'Advertising, Arts & Media',
    mission_statement: [
      'Our draft purpose is to help people live more fulfilling and productive working lives and help organisations succeed.',
    ],
    number_of_employees_range: '1,001-5,000',
    overview: [
      'SEEK is a global leader in employment marketplaces, solving real problems for our customers and connecting the right work with the right people. Our platforms are used by approximately 50 million candidates worldwide, we have relationships with over 300,000 hirers and exposure to around 900 million people, which means we really know the markets we are operating in.',
      'With an international presence that spans Australia, New Zealand, South-East Asia, Brazil and Mexico, your career opportunities are many at SEEK.',
    ],
    perks_benefits: [
      {
        text: 'draft Free kick-start breakfast every morning and free coffee, tea and fresh fruit are available all day in our offices.',
        title: 'Free Breakfast',
      },
      {
        text: '14 weeks paid primary carer leave with parental coaching program and support to transition back to work.',
        title: 'Support for parents',
      },
      {
        text: 'Paid volunteer opportunities with our charity partners, and salary deductions direct to our Small Change workplace giving program.',
        title: 'Community Impact',
      },
      {
        text: 'A range of internal and external opportunities to support SEEKers to reach their full potential.',
        title: 'Learning & Development:',
      },
    ],
    should_display_profile: true,
    specialities: ['Online Employment Classifieds', 'Job Boards', 'Education'],
    website_url: 'https://seek.com.au',
    awards: [
      {
        year: 2022,
        name: 'Best Places to work',
        link: 'https://image-service-cdn.seek.com.au/d396661325772a167de0cd248d7fc4ecdc3c0403',
      },
      {
        year: 2022,
        name: 'Certified carbon neutral for ANZ, Asia & Latin America business operations FY',
        link: 'https://image-service-cdn.seek.com.au/e54df8f98facd86b4e5d76154188b9f4bdf98349',
      },
    ],
    logo_url: DEFAULT_LOGO_URL,
  });

  const getCompanyProfileForeignId = async (
    companyId: string,
  ): Promise<string> => `99${companyId}`;

  const getCompanyReviews = async (
    _companyId: string,
    _options: {
      page: number;
      sort?: string;
    },
  ) => {
    const response = {
      data: [
        {
          company_id: '1217901',
          job_title: 'test job title',
          review_title: 'test',
          pros: 'test pro',
          cons: 'test con',
          created_at: '2017-04-07T03:05:42.000Z',
          rating: {
            work_life_balance: 3,
            benefits_and_perks: 3,
            company_overall: 4,
            diversity: 3,
            work_environment: 3,
            executive_management: 3,
            career_opportunity: 3,
          },
          review_id: '1294474',
          upvotes: {
            user_ids: [],
            count: 18,
            last_upvote_at: '2023-01-12T02:39:38.425Z',
          },
          replies: [
            {
              created_at: '2022-12-08T06:23:38.726Z',
              from: 'Manager',
              text: 'My test',
              user_id: '1',
            },
          ],
          anonymize_job_title: false,
          company_name: 'SEEK',
          id: '1294474',
          is_anonymized: false,
          rating_benefits_and_perks: 3,
          rating_career_opportunity: 3,
          rating_company_overall: 4,
          rating_executive_management: 3,
          rating_work_environment: 3,
          rating_work_life_balance: 3,
          rating_diversity: 3,
          title: 'test',
          total_upvote: 18,
          upvoted_users: [],
          review_response: 'My test',
          review_response_by: 'Manager',
          review_response_foreign_user_id: '1',
          review_response_created_at: '2022-12-08T06:23:38.726Z',
          review_url: '/companies/1217901/company-reviews/1294474',
          review_created_at: '2017-04-07T03:05:42.000Z',
          company_recommended: true,
          salary_summary: 'below',
        },
        {
          company_id: '1217901',
          job_title: 'test job title',
          review_title: 'test',
          pros: 'test pro',
          cons: 'test con',
          created_at: '2017-04-07T03:05:21.000Z',
          rating: {
            work_life_balance: 3,
            benefits_and_perks: 3,
            company_overall: 2,
            diversity: 3,
            work_environment: 3,
            executive_management: 3,
            career_opportunity: 3,
          },
          review_id: '1294472',
          upvotes: {
            user_ids: [],
            count: 16,
            last_upvote_at: '2023-01-12T02:39:52.723Z',
          },
          replies: [
            {
              user_id: '1',
              created_at: '2022-12-09T00:38:03.318Z',
              from: 'Manager',
              text: 'Some reply',
            },
          ],
          anonymize_job_title: false,
          company_name: 'SEEK',
          id: '1294472',
          is_anonymized: false,
          rating_benefits_and_perks: 3,
          rating_career_opportunity: 3,
          rating_company_overall: 2,
          rating_executive_management: 3,
          rating_work_environment: 3,
          rating_work_life_balance: 3,
          rating_diversity: 3,
          title: 'test',
          total_upvote: 16,
          upvoted_users: [],
          review_response: 'Some reply',
          review_response_by: 'Manager',
          review_response_foreign_user_id: '1',
          review_response_created_at: '2022-12-09T00:38:03.318Z',
          review_url: '/companies/1217901/company-reviews/1294472',
          review_created_at: '2017-04-07T03:05:21.000Z',
          company_recommended: true,
          salary_summary: 'fair',
        },
      ],
      paging: {
        page: 1,
        per_page: 10,
        total: 2,
        unfiltered_total: 2,
      },
    };

    return response;
  };

  const searchCompanies = async ({
    searchTerm,
    _page,
  }: {
    searchTerm: string;
    _page?: number;
  }): Promise<LegacySearchCompaniesResult> => ({
    data: [
      {
        company_name_slug: searchTerm,
        company_name: searchTerm,
        average_company_rating: 3.3,
        logo_url: '',
        reviews_count: 111,
      },
      {
        company_name_slug: 'seek',
        company_name: 'Seek',
        average_company_rating: 4.5,
        logo_url: '',
        reviews_count: 321,
      },
    ],
    paging: { page: 1, per_page: 10, total: 333 },
  });

  return {
    getCompanyReviews,
    getCompanyProfileForeignId,
    getLegacyCompanyProfile,
    getLegacyCompanyProfilePreview,
    searchCompanies,
  };
};
