import { adapter, getScriptTag } from '@seek/tealium-adapter';
import type { TrackingProps } from '@seek/tealium-adapter/lib-types/types';

import type { BaseConfig } from '..';
import type { Environment } from '../types';

enum TrackingEnvironment {
  STAGING = 'qa',
  PROD = 'prod',
}

export type ViewEventData = TrackingProps & {
  companyId?: string;
  companyName?: string;
  currentPage?: string;
  currentPageSection?: string;
};

export type LinkEventData = ViewEventData & {
  connectType?: string;
  linkName?: string;
  linkPosition?: string;
  linkText?: string;
  reviewType?: string;
  sortMode?: string;
};

const tracker = adapter();

const getTrackingEnvironment = (
  environment: Environment,
): TrackingEnvironment => {
  switch (environment) {
    case 'production':
      return TrackingEnvironment.PROD;
    case 'staging':
    default:
      return TrackingEnvironment.STAGING;
  }
};

export const initializeTrackingAdapter = (
  config: BaseConfig,
  profile: string,
) =>
  getScriptTag({
    account: 'seek',
    profile,
    environment: getTrackingEnvironment(config.environment),
  });

type EventName = string | string[];

const getTrackingData = (config: BaseConfig, data: LinkEventData) => ({
  ...data,
  siteCountry: config.country,
  siteLanguage: config.language,
  zone: config.zone,
  brand: config.brand,
});

export const sendLinkEvent = (
  eventName: EventName,
  config: BaseConfig,
  data: LinkEventData,
) => tracker.link(eventName, getTrackingData(config, data));

export const sendViewEvent = (
  eventName: EventName,
  config: BaseConfig,
  data: ViewEventData,
) => tracker.view(eventName, getTrackingData(config, data));
