import { createValidator } from '@seek/validators-js';

import translations from './.vocab';

export const { booleanRequired } = createValidator({
  test: ({ value }: { value?: boolean }) => value === true || value === false,
  formatErrorMessages: (messages) => () =>
    messages['Make a selection'].format(),
  translations,
  validatorName: 'booleanRequired',
});
