import { Box, Stack, Text } from 'braid-design-system';
import { useTranslations } from '@vocab/react';

import translations from './.vocab';
import { featuredImage } from './Featured.css';

import { Section } from '../../common/section/Section';
import { AspectRatioBox } from '../../common/AspectRatioBox/AspectRatioBox';
import { UpdateSuccessAlert, type Featured } from '../../../';

interface Props {
  mode: 'view' | 'edit';
  model?: Featured;
  onEditClick?: () => void;
  onHideUpdateSuccess?: () => void;
  showUpdateSuccess?: boolean;
}

export function FeaturedSection({
  mode,
  model,
  showUpdateSuccess,
  onEditClick,
  onHideUpdateSuccess,
}: Props) {
  const { t } = useTranslations(translations);

  if (mode === 'view' && !model) {
    return null;
  }

  const dataEmpty = mode === 'edit' && !model;

  return (
    <Section
      mode={mode}
      dataEmpty={dataEmpty}
      heading={t('Featured')}
      addDataHint={t(
        'Showcase something about your organisation that you want job seekers to know about.',
      )}
      onEditClick={onEditClick}
    >
      {!dataEmpty && (
        <Stack space="large">
          {showUpdateSuccess && (
            <UpdateSuccessAlert
              onClose={() => {
                if (onHideUpdateSuccess) onHideUpdateSuccess();
              }}
              text={t('Featured Updated')}
            />
          )}
          {model?.title ? <Text weight="medium">{model.title} </Text> : null}
          {model?.description?.length ? (
            <Stack space="gutter">
              {model.description.map((paragraph) => (
                <Text key={paragraph} tone="secondary">
                  {paragraph}
                </Text>
              ))}
            </Stack>
          ) : null}
          {model?.imageUrl ? (
            <Box
              component="img"
              src={model.imageUrl}
              className={featuredImage}
              alt={t('Featured banner image')}
            />
          ) : null}
          {model?.videoUrl ? (
            <AspectRatioBox aspectRatio="16:9">
              <iframe
                title={model.title}
                src={model.videoUrl}
                width="100%"
                height="100%"
                style={{ border: 0 }}
              />
            </AspectRatioBox>
          ) : null}
        </Stack>
      )}
    </Section>
  );
}
