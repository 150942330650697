import { Heading, Loader, Stack, Text, TextLink } from 'braid-design-system';

import { type ReviewRowModel } from '@seek/libs-shared';
import { useTranslations } from '@vocab/react';
import { CompanyProfilesPage } from '../../../..';
import translations from './.vocab';

interface Props {
  model: ReviewRowModel;
  isOnReviewPage?: boolean;
  isReplyReviewAccessCodeValid?: boolean;
  liteTitleMode?: boolean;
}

export const ReviewRowRatingDetails = ({
  model,
  liteTitleMode = false,
  isOnReviewPage = false,
  isReplyReviewAccessCodeValid = false,
}: Props) => {
  const { t } = useTranslations(translations);

  if (!model) {
    return <Loader />;
  }

  const { companyId, id, title, pros, cons } = model;

  const reviewLinkUrlPath = `/companies/${companyId}/${CompanyProfilesPage.Reviews}/${id}`;

  const onReviewLinkClick = (
    e: React.MouseEvent<HTMLAnchorElement, MouseEvent>,
  ) => {
    e.preventDefault();
    // todo
    // history.push(reviewLinkUrlPath, {
    //   shouldJumpToTop: true,
    // });
  };

  return (
    <Stack space="large">
      {liteTitleMode ? (
        <Text component="span" size="standard" weight="strong">
          {title}
        </Text>
      ) : (
        <Heading level="4" component="span">
          {isReplyReviewAccessCodeValid && !isOnReviewPage ? (
            <TextLink href={reviewLinkUrlPath} onClick={onReviewLinkClick}>
              {title}
            </TextLink>
          ) : (
            title
          )}
        </Heading>
      )}
      <Stack space="medium">
        <Text size="standard" weight="strong">
          {t('The good things')}
        </Text>
        <Text>{pros}</Text>
      </Stack>
      <Stack space="medium">
        <Text size="standard" weight="strong">
          {t('The challenges')}
        </Text>
        <Text>{cons}</Text>
      </Stack>
    </Stack>
  );
};
