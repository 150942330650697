import { gql } from '@apollo/client';

export const GET_COMPANY_REVIEWS_AI_SUMMARY_QUERY = gql`
  query CompanyReviewsAISummary($companyId: CompanyId!, $locale: Locale) {
    companyDetails(id: $companyId) {
      companyReviewsAISummary(locale: $locale) {
        reviewsCount
        reviewsDateFrom
        reviewsDateTo
        aISummaryContent {
          positiveAndChallengesSummary
        }
      }
    }
  }
`;
