
      import { createLanguage, createTranslationFile } from '@vocab/webpack/web';
      
    const translations = createTranslationFile({
      "en": createLanguage(
        require.resolveWeak("./translations.json-en-virtual.json!=!../../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJUaGUgZ29vZCB0aGluZ3MiOiJUaGUgZ29vZCB0aGluZ3MiLCJUaGUgY2hhbGxlbmdlcyI6IlRoZSBjaGFsbGVuZ2VzIiwiSGVscGZ1bD8iOiJIZWxwZnVsPyIsInBlb3BsZSI6InBlb3BsZSIsIlNhdmUiOiJTYXZlIiwiUmVwbHkiOiJSZXBseSIsIjEgcGVyc29uIjoiMSBwZXJzb24iLCJmb3VuZCB0aGlzIGhlbHBmdWwiOiJmb3VuZCB0aGlzIGhlbHBmdWwiLCJFeHBpcmVkIFJlcGx5IExpbmsiOiJFeHBpcmVkIFJlcGx5IExpbmsiLCJUaGlzIHJlcGx5IGxpbmsgaGFzIGV4cGlyZWQiOiJUaGlzIHJlcGx5IGxpbmsgaGFzIGV4cGlyZWQuIiwiVG8gZ2FpbiBhY2Nlc3MgdG8gYSB3b3JraW5nIHJlcGx5IGxpbmsiOiJUbyBnYWluIGFjY2VzcyB0byBhIHdvcmtpbmcgcmVwbHkgbGluaywgcGxlYXNlIDxUZXh0TGluaz5jb250YWN0IEN1c3RvbWVyIFNlcnZpY2U8L1RleHRMaW5rPiBvciBjYWxsIDxTdHJvbmc%2Be3Bob25lTnVtYmVyfTwvU3Ryb25nPi4iLCJSZXBvcnQgdGhpcyByZXZpZXciOiJSZXBvcnQgdGhpcyByZXZpZXcifQ%3D%3D!"),
        () => import(
          /* webpackChunkName: "en-translations" */
          "./translations.json-en-virtual.json!=!../../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJUaGUgZ29vZCB0aGluZ3MiOiJUaGUgZ29vZCB0aGluZ3MiLCJUaGUgY2hhbGxlbmdlcyI6IlRoZSBjaGFsbGVuZ2VzIiwiSGVscGZ1bD8iOiJIZWxwZnVsPyIsInBlb3BsZSI6InBlb3BsZSIsIlNhdmUiOiJTYXZlIiwiUmVwbHkiOiJSZXBseSIsIjEgcGVyc29uIjoiMSBwZXJzb24iLCJmb3VuZCB0aGlzIGhlbHBmdWwiOiJmb3VuZCB0aGlzIGhlbHBmdWwiLCJFeHBpcmVkIFJlcGx5IExpbmsiOiJFeHBpcmVkIFJlcGx5IExpbmsiLCJUaGlzIHJlcGx5IGxpbmsgaGFzIGV4cGlyZWQiOiJUaGlzIHJlcGx5IGxpbmsgaGFzIGV4cGlyZWQuIiwiVG8gZ2FpbiBhY2Nlc3MgdG8gYSB3b3JraW5nIHJlcGx5IGxpbmsiOiJUbyBnYWluIGFjY2VzcyB0byBhIHdvcmtpbmcgcmVwbHkgbGluaywgcGxlYXNlIDxUZXh0TGluaz5jb250YWN0IEN1c3RvbWVyIFNlcnZpY2U8L1RleHRMaW5rPiBvciBjYWxsIDxTdHJvbmc%2Be3Bob25lTnVtYmVyfTwvU3Ryb25nPi4iLCJSZXBvcnQgdGhpcyByZXZpZXciOiJSZXBvcnQgdGhpcyByZXZpZXcifQ%3D%3D!"
        )
      ),
"th": createLanguage(
        require.resolveWeak("./translations.json-th-virtual.json!=!../../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJUaGUgZ29vZCB0aGluZ3MiOiLguKrguLTguYjguIfguJfguLXguYjguJvguKPguLDguJfguLHguJrguYPguIgiLCJUaGUgY2hhbGxlbmdlcyI6IuC4hOC4p%2BC4suC4oeC4l%2BC5ieC4suC4l%2BC4suC4oiIsIkhlbHBmdWw%2FIjoi4Lih4Li14Lib4Lij4Liw4LmC4Lii4LiK4LiZ4LmMPyIsInBlb3BsZSI6IuC4hOC4mSIsIlNhdmUiOiLguJrguLHguJnguJfguLbguIEiLCJSZXBseSI6IuC4leC4reC4muC4geC4peC4seC4miIsIjEgcGVyc29uIjoiMSDguITguJkiLCJmb3VuZCB0aGlzIGhlbHBmdWwiOiLguKHguK3guIfguKfguYjguLLguKHguLXguJvguKPguLDguYLguKLguIrguJnguYwiLCJFeHBpcmVkIFJlcGx5IExpbmsiOiLguKXguLTguIfguIHguYzguJXguK3guJrguIHguKXguLHguJrguKvguKHguJTguK3guLLguKLguLgiLCJUaGlzIHJlcGx5IGxpbmsgaGFzIGV4cGlyZWQiOiLguKXguLTguIfguIHguYzguJXguK3guJrguIHguKXguLHguJrguJnguLXguYnguKvguKHguJTguK3guLLguKLguLjguYHguKXguYnguKciLCJUbyBnYWluIGFjY2VzcyB0byBhIHdvcmtpbmcgcmVwbHkgbGluayI6IuC5gOC4nuC4t%2BC5iOC4reC5gOC4guC5ieC4suC4luC4tuC4h%2BC4peC4tOC4h%2BC4geC5jOC4leC4reC4muC4geC4peC4seC4muC4l%2BC4teC5iOC5g%2BC4iuC5ieC4h%2BC4suC4meC5hOC4lOC5iSDguIHguKPguLjguJPguLI8VGV4dExpbms%2B4LiV4Li04LiU4LiV4LmI4Lit4Lid4LmI4Liy4Lii4Lia4Lij4Li04LiB4Liy4Lij4Lil4Li54LiB4LiE4LmJ4LiyPC9UZXh0TGluaz4g4Lir4Lij4Li34Lit4LmC4LiX4Lij4LmE4Lib4LiX4Li14LmI4Lir4Lih4Liy4Lii4LmA4Lil4LiCIDxTdHJvbmc%2Be3Bob25lTnVtYmVyfTwvU3Ryb25nPiIsIlJlcG9ydCB0aGlzIHJldmlldyI6IuC4o%2BC4suC4ouC4h%2BC4suC4meC4o%2BC4teC4p%2BC4tOC4p%2BC4meC4teC5iSJ9!"),
        () => import(
          /* webpackChunkName: "th-translations" */
          "./translations.json-th-virtual.json!=!../../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJUaGUgZ29vZCB0aGluZ3MiOiLguKrguLTguYjguIfguJfguLXguYjguJvguKPguLDguJfguLHguJrguYPguIgiLCJUaGUgY2hhbGxlbmdlcyI6IuC4hOC4p%2BC4suC4oeC4l%2BC5ieC4suC4l%2BC4suC4oiIsIkhlbHBmdWw%2FIjoi4Lih4Li14Lib4Lij4Liw4LmC4Lii4LiK4LiZ4LmMPyIsInBlb3BsZSI6IuC4hOC4mSIsIlNhdmUiOiLguJrguLHguJnguJfguLbguIEiLCJSZXBseSI6IuC4leC4reC4muC4geC4peC4seC4miIsIjEgcGVyc29uIjoiMSDguITguJkiLCJmb3VuZCB0aGlzIGhlbHBmdWwiOiLguKHguK3guIfguKfguYjguLLguKHguLXguJvguKPguLDguYLguKLguIrguJnguYwiLCJFeHBpcmVkIFJlcGx5IExpbmsiOiLguKXguLTguIfguIHguYzguJXguK3guJrguIHguKXguLHguJrguKvguKHguJTguK3guLLguKLguLgiLCJUaGlzIHJlcGx5IGxpbmsgaGFzIGV4cGlyZWQiOiLguKXguLTguIfguIHguYzguJXguK3guJrguIHguKXguLHguJrguJnguLXguYnguKvguKHguJTguK3guLLguKLguLjguYHguKXguYnguKciLCJUbyBnYWluIGFjY2VzcyB0byBhIHdvcmtpbmcgcmVwbHkgbGluayI6IuC5gOC4nuC4t%2BC5iOC4reC5gOC4guC5ieC4suC4luC4tuC4h%2BC4peC4tOC4h%2BC4geC5jOC4leC4reC4muC4geC4peC4seC4muC4l%2BC4teC5iOC5g%2BC4iuC5ieC4h%2BC4suC4meC5hOC4lOC5iSDguIHguKPguLjguJPguLI8VGV4dExpbms%2B4LiV4Li04LiU4LiV4LmI4Lit4Lid4LmI4Liy4Lii4Lia4Lij4Li04LiB4Liy4Lij4Lil4Li54LiB4LiE4LmJ4LiyPC9UZXh0TGluaz4g4Lir4Lij4Li34Lit4LmC4LiX4Lij4LmE4Lib4LiX4Li14LmI4Lir4Lih4Liy4Lii4LmA4Lil4LiCIDxTdHJvbmc%2Be3Bob25lTnVtYmVyfTwvU3Ryb25nPiIsIlJlcG9ydCB0aGlzIHJldmlldyI6IuC4o%2BC4suC4ouC4h%2BC4suC4meC4o%2BC4teC4p%2BC4tOC4p%2BC4meC4teC5iSJ9!"
        )
      ),
"id": createLanguage(
        require.resolveWeak("./translations.json-id-virtual.json!=!../../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJUaGUgZ29vZCB0aGluZ3MiOiJIYWwgeWFuZyBiYWlrIiwiVGhlIGNoYWxsZW5nZXMiOiJUYW50YW5nYW4iLCJIZWxwZnVsPyI6Ik1lbWJhbnR1PyIsInBlb3BsZSI6Im9yYW5nIiwiU2F2ZSI6IlNpbXBhbiIsIlJlcGx5IjoiSmF3YWIiLCIxIHBlcnNvbiI6IjEgb3JhbmciLCJmb3VuZCB0aGlzIGhlbHBmdWwiOiJtZW5nYW5nZ2FwIGluaSBtZW1iYW50dSIsIkV4cGlyZWQgUmVwbHkgTGluayI6IkxpbmsgQmFsYXNhbiBLZWRhbHV3YXJzYSIsIlRoaXMgcmVwbHkgbGluayBoYXMgZXhwaXJlZCI6IkxpbmsgYmFsYXNhbiBpbmkgdGVsYWgga2VkYWx1d2Fyc2EuIiwiVG8gZ2FpbiBhY2Nlc3MgdG8gYSB3b3JraW5nIHJlcGx5IGxpbmsiOiJVbnR1ayBtZW5nYWtzZXMgbGluayBiYWxhc2FuIHlhbmcgYWt0aWYsIHNpbGFrYW4gPFRleHRMaW5rPmh1YnVuZ2kgTGF5YW5hbiBQZWxhbmdnYW48L1RleHRMaW5rPiBhdGF1IHRlbGVwb24gPFN0cm9uZz57cGhvbmVOdW1iZXJ9PC9TdHJvbmc%2BLiIsIlJlcG9ydCB0aGlzIHJldmlldyI6IkxhcG9ya2FuIHVsYXNhbiBpbmkifQ%3D%3D!"),
        () => import(
          /* webpackChunkName: "id-translations" */
          "./translations.json-id-virtual.json!=!../../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJUaGUgZ29vZCB0aGluZ3MiOiJIYWwgeWFuZyBiYWlrIiwiVGhlIGNoYWxsZW5nZXMiOiJUYW50YW5nYW4iLCJIZWxwZnVsPyI6Ik1lbWJhbnR1PyIsInBlb3BsZSI6Im9yYW5nIiwiU2F2ZSI6IlNpbXBhbiIsIlJlcGx5IjoiSmF3YWIiLCIxIHBlcnNvbiI6IjEgb3JhbmciLCJmb3VuZCB0aGlzIGhlbHBmdWwiOiJtZW5nYW5nZ2FwIGluaSBtZW1iYW50dSIsIkV4cGlyZWQgUmVwbHkgTGluayI6IkxpbmsgQmFsYXNhbiBLZWRhbHV3YXJzYSIsIlRoaXMgcmVwbHkgbGluayBoYXMgZXhwaXJlZCI6IkxpbmsgYmFsYXNhbiBpbmkgdGVsYWgga2VkYWx1d2Fyc2EuIiwiVG8gZ2FpbiBhY2Nlc3MgdG8gYSB3b3JraW5nIHJlcGx5IGxpbmsiOiJVbnR1ayBtZW5nYWtzZXMgbGluayBiYWxhc2FuIHlhbmcgYWt0aWYsIHNpbGFrYW4gPFRleHRMaW5rPmh1YnVuZ2kgTGF5YW5hbiBQZWxhbmdnYW48L1RleHRMaW5rPiBhdGF1IHRlbGVwb24gPFN0cm9uZz57cGhvbmVOdW1iZXJ9PC9TdHJvbmc%2BLiIsIlJlcG9ydCB0aGlzIHJldmlldyI6IkxhcG9ya2FuIHVsYXNhbiBpbmkifQ%3D%3D!"
        )
      ),
"zh": createLanguage(
        require.resolveWeak("./translations.json-zh-virtual.json!=!../../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJUaGUgZ29vZCB0aGluZ3MiOiJUaGUgZ29vZCB0aGluZ3MiLCJUaGUgY2hhbGxlbmdlcyI6IlRoZSBjaGFsbGVuZ2VzIiwiSGVscGZ1bD8iOiJIZWxwZnVsPyIsInBlb3BsZSI6InBlb3BsZSIsIlNhdmUiOiJTYXZlIiwiUmVwbHkiOiJSZXBseSIsIjEgcGVyc29uIjoiMSBwZXJzb24iLCJmb3VuZCB0aGlzIGhlbHBmdWwiOiJmb3VuZCB0aGlzIGhlbHBmdWwiLCJFeHBpcmVkIFJlcGx5IExpbmsiOiJFeHBpcmVkIFJlcGx5IExpbmsiLCJUaGlzIHJlcGx5IGxpbmsgaGFzIGV4cGlyZWQiOiJUaGlzIHJlcGx5IGxpbmsgaGFzIGV4cGlyZWQuIiwiVG8gZ2FpbiBhY2Nlc3MgdG8gYSB3b3JraW5nIHJlcGx5IGxpbmsiOiJUbyBnYWluIGFjY2VzcyB0byBhIHdvcmtpbmcgcmVwbHkgbGluaywgcGxlYXNlIDxUZXh0TGluaz5jb250YWN0IEN1c3RvbWVyIFNlcnZpY2U8L1RleHRMaW5rPiBvciBjYWxsIDxTdHJvbmc%2Be3Bob25lTnVtYmVyfTwvU3Ryb25nPi4iLCJSZXBvcnQgdGhpcyByZXZpZXciOiJSZXBvcnQgdGhpcyByZXZpZXcifQ%3D%3D!"),
        () => import(
          /* webpackChunkName: "zh-translations" */
          "./translations.json-zh-virtual.json!=!../../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJUaGUgZ29vZCB0aGluZ3MiOiJUaGUgZ29vZCB0aGluZ3MiLCJUaGUgY2hhbGxlbmdlcyI6IlRoZSBjaGFsbGVuZ2VzIiwiSGVscGZ1bD8iOiJIZWxwZnVsPyIsInBlb3BsZSI6InBlb3BsZSIsIlNhdmUiOiJTYXZlIiwiUmVwbHkiOiJSZXBseSIsIjEgcGVyc29uIjoiMSBwZXJzb24iLCJmb3VuZCB0aGlzIGhlbHBmdWwiOiJmb3VuZCB0aGlzIGhlbHBmdWwiLCJFeHBpcmVkIFJlcGx5IExpbmsiOiJFeHBpcmVkIFJlcGx5IExpbmsiLCJUaGlzIHJlcGx5IGxpbmsgaGFzIGV4cGlyZWQiOiJUaGlzIHJlcGx5IGxpbmsgaGFzIGV4cGlyZWQuIiwiVG8gZ2FpbiBhY2Nlc3MgdG8gYSB3b3JraW5nIHJlcGx5IGxpbmsiOiJUbyBnYWluIGFjY2VzcyB0byBhIHdvcmtpbmcgcmVwbHkgbGluaywgcGxlYXNlIDxUZXh0TGluaz5jb250YWN0IEN1c3RvbWVyIFNlcnZpY2U8L1RleHRMaW5rPiBvciBjYWxsIDxTdHJvbmc%2Be3Bob25lTnVtYmVyfTwvU3Ryb25nPi4iLCJSZXBvcnQgdGhpcyByZXZpZXciOiJSZXBvcnQgdGhpcyByZXZpZXcifQ%3D%3D!"
        )
      ),
"en-AU": createLanguage(
        require.resolveWeak("./translations.json-en-AU-virtual.json!=!../../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJUaGUgZ29vZCB0aGluZ3MiOiJUaGUgZ29vZCB0aGluZ3MiLCJUaGUgY2hhbGxlbmdlcyI6IlRoZSBjaGFsbGVuZ2VzIiwiSGVscGZ1bD8iOiJIZWxwZnVsPyIsInBlb3BsZSI6InBlb3BsZSIsIlNhdmUiOiJTYXZlIiwiUmVwbHkiOiJSZXBseSIsIjEgcGVyc29uIjoiMSBwZXJzb24iLCJmb3VuZCB0aGlzIGhlbHBmdWwiOiJmb3VuZCB0aGlzIGhlbHBmdWwiLCJFeHBpcmVkIFJlcGx5IExpbmsiOiJFeHBpcmVkIFJlcGx5IExpbmsiLCJUaGlzIHJlcGx5IGxpbmsgaGFzIGV4cGlyZWQiOiJUaGlzIHJlcGx5IGxpbmsgaGFzIGV4cGlyZWQuIiwiVG8gZ2FpbiBhY2Nlc3MgdG8gYSB3b3JraW5nIHJlcGx5IGxpbmsiOiJUbyBnYWluIGFjY2VzcyB0byBhIHdvcmtpbmcgcmVwbHkgbGluaywgcGxlYXNlIDxUZXh0TGluaz5jb250YWN0IEN1c3RvbWVyIFNlcnZpY2U8L1RleHRMaW5rPiBvciBjYWxsIDxTdHJvbmc%2Be3Bob25lTnVtYmVyfTwvU3Ryb25nPi4iLCJSZXBvcnQgdGhpcyByZXZpZXciOiJSZXBvcnQgdGhpcyByZXZpZXcifQ%3D%3D!"),
        () => import(
          /* webpackChunkName: "en-AU-translations" */
          "./translations.json-en-AU-virtual.json!=!../../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJUaGUgZ29vZCB0aGluZ3MiOiJUaGUgZ29vZCB0aGluZ3MiLCJUaGUgY2hhbGxlbmdlcyI6IlRoZSBjaGFsbGVuZ2VzIiwiSGVscGZ1bD8iOiJIZWxwZnVsPyIsInBlb3BsZSI6InBlb3BsZSIsIlNhdmUiOiJTYXZlIiwiUmVwbHkiOiJSZXBseSIsIjEgcGVyc29uIjoiMSBwZXJzb24iLCJmb3VuZCB0aGlzIGhlbHBmdWwiOiJmb3VuZCB0aGlzIGhlbHBmdWwiLCJFeHBpcmVkIFJlcGx5IExpbmsiOiJFeHBpcmVkIFJlcGx5IExpbmsiLCJUaGlzIHJlcGx5IGxpbmsgaGFzIGV4cGlyZWQiOiJUaGlzIHJlcGx5IGxpbmsgaGFzIGV4cGlyZWQuIiwiVG8gZ2FpbiBhY2Nlc3MgdG8gYSB3b3JraW5nIHJlcGx5IGxpbmsiOiJUbyBnYWluIGFjY2VzcyB0byBhIHdvcmtpbmcgcmVwbHkgbGluaywgcGxlYXNlIDxUZXh0TGluaz5jb250YWN0IEN1c3RvbWVyIFNlcnZpY2U8L1RleHRMaW5rPiBvciBjYWxsIDxTdHJvbmc%2Be3Bob25lTnVtYmVyfTwvU3Ryb25nPi4iLCJSZXBvcnQgdGhpcyByZXZpZXciOiJSZXBvcnQgdGhpcyByZXZpZXcifQ%3D%3D!"
        )
      ),
"en-HK": createLanguage(
        require.resolveWeak("./translations.json-en-HK-virtual.json!=!../../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJUaGUgZ29vZCB0aGluZ3MiOiJUaGUgZ29vZCB0aGluZ3MiLCJUaGUgY2hhbGxlbmdlcyI6IlRoZSBjaGFsbGVuZ2VzIiwiSGVscGZ1bD8iOiJIZWxwZnVsPyIsInBlb3BsZSI6InBlb3BsZSIsIlNhdmUiOiJTYXZlIiwiUmVwbHkiOiJSZXBseSIsIjEgcGVyc29uIjoiMSBwZXJzb24iLCJmb3VuZCB0aGlzIGhlbHBmdWwiOiJmb3VuZCB0aGlzIGhlbHBmdWwiLCJFeHBpcmVkIFJlcGx5IExpbmsiOiJFeHBpcmVkIFJlcGx5IExpbmsiLCJUaGlzIHJlcGx5IGxpbmsgaGFzIGV4cGlyZWQiOiJUaGlzIHJlcGx5IGxpbmsgaGFzIGV4cGlyZWQuIiwiVG8gZ2FpbiBhY2Nlc3MgdG8gYSB3b3JraW5nIHJlcGx5IGxpbmsiOiJUbyBnYWluIGFjY2VzcyB0byBhIHdvcmtpbmcgcmVwbHkgbGluaywgcGxlYXNlIDxUZXh0TGluaz5jb250YWN0IEN1c3RvbWVyIFNlcnZpY2U8L1RleHRMaW5rPiBvciBjYWxsIDxTdHJvbmc%2Be3Bob25lTnVtYmVyfTwvU3Ryb25nPi4iLCJSZXBvcnQgdGhpcyByZXZpZXciOiJSZXBvcnQgdGhpcyByZXZpZXcifQ%3D%3D!"),
        () => import(
          /* webpackChunkName: "en-HK-translations" */
          "./translations.json-en-HK-virtual.json!=!../../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJUaGUgZ29vZCB0aGluZ3MiOiJUaGUgZ29vZCB0aGluZ3MiLCJUaGUgY2hhbGxlbmdlcyI6IlRoZSBjaGFsbGVuZ2VzIiwiSGVscGZ1bD8iOiJIZWxwZnVsPyIsInBlb3BsZSI6InBlb3BsZSIsIlNhdmUiOiJTYXZlIiwiUmVwbHkiOiJSZXBseSIsIjEgcGVyc29uIjoiMSBwZXJzb24iLCJmb3VuZCB0aGlzIGhlbHBmdWwiOiJmb3VuZCB0aGlzIGhlbHBmdWwiLCJFeHBpcmVkIFJlcGx5IExpbmsiOiJFeHBpcmVkIFJlcGx5IExpbmsiLCJUaGlzIHJlcGx5IGxpbmsgaGFzIGV4cGlyZWQiOiJUaGlzIHJlcGx5IGxpbmsgaGFzIGV4cGlyZWQuIiwiVG8gZ2FpbiBhY2Nlc3MgdG8gYSB3b3JraW5nIHJlcGx5IGxpbmsiOiJUbyBnYWluIGFjY2VzcyB0byBhIHdvcmtpbmcgcmVwbHkgbGluaywgcGxlYXNlIDxUZXh0TGluaz5jb250YWN0IEN1c3RvbWVyIFNlcnZpY2U8L1RleHRMaW5rPiBvciBjYWxsIDxTdHJvbmc%2Be3Bob25lTnVtYmVyfTwvU3Ryb25nPi4iLCJSZXBvcnQgdGhpcyByZXZpZXciOiJSZXBvcnQgdGhpcyByZXZpZXcifQ%3D%3D!"
        )
      ),
"en-ID": createLanguage(
        require.resolveWeak("./translations.json-en-ID-virtual.json!=!../../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJUaGUgZ29vZCB0aGluZ3MiOiJUaGUgZ29vZCB0aGluZ3MiLCJUaGUgY2hhbGxlbmdlcyI6IlRoZSBjaGFsbGVuZ2VzIiwiSGVscGZ1bD8iOiJIZWxwZnVsPyIsInBlb3BsZSI6InBlb3BsZSIsIlNhdmUiOiJTYXZlIiwiUmVwbHkiOiJSZXBseSIsIjEgcGVyc29uIjoiMSBwZXJzb24iLCJmb3VuZCB0aGlzIGhlbHBmdWwiOiJmb3VuZCB0aGlzIGhlbHBmdWwiLCJFeHBpcmVkIFJlcGx5IExpbmsiOiJFeHBpcmVkIFJlcGx5IExpbmsiLCJUaGlzIHJlcGx5IGxpbmsgaGFzIGV4cGlyZWQiOiJUaGlzIHJlcGx5IGxpbmsgaGFzIGV4cGlyZWQuIiwiVG8gZ2FpbiBhY2Nlc3MgdG8gYSB3b3JraW5nIHJlcGx5IGxpbmsiOiJUbyBnYWluIGFjY2VzcyB0byBhIHdvcmtpbmcgcmVwbHkgbGluaywgcGxlYXNlIDxUZXh0TGluaz5jb250YWN0IEN1c3RvbWVyIFNlcnZpY2U8L1RleHRMaW5rPiBvciBjYWxsIDxTdHJvbmc%2Be3Bob25lTnVtYmVyfTwvU3Ryb25nPi4iLCJSZXBvcnQgdGhpcyByZXZpZXciOiJSZXBvcnQgdGhpcyByZXZpZXcifQ%3D%3D!"),
        () => import(
          /* webpackChunkName: "en-ID-translations" */
          "./translations.json-en-ID-virtual.json!=!../../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJUaGUgZ29vZCB0aGluZ3MiOiJUaGUgZ29vZCB0aGluZ3MiLCJUaGUgY2hhbGxlbmdlcyI6IlRoZSBjaGFsbGVuZ2VzIiwiSGVscGZ1bD8iOiJIZWxwZnVsPyIsInBlb3BsZSI6InBlb3BsZSIsIlNhdmUiOiJTYXZlIiwiUmVwbHkiOiJSZXBseSIsIjEgcGVyc29uIjoiMSBwZXJzb24iLCJmb3VuZCB0aGlzIGhlbHBmdWwiOiJmb3VuZCB0aGlzIGhlbHBmdWwiLCJFeHBpcmVkIFJlcGx5IExpbmsiOiJFeHBpcmVkIFJlcGx5IExpbmsiLCJUaGlzIHJlcGx5IGxpbmsgaGFzIGV4cGlyZWQiOiJUaGlzIHJlcGx5IGxpbmsgaGFzIGV4cGlyZWQuIiwiVG8gZ2FpbiBhY2Nlc3MgdG8gYSB3b3JraW5nIHJlcGx5IGxpbmsiOiJUbyBnYWluIGFjY2VzcyB0byBhIHdvcmtpbmcgcmVwbHkgbGluaywgcGxlYXNlIDxUZXh0TGluaz5jb250YWN0IEN1c3RvbWVyIFNlcnZpY2U8L1RleHRMaW5rPiBvciBjYWxsIDxTdHJvbmc%2Be3Bob25lTnVtYmVyfTwvU3Ryb25nPi4iLCJSZXBvcnQgdGhpcyByZXZpZXciOiJSZXBvcnQgdGhpcyByZXZpZXcifQ%3D%3D!"
        )
      ),
"en-MY": createLanguage(
        require.resolveWeak("./translations.json-en-MY-virtual.json!=!../../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJUaGUgZ29vZCB0aGluZ3MiOiJUaGUgZ29vZCB0aGluZ3MiLCJUaGUgY2hhbGxlbmdlcyI6IlRoZSBjaGFsbGVuZ2VzIiwiSGVscGZ1bD8iOiJIZWxwZnVsPyIsInBlb3BsZSI6InBlb3BsZSIsIlNhdmUiOiJTYXZlIiwiUmVwbHkiOiJSZXBseSIsIjEgcGVyc29uIjoiMSBwZXJzb24iLCJmb3VuZCB0aGlzIGhlbHBmdWwiOiJmb3VuZCB0aGlzIGhlbHBmdWwiLCJFeHBpcmVkIFJlcGx5IExpbmsiOiJFeHBpcmVkIFJlcGx5IExpbmsiLCJUaGlzIHJlcGx5IGxpbmsgaGFzIGV4cGlyZWQiOiJUaGlzIHJlcGx5IGxpbmsgaGFzIGV4cGlyZWQuIiwiVG8gZ2FpbiBhY2Nlc3MgdG8gYSB3b3JraW5nIHJlcGx5IGxpbmsiOiJUbyBnYWluIGFjY2VzcyB0byBhIHdvcmtpbmcgcmVwbHkgbGluaywgcGxlYXNlIDxUZXh0TGluaz5jb250YWN0IEN1c3RvbWVyIFNlcnZpY2U8L1RleHRMaW5rPiBvciBjYWxsIDxTdHJvbmc%2Be3Bob25lTnVtYmVyfTwvU3Ryb25nPi4iLCJSZXBvcnQgdGhpcyByZXZpZXciOiJSZXBvcnQgdGhpcyByZXZpZXcifQ%3D%3D!"),
        () => import(
          /* webpackChunkName: "en-MY-translations" */
          "./translations.json-en-MY-virtual.json!=!../../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJUaGUgZ29vZCB0aGluZ3MiOiJUaGUgZ29vZCB0aGluZ3MiLCJUaGUgY2hhbGxlbmdlcyI6IlRoZSBjaGFsbGVuZ2VzIiwiSGVscGZ1bD8iOiJIZWxwZnVsPyIsInBlb3BsZSI6InBlb3BsZSIsIlNhdmUiOiJTYXZlIiwiUmVwbHkiOiJSZXBseSIsIjEgcGVyc29uIjoiMSBwZXJzb24iLCJmb3VuZCB0aGlzIGhlbHBmdWwiOiJmb3VuZCB0aGlzIGhlbHBmdWwiLCJFeHBpcmVkIFJlcGx5IExpbmsiOiJFeHBpcmVkIFJlcGx5IExpbmsiLCJUaGlzIHJlcGx5IGxpbmsgaGFzIGV4cGlyZWQiOiJUaGlzIHJlcGx5IGxpbmsgaGFzIGV4cGlyZWQuIiwiVG8gZ2FpbiBhY2Nlc3MgdG8gYSB3b3JraW5nIHJlcGx5IGxpbmsiOiJUbyBnYWluIGFjY2VzcyB0byBhIHdvcmtpbmcgcmVwbHkgbGluaywgcGxlYXNlIDxUZXh0TGluaz5jb250YWN0IEN1c3RvbWVyIFNlcnZpY2U8L1RleHRMaW5rPiBvciBjYWxsIDxTdHJvbmc%2Be3Bob25lTnVtYmVyfTwvU3Ryb25nPi4iLCJSZXBvcnQgdGhpcyByZXZpZXciOiJSZXBvcnQgdGhpcyByZXZpZXcifQ%3D%3D!"
        )
      ),
"en-NZ": createLanguage(
        require.resolveWeak("./translations.json-en-NZ-virtual.json!=!../../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJUaGUgZ29vZCB0aGluZ3MiOiJUaGUgZ29vZCB0aGluZ3MiLCJUaGUgY2hhbGxlbmdlcyI6IlRoZSBjaGFsbGVuZ2VzIiwiSGVscGZ1bD8iOiJIZWxwZnVsPyIsInBlb3BsZSI6InBlb3BsZSIsIlNhdmUiOiJTYXZlIiwiUmVwbHkiOiJSZXBseSIsIjEgcGVyc29uIjoiMSBwZXJzb24iLCJmb3VuZCB0aGlzIGhlbHBmdWwiOiJmb3VuZCB0aGlzIGhlbHBmdWwiLCJFeHBpcmVkIFJlcGx5IExpbmsiOiJFeHBpcmVkIFJlcGx5IExpbmsiLCJUaGlzIHJlcGx5IGxpbmsgaGFzIGV4cGlyZWQiOiJUaGlzIHJlcGx5IGxpbmsgaGFzIGV4cGlyZWQuIiwiVG8gZ2FpbiBhY2Nlc3MgdG8gYSB3b3JraW5nIHJlcGx5IGxpbmsiOiJUbyBnYWluIGFjY2VzcyB0byBhIHdvcmtpbmcgcmVwbHkgbGluaywgcGxlYXNlIDxUZXh0TGluaz5jb250YWN0IEN1c3RvbWVyIFNlcnZpY2U8L1RleHRMaW5rPiBvciBjYWxsIDxTdHJvbmc%2Be3Bob25lTnVtYmVyfTwvU3Ryb25nPi4iLCJSZXBvcnQgdGhpcyByZXZpZXciOiJSZXBvcnQgdGhpcyByZXZpZXcifQ%3D%3D!"),
        () => import(
          /* webpackChunkName: "en-NZ-translations" */
          "./translations.json-en-NZ-virtual.json!=!../../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJUaGUgZ29vZCB0aGluZ3MiOiJUaGUgZ29vZCB0aGluZ3MiLCJUaGUgY2hhbGxlbmdlcyI6IlRoZSBjaGFsbGVuZ2VzIiwiSGVscGZ1bD8iOiJIZWxwZnVsPyIsInBlb3BsZSI6InBlb3BsZSIsIlNhdmUiOiJTYXZlIiwiUmVwbHkiOiJSZXBseSIsIjEgcGVyc29uIjoiMSBwZXJzb24iLCJmb3VuZCB0aGlzIGhlbHBmdWwiOiJmb3VuZCB0aGlzIGhlbHBmdWwiLCJFeHBpcmVkIFJlcGx5IExpbmsiOiJFeHBpcmVkIFJlcGx5IExpbmsiLCJUaGlzIHJlcGx5IGxpbmsgaGFzIGV4cGlyZWQiOiJUaGlzIHJlcGx5IGxpbmsgaGFzIGV4cGlyZWQuIiwiVG8gZ2FpbiBhY2Nlc3MgdG8gYSB3b3JraW5nIHJlcGx5IGxpbmsiOiJUbyBnYWluIGFjY2VzcyB0byBhIHdvcmtpbmcgcmVwbHkgbGluaywgcGxlYXNlIDxUZXh0TGluaz5jb250YWN0IEN1c3RvbWVyIFNlcnZpY2U8L1RleHRMaW5rPiBvciBjYWxsIDxTdHJvbmc%2Be3Bob25lTnVtYmVyfTwvU3Ryb25nPi4iLCJSZXBvcnQgdGhpcyByZXZpZXciOiJSZXBvcnQgdGhpcyByZXZpZXcifQ%3D%3D!"
        )
      ),
"en-PH": createLanguage(
        require.resolveWeak("./translations.json-en-PH-virtual.json!=!../../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJUaGUgZ29vZCB0aGluZ3MiOiJUaGUgZ29vZCB0aGluZ3MiLCJUaGUgY2hhbGxlbmdlcyI6IlRoZSBjaGFsbGVuZ2VzIiwiSGVscGZ1bD8iOiJIZWxwZnVsPyIsInBlb3BsZSI6InBlb3BsZSIsIlNhdmUiOiJTYXZlIiwiUmVwbHkiOiJSZXBseSIsIjEgcGVyc29uIjoiMSBwZXJzb24iLCJmb3VuZCB0aGlzIGhlbHBmdWwiOiJmb3VuZCB0aGlzIGhlbHBmdWwiLCJFeHBpcmVkIFJlcGx5IExpbmsiOiJFeHBpcmVkIFJlcGx5IExpbmsiLCJUaGlzIHJlcGx5IGxpbmsgaGFzIGV4cGlyZWQiOiJUaGlzIHJlcGx5IGxpbmsgaGFzIGV4cGlyZWQuIiwiVG8gZ2FpbiBhY2Nlc3MgdG8gYSB3b3JraW5nIHJlcGx5IGxpbmsiOiJUbyBnYWluIGFjY2VzcyB0byBhIHdvcmtpbmcgcmVwbHkgbGluaywgcGxlYXNlIDxUZXh0TGluaz5jb250YWN0IEN1c3RvbWVyIFNlcnZpY2U8L1RleHRMaW5rPiBvciBjYWxsIDxTdHJvbmc%2Be3Bob25lTnVtYmVyfTwvU3Ryb25nPi4iLCJSZXBvcnQgdGhpcyByZXZpZXciOiJSZXBvcnQgdGhpcyByZXZpZXcifQ%3D%3D!"),
        () => import(
          /* webpackChunkName: "en-PH-translations" */
          "./translations.json-en-PH-virtual.json!=!../../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJUaGUgZ29vZCB0aGluZ3MiOiJUaGUgZ29vZCB0aGluZ3MiLCJUaGUgY2hhbGxlbmdlcyI6IlRoZSBjaGFsbGVuZ2VzIiwiSGVscGZ1bD8iOiJIZWxwZnVsPyIsInBlb3BsZSI6InBlb3BsZSIsIlNhdmUiOiJTYXZlIiwiUmVwbHkiOiJSZXBseSIsIjEgcGVyc29uIjoiMSBwZXJzb24iLCJmb3VuZCB0aGlzIGhlbHBmdWwiOiJmb3VuZCB0aGlzIGhlbHBmdWwiLCJFeHBpcmVkIFJlcGx5IExpbmsiOiJFeHBpcmVkIFJlcGx5IExpbmsiLCJUaGlzIHJlcGx5IGxpbmsgaGFzIGV4cGlyZWQiOiJUaGlzIHJlcGx5IGxpbmsgaGFzIGV4cGlyZWQuIiwiVG8gZ2FpbiBhY2Nlc3MgdG8gYSB3b3JraW5nIHJlcGx5IGxpbmsiOiJUbyBnYWluIGFjY2VzcyB0byBhIHdvcmtpbmcgcmVwbHkgbGluaywgcGxlYXNlIDxUZXh0TGluaz5jb250YWN0IEN1c3RvbWVyIFNlcnZpY2U8L1RleHRMaW5rPiBvciBjYWxsIDxTdHJvbmc%2Be3Bob25lTnVtYmVyfTwvU3Ryb25nPi4iLCJSZXBvcnQgdGhpcyByZXZpZXciOiJSZXBvcnQgdGhpcyByZXZpZXcifQ%3D%3D!"
        )
      ),
"en-SG": createLanguage(
        require.resolveWeak("./translations.json-en-SG-virtual.json!=!../../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJUaGUgZ29vZCB0aGluZ3MiOiJUaGUgZ29vZCB0aGluZ3MiLCJUaGUgY2hhbGxlbmdlcyI6IlRoZSBjaGFsbGVuZ2VzIiwiSGVscGZ1bD8iOiJIZWxwZnVsPyIsInBlb3BsZSI6InBlb3BsZSIsIlNhdmUiOiJTYXZlIiwiUmVwbHkiOiJSZXBseSIsIjEgcGVyc29uIjoiMSBwZXJzb24iLCJmb3VuZCB0aGlzIGhlbHBmdWwiOiJmb3VuZCB0aGlzIGhlbHBmdWwiLCJFeHBpcmVkIFJlcGx5IExpbmsiOiJFeHBpcmVkIFJlcGx5IExpbmsiLCJUaGlzIHJlcGx5IGxpbmsgaGFzIGV4cGlyZWQiOiJUaGlzIHJlcGx5IGxpbmsgaGFzIGV4cGlyZWQuIiwiVG8gZ2FpbiBhY2Nlc3MgdG8gYSB3b3JraW5nIHJlcGx5IGxpbmsiOiJUbyBnYWluIGFjY2VzcyB0byBhIHdvcmtpbmcgcmVwbHkgbGluaywgcGxlYXNlIDxUZXh0TGluaz5jb250YWN0IEN1c3RvbWVyIFNlcnZpY2U8L1RleHRMaW5rPiBvciBjYWxsIDxTdHJvbmc%2Be3Bob25lTnVtYmVyfTwvU3Ryb25nPi4iLCJSZXBvcnQgdGhpcyByZXZpZXciOiJSZXBvcnQgdGhpcyByZXZpZXcifQ%3D%3D!"),
        () => import(
          /* webpackChunkName: "en-SG-translations" */
          "./translations.json-en-SG-virtual.json!=!../../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJUaGUgZ29vZCB0aGluZ3MiOiJUaGUgZ29vZCB0aGluZ3MiLCJUaGUgY2hhbGxlbmdlcyI6IlRoZSBjaGFsbGVuZ2VzIiwiSGVscGZ1bD8iOiJIZWxwZnVsPyIsInBlb3BsZSI6InBlb3BsZSIsIlNhdmUiOiJTYXZlIiwiUmVwbHkiOiJSZXBseSIsIjEgcGVyc29uIjoiMSBwZXJzb24iLCJmb3VuZCB0aGlzIGhlbHBmdWwiOiJmb3VuZCB0aGlzIGhlbHBmdWwiLCJFeHBpcmVkIFJlcGx5IExpbmsiOiJFeHBpcmVkIFJlcGx5IExpbmsiLCJUaGlzIHJlcGx5IGxpbmsgaGFzIGV4cGlyZWQiOiJUaGlzIHJlcGx5IGxpbmsgaGFzIGV4cGlyZWQuIiwiVG8gZ2FpbiBhY2Nlc3MgdG8gYSB3b3JraW5nIHJlcGx5IGxpbmsiOiJUbyBnYWluIGFjY2VzcyB0byBhIHdvcmtpbmcgcmVwbHkgbGluaywgcGxlYXNlIDxUZXh0TGluaz5jb250YWN0IEN1c3RvbWVyIFNlcnZpY2U8L1RleHRMaW5rPiBvciBjYWxsIDxTdHJvbmc%2Be3Bob25lTnVtYmVyfTwvU3Ryb25nPi4iLCJSZXBvcnQgdGhpcyByZXZpZXciOiJSZXBvcnQgdGhpcyByZXZpZXcifQ%3D%3D!"
        )
      ),
"en-TH": createLanguage(
        require.resolveWeak("./translations.json-en-TH-virtual.json!=!../../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJUaGUgZ29vZCB0aGluZ3MiOiJUaGUgZ29vZCB0aGluZ3MiLCJUaGUgY2hhbGxlbmdlcyI6IlRoZSBjaGFsbGVuZ2VzIiwiSGVscGZ1bD8iOiJIZWxwZnVsPyIsInBlb3BsZSI6InBlb3BsZSIsIlNhdmUiOiJTYXZlIiwiUmVwbHkiOiJSZXBseSIsIjEgcGVyc29uIjoiMSBwZXJzb24iLCJmb3VuZCB0aGlzIGhlbHBmdWwiOiJmb3VuZCB0aGlzIGhlbHBmdWwiLCJFeHBpcmVkIFJlcGx5IExpbmsiOiJFeHBpcmVkIFJlcGx5IExpbmsiLCJUaGlzIHJlcGx5IGxpbmsgaGFzIGV4cGlyZWQiOiJUaGlzIHJlcGx5IGxpbmsgaGFzIGV4cGlyZWQuIiwiVG8gZ2FpbiBhY2Nlc3MgdG8gYSB3b3JraW5nIHJlcGx5IGxpbmsiOiJUbyBnYWluIGFjY2VzcyB0byBhIHdvcmtpbmcgcmVwbHkgbGluaywgcGxlYXNlIDxUZXh0TGluaz5jb250YWN0IEN1c3RvbWVyIFNlcnZpY2U8L1RleHRMaW5rPiBvciBjYWxsIDxTdHJvbmc%2Be3Bob25lTnVtYmVyfTwvU3Ryb25nPi4iLCJSZXBvcnQgdGhpcyByZXZpZXciOiJSZXBvcnQgdGhpcyByZXZpZXcifQ%3D%3D!"),
        () => import(
          /* webpackChunkName: "en-TH-translations" */
          "./translations.json-en-TH-virtual.json!=!../../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJUaGUgZ29vZCB0aGluZ3MiOiJUaGUgZ29vZCB0aGluZ3MiLCJUaGUgY2hhbGxlbmdlcyI6IlRoZSBjaGFsbGVuZ2VzIiwiSGVscGZ1bD8iOiJIZWxwZnVsPyIsInBlb3BsZSI6InBlb3BsZSIsIlNhdmUiOiJTYXZlIiwiUmVwbHkiOiJSZXBseSIsIjEgcGVyc29uIjoiMSBwZXJzb24iLCJmb3VuZCB0aGlzIGhlbHBmdWwiOiJmb3VuZCB0aGlzIGhlbHBmdWwiLCJFeHBpcmVkIFJlcGx5IExpbmsiOiJFeHBpcmVkIFJlcGx5IExpbmsiLCJUaGlzIHJlcGx5IGxpbmsgaGFzIGV4cGlyZWQiOiJUaGlzIHJlcGx5IGxpbmsgaGFzIGV4cGlyZWQuIiwiVG8gZ2FpbiBhY2Nlc3MgdG8gYSB3b3JraW5nIHJlcGx5IGxpbmsiOiJUbyBnYWluIGFjY2VzcyB0byBhIHdvcmtpbmcgcmVwbHkgbGluaywgcGxlYXNlIDxUZXh0TGluaz5jb250YWN0IEN1c3RvbWVyIFNlcnZpY2U8L1RleHRMaW5rPiBvciBjYWxsIDxTdHJvbmc%2Be3Bob25lTnVtYmVyfTwvU3Ryb25nPi4iLCJSZXBvcnQgdGhpcyByZXZpZXciOiJSZXBvcnQgdGhpcyByZXZpZXcifQ%3D%3D!"
        )
      ),
"th-TH": createLanguage(
        require.resolveWeak("./translations.json-th-TH-virtual.json!=!../../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJUaGUgZ29vZCB0aGluZ3MiOiLguKrguLTguYjguIfguJfguLXguYjguJvguKPguLDguJfguLHguJrguYPguIgiLCJUaGUgY2hhbGxlbmdlcyI6IuC4hOC4p%2BC4suC4oeC4l%2BC5ieC4suC4l%2BC4suC4oiIsIkhlbHBmdWw%2FIjoi4Lih4Li14Lib4Lij4Liw4LmC4Lii4LiK4LiZ4LmMPyIsInBlb3BsZSI6IuC4hOC4mSIsIlNhdmUiOiLguJrguLHguJnguJfguLbguIEiLCJSZXBseSI6IuC4leC4reC4muC4geC4peC4seC4miIsIjEgcGVyc29uIjoiMSDguITguJkiLCJmb3VuZCB0aGlzIGhlbHBmdWwiOiLguKHguK3guIfguKfguYjguLLguKHguLXguJvguKPguLDguYLguKLguIrguJnguYwiLCJFeHBpcmVkIFJlcGx5IExpbmsiOiLguKXguLTguIfguIHguYzguJXguK3guJrguIHguKXguLHguJrguKvguKHguJTguK3guLLguKLguLgiLCJUaGlzIHJlcGx5IGxpbmsgaGFzIGV4cGlyZWQiOiLguKXguLTguIfguIHguYzguJXguK3guJrguIHguKXguLHguJrguJnguLXguYnguKvguKHguJTguK3guLLguKLguLjguYHguKXguYnguKciLCJUbyBnYWluIGFjY2VzcyB0byBhIHdvcmtpbmcgcmVwbHkgbGluayI6IuC5gOC4nuC4t%2BC5iOC4reC5gOC4guC5ieC4suC4luC4tuC4h%2BC4peC4tOC4h%2BC4geC5jOC4leC4reC4muC4geC4peC4seC4muC4l%2BC4teC5iOC5g%2BC4iuC5ieC4h%2BC4suC4meC5hOC4lOC5iSDguIHguKPguLjguJPguLI8VGV4dExpbms%2B4LiV4Li04LiU4LiV4LmI4Lit4Lid4LmI4Liy4Lii4Lia4Lij4Li04LiB4Liy4Lij4Lil4Li54LiB4LiE4LmJ4LiyPC9UZXh0TGluaz4g4Lir4Lij4Li34Lit4LmC4LiX4Lij4LmE4Lib4LiX4Li14LmI4Lir4Lih4Liy4Lii4LmA4Lil4LiCIDxTdHJvbmc%2Be3Bob25lTnVtYmVyfTwvU3Ryb25nPiIsIlJlcG9ydCB0aGlzIHJldmlldyI6IuC4o%2BC4suC4ouC4h%2BC4suC4meC4o%2BC4teC4p%2BC4tOC4p%2BC4meC4teC5iSJ9!"),
        () => import(
          /* webpackChunkName: "th-TH-translations" */
          "./translations.json-th-TH-virtual.json!=!../../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJUaGUgZ29vZCB0aGluZ3MiOiLguKrguLTguYjguIfguJfguLXguYjguJvguKPguLDguJfguLHguJrguYPguIgiLCJUaGUgY2hhbGxlbmdlcyI6IuC4hOC4p%2BC4suC4oeC4l%2BC5ieC4suC4l%2BC4suC4oiIsIkhlbHBmdWw%2FIjoi4Lih4Li14Lib4Lij4Liw4LmC4Lii4LiK4LiZ4LmMPyIsInBlb3BsZSI6IuC4hOC4mSIsIlNhdmUiOiLguJrguLHguJnguJfguLbguIEiLCJSZXBseSI6IuC4leC4reC4muC4geC4peC4seC4miIsIjEgcGVyc29uIjoiMSDguITguJkiLCJmb3VuZCB0aGlzIGhlbHBmdWwiOiLguKHguK3guIfguKfguYjguLLguKHguLXguJvguKPguLDguYLguKLguIrguJnguYwiLCJFeHBpcmVkIFJlcGx5IExpbmsiOiLguKXguLTguIfguIHguYzguJXguK3guJrguIHguKXguLHguJrguKvguKHguJTguK3guLLguKLguLgiLCJUaGlzIHJlcGx5IGxpbmsgaGFzIGV4cGlyZWQiOiLguKXguLTguIfguIHguYzguJXguK3guJrguIHguKXguLHguJrguJnguLXguYnguKvguKHguJTguK3guLLguKLguLjguYHguKXguYnguKciLCJUbyBnYWluIGFjY2VzcyB0byBhIHdvcmtpbmcgcmVwbHkgbGluayI6IuC5gOC4nuC4t%2BC5iOC4reC5gOC4guC5ieC4suC4luC4tuC4h%2BC4peC4tOC4h%2BC4geC5jOC4leC4reC4muC4geC4peC4seC4muC4l%2BC4teC5iOC5g%2BC4iuC5ieC4h%2BC4suC4meC5hOC4lOC5iSDguIHguKPguLjguJPguLI8VGV4dExpbms%2B4LiV4Li04LiU4LiV4LmI4Lit4Lid4LmI4Liy4Lii4Lia4Lij4Li04LiB4Liy4Lij4Lil4Li54LiB4LiE4LmJ4LiyPC9UZXh0TGluaz4g4Lir4Lij4Li34Lit4LmC4LiX4Lij4LmE4Lib4LiX4Li14LmI4Lir4Lih4Liy4Lii4LmA4Lil4LiCIDxTdHJvbmc%2Be3Bob25lTnVtYmVyfTwvU3Ryb25nPiIsIlJlcG9ydCB0aGlzIHJldmlldyI6IuC4o%2BC4suC4ouC4h%2BC4suC4meC4o%2BC4teC4p%2BC4tOC4p%2BC4meC4teC5iSJ9!"
        )
      ),
"id-ID": createLanguage(
        require.resolveWeak("./translations.json-id-ID-virtual.json!=!../../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJUaGUgZ29vZCB0aGluZ3MiOiJIYWwgeWFuZyBiYWlrIiwiVGhlIGNoYWxsZW5nZXMiOiJUYW50YW5nYW4iLCJIZWxwZnVsPyI6Ik1lbWJhbnR1PyIsInBlb3BsZSI6Im9yYW5nIiwiU2F2ZSI6IlNpbXBhbiIsIlJlcGx5IjoiSmF3YWIiLCIxIHBlcnNvbiI6IjEgb3JhbmciLCJmb3VuZCB0aGlzIGhlbHBmdWwiOiJtZW5nYW5nZ2FwIGluaSBtZW1iYW50dSIsIkV4cGlyZWQgUmVwbHkgTGluayI6IkxpbmsgQmFsYXNhbiBLZWRhbHV3YXJzYSIsIlRoaXMgcmVwbHkgbGluayBoYXMgZXhwaXJlZCI6IkxpbmsgYmFsYXNhbiBpbmkgdGVsYWgga2VkYWx1d2Fyc2EuIiwiVG8gZ2FpbiBhY2Nlc3MgdG8gYSB3b3JraW5nIHJlcGx5IGxpbmsiOiJVbnR1ayBtZW5nYWtzZXMgbGluayBiYWxhc2FuIHlhbmcgYWt0aWYsIHNpbGFrYW4gPFRleHRMaW5rPmh1YnVuZ2kgTGF5YW5hbiBQZWxhbmdnYW48L1RleHRMaW5rPiBhdGF1IHRlbGVwb24gPFN0cm9uZz57cGhvbmVOdW1iZXJ9PC9TdHJvbmc%2BLiIsIlJlcG9ydCB0aGlzIHJldmlldyI6IkxhcG9ya2FuIHVsYXNhbiBpbmkifQ%3D%3D!"),
        () => import(
          /* webpackChunkName: "id-ID-translations" */
          "./translations.json-id-ID-virtual.json!=!../../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJUaGUgZ29vZCB0aGluZ3MiOiJIYWwgeWFuZyBiYWlrIiwiVGhlIGNoYWxsZW5nZXMiOiJUYW50YW5nYW4iLCJIZWxwZnVsPyI6Ik1lbWJhbnR1PyIsInBlb3BsZSI6Im9yYW5nIiwiU2F2ZSI6IlNpbXBhbiIsIlJlcGx5IjoiSmF3YWIiLCIxIHBlcnNvbiI6IjEgb3JhbmciLCJmb3VuZCB0aGlzIGhlbHBmdWwiOiJtZW5nYW5nZ2FwIGluaSBtZW1iYW50dSIsIkV4cGlyZWQgUmVwbHkgTGluayI6IkxpbmsgQmFsYXNhbiBLZWRhbHV3YXJzYSIsIlRoaXMgcmVwbHkgbGluayBoYXMgZXhwaXJlZCI6IkxpbmsgYmFsYXNhbiBpbmkgdGVsYWgga2VkYWx1d2Fyc2EuIiwiVG8gZ2FpbiBhY2Nlc3MgdG8gYSB3b3JraW5nIHJlcGx5IGxpbmsiOiJVbnR1ayBtZW5nYWtzZXMgbGluayBiYWxhc2FuIHlhbmcgYWt0aWYsIHNpbGFrYW4gPFRleHRMaW5rPmh1YnVuZ2kgTGF5YW5hbiBQZWxhbmdnYW48L1RleHRMaW5rPiBhdGF1IHRlbGVwb24gPFN0cm9uZz57cGhvbmVOdW1iZXJ9PC9TdHJvbmc%2BLiIsIlJlcG9ydCB0aGlzIHJldmlldyI6IkxhcG9ya2FuIHVsYXNhbiBpbmkifQ%3D%3D!"
        )
      ),
"zh-HK": createLanguage(
        require.resolveWeak("./translations.json-zh-HK-virtual.json!=!../../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJUaGUgZ29vZCB0aGluZ3MiOiJUaGUgZ29vZCB0aGluZ3MiLCJUaGUgY2hhbGxlbmdlcyI6IlRoZSBjaGFsbGVuZ2VzIiwiSGVscGZ1bD8iOiJIZWxwZnVsPyIsInBlb3BsZSI6InBlb3BsZSIsIlNhdmUiOiJTYXZlIiwiUmVwbHkiOiJSZXBseSIsIjEgcGVyc29uIjoiMSBwZXJzb24iLCJmb3VuZCB0aGlzIGhlbHBmdWwiOiJmb3VuZCB0aGlzIGhlbHBmdWwiLCJFeHBpcmVkIFJlcGx5IExpbmsiOiJFeHBpcmVkIFJlcGx5IExpbmsiLCJUaGlzIHJlcGx5IGxpbmsgaGFzIGV4cGlyZWQiOiJUaGlzIHJlcGx5IGxpbmsgaGFzIGV4cGlyZWQuIiwiVG8gZ2FpbiBhY2Nlc3MgdG8gYSB3b3JraW5nIHJlcGx5IGxpbmsiOiJUbyBnYWluIGFjY2VzcyB0byBhIHdvcmtpbmcgcmVwbHkgbGluaywgcGxlYXNlIDxUZXh0TGluaz5jb250YWN0IEN1c3RvbWVyIFNlcnZpY2U8L1RleHRMaW5rPiBvciBjYWxsIDxTdHJvbmc%2Be3Bob25lTnVtYmVyfTwvU3Ryb25nPi4iLCJSZXBvcnQgdGhpcyByZXZpZXciOiJSZXBvcnQgdGhpcyByZXZpZXcifQ%3D%3D!"),
        () => import(
          /* webpackChunkName: "zh-HK-translations" */
          "./translations.json-zh-HK-virtual.json!=!../../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJUaGUgZ29vZCB0aGluZ3MiOiJUaGUgZ29vZCB0aGluZ3MiLCJUaGUgY2hhbGxlbmdlcyI6IlRoZSBjaGFsbGVuZ2VzIiwiSGVscGZ1bD8iOiJIZWxwZnVsPyIsInBlb3BsZSI6InBlb3BsZSIsIlNhdmUiOiJTYXZlIiwiUmVwbHkiOiJSZXBseSIsIjEgcGVyc29uIjoiMSBwZXJzb24iLCJmb3VuZCB0aGlzIGhlbHBmdWwiOiJmb3VuZCB0aGlzIGhlbHBmdWwiLCJFeHBpcmVkIFJlcGx5IExpbmsiOiJFeHBpcmVkIFJlcGx5IExpbmsiLCJUaGlzIHJlcGx5IGxpbmsgaGFzIGV4cGlyZWQiOiJUaGlzIHJlcGx5IGxpbmsgaGFzIGV4cGlyZWQuIiwiVG8gZ2FpbiBhY2Nlc3MgdG8gYSB3b3JraW5nIHJlcGx5IGxpbmsiOiJUbyBnYWluIGFjY2VzcyB0byBhIHdvcmtpbmcgcmVwbHkgbGluaywgcGxlYXNlIDxUZXh0TGluaz5jb250YWN0IEN1c3RvbWVyIFNlcnZpY2U8L1RleHRMaW5rPiBvciBjYWxsIDxTdHJvbmc%2Be3Bob25lTnVtYmVyfTwvU3Ryb25nPi4iLCJSZXBvcnQgdGhpcyByZXZpZXciOiJSZXBvcnQgdGhpcyByZXZpZXcifQ%3D%3D!"
        )
      ),
"en-PSEUDO": createLanguage(
        require.resolveWeak("./translations.json-en-PSEUDO-virtual.json!=!../../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJUaGUgZ29vZCB0aGluZ3MiOiJb4bmu4bip4bq94bq94bq9IMSjw7bDtsO2w7bDtsO2xowg4bmv4bipw6zDrMOs4LiB4Li14LmJxKPFoV0iLCJUaGUgY2hhbGxlbmdlcyI6Ilvhua7huKnhur3hur3hur0gw6fhuKnEg8SDxIPGmsaa4bq94bq94bq94LiB4Li14LmJxKPhur3hur3hur3FoV0iLCJIZWxwZnVsPyI6IlvhuKjhur3hur3hur3GmsalxpLHmseax5rGmj9dIiwicGVvcGxlIjoiW8al4bq94bq9w7bDtsalxprhur3hur1dIiwiU2F2ZSI6IlvFoMSDxIPhub3hur3hur1dIiwiUmVwbHkiOiJbxZjhur3hur3Gpcaaw73DvV0iLCIxIHBlcnNvbiI6IlsxIMal4bq94bq94bq9xZnFocO2w7bDtuC4geC4teC5iV0iLCJmb3VuZCB0aGlzIGhlbHBmdWwiOiJbxpLDtsO2w7bHmseax5rguIHguLXguYnGjCDhua%2FhuKnDrMOsw6zFoSDhuKnhur3hur3hur3GmsalxpLHmseax5rGml0iLCJFeHBpcmVkIFJlcGx5IExpbmsiOiJbw4vqrZXGpcOsw6zDrMWZ4bq94bq94bq9xowgxZjhur3hur3hur3Gpcaaw73DvcO9IMWBw6zDrMOs4LiB4Li14LmJxLddIiwiVGhpcyByZXBseSBsaW5rIGhhcyBleHBpcmVkIjoiW%2BG5ruG4qcOsw6zDrMWhIMWZ4bq94bq94bq9xqXGmsO9w73DvSDGmsOsw6zDrOC4geC4teC5icS3IOG4qcSDxIPEg8WhIOG6veG6veG6veqtlcalw6zDrMOsxZnhur3hur3hur3GjC5dIiwiVG8gZ2FpbiBhY2Nlc3MgdG8gYSB3b3JraW5nIHJlcGx5IGxpbmsiOiJb4bmuw7bDtsO2IMSjxIPEg8SDw6zDrMOs4LiB4Li14LmJIMSDxIPEg8Onw6fhur3hur3hur3FocWhIOG5r8O2w7bDtiDEg8SDxIMgxbXDtsO2w7bFmcS3w6zDrMOs4LiB4Li14LmJxKMgxZnhur3hur3hur3Gpcaaw73DvcO9IMaaw6zDrMOs4LiB4Li14LmJxLcsIMalxprhur3hur3hur3Eg8SDxIPFoeG6veG6veG6vSA8VGV4dExpbms%2Bw6fDtsO2w7bguIHguLXguYnhua%2FEg8SDxIPDp%2BG5ryDDh8eax5rHmsWh4bmvw7bDtsO2bcyC4bq94bq94bq9xZkgxaDhur3hur3hur3FmeG5vcOsw6zDrMOn4bq94bq94bq9PC9UZXh0TGluaz4gw7bDtsO2xZkgw6fEg8SDxIPGmsaaIDxTdHJvbmc%2Be3Bob25lTnVtYmVyfTwvU3Ryb25nPi5dIiwiUmVwb3J0IHRoaXMgcmV2aWV3IjoiW8WY4bq94bq94bq9xqXDtsO2w7bFmeG5ryDhua%2FhuKnDrMOsw6zFoSDFmeG6veG6veG6veG5vcOsw6zDrOG6veG6veG6vcW1XSJ9!"),
        () => import(
          /* webpackChunkName: "en-PSEUDO-translations" */
          "./translations.json-en-PSEUDO-virtual.json!=!../../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJUaGUgZ29vZCB0aGluZ3MiOiJb4bmu4bip4bq94bq94bq9IMSjw7bDtsO2w7bDtsO2xowg4bmv4bipw6zDrMOs4LiB4Li14LmJxKPFoV0iLCJUaGUgY2hhbGxlbmdlcyI6Ilvhua7huKnhur3hur3hur0gw6fhuKnEg8SDxIPGmsaa4bq94bq94bq94LiB4Li14LmJxKPhur3hur3hur3FoV0iLCJIZWxwZnVsPyI6IlvhuKjhur3hur3hur3GmsalxpLHmseax5rGmj9dIiwicGVvcGxlIjoiW8al4bq94bq9w7bDtsalxprhur3hur1dIiwiU2F2ZSI6IlvFoMSDxIPhub3hur3hur1dIiwiUmVwbHkiOiJbxZjhur3hur3Gpcaaw73DvV0iLCIxIHBlcnNvbiI6IlsxIMal4bq94bq94bq9xZnFocO2w7bDtuC4geC4teC5iV0iLCJmb3VuZCB0aGlzIGhlbHBmdWwiOiJbxpLDtsO2w7bHmseax5rguIHguLXguYnGjCDhua%2FhuKnDrMOsw6zFoSDhuKnhur3hur3hur3GmsalxpLHmseax5rGml0iLCJFeHBpcmVkIFJlcGx5IExpbmsiOiJbw4vqrZXGpcOsw6zDrMWZ4bq94bq94bq9xowgxZjhur3hur3hur3Gpcaaw73DvcO9IMWBw6zDrMOs4LiB4Li14LmJxLddIiwiVGhpcyByZXBseSBsaW5rIGhhcyBleHBpcmVkIjoiW%2BG5ruG4qcOsw6zDrMWhIMWZ4bq94bq94bq9xqXGmsO9w73DvSDGmsOsw6zDrOC4geC4teC5icS3IOG4qcSDxIPEg8WhIOG6veG6veG6veqtlcalw6zDrMOsxZnhur3hur3hur3GjC5dIiwiVG8gZ2FpbiBhY2Nlc3MgdG8gYSB3b3JraW5nIHJlcGx5IGxpbmsiOiJb4bmuw7bDtsO2IMSjxIPEg8SDw6zDrMOs4LiB4Li14LmJIMSDxIPEg8Onw6fhur3hur3hur3FocWhIOG5r8O2w7bDtiDEg8SDxIMgxbXDtsO2w7bFmcS3w6zDrMOs4LiB4Li14LmJxKMgxZnhur3hur3hur3Gpcaaw73DvcO9IMaaw6zDrMOs4LiB4Li14LmJxLcsIMalxprhur3hur3hur3Eg8SDxIPFoeG6veG6veG6vSA8VGV4dExpbms%2Bw6fDtsO2w7bguIHguLXguYnhua%2FEg8SDxIPDp%2BG5ryDDh8eax5rHmsWh4bmvw7bDtsO2bcyC4bq94bq94bq9xZkgxaDhur3hur3hur3FmeG5vcOsw6zDrMOn4bq94bq94bq9PC9UZXh0TGluaz4gw7bDtsO2xZkgw6fEg8SDxIPGmsaaIDxTdHJvbmc%2Be3Bob25lTnVtYmVyfTwvU3Ryb25nPi5dIiwiUmVwb3J0IHRoaXMgcmV2aWV3IjoiW8WY4bq94bq94bq9xqXDtsO2w7bFmeG5ryDhua%2FhuKnDrMOsw6zFoSDFmeG6veG6veG6veG5vcOsw6zDrOG6veG6veG6vcW1XSJ9!"
        )
      )
      });
  
      export { translations as default };
    