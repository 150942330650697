import type { Zone } from '@seek/libs-shared';
import type { MelwaysZone, Country } from '@seek/melways-sites';

export const getMelwaysZoneByCountryCode = (
  countryCode: Uppercase<Country>,
): MelwaysZone => {
  switch (countryCode) {
    case 'NZ':
      return 'anz-2';
    case 'HK':
      return 'asia-1';
    case 'TH':
      return 'asia-3';
    case 'ID':
      return 'asia-4';
    case 'MY':
      return 'asia-5';
    case 'PH':
      return 'asia-6';
    case 'SG':
      return 'asia-7';
    default:
      return 'anz-1';
  }
};

type SupportedCountry = Exclude<Country, 'vn'>;

const legacyCountryZoneMap: Record<SupportedCountry, Zone> = {
  au: 'anz-1', // seek
  nz: 'anz-2', // seek
  hk: 'asia-1', // jobsdb
  th: 'asia-3', // jobsdb
  id: 'asia-4', // jobstreet
  my: 'asia-5', // jobstreet
  ph: 'asia-6', // jobstreet
  sg: 'asia-7', // jobstreet
};

type ZoneToCountryMap = Record<Zone, SupportedCountry | undefined>;

export const getCountryByZone = (zone: Zone): Country | undefined => {
  const zoneToCountryMap: ZoneToCountryMap = Object.entries(
    legacyCountryZoneMap,
  ).reduce((acc, [country, zoneValue]) => {
    acc[zoneValue] = country as SupportedCountry;
    return acc;
  }, {} as ZoneToCountryMap);

  return zoneToCountryMap[zone];
};
