
      import { createLanguage, createTranslationFile } from '@vocab/webpack/web';
      
    const translations = createTranslationFile({
      "en": createLanguage(
        require.resolveWeak("./translations.json-en-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJIb3cgd2FzIHlvdXIgb3ZlcmFsbCBleHBlcmllbmNlPyI6IkhvdyB3YXMgeW91ciBvdmVyYWxsIGV4cGVyaWVuY2U%2FIiwiQ2FyZWVyIGRldmVsb3BtZW50IG9wcG9ydHVuaXRpZXMiOiJDYXJlZXIgZGV2ZWxvcG1lbnQgb3Bwb3J0dW5pdGllcyIsIldvcmsvbGlmZSBiYWxhbmNlIjoiV29yay9saWZlIGJhbGFuY2UiLCJXb3JraW5nIGVudmlyb25tZW50IjoiV29ya2luZyBlbnZpcm9ubWVudCIsIkRpdmVyc2l0eSBhbmQgZXF1YWwgb3Bwb3J0dW5pdHkiOiJEaXZlcnNpdHkgYW5kIGVxdWFsIG9wcG9ydHVuaXR5IiwiTWFuYWdlbWVudCI6Ik1hbmFnZW1lbnQiLCJQZXJrcyBhbmQgYmVuZWZpdHMiOiJQZXJrcyBhbmQgYmVuZWZpdHMiLCJPcHRpb25hbCI6Ik9wdGlvbmFsIn0%3D!"),
        () => import(
          /* webpackChunkName: "en-translations" */
          "./translations.json-en-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJIb3cgd2FzIHlvdXIgb3ZlcmFsbCBleHBlcmllbmNlPyI6IkhvdyB3YXMgeW91ciBvdmVyYWxsIGV4cGVyaWVuY2U%2FIiwiQ2FyZWVyIGRldmVsb3BtZW50IG9wcG9ydHVuaXRpZXMiOiJDYXJlZXIgZGV2ZWxvcG1lbnQgb3Bwb3J0dW5pdGllcyIsIldvcmsvbGlmZSBiYWxhbmNlIjoiV29yay9saWZlIGJhbGFuY2UiLCJXb3JraW5nIGVudmlyb25tZW50IjoiV29ya2luZyBlbnZpcm9ubWVudCIsIkRpdmVyc2l0eSBhbmQgZXF1YWwgb3Bwb3J0dW5pdHkiOiJEaXZlcnNpdHkgYW5kIGVxdWFsIG9wcG9ydHVuaXR5IiwiTWFuYWdlbWVudCI6Ik1hbmFnZW1lbnQiLCJQZXJrcyBhbmQgYmVuZWZpdHMiOiJQZXJrcyBhbmQgYmVuZWZpdHMiLCJPcHRpb25hbCI6Ik9wdGlvbmFsIn0%3D!"
        )
      ),
"th": createLanguage(
        require.resolveWeak("./translations.json-th-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJIb3cgd2FzIHlvdXIgb3ZlcmFsbCBleHBlcmllbmNlPyI6IuC4hOC4uOC4k%2BC5g%2BC4q%2BC5ieC4hOC4sOC5geC4meC4meC4m%2BC4o%2BC4sOC4quC4muC4geC4suC4o%2BC4k%2BC5jOC5guC4lOC4ouC4o%2BC4p%2BC4oeC5gOC4l%2BC5iOC4suC5hOC4q%2BC4o%2BC5iCIsIkNhcmVlciBkZXZlbG9wbWVudCBvcHBvcnR1bml0aWVzIjoi4LmC4Lit4LiB4Liy4Liq4LiB4Liy4Lij4LmA4LiV4Li04Lia4LmC4LiV4LmB4Lil4Liw4Lie4Lix4LiS4LiZ4Liy4LmD4LiZ4LiB4Liy4Lij4LiX4Liz4LiH4Liy4LiZIiwiV29yay9saWZlIGJhbGFuY2UiOiLguITguKfguLLguKHguKrguKHguJTguLjguKXguKPguLDguKvguKfguYjguLLguIfguIrguLXguKfguLTguJXguYHguKXguLDguIHguLLguKPguJfguLPguIfguLLguJkiLCJXb3JraW5nIGVudmlyb25tZW50Ijoi4Liq4Lig4Liy4Lie4LmB4Lin4LiU4Lil4LmJ4Lit4Lih4LmD4LiZ4LiB4Liy4Lij4LiX4Liz4LiH4Liy4LiZIiwiRGl2ZXJzaXR5IGFuZCBlcXVhbCBvcHBvcnR1bml0eSI6IuC4hOC4p%2BC4suC4oeC4q%2BC4peC4suC4geC4q%2BC4peC4suC4ouC5geC4peC4sOC4hOC4p%2BC4suC4oeC5gOC4l%2BC5iOC4suC5gOC4l%2BC4teC4ouC4oSIsIk1hbmFnZW1lbnQiOiLguIHguLLguKPguJrguKPguLTguKvguLLguKPguIjguLHguJTguIHguLLguKMiLCJQZXJrcyBhbmQgYmVuZWZpdHMiOiLguYDguIfguLTguJnguYDguJTguLfguK3guJnguYHguKXguLDguKrguKfguLHguKrguJTguLTguIHguLLguKMiLCJPcHRpb25hbCI6IuC5hOC4oeC5iOC4iOC4s%2BC5gOC4m%2BC5h%2BC4meC4leC5ieC4reC4h%2BC4o%2BC4sOC4muC4uCJ9!"),
        () => import(
          /* webpackChunkName: "th-translations" */
          "./translations.json-th-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJIb3cgd2FzIHlvdXIgb3ZlcmFsbCBleHBlcmllbmNlPyI6IuC4hOC4uOC4k%2BC5g%2BC4q%2BC5ieC4hOC4sOC5geC4meC4meC4m%2BC4o%2BC4sOC4quC4muC4geC4suC4o%2BC4k%2BC5jOC5guC4lOC4ouC4o%2BC4p%2BC4oeC5gOC4l%2BC5iOC4suC5hOC4q%2BC4o%2BC5iCIsIkNhcmVlciBkZXZlbG9wbWVudCBvcHBvcnR1bml0aWVzIjoi4LmC4Lit4LiB4Liy4Liq4LiB4Liy4Lij4LmA4LiV4Li04Lia4LmC4LiV4LmB4Lil4Liw4Lie4Lix4LiS4LiZ4Liy4LmD4LiZ4LiB4Liy4Lij4LiX4Liz4LiH4Liy4LiZIiwiV29yay9saWZlIGJhbGFuY2UiOiLguITguKfguLLguKHguKrguKHguJTguLjguKXguKPguLDguKvguKfguYjguLLguIfguIrguLXguKfguLTguJXguYHguKXguLDguIHguLLguKPguJfguLPguIfguLLguJkiLCJXb3JraW5nIGVudmlyb25tZW50Ijoi4Liq4Lig4Liy4Lie4LmB4Lin4LiU4Lil4LmJ4Lit4Lih4LmD4LiZ4LiB4Liy4Lij4LiX4Liz4LiH4Liy4LiZIiwiRGl2ZXJzaXR5IGFuZCBlcXVhbCBvcHBvcnR1bml0eSI6IuC4hOC4p%2BC4suC4oeC4q%2BC4peC4suC4geC4q%2BC4peC4suC4ouC5geC4peC4sOC4hOC4p%2BC4suC4oeC5gOC4l%2BC5iOC4suC5gOC4l%2BC4teC4ouC4oSIsIk1hbmFnZW1lbnQiOiLguIHguLLguKPguJrguKPguLTguKvguLLguKPguIjguLHguJTguIHguLLguKMiLCJQZXJrcyBhbmQgYmVuZWZpdHMiOiLguYDguIfguLTguJnguYDguJTguLfguK3guJnguYHguKXguLDguKrguKfguLHguKrguJTguLTguIHguLLguKMiLCJPcHRpb25hbCI6IuC5hOC4oeC5iOC4iOC4s%2BC5gOC4m%2BC5h%2BC4meC4leC5ieC4reC4h%2BC4o%2BC4sOC4muC4uCJ9!"
        )
      ),
"id": createLanguage(
        require.resolveWeak("./translations.json-id-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJIb3cgd2FzIHlvdXIgb3ZlcmFsbCBleHBlcmllbmNlPyI6IkJhZ2FpbWFuYSBwZW5nYWxhbWFuIGtlc2VsdXJ1aGFuIEFuZGE%2FIiwiQ2FyZWVyIGRldmVsb3BtZW50IG9wcG9ydHVuaXRpZXMiOiJKZW5qYW5nIGthcmlyIiwiV29yay9saWZlIGJhbGFuY2UiOiJXb3JrLWxpZmUgYmFsYW5jZSIsIldvcmtpbmcgZW52aXJvbm1lbnQiOiJMaW5na3VuZ2FuIGtlcmphIiwiRGl2ZXJzaXR5IGFuZCBlcXVhbCBvcHBvcnR1bml0eSI6IktlcmFnYW1hbiBkYW4ga2VzZXRhcmFhbiIsIk1hbmFnZW1lbnQiOiJNYW5hamVtZW4iLCJQZXJrcyBhbmQgYmVuZWZpdHMiOiJNYW5mYWF0IGRhbiB0dW5qYW5nYW4iLCJPcHRpb25hbCI6Ik9wc2lvbmFsIn0%3D!"),
        () => import(
          /* webpackChunkName: "id-translations" */
          "./translations.json-id-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJIb3cgd2FzIHlvdXIgb3ZlcmFsbCBleHBlcmllbmNlPyI6IkJhZ2FpbWFuYSBwZW5nYWxhbWFuIGtlc2VsdXJ1aGFuIEFuZGE%2FIiwiQ2FyZWVyIGRldmVsb3BtZW50IG9wcG9ydHVuaXRpZXMiOiJKZW5qYW5nIGthcmlyIiwiV29yay9saWZlIGJhbGFuY2UiOiJXb3JrLWxpZmUgYmFsYW5jZSIsIldvcmtpbmcgZW52aXJvbm1lbnQiOiJMaW5na3VuZ2FuIGtlcmphIiwiRGl2ZXJzaXR5IGFuZCBlcXVhbCBvcHBvcnR1bml0eSI6IktlcmFnYW1hbiBkYW4ga2VzZXRhcmFhbiIsIk1hbmFnZW1lbnQiOiJNYW5hamVtZW4iLCJQZXJrcyBhbmQgYmVuZWZpdHMiOiJNYW5mYWF0IGRhbiB0dW5qYW5nYW4iLCJPcHRpb25hbCI6Ik9wc2lvbmFsIn0%3D!"
        )
      ),
"zh": createLanguage(
        require.resolveWeak("./translations.json-zh-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJIb3cgd2FzIHlvdXIgb3ZlcmFsbCBleHBlcmllbmNlPyI6IkhvdyB3YXMgeW91ciBvdmVyYWxsIGV4cGVyaWVuY2U%2FIiwiQ2FyZWVyIGRldmVsb3BtZW50IG9wcG9ydHVuaXRpZXMiOiJDYXJlZXIgZGV2ZWxvcG1lbnQgb3Bwb3J0dW5pdGllcyIsIldvcmsvbGlmZSBiYWxhbmNlIjoiV29yay9saWZlIGJhbGFuY2UiLCJXb3JraW5nIGVudmlyb25tZW50IjoiV29ya2luZyBlbnZpcm9ubWVudCIsIkRpdmVyc2l0eSBhbmQgZXF1YWwgb3Bwb3J0dW5pdHkiOiJEaXZlcnNpdHkgYW5kIGVxdWFsIG9wcG9ydHVuaXR5IiwiTWFuYWdlbWVudCI6Ik1hbmFnZW1lbnQiLCJQZXJrcyBhbmQgYmVuZWZpdHMiOiJQZXJrcyBhbmQgYmVuZWZpdHMiLCJPcHRpb25hbCI6Ik9wdGlvbmFsIn0%3D!"),
        () => import(
          /* webpackChunkName: "zh-translations" */
          "./translations.json-zh-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJIb3cgd2FzIHlvdXIgb3ZlcmFsbCBleHBlcmllbmNlPyI6IkhvdyB3YXMgeW91ciBvdmVyYWxsIGV4cGVyaWVuY2U%2FIiwiQ2FyZWVyIGRldmVsb3BtZW50IG9wcG9ydHVuaXRpZXMiOiJDYXJlZXIgZGV2ZWxvcG1lbnQgb3Bwb3J0dW5pdGllcyIsIldvcmsvbGlmZSBiYWxhbmNlIjoiV29yay9saWZlIGJhbGFuY2UiLCJXb3JraW5nIGVudmlyb25tZW50IjoiV29ya2luZyBlbnZpcm9ubWVudCIsIkRpdmVyc2l0eSBhbmQgZXF1YWwgb3Bwb3J0dW5pdHkiOiJEaXZlcnNpdHkgYW5kIGVxdWFsIG9wcG9ydHVuaXR5IiwiTWFuYWdlbWVudCI6Ik1hbmFnZW1lbnQiLCJQZXJrcyBhbmQgYmVuZWZpdHMiOiJQZXJrcyBhbmQgYmVuZWZpdHMiLCJPcHRpb25hbCI6Ik9wdGlvbmFsIn0%3D!"
        )
      ),
"en-AU": createLanguage(
        require.resolveWeak("./translations.json-en-AU-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJIb3cgd2FzIHlvdXIgb3ZlcmFsbCBleHBlcmllbmNlPyI6IkhvdyB3YXMgeW91ciBvdmVyYWxsIGV4cGVyaWVuY2U%2FIiwiQ2FyZWVyIGRldmVsb3BtZW50IG9wcG9ydHVuaXRpZXMiOiJDYXJlZXIgZGV2ZWxvcG1lbnQgb3Bwb3J0dW5pdGllcyIsIldvcmsvbGlmZSBiYWxhbmNlIjoiV29yay9saWZlIGJhbGFuY2UiLCJXb3JraW5nIGVudmlyb25tZW50IjoiV29ya2luZyBlbnZpcm9ubWVudCIsIkRpdmVyc2l0eSBhbmQgZXF1YWwgb3Bwb3J0dW5pdHkiOiJEaXZlcnNpdHkgYW5kIGVxdWFsIG9wcG9ydHVuaXR5IiwiTWFuYWdlbWVudCI6Ik1hbmFnZW1lbnQiLCJQZXJrcyBhbmQgYmVuZWZpdHMiOiJQZXJrcyBhbmQgYmVuZWZpdHMiLCJPcHRpb25hbCI6Ik9wdGlvbmFsIn0%3D!"),
        () => import(
          /* webpackChunkName: "en-AU-translations" */
          "./translations.json-en-AU-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJIb3cgd2FzIHlvdXIgb3ZlcmFsbCBleHBlcmllbmNlPyI6IkhvdyB3YXMgeW91ciBvdmVyYWxsIGV4cGVyaWVuY2U%2FIiwiQ2FyZWVyIGRldmVsb3BtZW50IG9wcG9ydHVuaXRpZXMiOiJDYXJlZXIgZGV2ZWxvcG1lbnQgb3Bwb3J0dW5pdGllcyIsIldvcmsvbGlmZSBiYWxhbmNlIjoiV29yay9saWZlIGJhbGFuY2UiLCJXb3JraW5nIGVudmlyb25tZW50IjoiV29ya2luZyBlbnZpcm9ubWVudCIsIkRpdmVyc2l0eSBhbmQgZXF1YWwgb3Bwb3J0dW5pdHkiOiJEaXZlcnNpdHkgYW5kIGVxdWFsIG9wcG9ydHVuaXR5IiwiTWFuYWdlbWVudCI6Ik1hbmFnZW1lbnQiLCJQZXJrcyBhbmQgYmVuZWZpdHMiOiJQZXJrcyBhbmQgYmVuZWZpdHMiLCJPcHRpb25hbCI6Ik9wdGlvbmFsIn0%3D!"
        )
      ),
"en-HK": createLanguage(
        require.resolveWeak("./translations.json-en-HK-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJIb3cgd2FzIHlvdXIgb3ZlcmFsbCBleHBlcmllbmNlPyI6IkhvdyB3YXMgeW91ciBvdmVyYWxsIGV4cGVyaWVuY2U%2FIiwiQ2FyZWVyIGRldmVsb3BtZW50IG9wcG9ydHVuaXRpZXMiOiJDYXJlZXIgZGV2ZWxvcG1lbnQgb3Bwb3J0dW5pdGllcyIsIldvcmsvbGlmZSBiYWxhbmNlIjoiV29yay9saWZlIGJhbGFuY2UiLCJXb3JraW5nIGVudmlyb25tZW50IjoiV29ya2luZyBlbnZpcm9ubWVudCIsIkRpdmVyc2l0eSBhbmQgZXF1YWwgb3Bwb3J0dW5pdHkiOiJEaXZlcnNpdHkgYW5kIGVxdWFsIG9wcG9ydHVuaXR5IiwiTWFuYWdlbWVudCI6Ik1hbmFnZW1lbnQiLCJQZXJrcyBhbmQgYmVuZWZpdHMiOiJQZXJrcyBhbmQgYmVuZWZpdHMiLCJPcHRpb25hbCI6Ik9wdGlvbmFsIn0%3D!"),
        () => import(
          /* webpackChunkName: "en-HK-translations" */
          "./translations.json-en-HK-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJIb3cgd2FzIHlvdXIgb3ZlcmFsbCBleHBlcmllbmNlPyI6IkhvdyB3YXMgeW91ciBvdmVyYWxsIGV4cGVyaWVuY2U%2FIiwiQ2FyZWVyIGRldmVsb3BtZW50IG9wcG9ydHVuaXRpZXMiOiJDYXJlZXIgZGV2ZWxvcG1lbnQgb3Bwb3J0dW5pdGllcyIsIldvcmsvbGlmZSBiYWxhbmNlIjoiV29yay9saWZlIGJhbGFuY2UiLCJXb3JraW5nIGVudmlyb25tZW50IjoiV29ya2luZyBlbnZpcm9ubWVudCIsIkRpdmVyc2l0eSBhbmQgZXF1YWwgb3Bwb3J0dW5pdHkiOiJEaXZlcnNpdHkgYW5kIGVxdWFsIG9wcG9ydHVuaXR5IiwiTWFuYWdlbWVudCI6Ik1hbmFnZW1lbnQiLCJQZXJrcyBhbmQgYmVuZWZpdHMiOiJQZXJrcyBhbmQgYmVuZWZpdHMiLCJPcHRpb25hbCI6Ik9wdGlvbmFsIn0%3D!"
        )
      ),
"en-ID": createLanguage(
        require.resolveWeak("./translations.json-en-ID-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJIb3cgd2FzIHlvdXIgb3ZlcmFsbCBleHBlcmllbmNlPyI6IkhvdyB3YXMgeW91ciBvdmVyYWxsIGV4cGVyaWVuY2U%2FIiwiQ2FyZWVyIGRldmVsb3BtZW50IG9wcG9ydHVuaXRpZXMiOiJDYXJlZXIgZGV2ZWxvcG1lbnQgb3Bwb3J0dW5pdGllcyIsIldvcmsvbGlmZSBiYWxhbmNlIjoiV29yay9saWZlIGJhbGFuY2UiLCJXb3JraW5nIGVudmlyb25tZW50IjoiV29ya2luZyBlbnZpcm9ubWVudCIsIkRpdmVyc2l0eSBhbmQgZXF1YWwgb3Bwb3J0dW5pdHkiOiJEaXZlcnNpdHkgYW5kIGVxdWFsIG9wcG9ydHVuaXR5IiwiTWFuYWdlbWVudCI6Ik1hbmFnZW1lbnQiLCJQZXJrcyBhbmQgYmVuZWZpdHMiOiJQZXJrcyBhbmQgYmVuZWZpdHMiLCJPcHRpb25hbCI6Ik9wdGlvbmFsIn0%3D!"),
        () => import(
          /* webpackChunkName: "en-ID-translations" */
          "./translations.json-en-ID-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJIb3cgd2FzIHlvdXIgb3ZlcmFsbCBleHBlcmllbmNlPyI6IkhvdyB3YXMgeW91ciBvdmVyYWxsIGV4cGVyaWVuY2U%2FIiwiQ2FyZWVyIGRldmVsb3BtZW50IG9wcG9ydHVuaXRpZXMiOiJDYXJlZXIgZGV2ZWxvcG1lbnQgb3Bwb3J0dW5pdGllcyIsIldvcmsvbGlmZSBiYWxhbmNlIjoiV29yay9saWZlIGJhbGFuY2UiLCJXb3JraW5nIGVudmlyb25tZW50IjoiV29ya2luZyBlbnZpcm9ubWVudCIsIkRpdmVyc2l0eSBhbmQgZXF1YWwgb3Bwb3J0dW5pdHkiOiJEaXZlcnNpdHkgYW5kIGVxdWFsIG9wcG9ydHVuaXR5IiwiTWFuYWdlbWVudCI6Ik1hbmFnZW1lbnQiLCJQZXJrcyBhbmQgYmVuZWZpdHMiOiJQZXJrcyBhbmQgYmVuZWZpdHMiLCJPcHRpb25hbCI6Ik9wdGlvbmFsIn0%3D!"
        )
      ),
"en-MY": createLanguage(
        require.resolveWeak("./translations.json-en-MY-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJIb3cgd2FzIHlvdXIgb3ZlcmFsbCBleHBlcmllbmNlPyI6IkhvdyB3YXMgeW91ciBvdmVyYWxsIGV4cGVyaWVuY2U%2FIiwiQ2FyZWVyIGRldmVsb3BtZW50IG9wcG9ydHVuaXRpZXMiOiJDYXJlZXIgZGV2ZWxvcG1lbnQgb3Bwb3J0dW5pdGllcyIsIldvcmsvbGlmZSBiYWxhbmNlIjoiV29yay9saWZlIGJhbGFuY2UiLCJXb3JraW5nIGVudmlyb25tZW50IjoiV29ya2luZyBlbnZpcm9ubWVudCIsIkRpdmVyc2l0eSBhbmQgZXF1YWwgb3Bwb3J0dW5pdHkiOiJEaXZlcnNpdHkgYW5kIGVxdWFsIG9wcG9ydHVuaXR5IiwiTWFuYWdlbWVudCI6Ik1hbmFnZW1lbnQiLCJQZXJrcyBhbmQgYmVuZWZpdHMiOiJQZXJrcyBhbmQgYmVuZWZpdHMiLCJPcHRpb25hbCI6Ik9wdGlvbmFsIn0%3D!"),
        () => import(
          /* webpackChunkName: "en-MY-translations" */
          "./translations.json-en-MY-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJIb3cgd2FzIHlvdXIgb3ZlcmFsbCBleHBlcmllbmNlPyI6IkhvdyB3YXMgeW91ciBvdmVyYWxsIGV4cGVyaWVuY2U%2FIiwiQ2FyZWVyIGRldmVsb3BtZW50IG9wcG9ydHVuaXRpZXMiOiJDYXJlZXIgZGV2ZWxvcG1lbnQgb3Bwb3J0dW5pdGllcyIsIldvcmsvbGlmZSBiYWxhbmNlIjoiV29yay9saWZlIGJhbGFuY2UiLCJXb3JraW5nIGVudmlyb25tZW50IjoiV29ya2luZyBlbnZpcm9ubWVudCIsIkRpdmVyc2l0eSBhbmQgZXF1YWwgb3Bwb3J0dW5pdHkiOiJEaXZlcnNpdHkgYW5kIGVxdWFsIG9wcG9ydHVuaXR5IiwiTWFuYWdlbWVudCI6Ik1hbmFnZW1lbnQiLCJQZXJrcyBhbmQgYmVuZWZpdHMiOiJQZXJrcyBhbmQgYmVuZWZpdHMiLCJPcHRpb25hbCI6Ik9wdGlvbmFsIn0%3D!"
        )
      ),
"en-NZ": createLanguage(
        require.resolveWeak("./translations.json-en-NZ-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJIb3cgd2FzIHlvdXIgb3ZlcmFsbCBleHBlcmllbmNlPyI6IkhvdyB3YXMgeW91ciBvdmVyYWxsIGV4cGVyaWVuY2U%2FIiwiQ2FyZWVyIGRldmVsb3BtZW50IG9wcG9ydHVuaXRpZXMiOiJDYXJlZXIgZGV2ZWxvcG1lbnQgb3Bwb3J0dW5pdGllcyIsIldvcmsvbGlmZSBiYWxhbmNlIjoiV29yay9saWZlIGJhbGFuY2UiLCJXb3JraW5nIGVudmlyb25tZW50IjoiV29ya2luZyBlbnZpcm9ubWVudCIsIkRpdmVyc2l0eSBhbmQgZXF1YWwgb3Bwb3J0dW5pdHkiOiJEaXZlcnNpdHkgYW5kIGVxdWFsIG9wcG9ydHVuaXR5IiwiTWFuYWdlbWVudCI6Ik1hbmFnZW1lbnQiLCJQZXJrcyBhbmQgYmVuZWZpdHMiOiJQZXJrcyBhbmQgYmVuZWZpdHMiLCJPcHRpb25hbCI6Ik9wdGlvbmFsIn0%3D!"),
        () => import(
          /* webpackChunkName: "en-NZ-translations" */
          "./translations.json-en-NZ-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJIb3cgd2FzIHlvdXIgb3ZlcmFsbCBleHBlcmllbmNlPyI6IkhvdyB3YXMgeW91ciBvdmVyYWxsIGV4cGVyaWVuY2U%2FIiwiQ2FyZWVyIGRldmVsb3BtZW50IG9wcG9ydHVuaXRpZXMiOiJDYXJlZXIgZGV2ZWxvcG1lbnQgb3Bwb3J0dW5pdGllcyIsIldvcmsvbGlmZSBiYWxhbmNlIjoiV29yay9saWZlIGJhbGFuY2UiLCJXb3JraW5nIGVudmlyb25tZW50IjoiV29ya2luZyBlbnZpcm9ubWVudCIsIkRpdmVyc2l0eSBhbmQgZXF1YWwgb3Bwb3J0dW5pdHkiOiJEaXZlcnNpdHkgYW5kIGVxdWFsIG9wcG9ydHVuaXR5IiwiTWFuYWdlbWVudCI6Ik1hbmFnZW1lbnQiLCJQZXJrcyBhbmQgYmVuZWZpdHMiOiJQZXJrcyBhbmQgYmVuZWZpdHMiLCJPcHRpb25hbCI6Ik9wdGlvbmFsIn0%3D!"
        )
      ),
"en-PH": createLanguage(
        require.resolveWeak("./translations.json-en-PH-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJIb3cgd2FzIHlvdXIgb3ZlcmFsbCBleHBlcmllbmNlPyI6IkhvdyB3YXMgeW91ciBvdmVyYWxsIGV4cGVyaWVuY2U%2FIiwiQ2FyZWVyIGRldmVsb3BtZW50IG9wcG9ydHVuaXRpZXMiOiJDYXJlZXIgZGV2ZWxvcG1lbnQgb3Bwb3J0dW5pdGllcyIsIldvcmsvbGlmZSBiYWxhbmNlIjoiV29yay9saWZlIGJhbGFuY2UiLCJXb3JraW5nIGVudmlyb25tZW50IjoiV29ya2luZyBlbnZpcm9ubWVudCIsIkRpdmVyc2l0eSBhbmQgZXF1YWwgb3Bwb3J0dW5pdHkiOiJEaXZlcnNpdHkgYW5kIGVxdWFsIG9wcG9ydHVuaXR5IiwiTWFuYWdlbWVudCI6Ik1hbmFnZW1lbnQiLCJQZXJrcyBhbmQgYmVuZWZpdHMiOiJQZXJrcyBhbmQgYmVuZWZpdHMiLCJPcHRpb25hbCI6Ik9wdGlvbmFsIn0%3D!"),
        () => import(
          /* webpackChunkName: "en-PH-translations" */
          "./translations.json-en-PH-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJIb3cgd2FzIHlvdXIgb3ZlcmFsbCBleHBlcmllbmNlPyI6IkhvdyB3YXMgeW91ciBvdmVyYWxsIGV4cGVyaWVuY2U%2FIiwiQ2FyZWVyIGRldmVsb3BtZW50IG9wcG9ydHVuaXRpZXMiOiJDYXJlZXIgZGV2ZWxvcG1lbnQgb3Bwb3J0dW5pdGllcyIsIldvcmsvbGlmZSBiYWxhbmNlIjoiV29yay9saWZlIGJhbGFuY2UiLCJXb3JraW5nIGVudmlyb25tZW50IjoiV29ya2luZyBlbnZpcm9ubWVudCIsIkRpdmVyc2l0eSBhbmQgZXF1YWwgb3Bwb3J0dW5pdHkiOiJEaXZlcnNpdHkgYW5kIGVxdWFsIG9wcG9ydHVuaXR5IiwiTWFuYWdlbWVudCI6Ik1hbmFnZW1lbnQiLCJQZXJrcyBhbmQgYmVuZWZpdHMiOiJQZXJrcyBhbmQgYmVuZWZpdHMiLCJPcHRpb25hbCI6Ik9wdGlvbmFsIn0%3D!"
        )
      ),
"en-SG": createLanguage(
        require.resolveWeak("./translations.json-en-SG-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJIb3cgd2FzIHlvdXIgb3ZlcmFsbCBleHBlcmllbmNlPyI6IkhvdyB3YXMgeW91ciBvdmVyYWxsIGV4cGVyaWVuY2U%2FIiwiQ2FyZWVyIGRldmVsb3BtZW50IG9wcG9ydHVuaXRpZXMiOiJDYXJlZXIgZGV2ZWxvcG1lbnQgb3Bwb3J0dW5pdGllcyIsIldvcmsvbGlmZSBiYWxhbmNlIjoiV29yay9saWZlIGJhbGFuY2UiLCJXb3JraW5nIGVudmlyb25tZW50IjoiV29ya2luZyBlbnZpcm9ubWVudCIsIkRpdmVyc2l0eSBhbmQgZXF1YWwgb3Bwb3J0dW5pdHkiOiJEaXZlcnNpdHkgYW5kIGVxdWFsIG9wcG9ydHVuaXR5IiwiTWFuYWdlbWVudCI6Ik1hbmFnZW1lbnQiLCJQZXJrcyBhbmQgYmVuZWZpdHMiOiJQZXJrcyBhbmQgYmVuZWZpdHMiLCJPcHRpb25hbCI6Ik9wdGlvbmFsIn0%3D!"),
        () => import(
          /* webpackChunkName: "en-SG-translations" */
          "./translations.json-en-SG-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJIb3cgd2FzIHlvdXIgb3ZlcmFsbCBleHBlcmllbmNlPyI6IkhvdyB3YXMgeW91ciBvdmVyYWxsIGV4cGVyaWVuY2U%2FIiwiQ2FyZWVyIGRldmVsb3BtZW50IG9wcG9ydHVuaXRpZXMiOiJDYXJlZXIgZGV2ZWxvcG1lbnQgb3Bwb3J0dW5pdGllcyIsIldvcmsvbGlmZSBiYWxhbmNlIjoiV29yay9saWZlIGJhbGFuY2UiLCJXb3JraW5nIGVudmlyb25tZW50IjoiV29ya2luZyBlbnZpcm9ubWVudCIsIkRpdmVyc2l0eSBhbmQgZXF1YWwgb3Bwb3J0dW5pdHkiOiJEaXZlcnNpdHkgYW5kIGVxdWFsIG9wcG9ydHVuaXR5IiwiTWFuYWdlbWVudCI6Ik1hbmFnZW1lbnQiLCJQZXJrcyBhbmQgYmVuZWZpdHMiOiJQZXJrcyBhbmQgYmVuZWZpdHMiLCJPcHRpb25hbCI6Ik9wdGlvbmFsIn0%3D!"
        )
      ),
"en-TH": createLanguage(
        require.resolveWeak("./translations.json-en-TH-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJIb3cgd2FzIHlvdXIgb3ZlcmFsbCBleHBlcmllbmNlPyI6IkhvdyB3YXMgeW91ciBvdmVyYWxsIGV4cGVyaWVuY2U%2FIiwiQ2FyZWVyIGRldmVsb3BtZW50IG9wcG9ydHVuaXRpZXMiOiJDYXJlZXIgZGV2ZWxvcG1lbnQgb3Bwb3J0dW5pdGllcyIsIldvcmsvbGlmZSBiYWxhbmNlIjoiV29yay9saWZlIGJhbGFuY2UiLCJXb3JraW5nIGVudmlyb25tZW50IjoiV29ya2luZyBlbnZpcm9ubWVudCIsIkRpdmVyc2l0eSBhbmQgZXF1YWwgb3Bwb3J0dW5pdHkiOiJEaXZlcnNpdHkgYW5kIGVxdWFsIG9wcG9ydHVuaXR5IiwiTWFuYWdlbWVudCI6Ik1hbmFnZW1lbnQiLCJQZXJrcyBhbmQgYmVuZWZpdHMiOiJQZXJrcyBhbmQgYmVuZWZpdHMiLCJPcHRpb25hbCI6Ik9wdGlvbmFsIn0%3D!"),
        () => import(
          /* webpackChunkName: "en-TH-translations" */
          "./translations.json-en-TH-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJIb3cgd2FzIHlvdXIgb3ZlcmFsbCBleHBlcmllbmNlPyI6IkhvdyB3YXMgeW91ciBvdmVyYWxsIGV4cGVyaWVuY2U%2FIiwiQ2FyZWVyIGRldmVsb3BtZW50IG9wcG9ydHVuaXRpZXMiOiJDYXJlZXIgZGV2ZWxvcG1lbnQgb3Bwb3J0dW5pdGllcyIsIldvcmsvbGlmZSBiYWxhbmNlIjoiV29yay9saWZlIGJhbGFuY2UiLCJXb3JraW5nIGVudmlyb25tZW50IjoiV29ya2luZyBlbnZpcm9ubWVudCIsIkRpdmVyc2l0eSBhbmQgZXF1YWwgb3Bwb3J0dW5pdHkiOiJEaXZlcnNpdHkgYW5kIGVxdWFsIG9wcG9ydHVuaXR5IiwiTWFuYWdlbWVudCI6Ik1hbmFnZW1lbnQiLCJQZXJrcyBhbmQgYmVuZWZpdHMiOiJQZXJrcyBhbmQgYmVuZWZpdHMiLCJPcHRpb25hbCI6Ik9wdGlvbmFsIn0%3D!"
        )
      ),
"th-TH": createLanguage(
        require.resolveWeak("./translations.json-th-TH-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJIb3cgd2FzIHlvdXIgb3ZlcmFsbCBleHBlcmllbmNlPyI6IuC4hOC4uOC4k%2BC5g%2BC4q%2BC5ieC4hOC4sOC5geC4meC4meC4m%2BC4o%2BC4sOC4quC4muC4geC4suC4o%2BC4k%2BC5jOC5guC4lOC4ouC4o%2BC4p%2BC4oeC5gOC4l%2BC5iOC4suC5hOC4q%2BC4o%2BC5iCIsIkNhcmVlciBkZXZlbG9wbWVudCBvcHBvcnR1bml0aWVzIjoi4LmC4Lit4LiB4Liy4Liq4LiB4Liy4Lij4LmA4LiV4Li04Lia4LmC4LiV4LmB4Lil4Liw4Lie4Lix4LiS4LiZ4Liy4LmD4LiZ4LiB4Liy4Lij4LiX4Liz4LiH4Liy4LiZIiwiV29yay9saWZlIGJhbGFuY2UiOiLguITguKfguLLguKHguKrguKHguJTguLjguKXguKPguLDguKvguKfguYjguLLguIfguIrguLXguKfguLTguJXguYHguKXguLDguIHguLLguKPguJfguLPguIfguLLguJkiLCJXb3JraW5nIGVudmlyb25tZW50Ijoi4Liq4Lig4Liy4Lie4LmB4Lin4LiU4Lil4LmJ4Lit4Lih4LmD4LiZ4LiB4Liy4Lij4LiX4Liz4LiH4Liy4LiZIiwiRGl2ZXJzaXR5IGFuZCBlcXVhbCBvcHBvcnR1bml0eSI6IuC4hOC4p%2BC4suC4oeC4q%2BC4peC4suC4geC4q%2BC4peC4suC4ouC5geC4peC4sOC4hOC4p%2BC4suC4oeC5gOC4l%2BC5iOC4suC5gOC4l%2BC4teC4ouC4oSIsIk1hbmFnZW1lbnQiOiLguIHguLLguKPguJrguKPguLTguKvguLLguKPguIjguLHguJTguIHguLLguKMiLCJQZXJrcyBhbmQgYmVuZWZpdHMiOiLguYDguIfguLTguJnguYDguJTguLfguK3guJnguYHguKXguLDguKrguKfguLHguKrguJTguLTguIHguLLguKMiLCJPcHRpb25hbCI6IuC5hOC4oeC5iOC4iOC4s%2BC5gOC4m%2BC5h%2BC4meC4leC5ieC4reC4h%2BC4o%2BC4sOC4muC4uCJ9!"),
        () => import(
          /* webpackChunkName: "th-TH-translations" */
          "./translations.json-th-TH-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJIb3cgd2FzIHlvdXIgb3ZlcmFsbCBleHBlcmllbmNlPyI6IuC4hOC4uOC4k%2BC5g%2BC4q%2BC5ieC4hOC4sOC5geC4meC4meC4m%2BC4o%2BC4sOC4quC4muC4geC4suC4o%2BC4k%2BC5jOC5guC4lOC4ouC4o%2BC4p%2BC4oeC5gOC4l%2BC5iOC4suC5hOC4q%2BC4o%2BC5iCIsIkNhcmVlciBkZXZlbG9wbWVudCBvcHBvcnR1bml0aWVzIjoi4LmC4Lit4LiB4Liy4Liq4LiB4Liy4Lij4LmA4LiV4Li04Lia4LmC4LiV4LmB4Lil4Liw4Lie4Lix4LiS4LiZ4Liy4LmD4LiZ4LiB4Liy4Lij4LiX4Liz4LiH4Liy4LiZIiwiV29yay9saWZlIGJhbGFuY2UiOiLguITguKfguLLguKHguKrguKHguJTguLjguKXguKPguLDguKvguKfguYjguLLguIfguIrguLXguKfguLTguJXguYHguKXguLDguIHguLLguKPguJfguLPguIfguLLguJkiLCJXb3JraW5nIGVudmlyb25tZW50Ijoi4Liq4Lig4Liy4Lie4LmB4Lin4LiU4Lil4LmJ4Lit4Lih4LmD4LiZ4LiB4Liy4Lij4LiX4Liz4LiH4Liy4LiZIiwiRGl2ZXJzaXR5IGFuZCBlcXVhbCBvcHBvcnR1bml0eSI6IuC4hOC4p%2BC4suC4oeC4q%2BC4peC4suC4geC4q%2BC4peC4suC4ouC5geC4peC4sOC4hOC4p%2BC4suC4oeC5gOC4l%2BC5iOC4suC5gOC4l%2BC4teC4ouC4oSIsIk1hbmFnZW1lbnQiOiLguIHguLLguKPguJrguKPguLTguKvguLLguKPguIjguLHguJTguIHguLLguKMiLCJQZXJrcyBhbmQgYmVuZWZpdHMiOiLguYDguIfguLTguJnguYDguJTguLfguK3guJnguYHguKXguLDguKrguKfguLHguKrguJTguLTguIHguLLguKMiLCJPcHRpb25hbCI6IuC5hOC4oeC5iOC4iOC4s%2BC5gOC4m%2BC5h%2BC4meC4leC5ieC4reC4h%2BC4o%2BC4sOC4muC4uCJ9!"
        )
      ),
"id-ID": createLanguage(
        require.resolveWeak("./translations.json-id-ID-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJIb3cgd2FzIHlvdXIgb3ZlcmFsbCBleHBlcmllbmNlPyI6IkJhZ2FpbWFuYSBwZW5nYWxhbWFuIGtlc2VsdXJ1aGFuIEFuZGE%2FIiwiQ2FyZWVyIGRldmVsb3BtZW50IG9wcG9ydHVuaXRpZXMiOiJKZW5qYW5nIGthcmlyIiwiV29yay9saWZlIGJhbGFuY2UiOiJXb3JrLWxpZmUgYmFsYW5jZSIsIldvcmtpbmcgZW52aXJvbm1lbnQiOiJMaW5na3VuZ2FuIGtlcmphIiwiRGl2ZXJzaXR5IGFuZCBlcXVhbCBvcHBvcnR1bml0eSI6IktlcmFnYW1hbiBkYW4ga2VzZXRhcmFhbiIsIk1hbmFnZW1lbnQiOiJNYW5hamVtZW4iLCJQZXJrcyBhbmQgYmVuZWZpdHMiOiJNYW5mYWF0IGRhbiB0dW5qYW5nYW4iLCJPcHRpb25hbCI6Ik9wc2lvbmFsIn0%3D!"),
        () => import(
          /* webpackChunkName: "id-ID-translations" */
          "./translations.json-id-ID-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJIb3cgd2FzIHlvdXIgb3ZlcmFsbCBleHBlcmllbmNlPyI6IkJhZ2FpbWFuYSBwZW5nYWxhbWFuIGtlc2VsdXJ1aGFuIEFuZGE%2FIiwiQ2FyZWVyIGRldmVsb3BtZW50IG9wcG9ydHVuaXRpZXMiOiJKZW5qYW5nIGthcmlyIiwiV29yay9saWZlIGJhbGFuY2UiOiJXb3JrLWxpZmUgYmFsYW5jZSIsIldvcmtpbmcgZW52aXJvbm1lbnQiOiJMaW5na3VuZ2FuIGtlcmphIiwiRGl2ZXJzaXR5IGFuZCBlcXVhbCBvcHBvcnR1bml0eSI6IktlcmFnYW1hbiBkYW4ga2VzZXRhcmFhbiIsIk1hbmFnZW1lbnQiOiJNYW5hamVtZW4iLCJQZXJrcyBhbmQgYmVuZWZpdHMiOiJNYW5mYWF0IGRhbiB0dW5qYW5nYW4iLCJPcHRpb25hbCI6Ik9wc2lvbmFsIn0%3D!"
        )
      ),
"zh-HK": createLanguage(
        require.resolveWeak("./translations.json-zh-HK-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJIb3cgd2FzIHlvdXIgb3ZlcmFsbCBleHBlcmllbmNlPyI6IkhvdyB3YXMgeW91ciBvdmVyYWxsIGV4cGVyaWVuY2U%2FIiwiQ2FyZWVyIGRldmVsb3BtZW50IG9wcG9ydHVuaXRpZXMiOiJDYXJlZXIgZGV2ZWxvcG1lbnQgb3Bwb3J0dW5pdGllcyIsIldvcmsvbGlmZSBiYWxhbmNlIjoiV29yay9saWZlIGJhbGFuY2UiLCJXb3JraW5nIGVudmlyb25tZW50IjoiV29ya2luZyBlbnZpcm9ubWVudCIsIkRpdmVyc2l0eSBhbmQgZXF1YWwgb3Bwb3J0dW5pdHkiOiJEaXZlcnNpdHkgYW5kIGVxdWFsIG9wcG9ydHVuaXR5IiwiTWFuYWdlbWVudCI6Ik1hbmFnZW1lbnQiLCJQZXJrcyBhbmQgYmVuZWZpdHMiOiJQZXJrcyBhbmQgYmVuZWZpdHMiLCJPcHRpb25hbCI6Ik9wdGlvbmFsIn0%3D!"),
        () => import(
          /* webpackChunkName: "zh-HK-translations" */
          "./translations.json-zh-HK-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJIb3cgd2FzIHlvdXIgb3ZlcmFsbCBleHBlcmllbmNlPyI6IkhvdyB3YXMgeW91ciBvdmVyYWxsIGV4cGVyaWVuY2U%2FIiwiQ2FyZWVyIGRldmVsb3BtZW50IG9wcG9ydHVuaXRpZXMiOiJDYXJlZXIgZGV2ZWxvcG1lbnQgb3Bwb3J0dW5pdGllcyIsIldvcmsvbGlmZSBiYWxhbmNlIjoiV29yay9saWZlIGJhbGFuY2UiLCJXb3JraW5nIGVudmlyb25tZW50IjoiV29ya2luZyBlbnZpcm9ubWVudCIsIkRpdmVyc2l0eSBhbmQgZXF1YWwgb3Bwb3J0dW5pdHkiOiJEaXZlcnNpdHkgYW5kIGVxdWFsIG9wcG9ydHVuaXR5IiwiTWFuYWdlbWVudCI6Ik1hbmFnZW1lbnQiLCJQZXJrcyBhbmQgYmVuZWZpdHMiOiJQZXJrcyBhbmQgYmVuZWZpdHMiLCJPcHRpb25hbCI6Ik9wdGlvbmFsIn0%3D!"
        )
      ),
"en-PSEUDO": createLanguage(
        require.resolveWeak("./translations.json-en-PSEUDO-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJIb3cgd2FzIHlvdXIgb3ZlcmFsbCBleHBlcmllbmNlPyI6IlvhuKjDtsO2xbUgxbXEg8SDxaEgw73DvcO2w7bHmseaxZkgw7bDtuG5veG6veG6vcWZxIPEg8aaxpog4bq94bq96q2VxqXhur3hur3FmcOsw6zhur3hur3guIHguLXguYnDp%2BG6veG6vT9dIiwiQ2FyZWVyIGRldmVsb3BtZW50IG9wcG9ydHVuaXRpZXMiOiJbw4fEg8SDxZnhur3hur3hur3hur3FmSDGjOG6veG6veG5veG6veG6vcaaw7bDtsalbcyC4bq94bq94LiB4Li14LmJ4bmvIMO2w7bGpcalw7bDtsWZ4bmvx5rHmuC4geC4teC5icOsw6zhua%2FDrMOs4bq94bq9xaFdIiwiV29yay9saWZlIGJhbGFuY2UiOiJbxbTDtsO2w7bFmcS3L8aaw6zDrMOsxpLhur3hur3hur0gw5%2FEg8SDxIPGmsSDxIPEg%2BC4geC4teC5icOn4bq94bq94bq9XSIsIldvcmtpbmcgZW52aXJvbm1lbnQiOiJbxbTDtsO2w7bFmcS3w6zDrMOs4LiB4Li14LmJxKMg4bq94bq94bq94LiB4Li14LmJ4bm9w6zDrMOsxZnDtsO2w7bguIHguLXguYltzILhur3hur3hur3guIHguLXguYnhua9dIiwiRGl2ZXJzaXR5IGFuZCBlcXVhbCBvcHBvcnR1bml0eSI6IlvhuIrDrMOs4bm94bq94bq9xZnFocOsw6zhua%2FDvcO9IMSDxIPguIHguLXguYnGjCDhur3hur1xzIfHmseaxIPEg8aaIMO2w7bGpcalw7bDtsWZ4bmvx5rHmuC4geC4teC5icOsw6zhua%2FDvcO9XSIsIk1hbmFnZW1lbnQiOiJb4bmCxIPEg%2BC4geC4teC5icSDxIPEo%2BG6veG6vW3MguG6veG6veC4geC4teC5ieG5r10iLCJQZXJrcyBhbmQgYmVuZWZpdHMiOiJbxqThur3hur3hur3FmcS3xaEgxIPEg8SD4LiB4Li14LmJxowgw5%2Fhur3hur3hur3guIHguLXguYnhur3hur3hur3GksOsw6zDrOG5r8WhXSIsIk9wdGlvbmFsIjoiW8OWxqXhua%2FDrMOsw6zDtsO2w7bguIHguLXguYnEg8SDxIPGml0ifQ%3D%3D!"),
        () => import(
          /* webpackChunkName: "en-PSEUDO-translations" */
          "./translations.json-en-PSEUDO-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJIb3cgd2FzIHlvdXIgb3ZlcmFsbCBleHBlcmllbmNlPyI6IlvhuKjDtsO2xbUgxbXEg8SDxaEgw73DvcO2w7bHmseaxZkgw7bDtuG5veG6veG6vcWZxIPEg8aaxpog4bq94bq96q2VxqXhur3hur3FmcOsw6zhur3hur3guIHguLXguYnDp%2BG6veG6vT9dIiwiQ2FyZWVyIGRldmVsb3BtZW50IG9wcG9ydHVuaXRpZXMiOiJbw4fEg8SDxZnhur3hur3hur3hur3FmSDGjOG6veG6veG5veG6veG6vcaaw7bDtsalbcyC4bq94bq94LiB4Li14LmJ4bmvIMO2w7bGpcalw7bDtsWZ4bmvx5rHmuC4geC4teC5icOsw6zhua%2FDrMOs4bq94bq9xaFdIiwiV29yay9saWZlIGJhbGFuY2UiOiJbxbTDtsO2w7bFmcS3L8aaw6zDrMOsxpLhur3hur3hur0gw5%2FEg8SDxIPGmsSDxIPEg%2BC4geC4teC5icOn4bq94bq94bq9XSIsIldvcmtpbmcgZW52aXJvbm1lbnQiOiJbxbTDtsO2w7bFmcS3w6zDrMOs4LiB4Li14LmJxKMg4bq94bq94bq94LiB4Li14LmJ4bm9w6zDrMOsxZnDtsO2w7bguIHguLXguYltzILhur3hur3hur3guIHguLXguYnhua9dIiwiRGl2ZXJzaXR5IGFuZCBlcXVhbCBvcHBvcnR1bml0eSI6IlvhuIrDrMOs4bm94bq94bq9xZnFocOsw6zhua%2FDvcO9IMSDxIPguIHguLXguYnGjCDhur3hur1xzIfHmseaxIPEg8aaIMO2w7bGpcalw7bDtsWZ4bmvx5rHmuC4geC4teC5icOsw6zhua%2FDvcO9XSIsIk1hbmFnZW1lbnQiOiJb4bmCxIPEg%2BC4geC4teC5icSDxIPEo%2BG6veG6vW3MguG6veG6veC4geC4teC5ieG5r10iLCJQZXJrcyBhbmQgYmVuZWZpdHMiOiJbxqThur3hur3hur3FmcS3xaEgxIPEg8SD4LiB4Li14LmJxowgw5%2Fhur3hur3hur3guIHguLXguYnhur3hur3hur3GksOsw6zDrOG5r8WhXSIsIk9wdGlvbmFsIjoiW8OWxqXhua%2FDrMOsw6zDtsO2w7bguIHguLXguYnEg8SDxIPGml0ifQ%3D%3D!"
        )
      )
      });
  
      export { translations as default };
    