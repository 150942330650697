import { createValidator } from '@seek/validators-js';

import { getProfanities } from '../../utils/profanityCheck/profanityCheck';

import translations from './.vocab';

export const { badWords } = createValidator({
  test: ({ value }: { value?: string }) => {
    const words = getProfanities(value ?? '');
    return !words.length;
  },
  formatErrorMessages:
    (messages) =>
    ({ value }: { value?: string }) =>
      messages['Remove the following inappropriate words: {words}'].format({
        words: getProfanities(value ?? '').join(', '),
      }),
  translations,
  validatorName: 'badWords',
});
