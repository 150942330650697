import { makeLinkComponent } from 'braid-design-system';
import { Link } from 'react-router';

export const CustomLink = makeLinkComponent(({ href, ...restProps }, ref) =>
  href.startsWith('/companies') || href.startsWith('/id/companies') ? (
    <Link ref={ref} to={href} {...restProps} />
  ) : (
    // eslint-disable-next-line jsx-a11y/anchor-has-content
    <a ref={ref} href={href} {...restProps} />
  ),
);
