
      import { createLanguage, createTranslationFile } from '@vocab/webpack/web';
      
    const translations = createTranslationFile({
      "en": createLanguage(
        require.resolveWeak("./translations.json-en-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJSZXZpZXcgd29ya2luZyBhdCB7Y29tcGFueU5hbWV9IjoiUmV2aWV3IHdvcmtpbmcgYXQge2NvbXBhbnlOYW1lfSIsIldyaXRlIGEgY29tcGFueSByZXZpZXciOiJXcml0ZSBhIGNvbXBhbnkgcmV2aWV3IiwiQ29udGludWUiOiJDb250aW51ZSIsIkJhY2siOiJCYWNrIiwiT3ZlcmFsbCByYXRpbmciOiJPdmVyYWxsIHJhdGluZyIsIllvdXIgcm9sZSI6IllvdXIgcm9sZSIsIllvdXIgZXhwZXJpZW5jZSI6IllvdXIgZXhwZXJpZW5jZSIsIllvdXIgc2FsYXJ5IjoiWW91ciBzYWxhcnkiLCJZb3VyIGZlZWRiYWNrIjoiWW91ciBmZWVkYmFjayIsImNvbXBhbnkgbG9nbyI6ImNvbXBhbnkgbG9nbyJ9!"),
        () => import(
          /* webpackChunkName: "en-translations" */
          "./translations.json-en-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJSZXZpZXcgd29ya2luZyBhdCB7Y29tcGFueU5hbWV9IjoiUmV2aWV3IHdvcmtpbmcgYXQge2NvbXBhbnlOYW1lfSIsIldyaXRlIGEgY29tcGFueSByZXZpZXciOiJXcml0ZSBhIGNvbXBhbnkgcmV2aWV3IiwiQ29udGludWUiOiJDb250aW51ZSIsIkJhY2siOiJCYWNrIiwiT3ZlcmFsbCByYXRpbmciOiJPdmVyYWxsIHJhdGluZyIsIllvdXIgcm9sZSI6IllvdXIgcm9sZSIsIllvdXIgZXhwZXJpZW5jZSI6IllvdXIgZXhwZXJpZW5jZSIsIllvdXIgc2FsYXJ5IjoiWW91ciBzYWxhcnkiLCJZb3VyIGZlZWRiYWNrIjoiWW91ciBmZWVkYmFjayIsImNvbXBhbnkgbG9nbyI6ImNvbXBhbnkgbG9nbyJ9!"
        )
      ),
"th": createLanguage(
        require.resolveWeak("./translations.json-th-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJSZXZpZXcgd29ya2luZyBhdCB7Y29tcGFueU5hbWV9Ijoi4Lij4Li14Lin4Li04Lin4LiB4Liy4Lij4LiX4Liz4LiH4Liy4LiZ4LiX4Li14LmIIHtjb21wYW55TmFtZX0iLCJXcml0ZSBhIGNvbXBhbnkgcmV2aWV3Ijoi4LmA4LiC4Li14Lii4LiZ4Lij4Li14Lin4Li04Lin4Lit4LiH4LiE4LmM4LiB4LijIiwiQ29udGludWUiOiLguJTguLPguYDguJnguLTguJnguIHguLLguKPguJXguYjguK0iLCJCYWNrIjoi4LiB4Lil4Lix4LiaIiwiT3ZlcmFsbCByYXRpbmciOiLguITguLDguYHguJnguJnguYLguJTguKLguKPguKfguKEiLCJZb3VyIHJvbGUiOiLguJXguLPguYHguKvguJnguYjguIfguIfguLLguJnguILguK3guIfguITguLjguJMiLCJZb3VyIGV4cGVyaWVuY2UiOiLguJvguKPguLDguKrguJrguIHguLLguKPguJPguYzguILguK3guIfguITguLjguJMiLCJZb3VyIHNhbGFyeSI6IuC5gOC4h%2BC4tOC4meC5gOC4lOC4t%2BC4reC4meC4guC4reC4h%2BC4hOC4uOC4kyIsIllvdXIgZmVlZGJhY2siOiLguITguKfguLLguKHguITguLTguJTguYDguKvguYfguJnguILguK3guIfguITguLjguJMiLCJjb21wYW55IGxvZ28iOiLguYLguKXguYLguIHguYnguJrguKPguLTguKnguLHguJcifQ%3D%3D!"),
        () => import(
          /* webpackChunkName: "th-translations" */
          "./translations.json-th-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJSZXZpZXcgd29ya2luZyBhdCB7Y29tcGFueU5hbWV9Ijoi4Lij4Li14Lin4Li04Lin4LiB4Liy4Lij4LiX4Liz4LiH4Liy4LiZ4LiX4Li14LmIIHtjb21wYW55TmFtZX0iLCJXcml0ZSBhIGNvbXBhbnkgcmV2aWV3Ijoi4LmA4LiC4Li14Lii4LiZ4Lij4Li14Lin4Li04Lin4Lit4LiH4LiE4LmM4LiB4LijIiwiQ29udGludWUiOiLguJTguLPguYDguJnguLTguJnguIHguLLguKPguJXguYjguK0iLCJCYWNrIjoi4LiB4Lil4Lix4LiaIiwiT3ZlcmFsbCByYXRpbmciOiLguITguLDguYHguJnguJnguYLguJTguKLguKPguKfguKEiLCJZb3VyIHJvbGUiOiLguJXguLPguYHguKvguJnguYjguIfguIfguLLguJnguILguK3guIfguITguLjguJMiLCJZb3VyIGV4cGVyaWVuY2UiOiLguJvguKPguLDguKrguJrguIHguLLguKPguJPguYzguILguK3guIfguITguLjguJMiLCJZb3VyIHNhbGFyeSI6IuC5gOC4h%2BC4tOC4meC5gOC4lOC4t%2BC4reC4meC4guC4reC4h%2BC4hOC4uOC4kyIsIllvdXIgZmVlZGJhY2siOiLguITguKfguLLguKHguITguLTguJTguYDguKvguYfguJnguILguK3guIfguITguLjguJMiLCJjb21wYW55IGxvZ28iOiLguYLguKXguYLguIHguYnguJrguKPguLTguKnguLHguJcifQ%3D%3D!"
        )
      ),
"id": createLanguage(
        require.resolveWeak("./translations.json-id-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJSZXZpZXcgd29ya2luZyBhdCB7Y29tcGFueU5hbWV9IjoiQmVyaSB1bGFzYW4gYmVrZXJqYSBkaSB7Y29tcGFueU5hbWV9IiwiV3JpdGUgYSBjb21wYW55IHJldmlldyI6IlR1bGlzIHVsYXNhbiBwZXJ1c2FoYWFuIiwiQ29udGludWUiOiJMYW5qdXRrYW4iLCJCYWNrIjoiS2VtYmFsaSIsIk92ZXJhbGwgcmF0aW5nIjoiS2VzZWx1cnVoYW4iLCJZb3VyIHJvbGUiOiJKYWJhdGFuIiwiWW91ciBleHBlcmllbmNlIjoiUGVuZ2FsYW1hbiIsIllvdXIgc2FsYXJ5IjoiR2FqaSIsIllvdXIgZmVlZGJhY2siOiJOaWxhaSBwZXJ1c2FoYWFuIiwiY29tcGFueSBsb2dvIjoibG9nbyBwZXJ1c2FoYWFuIn0%3D!"),
        () => import(
          /* webpackChunkName: "id-translations" */
          "./translations.json-id-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJSZXZpZXcgd29ya2luZyBhdCB7Y29tcGFueU5hbWV9IjoiQmVyaSB1bGFzYW4gYmVrZXJqYSBkaSB7Y29tcGFueU5hbWV9IiwiV3JpdGUgYSBjb21wYW55IHJldmlldyI6IlR1bGlzIHVsYXNhbiBwZXJ1c2FoYWFuIiwiQ29udGludWUiOiJMYW5qdXRrYW4iLCJCYWNrIjoiS2VtYmFsaSIsIk92ZXJhbGwgcmF0aW5nIjoiS2VzZWx1cnVoYW4iLCJZb3VyIHJvbGUiOiJKYWJhdGFuIiwiWW91ciBleHBlcmllbmNlIjoiUGVuZ2FsYW1hbiIsIllvdXIgc2FsYXJ5IjoiR2FqaSIsIllvdXIgZmVlZGJhY2siOiJOaWxhaSBwZXJ1c2FoYWFuIiwiY29tcGFueSBsb2dvIjoibG9nbyBwZXJ1c2FoYWFuIn0%3D!"
        )
      ),
"zh": createLanguage(
        require.resolveWeak("./translations.json-zh-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJSZXZpZXcgd29ya2luZyBhdCB7Y29tcGFueU5hbWV9IjoiUmV2aWV3IHdvcmtpbmcgYXQge2NvbXBhbnlOYW1lfSIsIldyaXRlIGEgY29tcGFueSByZXZpZXciOiJXcml0ZSBhIGNvbXBhbnkgcmV2aWV3IiwiQ29udGludWUiOiJDb250aW51ZSIsIkJhY2siOiJCYWNrIiwiT3ZlcmFsbCByYXRpbmciOiJPdmVyYWxsIHJhdGluZyIsIllvdXIgcm9sZSI6IllvdXIgcm9sZSIsIllvdXIgZXhwZXJpZW5jZSI6IllvdXIgZXhwZXJpZW5jZSIsIllvdXIgc2FsYXJ5IjoiWW91ciBzYWxhcnkiLCJZb3VyIGZlZWRiYWNrIjoiWW91ciBmZWVkYmFjayIsImNvbXBhbnkgbG9nbyI6ImNvbXBhbnkgbG9nbyJ9!"),
        () => import(
          /* webpackChunkName: "zh-translations" */
          "./translations.json-zh-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJSZXZpZXcgd29ya2luZyBhdCB7Y29tcGFueU5hbWV9IjoiUmV2aWV3IHdvcmtpbmcgYXQge2NvbXBhbnlOYW1lfSIsIldyaXRlIGEgY29tcGFueSByZXZpZXciOiJXcml0ZSBhIGNvbXBhbnkgcmV2aWV3IiwiQ29udGludWUiOiJDb250aW51ZSIsIkJhY2siOiJCYWNrIiwiT3ZlcmFsbCByYXRpbmciOiJPdmVyYWxsIHJhdGluZyIsIllvdXIgcm9sZSI6IllvdXIgcm9sZSIsIllvdXIgZXhwZXJpZW5jZSI6IllvdXIgZXhwZXJpZW5jZSIsIllvdXIgc2FsYXJ5IjoiWW91ciBzYWxhcnkiLCJZb3VyIGZlZWRiYWNrIjoiWW91ciBmZWVkYmFjayIsImNvbXBhbnkgbG9nbyI6ImNvbXBhbnkgbG9nbyJ9!"
        )
      ),
"en-AU": createLanguage(
        require.resolveWeak("./translations.json-en-AU-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJSZXZpZXcgd29ya2luZyBhdCB7Y29tcGFueU5hbWV9IjoiUmV2aWV3IHdvcmtpbmcgYXQge2NvbXBhbnlOYW1lfSIsIldyaXRlIGEgY29tcGFueSByZXZpZXciOiJXcml0ZSBhIGNvbXBhbnkgcmV2aWV3IiwiQ29udGludWUiOiJDb250aW51ZSIsIkJhY2siOiJCYWNrIiwiT3ZlcmFsbCByYXRpbmciOiJPdmVyYWxsIHJhdGluZyIsIllvdXIgcm9sZSI6IllvdXIgcm9sZSIsIllvdXIgZXhwZXJpZW5jZSI6IllvdXIgZXhwZXJpZW5jZSIsIllvdXIgc2FsYXJ5IjoiWW91ciBzYWxhcnkiLCJZb3VyIGZlZWRiYWNrIjoiWW91ciBmZWVkYmFjayIsImNvbXBhbnkgbG9nbyI6ImNvbXBhbnkgbG9nbyJ9!"),
        () => import(
          /* webpackChunkName: "en-AU-translations" */
          "./translations.json-en-AU-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJSZXZpZXcgd29ya2luZyBhdCB7Y29tcGFueU5hbWV9IjoiUmV2aWV3IHdvcmtpbmcgYXQge2NvbXBhbnlOYW1lfSIsIldyaXRlIGEgY29tcGFueSByZXZpZXciOiJXcml0ZSBhIGNvbXBhbnkgcmV2aWV3IiwiQ29udGludWUiOiJDb250aW51ZSIsIkJhY2siOiJCYWNrIiwiT3ZlcmFsbCByYXRpbmciOiJPdmVyYWxsIHJhdGluZyIsIllvdXIgcm9sZSI6IllvdXIgcm9sZSIsIllvdXIgZXhwZXJpZW5jZSI6IllvdXIgZXhwZXJpZW5jZSIsIllvdXIgc2FsYXJ5IjoiWW91ciBzYWxhcnkiLCJZb3VyIGZlZWRiYWNrIjoiWW91ciBmZWVkYmFjayIsImNvbXBhbnkgbG9nbyI6ImNvbXBhbnkgbG9nbyJ9!"
        )
      ),
"en-HK": createLanguage(
        require.resolveWeak("./translations.json-en-HK-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJSZXZpZXcgd29ya2luZyBhdCB7Y29tcGFueU5hbWV9IjoiUmV2aWV3IHdvcmtpbmcgYXQge2NvbXBhbnlOYW1lfSIsIldyaXRlIGEgY29tcGFueSByZXZpZXciOiJXcml0ZSBhIGNvbXBhbnkgcmV2aWV3IiwiQ29udGludWUiOiJDb250aW51ZSIsIkJhY2siOiJCYWNrIiwiT3ZlcmFsbCByYXRpbmciOiJPdmVyYWxsIHJhdGluZyIsIllvdXIgcm9sZSI6IllvdXIgcm9sZSIsIllvdXIgZXhwZXJpZW5jZSI6IllvdXIgZXhwZXJpZW5jZSIsIllvdXIgc2FsYXJ5IjoiWW91ciBzYWxhcnkiLCJZb3VyIGZlZWRiYWNrIjoiWW91ciBmZWVkYmFjayIsImNvbXBhbnkgbG9nbyI6ImNvbXBhbnkgbG9nbyJ9!"),
        () => import(
          /* webpackChunkName: "en-HK-translations" */
          "./translations.json-en-HK-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJSZXZpZXcgd29ya2luZyBhdCB7Y29tcGFueU5hbWV9IjoiUmV2aWV3IHdvcmtpbmcgYXQge2NvbXBhbnlOYW1lfSIsIldyaXRlIGEgY29tcGFueSByZXZpZXciOiJXcml0ZSBhIGNvbXBhbnkgcmV2aWV3IiwiQ29udGludWUiOiJDb250aW51ZSIsIkJhY2siOiJCYWNrIiwiT3ZlcmFsbCByYXRpbmciOiJPdmVyYWxsIHJhdGluZyIsIllvdXIgcm9sZSI6IllvdXIgcm9sZSIsIllvdXIgZXhwZXJpZW5jZSI6IllvdXIgZXhwZXJpZW5jZSIsIllvdXIgc2FsYXJ5IjoiWW91ciBzYWxhcnkiLCJZb3VyIGZlZWRiYWNrIjoiWW91ciBmZWVkYmFjayIsImNvbXBhbnkgbG9nbyI6ImNvbXBhbnkgbG9nbyJ9!"
        )
      ),
"en-ID": createLanguage(
        require.resolveWeak("./translations.json-en-ID-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJSZXZpZXcgd29ya2luZyBhdCB7Y29tcGFueU5hbWV9IjoiUmV2aWV3IHdvcmtpbmcgYXQge2NvbXBhbnlOYW1lfSIsIldyaXRlIGEgY29tcGFueSByZXZpZXciOiJXcml0ZSBhIGNvbXBhbnkgcmV2aWV3IiwiQ29udGludWUiOiJDb250aW51ZSIsIkJhY2siOiJCYWNrIiwiT3ZlcmFsbCByYXRpbmciOiJPdmVyYWxsIHJhdGluZyIsIllvdXIgcm9sZSI6IllvdXIgcm9sZSIsIllvdXIgZXhwZXJpZW5jZSI6IllvdXIgZXhwZXJpZW5jZSIsIllvdXIgc2FsYXJ5IjoiWW91ciBzYWxhcnkiLCJZb3VyIGZlZWRiYWNrIjoiWW91ciBmZWVkYmFjayIsImNvbXBhbnkgbG9nbyI6ImNvbXBhbnkgbG9nbyJ9!"),
        () => import(
          /* webpackChunkName: "en-ID-translations" */
          "./translations.json-en-ID-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJSZXZpZXcgd29ya2luZyBhdCB7Y29tcGFueU5hbWV9IjoiUmV2aWV3IHdvcmtpbmcgYXQge2NvbXBhbnlOYW1lfSIsIldyaXRlIGEgY29tcGFueSByZXZpZXciOiJXcml0ZSBhIGNvbXBhbnkgcmV2aWV3IiwiQ29udGludWUiOiJDb250aW51ZSIsIkJhY2siOiJCYWNrIiwiT3ZlcmFsbCByYXRpbmciOiJPdmVyYWxsIHJhdGluZyIsIllvdXIgcm9sZSI6IllvdXIgcm9sZSIsIllvdXIgZXhwZXJpZW5jZSI6IllvdXIgZXhwZXJpZW5jZSIsIllvdXIgc2FsYXJ5IjoiWW91ciBzYWxhcnkiLCJZb3VyIGZlZWRiYWNrIjoiWW91ciBmZWVkYmFjayIsImNvbXBhbnkgbG9nbyI6ImNvbXBhbnkgbG9nbyJ9!"
        )
      ),
"en-MY": createLanguage(
        require.resolveWeak("./translations.json-en-MY-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJSZXZpZXcgd29ya2luZyBhdCB7Y29tcGFueU5hbWV9IjoiUmV2aWV3IHdvcmtpbmcgYXQge2NvbXBhbnlOYW1lfSIsIldyaXRlIGEgY29tcGFueSByZXZpZXciOiJXcml0ZSBhIGNvbXBhbnkgcmV2aWV3IiwiQ29udGludWUiOiJDb250aW51ZSIsIkJhY2siOiJCYWNrIiwiT3ZlcmFsbCByYXRpbmciOiJPdmVyYWxsIHJhdGluZyIsIllvdXIgcm9sZSI6IllvdXIgcm9sZSIsIllvdXIgZXhwZXJpZW5jZSI6IllvdXIgZXhwZXJpZW5jZSIsIllvdXIgc2FsYXJ5IjoiWW91ciBzYWxhcnkiLCJZb3VyIGZlZWRiYWNrIjoiWW91ciBmZWVkYmFjayIsImNvbXBhbnkgbG9nbyI6ImNvbXBhbnkgbG9nbyJ9!"),
        () => import(
          /* webpackChunkName: "en-MY-translations" */
          "./translations.json-en-MY-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJSZXZpZXcgd29ya2luZyBhdCB7Y29tcGFueU5hbWV9IjoiUmV2aWV3IHdvcmtpbmcgYXQge2NvbXBhbnlOYW1lfSIsIldyaXRlIGEgY29tcGFueSByZXZpZXciOiJXcml0ZSBhIGNvbXBhbnkgcmV2aWV3IiwiQ29udGludWUiOiJDb250aW51ZSIsIkJhY2siOiJCYWNrIiwiT3ZlcmFsbCByYXRpbmciOiJPdmVyYWxsIHJhdGluZyIsIllvdXIgcm9sZSI6IllvdXIgcm9sZSIsIllvdXIgZXhwZXJpZW5jZSI6IllvdXIgZXhwZXJpZW5jZSIsIllvdXIgc2FsYXJ5IjoiWW91ciBzYWxhcnkiLCJZb3VyIGZlZWRiYWNrIjoiWW91ciBmZWVkYmFjayIsImNvbXBhbnkgbG9nbyI6ImNvbXBhbnkgbG9nbyJ9!"
        )
      ),
"en-NZ": createLanguage(
        require.resolveWeak("./translations.json-en-NZ-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJSZXZpZXcgd29ya2luZyBhdCB7Y29tcGFueU5hbWV9IjoiUmV2aWV3IHdvcmtpbmcgYXQge2NvbXBhbnlOYW1lfSIsIldyaXRlIGEgY29tcGFueSByZXZpZXciOiJXcml0ZSBhIGNvbXBhbnkgcmV2aWV3IiwiQ29udGludWUiOiJDb250aW51ZSIsIkJhY2siOiJCYWNrIiwiT3ZlcmFsbCByYXRpbmciOiJPdmVyYWxsIHJhdGluZyIsIllvdXIgcm9sZSI6IllvdXIgcm9sZSIsIllvdXIgZXhwZXJpZW5jZSI6IllvdXIgZXhwZXJpZW5jZSIsIllvdXIgc2FsYXJ5IjoiWW91ciBzYWxhcnkiLCJZb3VyIGZlZWRiYWNrIjoiWW91ciBmZWVkYmFjayIsImNvbXBhbnkgbG9nbyI6ImNvbXBhbnkgbG9nbyJ9!"),
        () => import(
          /* webpackChunkName: "en-NZ-translations" */
          "./translations.json-en-NZ-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJSZXZpZXcgd29ya2luZyBhdCB7Y29tcGFueU5hbWV9IjoiUmV2aWV3IHdvcmtpbmcgYXQge2NvbXBhbnlOYW1lfSIsIldyaXRlIGEgY29tcGFueSByZXZpZXciOiJXcml0ZSBhIGNvbXBhbnkgcmV2aWV3IiwiQ29udGludWUiOiJDb250aW51ZSIsIkJhY2siOiJCYWNrIiwiT3ZlcmFsbCByYXRpbmciOiJPdmVyYWxsIHJhdGluZyIsIllvdXIgcm9sZSI6IllvdXIgcm9sZSIsIllvdXIgZXhwZXJpZW5jZSI6IllvdXIgZXhwZXJpZW5jZSIsIllvdXIgc2FsYXJ5IjoiWW91ciBzYWxhcnkiLCJZb3VyIGZlZWRiYWNrIjoiWW91ciBmZWVkYmFjayIsImNvbXBhbnkgbG9nbyI6ImNvbXBhbnkgbG9nbyJ9!"
        )
      ),
"en-PH": createLanguage(
        require.resolveWeak("./translations.json-en-PH-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJSZXZpZXcgd29ya2luZyBhdCB7Y29tcGFueU5hbWV9IjoiUmV2aWV3IHdvcmtpbmcgYXQge2NvbXBhbnlOYW1lfSIsIldyaXRlIGEgY29tcGFueSByZXZpZXciOiJXcml0ZSBhIGNvbXBhbnkgcmV2aWV3IiwiQ29udGludWUiOiJDb250aW51ZSIsIkJhY2siOiJCYWNrIiwiT3ZlcmFsbCByYXRpbmciOiJPdmVyYWxsIHJhdGluZyIsIllvdXIgcm9sZSI6IllvdXIgcm9sZSIsIllvdXIgZXhwZXJpZW5jZSI6IllvdXIgZXhwZXJpZW5jZSIsIllvdXIgc2FsYXJ5IjoiWW91ciBzYWxhcnkiLCJZb3VyIGZlZWRiYWNrIjoiWW91ciBmZWVkYmFjayIsImNvbXBhbnkgbG9nbyI6ImNvbXBhbnkgbG9nbyJ9!"),
        () => import(
          /* webpackChunkName: "en-PH-translations" */
          "./translations.json-en-PH-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJSZXZpZXcgd29ya2luZyBhdCB7Y29tcGFueU5hbWV9IjoiUmV2aWV3IHdvcmtpbmcgYXQge2NvbXBhbnlOYW1lfSIsIldyaXRlIGEgY29tcGFueSByZXZpZXciOiJXcml0ZSBhIGNvbXBhbnkgcmV2aWV3IiwiQ29udGludWUiOiJDb250aW51ZSIsIkJhY2siOiJCYWNrIiwiT3ZlcmFsbCByYXRpbmciOiJPdmVyYWxsIHJhdGluZyIsIllvdXIgcm9sZSI6IllvdXIgcm9sZSIsIllvdXIgZXhwZXJpZW5jZSI6IllvdXIgZXhwZXJpZW5jZSIsIllvdXIgc2FsYXJ5IjoiWW91ciBzYWxhcnkiLCJZb3VyIGZlZWRiYWNrIjoiWW91ciBmZWVkYmFjayIsImNvbXBhbnkgbG9nbyI6ImNvbXBhbnkgbG9nbyJ9!"
        )
      ),
"en-SG": createLanguage(
        require.resolveWeak("./translations.json-en-SG-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJSZXZpZXcgd29ya2luZyBhdCB7Y29tcGFueU5hbWV9IjoiUmV2aWV3IHdvcmtpbmcgYXQge2NvbXBhbnlOYW1lfSIsIldyaXRlIGEgY29tcGFueSByZXZpZXciOiJXcml0ZSBhIGNvbXBhbnkgcmV2aWV3IiwiQ29udGludWUiOiJDb250aW51ZSIsIkJhY2siOiJCYWNrIiwiT3ZlcmFsbCByYXRpbmciOiJPdmVyYWxsIHJhdGluZyIsIllvdXIgcm9sZSI6IllvdXIgcm9sZSIsIllvdXIgZXhwZXJpZW5jZSI6IllvdXIgZXhwZXJpZW5jZSIsIllvdXIgc2FsYXJ5IjoiWW91ciBzYWxhcnkiLCJZb3VyIGZlZWRiYWNrIjoiWW91ciBmZWVkYmFjayIsImNvbXBhbnkgbG9nbyI6ImNvbXBhbnkgbG9nbyJ9!"),
        () => import(
          /* webpackChunkName: "en-SG-translations" */
          "./translations.json-en-SG-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJSZXZpZXcgd29ya2luZyBhdCB7Y29tcGFueU5hbWV9IjoiUmV2aWV3IHdvcmtpbmcgYXQge2NvbXBhbnlOYW1lfSIsIldyaXRlIGEgY29tcGFueSByZXZpZXciOiJXcml0ZSBhIGNvbXBhbnkgcmV2aWV3IiwiQ29udGludWUiOiJDb250aW51ZSIsIkJhY2siOiJCYWNrIiwiT3ZlcmFsbCByYXRpbmciOiJPdmVyYWxsIHJhdGluZyIsIllvdXIgcm9sZSI6IllvdXIgcm9sZSIsIllvdXIgZXhwZXJpZW5jZSI6IllvdXIgZXhwZXJpZW5jZSIsIllvdXIgc2FsYXJ5IjoiWW91ciBzYWxhcnkiLCJZb3VyIGZlZWRiYWNrIjoiWW91ciBmZWVkYmFjayIsImNvbXBhbnkgbG9nbyI6ImNvbXBhbnkgbG9nbyJ9!"
        )
      ),
"en-TH": createLanguage(
        require.resolveWeak("./translations.json-en-TH-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJSZXZpZXcgd29ya2luZyBhdCB7Y29tcGFueU5hbWV9IjoiUmV2aWV3IHdvcmtpbmcgYXQge2NvbXBhbnlOYW1lfSIsIldyaXRlIGEgY29tcGFueSByZXZpZXciOiJXcml0ZSBhIGNvbXBhbnkgcmV2aWV3IiwiQ29udGludWUiOiJDb250aW51ZSIsIkJhY2siOiJCYWNrIiwiT3ZlcmFsbCByYXRpbmciOiJPdmVyYWxsIHJhdGluZyIsIllvdXIgcm9sZSI6IllvdXIgcm9sZSIsIllvdXIgZXhwZXJpZW5jZSI6IllvdXIgZXhwZXJpZW5jZSIsIllvdXIgc2FsYXJ5IjoiWW91ciBzYWxhcnkiLCJZb3VyIGZlZWRiYWNrIjoiWW91ciBmZWVkYmFjayIsImNvbXBhbnkgbG9nbyI6ImNvbXBhbnkgbG9nbyJ9!"),
        () => import(
          /* webpackChunkName: "en-TH-translations" */
          "./translations.json-en-TH-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJSZXZpZXcgd29ya2luZyBhdCB7Y29tcGFueU5hbWV9IjoiUmV2aWV3IHdvcmtpbmcgYXQge2NvbXBhbnlOYW1lfSIsIldyaXRlIGEgY29tcGFueSByZXZpZXciOiJXcml0ZSBhIGNvbXBhbnkgcmV2aWV3IiwiQ29udGludWUiOiJDb250aW51ZSIsIkJhY2siOiJCYWNrIiwiT3ZlcmFsbCByYXRpbmciOiJPdmVyYWxsIHJhdGluZyIsIllvdXIgcm9sZSI6IllvdXIgcm9sZSIsIllvdXIgZXhwZXJpZW5jZSI6IllvdXIgZXhwZXJpZW5jZSIsIllvdXIgc2FsYXJ5IjoiWW91ciBzYWxhcnkiLCJZb3VyIGZlZWRiYWNrIjoiWW91ciBmZWVkYmFjayIsImNvbXBhbnkgbG9nbyI6ImNvbXBhbnkgbG9nbyJ9!"
        )
      ),
"th-TH": createLanguage(
        require.resolveWeak("./translations.json-th-TH-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJSZXZpZXcgd29ya2luZyBhdCB7Y29tcGFueU5hbWV9Ijoi4Lij4Li14Lin4Li04Lin4LiB4Liy4Lij4LiX4Liz4LiH4Liy4LiZ4LiX4Li14LmIIHtjb21wYW55TmFtZX0iLCJXcml0ZSBhIGNvbXBhbnkgcmV2aWV3Ijoi4LmA4LiC4Li14Lii4LiZ4Lij4Li14Lin4Li04Lin4Lit4LiH4LiE4LmM4LiB4LijIiwiQ29udGludWUiOiLguJTguLPguYDguJnguLTguJnguIHguLLguKPguJXguYjguK0iLCJCYWNrIjoi4LiB4Lil4Lix4LiaIiwiT3ZlcmFsbCByYXRpbmciOiLguITguLDguYHguJnguJnguYLguJTguKLguKPguKfguKEiLCJZb3VyIHJvbGUiOiLguJXguLPguYHguKvguJnguYjguIfguIfguLLguJnguILguK3guIfguITguLjguJMiLCJZb3VyIGV4cGVyaWVuY2UiOiLguJvguKPguLDguKrguJrguIHguLLguKPguJPguYzguILguK3guIfguITguLjguJMiLCJZb3VyIHNhbGFyeSI6IuC5gOC4h%2BC4tOC4meC5gOC4lOC4t%2BC4reC4meC4guC4reC4h%2BC4hOC4uOC4kyIsIllvdXIgZmVlZGJhY2siOiLguITguKfguLLguKHguITguLTguJTguYDguKvguYfguJnguILguK3guIfguITguLjguJMiLCJjb21wYW55IGxvZ28iOiLguYLguKXguYLguIHguYnguJrguKPguLTguKnguLHguJcifQ%3D%3D!"),
        () => import(
          /* webpackChunkName: "th-TH-translations" */
          "./translations.json-th-TH-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJSZXZpZXcgd29ya2luZyBhdCB7Y29tcGFueU5hbWV9Ijoi4Lij4Li14Lin4Li04Lin4LiB4Liy4Lij4LiX4Liz4LiH4Liy4LiZ4LiX4Li14LmIIHtjb21wYW55TmFtZX0iLCJXcml0ZSBhIGNvbXBhbnkgcmV2aWV3Ijoi4LmA4LiC4Li14Lii4LiZ4Lij4Li14Lin4Li04Lin4Lit4LiH4LiE4LmM4LiB4LijIiwiQ29udGludWUiOiLguJTguLPguYDguJnguLTguJnguIHguLLguKPguJXguYjguK0iLCJCYWNrIjoi4LiB4Lil4Lix4LiaIiwiT3ZlcmFsbCByYXRpbmciOiLguITguLDguYHguJnguJnguYLguJTguKLguKPguKfguKEiLCJZb3VyIHJvbGUiOiLguJXguLPguYHguKvguJnguYjguIfguIfguLLguJnguILguK3guIfguITguLjguJMiLCJZb3VyIGV4cGVyaWVuY2UiOiLguJvguKPguLDguKrguJrguIHguLLguKPguJPguYzguILguK3guIfguITguLjguJMiLCJZb3VyIHNhbGFyeSI6IuC5gOC4h%2BC4tOC4meC5gOC4lOC4t%2BC4reC4meC4guC4reC4h%2BC4hOC4uOC4kyIsIllvdXIgZmVlZGJhY2siOiLguITguKfguLLguKHguITguLTguJTguYDguKvguYfguJnguILguK3guIfguITguLjguJMiLCJjb21wYW55IGxvZ28iOiLguYLguKXguYLguIHguYnguJrguKPguLTguKnguLHguJcifQ%3D%3D!"
        )
      ),
"id-ID": createLanguage(
        require.resolveWeak("./translations.json-id-ID-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJSZXZpZXcgd29ya2luZyBhdCB7Y29tcGFueU5hbWV9IjoiQmVyaSB1bGFzYW4gYmVrZXJqYSBkaSB7Y29tcGFueU5hbWV9IiwiV3JpdGUgYSBjb21wYW55IHJldmlldyI6IlR1bGlzIHVsYXNhbiBwZXJ1c2FoYWFuIiwiQ29udGludWUiOiJMYW5qdXRrYW4iLCJCYWNrIjoiS2VtYmFsaSIsIk92ZXJhbGwgcmF0aW5nIjoiS2VzZWx1cnVoYW4iLCJZb3VyIHJvbGUiOiJKYWJhdGFuIiwiWW91ciBleHBlcmllbmNlIjoiUGVuZ2FsYW1hbiIsIllvdXIgc2FsYXJ5IjoiR2FqaSIsIllvdXIgZmVlZGJhY2siOiJOaWxhaSBwZXJ1c2FoYWFuIiwiY29tcGFueSBsb2dvIjoibG9nbyBwZXJ1c2FoYWFuIn0%3D!"),
        () => import(
          /* webpackChunkName: "id-ID-translations" */
          "./translations.json-id-ID-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJSZXZpZXcgd29ya2luZyBhdCB7Y29tcGFueU5hbWV9IjoiQmVyaSB1bGFzYW4gYmVrZXJqYSBkaSB7Y29tcGFueU5hbWV9IiwiV3JpdGUgYSBjb21wYW55IHJldmlldyI6IlR1bGlzIHVsYXNhbiBwZXJ1c2FoYWFuIiwiQ29udGludWUiOiJMYW5qdXRrYW4iLCJCYWNrIjoiS2VtYmFsaSIsIk92ZXJhbGwgcmF0aW5nIjoiS2VzZWx1cnVoYW4iLCJZb3VyIHJvbGUiOiJKYWJhdGFuIiwiWW91ciBleHBlcmllbmNlIjoiUGVuZ2FsYW1hbiIsIllvdXIgc2FsYXJ5IjoiR2FqaSIsIllvdXIgZmVlZGJhY2siOiJOaWxhaSBwZXJ1c2FoYWFuIiwiY29tcGFueSBsb2dvIjoibG9nbyBwZXJ1c2FoYWFuIn0%3D!"
        )
      ),
"zh-HK": createLanguage(
        require.resolveWeak("./translations.json-zh-HK-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJSZXZpZXcgd29ya2luZyBhdCB7Y29tcGFueU5hbWV9IjoiUmV2aWV3IHdvcmtpbmcgYXQge2NvbXBhbnlOYW1lfSIsIldyaXRlIGEgY29tcGFueSByZXZpZXciOiJXcml0ZSBhIGNvbXBhbnkgcmV2aWV3IiwiQ29udGludWUiOiJDb250aW51ZSIsIkJhY2siOiJCYWNrIiwiT3ZlcmFsbCByYXRpbmciOiJPdmVyYWxsIHJhdGluZyIsIllvdXIgcm9sZSI6IllvdXIgcm9sZSIsIllvdXIgZXhwZXJpZW5jZSI6IllvdXIgZXhwZXJpZW5jZSIsIllvdXIgc2FsYXJ5IjoiWW91ciBzYWxhcnkiLCJZb3VyIGZlZWRiYWNrIjoiWW91ciBmZWVkYmFjayIsImNvbXBhbnkgbG9nbyI6ImNvbXBhbnkgbG9nbyJ9!"),
        () => import(
          /* webpackChunkName: "zh-HK-translations" */
          "./translations.json-zh-HK-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJSZXZpZXcgd29ya2luZyBhdCB7Y29tcGFueU5hbWV9IjoiUmV2aWV3IHdvcmtpbmcgYXQge2NvbXBhbnlOYW1lfSIsIldyaXRlIGEgY29tcGFueSByZXZpZXciOiJXcml0ZSBhIGNvbXBhbnkgcmV2aWV3IiwiQ29udGludWUiOiJDb250aW51ZSIsIkJhY2siOiJCYWNrIiwiT3ZlcmFsbCByYXRpbmciOiJPdmVyYWxsIHJhdGluZyIsIllvdXIgcm9sZSI6IllvdXIgcm9sZSIsIllvdXIgZXhwZXJpZW5jZSI6IllvdXIgZXhwZXJpZW5jZSIsIllvdXIgc2FsYXJ5IjoiWW91ciBzYWxhcnkiLCJZb3VyIGZlZWRiYWNrIjoiWW91ciBmZWVkYmFjayIsImNvbXBhbnkgbG9nbyI6ImNvbXBhbnkgbG9nbyJ9!"
        )
      ),
"en-PSEUDO": createLanguage(
        require.resolveWeak("./translations.json-en-PSEUDO-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJSZXZpZXcgd29ya2luZyBhdCB7Y29tcGFueU5hbWV9IjoiW8WY4bq94bq94bq94bm9w6zDrMOs4bq94bq94bq9xbUgxbXDtsO2w7bFmcS3w6zDrMOs4LiB4Li14LmJxKMgxIPEg8SD4bmvIHtjb21wYW55TmFtZX1dIiwiV3JpdGUgYSBjb21wYW55IHJldmlldyI6IlvFtMWZw6zDrOG5r%2BG6veG6vSDEg8SDIMOnw7bDtm3MgsalxIPEg%2BC4geC4teC5icO9w70gxZnhur3hur3hub3DrMOs4bq94bq9xbVdIiwiQ29udGludWUiOiJbw4fDtsO24LiB4Li14LmJ4bmvw6zDrOC4geC4teC5iceax5rhur3hur1dIiwiQmFjayI6Ilvhup7Eg8SDxIPDp8S3XSIsIk92ZXJhbGwgcmF0aW5nIjoiW8OW4bm94bq94bq94bq9xZnEg8SDxIPGmsaaIMWZxIPEg8SD4bmvw6zDrMOs4LiB4Li14LmJxKNdIiwiWW91ciByb2xlIjoiW8Odw7bDtseax5rFmSDFmcO2w7bGmuG6veG6vV0iLCJZb3VyIGV4cGVyaWVuY2UiOiJbw53DtsO2x5rHmsWZIOG6veG6veqtlcal4bq94bq9xZnDrMOs4bq94bq94LiB4Li14LmJw6fhur3hur1dIiwiWW91ciBzYWxhcnkiOiJbw53DtsO2x5rHmsWZIMWhxIPEg8aaxIPEg8WZw73DvV0iLCJZb3VyIGZlZWRiYWNrIjoiW8Odw7bDtsO2x5rHmseaxZkgxpLhur3hur3hur3hur3hur3hur3GjMOfxIPEg8SDw6fEt10iLCJjb21wYW55IGxvZ28iOiJbw6fDtsO2bcyCxqXEg8SD4LiB4Li14LmJw73DvSDGmsO2w7bEo8O2w7ZdIn0%3D!"),
        () => import(
          /* webpackChunkName: "en-PSEUDO-translations" */
          "./translations.json-en-PSEUDO-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJSZXZpZXcgd29ya2luZyBhdCB7Y29tcGFueU5hbWV9IjoiW8WY4bq94bq94bq94bm9w6zDrMOs4bq94bq94bq9xbUgxbXDtsO2w7bFmcS3w6zDrMOs4LiB4Li14LmJxKMgxIPEg8SD4bmvIHtjb21wYW55TmFtZX1dIiwiV3JpdGUgYSBjb21wYW55IHJldmlldyI6IlvFtMWZw6zDrOG5r%2BG6veG6vSDEg8SDIMOnw7bDtm3MgsalxIPEg%2BC4geC4teC5icO9w70gxZnhur3hur3hub3DrMOs4bq94bq9xbVdIiwiQ29udGludWUiOiJbw4fDtsO24LiB4Li14LmJ4bmvw6zDrOC4geC4teC5iceax5rhur3hur1dIiwiQmFjayI6Ilvhup7Eg8SDxIPDp8S3XSIsIk92ZXJhbGwgcmF0aW5nIjoiW8OW4bm94bq94bq94bq9xZnEg8SDxIPGmsaaIMWZxIPEg8SD4bmvw6zDrMOs4LiB4Li14LmJxKNdIiwiWW91ciByb2xlIjoiW8Odw7bDtseax5rFmSDFmcO2w7bGmuG6veG6vV0iLCJZb3VyIGV4cGVyaWVuY2UiOiJbw53DtsO2x5rHmsWZIOG6veG6veqtlcal4bq94bq9xZnDrMOs4bq94bq94LiB4Li14LmJw6fhur3hur1dIiwiWW91ciBzYWxhcnkiOiJbw53DtsO2x5rHmsWZIMWhxIPEg8aaxIPEg8WZw73DvV0iLCJZb3VyIGZlZWRiYWNrIjoiW8Odw7bDtsO2x5rHmseaxZkgxpLhur3hur3hur3hur3hur3hur3GjMOfxIPEg8SDw6fEt10iLCJjb21wYW55IGxvZ28iOiJbw6fDtsO2bcyCxqXEg8SD4LiB4Li14LmJw73DvSDGmsO2w7bEo8O2w7ZdIn0%3D!"
        )
      )
      });
  
      export { translations as default };
    