import type { ReviewFormActionOrigin } from '../../shared/tracking/writeAReview/trackingEvents';

const actionOriginMapping: Record<string, ReviewFormActionOrigin> = {
  'company-profile': 'Company Profile',
  'overview-personalised': 'Candidate Profile',
  companies: 'Companies',
  'leave_a_review_star_rating-personalised': 'Career Advice',
};

export const mapActionOrigin = (
  actionOrigin: string | undefined,
): ReviewFormActionOrigin =>
  actionOrigin ? (actionOriginMapping[actionOrigin] ?? null) : null;
