import { useTranslations } from '@vocab/react';

import translations from './.vocab';
import { Section } from '../../common/section/Section';
import {
  Tiles,
  Text,
  Stack,
  Columns,
  Column,
  IconGift,
} from 'braid-design-system';
import { UpdateSuccessAlert, type PerksAndBenefits } from '../../../';

interface Props {
  mode: 'view' | 'edit';
  loading?: boolean;
  model?: PerksAndBenefits;

  onEditClick?: () => void;
  onHideUpdateSuccess?: () => void;
  /**
   * Only used for 'edit' mode
   */
  showUpdateSuccess?: boolean;
}

export function PerksAndBenefitsSection({
  mode,
  model,
  loading,
  showUpdateSuccess = false,
  onEditClick,
  onHideUpdateSuccess,
}: Props) {
  const { t } = useTranslations(translations);
  const empty =
    !model || Object.keys(model).length === 0 || model?.length === 0;

  return (
    <Section
      mode={mode}
      loading={loading}
      dataEmpty={empty}
      heading={t('Perks and benefits')}
      addDataHint={t(
        'Help job seekers understand the benefits of working for your company.',
      )}
      onEditClick={onEditClick}
    >
      <Stack space="gutter">
        {showUpdateSuccess && (
          <UpdateSuccessAlert
            onClose={() => {
              if (onHideUpdateSuccess) onHideUpdateSuccess();
            }}
            text={t('Perks and benefits updated')}
          />
        )}
        <Tiles columns={{ mobile: 1, tablet: 2 }} space="large">
          {model?.map((perk, index) => (
            <Columns key={index} space="small">
              <Column width="content">
                <Text size="large">
                  <IconGift alignY="uppercase" />
                </Text>
              </Column>
              <Column>
                <Stack space="medium">
                  <Text weight="strong" component="h3">{perk.title}</Text>
                  <Text>{perk.description}</Text>
                </Stack>
              </Column>
            </Columns>
          ))}
        </Tiles>
      </Stack>
    </Section>
  );
}
