import { parse } from 'cookie';
import React from 'react';
import { getFeatureFlag } from './getFeatureFlag';

type FeatureFlagContextProps<T extends Record<string, boolean>> =
  | {
      cookieFeatureFlags: T;
      featureFlags: Record<string, unknown>;
    }
  | undefined;

type FeatureFlagProviderProps = React.PropsWithChildren & {
  testFeatureFlags?: Record<string, unknown>;
};

const FeatureFlagProviderContext =
  React.createContext<FeatureFlagContextProps<FeatureFlagsFromCookies>>(
    undefined,
  );

const parseCookies = () => {
  if (typeof document === 'undefined') {
    return {};
  }

  return parse(document.cookie);
};

const getFeatureFlagsFromCookies = (
  cookies: Record<string, string | undefined>,
) => {
  return {
    COMPANY_PROFILES_ADD_DEPTH_AI_GEN:
      cookies.COMPANY_PROFILES_ADD_DEPTH_AI_GEN === 'true',
  } as const satisfies Record<string, boolean>;
};
type FeatureFlagsFromCookies = ReturnType<typeof getFeatureFlagsFromCookies>;

export const FeatureFlagProvider = ({
  children,
  testFeatureFlags,
}: FeatureFlagProviderProps) => {
  const cookies = parseCookies();
  const cookieFeatureFlags = getFeatureFlagsFromCookies(cookies);

  return (
    <FeatureFlagProviderContext.Provider
      value={{ featureFlags: testFeatureFlags ?? {}, cookieFeatureFlags }}
    >
      {children}
    </FeatureFlagProviderContext.Provider>
  );
};

export const useFeatureFlags = () => {
  const context = React.useContext(FeatureFlagProviderContext);

  if (context === undefined) {
    throw new Error(
      'useFeatureFlags must be used within a FeatureFlagProvider',
    );
  }

  return {
    ...context,
    getFeatureFlag: getFeatureFlag(context.featureFlags),
  };
};
