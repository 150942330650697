import type { Language } from '@seek/melways-sites';

import type { AppConfig } from '../config';

import { isLocationBasedReviewRoute } from './isLocationBasedReviewRoute';

export const getLocationContextualName = (params: {
  config: AppConfig;
  locationSlug?: string;
}): string | undefined => {
  const { locationSlug, config } = params;
  const { language, reviewLocation } = config;

  const siteLanguage: Exclude<Language, 'zh'> =
    language === 'zh' ? 'en' : language;

  return isLocationBasedReviewRoute({
    locationSlug,
    locationId: reviewLocation.details?.locationId,
  })
    ? reviewLocation.details?.contextualNames?.[siteLanguage] ||
        reviewLocation.details?.contextualNames?.[
          reviewLocation.details.locationLanguage
        ]
    : undefined;
};
