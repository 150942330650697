import { DEFAULT_LOGO_URL } from '@seek/libs-shared';
import { useTranslations } from '@vocab/react';
import {
  Box,
  Card,
  Columns,
  Column,
  Inline,
  Rating,
  Strong,
  Text,
} from 'braid-design-system';
import translations from './.vocab';

export const CompanySearchCard = ({
  name,
  logo = DEFAULT_LOGO_URL,
  averageCompanyRating,
  reviewsCount,
  onClick,
}: {
  name: string;
  averageCompanyRating?: number;
  logo?: string;
  onClick?: () => void;
  reviewsCount?: number;
}) => {
  const { t } = useTranslations(translations);

  return (
    <Box onClick={onClick} cursor="pointer">
      <Card>
        <Columns
          space={['medium', 'large']}
          alignY="center"
          collapseBelow="tablet"
        >
          <Column width="content">
            <img
              src={logo}
              style={{
                width: '100px',
                height: '65px',
                objectFit: 'contain',
              }}
              alt=""
            />
          </Column>
          <Column>
            <Box style={{ maxWidth: '328px' }}>
              <Text weight="strong">{name}</Text>
            </Box>
          </Column>
          <Column width="content">
            {averageCompanyRating && reviewsCount && reviewsCount > 2 ? (
              <Inline space="xxsmall" alignY="center">
                <Rating
                  rating={averageCompanyRating}
                  variant="minimal"
                  size="small"
                />
                <Text size="small">
                  <Strong>&middot;</Strong>
                </Text>
                <Text size="small">
                  {t('reviewsCount reviews', {
                    reviewsCount: reviewsCount.toString(),
                  })}
                </Text>
              </Inline>
            ) : null}
          </Column>
        </Columns>
      </Card>
    </Box>
  );
};
