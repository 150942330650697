/* istanbul ignore file */
// this is a mock file for testing

import type { CompanyReviewsAISummary } from '@seek/libs-shared';

import type { AppConfig } from '../../config';

import type { CompanyDetailsService } from './companyDetailsService';

export const createCompanyDetailsServiceMock = (_props: {
  config: AppConfig;
}): CompanyDetailsService => {
  const getCompanyReviewsAISummaryById = async (
    _id: string,
  ): Promise<CompanyReviewsAISummary | null> => ({
    reviewsCount: 2,
    reviewsDateFrom: '2024-01-01T00:22:48.000Z',
    reviewsDateTo: '2024-02-01T00:22:48.000Z',
    aISummaryContent: {
      positiveAndChallengesSummary:
        '<p>Paragraph one, this is a <strong>hard-coded sample</strong> that acts as mock data for visualizing AI-generated summary content, it supports multiple lines, and all content is generated from AWS Bedrock.</p>Employees list several positives, including attractive benefits, staff discounts, work-from-home options, and a supportive team environment that promotes flexibility and work-life balance. Employees also appreciate opportunities for training and career development.<p/> However, challenges include concerns around management, with some citing micromanagement and shifting priorities, as well as outdated systems and processes. Additionally, high workloads and job pressures, especially in customer-facing roles, along with frequent organisational changes, can contribute to stress and job insecurity.',
    },
  });

  return {
    getCompanyReviewsAISummaryById,
  };
};
