import type { ReviewRowModel } from '@seek/libs-shared';

import type { AboutModel } from '../../pages/about/aboutModel';
import type { ReviewsTabModel } from '../../pages/reviews/reviewsTabModel';

import type { SeoStructuredData } from './SeoStructuredData';

interface JsonLd {
  '@context': string;
  '@type': string;
  name: string;
  aggregateRating?: {
    '@type': string;
    ratingCount: number;
    ratingValue: number;
  };
  logo?: string;
  reviews?: ReviewJsonLd[];
  url?: string;
}

interface ReviewJsonLd {
  '@type': string;
  author?: {
    '@type': string;
    jobTitle?: string;
  };
  datePublished?: string;
  reviewBody?: string;
  reviewRating?: {
    '@type': string;
    ratingValue?: number;
  };
}

export const mapToReviewJsonLd = (review: ReviewRowModel) => {
  const formattedDate = review.createdAt.split('T')[0];
  return {
    '@type': 'Review',
    author: {
      '@type': 'Person',
      jobTitle: review.jobTitle,
    },
    reviewBody: review.title,
    datePublished: formattedDate,
    reviewRating: {
      '@type': 'Rating',
      ratingValue: review.overallRating,
    },
  };
};

export const mapToJsonLd = (data: SeoStructuredData): JsonLd => ({
  '@context': 'http://schema.org',
  '@type': 'LocalBusiness',
  name: data.name,
  aggregateRating: {
    '@type': 'AggregateRating',
    ratingCount: data.ratingCount,
    ratingValue: data.ratingValue,
  },
  ...(data?.location ? { address: data?.location } : {}),
  ...(data?.url ? { url: data?.url } : {}),
  ...(data?.logo ? { logo: data?.logo } : {}),
  ...(data?.reviews
    ? { review: data?.reviews.map((review) => mapToReviewJsonLd(review)) }
    : {}),
});

export const mapToSeoData = (
  data?: AboutModel | ReviewsTabModel,
): SeoStructuredData | undefined => {
  if (!data) {
    return undefined;
  }
  return {
    name: data.name,
    url: data.overview?.website?.url,
    logo: data.branding?.logoImageUrl,
    ratingCount: data.reviewSummary?.overallRating?.count ?? 0,
    ratingValue: data.reviewSummary?.overallRating?.average ?? 0,
    location: data.overview?.primaryLocation,
    ...('reviews' in data
      ? { reviews: (data as ReviewsTabModel).reviews?.reviews }
      : {}),
  };
};
