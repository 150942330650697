import { useConfig } from '@seek/libs-shared';
import { mapCountryCodeToDefaultCurrency } from '@seek/salary-data';
import { constructSalaryDisplayText } from '@seek/unified-display-salary';
import { useTranslations } from '@vocab/react';
import {
  Box,
  Checkbox,
  Dropdown,
  FieldLabel,
  Stack,
  Text,
} from 'braid-design-system';
import { useEffect, useState } from 'react';

import type {
  QuestionProps,
  ReviewFormContext,
  Salary,
  SalaryType,
} from '../../../type';

import translations from './.vocab';
import { getSalaryRate, type SupportedCurrency } from './SalaryRanges';

type Props = QuestionProps<Salary> & {
  onBonusChange: () => void;
  onSalaryChange: () => void;
  reviewFormContext: ReviewFormContext;
  salaryType?: SalaryType;
};

export const SalaryQuestion = ({
  value = {},
  message,
  tone,
  onChange,
  onBonusChange,
  onSalaryChange,
  salaryType,
}: Props) => {
  const { t } = useTranslations(translations);

  const config = useConfig();
  const [selectedSalaryBracket, setSelectedSalaryBracket] =
    useState<string>('');
  const [salaryBracketList, setSalaryBracketList] = useState<
    { max: number, min: number; }[]
  >([]);

  const availableCurrencies: SupportedCurrency[] = [
    mapCountryCodeToDefaultCurrency(config.country) as SupportedCurrency,
  ];

  // Default the currency to the country's default currency if empty
  if (!value.currency) {
    value.currency = availableCurrencies[0];
  }

  useEffect(() => {
    const currency = value.currency;

    if (currency && salaryType) {
      const currentSalaryBracketList = getSalaryRate(currency, salaryType);
      if (currentSalaryBracketList) {
        setSalaryBracketList(currentSalaryBracketList);
        if (value.minimum && value.maximum) {
          const selectedSalaryBracket = currentSalaryBracketList.findIndex(
            (salaryBracket) =>
              salaryBracket.min === value.minimum &&
              salaryBracket.max === value.maximum,
          );
          setSelectedSalaryBracket(selectedSalaryBracket.toString());
        } else {
          setSelectedSalaryBracket('');
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [salaryType, value.currency]);

  const handleSalaryBracketOnChange = (
    event: React.FormEvent<HTMLSelectElement>,
  ) => {
    setSelectedSalaryBracket(event.currentTarget.value);
    onChange({
      ...value,
      minimum: salaryBracketList[Number(event.currentTarget.value)].min,
      maximum: salaryBracketList[Number(event.currentTarget.value)].max,
    });
    onSalaryChange();
  };

  const onQuestionBonusChange = (event: React.FormEvent<HTMLInputElement>) => {
    onChange({
      ...value,
      includeBonus: event.currentTarget.checked,
    });
    onBonusChange();
  };

  const getSalaryText = ({
    min,
    max,
    index,
    last,
  }: {
    index?: number;
    last?: boolean;
    max?: number;
    min?: number;
  }): string | undefined => {
    if (value.currency) {
      const salaryText = constructSalaryDisplayText({
        min,
        max,
        currency: value.currency,
      });
      if (index === 0) {
        return t('Under {salaryText}', { salaryText });
      }
      if (last) {
        return t('More than {salaryText}', { salaryText });
      }
      return `${salaryText}`;
    }
  };

  return (
    <Box id="salaryQuestion">
      <Stack space="large">
        <FieldLabel
          htmlFor="range"
          label={t('What is your salary?')}
          description={t(
            "Used to calculate average and won't be included in your review",
          )}
        />
        <Dropdown
          id="range"
          label={t('Amount')}
          aria-label={t('Amount')}
          secondaryLabel={t('excl. super')}
          onChange={handleSalaryBracketOnChange}
          value={selectedSalaryBracket}
          placeholder=""
          tone={tone}
          data={{ testid: 'range-selection' }}
          disabled={salaryType === undefined}
        >
          {salaryBracketList?.map((salaryBracket, index) => (
            <option key={index} value={index}>
              {getSalaryText({
                min: salaryBracket.min,
                max: salaryBracket.max,
                index,
                last: index === salaryBracketList.length - 1,
              })}
            </option>
          ))}
        </Dropdown>

        <Checkbox
          checked={value.includeBonus ?? false}
          onChange={onQuestionBonusChange}
          label={t('I also receive bonuses or commission')}
          id="includeBonus"
          data={{ testid: 'include-bonus-check' }}
          disabled={salaryType === undefined}
        />

        {message ? <Text tone={tone}>{message}</Text> : null}
      </Stack>
    </Box>
  );
};
