import {
  Column,
  Stack,
  Heading,
  Box,
  Columns,
  Text,
  IconArrow,
  ButtonLink,
  Actions,
} from 'braid-design-system';
import {
  useConfig,
  buildCommunityGuidelinesURI,
  buildEmployerCompanyProfilePromoPageURI,
} from '@seek/libs-shared';
import { useTranslations } from '@vocab/react';

import { CultureAndValuesIcon } from './CultureAndValuesIcon';
import { RatingsAndReviewsIcon } from './RatingsAndReviewsIcon';
import { PerksAndBenefitsIcon } from './PerksAndBenefitsIcon';
import translations from './.vocab';

function ValueProp({
  Icon,
  title,
  description,
}: {
  Icon: () => JSX.Element;
  description: string;
  title: string;
}) {
  return (
    <Column width="1/3">
      <Stack space="large" align="center">
        <Icon />
        <Stack space="medium" align="center">
          <Heading level="4" align="center">
            {title}
          </Heading>
          <Text align="center">{description}</Text>
        </Stack>
      </Stack>
    </Column>
  );
}

export function ValuePropMarketing() {
  const { t } = useTranslations(translations);
  const { country, zone, locale, language, environment, experience } =
    useConfig();
  return (
    <Stack space="xxlarge">
      <Stack space="xxlarge" align="center">
        <Heading level="2" align="center">
          {t('Get the full picture before you apply')}
        </Heading>
        <Box paddingX="large" width="full">
          <Columns
            space={{
              mobile: 'xlarge',
              desktop: 'xxxlarge',
            }}
            align="center"
            collapseBelow="tablet"
          >
            <ValueProp
              Icon={CultureAndValuesIcon}
              title={t('Culture and values')}
              description={t('Find out about the company culture')}
            />
            <ValueProp
              Icon={RatingsAndReviewsIcon}
              title={t('Ratings and reviews')}
              description={t('Read reviews from employees')}
            />
            <ValueProp
              Icon={PerksAndBenefitsIcon}
              title={t('Perks and benefits')}
              description={t('Find perks that matter to you')}
            />
          </Columns>
        </Box>
      </Stack>
      <Stack space="xxsmall">
        <Actions>
          <ButtonLink
            target="_blank"
            href={buildCommunityGuidelinesURI({ locale, zone, experience })}
            icon={<IconArrow direction="right" />}
            iconPosition="trailing"
            variant="transparent"
          >
            {t('See community guidelines')}
          </ButtonLink>
        </Actions>
        <Actions>
          <ButtonLink
            target="_blank"
            href={buildEmployerCompanyProfilePromoPageURI({
              country,
              environment,
              language,
            })}
            icon={<IconArrow direction="right" />}
            iconPosition="trailing"
            variant="transparent"
          >
            {t('Information for employers')}
          </ButtonLink>
        </Actions>
      </Stack>
    </Stack>
  );
}
