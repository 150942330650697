import { Box, Stack, Text } from 'braid-design-system';
import dayjs from 'dayjs';
import { AspectRatioBox } from '../../AspectRatioBox/AspectRatioBox';
import * as styles from './PhotoTile.css';

interface PhotoTileProps {
  link: string;
  date?: number;
  mode?: 'view' | 'edit';
  onClick?: (e: React.MouseEvent<HTMLElement>) => void;
  title?: string;
}

export const PhotoTile = ({
  link,
  title,
  onClick,
  mode = 'view',
  date,
}: PhotoTileProps) => {
  return (
    <Stack space="small">
        <Box position="relative">
          <AspectRatioBox>
            <Box
              className={styles.imageBox}
              style={{
                backgroundImage: `url(${link})`,
              }}
              borderRadius="large"
            />
          </AspectRatioBox>

          <Box
            component="button"
            className={styles.galleryButton}
            onClick={onClick}
            hidden={mode === 'edit'}
          >
            <Box className={styles.photoTileOverlayBox} borderRadius="large" />
            <Box
              className={styles.photoTileOverlayText}
              padding="medium"
              background="customDark"
            >
              <Text size="small" weight="medium" align="left">
                {title}
              </Text>
            </Box>
          </Box>
        </Box>
        {mode === 'edit' && (
          <Box paddingBottom="medium">
            <Stack space="small">
              <Text weight="strong">{title}</Text>
              {date ? (
                <Text tone="secondary">
                  {dayjs.unix(date).format('DD MMMM, YYYY')}
                </Text>
              ) : null}
            </Stack>
          </Box>
        )}
      </Stack>
  );
};
