import {
  ApacCandidateFooter,
  ApacCandidateHeader,
} from '@seek/apac-candidate-header-footer';
import { Box, Page, PageBlock, Stack } from 'braid-design-system';
import { useParams } from 'react-router';
import type { Language } from '@seek/melways-sites';
import { useConfig } from '@seek/libs-shared';

import { useAuth } from '../../../shared/providers/authProvider';
import type { AppConfig } from '../../../shared/config';
import { getLanguageSwitcher, getLanguageUrl } from './languageSwitcher';

import * as styles from './MainLayout.css';

interface Props extends React.PropsWithChildren {
  pageWidth?: 'large' | 'medium' | undefined;
  showHeaderDivider?: boolean;
  showMarginTopAndBottom?: boolean;
}

export function MainLayout({
  children,
  pageWidth = 'large',
  showHeaderDivider = false,
  showMarginTopAndBottom = true,
}: Props) {
  const config = useConfig<AppConfig>();
  const urlParams = useParams<'locationSlug'>();

  const { authenticationStatus, userProfile } = useAuth();

  const languageSwitcher = getLanguageSwitcher({ urlParams, config });

  const handleLanguageSwitch = (selectedLanguage: Language) =>
    // eslint-disable-next-line no-restricted-globals
    location.replace(
      getLanguageUrl({
        config,
        urlParams,
        selectedLanguage,
        // eslint-disable-next-line no-restricted-globals
        absoluteUrl: location.href,
      }),
    );

  return (
    <Page
      footer={
        <Box className={styles.footerWrapper}>
          <ApacCandidateFooter
            authenticationStatus={authenticationStatus}
            isExpandedVersion
          />
        </Box>
      }
      footerPosition="belowFold"
    >
      <Stack space={showMarginTopAndBottom ? 'large' : 'none'}>
        <ApacCandidateHeader
          authenticationStatus={authenticationStatus}
          userProfile={userProfile}
          returnUrlForPostAuth="/companies"
          activePrimaryTab="company"
          languageSwitcher={languageSwitcher}
          divider={showHeaderDivider}
          onLanguageSwitch={handleLanguageSwitch}
        />
        <PageBlock width={pageWidth}>
          <Box className={styles.body}>{children}</Box>
        </PageBlock>
      </Stack>
    </Page>
  );
}
