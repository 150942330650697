import type { ApolloClient, NormalizedCacheObject } from '@apollo/client';
import type {
  CompanyNameSlug as CompanyNameSlugFromGraph,
  CompanyProfile as CompanyProfileFromGraph,
  GroupedCompanies,
  PostCompanyProfileReviewFlagPayload as ReviewFlagPayloadFromGraph,
  Reviews as ReviewsFromGraph,
  ReviewsRatingSummary,
  PostCompanyProfileReviewUpvotesPayload as ReviewUpvotesPayloadFromGraph,
} from '@seek/ca-graphql-schema/types';
import type {
  CompanyProfile,
  Jobs,
  Logger,
  Reviews,
  ReviewUpvotes as ReviewUpvotesFromGraph,
  SortOrder,
} from '@seek/libs-shared';

import type { CompaniesListModel } from '../../../companyProfiles/models/companiesListModel';
import type { AppConfig } from '../../config';
import { createJobService } from '../jobService/jobService';
import { createLegacyCompanyProfileService } from '../legacyCompanyProfileService/legacyCompanyProfileService';

import {
  GET_COMPANY_NAME_SLUG_QUERY,
  GET_COMPANY_PROFILE_QUERY,
  GET_COMPANY_PROFILES_BY_NAME_QUERY,
  GET_COMPANY_REVIEW_RATING_SUMMARY_QUERY,
  GET_COMPANY_REVIEW_UPVOTES_QUERY,
  GET_GROUPED_COMPANIES_QUERY,
  GET_REVIEWS_QUERY,
  POST_COMPANY_REVIEW_FLAG_MUTATE,
  POST_COMPANY_REVIEW_UPVOTE_MUTATE,
} from './graphqlQueries';
import {
  mapCompanyGroupsToFlatCompanies,
  mapCompanyProfileModel,
  mapCompanyProfileModelFromDraft,
  mapJobsModel,
  mapReviewsModel,
  mapSearchCompanyApiResultToSearchCompanyResult,
  mapSearchCompanyModel,
} from './mapper';
import type {
  SearchCompaniesResult,
  SearchCompanyProfileApiResult,
  SearchCompanyProfileResult,
} from './types';

export type CompanyProfileService = ReturnType<
  typeof createCompanyProfileService
>;

export const createCompanyProfileService = ({
  config,
  client,
  logger,
}: {
  client: ApolloClient<NormalizedCacheObject>;
  config: AppConfig;
  logger: Logger;
}) => {
  const REVIEWS_PER_PAGE = 30;
  const legacyCompanyProfileService = createLegacyCompanyProfileService({
    companyProfilesApiBaseUrl: config.companyProfilesApiBaseUrl,
    config,
    logger,
  });

  const jobService = createJobService({
    config,
    logger,
  });

  const searchCompanies = async ({
    searchTerm,
  }: {
    searchTerm: string;
  }): Promise<SearchCompaniesResult> => {
    try {
      const res = await legacyCompanyProfileService.searchCompanies({
        searchTerm,
      });

      if (!res.data) {
        return { companies: [], totalCompanies: 0 };
      }

      return mapSearchCompanyModel(res);
    } catch (error) {
      throw new Error('Error from searchCompanies', { cause: error });
    }
  };

  const getCompanyProfileForeignId = async (
    companyId: string,
  ): Promise<string> =>
    await legacyCompanyProfileService.getCompanyProfileForeignId(companyId);

  const getJobs = async (companyId: string, page = 1): Promise<Jobs> => {
    const organisationId = await getCompanyProfileForeignId(companyId);

    if (!organisationId) return { jobs: [], totalJobs: 0 };

    const jobs = await jobService.getJobsList({
      organisationId,
      page,
      country: config.country,
      locale: config.locale,
    });

    return mapJobsModel(jobs);
  };

  const getCompanyProfile = async (
    companyId: string,
    isDraft = false,
  ): Promise<CompanyProfile> => {
    if (isDraft) {
      const draft =
        await legacyCompanyProfileService.getLegacyCompanyProfilePreview(
          companyId,
        );

      if (!draft) {
        throw new Error(`Company not found: ${companyId}`);
      }

      const reviewRatingSummaryResponse = await client.query<{
        reviewsRatingSummary: ReviewsRatingSummary;
      }>({
        query: GET_COMPANY_REVIEW_RATING_SUMMARY_QUERY,
        variables: {
          companyId,
        },
      });

      return mapCompanyProfileModelFromDraft(
        draft,
        reviewRatingSummaryResponse.data?.reviewsRatingSummary,
      );
    }

    // only foreignCompanyId and gallery are used from legacyCompanyProfile
    const legacyCompanyProfile =
      await legacyCompanyProfileService.getLegacyCompanyProfile(companyId);

    if (!legacyCompanyProfile)
      throw new Error(`Company not found: ${companyId}`);

    const reviewRatingSummaryResponse = await client.query<{
      reviewsRatingSummary: ReviewsRatingSummary;
    }>({
      query: GET_COMPANY_REVIEW_RATING_SUMMARY_QUERY,
      variables: {
        companyId,
      },
    });
    const response = await client.query<{
      companyProfile: CompanyProfileFromGraph;
    }>({
      query: GET_COMPANY_PROFILE_QUERY,
      variables: {
        companyProfileId: companyId,
        zone: config.zone,
        locale: config.locale,
      },
    });

    return mapCompanyProfileModel({
      companyProfileFromGraph: response.data?.companyProfile,
      companyReviewRatingSummary:
        reviewRatingSummaryResponse.data?.reviewsRatingSummary,
      legacyCompanyProfile,
    });
  };

  const getReviews = async ({
    companyId,
    page = 1,
    sortOrder = 'most recent',
    perPage = REVIEWS_PER_PAGE,
    /**
     *! IMPORTANT NOTE:
     *! locationId is used to filter reviews, ONLY PASS IT when you are in the location based review route.
     *! USE `getLocationId()` helper function to determine if you are in the location based review route.
     *  */
    locationId,
  }: {
    companyId: string;
    locationId?: number;
    page?: number;
    perPage?: number;
    sortOrder?: SortOrder;
  }): Promise<Reviews> => {
    const response = await client.query<{
      companyProfile: CompanyProfileFromGraph;
      companyReviews: ReviewsFromGraph;
    }>({
      query: GET_REVIEWS_QUERY,
      variables: {
        companyProfileId: companyId,
        zone: config.zone,
        locale: config.locale,
        page,
        perPage,
        sort: sortOrder === 'most helpful' ? '-upvote_count' : '',
        locationId,
      },
    });

    return mapReviewsModel(
      response.data.companyProfile,
      response.data.companyReviews,
    );
  };

  const getCompanyNameSlug = async (
    companySlug: string,
    isLegacyUrl: boolean,
  ): Promise<CompanyNameSlugFromGraph> => {
    const response = await client.query<{
      companyNameSlug: CompanyNameSlugFromGraph;
    }>({
      query: GET_COMPANY_NAME_SLUG_QUERY,
      variables: {
        companySlug,
        zone: config.zone,
        isLegacyUrl,
      },
    });

    return response?.data?.companyNameSlug;
  };

  const getUpvoteIds = async ({
    companyId,
  }: {
    companyId: string;
  }): Promise<ReviewUpvotesFromGraph> => {
    const response = await client.query<{
      companyProfileReviewUpvotes: ReviewUpvotesFromGraph;
    }>({
      query: GET_COMPANY_REVIEW_UPVOTES_QUERY,
      variables: {
        companyProfileReviewUpvotesId: companyId,
        zone: config.zone,
      },
    });

    return response?.data?.companyProfileReviewUpvotes;
  };

  const postUpvoteReview = async ({
    companyId,
    reviewId,
  }: {
    companyId: string;
    reviewId: string;
  }): Promise<ReviewUpvotesPayloadFromGraph | undefined> => {
    const response = await client.mutate<{
      postCompanyProfileReviewUpvotes: ReviewUpvotesPayloadFromGraph;
    }>({
      mutation: POST_COMPANY_REVIEW_UPVOTE_MUTATE,
      variables: {
        input: {
          companyId,
          reviewId,
        },
      },
    });

    return response?.data?.postCompanyProfileReviewUpvotes;
  };

  const postFlagReview = async ({
    companyId,
    reviewId,
    reason,
    details,
  }: {
    companyId: string;
    details: string;
    reason: string;
    reviewId: string;
  }): Promise<ReviewFlagPayloadFromGraph | undefined> => {
    const response = await client.mutate<{
      postCompanyProfileReviewFlag: ReviewFlagPayloadFromGraph;
    }>({
      mutation: POST_COMPANY_REVIEW_FLAG_MUTATE,
      variables: {
        input: {
          companyId,
          reviewId,
          reason,
          details,
        },
      },
    });

    return response?.data?.postCompanyProfileReviewFlag;
  };

  const getGroupedCompanies = async ({
    groupBy,
    perPage = 10,
    sortBy,
    excludedCompanyIds = [],
    randomSeed,
  }: {
    groupBy: {
      group: 'INDUSTRY';
      value: string[];
    };
    excludedCompanyIds?: string[];
    perPage?: number;
    randomSeed?: string;
    sortBy?: 'REVIEWS_COUNT';
  }): Promise<CompaniesListModel> => {
    const response = await client.query<{
      groupedCompanies: GroupedCompanies[];
    }>({
      query: GET_GROUPED_COMPANIES_QUERY,
      variables: {
        groupBy,
        perPage,
        sortBy,
        zone: config.zone,
        excludedCompanyIds,
        randomSeed,
      },
    });
    return mapCompanyGroupsToFlatCompanies(response.data.groupedCompanies);
  };

  const getCompanyProfilesByName = async (
    name: string,
    includeBranding = false,
  ): Promise<SearchCompanyProfileResult> => {
    const response = await client.query<{
      searchCompanyProfilesByName: SearchCompanyProfileApiResult;
    }>({
      query: GET_COMPANY_PROFILES_BY_NAME_QUERY,
      variables: {
        name,
        includeBranding,
      },
      fetchPolicy: 'no-cache',
    });

    return mapSearchCompanyApiResultToSearchCompanyResult(
      response?.data?.searchCompanyProfilesByName,
    );
  };

  return {
    getCompanyNameSlug,
    getCompanyProfile,
    getCompanyProfileForeignId,
    getJobs,
    getReviews,
    getUpvoteIds,
    postUpvoteReview,
    postFlagReview,
    searchCompanies,
    getGroupedCompanies,
    getCompanyProfilesByName,
  };
};
