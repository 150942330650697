import { v4 as uuid } from 'uuid';

import type { Logger } from '../logging/logger';

export interface TypedResponse<TResponse> extends Response {
  contentType: string;
  data: TResponse | undefined;
  status: number;
  statusText: string;
}

export const creatRestClient = (
  baseUrl: string,
  logger: Logger,
  headers?: RequestInit['headers'],
) => {
  const send = async <TRequest, TResponse>(
    path: string,
    method: string,
    request?: TRequest | undefined,
    options?: Partial<RequestInit>,
  ) => {
    const requestUrl = `${baseUrl}${path}`;

    try {
      const response = await fetch(requestUrl, {
        ...options,
        method,
        mode: typeof window === 'undefined' ? 'no-cors' : 'cors',
        headers: {
          ...headers,
          'x-request-id': uuid(),
          ...options?.headers,
        },
        body: request ? JSON.stringify(request) : undefined,
      });

      let bodyContent: TResponse | undefined;
      try {
        const body = await response.text();
        if (body) {
          try {
            bodyContent = JSON.parse(body) as TResponse;
          } catch {
            bodyContent = body as TResponse;
          }
        }
      } catch (error) {
        logger.error({ requestUrl, error }, 'Error in Requesting External API');
      }

      const fetchResonse: TypedResponse<TResponse> = {
        ...response,
        data: bodyContent as TResponse,
        status: response.status,
        contentType: response.headers.get('content-type') ?? '',
      };

      return fetchResonse;
    } catch (error: unknown) {
      if (error instanceof Error && error.name === 'AbortError') {
        logger.warn({ requestUrl }, 'Request aborted due to client disconnect');

        /* Create a special response object for aborted requests
         * Requests are aborted when a client:
         * Navigates away from a page before a fetch request completes
         * Closes their browser tab during an active request
         * Clicks a link that takes them to another page while data is loading
         * Refreshes the page while a request is in progress
         */
        const abortedResponse: TypedResponse<TResponse> = {
          ok: false,
          status: 499, // Using 499 as a convention for client disconnected
          statusText: 'Client Disconnected',
          data: undefined as TResponse,
          contentType: '',
          headers: new Headers(),
          type: 'error',
          url: requestUrl,
          redirected: false,
          body: null,
          bodyUsed: false,
          clone: () => abortedResponse,
          arrayBuffer: () => Promise.reject(error),
          blob: () => Promise.reject(error),
          formData: () => Promise.reject(error),
          json: () => Promise.reject(error),
          text: () => Promise.reject(error),
          bytes: () => Promise.reject(error),
        };

        return abortedResponse;
      } else {
        // Re-throw for the caller to handle
        throw error;
      }
    }
  };

  const get = async <TResponse = void>(path: string, options?: RequestInit) =>
    send<undefined, TResponse>(path, 'GET', undefined, options);

  return {
    get,
  };
};
