import { CompanyProfileCarousel } from '@seek/branding-assets';
import { buildWriteAReviewURI, useConfig } from '@seek/libs-shared';
import { useLayoutEffect, useState } from 'react';

const HideReviewsCountries = ['th', 'hk', 'nz'];
export function ExploreCompanies({ userId }: { userId?: number }) {
  const [mounted, setMounted] = useState(false);
  const [isCompanyCarouselError, setIsCompanyCarouselError] = useState(false);
  const { country, language, environment, zone, consolidated } = useConfig();
  

  const writeAReviewUrl = buildWriteAReviewURI({
    country,
    environment,
    language,
    consolidated,
  });

  useLayoutEffect(() => {
    setMounted(true)
  }, []);

  if (!mounted || isCompanyCarouselError) return null;

  return (
    <CompanyProfileCarousel
      analyticsQueryParams="cid=sk-reviews-carousel&ref=sk-reviews-carousel&tracking=companies"
      hideReviews={HideReviewsCountries.includes(country)}
      onReviewsLandingPage
      onError={() => {
        setIsCompanyCarouselError(true);
      }}
      writeAReviewUrl={writeAReviewUrl}
      payload={{
        currentPage: 'company overview',
        siteCountry: country,
        siteLanguage: language,
        zone,
        isLoggedIn: Boolean(userId),
        siteSection: 'discover',
        loginId: userId?.toString() || '',
        companyViewOrigin: 'sk-reviews-carousel',
      }}
    />
  );
}
