import { useConfig } from '@seek/libs-shared';
import { createAbsoluteUrl } from '@seek/melways-sites';
import { useTranslations } from '@vocab/react';
import { Stack, Text, TextLink } from 'braid-design-system';

import translations from './.vocab';
import { NoJobsFoundIcon } from './NoJobsFoundIcon';
import type { AppConfig } from '../../../shared/config';

export function NoJobsFound({ companyName }: { companyName: string }) {
  const { t } = useTranslations(translations);
  const { country, language, consolidated, environment } =
    useConfig<AppConfig>();

  return (
    <Stack space="large" align="center">
      <NoJobsFoundIcon />
      <Stack space="medium" align="center">
        <Text tone="secondary" align="center">
          {t('{companyName} does not have any active jobs right now.', {
            companyName,
          })}
        </Text>
        <Text tone="secondary" align="center">
          {t('Please use our Job search to look for open vacancies.', {
            TextLink: (children) => (
              <TextLink
                href={createAbsoluteUrl({
                  path: '/jobs',
                  country,
                  product: 'candidate',
                  language,
                  ...(consolidated ? { alternative: 'subDomainRegion' } : {}),
                  staging: environment !== 'production',
                })}
              >
                {children}
              </TextLink>
            ),
          })}
        </Text>
      </Stack>
    </Stack>
  );
}
