import { useState } from 'react';
import { useTranslations } from '@vocab/react';
import {
  Stack,
  Inline,
  TextLink,
  Box,
  Text,
  TextLinkButton,
  IconChevron,
  Tiles,
  Hidden,
} from 'braid-design-system';

import * as styles from './SeoList.css';
import translations from './.vocab';
import { TOP_SEO_ITEMS } from './constants';

const SeoLink = ({
  name,
  url,
  key,
}: {
  key: string;
  name: string;
  url: string;
}) => (
  <Text tone="secondary" size="small" key={key}>
    <TextLink target="_top" href={url} weight="weak">
      {name}
    </TextLink>
  </Text>
);

export const SeoList = ({
  title,
  seoList,
}: {
  seoList: { name: string; url: string }[];
  title: string;
}) => {
  const [hideTopItems, setHideTopItems] = useState<boolean>(true);
  const { t } = useTranslations(translations);
  return (
    <Hidden below="tablet">
      <Stack space="large">
        <Text weight="strong">{title}</Text>
        <Inline space={'small'}>
          {seoList.slice(0, TOP_SEO_ITEMS).map((item, index) => (
            <SeoLink {...item} key={`${item.name}-${index.toString()}`} />
          ))}
          {seoList.length > TOP_SEO_ITEMS && (
            <Box
              onClick={() => {
                setHideTopItems(!hideTopItems);
              }}
              cursor="pointer"
            >
              <Inline space={'xxsmall'}>
                <TextLinkButton>
                  <Text tone="secondary" size="small">
                    {t('View all')}
                  </Text>
                </TextLinkButton>
                <Box
                  className={`${hideTopItems ? '' : styles.rotated}`}
                  style={{ transition: 'transform 0.2s ease-in-out' }}
                >
                  <Text size="small">
                    <IconChevron alignY="lowercase" />
                  </Text>
                </Box>
              </Inline>
            </Box>
          )}
        </Inline>
        {seoList.length > TOP_SEO_ITEMS && (
          <Box
            style={{
              display: hideTopItems ? 'none' : 'unset',
              transition: 'transform 0.2s ease-in-out opacity 0.2s ease-in-out',
            }}
          >
            <Tiles columns={4} space={'medium'}>
              {seoList.slice(TOP_SEO_ITEMS).map((item, index) => (
                <SeoLink {...item} key={`${item.name}-${index.toString()}`} />
              ))}
            </Tiles>
          </Box>
        )}
      </Stack>
    </Hidden>
  );
};
