import type { Jobs } from '@seek/libs-shared';
import type { Locale } from '@seek/melways-sites';

import type { GetJobsListResponse } from './types';

export const mapJobsModel = (legacyJobs?: GetJobsListResponse): Jobs => ({
  jobs:
    legacyJobs?.data?.map((job) => ({
      id: job.id,
      title: job.title,
      url: `/job/${job.id}`,
      advertiser: job.advertiser?.description || '',
      classification: job.classifications?.length
        ? job.classifications[0].classification?.description || ''
        : '',
      subClassification: job.classifications?.length
        ? job.classifications[0].subClassification?.description || ''
        : '',
      description: job.teaser,
      createdAt: job.listingDate,
      listingDateDisplay: job.listingDateDisplay,
      bulletPoints: job.bulletPoints,
      salaryDisplayFlag: false,
      location: job.locations?.length ? job.locations[0].label : '',
    })) ?? [],
  totalJobs:
    legacyJobs?.solMetadata?.totalJobCount ?? legacyJobs?.data?.length ?? 0,
});

export const buildUrl = (prefix: string, params: string[]): string =>
  `${prefix}?${params.filter((param) => param !== '').join('&')}`;

export const buildJobSearchUrlForSearch = ({
  organisationId,
  page,
  pageSize,
  country,
  source,
  locale,
}: {
  country: string;
  locale: Locale;
  organisationId: string;
  page: number;
  pageSize: number;
  source: string;
}): string =>
  buildUrl(`/search`, [
    `companyid=${organisationId}`,
    page && page.toString() !== '1' ? `page=${page}` : '',
    `pagesize=${pageSize}`,
    `siteKey=${country}`,
    `source=${source}`,
    `locale=${locale}`,
    `include=nofeatured`,
  ]);
