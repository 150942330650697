import { useTranslations } from '@vocab/react';
import { Box, Stack, Text, Tiles } from 'braid-design-system';

import translations from './.vocab';
import { Section } from '../../common/section/Section';
import { UpdateSuccessAlert, type Award as AwardType } from '../../../';
import { AutoFitImage } from '../../common/AutoFitImage/AutoFitImage';

const MAX_AWARDS = 12;

interface Props {
  mode: 'view' | 'edit';
  model?: AwardType[];
  onEditClick?: () => void;
  onHideUpdateSuccess?: () => void;
  showUpdateSuccess?: boolean;
}

const Award = ({
  name,
  year,
  imageUrl,
}: {
  imageUrl: AwardType['imageUrl'];
  mode: Props['mode'];
  name: AwardType['name'];
  year: AwardType['year'];
}) => {
  return (
    <Stack space="small">
      <AutoFitImage src={imageUrl} alt={`${name} ${year}`} isImageLeftAlign />
      <Box>
        <Text weight="medium">{`${name} ${year}`}</Text>
      </Box>
    </Stack>
  );
};

export function AwardsSection({
  mode,
  model,
  showUpdateSuccess = false,
  onEditClick,
  onHideUpdateSuccess,
}: Props) {
  const { t } = useTranslations(translations);

  const awards =
    model?.slice(0, MAX_AWARDS).sort((a, b) => b.year - a.year) || [];

  return (
    <Section
      mode={mode}
      heading={t('Awards and accreditations')}
      paddingBottom={mode === 'view' ? 'none' : 'large'}
      dataEmpty={!awards.length}
      addDataHint={t(
        'Feature awards and accreditations your company has received.',
      )}
      onEditClick={onEditClick}
    >
      <Stack space="gutter">
        {showUpdateSuccess && (
          <UpdateSuccessAlert
            onClose={() => {
              if (onHideUpdateSuccess) onHideUpdateSuccess();
            }}
            text={t('Awards and accreditations updated')}
          />
        )}
        <Tiles columns={[2, 3, 4]} space="medium">
          {awards.map(({ name, year, imageUrl }, index) => (
            <Award
              key={index}
              mode={mode}
              name={name}
              year={year}
              imageUrl={imageUrl}
            />
          ))}
        </Tiles>
      </Stack>
    </Section>
  );
}
