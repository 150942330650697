import { useTranslations } from '@vocab/react';
import {
  Autosuggest,
  filterSuggestions,
  IconSearch,
} from 'braid-design-system';
import { useEffect, useState } from 'react';
import { useDebounce } from 'use-debounce';

import type { RoleTitle } from '../../../../shared/services/roleService/roleService';
import type { QuestionProps } from '../../../type';
import { COMPANY_NAME_MAX_LENGTH } from '../../../validation/validationRules';
import translations from './.vocab';
export interface JobTitleValue {
  id?: string;
  text?: string;
}

type JobTitleQuestionProps = QuestionProps<JobTitleValue> & {
  getRoleTitles: (query: string) => Promise<RoleTitle[]>;
  onValueSelected: () => void;
};

interface AutosuggestValue {
  text: string;
  description?: string;
  value?: string;
}

export const JobTitleQuestion = ({
  getRoleTitles,
  onChange,
  message,
  tone,
  value,
  onValueSelected,
}: JobTitleQuestionProps) => {
  const MINIMUM_CHARACTERS_SEARCH = 3;
  const CUSTOM_ROLE_TITLE = 'CUSTOM_ROLE_TITLE';

  const { t } = useTranslations(translations);

  const [suggestions, setSuggestions] = useState<AutosuggestValue[]>([]);
  const [searchTerm, setSearchTerm] = useState<AutosuggestValue>({
    text: value?.text || '',
    value: value?.id,
  });

  const [debouncedSearchTerm] = useDebounce(searchTerm, 400);

  const handleOnChangeAutosuggest = (event: AutosuggestValue) => {
    setSearchTerm(event);
    if (!event.text || event.text.length < MINIMUM_CHARACTERS_SEARCH) {
      setSuggestions([]);
      onChange({});
      return;
    }
    if (event.text) {
      onValueSelected();
    } else {
      onChange({});
    }
  };

  const onClear = () => {
    setSearchTerm({ text: '' });
    onChange({});
    setSuggestions([]);
  };

  useEffect(() => {
    const fetchJobTitles = async (text: string) => {
      const roleTitles = await getRoleTitles(text);

      const isRoleFound = roleTitles.some(
        (role) => role.normalisedText.toLowerCase() === text.toLowerCase(),
      );

      const allRoleTitles = [
        ...roleTitles,
        ...(isRoleFound
          ? []
          : [
              {
                normalisedText: text,
                id: CUSTOM_ROLE_TITLE,
              },
            ]),
      ];

      const roleSuggestions = allRoleTitles.map((role) => ({
        text: role.normalisedText,
        value: role.id,
      }));

      setSuggestions(roleSuggestions);

      onChange({
        text: searchTerm.text,
        id: roleSuggestions.find(
          (option) =>
            option.text.toLowerCase() === searchTerm.text.toLowerCase(),
        )?.value,
      });
    };

    const debouncedSearchTermText = debouncedSearchTerm.text?.trim();
    if (
      debouncedSearchTermText &&
      debouncedSearchTermText.length >= MINIMUM_CHARACTERS_SEARCH &&
      debouncedSearchTermText.length <= COMPANY_NAME_MAX_LENGTH
    ) {
      fetchJobTitles(debouncedSearchTermText);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedSearchTerm]);

  return (
    <Autosuggest
      id="jobTitle"
      label={t('Job title')}
      aria-label={t('Job title')}
      icon={<IconSearch />}
      value={searchTerm}
      onChange={handleOnChangeAutosuggest}
      onClear={onClear}
      suggestions={filterSuggestions(suggestions)}
      tone={tone}
      message={message}
      suggestionHighlight="matching"
      reserveMessageSpace={true}
      automaticSelection={
        suggestions[0]?.text.toLowerCase() === searchTerm.text.toLowerCase()
      }
    />
  );
};
