export const removeUndefinedProps = <T extends Record<string, unknown>>(
  obj: T,
): T =>
  Object.keys(obj).reduce((acc, key) => {
    if (obj[key] !== undefined) {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      (acc as any)[key] = obj[key];
    }
    return acc;
  }, {} as T);
