import { useTranslations } from '@vocab/react';
import {
  Box,
  IconChevron,
  Inline,
  Rating,
  Stack,
  Text,
} from 'braid-design-system';
import { useState } from 'react';

import type { ReviewRowModel } from '../../../';

import translations from './.vocab';

interface Props {
  model: Pick<ReviewRowModel, 'overallRating' | 'ratings'>;
  showBreakdown?: boolean;
}

interface RatingOverallProp {
  data: Pick<ReviewRowModel, 'overallRating'>;
  showBreakdown?: boolean;
}

const RatingOverall = ({
  data: { overallRating },
  showBreakdown = true,
}: RatingOverallProp) =>
  overallRating && overallRating > 0 ? (
    <Inline space={['xxsmall']} alignY="center">
      <Rating size="large" rating={overallRating} variant="starsOnly" />
      <Inline space="xxsmall">
        <Text>{overallRating.toPrecision(2)}</Text>
        {showBreakdown && (
          <Text size="small">
            <IconChevron />
          </Text>
        )}
      </Inline>
    </Inline>
  ) : null;

interface RatingBreakdownProp {
  data: Pick<ReviewRowModel, 'ratings'>;
}

const RatingBreakdown = ({ data }: RatingBreakdownProp) => {
  const { t } = useTranslations(translations);

  const reviewSummaryBreakdowns = data.ratings || [];

  type DefaultDescription =
    | 'Work/Life balance'
    | 'Career development'
    | 'Benefits & perks'
    | 'Management'
    | 'Working environment'
    | 'Diversity & equal opportunity';

  const getRatingByDescription = (description: DefaultDescription) => {
    const ratingItem = reviewSummaryBreakdowns.find(
      (item) => item.description === description,
    );
    return ratingItem && ratingItem.rating > 0
      ? { description: t(description), rating: ratingItem.rating }
      : null;
  };
  const ratings = [
    getRatingByDescription('Work/Life balance'),
    getRatingByDescription('Career development'),
    getRatingByDescription('Benefits & perks'),
    getRatingByDescription('Management'),
    getRatingByDescription('Working environment'),
    getRatingByDescription('Diversity & equal opportunity'),
  ];

  return (
    <Stack space="gutter">
      {ratings.map((rating) => {
        if (rating === null) {
          return null;
        } else {
          return (
            <Stack
              space="xsmall"
              key={`${rating.description}_${rating.rating}`}
            >
              <Text size="small">{rating.description}</Text>
              <Rating rating={rating.rating} variant="starsOnly" size="small" />
            </Stack>
          );
        }
      })}
    </Stack>
  );
};

const TooltipBox = ({
  model,
}: {
  model: Pick<ReviewRowModel, 'overallRating' | 'ratings'>;
}) => {
  const [showBreakdownPopup, setShowBreakdownPopup] = useState(false);

  const shouldShowBreakdown = Boolean(model.ratings?.length)

  return (
    <>
      <Box
        cursor={shouldShowBreakdown ? 'pointer' : 'default'}
        onMouseEnter={() => {
          setShowBreakdownPopup(true);
        }}
        onMouseLeave={() => setShowBreakdownPopup(false)}
      >
        <RatingOverall data={model} showBreakdown={shouldShowBreakdown} />
      </Box>
      {shouldShowBreakdown && showBreakdownPopup && (
        <Box
          position="absolute"
          zIndex="sticky"
          boxShadow="medium"
          background="surface"
          borderRadius="large"
          marginTop={'medium'}
        >
          <Box
            boxShadow="borderNeutralLight"
            padding="gutter"
            borderRadius="large"
          >
            <RatingBreakdown data={model} />
          </Box>
        </Box>
      )}
    </>
  );
};

export const ReviewRowRating = ({ showBreakdown = true, model }: Props) =>
  showBreakdown ? (
    <TooltipBox model={model} />
  ) : (
    <RatingOverall data={model} showBreakdown={showBreakdown} />
  );
