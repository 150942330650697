import { useTranslations } from '@vocab/react';
import {
  StarSelector,
  type Rating,
} from '../../components/StarSelector/StarSelector';
import type { QuestionProps } from '../../type';
import translations from './.vocab';

export const WorkLifeBalanceRatingQuestion = (props: QuestionProps<Rating>) => {
  const { t } = useTranslations(translations);
  return (
    <StarSelector
      id="workLifeBalanceRating"
      {...props}
      title={t('Work/life balance')}
    />
  );
};
