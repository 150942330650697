import type { Logger as PinoLogger } from '@seek/logger';

export type LogFn = PinoLogger['info'];

export type Logger = Pick<
  PinoLogger,
  'info' | 'error' | 'warn' | 'debug' | 'fatal' | 'trace'
>;

export const createClientLogger = () => {
  const logger: Logger = {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    debug: (obj: unknown, msg?: string, ...args: any[]) => {
      // eslint-disable-next-line no-console
      console.debug(obj || msg, msg || args);
    },
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    error: (obj: unknown, msg?: string, ...args: any[]) => {
      // eslint-disable-next-line no-console
      console.error(obj || msg, msg || args);
    },
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    fatal: (obj: unknown, msg?: string, ...args: any[]) => {
      // eslint-disable-next-line no-console
      console.error(obj || msg, msg || args);
    },
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    info: (obj: unknown, msg?: string, ...args: any[]) => {
      // eslint-disable-next-line no-console
      console.info(obj || msg, msg || args);
    },
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    trace: (obj: unknown, msg?: string, ...args: any[]) => {
      // eslint-disable-next-line no-console
      console.trace(obj || msg, msg || args);
    },
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    warn: (obj: unknown, msg?: string, ...args: any[]) => {
      // eslint-disable-next-line no-console
      console.warn(obj || msg, msg || args);
    },
  };

  return logger;
};
