import { AUTHENTICATION_TYPES } from '@seek/apac-candidate-header-footer/types';
import {
  Box,
  Button,
  ButtonIcon,
  Card,
  Column,
  Columns,
  Divider,
  IconFlag,
  IconThumb,
  Inline,
  Loader,
  Stack,
  Text,
} from 'braid-design-system';

import { type ReviewRowModel } from '@seek/libs-shared';
import { useTranslations } from '@vocab/react';
import { useState } from 'react';
import { type FlagReviewPayload } from '../../../../';
import { useAuth } from '../../../../shared/providers/authProvider';
import { ReportConfirmedDialog } from '../reportConfirmedDialog/ReportConfirmedDialog';
import { ReportReviewForm } from '../reportReviewForm/ReportReviewForm';
import translations from './.vocab';
import { ReviewReply } from './ReviewReply';
import { ReviewRowRatingDetails } from './ReviewRowRatingDetails';
import { ReviewRowRatingSummary } from './ReviewRowRatingSummary';

interface Props {
  flagReview: (
    companyId: string,
    reviewId: string,
    payload: FlagReviewPayload,
  ) => Promise<boolean>;
  index: number;
  model: ReviewRowModel;
  upvoteReview: ({
    reviewId,
    companyId,
  }: {
    companyId: string;
    reviewId: string;
  }) => Promise<void>;
  isOnReviewPage?: boolean;
  isReplyReviewAccessCodeValid?: boolean;
  newUpvotedReviewsIds?: string[];
  upvotedReviewsIds?: string[];
}

export const ReviewRow = ({
  model,
  upvoteReview,
  flagReview,
  isOnReviewPage = false,
  upvotedReviewsIds = [],
  newUpvotedReviewsIds = [],
  isReplyReviewAccessCodeValid = false,
  index,
}: Props) => {
  const { t } = useTranslations(translations);
  const { authenticationStatus, ssoLogin } = useAuth();
  const [showReportForm, setShowReportForm] = useState(false);
  const [showReportConfirmedDialog, setShowReportConfirmedDialog] =
    useState(false);

  const displayThankYouBox = () => {
    setShowReportForm(false);
    setShowReportConfirmedDialog(true);
  };

  const closeReportForm = () => {
    setShowReportForm(false);
  };

  const closeReportConfirmedDialog = () => {
    setShowReportConfirmedDialog(false);
  };

  if (!model) {
    return <Loader />;
  }

  const { companyId, id, upvotes, reply } = model;

  const onFlagClick = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.currentTarget.blur();
    // if unathenticated, redirect to login page
    if (
      authenticationStatus === AUTHENTICATION_TYPES.UNAUTHENTICATED &&
      typeof ssoLogin === 'function'
    ) {
      ssoLogin();
    } else {
      setShowReportForm(true);
    }
  };

  const showUpvoteIcon = !isOnReviewPage && typeof upvoteReview === 'function';
  const upvoteCount =
    (upvotes?.count || 0) + (newUpvotedReviewsIds?.includes(id) ? 1 : 0);

  return (
    <Box id={`review-card-${index}`}>
      <Card>
        <Columns space="medium" collapseBelow="tablet">
          <Column width="1/4">
            <ReviewRowRatingSummary model={model} showBreakdown={true} />
          </Column>
          <Column width="3/4">
            <Stack space="large">
              <ReviewRowRatingDetails
                model={model}
                isOnReviewPage={isOnReviewPage}
                isReplyReviewAccessCodeValid={isReplyReviewAccessCodeValid}
              />
              <Columns space="none" alignY="top">
                <Column>
                  {showUpvoteIcon && (
                    <Inline
                      space="small"
                      alignY="center"
                      data={{ testid: `upvote-${id}` }}
                    >
                      <Button
                        variant={
                          upvotedReviewsIds?.includes(id) ? 'solid' : 'soft'
                        }
                        size="small"
                        onClick={() => {
                          if (upvotedReviewsIds?.includes(id)) return;
                          upvoteReview({ reviewId: id, companyId });
                        }}
                      >
                        <IconThumb /> {t('Helpful?')}
                      </Button>
                      {upvoteCount ? (
                        <Text size="small" tone="neutral">
                          {upvoteCount >= 1 &&
                            `${
                              upvoteCount === 1
                                ? t('1 person')
                                : `${upvoteCount} ${t('people')}`
                            } ${t('found this helpful')}`}
                        </Text>
                      ) : null}
                    </Inline>
                  )}
                </Column>
                <Column width="content">
                  <ButtonIcon
                    onClick={(e) => {
                      onFlagClick(e);
                    }}
                    icon={<IconFlag />}
                    id={`Flag review ${index}`}
                    data={{ testid: `flag-review-${id}` }}
                    label={t('Report this review')}
                  />
                </Column>
              </Columns>
              {reply && (
                <>
                  <Divider />
                  <ReviewReply reply={reply} />
                </>
              )}
            </Stack>
          </Column>
        </Columns>

        <ReportReviewForm
          model={{ companyId, reviewId: id }}
          displayThankYouBox={displayThankYouBox}
          onClose={closeReportForm}
          isOpen={showReportForm}
          flagReview={flagReview}
        />
        <ReportConfirmedDialog
          isOpen={showReportConfirmedDialog}
          onClose={closeReportConfirmedDialog}
        />
      </Card>
    </Box>
  );
};
