
      import { createLanguage, createTranslationFile } from '@vocab/webpack/web';
      
    const translations = createTranslationFile({
      "en": createLanguage(
        require.resolveWeak("./translations.json-en-virtual.json!=!../../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJUaXRsZSI6IlJldmlld3Mgb3ZlcnZpZXciLCJTZWNvbmRhcnkgdGl0bGUiOiJSZWNlbnQgcmV2aWV3cyIsIlNlZSBhbGwgcmV2aWV3cyI6IlNlZSBhbGwgcmV2aWV3cyIsIkNvbW11bml0eSBndWlkZWxpbmUgZGVzY3JpcHRpb24iOiJSYXRpbmdzIGZvciB7Y29tcGFueU5hbWV9IGFyZSBzaGFyZWQgYXMtaXMgZnJvbSBlbXBsb3llZXMgaW4gbGluZSB3aXRoIG91ciA8VGV4dExpbms%2BY29tbXVuaXR5IGd1aWRlbGluZXM8L1RleHRMaW5rPiIsIklzIHRoaXMgQUkgc3VtbWFyeSB1c2VmdWw%2FIjoiSXMgdGhpcyBBSSBzdW1tYXJ5IHVzZWZ1bD8iLCJZZXMiOiJZZXMiLCJObyI6Ik5vIn0%3D!"),
        () => import(
          /* webpackChunkName: "en-translations" */
          "./translations.json-en-virtual.json!=!../../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJUaXRsZSI6IlJldmlld3Mgb3ZlcnZpZXciLCJTZWNvbmRhcnkgdGl0bGUiOiJSZWNlbnQgcmV2aWV3cyIsIlNlZSBhbGwgcmV2aWV3cyI6IlNlZSBhbGwgcmV2aWV3cyIsIkNvbW11bml0eSBndWlkZWxpbmUgZGVzY3JpcHRpb24iOiJSYXRpbmdzIGZvciB7Y29tcGFueU5hbWV9IGFyZSBzaGFyZWQgYXMtaXMgZnJvbSBlbXBsb3llZXMgaW4gbGluZSB3aXRoIG91ciA8VGV4dExpbms%2BY29tbXVuaXR5IGd1aWRlbGluZXM8L1RleHRMaW5rPiIsIklzIHRoaXMgQUkgc3VtbWFyeSB1c2VmdWw%2FIjoiSXMgdGhpcyBBSSBzdW1tYXJ5IHVzZWZ1bD8iLCJZZXMiOiJZZXMiLCJObyI6Ik5vIn0%3D!"
        )
      ),
"th": createLanguage(
        require.resolveWeak("./translations.json-th-virtual.json!=!../../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJUaXRsZSI6IuC4o%2BC4teC4p%2BC4tOC4p%2BC5guC4lOC4ouC4oOC4suC4nuC4o%2BC4p%2BC4oSIsIlNlY29uZGFyeSB0aXRsZSI6IuC4o%2BC4teC4p%2BC4tOC4p%2BC4peC5iOC4suC4quC4uOC4lCIsIlNlZSBhbGwgcmV2aWV3cyI6IuC4lOC4ueC4o%2BC4teC4p%2BC4tOC4p%2BC4l%2BC4seC5ieC4h%2BC4q%2BC4oeC4lCIsIkNvbW11bml0eSBndWlkZWxpbmUgZGVzY3JpcHRpb24iOiLguITguLDguYHguJnguJnguKrguLPguKvguKPguLHguJoge2NvbXBhbnlOYW1lfSDguIjguLDguYHguKrguJTguIfguJXguLLguKHguITguLDguYHguJnguJnguIjguKPguLTguIfguIjguLLguIHguJ7guJnguLHguIHguIfguLLguJkg4LmA4Lie4Li34LmI4Lit4LmD4Lir4LmJ4LmA4Lib4LmH4LiZ4LmE4Lib4LiV4Liy4LihPFRleHRMaW5rPuC5geC4meC4p%2BC4l%2BC4suC4h%2BC4m%2BC4j%2BC4tOC4muC4seC4leC4tOC4guC4reC4h%2BC4iuC4uOC4oeC4iuC4mTwvVGV4dExpbms%2B4LiC4Lit4LiH4LmA4Lij4LiyIiwiSXMgdGhpcyBBSSBzdW1tYXJ5IHVzZWZ1bD8iOiLguJrguJfguKrguKPguLjguJvguYLguJTguKIgQUkg4LiZ4Li14LmJ4Lih4Li14Lib4Lij4Liw4LmC4Lii4LiK4LiZ4LmM4Lir4Lij4Li34Lit4LmE4Lih4LmIPyIsIlllcyI6IuC5g%2BC4iuC5iCIsIk5vIjoi4LmE4Lih4LmI4LmD4LiK4LmIIn0%3D!"),
        () => import(
          /* webpackChunkName: "th-translations" */
          "./translations.json-th-virtual.json!=!../../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJUaXRsZSI6IuC4o%2BC4teC4p%2BC4tOC4p%2BC5guC4lOC4ouC4oOC4suC4nuC4o%2BC4p%2BC4oSIsIlNlY29uZGFyeSB0aXRsZSI6IuC4o%2BC4teC4p%2BC4tOC4p%2BC4peC5iOC4suC4quC4uOC4lCIsIlNlZSBhbGwgcmV2aWV3cyI6IuC4lOC4ueC4o%2BC4teC4p%2BC4tOC4p%2BC4l%2BC4seC5ieC4h%2BC4q%2BC4oeC4lCIsIkNvbW11bml0eSBndWlkZWxpbmUgZGVzY3JpcHRpb24iOiLguITguLDguYHguJnguJnguKrguLPguKvguKPguLHguJoge2NvbXBhbnlOYW1lfSDguIjguLDguYHguKrguJTguIfguJXguLLguKHguITguLDguYHguJnguJnguIjguKPguLTguIfguIjguLLguIHguJ7guJnguLHguIHguIfguLLguJkg4LmA4Lie4Li34LmI4Lit4LmD4Lir4LmJ4LmA4Lib4LmH4LiZ4LmE4Lib4LiV4Liy4LihPFRleHRMaW5rPuC5geC4meC4p%2BC4l%2BC4suC4h%2BC4m%2BC4j%2BC4tOC4muC4seC4leC4tOC4guC4reC4h%2BC4iuC4uOC4oeC4iuC4mTwvVGV4dExpbms%2B4LiC4Lit4LiH4LmA4Lij4LiyIiwiSXMgdGhpcyBBSSBzdW1tYXJ5IHVzZWZ1bD8iOiLguJrguJfguKrguKPguLjguJvguYLguJTguKIgQUkg4LiZ4Li14LmJ4Lih4Li14Lib4Lij4Liw4LmC4Lii4LiK4LiZ4LmM4Lir4Lij4Li34Lit4LmE4Lih4LmIPyIsIlllcyI6IuC5g%2BC4iuC5iCIsIk5vIjoi4LmE4Lih4LmI4LmD4LiK4LmIIn0%3D!"
        )
      ),
"id": createLanguage(
        require.resolveWeak("./translations.json-id-virtual.json!=!../../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJUaXRsZSI6IlVsYXNhbiBkYW4gcGVuaWxhaWFuIiwiU2Vjb25kYXJ5IHRpdGxlIjoiVWxhc2FuIHRlcmJhcnUiLCJTZWUgYWxsIHJldmlld3MiOiJMaWhhdCBzZW11YSB1bGFzYW4iLCJDb21tdW5pdHkgZ3VpZGVsaW5lIGRlc2NyaXB0aW9uIjoiUGVuaWxhaWFuIHVudHVrIHtjb21wYW55TmFtZX0gZGl0YW1waWxrYW4gYXBhIGFkYW55YSBkYXJpIGthcnlhd2FuIHNlc3VhaSBkZW5nYW4gPFRleHRMaW5rPnBlZG9tYW4ga29tdW5pdGFzPC9UZXh0TGluaz4ga2FtaSIsIklzIHRoaXMgQUkgc3VtbWFyeSB1c2VmdWw%2FIjoiQXBha2FoIHJpbmdrYXNhbiBBSSBpbmkgbWVtYmFudHU%2FIiwiWWVzIjoiWWEiLCJObyI6IlRpZGFrIn0%3D!"),
        () => import(
          /* webpackChunkName: "id-translations" */
          "./translations.json-id-virtual.json!=!../../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJUaXRsZSI6IlVsYXNhbiBkYW4gcGVuaWxhaWFuIiwiU2Vjb25kYXJ5IHRpdGxlIjoiVWxhc2FuIHRlcmJhcnUiLCJTZWUgYWxsIHJldmlld3MiOiJMaWhhdCBzZW11YSB1bGFzYW4iLCJDb21tdW5pdHkgZ3VpZGVsaW5lIGRlc2NyaXB0aW9uIjoiUGVuaWxhaWFuIHVudHVrIHtjb21wYW55TmFtZX0gZGl0YW1waWxrYW4gYXBhIGFkYW55YSBkYXJpIGthcnlhd2FuIHNlc3VhaSBkZW5nYW4gPFRleHRMaW5rPnBlZG9tYW4ga29tdW5pdGFzPC9UZXh0TGluaz4ga2FtaSIsIklzIHRoaXMgQUkgc3VtbWFyeSB1c2VmdWw%2FIjoiQXBha2FoIHJpbmdrYXNhbiBBSSBpbmkgbWVtYmFudHU%2FIiwiWWVzIjoiWWEiLCJObyI6IlRpZGFrIn0%3D!"
        )
      ),
"zh": createLanguage(
        require.resolveWeak("./translations.json-zh-virtual.json!=!../../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJUaXRsZSI6IlJldmlld3Mgb3ZlcnZpZXciLCJTZWNvbmRhcnkgdGl0bGUiOiJSZWNlbnQgcmV2aWV3cyIsIlNlZSBhbGwgcmV2aWV3cyI6IlNlZSBhbGwgcmV2aWV3cyIsIkNvbW11bml0eSBndWlkZWxpbmUgZGVzY3JpcHRpb24iOiJSYXRpbmdzIGZvciB7Y29tcGFueU5hbWV9IGFyZSBzaGFyZWQgYXMtaXMgZnJvbSBlbXBsb3llZXMgaW4gbGluZSB3aXRoIG91ciA8VGV4dExpbms%2BY29tbXVuaXR5IGd1aWRlbGluZXM8L1RleHRMaW5rPiIsIklzIHRoaXMgQUkgc3VtbWFyeSB1c2VmdWw%2FIjoiSXMgdGhpcyBBSSBzdW1tYXJ5IHVzZWZ1bD8iLCJZZXMiOiJZZXMiLCJObyI6Ik5vIn0%3D!"),
        () => import(
          /* webpackChunkName: "zh-translations" */
          "./translations.json-zh-virtual.json!=!../../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJUaXRsZSI6IlJldmlld3Mgb3ZlcnZpZXciLCJTZWNvbmRhcnkgdGl0bGUiOiJSZWNlbnQgcmV2aWV3cyIsIlNlZSBhbGwgcmV2aWV3cyI6IlNlZSBhbGwgcmV2aWV3cyIsIkNvbW11bml0eSBndWlkZWxpbmUgZGVzY3JpcHRpb24iOiJSYXRpbmdzIGZvciB7Y29tcGFueU5hbWV9IGFyZSBzaGFyZWQgYXMtaXMgZnJvbSBlbXBsb3llZXMgaW4gbGluZSB3aXRoIG91ciA8VGV4dExpbms%2BY29tbXVuaXR5IGd1aWRlbGluZXM8L1RleHRMaW5rPiIsIklzIHRoaXMgQUkgc3VtbWFyeSB1c2VmdWw%2FIjoiSXMgdGhpcyBBSSBzdW1tYXJ5IHVzZWZ1bD8iLCJZZXMiOiJZZXMiLCJObyI6Ik5vIn0%3D!"
        )
      ),
"en-AU": createLanguage(
        require.resolveWeak("./translations.json-en-AU-virtual.json!=!../../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJUaXRsZSI6IlJldmlld3Mgb3ZlcnZpZXciLCJTZWNvbmRhcnkgdGl0bGUiOiJSZWNlbnQgcmV2aWV3cyIsIlNlZSBhbGwgcmV2aWV3cyI6IlNlZSBhbGwgcmV2aWV3cyIsIkNvbW11bml0eSBndWlkZWxpbmUgZGVzY3JpcHRpb24iOiJSYXRpbmdzIGZvciB7Y29tcGFueU5hbWV9IGFyZSBzaGFyZWQgYXMtaXMgZnJvbSBlbXBsb3llZXMgaW4gbGluZSB3aXRoIG91ciA8VGV4dExpbms%2BY29tbXVuaXR5IGd1aWRlbGluZXM8L1RleHRMaW5rPiIsIklzIHRoaXMgQUkgc3VtbWFyeSB1c2VmdWw%2FIjoiSXMgdGhpcyBBSSBzdW1tYXJ5IHVzZWZ1bD8iLCJZZXMiOiJZZXMiLCJObyI6Ik5vIn0%3D!"),
        () => import(
          /* webpackChunkName: "en-AU-translations" */
          "./translations.json-en-AU-virtual.json!=!../../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJUaXRsZSI6IlJldmlld3Mgb3ZlcnZpZXciLCJTZWNvbmRhcnkgdGl0bGUiOiJSZWNlbnQgcmV2aWV3cyIsIlNlZSBhbGwgcmV2aWV3cyI6IlNlZSBhbGwgcmV2aWV3cyIsIkNvbW11bml0eSBndWlkZWxpbmUgZGVzY3JpcHRpb24iOiJSYXRpbmdzIGZvciB7Y29tcGFueU5hbWV9IGFyZSBzaGFyZWQgYXMtaXMgZnJvbSBlbXBsb3llZXMgaW4gbGluZSB3aXRoIG91ciA8VGV4dExpbms%2BY29tbXVuaXR5IGd1aWRlbGluZXM8L1RleHRMaW5rPiIsIklzIHRoaXMgQUkgc3VtbWFyeSB1c2VmdWw%2FIjoiSXMgdGhpcyBBSSBzdW1tYXJ5IHVzZWZ1bD8iLCJZZXMiOiJZZXMiLCJObyI6Ik5vIn0%3D!"
        )
      ),
"en-HK": createLanguage(
        require.resolveWeak("./translations.json-en-HK-virtual.json!=!../../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJUaXRsZSI6IlJldmlld3Mgb3ZlcnZpZXciLCJTZWNvbmRhcnkgdGl0bGUiOiJSZWNlbnQgcmV2aWV3cyIsIlNlZSBhbGwgcmV2aWV3cyI6IlNlZSBhbGwgcmV2aWV3cyIsIkNvbW11bml0eSBndWlkZWxpbmUgZGVzY3JpcHRpb24iOiJSYXRpbmdzIGZvciB7Y29tcGFueU5hbWV9IGFyZSBzaGFyZWQgYXMtaXMgZnJvbSBlbXBsb3llZXMgaW4gbGluZSB3aXRoIG91ciA8VGV4dExpbms%2BY29tbXVuaXR5IGd1aWRlbGluZXM8L1RleHRMaW5rPiIsIklzIHRoaXMgQUkgc3VtbWFyeSB1c2VmdWw%2FIjoiSXMgdGhpcyBBSSBzdW1tYXJ5IHVzZWZ1bD8iLCJZZXMiOiJZZXMiLCJObyI6Ik5vIn0%3D!"),
        () => import(
          /* webpackChunkName: "en-HK-translations" */
          "./translations.json-en-HK-virtual.json!=!../../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJUaXRsZSI6IlJldmlld3Mgb3ZlcnZpZXciLCJTZWNvbmRhcnkgdGl0bGUiOiJSZWNlbnQgcmV2aWV3cyIsIlNlZSBhbGwgcmV2aWV3cyI6IlNlZSBhbGwgcmV2aWV3cyIsIkNvbW11bml0eSBndWlkZWxpbmUgZGVzY3JpcHRpb24iOiJSYXRpbmdzIGZvciB7Y29tcGFueU5hbWV9IGFyZSBzaGFyZWQgYXMtaXMgZnJvbSBlbXBsb3llZXMgaW4gbGluZSB3aXRoIG91ciA8VGV4dExpbms%2BY29tbXVuaXR5IGd1aWRlbGluZXM8L1RleHRMaW5rPiIsIklzIHRoaXMgQUkgc3VtbWFyeSB1c2VmdWw%2FIjoiSXMgdGhpcyBBSSBzdW1tYXJ5IHVzZWZ1bD8iLCJZZXMiOiJZZXMiLCJObyI6Ik5vIn0%3D!"
        )
      ),
"en-ID": createLanguage(
        require.resolveWeak("./translations.json-en-ID-virtual.json!=!../../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJUaXRsZSI6IlJldmlld3Mgb3ZlcnZpZXciLCJTZWNvbmRhcnkgdGl0bGUiOiJSZWNlbnQgcmV2aWV3cyIsIlNlZSBhbGwgcmV2aWV3cyI6IlNlZSBhbGwgcmV2aWV3cyIsIkNvbW11bml0eSBndWlkZWxpbmUgZGVzY3JpcHRpb24iOiJSYXRpbmdzIGZvciB7Y29tcGFueU5hbWV9IGFyZSBzaGFyZWQgYXMtaXMgZnJvbSBlbXBsb3llZXMgaW4gbGluZSB3aXRoIG91ciA8VGV4dExpbms%2BY29tbXVuaXR5IGd1aWRlbGluZXM8L1RleHRMaW5rPiIsIklzIHRoaXMgQUkgc3VtbWFyeSB1c2VmdWw%2FIjoiSXMgdGhpcyBBSSBzdW1tYXJ5IHVzZWZ1bD8iLCJZZXMiOiJZZXMiLCJObyI6Ik5vIn0%3D!"),
        () => import(
          /* webpackChunkName: "en-ID-translations" */
          "./translations.json-en-ID-virtual.json!=!../../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJUaXRsZSI6IlJldmlld3Mgb3ZlcnZpZXciLCJTZWNvbmRhcnkgdGl0bGUiOiJSZWNlbnQgcmV2aWV3cyIsIlNlZSBhbGwgcmV2aWV3cyI6IlNlZSBhbGwgcmV2aWV3cyIsIkNvbW11bml0eSBndWlkZWxpbmUgZGVzY3JpcHRpb24iOiJSYXRpbmdzIGZvciB7Y29tcGFueU5hbWV9IGFyZSBzaGFyZWQgYXMtaXMgZnJvbSBlbXBsb3llZXMgaW4gbGluZSB3aXRoIG91ciA8VGV4dExpbms%2BY29tbXVuaXR5IGd1aWRlbGluZXM8L1RleHRMaW5rPiIsIklzIHRoaXMgQUkgc3VtbWFyeSB1c2VmdWw%2FIjoiSXMgdGhpcyBBSSBzdW1tYXJ5IHVzZWZ1bD8iLCJZZXMiOiJZZXMiLCJObyI6Ik5vIn0%3D!"
        )
      ),
"en-MY": createLanguage(
        require.resolveWeak("./translations.json-en-MY-virtual.json!=!../../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJUaXRsZSI6IlJldmlld3Mgb3ZlcnZpZXciLCJTZWNvbmRhcnkgdGl0bGUiOiJSZWNlbnQgcmV2aWV3cyIsIlNlZSBhbGwgcmV2aWV3cyI6IlNlZSBhbGwgcmV2aWV3cyIsIkNvbW11bml0eSBndWlkZWxpbmUgZGVzY3JpcHRpb24iOiJSYXRpbmdzIGZvciB7Y29tcGFueU5hbWV9IGFyZSBzaGFyZWQgYXMtaXMgZnJvbSBlbXBsb3llZXMgaW4gbGluZSB3aXRoIG91ciA8VGV4dExpbms%2BY29tbXVuaXR5IGd1aWRlbGluZXM8L1RleHRMaW5rPiIsIklzIHRoaXMgQUkgc3VtbWFyeSB1c2VmdWw%2FIjoiSXMgdGhpcyBBSSBzdW1tYXJ5IHVzZWZ1bD8iLCJZZXMiOiJZZXMiLCJObyI6Ik5vIn0%3D!"),
        () => import(
          /* webpackChunkName: "en-MY-translations" */
          "./translations.json-en-MY-virtual.json!=!../../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJUaXRsZSI6IlJldmlld3Mgb3ZlcnZpZXciLCJTZWNvbmRhcnkgdGl0bGUiOiJSZWNlbnQgcmV2aWV3cyIsIlNlZSBhbGwgcmV2aWV3cyI6IlNlZSBhbGwgcmV2aWV3cyIsIkNvbW11bml0eSBndWlkZWxpbmUgZGVzY3JpcHRpb24iOiJSYXRpbmdzIGZvciB7Y29tcGFueU5hbWV9IGFyZSBzaGFyZWQgYXMtaXMgZnJvbSBlbXBsb3llZXMgaW4gbGluZSB3aXRoIG91ciA8VGV4dExpbms%2BY29tbXVuaXR5IGd1aWRlbGluZXM8L1RleHRMaW5rPiIsIklzIHRoaXMgQUkgc3VtbWFyeSB1c2VmdWw%2FIjoiSXMgdGhpcyBBSSBzdW1tYXJ5IHVzZWZ1bD8iLCJZZXMiOiJZZXMiLCJObyI6Ik5vIn0%3D!"
        )
      ),
"en-NZ": createLanguage(
        require.resolveWeak("./translations.json-en-NZ-virtual.json!=!../../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJUaXRsZSI6IlJldmlld3Mgb3ZlcnZpZXciLCJTZWNvbmRhcnkgdGl0bGUiOiJSZWNlbnQgcmV2aWV3cyIsIlNlZSBhbGwgcmV2aWV3cyI6IlNlZSBhbGwgcmV2aWV3cyIsIkNvbW11bml0eSBndWlkZWxpbmUgZGVzY3JpcHRpb24iOiJSYXRpbmdzIGZvciB7Y29tcGFueU5hbWV9IGFyZSBzaGFyZWQgYXMtaXMgZnJvbSBlbXBsb3llZXMgaW4gbGluZSB3aXRoIG91ciA8VGV4dExpbms%2BY29tbXVuaXR5IGd1aWRlbGluZXM8L1RleHRMaW5rPiIsIklzIHRoaXMgQUkgc3VtbWFyeSB1c2VmdWw%2FIjoiSXMgdGhpcyBBSSBzdW1tYXJ5IHVzZWZ1bD8iLCJZZXMiOiJZZXMiLCJObyI6Ik5vIn0%3D!"),
        () => import(
          /* webpackChunkName: "en-NZ-translations" */
          "./translations.json-en-NZ-virtual.json!=!../../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJUaXRsZSI6IlJldmlld3Mgb3ZlcnZpZXciLCJTZWNvbmRhcnkgdGl0bGUiOiJSZWNlbnQgcmV2aWV3cyIsIlNlZSBhbGwgcmV2aWV3cyI6IlNlZSBhbGwgcmV2aWV3cyIsIkNvbW11bml0eSBndWlkZWxpbmUgZGVzY3JpcHRpb24iOiJSYXRpbmdzIGZvciB7Y29tcGFueU5hbWV9IGFyZSBzaGFyZWQgYXMtaXMgZnJvbSBlbXBsb3llZXMgaW4gbGluZSB3aXRoIG91ciA8VGV4dExpbms%2BY29tbXVuaXR5IGd1aWRlbGluZXM8L1RleHRMaW5rPiIsIklzIHRoaXMgQUkgc3VtbWFyeSB1c2VmdWw%2FIjoiSXMgdGhpcyBBSSBzdW1tYXJ5IHVzZWZ1bD8iLCJZZXMiOiJZZXMiLCJObyI6Ik5vIn0%3D!"
        )
      ),
"en-PH": createLanguage(
        require.resolveWeak("./translations.json-en-PH-virtual.json!=!../../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJUaXRsZSI6IlJldmlld3Mgb3ZlcnZpZXciLCJTZWNvbmRhcnkgdGl0bGUiOiJSZWNlbnQgcmV2aWV3cyIsIlNlZSBhbGwgcmV2aWV3cyI6IlNlZSBhbGwgcmV2aWV3cyIsIkNvbW11bml0eSBndWlkZWxpbmUgZGVzY3JpcHRpb24iOiJSYXRpbmdzIGZvciB7Y29tcGFueU5hbWV9IGFyZSBzaGFyZWQgYXMtaXMgZnJvbSBlbXBsb3llZXMgaW4gbGluZSB3aXRoIG91ciA8VGV4dExpbms%2BY29tbXVuaXR5IGd1aWRlbGluZXM8L1RleHRMaW5rPiIsIklzIHRoaXMgQUkgc3VtbWFyeSB1c2VmdWw%2FIjoiSXMgdGhpcyBBSSBzdW1tYXJ5IHVzZWZ1bD8iLCJZZXMiOiJZZXMiLCJObyI6Ik5vIn0%3D!"),
        () => import(
          /* webpackChunkName: "en-PH-translations" */
          "./translations.json-en-PH-virtual.json!=!../../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJUaXRsZSI6IlJldmlld3Mgb3ZlcnZpZXciLCJTZWNvbmRhcnkgdGl0bGUiOiJSZWNlbnQgcmV2aWV3cyIsIlNlZSBhbGwgcmV2aWV3cyI6IlNlZSBhbGwgcmV2aWV3cyIsIkNvbW11bml0eSBndWlkZWxpbmUgZGVzY3JpcHRpb24iOiJSYXRpbmdzIGZvciB7Y29tcGFueU5hbWV9IGFyZSBzaGFyZWQgYXMtaXMgZnJvbSBlbXBsb3llZXMgaW4gbGluZSB3aXRoIG91ciA8VGV4dExpbms%2BY29tbXVuaXR5IGd1aWRlbGluZXM8L1RleHRMaW5rPiIsIklzIHRoaXMgQUkgc3VtbWFyeSB1c2VmdWw%2FIjoiSXMgdGhpcyBBSSBzdW1tYXJ5IHVzZWZ1bD8iLCJZZXMiOiJZZXMiLCJObyI6Ik5vIn0%3D!"
        )
      ),
"en-SG": createLanguage(
        require.resolveWeak("./translations.json-en-SG-virtual.json!=!../../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJUaXRsZSI6IlJldmlld3Mgb3ZlcnZpZXciLCJTZWNvbmRhcnkgdGl0bGUiOiJSZWNlbnQgcmV2aWV3cyIsIlNlZSBhbGwgcmV2aWV3cyI6IlNlZSBhbGwgcmV2aWV3cyIsIkNvbW11bml0eSBndWlkZWxpbmUgZGVzY3JpcHRpb24iOiJSYXRpbmdzIGZvciB7Y29tcGFueU5hbWV9IGFyZSBzaGFyZWQgYXMtaXMgZnJvbSBlbXBsb3llZXMgaW4gbGluZSB3aXRoIG91ciA8VGV4dExpbms%2BY29tbXVuaXR5IGd1aWRlbGluZXM8L1RleHRMaW5rPiIsIklzIHRoaXMgQUkgc3VtbWFyeSB1c2VmdWw%2FIjoiSXMgdGhpcyBBSSBzdW1tYXJ5IHVzZWZ1bD8iLCJZZXMiOiJZZXMiLCJObyI6Ik5vIn0%3D!"),
        () => import(
          /* webpackChunkName: "en-SG-translations" */
          "./translations.json-en-SG-virtual.json!=!../../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJUaXRsZSI6IlJldmlld3Mgb3ZlcnZpZXciLCJTZWNvbmRhcnkgdGl0bGUiOiJSZWNlbnQgcmV2aWV3cyIsIlNlZSBhbGwgcmV2aWV3cyI6IlNlZSBhbGwgcmV2aWV3cyIsIkNvbW11bml0eSBndWlkZWxpbmUgZGVzY3JpcHRpb24iOiJSYXRpbmdzIGZvciB7Y29tcGFueU5hbWV9IGFyZSBzaGFyZWQgYXMtaXMgZnJvbSBlbXBsb3llZXMgaW4gbGluZSB3aXRoIG91ciA8VGV4dExpbms%2BY29tbXVuaXR5IGd1aWRlbGluZXM8L1RleHRMaW5rPiIsIklzIHRoaXMgQUkgc3VtbWFyeSB1c2VmdWw%2FIjoiSXMgdGhpcyBBSSBzdW1tYXJ5IHVzZWZ1bD8iLCJZZXMiOiJZZXMiLCJObyI6Ik5vIn0%3D!"
        )
      ),
"en-TH": createLanguage(
        require.resolveWeak("./translations.json-en-TH-virtual.json!=!../../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJUaXRsZSI6IlJldmlld3Mgb3ZlcnZpZXciLCJTZWNvbmRhcnkgdGl0bGUiOiJSZWNlbnQgcmV2aWV3cyIsIlNlZSBhbGwgcmV2aWV3cyI6IlNlZSBhbGwgcmV2aWV3cyIsIkNvbW11bml0eSBndWlkZWxpbmUgZGVzY3JpcHRpb24iOiJSYXRpbmdzIGZvciB7Y29tcGFueU5hbWV9IGFyZSBzaGFyZWQgYXMtaXMgZnJvbSBlbXBsb3llZXMgaW4gbGluZSB3aXRoIG91ciA8VGV4dExpbms%2BY29tbXVuaXR5IGd1aWRlbGluZXM8L1RleHRMaW5rPiIsIklzIHRoaXMgQUkgc3VtbWFyeSB1c2VmdWw%2FIjoiSXMgdGhpcyBBSSBzdW1tYXJ5IHVzZWZ1bD8iLCJZZXMiOiJZZXMiLCJObyI6Ik5vIn0%3D!"),
        () => import(
          /* webpackChunkName: "en-TH-translations" */
          "./translations.json-en-TH-virtual.json!=!../../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJUaXRsZSI6IlJldmlld3Mgb3ZlcnZpZXciLCJTZWNvbmRhcnkgdGl0bGUiOiJSZWNlbnQgcmV2aWV3cyIsIlNlZSBhbGwgcmV2aWV3cyI6IlNlZSBhbGwgcmV2aWV3cyIsIkNvbW11bml0eSBndWlkZWxpbmUgZGVzY3JpcHRpb24iOiJSYXRpbmdzIGZvciB7Y29tcGFueU5hbWV9IGFyZSBzaGFyZWQgYXMtaXMgZnJvbSBlbXBsb3llZXMgaW4gbGluZSB3aXRoIG91ciA8VGV4dExpbms%2BY29tbXVuaXR5IGd1aWRlbGluZXM8L1RleHRMaW5rPiIsIklzIHRoaXMgQUkgc3VtbWFyeSB1c2VmdWw%2FIjoiSXMgdGhpcyBBSSBzdW1tYXJ5IHVzZWZ1bD8iLCJZZXMiOiJZZXMiLCJObyI6Ik5vIn0%3D!"
        )
      ),
"th-TH": createLanguage(
        require.resolveWeak("./translations.json-th-TH-virtual.json!=!../../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJUaXRsZSI6IuC4o%2BC4teC4p%2BC4tOC4p%2BC5guC4lOC4ouC4oOC4suC4nuC4o%2BC4p%2BC4oSIsIlNlY29uZGFyeSB0aXRsZSI6IuC4o%2BC4teC4p%2BC4tOC4p%2BC4peC5iOC4suC4quC4uOC4lCIsIlNlZSBhbGwgcmV2aWV3cyI6IuC4lOC4ueC4o%2BC4teC4p%2BC4tOC4p%2BC4l%2BC4seC5ieC4h%2BC4q%2BC4oeC4lCIsIkNvbW11bml0eSBndWlkZWxpbmUgZGVzY3JpcHRpb24iOiLguITguLDguYHguJnguJnguKrguLPguKvguKPguLHguJoge2NvbXBhbnlOYW1lfSDguIjguLDguYHguKrguJTguIfguJXguLLguKHguITguLDguYHguJnguJnguIjguKPguLTguIfguIjguLLguIHguJ7guJnguLHguIHguIfguLLguJkg4LmA4Lie4Li34LmI4Lit4LmD4Lir4LmJ4LmA4Lib4LmH4LiZ4LmE4Lib4LiV4Liy4LihPFRleHRMaW5rPuC5geC4meC4p%2BC4l%2BC4suC4h%2BC4m%2BC4j%2BC4tOC4muC4seC4leC4tOC4guC4reC4h%2BC4iuC4uOC4oeC4iuC4mTwvVGV4dExpbms%2B4LiC4Lit4LiH4LmA4Lij4LiyIiwiSXMgdGhpcyBBSSBzdW1tYXJ5IHVzZWZ1bD8iOiLguJrguJfguKrguKPguLjguJvguYLguJTguKIgQUkg4LiZ4Li14LmJ4Lih4Li14Lib4Lij4Liw4LmC4Lii4LiK4LiZ4LmM4Lir4Lij4Li34Lit4LmE4Lih4LmIPyIsIlllcyI6IuC5g%2BC4iuC5iCIsIk5vIjoi4LmE4Lih4LmI4LmD4LiK4LmIIn0%3D!"),
        () => import(
          /* webpackChunkName: "th-TH-translations" */
          "./translations.json-th-TH-virtual.json!=!../../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJUaXRsZSI6IuC4o%2BC4teC4p%2BC4tOC4p%2BC5guC4lOC4ouC4oOC4suC4nuC4o%2BC4p%2BC4oSIsIlNlY29uZGFyeSB0aXRsZSI6IuC4o%2BC4teC4p%2BC4tOC4p%2BC4peC5iOC4suC4quC4uOC4lCIsIlNlZSBhbGwgcmV2aWV3cyI6IuC4lOC4ueC4o%2BC4teC4p%2BC4tOC4p%2BC4l%2BC4seC5ieC4h%2BC4q%2BC4oeC4lCIsIkNvbW11bml0eSBndWlkZWxpbmUgZGVzY3JpcHRpb24iOiLguITguLDguYHguJnguJnguKrguLPguKvguKPguLHguJoge2NvbXBhbnlOYW1lfSDguIjguLDguYHguKrguJTguIfguJXguLLguKHguITguLDguYHguJnguJnguIjguKPguLTguIfguIjguLLguIHguJ7guJnguLHguIHguIfguLLguJkg4LmA4Lie4Li34LmI4Lit4LmD4Lir4LmJ4LmA4Lib4LmH4LiZ4LmE4Lib4LiV4Liy4LihPFRleHRMaW5rPuC5geC4meC4p%2BC4l%2BC4suC4h%2BC4m%2BC4j%2BC4tOC4muC4seC4leC4tOC4guC4reC4h%2BC4iuC4uOC4oeC4iuC4mTwvVGV4dExpbms%2B4LiC4Lit4LiH4LmA4Lij4LiyIiwiSXMgdGhpcyBBSSBzdW1tYXJ5IHVzZWZ1bD8iOiLguJrguJfguKrguKPguLjguJvguYLguJTguKIgQUkg4LiZ4Li14LmJ4Lih4Li14Lib4Lij4Liw4LmC4Lii4LiK4LiZ4LmM4Lir4Lij4Li34Lit4LmE4Lih4LmIPyIsIlllcyI6IuC5g%2BC4iuC5iCIsIk5vIjoi4LmE4Lih4LmI4LmD4LiK4LmIIn0%3D!"
        )
      ),
"id-ID": createLanguage(
        require.resolveWeak("./translations.json-id-ID-virtual.json!=!../../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJUaXRsZSI6IlVsYXNhbiBkYW4gcGVuaWxhaWFuIiwiU2Vjb25kYXJ5IHRpdGxlIjoiVWxhc2FuIHRlcmJhcnUiLCJTZWUgYWxsIHJldmlld3MiOiJMaWhhdCBzZW11YSB1bGFzYW4iLCJDb21tdW5pdHkgZ3VpZGVsaW5lIGRlc2NyaXB0aW9uIjoiUGVuaWxhaWFuIHVudHVrIHtjb21wYW55TmFtZX0gZGl0YW1waWxrYW4gYXBhIGFkYW55YSBkYXJpIGthcnlhd2FuIHNlc3VhaSBkZW5nYW4gPFRleHRMaW5rPnBlZG9tYW4ga29tdW5pdGFzPC9UZXh0TGluaz4ga2FtaSIsIklzIHRoaXMgQUkgc3VtbWFyeSB1c2VmdWw%2FIjoiQXBha2FoIHJpbmdrYXNhbiBBSSBpbmkgbWVtYmFudHU%2FIiwiWWVzIjoiWWEiLCJObyI6IlRpZGFrIn0%3D!"),
        () => import(
          /* webpackChunkName: "id-ID-translations" */
          "./translations.json-id-ID-virtual.json!=!../../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJUaXRsZSI6IlVsYXNhbiBkYW4gcGVuaWxhaWFuIiwiU2Vjb25kYXJ5IHRpdGxlIjoiVWxhc2FuIHRlcmJhcnUiLCJTZWUgYWxsIHJldmlld3MiOiJMaWhhdCBzZW11YSB1bGFzYW4iLCJDb21tdW5pdHkgZ3VpZGVsaW5lIGRlc2NyaXB0aW9uIjoiUGVuaWxhaWFuIHVudHVrIHtjb21wYW55TmFtZX0gZGl0YW1waWxrYW4gYXBhIGFkYW55YSBkYXJpIGthcnlhd2FuIHNlc3VhaSBkZW5nYW4gPFRleHRMaW5rPnBlZG9tYW4ga29tdW5pdGFzPC9UZXh0TGluaz4ga2FtaSIsIklzIHRoaXMgQUkgc3VtbWFyeSB1c2VmdWw%2FIjoiQXBha2FoIHJpbmdrYXNhbiBBSSBpbmkgbWVtYmFudHU%2FIiwiWWVzIjoiWWEiLCJObyI6IlRpZGFrIn0%3D!"
        )
      ),
"zh-HK": createLanguage(
        require.resolveWeak("./translations.json-zh-HK-virtual.json!=!../../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJUaXRsZSI6IlJldmlld3Mgb3ZlcnZpZXciLCJTZWNvbmRhcnkgdGl0bGUiOiJSZWNlbnQgcmV2aWV3cyIsIlNlZSBhbGwgcmV2aWV3cyI6IlNlZSBhbGwgcmV2aWV3cyIsIkNvbW11bml0eSBndWlkZWxpbmUgZGVzY3JpcHRpb24iOiJSYXRpbmdzIGZvciB7Y29tcGFueU5hbWV9IGFyZSBzaGFyZWQgYXMtaXMgZnJvbSBlbXBsb3llZXMgaW4gbGluZSB3aXRoIG91ciA8VGV4dExpbms%2BY29tbXVuaXR5IGd1aWRlbGluZXM8L1RleHRMaW5rPiIsIklzIHRoaXMgQUkgc3VtbWFyeSB1c2VmdWw%2FIjoiSXMgdGhpcyBBSSBzdW1tYXJ5IHVzZWZ1bD8iLCJZZXMiOiJZZXMiLCJObyI6Ik5vIn0%3D!"),
        () => import(
          /* webpackChunkName: "zh-HK-translations" */
          "./translations.json-zh-HK-virtual.json!=!../../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJUaXRsZSI6IlJldmlld3Mgb3ZlcnZpZXciLCJTZWNvbmRhcnkgdGl0bGUiOiJSZWNlbnQgcmV2aWV3cyIsIlNlZSBhbGwgcmV2aWV3cyI6IlNlZSBhbGwgcmV2aWV3cyIsIkNvbW11bml0eSBndWlkZWxpbmUgZGVzY3JpcHRpb24iOiJSYXRpbmdzIGZvciB7Y29tcGFueU5hbWV9IGFyZSBzaGFyZWQgYXMtaXMgZnJvbSBlbXBsb3llZXMgaW4gbGluZSB3aXRoIG91ciA8VGV4dExpbms%2BY29tbXVuaXR5IGd1aWRlbGluZXM8L1RleHRMaW5rPiIsIklzIHRoaXMgQUkgc3VtbWFyeSB1c2VmdWw%2FIjoiSXMgdGhpcyBBSSBzdW1tYXJ5IHVzZWZ1bD8iLCJZZXMiOiJZZXMiLCJObyI6Ik5vIn0%3D!"
        )
      ),
"en-PSEUDO": createLanguage(
        require.resolveWeak("./translations.json-en-PSEUDO-virtual.json!=!../../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJUaXRsZSI6IlvFmOG6veG6veG5vcOsw6zhur3hur3FtcWhIMO2w7bhub3hur3hur3FmeG5vcOsw6zhur3hur3FtV0iLCJTZWNvbmRhcnkgdGl0bGUiOiJbxZjhur3hur3hur3Dp%2BG6veG6veG6veC4geC4teC5ieG5ryDFmeG6veG6veG6veG5vcOsw6zDrOG6veG6veG6vcW1xaFdIiwiU2VlIGFsbCByZXZpZXdzIjoiW8Wg4bq94bq94bq94bq9IMSDxIPGmsaaIMWZ4bq94bq94bm9w6zDrOG6veG6vcW1xaFdIiwiQ29tbXVuaXR5IGd1aWRlbGluZSBkZXNjcmlwdGlvbiI6IlvFmMSDxIPEg%2BG5r8Osw6zDrOC4geC4teC5icSjxaEgxpLDtsO2w7bFmSB7Y29tcGFueU5hbWV9IMSDxIPEg8WZ4bq94bq94bq9IMWh4bipxIPEg8SDxZnhur3hur3hur3GjCDEg8SDxIPFoS3DrMOsw6zFoSDGksWZw7bDtsO2bcyCIOG6veG6veG6vW3MgsalxprDtsO2w7bDvcO9w73hur3hur3hur3hur3hur3hur3FoSDDrMOsw6zguIHguLXguYkgxprDrMOsw6zguIHguLXguYnhur3hur3hur0gxbXDrMOsw6zhua%2FhuKkgw7bDtsO2x5rHmseaxZkgPFRleHRMaW5rPsOnw7bDtm3Mgm3Mgseax5rguIHguLXguYnDrMOs4bmvw73DvSDEo8eax5rDrMOsxozhur3hur3GmsOsw6zguIHguLXguYnhur3hur3FoTwvVGV4dExpbms%2BXSIsIklzIHRoaXMgQUkgc3VtbWFyeSB1c2VmdWw%2FIjoiW8OPxaEg4bmv4bipw6zDrMOsxaEg4bqsw48gxaHHmseax5ptzIJtzILEg8SDxIPFmcO9w73DvSDHmseax5rFoeG6veG6veG6vcaSx5rHmseaxpo%2FXSIsIlllcyI6IlvDneG6veG6veG6vcWhXSIsIk5vIjoiW8ORw7bDtl0ifQ%3D%3D!"),
        () => import(
          /* webpackChunkName: "en-PSEUDO-translations" */
          "./translations.json-en-PSEUDO-virtual.json!=!../../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJUaXRsZSI6IlvFmOG6veG6veG5vcOsw6zhur3hur3FtcWhIMO2w7bhub3hur3hur3FmeG5vcOsw6zhur3hur3FtV0iLCJTZWNvbmRhcnkgdGl0bGUiOiJbxZjhur3hur3hur3Dp%2BG6veG6veG6veC4geC4teC5ieG5ryDFmeG6veG6veG6veG5vcOsw6zDrOG6veG6veG6vcW1xaFdIiwiU2VlIGFsbCByZXZpZXdzIjoiW8Wg4bq94bq94bq94bq9IMSDxIPGmsaaIMWZ4bq94bq94bm9w6zDrOG6veG6vcW1xaFdIiwiQ29tbXVuaXR5IGd1aWRlbGluZSBkZXNjcmlwdGlvbiI6IlvFmMSDxIPEg%2BG5r8Osw6zDrOC4geC4teC5icSjxaEgxpLDtsO2w7bFmSB7Y29tcGFueU5hbWV9IMSDxIPEg8WZ4bq94bq94bq9IMWh4bipxIPEg8SDxZnhur3hur3hur3GjCDEg8SDxIPFoS3DrMOsw6zFoSDGksWZw7bDtsO2bcyCIOG6veG6veG6vW3MgsalxprDtsO2w7bDvcO9w73hur3hur3hur3hur3hur3hur3FoSDDrMOsw6zguIHguLXguYkgxprDrMOsw6zguIHguLXguYnhur3hur3hur0gxbXDrMOsw6zhua%2FhuKkgw7bDtsO2x5rHmseaxZkgPFRleHRMaW5rPsOnw7bDtm3Mgm3Mgseax5rguIHguLXguYnDrMOs4bmvw73DvSDEo8eax5rDrMOsxozhur3hur3GmsOsw6zguIHguLXguYnhur3hur3FoTwvVGV4dExpbms%2BXSIsIklzIHRoaXMgQUkgc3VtbWFyeSB1c2VmdWw%2FIjoiW8OPxaEg4bmv4bipw6zDrMOsxaEg4bqsw48gxaHHmseax5ptzIJtzILEg8SDxIPFmcO9w73DvSDHmseax5rFoeG6veG6veG6vcaSx5rHmseaxpo%2FXSIsIlllcyI6IlvDneG6veG6veG6vcWhXSIsIk5vIjoiW8ORw7bDtl0ifQ%3D%3D!"
        )
      )
      });
  
      export { translations as default };
    