import { Button, Dialog, Text } from 'braid-design-system';
import { useTranslations } from '@vocab/react';
import translations from './.vocab';

export const ReportConfirmedDialog = ({
  isOpen = false,
  onClose,
}: {
  onClose: () => void;
  isOpen?: boolean;
}) => {
  const { t } = useTranslations(translations);

  return (
    <Dialog
      id="ReportConfirmedDialog"
      onClose={onClose}
      open={isOpen}
      title={t('We will look into this review')}
    >
      <Text>{t('Thanks for your feedback')}</Text>
      <Button onClick={onClose}>{t('Close')}</Button>
    </Dialog>
  );
};
