import {
  type ApolloClient,
  type NormalizedCacheObject,
  gql,
} from '@apollo/client';
import type { QueryOntologyOrganisationsArgs } from '@seek/ca-graphql-schema/types';
import { removeTypename } from '@seek/libs-shared';

import type { MonthPickerValue } from '../../../companyReviewForm/type';
import type { AppConfig } from '../../config';

const ROLE_TITLE_SEARCH_QUERY = gql`
  query OntologyRoleTitlesSeniority(
    $query: String!
    $count: Int!
    $session: String!
    $countryCode2: CountryCodeIso2
    $languageCode: LanguageCodeIso
  ) {
    ontologyRoleTitlesSeniority(
      query: $query
      count: $count
      session: $session
      countryCode2: $countryCode2
      languageCode: $languageCode
    ) {
      id
      normalisedText
    }
  }
`;

const CONFIRMED_ROLES_SEARCH_QUERY = gql`
  query GetConfirmedRoles {
    viewer {
      confirmedRoles: roles(status: confirmed) {
        id
        title {
          text
        }
        company {
          text
        }
        from {
          year
          month
        }
        to {
          year
          month
        }
      }
    }
  }
`;
const UPDATE_ROLE_MUTATION = gql`
  mutation UpdateRole($role: UpdateRoleInput!) {
    updateRole(input: $role) {
      viewer {
        confirmedRoles: roles(status: confirmed) {
          id
          title {
            text
          }
          company {
            text
          }
          from {
            year
            month
          }
          to {
            year
            month
          }
        }
      }
    }
  }
`;

export interface RoleTitle {
  normalisedText: string;
  id?: string;
}

export interface ConfirmedRoles {
  from: MonthPickerValue;
  title: {
    text: string;
  };
  company?: {
    text: string;
  };
  id?: string;
  to?: MonthPickerValue;
}

export type RoleService = ReturnType<typeof createRoleService>;

export const createRoleService = ({
  client,
  config,
}: {
  client: ApolloClient<NormalizedCacheObject>;
  config: AppConfig;
}) => {
  const ROLES_TO_SUGGEST = 10;

  const getRoleTitles = async (
    query: string,
    sessionId: string,
  ): Promise<RoleTitle[]> => {
    const response = await client.query<
      { ontologyRoleTitlesSeniority: RoleTitle[] },
      Partial<QueryOntologyOrganisationsArgs>
    >({
      query: ROLE_TITLE_SEARCH_QUERY,
      variables: {
        query,
        count: ROLES_TO_SUGGEST,
        session: sessionId,
        countryCode2: config.country,
        languageCode: config.language,
      },
    });

    return response.data?.ontologyRoleTitlesSeniority ?? [];
  };

  const getConfirmedRoles = async (): Promise<ConfirmedRoles[]> => {
    const response = await client.query<{
      viewer: { confirmedRoles: ConfirmedRoles[] };
    }>({
      query: CONFIRMED_ROLES_SEARCH_QUERY,
      variables: {},
      fetchPolicy: 'no-cache',
    });

    return response.data?.viewer?.confirmedRoles ?? [];
  };

  const updateRole = async (role: ConfirmedRoles) => {
    const response = await client.mutate<{
      updateRole: { viewer: { confirmedRoles: ConfirmedRoles[] } };
    }>({
      mutation: UPDATE_ROLE_MUTATION,
      variables: removeTypename({
        role,
      }),
    });
    return response.errors == null;
  };

  return { getRoleTitles, getConfirmedRoles, updateRole };
};
