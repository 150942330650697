import { useConfig, useValidation, type Rules } from '@seek/libs-shared';
import { useTranslations } from '@vocab/react';
import { Stack } from 'braid-design-system';
import { ReviewPageLayout } from '../components/ReviewPageLayout/ReviewPageLayout';
import type { PageProps, ReviewPayload } from '../type';
import { validationRules } from '../validation/validationRules';
import translations from './.vocab';

import { useEffect } from 'react';
import type { SearchCompanyProfileResult } from '../../shared/services/companyProfileService/types';
import {
  trackClickOverallPageContinue,
  trackClickOverallPageSubSection,
  trackDisplayOverallPage,
  trackValidationErrorOverallPage,
} from '../../shared/tracking/writeAReview/trackingEvents';
import type { Rating } from '../components/StarSelector/StarSelector';
import {
  CompanyNameQuestion,
  type CompanyNameValue,
} from '../questions/AutoSuggestionQuestions/CompanyNameQuestion/CompanyNameQuestion';
import { OverallRatingQuestion } from '../questions/RatingQuesions/OverallRatingQuestion';
import { mapErrorMessage } from '../utils/mapErrorMessage';

export interface OverallRatingPageProps extends PageProps {
  searchCompanyProfiles: (query: string) => Promise<SearchCompanyProfileResult>;
  hideCompanyNameSearch?: boolean;
}

export const OverallRatingPage = ({
  review,
  setPage,
  setter,
  searchCompanyProfiles,
  pageNumber,
  hideCompanyNameSearch,
  reviewFormContext,
}: OverallRatingPageProps) => {
  const { t } = useTranslations(translations);
  const config = useConfig();

  const fields = {
    companyName: review?.companyName,
    overallRating: review?.overallRating,
  };

  const { companyName, overallRating } = validationRules(config.language);

  const rules = {
    companyName,
    overallRating,
  } as Rules<typeof fields>;

  const {
    validateWithErrors,
    getTone,
    getMessage,
    handleValueChangeWithValidation,
  } = useValidation<ReviewPayload>(fields, rules);

  const handleOnContinue = () => {
    trackClickOverallPageContinue({ config, context: reviewFormContext });
    const { isValid, errors } = validateWithErrors();
    if (!isValid) {
      trackValidationErrorOverallPage({
        config,
        context: reviewFormContext,
        errorMessage: errors.map((error) => mapErrorMessage(error.fieldName)),
        errorText: errors.map((error) => error.message),
      });
      return;
    }
    setPage(pageNumber + 1);
  };

  const onSearchCompanyProfiles = async (query: string) => {
    return searchCompanyProfiles(query);
  };

  const handleCompanyNameChange = (value: CompanyNameValue) => {
    handleValueChangeWithValidation({
      setter,
      validatorProperty: 'companyName',
      previousValue: review,
    })(value.companyName);

    setter((prev) => ({
      ...prev,
      companyId: value.companyId,
      organisationId: value.organisationId,
      companyProfilePublished: value.published,
    }));
  };

  const handleOverallRatingChange = (value: Rating) => {
    handleValueChangeWithValidation({
      setter,
      validatorProperty: 'overallRating',
      previousValue: review,
    })(value);

    trackClickOverallPageSubSection({
      config,
      context: reviewFormContext,
      currentPageSubsection: 'overall_experience',
    });
  };

  useEffect(() => {
    trackDisplayOverallPage({ config, context: reviewFormContext });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <ReviewPageLayout
      progress={pageNumber}
      companyName={review?.companyName}
      subtitle={t(
        'Your review will help someone else and it only takes a few minutes. All reviews are anonymous. We wont share your name, only details about your role.',
      )}
      logo={review?.branding?.logoImageUrl}
      onContinue={handleOnContinue}
      testid="overall-rating-page"
    >
      <Stack space="xlarge">
        {!hideCompanyNameSearch && (
          <CompanyNameQuestion
            onChange={handleCompanyNameChange}
            searchCompanyProfiles={onSearchCompanyProfiles}
            text={review?.companyName}
            value={{
              companyId: review?.companyId,
              organisationId: review?.organisationId,
              companyName: review?.companyName,
            }}
            tone={getTone('companyName')}
            message={getMessage('companyName')}
            onValueSelected={() =>
              trackClickOverallPageSubSection({
                config,
                context: reviewFormContext,
                currentPageSubsection: 'company_name',
              })
            }
          />
        )}

        <OverallRatingQuestion
          onChange={handleOverallRatingChange}
          value={review?.overallRating}
          message={getMessage('overallRating')}
        />
      </Stack>
    </ReviewPageLayout>
  );
};
