import { useTranslations } from '@vocab/react';
import { Textarea } from 'braid-design-system';
import type { QuestionProps } from '../../type';
import translations from './.vocab';
import { CHALLENGES_MAX_LENGTH } from '../../validation/validationRules';

export const TheChallengesQuestion = ({
  value = '',
  message = '',
  tone,
  onChange,
}: QuestionProps) => {
  const { t } = useTranslations(translations);
  return (
    <Textarea
      id={'theChallenges'}
      label={t('The challenges')}
      description={t("Describe what you don't like")}
      onChange={(event) => onChange(event.currentTarget.value)}
      value={value}
      tone={tone}
      message={message}
      reserveMessageSpace
      characterLimit={CHALLENGES_MAX_LENGTH}
    />
  );
};
