/**
 *
 * @param locationSlug - param location slug from url path `/companies/:companySlug/reviews/locations/:locationSlug`
 * @param locationId - param location id from AppConfig config.reviewLocation.details?.locationId
 * @returns boolean value: true | false
 */
export const isLocationBasedReviewRoute = ({
  locationSlug,
  locationId,
}: {
  locationId?: number;
  locationSlug?: string;
}): boolean => Boolean(locationSlug && locationId);
