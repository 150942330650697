
      import { createLanguage, createTranslationFile } from '@vocab/webpack/web';
      
    const translations = createTranslationFile({
      "en": createLanguage(
        require.resolveWeak("./translations.json-en-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJDb21wYW55IG5hbWUgQ29tcGFueSBQcm9maWxlIjoie2NvbXBhbnlOYW1lfSBDb21wYW55IFByb2ZpbGUiLCJPdGhlciBjb21wYW5pZXMgaW4gdGhpcyBpbmR1c3RyeSI6Ik90aGVyIGNvbXBhbmllcyBpbiB0aGlzIGluZHVzdHJ5In0%3D!"),
        () => import(
          /* webpackChunkName: "en-translations" */
          "./translations.json-en-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJDb21wYW55IG5hbWUgQ29tcGFueSBQcm9maWxlIjoie2NvbXBhbnlOYW1lfSBDb21wYW55IFByb2ZpbGUiLCJPdGhlciBjb21wYW5pZXMgaW4gdGhpcyBpbmR1c3RyeSI6Ik90aGVyIGNvbXBhbmllcyBpbiB0aGlzIGluZHVzdHJ5In0%3D!"
        )
      ),
"th": createLanguage(
        require.resolveWeak("./translations.json-th-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJDb21wYW55IG5hbWUgQ29tcGFueSBQcm9maWxlIjoi4LmC4Lib4Lij4LmE4Lif4Lil4LmM4Lia4Lij4Li04Lip4Lix4LiXIHtjb21wYW55TmFtZX0iLCJPdGhlciBjb21wYW5pZXMgaW4gdGhpcyBpbmR1c3RyeSI6IuC4muC4o%2BC4tOC4qeC4seC4l%2BC4reC4t%2BC5iOC4meC5hiDguYPguJnguK3guLjguJXguKrguLLguKvguIHguKPguKPguKHguJnguLXguYkifQ%3D%3D!"),
        () => import(
          /* webpackChunkName: "th-translations" */
          "./translations.json-th-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJDb21wYW55IG5hbWUgQ29tcGFueSBQcm9maWxlIjoi4LmC4Lib4Lij4LmE4Lif4Lil4LmM4Lia4Lij4Li04Lip4Lix4LiXIHtjb21wYW55TmFtZX0iLCJPdGhlciBjb21wYW5pZXMgaW4gdGhpcyBpbmR1c3RyeSI6IuC4muC4o%2BC4tOC4qeC4seC4l%2BC4reC4t%2BC5iOC4meC5hiDguYPguJnguK3guLjguJXguKrguLLguKvguIHguKPguKPguKHguJnguLXguYkifQ%3D%3D!"
        )
      ),
"id": createLanguage(
        require.resolveWeak("./translations.json-id-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJDb21wYW55IG5hbWUgQ29tcGFueSBQcm9maWxlIjoiUHJvZmlsIFBlcnVzYWhhYW4ge2NvbXBhbnlOYW1lfSIsIk90aGVyIGNvbXBhbmllcyBpbiB0aGlzIGluZHVzdHJ5IjoiUGVydXNhaGFhbiBsYWluIGRhbGFtIGluZHVzdHJpIGluaSJ9!"),
        () => import(
          /* webpackChunkName: "id-translations" */
          "./translations.json-id-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJDb21wYW55IG5hbWUgQ29tcGFueSBQcm9maWxlIjoiUHJvZmlsIFBlcnVzYWhhYW4ge2NvbXBhbnlOYW1lfSIsIk90aGVyIGNvbXBhbmllcyBpbiB0aGlzIGluZHVzdHJ5IjoiUGVydXNhaGFhbiBsYWluIGRhbGFtIGluZHVzdHJpIGluaSJ9!"
        )
      ),
"zh": createLanguage(
        require.resolveWeak("./translations.json-zh-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJDb21wYW55IG5hbWUgQ29tcGFueSBQcm9maWxlIjoie2NvbXBhbnlOYW1lfSBDb21wYW55IFByb2ZpbGUiLCJPdGhlciBjb21wYW5pZXMgaW4gdGhpcyBpbmR1c3RyeSI6Ik90aGVyIGNvbXBhbmllcyBpbiB0aGlzIGluZHVzdHJ5In0%3D!"),
        () => import(
          /* webpackChunkName: "zh-translations" */
          "./translations.json-zh-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJDb21wYW55IG5hbWUgQ29tcGFueSBQcm9maWxlIjoie2NvbXBhbnlOYW1lfSBDb21wYW55IFByb2ZpbGUiLCJPdGhlciBjb21wYW5pZXMgaW4gdGhpcyBpbmR1c3RyeSI6Ik90aGVyIGNvbXBhbmllcyBpbiB0aGlzIGluZHVzdHJ5In0%3D!"
        )
      ),
"en-AU": createLanguage(
        require.resolveWeak("./translations.json-en-AU-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJDb21wYW55IG5hbWUgQ29tcGFueSBQcm9maWxlIjoie2NvbXBhbnlOYW1lfSBDb21wYW55IFByb2ZpbGUiLCJPdGhlciBjb21wYW5pZXMgaW4gdGhpcyBpbmR1c3RyeSI6Ik90aGVyIGNvbXBhbmllcyBpbiB0aGlzIGluZHVzdHJ5In0%3D!"),
        () => import(
          /* webpackChunkName: "en-AU-translations" */
          "./translations.json-en-AU-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJDb21wYW55IG5hbWUgQ29tcGFueSBQcm9maWxlIjoie2NvbXBhbnlOYW1lfSBDb21wYW55IFByb2ZpbGUiLCJPdGhlciBjb21wYW5pZXMgaW4gdGhpcyBpbmR1c3RyeSI6Ik90aGVyIGNvbXBhbmllcyBpbiB0aGlzIGluZHVzdHJ5In0%3D!"
        )
      ),
"en-HK": createLanguage(
        require.resolveWeak("./translations.json-en-HK-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJDb21wYW55IG5hbWUgQ29tcGFueSBQcm9maWxlIjoie2NvbXBhbnlOYW1lfSBDb21wYW55IFByb2ZpbGUiLCJPdGhlciBjb21wYW5pZXMgaW4gdGhpcyBpbmR1c3RyeSI6Ik90aGVyIGNvbXBhbmllcyBpbiB0aGlzIGluZHVzdHJ5In0%3D!"),
        () => import(
          /* webpackChunkName: "en-HK-translations" */
          "./translations.json-en-HK-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJDb21wYW55IG5hbWUgQ29tcGFueSBQcm9maWxlIjoie2NvbXBhbnlOYW1lfSBDb21wYW55IFByb2ZpbGUiLCJPdGhlciBjb21wYW5pZXMgaW4gdGhpcyBpbmR1c3RyeSI6Ik90aGVyIGNvbXBhbmllcyBpbiB0aGlzIGluZHVzdHJ5In0%3D!"
        )
      ),
"en-ID": createLanguage(
        require.resolveWeak("./translations.json-en-ID-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJDb21wYW55IG5hbWUgQ29tcGFueSBQcm9maWxlIjoie2NvbXBhbnlOYW1lfSBDb21wYW55IFByb2ZpbGUiLCJPdGhlciBjb21wYW5pZXMgaW4gdGhpcyBpbmR1c3RyeSI6Ik90aGVyIGNvbXBhbmllcyBpbiB0aGlzIGluZHVzdHJ5In0%3D!"),
        () => import(
          /* webpackChunkName: "en-ID-translations" */
          "./translations.json-en-ID-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJDb21wYW55IG5hbWUgQ29tcGFueSBQcm9maWxlIjoie2NvbXBhbnlOYW1lfSBDb21wYW55IFByb2ZpbGUiLCJPdGhlciBjb21wYW5pZXMgaW4gdGhpcyBpbmR1c3RyeSI6Ik90aGVyIGNvbXBhbmllcyBpbiB0aGlzIGluZHVzdHJ5In0%3D!"
        )
      ),
"en-MY": createLanguage(
        require.resolveWeak("./translations.json-en-MY-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJDb21wYW55IG5hbWUgQ29tcGFueSBQcm9maWxlIjoie2NvbXBhbnlOYW1lfSBDb21wYW55IFByb2ZpbGUiLCJPdGhlciBjb21wYW5pZXMgaW4gdGhpcyBpbmR1c3RyeSI6Ik90aGVyIGNvbXBhbmllcyBpbiB0aGlzIGluZHVzdHJ5In0%3D!"),
        () => import(
          /* webpackChunkName: "en-MY-translations" */
          "./translations.json-en-MY-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJDb21wYW55IG5hbWUgQ29tcGFueSBQcm9maWxlIjoie2NvbXBhbnlOYW1lfSBDb21wYW55IFByb2ZpbGUiLCJPdGhlciBjb21wYW5pZXMgaW4gdGhpcyBpbmR1c3RyeSI6Ik90aGVyIGNvbXBhbmllcyBpbiB0aGlzIGluZHVzdHJ5In0%3D!"
        )
      ),
"en-NZ": createLanguage(
        require.resolveWeak("./translations.json-en-NZ-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJDb21wYW55IG5hbWUgQ29tcGFueSBQcm9maWxlIjoie2NvbXBhbnlOYW1lfSBDb21wYW55IFByb2ZpbGUiLCJPdGhlciBjb21wYW5pZXMgaW4gdGhpcyBpbmR1c3RyeSI6Ik90aGVyIGNvbXBhbmllcyBpbiB0aGlzIGluZHVzdHJ5In0%3D!"),
        () => import(
          /* webpackChunkName: "en-NZ-translations" */
          "./translations.json-en-NZ-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJDb21wYW55IG5hbWUgQ29tcGFueSBQcm9maWxlIjoie2NvbXBhbnlOYW1lfSBDb21wYW55IFByb2ZpbGUiLCJPdGhlciBjb21wYW5pZXMgaW4gdGhpcyBpbmR1c3RyeSI6Ik90aGVyIGNvbXBhbmllcyBpbiB0aGlzIGluZHVzdHJ5In0%3D!"
        )
      ),
"en-PH": createLanguage(
        require.resolveWeak("./translations.json-en-PH-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJDb21wYW55IG5hbWUgQ29tcGFueSBQcm9maWxlIjoie2NvbXBhbnlOYW1lfSBDb21wYW55IFByb2ZpbGUiLCJPdGhlciBjb21wYW5pZXMgaW4gdGhpcyBpbmR1c3RyeSI6Ik90aGVyIGNvbXBhbmllcyBpbiB0aGlzIGluZHVzdHJ5In0%3D!"),
        () => import(
          /* webpackChunkName: "en-PH-translations" */
          "./translations.json-en-PH-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJDb21wYW55IG5hbWUgQ29tcGFueSBQcm9maWxlIjoie2NvbXBhbnlOYW1lfSBDb21wYW55IFByb2ZpbGUiLCJPdGhlciBjb21wYW5pZXMgaW4gdGhpcyBpbmR1c3RyeSI6Ik90aGVyIGNvbXBhbmllcyBpbiB0aGlzIGluZHVzdHJ5In0%3D!"
        )
      ),
"en-SG": createLanguage(
        require.resolveWeak("./translations.json-en-SG-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJDb21wYW55IG5hbWUgQ29tcGFueSBQcm9maWxlIjoie2NvbXBhbnlOYW1lfSBDb21wYW55IFByb2ZpbGUiLCJPdGhlciBjb21wYW5pZXMgaW4gdGhpcyBpbmR1c3RyeSI6Ik90aGVyIGNvbXBhbmllcyBpbiB0aGlzIGluZHVzdHJ5In0%3D!"),
        () => import(
          /* webpackChunkName: "en-SG-translations" */
          "./translations.json-en-SG-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJDb21wYW55IG5hbWUgQ29tcGFueSBQcm9maWxlIjoie2NvbXBhbnlOYW1lfSBDb21wYW55IFByb2ZpbGUiLCJPdGhlciBjb21wYW5pZXMgaW4gdGhpcyBpbmR1c3RyeSI6Ik90aGVyIGNvbXBhbmllcyBpbiB0aGlzIGluZHVzdHJ5In0%3D!"
        )
      ),
"en-TH": createLanguage(
        require.resolveWeak("./translations.json-en-TH-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJDb21wYW55IG5hbWUgQ29tcGFueSBQcm9maWxlIjoie2NvbXBhbnlOYW1lfSBDb21wYW55IFByb2ZpbGUiLCJPdGhlciBjb21wYW5pZXMgaW4gdGhpcyBpbmR1c3RyeSI6Ik90aGVyIGNvbXBhbmllcyBpbiB0aGlzIGluZHVzdHJ5In0%3D!"),
        () => import(
          /* webpackChunkName: "en-TH-translations" */
          "./translations.json-en-TH-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJDb21wYW55IG5hbWUgQ29tcGFueSBQcm9maWxlIjoie2NvbXBhbnlOYW1lfSBDb21wYW55IFByb2ZpbGUiLCJPdGhlciBjb21wYW5pZXMgaW4gdGhpcyBpbmR1c3RyeSI6Ik90aGVyIGNvbXBhbmllcyBpbiB0aGlzIGluZHVzdHJ5In0%3D!"
        )
      ),
"th-TH": createLanguage(
        require.resolveWeak("./translations.json-th-TH-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJDb21wYW55IG5hbWUgQ29tcGFueSBQcm9maWxlIjoi4LmC4Lib4Lij4LmE4Lif4Lil4LmM4Lia4Lij4Li04Lip4Lix4LiXIHtjb21wYW55TmFtZX0iLCJPdGhlciBjb21wYW5pZXMgaW4gdGhpcyBpbmR1c3RyeSI6IuC4muC4o%2BC4tOC4qeC4seC4l%2BC4reC4t%2BC5iOC4meC5hiDguYPguJnguK3guLjguJXguKrguLLguKvguIHguKPguKPguKHguJnguLXguYkifQ%3D%3D!"),
        () => import(
          /* webpackChunkName: "th-TH-translations" */
          "./translations.json-th-TH-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJDb21wYW55IG5hbWUgQ29tcGFueSBQcm9maWxlIjoi4LmC4Lib4Lij4LmE4Lif4Lil4LmM4Lia4Lij4Li04Lip4Lix4LiXIHtjb21wYW55TmFtZX0iLCJPdGhlciBjb21wYW5pZXMgaW4gdGhpcyBpbmR1c3RyeSI6IuC4muC4o%2BC4tOC4qeC4seC4l%2BC4reC4t%2BC5iOC4meC5hiDguYPguJnguK3guLjguJXguKrguLLguKvguIHguKPguKPguKHguJnguLXguYkifQ%3D%3D!"
        )
      ),
"id-ID": createLanguage(
        require.resolveWeak("./translations.json-id-ID-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJDb21wYW55IG5hbWUgQ29tcGFueSBQcm9maWxlIjoiUHJvZmlsIFBlcnVzYWhhYW4ge2NvbXBhbnlOYW1lfSIsIk90aGVyIGNvbXBhbmllcyBpbiB0aGlzIGluZHVzdHJ5IjoiUGVydXNhaGFhbiBsYWluIGRhbGFtIGluZHVzdHJpIGluaSJ9!"),
        () => import(
          /* webpackChunkName: "id-ID-translations" */
          "./translations.json-id-ID-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJDb21wYW55IG5hbWUgQ29tcGFueSBQcm9maWxlIjoiUHJvZmlsIFBlcnVzYWhhYW4ge2NvbXBhbnlOYW1lfSIsIk90aGVyIGNvbXBhbmllcyBpbiB0aGlzIGluZHVzdHJ5IjoiUGVydXNhaGFhbiBsYWluIGRhbGFtIGluZHVzdHJpIGluaSJ9!"
        )
      ),
"zh-HK": createLanguage(
        require.resolveWeak("./translations.json-zh-HK-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJDb21wYW55IG5hbWUgQ29tcGFueSBQcm9maWxlIjoie2NvbXBhbnlOYW1lfSBDb21wYW55IFByb2ZpbGUiLCJPdGhlciBjb21wYW5pZXMgaW4gdGhpcyBpbmR1c3RyeSI6Ik90aGVyIGNvbXBhbmllcyBpbiB0aGlzIGluZHVzdHJ5In0%3D!"),
        () => import(
          /* webpackChunkName: "zh-HK-translations" */
          "./translations.json-zh-HK-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJDb21wYW55IG5hbWUgQ29tcGFueSBQcm9maWxlIjoie2NvbXBhbnlOYW1lfSBDb21wYW55IFByb2ZpbGUiLCJPdGhlciBjb21wYW5pZXMgaW4gdGhpcyBpbmR1c3RyeSI6Ik90aGVyIGNvbXBhbmllcyBpbiB0aGlzIGluZHVzdHJ5In0%3D!"
        )
      ),
"en-PSEUDO": createLanguage(
        require.resolveWeak("./translations.json-en-PSEUDO-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJDb21wYW55IG5hbWUgQ29tcGFueSBQcm9maWxlIjoiW3tjb21wYW55TmFtZX0gw4fDtsO2w7ZtzILGpcSDxIPEg%2BC4geC4teC5icO9w73DvSDGpMWZw7bDtsO2xpLDrMOsw6zGmuG6veG6veG6vV0iLCJPdGhlciBjb21wYW5pZXMgaW4gdGhpcyBpbmR1c3RyeSI6IlvDluG5r%2BG4qeG6veG6veG6vcWZIMOnw7bDtsO2bcyCxqXEg8SDxIPguIHguLXguYnDrMOsw6zhur3hur3hur3FoSDDrMOsw6zguIHguLXguYkg4bmv4bipw6zDrMOsxaEgw6zDrMOs4LiB4Li14LmJxozHmseax5rFoeG5r8WZw73DvcO9XSJ9!"),
        () => import(
          /* webpackChunkName: "en-PSEUDO-translations" */
          "./translations.json-en-PSEUDO-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJDb21wYW55IG5hbWUgQ29tcGFueSBQcm9maWxlIjoiW3tjb21wYW55TmFtZX0gw4fDtsO2w7ZtzILGpcSDxIPEg%2BC4geC4teC5icO9w73DvSDGpMWZw7bDtsO2xpLDrMOsw6zGmuG6veG6veG6vV0iLCJPdGhlciBjb21wYW5pZXMgaW4gdGhpcyBpbmR1c3RyeSI6IlvDluG5r%2BG4qeG6veG6veG6vcWZIMOnw7bDtsO2bcyCxqXEg8SDxIPguIHguLXguYnDrMOsw6zhur3hur3hur3FoSDDrMOsw6zguIHguLXguYkg4bmv4bipw6zDrMOsxaEgw6zDrMOs4LiB4Li14LmJxozHmseax5rFoeG5r8WZw73DvcO9XSJ9!"
        )
      )
      });
  
      export { translations as default };
    