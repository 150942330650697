import { Box, Inline, Loader, Stack, Text } from 'braid-design-system';

import type { CompanyProfileRatingBreakdown } from '@seek/libs-shared';
import * as styles from './RatingBreakdown.css';

const buildWidthPercentage = (percentage = 0, percentageFactor: number) => {
  const widthPercentage = percentage
    ? Math.ceil(percentage * percentageFactor)
    : 0;
  return `${widthPercentage.toFixed(2)}%`;
};

type RatingBreakdownModel = CompanyProfileRatingBreakdown;

interface Props {
  model?: RatingBreakdownModel;
}

export const RatingBreakdown = ({ model }: Props) => {
  if (!model) {
    return <Loader />;
  }
  const ratings = [
    {
      star: 5,
      count: model.companyRating5StarCount,
      percentage: model.companyRating5StarPercentage,
    },
    {
      star: 4,
      count: model.companyRating4StarCount,
      percentage: model.companyRating4StarPercentage,
    },
    {
      star: 3,
      count: model.companyRating3StarCount,
      percentage: model.companyRating3StarPercentage,
    },
    {
      star: 2,
      count: model.companyRating2StarCount,
      percentage: model.companyRating2StarPercentage,
    },
    {
      star: 1,
      count: model.companyRating1StarCount,
      percentage: model.companyRating1StarPercentage,
    },
  ];

  const percentages = ratings.map((r) => r.percentage);

  const highestRating = Math.max(...percentages);

  const percentageFactor = 100 / highestRating;

  return (
    <Box className={styles.root}>
      <Stack space="xsmall">
        {ratings.map((rating) => (
          <Inline key={rating.star} space="xsmall" alignY="center">
            <Box className={styles.ratingValueContainer}>
              <Text size="small" weight="strong">
                {rating.star}
              </Text>
            </Box>
            <Box className={styles.barContainer}>
              <Box
                className={styles.bar}
                style={{
                  width: buildWidthPercentage(
                    rating.percentage,
                    percentageFactor,
                  ),
                }}
              />
            </Box>
            <Text size="xsmall">{rating.count}</Text>
          </Inline>
        ))}
      </Stack>
    </Box>
  );
};
