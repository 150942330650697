
      import { createLanguage, createTranslationFile } from '@vocab/webpack/web';
      
    const translations = createTranslationFile({
      "en": createLanguage(
        require.resolveWeak("./translations.json-en-virtual.json!=!../../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJTZWUgYWxsIHJldmlld3MiOiJTZWUgYWxsIHJldmlld3MiLCJXb3JraW5nIGF0IHtjb21wYW55X25hbWV9IjoiV29ya2luZyBhdCB7Y29tcGFueV9uYW1lfSIsInJhdGUgc2FsYXJ5IGFzIGhpZ2ggb3IgYXZlcmFnZSI6IlJhdGUgc2FsYXJ5IGFzIGhpZ2ggb3IgYXZlcmFnZSIsImVtcGxveWVlcyByZWNvbW1lbmQgdGhpcyBlbXBsb3llciB0byBmcmllbmRzIjoiRW1wbG95ZWVzIHJlY29tbWVuZCB0aGlzIGVtcGxveWVyIHRvIGZyaWVuZHMiLCJDb21tdW5pdHkgZ3VpZGVsaW5lIGRlc2NyaXB0aW9uIjoiUmF0aW5ncyBmb3Ige2NvbXBhbnlOYW1lfSBhcmUgc2hhcmVkIGFzLWlzIGZyb20gZW1wbG95ZWVzIGluIGxpbmUgd2l0aCBvdXIgPFRleHRMaW5rPmNvbW11bml0eSBndWlkZWxpbmVzPC9UZXh0TGluaz4ifQ%3D%3D!"),
        () => import(
          /* webpackChunkName: "en-translations" */
          "./translations.json-en-virtual.json!=!../../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJTZWUgYWxsIHJldmlld3MiOiJTZWUgYWxsIHJldmlld3MiLCJXb3JraW5nIGF0IHtjb21wYW55X25hbWV9IjoiV29ya2luZyBhdCB7Y29tcGFueV9uYW1lfSIsInJhdGUgc2FsYXJ5IGFzIGhpZ2ggb3IgYXZlcmFnZSI6IlJhdGUgc2FsYXJ5IGFzIGhpZ2ggb3IgYXZlcmFnZSIsImVtcGxveWVlcyByZWNvbW1lbmQgdGhpcyBlbXBsb3llciB0byBmcmllbmRzIjoiRW1wbG95ZWVzIHJlY29tbWVuZCB0aGlzIGVtcGxveWVyIHRvIGZyaWVuZHMiLCJDb21tdW5pdHkgZ3VpZGVsaW5lIGRlc2NyaXB0aW9uIjoiUmF0aW5ncyBmb3Ige2NvbXBhbnlOYW1lfSBhcmUgc2hhcmVkIGFzLWlzIGZyb20gZW1wbG95ZWVzIGluIGxpbmUgd2l0aCBvdXIgPFRleHRMaW5rPmNvbW11bml0eSBndWlkZWxpbmVzPC9UZXh0TGluaz4ifQ%3D%3D!"
        )
      ),
"th": createLanguage(
        require.resolveWeak("./translations.json-th-virtual.json!=!../../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJTZWUgYWxsIHJldmlld3MiOiLguJTguLnguKPguLXguKfguLTguKfguJfguLHguYnguIfguKvguKHguJQiLCJXb3JraW5nIGF0IHtjb21wYW55X25hbWV9Ijoi4LiB4Liy4Lij4LiX4Liz4LiH4Liy4LiZ4LiX4Li14LmIIHtjb21wYW55X25hbWV9IiwicmF0ZSBzYWxhcnkgYXMgaGlnaCBvciBhdmVyYWdlIjoi4LmD4Lir4LmJ4LiE4Liw4LmB4LiZ4LiZ4LmA4LiH4Li04LiZ4LmA4LiU4Li34Lit4LiZ4Lin4LmI4Liy4Lit4Lii4Li54LmI4LmD4LiZ4Lij4Liw4LiU4Lix4Lia4Liq4Li54LiH4Lir4Lij4Li34Lit4Lib4Liy4LiZ4LiB4Lil4Liy4LiHIiwiZW1wbG95ZWVzIHJlY29tbWVuZCB0aGlzIGVtcGxveWVyIHRvIGZyaWVuZHMiOiLguJ7guJnguLHguIHguIfguLLguJnguYHguJnguLDguJnguLPguJrguKPguLTguKnguLHguJfguJnguLXguYnguYPguKvguYnguIHguLHguJrguYDguJ7guLfguYjguK3guJnguILguK3guIfguJ7guKfguIHguYDguILguLIiLCJDb21tdW5pdHkgZ3VpZGVsaW5lIGRlc2NyaXB0aW9uIjoi4LiE4Liw4LmB4LiZ4LiZ4Liq4Liz4Lir4Lij4Lix4LiaIHtjb21wYW55TmFtZX0g4LiI4Liw4LmB4Liq4LiU4LiH4LiV4Liy4Lih4LiE4Liw4LmB4LiZ4LiZ4LiI4Lij4Li04LiH4LiI4Liy4LiB4Lie4LiZ4Lix4LiB4LiH4Liy4LiZIOC5gOC4nuC4t%2BC5iOC4reC5g%2BC4q%2BC5ieC5gOC4m%2BC5h%2BC4meC5hOC4m%2BC4leC4suC4oTxUZXh0TGluaz7guYHguJnguKfguJfguLLguIfguJvguI%2FguLTguJrguLHguJXguLTguILguK3guIfguIrguLjguKHguIrguJk8L1RleHRMaW5rPuC4guC4reC4h%2BC5gOC4o%2BC4siJ9!"),
        () => import(
          /* webpackChunkName: "th-translations" */
          "./translations.json-th-virtual.json!=!../../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJTZWUgYWxsIHJldmlld3MiOiLguJTguLnguKPguLXguKfguLTguKfguJfguLHguYnguIfguKvguKHguJQiLCJXb3JraW5nIGF0IHtjb21wYW55X25hbWV9Ijoi4LiB4Liy4Lij4LiX4Liz4LiH4Liy4LiZ4LiX4Li14LmIIHtjb21wYW55X25hbWV9IiwicmF0ZSBzYWxhcnkgYXMgaGlnaCBvciBhdmVyYWdlIjoi4LmD4Lir4LmJ4LiE4Liw4LmB4LiZ4LiZ4LmA4LiH4Li04LiZ4LmA4LiU4Li34Lit4LiZ4Lin4LmI4Liy4Lit4Lii4Li54LmI4LmD4LiZ4Lij4Liw4LiU4Lix4Lia4Liq4Li54LiH4Lir4Lij4Li34Lit4Lib4Liy4LiZ4LiB4Lil4Liy4LiHIiwiZW1wbG95ZWVzIHJlY29tbWVuZCB0aGlzIGVtcGxveWVyIHRvIGZyaWVuZHMiOiLguJ7guJnguLHguIHguIfguLLguJnguYHguJnguLDguJnguLPguJrguKPguLTguKnguLHguJfguJnguLXguYnguYPguKvguYnguIHguLHguJrguYDguJ7guLfguYjguK3guJnguILguK3guIfguJ7guKfguIHguYDguILguLIiLCJDb21tdW5pdHkgZ3VpZGVsaW5lIGRlc2NyaXB0aW9uIjoi4LiE4Liw4LmB4LiZ4LiZ4Liq4Liz4Lir4Lij4Lix4LiaIHtjb21wYW55TmFtZX0g4LiI4Liw4LmB4Liq4LiU4LiH4LiV4Liy4Lih4LiE4Liw4LmB4LiZ4LiZ4LiI4Lij4Li04LiH4LiI4Liy4LiB4Lie4LiZ4Lix4LiB4LiH4Liy4LiZIOC5gOC4nuC4t%2BC5iOC4reC5g%2BC4q%2BC5ieC5gOC4m%2BC5h%2BC4meC5hOC4m%2BC4leC4suC4oTxUZXh0TGluaz7guYHguJnguKfguJfguLLguIfguJvguI%2FguLTguJrguLHguJXguLTguILguK3guIfguIrguLjguKHguIrguJk8L1RleHRMaW5rPuC4guC4reC4h%2BC5gOC4o%2BC4siJ9!"
        )
      ),
"id": createLanguage(
        require.resolveWeak("./translations.json-id-virtual.json!=!../../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJTZWUgYWxsIHJldmlld3MiOiJMaWhhdCBzZW11YSB1bGFzYW4iLCJXb3JraW5nIGF0IHtjb21wYW55X25hbWV9IjoiQmVrZXJqYSBkaSB7Y29tcGFueV9uYW1lfSIsInJhdGUgc2FsYXJ5IGFzIGhpZ2ggb3IgYXZlcmFnZSI6Ik1lbmlsYWkgZ2FqaSB0aW5nZ2kgYXRhdSByYXRhLXJhdGEiLCJlbXBsb3llZXMgcmVjb21tZW5kIHRoaXMgZW1wbG95ZXIgdG8gZnJpZW5kcyI6IlBlZ2F3YWkgbWVyZWtvbWVuZGFzaWthbiBwZXJ1c2FoYWFuIGluaSIsIkNvbW11bml0eSBndWlkZWxpbmUgZGVzY3JpcHRpb24iOiJQZXJpbmdrYXQgdW50dWsge2NvbXBhbnlOYW1lfSBkaWJhZ2lrYW4gc2ViYWdhaW1hbmEgYWRhbnlhIGRhcmkga2FyeWF3YW4gc2VzdWFpIGRlbmdhbiBwZXJhdHVyYW4ga2FtaTxUZXh0TGluaz4gcGVkb21hbiBrb211bml0YXM8L1RleHRMaW5rPiJ9!"),
        () => import(
          /* webpackChunkName: "id-translations" */
          "./translations.json-id-virtual.json!=!../../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJTZWUgYWxsIHJldmlld3MiOiJMaWhhdCBzZW11YSB1bGFzYW4iLCJXb3JraW5nIGF0IHtjb21wYW55X25hbWV9IjoiQmVrZXJqYSBkaSB7Y29tcGFueV9uYW1lfSIsInJhdGUgc2FsYXJ5IGFzIGhpZ2ggb3IgYXZlcmFnZSI6Ik1lbmlsYWkgZ2FqaSB0aW5nZ2kgYXRhdSByYXRhLXJhdGEiLCJlbXBsb3llZXMgcmVjb21tZW5kIHRoaXMgZW1wbG95ZXIgdG8gZnJpZW5kcyI6IlBlZ2F3YWkgbWVyZWtvbWVuZGFzaWthbiBwZXJ1c2FoYWFuIGluaSIsIkNvbW11bml0eSBndWlkZWxpbmUgZGVzY3JpcHRpb24iOiJQZXJpbmdrYXQgdW50dWsge2NvbXBhbnlOYW1lfSBkaWJhZ2lrYW4gc2ViYWdhaW1hbmEgYWRhbnlhIGRhcmkga2FyeWF3YW4gc2VzdWFpIGRlbmdhbiBwZXJhdHVyYW4ga2FtaTxUZXh0TGluaz4gcGVkb21hbiBrb211bml0YXM8L1RleHRMaW5rPiJ9!"
        )
      ),
"zh": createLanguage(
        require.resolveWeak("./translations.json-zh-virtual.json!=!../../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJTZWUgYWxsIHJldmlld3MiOiJTZWUgYWxsIHJldmlld3MiLCJXb3JraW5nIGF0IHtjb21wYW55X25hbWV9IjoiV29ya2luZyBhdCB7Y29tcGFueV9uYW1lfSIsInJhdGUgc2FsYXJ5IGFzIGhpZ2ggb3IgYXZlcmFnZSI6IlJhdGUgc2FsYXJ5IGFzIGhpZ2ggb3IgYXZlcmFnZSIsImVtcGxveWVlcyByZWNvbW1lbmQgdGhpcyBlbXBsb3llciB0byBmcmllbmRzIjoiRW1wbG95ZWVzIHJlY29tbWVuZCB0aGlzIGVtcGxveWVyIHRvIGZyaWVuZHMiLCJDb21tdW5pdHkgZ3VpZGVsaW5lIGRlc2NyaXB0aW9uIjoiUmF0aW5ncyBmb3Ige2NvbXBhbnlOYW1lfSBhcmUgc2hhcmVkIGFzLWlzIGZyb20gZW1wbG95ZWVzIGluIGxpbmUgd2l0aCBvdXIgPFRleHRMaW5rPmNvbW11bml0eSBndWlkZWxpbmVzPC9UZXh0TGluaz4ifQ%3D%3D!"),
        () => import(
          /* webpackChunkName: "zh-translations" */
          "./translations.json-zh-virtual.json!=!../../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJTZWUgYWxsIHJldmlld3MiOiJTZWUgYWxsIHJldmlld3MiLCJXb3JraW5nIGF0IHtjb21wYW55X25hbWV9IjoiV29ya2luZyBhdCB7Y29tcGFueV9uYW1lfSIsInJhdGUgc2FsYXJ5IGFzIGhpZ2ggb3IgYXZlcmFnZSI6IlJhdGUgc2FsYXJ5IGFzIGhpZ2ggb3IgYXZlcmFnZSIsImVtcGxveWVlcyByZWNvbW1lbmQgdGhpcyBlbXBsb3llciB0byBmcmllbmRzIjoiRW1wbG95ZWVzIHJlY29tbWVuZCB0aGlzIGVtcGxveWVyIHRvIGZyaWVuZHMiLCJDb21tdW5pdHkgZ3VpZGVsaW5lIGRlc2NyaXB0aW9uIjoiUmF0aW5ncyBmb3Ige2NvbXBhbnlOYW1lfSBhcmUgc2hhcmVkIGFzLWlzIGZyb20gZW1wbG95ZWVzIGluIGxpbmUgd2l0aCBvdXIgPFRleHRMaW5rPmNvbW11bml0eSBndWlkZWxpbmVzPC9UZXh0TGluaz4ifQ%3D%3D!"
        )
      ),
"en-AU": createLanguage(
        require.resolveWeak("./translations.json-en-AU-virtual.json!=!../../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJTZWUgYWxsIHJldmlld3MiOiJTZWUgYWxsIHJldmlld3MiLCJXb3JraW5nIGF0IHtjb21wYW55X25hbWV9IjoiV29ya2luZyBhdCB7Y29tcGFueV9uYW1lfSIsInJhdGUgc2FsYXJ5IGFzIGhpZ2ggb3IgYXZlcmFnZSI6IlJhdGUgc2FsYXJ5IGFzIGhpZ2ggb3IgYXZlcmFnZSIsImVtcGxveWVlcyByZWNvbW1lbmQgdGhpcyBlbXBsb3llciB0byBmcmllbmRzIjoiRW1wbG95ZWVzIHJlY29tbWVuZCB0aGlzIGVtcGxveWVyIHRvIGZyaWVuZHMiLCJDb21tdW5pdHkgZ3VpZGVsaW5lIGRlc2NyaXB0aW9uIjoiUmF0aW5ncyBmb3Ige2NvbXBhbnlOYW1lfSBhcmUgc2hhcmVkIGFzLWlzIGZyb20gZW1wbG95ZWVzIGluIGxpbmUgd2l0aCBvdXIgPFRleHRMaW5rPmNvbW11bml0eSBndWlkZWxpbmVzPC9UZXh0TGluaz4ifQ%3D%3D!"),
        () => import(
          /* webpackChunkName: "en-AU-translations" */
          "./translations.json-en-AU-virtual.json!=!../../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJTZWUgYWxsIHJldmlld3MiOiJTZWUgYWxsIHJldmlld3MiLCJXb3JraW5nIGF0IHtjb21wYW55X25hbWV9IjoiV29ya2luZyBhdCB7Y29tcGFueV9uYW1lfSIsInJhdGUgc2FsYXJ5IGFzIGhpZ2ggb3IgYXZlcmFnZSI6IlJhdGUgc2FsYXJ5IGFzIGhpZ2ggb3IgYXZlcmFnZSIsImVtcGxveWVlcyByZWNvbW1lbmQgdGhpcyBlbXBsb3llciB0byBmcmllbmRzIjoiRW1wbG95ZWVzIHJlY29tbWVuZCB0aGlzIGVtcGxveWVyIHRvIGZyaWVuZHMiLCJDb21tdW5pdHkgZ3VpZGVsaW5lIGRlc2NyaXB0aW9uIjoiUmF0aW5ncyBmb3Ige2NvbXBhbnlOYW1lfSBhcmUgc2hhcmVkIGFzLWlzIGZyb20gZW1wbG95ZWVzIGluIGxpbmUgd2l0aCBvdXIgPFRleHRMaW5rPmNvbW11bml0eSBndWlkZWxpbmVzPC9UZXh0TGluaz4ifQ%3D%3D!"
        )
      ),
"en-HK": createLanguage(
        require.resolveWeak("./translations.json-en-HK-virtual.json!=!../../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJTZWUgYWxsIHJldmlld3MiOiJTZWUgYWxsIHJldmlld3MiLCJXb3JraW5nIGF0IHtjb21wYW55X25hbWV9IjoiV29ya2luZyBhdCB7Y29tcGFueV9uYW1lfSIsInJhdGUgc2FsYXJ5IGFzIGhpZ2ggb3IgYXZlcmFnZSI6IlJhdGUgc2FsYXJ5IGFzIGhpZ2ggb3IgYXZlcmFnZSIsImVtcGxveWVlcyByZWNvbW1lbmQgdGhpcyBlbXBsb3llciB0byBmcmllbmRzIjoiRW1wbG95ZWVzIHJlY29tbWVuZCB0aGlzIGVtcGxveWVyIHRvIGZyaWVuZHMiLCJDb21tdW5pdHkgZ3VpZGVsaW5lIGRlc2NyaXB0aW9uIjoiUmF0aW5ncyBmb3Ige2NvbXBhbnlOYW1lfSBhcmUgc2hhcmVkIGFzLWlzIGZyb20gZW1wbG95ZWVzIGluIGxpbmUgd2l0aCBvdXIgPFRleHRMaW5rPmNvbW11bml0eSBndWlkZWxpbmVzPC9UZXh0TGluaz4ifQ%3D%3D!"),
        () => import(
          /* webpackChunkName: "en-HK-translations" */
          "./translations.json-en-HK-virtual.json!=!../../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJTZWUgYWxsIHJldmlld3MiOiJTZWUgYWxsIHJldmlld3MiLCJXb3JraW5nIGF0IHtjb21wYW55X25hbWV9IjoiV29ya2luZyBhdCB7Y29tcGFueV9uYW1lfSIsInJhdGUgc2FsYXJ5IGFzIGhpZ2ggb3IgYXZlcmFnZSI6IlJhdGUgc2FsYXJ5IGFzIGhpZ2ggb3IgYXZlcmFnZSIsImVtcGxveWVlcyByZWNvbW1lbmQgdGhpcyBlbXBsb3llciB0byBmcmllbmRzIjoiRW1wbG95ZWVzIHJlY29tbWVuZCB0aGlzIGVtcGxveWVyIHRvIGZyaWVuZHMiLCJDb21tdW5pdHkgZ3VpZGVsaW5lIGRlc2NyaXB0aW9uIjoiUmF0aW5ncyBmb3Ige2NvbXBhbnlOYW1lfSBhcmUgc2hhcmVkIGFzLWlzIGZyb20gZW1wbG95ZWVzIGluIGxpbmUgd2l0aCBvdXIgPFRleHRMaW5rPmNvbW11bml0eSBndWlkZWxpbmVzPC9UZXh0TGluaz4ifQ%3D%3D!"
        )
      ),
"en-ID": createLanguage(
        require.resolveWeak("./translations.json-en-ID-virtual.json!=!../../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJTZWUgYWxsIHJldmlld3MiOiJTZWUgYWxsIHJldmlld3MiLCJXb3JraW5nIGF0IHtjb21wYW55X25hbWV9IjoiV29ya2luZyBhdCB7Y29tcGFueV9uYW1lfSIsInJhdGUgc2FsYXJ5IGFzIGhpZ2ggb3IgYXZlcmFnZSI6IlJhdGUgc2FsYXJ5IGFzIGhpZ2ggb3IgYXZlcmFnZSIsImVtcGxveWVlcyByZWNvbW1lbmQgdGhpcyBlbXBsb3llciB0byBmcmllbmRzIjoiRW1wbG95ZWVzIHJlY29tbWVuZCB0aGlzIGVtcGxveWVyIHRvIGZyaWVuZHMiLCJDb21tdW5pdHkgZ3VpZGVsaW5lIGRlc2NyaXB0aW9uIjoiUmF0aW5ncyBmb3Ige2NvbXBhbnlOYW1lfSBhcmUgc2hhcmVkIGFzLWlzIGZyb20gZW1wbG95ZWVzIGluIGxpbmUgd2l0aCBvdXIgPFRleHRMaW5rPmNvbW11bml0eSBndWlkZWxpbmVzPC9UZXh0TGluaz4ifQ%3D%3D!"),
        () => import(
          /* webpackChunkName: "en-ID-translations" */
          "./translations.json-en-ID-virtual.json!=!../../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJTZWUgYWxsIHJldmlld3MiOiJTZWUgYWxsIHJldmlld3MiLCJXb3JraW5nIGF0IHtjb21wYW55X25hbWV9IjoiV29ya2luZyBhdCB7Y29tcGFueV9uYW1lfSIsInJhdGUgc2FsYXJ5IGFzIGhpZ2ggb3IgYXZlcmFnZSI6IlJhdGUgc2FsYXJ5IGFzIGhpZ2ggb3IgYXZlcmFnZSIsImVtcGxveWVlcyByZWNvbW1lbmQgdGhpcyBlbXBsb3llciB0byBmcmllbmRzIjoiRW1wbG95ZWVzIHJlY29tbWVuZCB0aGlzIGVtcGxveWVyIHRvIGZyaWVuZHMiLCJDb21tdW5pdHkgZ3VpZGVsaW5lIGRlc2NyaXB0aW9uIjoiUmF0aW5ncyBmb3Ige2NvbXBhbnlOYW1lfSBhcmUgc2hhcmVkIGFzLWlzIGZyb20gZW1wbG95ZWVzIGluIGxpbmUgd2l0aCBvdXIgPFRleHRMaW5rPmNvbW11bml0eSBndWlkZWxpbmVzPC9UZXh0TGluaz4ifQ%3D%3D!"
        )
      ),
"en-MY": createLanguage(
        require.resolveWeak("./translations.json-en-MY-virtual.json!=!../../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJTZWUgYWxsIHJldmlld3MiOiJTZWUgYWxsIHJldmlld3MiLCJXb3JraW5nIGF0IHtjb21wYW55X25hbWV9IjoiV29ya2luZyBhdCB7Y29tcGFueV9uYW1lfSIsInJhdGUgc2FsYXJ5IGFzIGhpZ2ggb3IgYXZlcmFnZSI6IlJhdGUgc2FsYXJ5IGFzIGhpZ2ggb3IgYXZlcmFnZSIsImVtcGxveWVlcyByZWNvbW1lbmQgdGhpcyBlbXBsb3llciB0byBmcmllbmRzIjoiRW1wbG95ZWVzIHJlY29tbWVuZCB0aGlzIGVtcGxveWVyIHRvIGZyaWVuZHMiLCJDb21tdW5pdHkgZ3VpZGVsaW5lIGRlc2NyaXB0aW9uIjoiUmF0aW5ncyBmb3Ige2NvbXBhbnlOYW1lfSBhcmUgc2hhcmVkIGFzLWlzIGZyb20gZW1wbG95ZWVzIGluIGxpbmUgd2l0aCBvdXIgPFRleHRMaW5rPmNvbW11bml0eSBndWlkZWxpbmVzPC9UZXh0TGluaz4ifQ%3D%3D!"),
        () => import(
          /* webpackChunkName: "en-MY-translations" */
          "./translations.json-en-MY-virtual.json!=!../../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJTZWUgYWxsIHJldmlld3MiOiJTZWUgYWxsIHJldmlld3MiLCJXb3JraW5nIGF0IHtjb21wYW55X25hbWV9IjoiV29ya2luZyBhdCB7Y29tcGFueV9uYW1lfSIsInJhdGUgc2FsYXJ5IGFzIGhpZ2ggb3IgYXZlcmFnZSI6IlJhdGUgc2FsYXJ5IGFzIGhpZ2ggb3IgYXZlcmFnZSIsImVtcGxveWVlcyByZWNvbW1lbmQgdGhpcyBlbXBsb3llciB0byBmcmllbmRzIjoiRW1wbG95ZWVzIHJlY29tbWVuZCB0aGlzIGVtcGxveWVyIHRvIGZyaWVuZHMiLCJDb21tdW5pdHkgZ3VpZGVsaW5lIGRlc2NyaXB0aW9uIjoiUmF0aW5ncyBmb3Ige2NvbXBhbnlOYW1lfSBhcmUgc2hhcmVkIGFzLWlzIGZyb20gZW1wbG95ZWVzIGluIGxpbmUgd2l0aCBvdXIgPFRleHRMaW5rPmNvbW11bml0eSBndWlkZWxpbmVzPC9UZXh0TGluaz4ifQ%3D%3D!"
        )
      ),
"en-NZ": createLanguage(
        require.resolveWeak("./translations.json-en-NZ-virtual.json!=!../../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJTZWUgYWxsIHJldmlld3MiOiJTZWUgYWxsIHJldmlld3MiLCJXb3JraW5nIGF0IHtjb21wYW55X25hbWV9IjoiV29ya2luZyBhdCB7Y29tcGFueV9uYW1lfSIsInJhdGUgc2FsYXJ5IGFzIGhpZ2ggb3IgYXZlcmFnZSI6IlJhdGUgc2FsYXJ5IGFzIGhpZ2ggb3IgYXZlcmFnZSIsImVtcGxveWVlcyByZWNvbW1lbmQgdGhpcyBlbXBsb3llciB0byBmcmllbmRzIjoiRW1wbG95ZWVzIHJlY29tbWVuZCB0aGlzIGVtcGxveWVyIHRvIGZyaWVuZHMiLCJDb21tdW5pdHkgZ3VpZGVsaW5lIGRlc2NyaXB0aW9uIjoiUmF0aW5ncyBmb3Ige2NvbXBhbnlOYW1lfSBhcmUgc2hhcmVkIGFzLWlzIGZyb20gZW1wbG95ZWVzIGluIGxpbmUgd2l0aCBvdXIgPFRleHRMaW5rPmNvbW11bml0eSBndWlkZWxpbmVzPC9UZXh0TGluaz4ifQ%3D%3D!"),
        () => import(
          /* webpackChunkName: "en-NZ-translations" */
          "./translations.json-en-NZ-virtual.json!=!../../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJTZWUgYWxsIHJldmlld3MiOiJTZWUgYWxsIHJldmlld3MiLCJXb3JraW5nIGF0IHtjb21wYW55X25hbWV9IjoiV29ya2luZyBhdCB7Y29tcGFueV9uYW1lfSIsInJhdGUgc2FsYXJ5IGFzIGhpZ2ggb3IgYXZlcmFnZSI6IlJhdGUgc2FsYXJ5IGFzIGhpZ2ggb3IgYXZlcmFnZSIsImVtcGxveWVlcyByZWNvbW1lbmQgdGhpcyBlbXBsb3llciB0byBmcmllbmRzIjoiRW1wbG95ZWVzIHJlY29tbWVuZCB0aGlzIGVtcGxveWVyIHRvIGZyaWVuZHMiLCJDb21tdW5pdHkgZ3VpZGVsaW5lIGRlc2NyaXB0aW9uIjoiUmF0aW5ncyBmb3Ige2NvbXBhbnlOYW1lfSBhcmUgc2hhcmVkIGFzLWlzIGZyb20gZW1wbG95ZWVzIGluIGxpbmUgd2l0aCBvdXIgPFRleHRMaW5rPmNvbW11bml0eSBndWlkZWxpbmVzPC9UZXh0TGluaz4ifQ%3D%3D!"
        )
      ),
"en-PH": createLanguage(
        require.resolveWeak("./translations.json-en-PH-virtual.json!=!../../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJTZWUgYWxsIHJldmlld3MiOiJTZWUgYWxsIHJldmlld3MiLCJXb3JraW5nIGF0IHtjb21wYW55X25hbWV9IjoiV29ya2luZyBhdCB7Y29tcGFueV9uYW1lfSIsInJhdGUgc2FsYXJ5IGFzIGhpZ2ggb3IgYXZlcmFnZSI6IlJhdGUgc2FsYXJ5IGFzIGhpZ2ggb3IgYXZlcmFnZSIsImVtcGxveWVlcyByZWNvbW1lbmQgdGhpcyBlbXBsb3llciB0byBmcmllbmRzIjoiRW1wbG95ZWVzIHJlY29tbWVuZCB0aGlzIGVtcGxveWVyIHRvIGZyaWVuZHMiLCJDb21tdW5pdHkgZ3VpZGVsaW5lIGRlc2NyaXB0aW9uIjoiUmF0aW5ncyBmb3Ige2NvbXBhbnlOYW1lfSBhcmUgc2hhcmVkIGFzLWlzIGZyb20gZW1wbG95ZWVzIGluIGxpbmUgd2l0aCBvdXIgPFRleHRMaW5rPmNvbW11bml0eSBndWlkZWxpbmVzPC9UZXh0TGluaz4ifQ%3D%3D!"),
        () => import(
          /* webpackChunkName: "en-PH-translations" */
          "./translations.json-en-PH-virtual.json!=!../../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJTZWUgYWxsIHJldmlld3MiOiJTZWUgYWxsIHJldmlld3MiLCJXb3JraW5nIGF0IHtjb21wYW55X25hbWV9IjoiV29ya2luZyBhdCB7Y29tcGFueV9uYW1lfSIsInJhdGUgc2FsYXJ5IGFzIGhpZ2ggb3IgYXZlcmFnZSI6IlJhdGUgc2FsYXJ5IGFzIGhpZ2ggb3IgYXZlcmFnZSIsImVtcGxveWVlcyByZWNvbW1lbmQgdGhpcyBlbXBsb3llciB0byBmcmllbmRzIjoiRW1wbG95ZWVzIHJlY29tbWVuZCB0aGlzIGVtcGxveWVyIHRvIGZyaWVuZHMiLCJDb21tdW5pdHkgZ3VpZGVsaW5lIGRlc2NyaXB0aW9uIjoiUmF0aW5ncyBmb3Ige2NvbXBhbnlOYW1lfSBhcmUgc2hhcmVkIGFzLWlzIGZyb20gZW1wbG95ZWVzIGluIGxpbmUgd2l0aCBvdXIgPFRleHRMaW5rPmNvbW11bml0eSBndWlkZWxpbmVzPC9UZXh0TGluaz4ifQ%3D%3D!"
        )
      ),
"en-SG": createLanguage(
        require.resolveWeak("./translations.json-en-SG-virtual.json!=!../../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJTZWUgYWxsIHJldmlld3MiOiJTZWUgYWxsIHJldmlld3MiLCJXb3JraW5nIGF0IHtjb21wYW55X25hbWV9IjoiV29ya2luZyBhdCB7Y29tcGFueV9uYW1lfSIsInJhdGUgc2FsYXJ5IGFzIGhpZ2ggb3IgYXZlcmFnZSI6IlJhdGUgc2FsYXJ5IGFzIGhpZ2ggb3IgYXZlcmFnZSIsImVtcGxveWVlcyByZWNvbW1lbmQgdGhpcyBlbXBsb3llciB0byBmcmllbmRzIjoiRW1wbG95ZWVzIHJlY29tbWVuZCB0aGlzIGVtcGxveWVyIHRvIGZyaWVuZHMiLCJDb21tdW5pdHkgZ3VpZGVsaW5lIGRlc2NyaXB0aW9uIjoiUmF0aW5ncyBmb3Ige2NvbXBhbnlOYW1lfSBhcmUgc2hhcmVkIGFzLWlzIGZyb20gZW1wbG95ZWVzIGluIGxpbmUgd2l0aCBvdXIgPFRleHRMaW5rPmNvbW11bml0eSBndWlkZWxpbmVzPC9UZXh0TGluaz4ifQ%3D%3D!"),
        () => import(
          /* webpackChunkName: "en-SG-translations" */
          "./translations.json-en-SG-virtual.json!=!../../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJTZWUgYWxsIHJldmlld3MiOiJTZWUgYWxsIHJldmlld3MiLCJXb3JraW5nIGF0IHtjb21wYW55X25hbWV9IjoiV29ya2luZyBhdCB7Y29tcGFueV9uYW1lfSIsInJhdGUgc2FsYXJ5IGFzIGhpZ2ggb3IgYXZlcmFnZSI6IlJhdGUgc2FsYXJ5IGFzIGhpZ2ggb3IgYXZlcmFnZSIsImVtcGxveWVlcyByZWNvbW1lbmQgdGhpcyBlbXBsb3llciB0byBmcmllbmRzIjoiRW1wbG95ZWVzIHJlY29tbWVuZCB0aGlzIGVtcGxveWVyIHRvIGZyaWVuZHMiLCJDb21tdW5pdHkgZ3VpZGVsaW5lIGRlc2NyaXB0aW9uIjoiUmF0aW5ncyBmb3Ige2NvbXBhbnlOYW1lfSBhcmUgc2hhcmVkIGFzLWlzIGZyb20gZW1wbG95ZWVzIGluIGxpbmUgd2l0aCBvdXIgPFRleHRMaW5rPmNvbW11bml0eSBndWlkZWxpbmVzPC9UZXh0TGluaz4ifQ%3D%3D!"
        )
      ),
"en-TH": createLanguage(
        require.resolveWeak("./translations.json-en-TH-virtual.json!=!../../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJTZWUgYWxsIHJldmlld3MiOiJTZWUgYWxsIHJldmlld3MiLCJXb3JraW5nIGF0IHtjb21wYW55X25hbWV9IjoiV29ya2luZyBhdCB7Y29tcGFueV9uYW1lfSIsInJhdGUgc2FsYXJ5IGFzIGhpZ2ggb3IgYXZlcmFnZSI6IlJhdGUgc2FsYXJ5IGFzIGhpZ2ggb3IgYXZlcmFnZSIsImVtcGxveWVlcyByZWNvbW1lbmQgdGhpcyBlbXBsb3llciB0byBmcmllbmRzIjoiRW1wbG95ZWVzIHJlY29tbWVuZCB0aGlzIGVtcGxveWVyIHRvIGZyaWVuZHMiLCJDb21tdW5pdHkgZ3VpZGVsaW5lIGRlc2NyaXB0aW9uIjoiUmF0aW5ncyBmb3Ige2NvbXBhbnlOYW1lfSBhcmUgc2hhcmVkIGFzLWlzIGZyb20gZW1wbG95ZWVzIGluIGxpbmUgd2l0aCBvdXIgPFRleHRMaW5rPmNvbW11bml0eSBndWlkZWxpbmVzPC9UZXh0TGluaz4ifQ%3D%3D!"),
        () => import(
          /* webpackChunkName: "en-TH-translations" */
          "./translations.json-en-TH-virtual.json!=!../../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJTZWUgYWxsIHJldmlld3MiOiJTZWUgYWxsIHJldmlld3MiLCJXb3JraW5nIGF0IHtjb21wYW55X25hbWV9IjoiV29ya2luZyBhdCB7Y29tcGFueV9uYW1lfSIsInJhdGUgc2FsYXJ5IGFzIGhpZ2ggb3IgYXZlcmFnZSI6IlJhdGUgc2FsYXJ5IGFzIGhpZ2ggb3IgYXZlcmFnZSIsImVtcGxveWVlcyByZWNvbW1lbmQgdGhpcyBlbXBsb3llciB0byBmcmllbmRzIjoiRW1wbG95ZWVzIHJlY29tbWVuZCB0aGlzIGVtcGxveWVyIHRvIGZyaWVuZHMiLCJDb21tdW5pdHkgZ3VpZGVsaW5lIGRlc2NyaXB0aW9uIjoiUmF0aW5ncyBmb3Ige2NvbXBhbnlOYW1lfSBhcmUgc2hhcmVkIGFzLWlzIGZyb20gZW1wbG95ZWVzIGluIGxpbmUgd2l0aCBvdXIgPFRleHRMaW5rPmNvbW11bml0eSBndWlkZWxpbmVzPC9UZXh0TGluaz4ifQ%3D%3D!"
        )
      ),
"th-TH": createLanguage(
        require.resolveWeak("./translations.json-th-TH-virtual.json!=!../../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJTZWUgYWxsIHJldmlld3MiOiLguJTguLnguKPguLXguKfguLTguKfguJfguLHguYnguIfguKvguKHguJQiLCJXb3JraW5nIGF0IHtjb21wYW55X25hbWV9Ijoi4LiB4Liy4Lij4LiX4Liz4LiH4Liy4LiZ4LiX4Li14LmIIHtjb21wYW55X25hbWV9IiwicmF0ZSBzYWxhcnkgYXMgaGlnaCBvciBhdmVyYWdlIjoi4LmD4Lir4LmJ4LiE4Liw4LmB4LiZ4LiZ4LmA4LiH4Li04LiZ4LmA4LiU4Li34Lit4LiZ4Lin4LmI4Liy4Lit4Lii4Li54LmI4LmD4LiZ4Lij4Liw4LiU4Lix4Lia4Liq4Li54LiH4Lir4Lij4Li34Lit4Lib4Liy4LiZ4LiB4Lil4Liy4LiHIiwiZW1wbG95ZWVzIHJlY29tbWVuZCB0aGlzIGVtcGxveWVyIHRvIGZyaWVuZHMiOiLguJ7guJnguLHguIHguIfguLLguJnguYHguJnguLDguJnguLPguJrguKPguLTguKnguLHguJfguJnguLXguYnguYPguKvguYnguIHguLHguJrguYDguJ7guLfguYjguK3guJnguILguK3guIfguJ7guKfguIHguYDguILguLIiLCJDb21tdW5pdHkgZ3VpZGVsaW5lIGRlc2NyaXB0aW9uIjoi4LiE4Liw4LmB4LiZ4LiZ4Liq4Liz4Lir4Lij4Lix4LiaIHtjb21wYW55TmFtZX0g4LiI4Liw4LmB4Liq4LiU4LiH4LiV4Liy4Lih4LiE4Liw4LmB4LiZ4LiZ4LiI4Lij4Li04LiH4LiI4Liy4LiB4Lie4LiZ4Lix4LiB4LiH4Liy4LiZIOC5gOC4nuC4t%2BC5iOC4reC5g%2BC4q%2BC5ieC5gOC4m%2BC5h%2BC4meC5hOC4m%2BC4leC4suC4oTxUZXh0TGluaz7guYHguJnguKfguJfguLLguIfguJvguI%2FguLTguJrguLHguJXguLTguILguK3guIfguIrguLjguKHguIrguJk8L1RleHRMaW5rPuC4guC4reC4h%2BC5gOC4o%2BC4siJ9!"),
        () => import(
          /* webpackChunkName: "th-TH-translations" */
          "./translations.json-th-TH-virtual.json!=!../../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJTZWUgYWxsIHJldmlld3MiOiLguJTguLnguKPguLXguKfguLTguKfguJfguLHguYnguIfguKvguKHguJQiLCJXb3JraW5nIGF0IHtjb21wYW55X25hbWV9Ijoi4LiB4Liy4Lij4LiX4Liz4LiH4Liy4LiZ4LiX4Li14LmIIHtjb21wYW55X25hbWV9IiwicmF0ZSBzYWxhcnkgYXMgaGlnaCBvciBhdmVyYWdlIjoi4LmD4Lir4LmJ4LiE4Liw4LmB4LiZ4LiZ4LmA4LiH4Li04LiZ4LmA4LiU4Li34Lit4LiZ4Lin4LmI4Liy4Lit4Lii4Li54LmI4LmD4LiZ4Lij4Liw4LiU4Lix4Lia4Liq4Li54LiH4Lir4Lij4Li34Lit4Lib4Liy4LiZ4LiB4Lil4Liy4LiHIiwiZW1wbG95ZWVzIHJlY29tbWVuZCB0aGlzIGVtcGxveWVyIHRvIGZyaWVuZHMiOiLguJ7guJnguLHguIHguIfguLLguJnguYHguJnguLDguJnguLPguJrguKPguLTguKnguLHguJfguJnguLXguYnguYPguKvguYnguIHguLHguJrguYDguJ7guLfguYjguK3guJnguILguK3guIfguJ7guKfguIHguYDguILguLIiLCJDb21tdW5pdHkgZ3VpZGVsaW5lIGRlc2NyaXB0aW9uIjoi4LiE4Liw4LmB4LiZ4LiZ4Liq4Liz4Lir4Lij4Lix4LiaIHtjb21wYW55TmFtZX0g4LiI4Liw4LmB4Liq4LiU4LiH4LiV4Liy4Lih4LiE4Liw4LmB4LiZ4LiZ4LiI4Lij4Li04LiH4LiI4Liy4LiB4Lie4LiZ4Lix4LiB4LiH4Liy4LiZIOC5gOC4nuC4t%2BC5iOC4reC5g%2BC4q%2BC5ieC5gOC4m%2BC5h%2BC4meC5hOC4m%2BC4leC4suC4oTxUZXh0TGluaz7guYHguJnguKfguJfguLLguIfguJvguI%2FguLTguJrguLHguJXguLTguILguK3guIfguIrguLjguKHguIrguJk8L1RleHRMaW5rPuC4guC4reC4h%2BC5gOC4o%2BC4siJ9!"
        )
      ),
"id-ID": createLanguage(
        require.resolveWeak("./translations.json-id-ID-virtual.json!=!../../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJTZWUgYWxsIHJldmlld3MiOiJMaWhhdCBzZW11YSB1bGFzYW4iLCJXb3JraW5nIGF0IHtjb21wYW55X25hbWV9IjoiQmVrZXJqYSBkaSB7Y29tcGFueV9uYW1lfSIsInJhdGUgc2FsYXJ5IGFzIGhpZ2ggb3IgYXZlcmFnZSI6Ik1lbmlsYWkgZ2FqaSB0aW5nZ2kgYXRhdSByYXRhLXJhdGEiLCJlbXBsb3llZXMgcmVjb21tZW5kIHRoaXMgZW1wbG95ZXIgdG8gZnJpZW5kcyI6IlBlZ2F3YWkgbWVyZWtvbWVuZGFzaWthbiBwZXJ1c2FoYWFuIGluaSIsIkNvbW11bml0eSBndWlkZWxpbmUgZGVzY3JpcHRpb24iOiJQZXJpbmdrYXQgdW50dWsge2NvbXBhbnlOYW1lfSBkaWJhZ2lrYW4gc2ViYWdhaW1hbmEgYWRhbnlhIGRhcmkga2FyeWF3YW4gc2VzdWFpIGRlbmdhbiBwZXJhdHVyYW4ga2FtaTxUZXh0TGluaz4gcGVkb21hbiBrb211bml0YXM8L1RleHRMaW5rPiJ9!"),
        () => import(
          /* webpackChunkName: "id-ID-translations" */
          "./translations.json-id-ID-virtual.json!=!../../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJTZWUgYWxsIHJldmlld3MiOiJMaWhhdCBzZW11YSB1bGFzYW4iLCJXb3JraW5nIGF0IHtjb21wYW55X25hbWV9IjoiQmVrZXJqYSBkaSB7Y29tcGFueV9uYW1lfSIsInJhdGUgc2FsYXJ5IGFzIGhpZ2ggb3IgYXZlcmFnZSI6Ik1lbmlsYWkgZ2FqaSB0aW5nZ2kgYXRhdSByYXRhLXJhdGEiLCJlbXBsb3llZXMgcmVjb21tZW5kIHRoaXMgZW1wbG95ZXIgdG8gZnJpZW5kcyI6IlBlZ2F3YWkgbWVyZWtvbWVuZGFzaWthbiBwZXJ1c2FoYWFuIGluaSIsIkNvbW11bml0eSBndWlkZWxpbmUgZGVzY3JpcHRpb24iOiJQZXJpbmdrYXQgdW50dWsge2NvbXBhbnlOYW1lfSBkaWJhZ2lrYW4gc2ViYWdhaW1hbmEgYWRhbnlhIGRhcmkga2FyeWF3YW4gc2VzdWFpIGRlbmdhbiBwZXJhdHVyYW4ga2FtaTxUZXh0TGluaz4gcGVkb21hbiBrb211bml0YXM8L1RleHRMaW5rPiJ9!"
        )
      ),
"zh-HK": createLanguage(
        require.resolveWeak("./translations.json-zh-HK-virtual.json!=!../../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJTZWUgYWxsIHJldmlld3MiOiJTZWUgYWxsIHJldmlld3MiLCJXb3JraW5nIGF0IHtjb21wYW55X25hbWV9IjoiV29ya2luZyBhdCB7Y29tcGFueV9uYW1lfSIsInJhdGUgc2FsYXJ5IGFzIGhpZ2ggb3IgYXZlcmFnZSI6IlJhdGUgc2FsYXJ5IGFzIGhpZ2ggb3IgYXZlcmFnZSIsImVtcGxveWVlcyByZWNvbW1lbmQgdGhpcyBlbXBsb3llciB0byBmcmllbmRzIjoiRW1wbG95ZWVzIHJlY29tbWVuZCB0aGlzIGVtcGxveWVyIHRvIGZyaWVuZHMiLCJDb21tdW5pdHkgZ3VpZGVsaW5lIGRlc2NyaXB0aW9uIjoiUmF0aW5ncyBmb3Ige2NvbXBhbnlOYW1lfSBhcmUgc2hhcmVkIGFzLWlzIGZyb20gZW1wbG95ZWVzIGluIGxpbmUgd2l0aCBvdXIgPFRleHRMaW5rPmNvbW11bml0eSBndWlkZWxpbmVzPC9UZXh0TGluaz4ifQ%3D%3D!"),
        () => import(
          /* webpackChunkName: "zh-HK-translations" */
          "./translations.json-zh-HK-virtual.json!=!../../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJTZWUgYWxsIHJldmlld3MiOiJTZWUgYWxsIHJldmlld3MiLCJXb3JraW5nIGF0IHtjb21wYW55X25hbWV9IjoiV29ya2luZyBhdCB7Y29tcGFueV9uYW1lfSIsInJhdGUgc2FsYXJ5IGFzIGhpZ2ggb3IgYXZlcmFnZSI6IlJhdGUgc2FsYXJ5IGFzIGhpZ2ggb3IgYXZlcmFnZSIsImVtcGxveWVlcyByZWNvbW1lbmQgdGhpcyBlbXBsb3llciB0byBmcmllbmRzIjoiRW1wbG95ZWVzIHJlY29tbWVuZCB0aGlzIGVtcGxveWVyIHRvIGZyaWVuZHMiLCJDb21tdW5pdHkgZ3VpZGVsaW5lIGRlc2NyaXB0aW9uIjoiUmF0aW5ncyBmb3Ige2NvbXBhbnlOYW1lfSBhcmUgc2hhcmVkIGFzLWlzIGZyb20gZW1wbG95ZWVzIGluIGxpbmUgd2l0aCBvdXIgPFRleHRMaW5rPmNvbW11bml0eSBndWlkZWxpbmVzPC9UZXh0TGluaz4ifQ%3D%3D!"
        )
      ),
"en-PSEUDO": createLanguage(
        require.resolveWeak("./translations.json-en-PSEUDO-virtual.json!=!../../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJTZWUgYWxsIHJldmlld3MiOiJbxaDhur3hur3hur3hur0gxIPEg8aaxpogxZnhur3hur3hub3DrMOs4bq94bq9xbXFoV0iLCJXb3JraW5nIGF0IHtjb21wYW55X25hbWV9IjoiW8W0w7bDtsO2xZnEt8Osw6zDrOC4geC4teC5icSjIMSDxIPEg%2BG5ryB7Y29tcGFueV9uYW1lfV0iLCJyYXRlIHNhbGFyeSBhcyBoaWdoIG9yIGF2ZXJhZ2UiOiJbxZjEg8SD4bmv4bq94bq9IMWhxIPEg8aaxIPEg8WZw73DvSDEg8SDxaEg4bipw6zDrMSj4bipIMO2w7bFmSDEg8SD4bm94bq94bq9xZnEg8SDxKPhur3hur1dIiwiZW1wbG95ZWVzIHJlY29tbWVuZCB0aGlzIGVtcGxveWVyIHRvIGZyaWVuZHMiOiJbw4ttzILGpcaaw7bDtsO2w73DvcO94bq94bq94bq94bq94bq94bq9xaEgxZnhur3hur3hur3Dp8O2w7bDtm3Mgm3MguG6veG6veG6veC4geC4teC5icaMIOG5r%2BG4qcOsw6zDrMWhIOG6veG6veG6vW3MgsalxprDtsO2w7bDvcO9w73hur3hur3hur3FmSDhua%2FDtsO2w7YgxpLFmcOsw6zDrOG6veG6veG6veC4geC4teC5icaMxaFdIiwiQ29tbXVuaXR5IGd1aWRlbGluZSBkZXNjcmlwdGlvbiI6IlvFmMSDxIPEg%2BG5r8Osw6zDrOC4geC4teC5icSjxaEgxpLDtsO2w7bFmSB7Y29tcGFueU5hbWV9IMSDxIPEg8WZ4bq94bq94bq9IMWh4bipxIPEg8SDxZnhur3hur3hur3GjCDEg8SDxIPFoS3DrMOsw6zFoSDGksWZw7bDtsO2bcyCIOG6veG6veG6vW3MgsalxprDtsO2w7bDvcO9w73hur3hur3hur3hur3hur3hur3FoSDDrMOsw6zguIHguLXguYkgxprDrMOsw6zguIHguLXguYnhur3hur3hur0gxbXDrMOsw6zhua%2FhuKkgw7bDtsO2x5rHmseaxZkgPFRleHRMaW5rPsOnw7bDtm3Mgm3Mgseax5rguIHguLXguYnDrMOs4bmvw73DvSDEo8eax5rDrMOsxozhur3hur3GmsOsw6zguIHguLXguYnhur3hur3FoTwvVGV4dExpbms%2BXSJ9!"),
        () => import(
          /* webpackChunkName: "en-PSEUDO-translations" */
          "./translations.json-en-PSEUDO-virtual.json!=!../../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJTZWUgYWxsIHJldmlld3MiOiJbxaDhur3hur3hur3hur0gxIPEg8aaxpogxZnhur3hur3hub3DrMOs4bq94bq9xbXFoV0iLCJXb3JraW5nIGF0IHtjb21wYW55X25hbWV9IjoiW8W0w7bDtsO2xZnEt8Osw6zDrOC4geC4teC5icSjIMSDxIPEg%2BG5ryB7Y29tcGFueV9uYW1lfV0iLCJyYXRlIHNhbGFyeSBhcyBoaWdoIG9yIGF2ZXJhZ2UiOiJbxZjEg8SD4bmv4bq94bq9IMWhxIPEg8aaxIPEg8WZw73DvSDEg8SDxaEg4bipw6zDrMSj4bipIMO2w7bFmSDEg8SD4bm94bq94bq9xZnEg8SDxKPhur3hur1dIiwiZW1wbG95ZWVzIHJlY29tbWVuZCB0aGlzIGVtcGxveWVyIHRvIGZyaWVuZHMiOiJbw4ttzILGpcaaw7bDtsO2w73DvcO94bq94bq94bq94bq94bq94bq9xaEgxZnhur3hur3hur3Dp8O2w7bDtm3Mgm3MguG6veG6veG6veC4geC4teC5icaMIOG5r%2BG4qcOsw6zDrMWhIOG6veG6veG6vW3MgsalxprDtsO2w7bDvcO9w73hur3hur3hur3FmSDhua%2FDtsO2w7YgxpLFmcOsw6zDrOG6veG6veG6veC4geC4teC5icaMxaFdIiwiQ29tbXVuaXR5IGd1aWRlbGluZSBkZXNjcmlwdGlvbiI6IlvFmMSDxIPEg%2BG5r8Osw6zDrOC4geC4teC5icSjxaEgxpLDtsO2w7bFmSB7Y29tcGFueU5hbWV9IMSDxIPEg8WZ4bq94bq94bq9IMWh4bipxIPEg8SDxZnhur3hur3hur3GjCDEg8SDxIPFoS3DrMOsw6zFoSDGksWZw7bDtsO2bcyCIOG6veG6veG6vW3MgsalxprDtsO2w7bDvcO9w73hur3hur3hur3hur3hur3hur3FoSDDrMOsw6zguIHguLXguYkgxprDrMOsw6zguIHguLXguYnhur3hur3hur0gxbXDrMOsw6zhua%2FhuKkgw7bDtsO2x5rHmseaxZkgPFRleHRMaW5rPsOnw7bDtm3Mgm3Mgseax5rguIHguLXguYnDrMOs4bmvw73DvSDEo8eax5rDrMOsxozhur3hur3GmsOsw6zguIHguLXguYnhur3hur3FoTwvVGV4dExpbms%2BXSJ9!"
        )
      )
      });
  
      export { translations as default };
    