import type { AppConfig } from '../config';

import { isLocationBasedReviewRoute } from './isLocationBasedReviewRoute';

export const getLocationId = (params: {
  reviewLocation: AppConfig['reviewLocation'];
  locationSlug?: string;
}): number | undefined =>
  isLocationBasedReviewRoute({
    locationSlug: params.locationSlug,
    locationId: params.reviewLocation.details?.locationId,
  })
    ? params.reviewLocation.details?.locationId
    : undefined;
