import { Box, ButtonIcon, IconDelete, Loader } from 'braid-design-system';
import { AspectRatioBox } from '../AspectRatioBox/AspectRatioBox';
import * as styles from './AutoFitImage.css';
import { AspectRatio } from '../../../types';
import translations from './.vocab';
import { useTranslations } from '@vocab/react';

export interface Props {
  alt?: string;
  aspectRatio?: AspectRatio;
  background?: 'body' | 'surface';
  isImageLeftAlign?: boolean;
  isLoading?: boolean;
  onDeleteButtonClicked?: () => void;
  showDeleteButton?: boolean;
  src?: string;
}

export const AutoFitImage = ({
  isLoading = false,
  src,
  alt,
  background = 'surface',
  aspectRatio = '2:1',
  showDeleteButton,
  onDeleteButtonClicked,
  isImageLeftAlign = false,
}: Props) => {
  const { t } = useTranslations(translations);
  const handleOnDeleteButtonClicked = () => {
    if (onDeleteButtonClicked) onDeleteButtonClicked();
  };

  return (
    <Box background={background} position="relative">
      <AspectRatioBox aspectRatio={aspectRatio}>
        {isLoading ? (
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            height="full"
            data-testid="loader"
          >
            <Loader />
          </Box>
        ) : (
          <Box width="full" height="full" data-testid="auto-fit-img">
            <img
              style={{
                objectFit: 'contain',
                width: '100%',
                height: '100%',
                maxWidth: isImageLeftAlign ? 'fit-content' : undefined,
              }}
              loading="lazy"
              src={src}
              alt={alt}
            />
          </Box>
        )}
      </AspectRatioBox>
      {showDeleteButton ? (
        <Box className={styles.deleteButton} data={{ testid: 'delete-button' }}>
          <ButtonIcon
            icon={<IconDelete />}
            onClick={handleOnDeleteButtonClicked}
            id="DeleteButton"
            data={{ testid: 'delete-icon' }}
            label={t('Delete')}
          />
        </Box>
      ) : null}
    </Box>
  );
};
