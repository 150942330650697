import { sendHotjarEvent, useAppContext, useConfig } from '@seek/libs-shared';
import { useTranslations } from '@vocab/react';
import {
  Box,
  Button,
  Column,
  Columns,
  IconThumb,
  Inline,
  Text,
} from 'braid-design-system';
import { useState } from 'react';
import { trackLinkEvents } from '../../../../shared/tracking/candidateApp/trackLinkEvents';
import translations from './.vocab';

export const FeedbackAISummary = () => {
  const { t } = useTranslations(translations);
  const config = useConfig();
  const { country } = config;

  const context = useAppContext();

  const [showFeedbackOptions, setShowFeedbackOptions] = useState(true);

  return !showFeedbackOptions ? (
    <Box id="hj-survey-container" padding={'none'} />
  ) : (
    <Box background="neutralLight" padding="medium" borderRadius="large">
      <Columns
        space={'small'}
        alignY={'center'}
        align={['center', 'left']}
        collapseBelow="tablet"
      >
        <Column width="content">
          <Text tone="neutral" weight="strong" size="small">
            {t('Is this AI summary useful?')}
          </Text>
        </Column>
        <Column>
          <Inline space="small" align={'left'}>
            <Button
              id="btn-feedback-ai-summary-yes"
              variant="ghost"
              icon={<IconThumb direction="up" />}
              size="small"
              onClick={() => {
                setShowFeedbackOptions(false);
                trackLinkEvents.trackFeedbackAISummaryClicked({
                  config,
                  context,
                  type: 'positive',
                });
                sendHotjarEvent(`feedback-ai-summary-yes-${country}`);
              }}
            >
              {t('Yes')}
            </Button>
            <Button
              id="btn-feedback-ai-summary-no"
              variant="ghost"
              icon={<IconThumb direction="down" />}
              size="small"
              onClick={() => {
                setShowFeedbackOptions(false);
                trackLinkEvents.trackFeedbackAISummaryClicked({
                  config,
                  context,
                  type: 'negative',
                });
                sendHotjarEvent(`feedback-ai-summary-no-${country}`);
              }}
            >
              {t('No')}
            </Button>
          </Inline>
        </Column>
      </Columns>
    </Box>
  );
};
