
      import { createLanguage, createTranslationFile } from '@vocab/webpack/web';
      
    const translations = createTranslationFile({
      "en": createLanguage(
        require.resolveWeak("./translations.json-en-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJTaWduIGluIHRvIHlvdXIgYWR2ZXJ0aXNlciBhY2NvdW50IHRvIHJlcGx5IjoiU2lnbiBpbiB0byB5b3VyIGFkdmVydGlzZXIgYWNjb3VudCB0byByZXBseSIsIlRvIHJlcGx5IHRvIGEgcmV2aWV3LCBzaWduIGluIHRvIHlvdXIgYWR2ZXJ0aXNlciBhY2NvdW50IHdpdGggYWRtaW4gYWNjZXNzLiI6IlRvIHJlcGx5IHRvIGEgcmV2aWV3LCBzaWduIGluIHRvIHlvdXIgYWR2ZXJ0aXNlciBhY2NvdW50IHdpdGggYWRtaW4gYWNjZXNzLiIsIklmIGl0IHN0aWxsIGRvZXNuJ3Qgd29yaywgcmVhY2ggb3V0IHRvIG91ciBDdXN0b21lciBzZXJ2aWNlIHRlYW0uIjoiSWYgaXQgc3RpbGwgZG9lc24ndCB3b3JrLCByZWFjaCBvdXQgdG8gb3VyIDxUZXh0TGluaz5DdXN0b21lciBzZXJ2aWNlIHRlYW0uPC9UZXh0TGluaz4ifQ%3D%3D!"),
        () => import(
          /* webpackChunkName: "en-translations" */
          "./translations.json-en-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJTaWduIGluIHRvIHlvdXIgYWR2ZXJ0aXNlciBhY2NvdW50IHRvIHJlcGx5IjoiU2lnbiBpbiB0byB5b3VyIGFkdmVydGlzZXIgYWNjb3VudCB0byByZXBseSIsIlRvIHJlcGx5IHRvIGEgcmV2aWV3LCBzaWduIGluIHRvIHlvdXIgYWR2ZXJ0aXNlciBhY2NvdW50IHdpdGggYWRtaW4gYWNjZXNzLiI6IlRvIHJlcGx5IHRvIGEgcmV2aWV3LCBzaWduIGluIHRvIHlvdXIgYWR2ZXJ0aXNlciBhY2NvdW50IHdpdGggYWRtaW4gYWNjZXNzLiIsIklmIGl0IHN0aWxsIGRvZXNuJ3Qgd29yaywgcmVhY2ggb3V0IHRvIG91ciBDdXN0b21lciBzZXJ2aWNlIHRlYW0uIjoiSWYgaXQgc3RpbGwgZG9lc24ndCB3b3JrLCByZWFjaCBvdXQgdG8gb3VyIDxUZXh0TGluaz5DdXN0b21lciBzZXJ2aWNlIHRlYW0uPC9UZXh0TGluaz4ifQ%3D%3D!"
        )
      ),
"th": createLanguage(
        require.resolveWeak("./translations.json-th-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJTaWduIGluIHRvIHlvdXIgYWR2ZXJ0aXNlciBhY2NvdW50IHRvIHJlcGx5Ijoi4Lil4LiH4LiK4Li34LmI4Lit4LmA4LiC4LmJ4Liy4LmD4LiK4LmJ4Lia4Lix4LiN4LiK4Li14Lic4Li54LmJ4Lib4Lij4Liw4LiB4Lit4Lia4LiB4Liy4Lij4LiC4Lit4LiH4LiE4Li44LiT4LmA4Lie4Li34LmI4Lit4LiV4Lit4Lia4LiB4Lil4Lix4LiaIiwiVG8gcmVwbHkgdG8gYSByZXZpZXcsIHNpZ24gaW4gdG8geW91ciBhZHZlcnRpc2VyIGFjY291bnQgd2l0aCBhZG1pbiBhY2Nlc3MuIjoi4Lir4Liy4LiB4LiV4LmJ4Lit4LiH4LiB4Liy4Lij4LiV4Lit4Lia4LiB4Lil4Lix4Lia4Lij4Li14Lin4Li04LinIOC4geC4o%2BC4uOC4k%2BC4suC4peC4h%2BC4iuC4t%2BC5iOC4reC5gOC4guC5ieC4suC5g%2BC4iuC5ieC4muC4seC4jeC4iuC4teC4nOC4ueC5ieC4m%2BC4o%2BC4sOC4geC4reC4muC4geC4suC4o%2BC4lOC5ieC4p%2BC4ouC4muC4seC4jeC4iuC4teC5geC4reC4lOC4oeC4tOC4mSIsIklmIGl0IHN0aWxsIGRvZXNuJ3Qgd29yaywgcmVhY2ggb3V0IHRvIG91ciBDdXN0b21lciBzZXJ2aWNlIHRlYW0uIjoi4Lir4Liy4LiB4Lii4Lix4LiH4LiE4LiH4LmD4LiK4LmJ4LiB4Liy4Lij4LmE4Lih4LmI4LmE4LiU4LmJIOC5guC4m%2BC4o%2BC4lOC4leC4tOC4lOC4leC5iOC4rTxUZXh0TGluaz7guYHguJzguJnguIHguKXguLnguIHguITguYnguLLguKrguLHguKHguJ7guLHguJnguJjguYw8L1RleHRMaW5rPuC4guC4reC4h%2BC5gOC4o%2BC4siJ9!"),
        () => import(
          /* webpackChunkName: "th-translations" */
          "./translations.json-th-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJTaWduIGluIHRvIHlvdXIgYWR2ZXJ0aXNlciBhY2NvdW50IHRvIHJlcGx5Ijoi4Lil4LiH4LiK4Li34LmI4Lit4LmA4LiC4LmJ4Liy4LmD4LiK4LmJ4Lia4Lix4LiN4LiK4Li14Lic4Li54LmJ4Lib4Lij4Liw4LiB4Lit4Lia4LiB4Liy4Lij4LiC4Lit4LiH4LiE4Li44LiT4LmA4Lie4Li34LmI4Lit4LiV4Lit4Lia4LiB4Lil4Lix4LiaIiwiVG8gcmVwbHkgdG8gYSByZXZpZXcsIHNpZ24gaW4gdG8geW91ciBhZHZlcnRpc2VyIGFjY291bnQgd2l0aCBhZG1pbiBhY2Nlc3MuIjoi4Lir4Liy4LiB4LiV4LmJ4Lit4LiH4LiB4Liy4Lij4LiV4Lit4Lia4LiB4Lil4Lix4Lia4Lij4Li14Lin4Li04LinIOC4geC4o%2BC4uOC4k%2BC4suC4peC4h%2BC4iuC4t%2BC5iOC4reC5gOC4guC5ieC4suC5g%2BC4iuC5ieC4muC4seC4jeC4iuC4teC4nOC4ueC5ieC4m%2BC4o%2BC4sOC4geC4reC4muC4geC4suC4o%2BC4lOC5ieC4p%2BC4ouC4muC4seC4jeC4iuC4teC5geC4reC4lOC4oeC4tOC4mSIsIklmIGl0IHN0aWxsIGRvZXNuJ3Qgd29yaywgcmVhY2ggb3V0IHRvIG91ciBDdXN0b21lciBzZXJ2aWNlIHRlYW0uIjoi4Lir4Liy4LiB4Lii4Lix4LiH4LiE4LiH4LmD4LiK4LmJ4LiB4Liy4Lij4LmE4Lih4LmI4LmE4LiU4LmJIOC5guC4m%2BC4o%2BC4lOC4leC4tOC4lOC4leC5iOC4rTxUZXh0TGluaz7guYHguJzguJnguIHguKXguLnguIHguITguYnguLLguKrguLHguKHguJ7guLHguJnguJjguYw8L1RleHRMaW5rPuC4guC4reC4h%2BC5gOC4o%2BC4siJ9!"
        )
      ),
"id": createLanguage(
        require.resolveWeak("./translations.json-id-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJTaWduIGluIHRvIHlvdXIgYWR2ZXJ0aXNlciBhY2NvdW50IHRvIHJlcGx5IjoiTWFzdWsga2UgYWt1biBwZW5naWtsYW4gQW5kYSB1bnR1ayBtZW1iYWxhcyIsIlRvIHJlcGx5IHRvIGEgcmV2aWV3LCBzaWduIGluIHRvIHlvdXIgYWR2ZXJ0aXNlciBhY2NvdW50IHdpdGggYWRtaW4gYWNjZXNzLiI6IlVudHVrIG1lbWJhbGFzIHVsYXNhbiwgbWFzdWsga2UgYWt1biBwZW5naWtsYW4gQW5kYSBkZW5nYW4gYWtzZXMgYWRtaW4uIiwiSWYgaXQgc3RpbGwgZG9lc24ndCB3b3JrLCByZWFjaCBvdXQgdG8gb3VyIEN1c3RvbWVyIHNlcnZpY2UgdGVhbS4iOiJKaWthIG1hc2loIHRpZGFrIGJlcmhhc2lsLCBodWJ1bmdpIHRpbSA8VGV4dExpbms%2BVGltIGxheWFuYW4gcGVsYW5nZ2FuLjwvVGV4dExpbms%2BIn0%3D!"),
        () => import(
          /* webpackChunkName: "id-translations" */
          "./translations.json-id-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJTaWduIGluIHRvIHlvdXIgYWR2ZXJ0aXNlciBhY2NvdW50IHRvIHJlcGx5IjoiTWFzdWsga2UgYWt1biBwZW5naWtsYW4gQW5kYSB1bnR1ayBtZW1iYWxhcyIsIlRvIHJlcGx5IHRvIGEgcmV2aWV3LCBzaWduIGluIHRvIHlvdXIgYWR2ZXJ0aXNlciBhY2NvdW50IHdpdGggYWRtaW4gYWNjZXNzLiI6IlVudHVrIG1lbWJhbGFzIHVsYXNhbiwgbWFzdWsga2UgYWt1biBwZW5naWtsYW4gQW5kYSBkZW5nYW4gYWtzZXMgYWRtaW4uIiwiSWYgaXQgc3RpbGwgZG9lc24ndCB3b3JrLCByZWFjaCBvdXQgdG8gb3VyIEN1c3RvbWVyIHNlcnZpY2UgdGVhbS4iOiJKaWthIG1hc2loIHRpZGFrIGJlcmhhc2lsLCBodWJ1bmdpIHRpbSA8VGV4dExpbms%2BVGltIGxheWFuYW4gcGVsYW5nZ2FuLjwvVGV4dExpbms%2BIn0%3D!"
        )
      ),
"zh": createLanguage(
        require.resolveWeak("./translations.json-zh-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJTaWduIGluIHRvIHlvdXIgYWR2ZXJ0aXNlciBhY2NvdW50IHRvIHJlcGx5IjoiU2lnbiBpbiB0byB5b3VyIGFkdmVydGlzZXIgYWNjb3VudCB0byByZXBseSIsIlRvIHJlcGx5IHRvIGEgcmV2aWV3LCBzaWduIGluIHRvIHlvdXIgYWR2ZXJ0aXNlciBhY2NvdW50IHdpdGggYWRtaW4gYWNjZXNzLiI6IlRvIHJlcGx5IHRvIGEgcmV2aWV3LCBzaWduIGluIHRvIHlvdXIgYWR2ZXJ0aXNlciBhY2NvdW50IHdpdGggYWRtaW4gYWNjZXNzLiIsIklmIGl0IHN0aWxsIGRvZXNuJ3Qgd29yaywgcmVhY2ggb3V0IHRvIG91ciBDdXN0b21lciBzZXJ2aWNlIHRlYW0uIjoiSWYgaXQgc3RpbGwgZG9lc24ndCB3b3JrLCByZWFjaCBvdXQgdG8gb3VyIDxUZXh0TGluaz5DdXN0b21lciBzZXJ2aWNlIHRlYW0uPC9UZXh0TGluaz4ifQ%3D%3D!"),
        () => import(
          /* webpackChunkName: "zh-translations" */
          "./translations.json-zh-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJTaWduIGluIHRvIHlvdXIgYWR2ZXJ0aXNlciBhY2NvdW50IHRvIHJlcGx5IjoiU2lnbiBpbiB0byB5b3VyIGFkdmVydGlzZXIgYWNjb3VudCB0byByZXBseSIsIlRvIHJlcGx5IHRvIGEgcmV2aWV3LCBzaWduIGluIHRvIHlvdXIgYWR2ZXJ0aXNlciBhY2NvdW50IHdpdGggYWRtaW4gYWNjZXNzLiI6IlRvIHJlcGx5IHRvIGEgcmV2aWV3LCBzaWduIGluIHRvIHlvdXIgYWR2ZXJ0aXNlciBhY2NvdW50IHdpdGggYWRtaW4gYWNjZXNzLiIsIklmIGl0IHN0aWxsIGRvZXNuJ3Qgd29yaywgcmVhY2ggb3V0IHRvIG91ciBDdXN0b21lciBzZXJ2aWNlIHRlYW0uIjoiSWYgaXQgc3RpbGwgZG9lc24ndCB3b3JrLCByZWFjaCBvdXQgdG8gb3VyIDxUZXh0TGluaz5DdXN0b21lciBzZXJ2aWNlIHRlYW0uPC9UZXh0TGluaz4ifQ%3D%3D!"
        )
      ),
"en-AU": createLanguage(
        require.resolveWeak("./translations.json-en-AU-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJTaWduIGluIHRvIHlvdXIgYWR2ZXJ0aXNlciBhY2NvdW50IHRvIHJlcGx5IjoiU2lnbiBpbiB0byB5b3VyIGFkdmVydGlzZXIgYWNjb3VudCB0byByZXBseSIsIlRvIHJlcGx5IHRvIGEgcmV2aWV3LCBzaWduIGluIHRvIHlvdXIgYWR2ZXJ0aXNlciBhY2NvdW50IHdpdGggYWRtaW4gYWNjZXNzLiI6IlRvIHJlcGx5IHRvIGEgcmV2aWV3LCBzaWduIGluIHRvIHlvdXIgYWR2ZXJ0aXNlciBhY2NvdW50IHdpdGggYWRtaW4gYWNjZXNzLiIsIklmIGl0IHN0aWxsIGRvZXNuJ3Qgd29yaywgcmVhY2ggb3V0IHRvIG91ciBDdXN0b21lciBzZXJ2aWNlIHRlYW0uIjoiSWYgaXQgc3RpbGwgZG9lc24ndCB3b3JrLCByZWFjaCBvdXQgdG8gb3VyIDxUZXh0TGluaz5DdXN0b21lciBzZXJ2aWNlIHRlYW0uPC9UZXh0TGluaz4ifQ%3D%3D!"),
        () => import(
          /* webpackChunkName: "en-AU-translations" */
          "./translations.json-en-AU-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJTaWduIGluIHRvIHlvdXIgYWR2ZXJ0aXNlciBhY2NvdW50IHRvIHJlcGx5IjoiU2lnbiBpbiB0byB5b3VyIGFkdmVydGlzZXIgYWNjb3VudCB0byByZXBseSIsIlRvIHJlcGx5IHRvIGEgcmV2aWV3LCBzaWduIGluIHRvIHlvdXIgYWR2ZXJ0aXNlciBhY2NvdW50IHdpdGggYWRtaW4gYWNjZXNzLiI6IlRvIHJlcGx5IHRvIGEgcmV2aWV3LCBzaWduIGluIHRvIHlvdXIgYWR2ZXJ0aXNlciBhY2NvdW50IHdpdGggYWRtaW4gYWNjZXNzLiIsIklmIGl0IHN0aWxsIGRvZXNuJ3Qgd29yaywgcmVhY2ggb3V0IHRvIG91ciBDdXN0b21lciBzZXJ2aWNlIHRlYW0uIjoiSWYgaXQgc3RpbGwgZG9lc24ndCB3b3JrLCByZWFjaCBvdXQgdG8gb3VyIDxUZXh0TGluaz5DdXN0b21lciBzZXJ2aWNlIHRlYW0uPC9UZXh0TGluaz4ifQ%3D%3D!"
        )
      ),
"en-HK": createLanguage(
        require.resolveWeak("./translations.json-en-HK-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJTaWduIGluIHRvIHlvdXIgYWR2ZXJ0aXNlciBhY2NvdW50IHRvIHJlcGx5IjoiU2lnbiBpbiB0byB5b3VyIGFkdmVydGlzZXIgYWNjb3VudCB0byByZXBseSIsIlRvIHJlcGx5IHRvIGEgcmV2aWV3LCBzaWduIGluIHRvIHlvdXIgYWR2ZXJ0aXNlciBhY2NvdW50IHdpdGggYWRtaW4gYWNjZXNzLiI6IlRvIHJlcGx5IHRvIGEgcmV2aWV3LCBzaWduIGluIHRvIHlvdXIgYWR2ZXJ0aXNlciBhY2NvdW50IHdpdGggYWRtaW4gYWNjZXNzLiIsIklmIGl0IHN0aWxsIGRvZXNuJ3Qgd29yaywgcmVhY2ggb3V0IHRvIG91ciBDdXN0b21lciBzZXJ2aWNlIHRlYW0uIjoiSWYgaXQgc3RpbGwgZG9lc24ndCB3b3JrLCByZWFjaCBvdXQgdG8gb3VyIDxUZXh0TGluaz5DdXN0b21lciBzZXJ2aWNlIHRlYW0uPC9UZXh0TGluaz4ifQ%3D%3D!"),
        () => import(
          /* webpackChunkName: "en-HK-translations" */
          "./translations.json-en-HK-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJTaWduIGluIHRvIHlvdXIgYWR2ZXJ0aXNlciBhY2NvdW50IHRvIHJlcGx5IjoiU2lnbiBpbiB0byB5b3VyIGFkdmVydGlzZXIgYWNjb3VudCB0byByZXBseSIsIlRvIHJlcGx5IHRvIGEgcmV2aWV3LCBzaWduIGluIHRvIHlvdXIgYWR2ZXJ0aXNlciBhY2NvdW50IHdpdGggYWRtaW4gYWNjZXNzLiI6IlRvIHJlcGx5IHRvIGEgcmV2aWV3LCBzaWduIGluIHRvIHlvdXIgYWR2ZXJ0aXNlciBhY2NvdW50IHdpdGggYWRtaW4gYWNjZXNzLiIsIklmIGl0IHN0aWxsIGRvZXNuJ3Qgd29yaywgcmVhY2ggb3V0IHRvIG91ciBDdXN0b21lciBzZXJ2aWNlIHRlYW0uIjoiSWYgaXQgc3RpbGwgZG9lc24ndCB3b3JrLCByZWFjaCBvdXQgdG8gb3VyIDxUZXh0TGluaz5DdXN0b21lciBzZXJ2aWNlIHRlYW0uPC9UZXh0TGluaz4ifQ%3D%3D!"
        )
      ),
"en-ID": createLanguage(
        require.resolveWeak("./translations.json-en-ID-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJTaWduIGluIHRvIHlvdXIgYWR2ZXJ0aXNlciBhY2NvdW50IHRvIHJlcGx5IjoiU2lnbiBpbiB0byB5b3VyIGFkdmVydGlzZXIgYWNjb3VudCB0byByZXBseSIsIlRvIHJlcGx5IHRvIGEgcmV2aWV3LCBzaWduIGluIHRvIHlvdXIgYWR2ZXJ0aXNlciBhY2NvdW50IHdpdGggYWRtaW4gYWNjZXNzLiI6IlRvIHJlcGx5IHRvIGEgcmV2aWV3LCBzaWduIGluIHRvIHlvdXIgYWR2ZXJ0aXNlciBhY2NvdW50IHdpdGggYWRtaW4gYWNjZXNzLiIsIklmIGl0IHN0aWxsIGRvZXNuJ3Qgd29yaywgcmVhY2ggb3V0IHRvIG91ciBDdXN0b21lciBzZXJ2aWNlIHRlYW0uIjoiSWYgaXQgc3RpbGwgZG9lc24ndCB3b3JrLCByZWFjaCBvdXQgdG8gb3VyIDxUZXh0TGluaz5DdXN0b21lciBzZXJ2aWNlIHRlYW0uPC9UZXh0TGluaz4ifQ%3D%3D!"),
        () => import(
          /* webpackChunkName: "en-ID-translations" */
          "./translations.json-en-ID-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJTaWduIGluIHRvIHlvdXIgYWR2ZXJ0aXNlciBhY2NvdW50IHRvIHJlcGx5IjoiU2lnbiBpbiB0byB5b3VyIGFkdmVydGlzZXIgYWNjb3VudCB0byByZXBseSIsIlRvIHJlcGx5IHRvIGEgcmV2aWV3LCBzaWduIGluIHRvIHlvdXIgYWR2ZXJ0aXNlciBhY2NvdW50IHdpdGggYWRtaW4gYWNjZXNzLiI6IlRvIHJlcGx5IHRvIGEgcmV2aWV3LCBzaWduIGluIHRvIHlvdXIgYWR2ZXJ0aXNlciBhY2NvdW50IHdpdGggYWRtaW4gYWNjZXNzLiIsIklmIGl0IHN0aWxsIGRvZXNuJ3Qgd29yaywgcmVhY2ggb3V0IHRvIG91ciBDdXN0b21lciBzZXJ2aWNlIHRlYW0uIjoiSWYgaXQgc3RpbGwgZG9lc24ndCB3b3JrLCByZWFjaCBvdXQgdG8gb3VyIDxUZXh0TGluaz5DdXN0b21lciBzZXJ2aWNlIHRlYW0uPC9UZXh0TGluaz4ifQ%3D%3D!"
        )
      ),
"en-MY": createLanguage(
        require.resolveWeak("./translations.json-en-MY-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJTaWduIGluIHRvIHlvdXIgYWR2ZXJ0aXNlciBhY2NvdW50IHRvIHJlcGx5IjoiU2lnbiBpbiB0byB5b3VyIGFkdmVydGlzZXIgYWNjb3VudCB0byByZXBseSIsIlRvIHJlcGx5IHRvIGEgcmV2aWV3LCBzaWduIGluIHRvIHlvdXIgYWR2ZXJ0aXNlciBhY2NvdW50IHdpdGggYWRtaW4gYWNjZXNzLiI6IlRvIHJlcGx5IHRvIGEgcmV2aWV3LCBzaWduIGluIHRvIHlvdXIgYWR2ZXJ0aXNlciBhY2NvdW50IHdpdGggYWRtaW4gYWNjZXNzLiIsIklmIGl0IHN0aWxsIGRvZXNuJ3Qgd29yaywgcmVhY2ggb3V0IHRvIG91ciBDdXN0b21lciBzZXJ2aWNlIHRlYW0uIjoiSWYgaXQgc3RpbGwgZG9lc24ndCB3b3JrLCByZWFjaCBvdXQgdG8gb3VyIDxUZXh0TGluaz5DdXN0b21lciBzZXJ2aWNlIHRlYW0uPC9UZXh0TGluaz4ifQ%3D%3D!"),
        () => import(
          /* webpackChunkName: "en-MY-translations" */
          "./translations.json-en-MY-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJTaWduIGluIHRvIHlvdXIgYWR2ZXJ0aXNlciBhY2NvdW50IHRvIHJlcGx5IjoiU2lnbiBpbiB0byB5b3VyIGFkdmVydGlzZXIgYWNjb3VudCB0byByZXBseSIsIlRvIHJlcGx5IHRvIGEgcmV2aWV3LCBzaWduIGluIHRvIHlvdXIgYWR2ZXJ0aXNlciBhY2NvdW50IHdpdGggYWRtaW4gYWNjZXNzLiI6IlRvIHJlcGx5IHRvIGEgcmV2aWV3LCBzaWduIGluIHRvIHlvdXIgYWR2ZXJ0aXNlciBhY2NvdW50IHdpdGggYWRtaW4gYWNjZXNzLiIsIklmIGl0IHN0aWxsIGRvZXNuJ3Qgd29yaywgcmVhY2ggb3V0IHRvIG91ciBDdXN0b21lciBzZXJ2aWNlIHRlYW0uIjoiSWYgaXQgc3RpbGwgZG9lc24ndCB3b3JrLCByZWFjaCBvdXQgdG8gb3VyIDxUZXh0TGluaz5DdXN0b21lciBzZXJ2aWNlIHRlYW0uPC9UZXh0TGluaz4ifQ%3D%3D!"
        )
      ),
"en-NZ": createLanguage(
        require.resolveWeak("./translations.json-en-NZ-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJTaWduIGluIHRvIHlvdXIgYWR2ZXJ0aXNlciBhY2NvdW50IHRvIHJlcGx5IjoiU2lnbiBpbiB0byB5b3VyIGFkdmVydGlzZXIgYWNjb3VudCB0byByZXBseSIsIlRvIHJlcGx5IHRvIGEgcmV2aWV3LCBzaWduIGluIHRvIHlvdXIgYWR2ZXJ0aXNlciBhY2NvdW50IHdpdGggYWRtaW4gYWNjZXNzLiI6IlRvIHJlcGx5IHRvIGEgcmV2aWV3LCBzaWduIGluIHRvIHlvdXIgYWR2ZXJ0aXNlciBhY2NvdW50IHdpdGggYWRtaW4gYWNjZXNzLiIsIklmIGl0IHN0aWxsIGRvZXNuJ3Qgd29yaywgcmVhY2ggb3V0IHRvIG91ciBDdXN0b21lciBzZXJ2aWNlIHRlYW0uIjoiSWYgaXQgc3RpbGwgZG9lc24ndCB3b3JrLCByZWFjaCBvdXQgdG8gb3VyIDxUZXh0TGluaz5DdXN0b21lciBzZXJ2aWNlIHRlYW0uPC9UZXh0TGluaz4ifQ%3D%3D!"),
        () => import(
          /* webpackChunkName: "en-NZ-translations" */
          "./translations.json-en-NZ-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJTaWduIGluIHRvIHlvdXIgYWR2ZXJ0aXNlciBhY2NvdW50IHRvIHJlcGx5IjoiU2lnbiBpbiB0byB5b3VyIGFkdmVydGlzZXIgYWNjb3VudCB0byByZXBseSIsIlRvIHJlcGx5IHRvIGEgcmV2aWV3LCBzaWduIGluIHRvIHlvdXIgYWR2ZXJ0aXNlciBhY2NvdW50IHdpdGggYWRtaW4gYWNjZXNzLiI6IlRvIHJlcGx5IHRvIGEgcmV2aWV3LCBzaWduIGluIHRvIHlvdXIgYWR2ZXJ0aXNlciBhY2NvdW50IHdpdGggYWRtaW4gYWNjZXNzLiIsIklmIGl0IHN0aWxsIGRvZXNuJ3Qgd29yaywgcmVhY2ggb3V0IHRvIG91ciBDdXN0b21lciBzZXJ2aWNlIHRlYW0uIjoiSWYgaXQgc3RpbGwgZG9lc24ndCB3b3JrLCByZWFjaCBvdXQgdG8gb3VyIDxUZXh0TGluaz5DdXN0b21lciBzZXJ2aWNlIHRlYW0uPC9UZXh0TGluaz4ifQ%3D%3D!"
        )
      ),
"en-PH": createLanguage(
        require.resolveWeak("./translations.json-en-PH-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJTaWduIGluIHRvIHlvdXIgYWR2ZXJ0aXNlciBhY2NvdW50IHRvIHJlcGx5IjoiU2lnbiBpbiB0byB5b3VyIGFkdmVydGlzZXIgYWNjb3VudCB0byByZXBseSIsIlRvIHJlcGx5IHRvIGEgcmV2aWV3LCBzaWduIGluIHRvIHlvdXIgYWR2ZXJ0aXNlciBhY2NvdW50IHdpdGggYWRtaW4gYWNjZXNzLiI6IlRvIHJlcGx5IHRvIGEgcmV2aWV3LCBzaWduIGluIHRvIHlvdXIgYWR2ZXJ0aXNlciBhY2NvdW50IHdpdGggYWRtaW4gYWNjZXNzLiIsIklmIGl0IHN0aWxsIGRvZXNuJ3Qgd29yaywgcmVhY2ggb3V0IHRvIG91ciBDdXN0b21lciBzZXJ2aWNlIHRlYW0uIjoiSWYgaXQgc3RpbGwgZG9lc24ndCB3b3JrLCByZWFjaCBvdXQgdG8gb3VyIDxUZXh0TGluaz5DdXN0b21lciBzZXJ2aWNlIHRlYW0uPC9UZXh0TGluaz4ifQ%3D%3D!"),
        () => import(
          /* webpackChunkName: "en-PH-translations" */
          "./translations.json-en-PH-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJTaWduIGluIHRvIHlvdXIgYWR2ZXJ0aXNlciBhY2NvdW50IHRvIHJlcGx5IjoiU2lnbiBpbiB0byB5b3VyIGFkdmVydGlzZXIgYWNjb3VudCB0byByZXBseSIsIlRvIHJlcGx5IHRvIGEgcmV2aWV3LCBzaWduIGluIHRvIHlvdXIgYWR2ZXJ0aXNlciBhY2NvdW50IHdpdGggYWRtaW4gYWNjZXNzLiI6IlRvIHJlcGx5IHRvIGEgcmV2aWV3LCBzaWduIGluIHRvIHlvdXIgYWR2ZXJ0aXNlciBhY2NvdW50IHdpdGggYWRtaW4gYWNjZXNzLiIsIklmIGl0IHN0aWxsIGRvZXNuJ3Qgd29yaywgcmVhY2ggb3V0IHRvIG91ciBDdXN0b21lciBzZXJ2aWNlIHRlYW0uIjoiSWYgaXQgc3RpbGwgZG9lc24ndCB3b3JrLCByZWFjaCBvdXQgdG8gb3VyIDxUZXh0TGluaz5DdXN0b21lciBzZXJ2aWNlIHRlYW0uPC9UZXh0TGluaz4ifQ%3D%3D!"
        )
      ),
"en-SG": createLanguage(
        require.resolveWeak("./translations.json-en-SG-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJTaWduIGluIHRvIHlvdXIgYWR2ZXJ0aXNlciBhY2NvdW50IHRvIHJlcGx5IjoiU2lnbiBpbiB0byB5b3VyIGFkdmVydGlzZXIgYWNjb3VudCB0byByZXBseSIsIlRvIHJlcGx5IHRvIGEgcmV2aWV3LCBzaWduIGluIHRvIHlvdXIgYWR2ZXJ0aXNlciBhY2NvdW50IHdpdGggYWRtaW4gYWNjZXNzLiI6IlRvIHJlcGx5IHRvIGEgcmV2aWV3LCBzaWduIGluIHRvIHlvdXIgYWR2ZXJ0aXNlciBhY2NvdW50IHdpdGggYWRtaW4gYWNjZXNzLiIsIklmIGl0IHN0aWxsIGRvZXNuJ3Qgd29yaywgcmVhY2ggb3V0IHRvIG91ciBDdXN0b21lciBzZXJ2aWNlIHRlYW0uIjoiSWYgaXQgc3RpbGwgZG9lc24ndCB3b3JrLCByZWFjaCBvdXQgdG8gb3VyIDxUZXh0TGluaz5DdXN0b21lciBzZXJ2aWNlIHRlYW0uPC9UZXh0TGluaz4ifQ%3D%3D!"),
        () => import(
          /* webpackChunkName: "en-SG-translations" */
          "./translations.json-en-SG-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJTaWduIGluIHRvIHlvdXIgYWR2ZXJ0aXNlciBhY2NvdW50IHRvIHJlcGx5IjoiU2lnbiBpbiB0byB5b3VyIGFkdmVydGlzZXIgYWNjb3VudCB0byByZXBseSIsIlRvIHJlcGx5IHRvIGEgcmV2aWV3LCBzaWduIGluIHRvIHlvdXIgYWR2ZXJ0aXNlciBhY2NvdW50IHdpdGggYWRtaW4gYWNjZXNzLiI6IlRvIHJlcGx5IHRvIGEgcmV2aWV3LCBzaWduIGluIHRvIHlvdXIgYWR2ZXJ0aXNlciBhY2NvdW50IHdpdGggYWRtaW4gYWNjZXNzLiIsIklmIGl0IHN0aWxsIGRvZXNuJ3Qgd29yaywgcmVhY2ggb3V0IHRvIG91ciBDdXN0b21lciBzZXJ2aWNlIHRlYW0uIjoiSWYgaXQgc3RpbGwgZG9lc24ndCB3b3JrLCByZWFjaCBvdXQgdG8gb3VyIDxUZXh0TGluaz5DdXN0b21lciBzZXJ2aWNlIHRlYW0uPC9UZXh0TGluaz4ifQ%3D%3D!"
        )
      ),
"en-TH": createLanguage(
        require.resolveWeak("./translations.json-en-TH-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJTaWduIGluIHRvIHlvdXIgYWR2ZXJ0aXNlciBhY2NvdW50IHRvIHJlcGx5IjoiU2lnbiBpbiB0byB5b3VyIGFkdmVydGlzZXIgYWNjb3VudCB0byByZXBseSIsIlRvIHJlcGx5IHRvIGEgcmV2aWV3LCBzaWduIGluIHRvIHlvdXIgYWR2ZXJ0aXNlciBhY2NvdW50IHdpdGggYWRtaW4gYWNjZXNzLiI6IlRvIHJlcGx5IHRvIGEgcmV2aWV3LCBzaWduIGluIHRvIHlvdXIgYWR2ZXJ0aXNlciBhY2NvdW50IHdpdGggYWRtaW4gYWNjZXNzLiIsIklmIGl0IHN0aWxsIGRvZXNuJ3Qgd29yaywgcmVhY2ggb3V0IHRvIG91ciBDdXN0b21lciBzZXJ2aWNlIHRlYW0uIjoiSWYgaXQgc3RpbGwgZG9lc24ndCB3b3JrLCByZWFjaCBvdXQgdG8gb3VyIDxUZXh0TGluaz5DdXN0b21lciBzZXJ2aWNlIHRlYW0uPC9UZXh0TGluaz4ifQ%3D%3D!"),
        () => import(
          /* webpackChunkName: "en-TH-translations" */
          "./translations.json-en-TH-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJTaWduIGluIHRvIHlvdXIgYWR2ZXJ0aXNlciBhY2NvdW50IHRvIHJlcGx5IjoiU2lnbiBpbiB0byB5b3VyIGFkdmVydGlzZXIgYWNjb3VudCB0byByZXBseSIsIlRvIHJlcGx5IHRvIGEgcmV2aWV3LCBzaWduIGluIHRvIHlvdXIgYWR2ZXJ0aXNlciBhY2NvdW50IHdpdGggYWRtaW4gYWNjZXNzLiI6IlRvIHJlcGx5IHRvIGEgcmV2aWV3LCBzaWduIGluIHRvIHlvdXIgYWR2ZXJ0aXNlciBhY2NvdW50IHdpdGggYWRtaW4gYWNjZXNzLiIsIklmIGl0IHN0aWxsIGRvZXNuJ3Qgd29yaywgcmVhY2ggb3V0IHRvIG91ciBDdXN0b21lciBzZXJ2aWNlIHRlYW0uIjoiSWYgaXQgc3RpbGwgZG9lc24ndCB3b3JrLCByZWFjaCBvdXQgdG8gb3VyIDxUZXh0TGluaz5DdXN0b21lciBzZXJ2aWNlIHRlYW0uPC9UZXh0TGluaz4ifQ%3D%3D!"
        )
      ),
"th-TH": createLanguage(
        require.resolveWeak("./translations.json-th-TH-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJTaWduIGluIHRvIHlvdXIgYWR2ZXJ0aXNlciBhY2NvdW50IHRvIHJlcGx5Ijoi4Lil4LiH4LiK4Li34LmI4Lit4LmA4LiC4LmJ4Liy4LmD4LiK4LmJ4Lia4Lix4LiN4LiK4Li14Lic4Li54LmJ4Lib4Lij4Liw4LiB4Lit4Lia4LiB4Liy4Lij4LiC4Lit4LiH4LiE4Li44LiT4LmA4Lie4Li34LmI4Lit4LiV4Lit4Lia4LiB4Lil4Lix4LiaIiwiVG8gcmVwbHkgdG8gYSByZXZpZXcsIHNpZ24gaW4gdG8geW91ciBhZHZlcnRpc2VyIGFjY291bnQgd2l0aCBhZG1pbiBhY2Nlc3MuIjoi4Lir4Liy4LiB4LiV4LmJ4Lit4LiH4LiB4Liy4Lij4LiV4Lit4Lia4LiB4Lil4Lix4Lia4Lij4Li14Lin4Li04LinIOC4geC4o%2BC4uOC4k%2BC4suC4peC4h%2BC4iuC4t%2BC5iOC4reC5gOC4guC5ieC4suC5g%2BC4iuC5ieC4muC4seC4jeC4iuC4teC4nOC4ueC5ieC4m%2BC4o%2BC4sOC4geC4reC4muC4geC4suC4o%2BC4lOC5ieC4p%2BC4ouC4muC4seC4jeC4iuC4teC5geC4reC4lOC4oeC4tOC4mSIsIklmIGl0IHN0aWxsIGRvZXNuJ3Qgd29yaywgcmVhY2ggb3V0IHRvIG91ciBDdXN0b21lciBzZXJ2aWNlIHRlYW0uIjoi4Lir4Liy4LiB4Lii4Lix4LiH4LiE4LiH4LmD4LiK4LmJ4LiB4Liy4Lij4LmE4Lih4LmI4LmE4LiU4LmJIOC5guC4m%2BC4o%2BC4lOC4leC4tOC4lOC4leC5iOC4rTxUZXh0TGluaz7guYHguJzguJnguIHguKXguLnguIHguITguYnguLLguKrguLHguKHguJ7guLHguJnguJjguYw8L1RleHRMaW5rPuC4guC4reC4h%2BC5gOC4o%2BC4siJ9!"),
        () => import(
          /* webpackChunkName: "th-TH-translations" */
          "./translations.json-th-TH-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJTaWduIGluIHRvIHlvdXIgYWR2ZXJ0aXNlciBhY2NvdW50IHRvIHJlcGx5Ijoi4Lil4LiH4LiK4Li34LmI4Lit4LmA4LiC4LmJ4Liy4LmD4LiK4LmJ4Lia4Lix4LiN4LiK4Li14Lic4Li54LmJ4Lib4Lij4Liw4LiB4Lit4Lia4LiB4Liy4Lij4LiC4Lit4LiH4LiE4Li44LiT4LmA4Lie4Li34LmI4Lit4LiV4Lit4Lia4LiB4Lil4Lix4LiaIiwiVG8gcmVwbHkgdG8gYSByZXZpZXcsIHNpZ24gaW4gdG8geW91ciBhZHZlcnRpc2VyIGFjY291bnQgd2l0aCBhZG1pbiBhY2Nlc3MuIjoi4Lir4Liy4LiB4LiV4LmJ4Lit4LiH4LiB4Liy4Lij4LiV4Lit4Lia4LiB4Lil4Lix4Lia4Lij4Li14Lin4Li04LinIOC4geC4o%2BC4uOC4k%2BC4suC4peC4h%2BC4iuC4t%2BC5iOC4reC5gOC4guC5ieC4suC5g%2BC4iuC5ieC4muC4seC4jeC4iuC4teC4nOC4ueC5ieC4m%2BC4o%2BC4sOC4geC4reC4muC4geC4suC4o%2BC4lOC5ieC4p%2BC4ouC4muC4seC4jeC4iuC4teC5geC4reC4lOC4oeC4tOC4mSIsIklmIGl0IHN0aWxsIGRvZXNuJ3Qgd29yaywgcmVhY2ggb3V0IHRvIG91ciBDdXN0b21lciBzZXJ2aWNlIHRlYW0uIjoi4Lir4Liy4LiB4Lii4Lix4LiH4LiE4LiH4LmD4LiK4LmJ4LiB4Liy4Lij4LmE4Lih4LmI4LmE4LiU4LmJIOC5guC4m%2BC4o%2BC4lOC4leC4tOC4lOC4leC5iOC4rTxUZXh0TGluaz7guYHguJzguJnguIHguKXguLnguIHguITguYnguLLguKrguLHguKHguJ7guLHguJnguJjguYw8L1RleHRMaW5rPuC4guC4reC4h%2BC5gOC4o%2BC4siJ9!"
        )
      ),
"id-ID": createLanguage(
        require.resolveWeak("./translations.json-id-ID-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJTaWduIGluIHRvIHlvdXIgYWR2ZXJ0aXNlciBhY2NvdW50IHRvIHJlcGx5IjoiTWFzdWsga2UgYWt1biBwZW5naWtsYW4gQW5kYSB1bnR1ayBtZW1iYWxhcyIsIlRvIHJlcGx5IHRvIGEgcmV2aWV3LCBzaWduIGluIHRvIHlvdXIgYWR2ZXJ0aXNlciBhY2NvdW50IHdpdGggYWRtaW4gYWNjZXNzLiI6IlVudHVrIG1lbWJhbGFzIHVsYXNhbiwgbWFzdWsga2UgYWt1biBwZW5naWtsYW4gQW5kYSBkZW5nYW4gYWtzZXMgYWRtaW4uIiwiSWYgaXQgc3RpbGwgZG9lc24ndCB3b3JrLCByZWFjaCBvdXQgdG8gb3VyIEN1c3RvbWVyIHNlcnZpY2UgdGVhbS4iOiJKaWthIG1hc2loIHRpZGFrIGJlcmhhc2lsLCBodWJ1bmdpIHRpbSA8VGV4dExpbms%2BVGltIGxheWFuYW4gcGVsYW5nZ2FuLjwvVGV4dExpbms%2BIn0%3D!"),
        () => import(
          /* webpackChunkName: "id-ID-translations" */
          "./translations.json-id-ID-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJTaWduIGluIHRvIHlvdXIgYWR2ZXJ0aXNlciBhY2NvdW50IHRvIHJlcGx5IjoiTWFzdWsga2UgYWt1biBwZW5naWtsYW4gQW5kYSB1bnR1ayBtZW1iYWxhcyIsIlRvIHJlcGx5IHRvIGEgcmV2aWV3LCBzaWduIGluIHRvIHlvdXIgYWR2ZXJ0aXNlciBhY2NvdW50IHdpdGggYWRtaW4gYWNjZXNzLiI6IlVudHVrIG1lbWJhbGFzIHVsYXNhbiwgbWFzdWsga2UgYWt1biBwZW5naWtsYW4gQW5kYSBkZW5nYW4gYWtzZXMgYWRtaW4uIiwiSWYgaXQgc3RpbGwgZG9lc24ndCB3b3JrLCByZWFjaCBvdXQgdG8gb3VyIEN1c3RvbWVyIHNlcnZpY2UgdGVhbS4iOiJKaWthIG1hc2loIHRpZGFrIGJlcmhhc2lsLCBodWJ1bmdpIHRpbSA8VGV4dExpbms%2BVGltIGxheWFuYW4gcGVsYW5nZ2FuLjwvVGV4dExpbms%2BIn0%3D!"
        )
      ),
"zh-HK": createLanguage(
        require.resolveWeak("./translations.json-zh-HK-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJTaWduIGluIHRvIHlvdXIgYWR2ZXJ0aXNlciBhY2NvdW50IHRvIHJlcGx5IjoiU2lnbiBpbiB0byB5b3VyIGFkdmVydGlzZXIgYWNjb3VudCB0byByZXBseSIsIlRvIHJlcGx5IHRvIGEgcmV2aWV3LCBzaWduIGluIHRvIHlvdXIgYWR2ZXJ0aXNlciBhY2NvdW50IHdpdGggYWRtaW4gYWNjZXNzLiI6IlRvIHJlcGx5IHRvIGEgcmV2aWV3LCBzaWduIGluIHRvIHlvdXIgYWR2ZXJ0aXNlciBhY2NvdW50IHdpdGggYWRtaW4gYWNjZXNzLiIsIklmIGl0IHN0aWxsIGRvZXNuJ3Qgd29yaywgcmVhY2ggb3V0IHRvIG91ciBDdXN0b21lciBzZXJ2aWNlIHRlYW0uIjoiSWYgaXQgc3RpbGwgZG9lc24ndCB3b3JrLCByZWFjaCBvdXQgdG8gb3VyIDxUZXh0TGluaz5DdXN0b21lciBzZXJ2aWNlIHRlYW0uPC9UZXh0TGluaz4ifQ%3D%3D!"),
        () => import(
          /* webpackChunkName: "zh-HK-translations" */
          "./translations.json-zh-HK-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJTaWduIGluIHRvIHlvdXIgYWR2ZXJ0aXNlciBhY2NvdW50IHRvIHJlcGx5IjoiU2lnbiBpbiB0byB5b3VyIGFkdmVydGlzZXIgYWNjb3VudCB0byByZXBseSIsIlRvIHJlcGx5IHRvIGEgcmV2aWV3LCBzaWduIGluIHRvIHlvdXIgYWR2ZXJ0aXNlciBhY2NvdW50IHdpdGggYWRtaW4gYWNjZXNzLiI6IlRvIHJlcGx5IHRvIGEgcmV2aWV3LCBzaWduIGluIHRvIHlvdXIgYWR2ZXJ0aXNlciBhY2NvdW50IHdpdGggYWRtaW4gYWNjZXNzLiIsIklmIGl0IHN0aWxsIGRvZXNuJ3Qgd29yaywgcmVhY2ggb3V0IHRvIG91ciBDdXN0b21lciBzZXJ2aWNlIHRlYW0uIjoiSWYgaXQgc3RpbGwgZG9lc24ndCB3b3JrLCByZWFjaCBvdXQgdG8gb3VyIDxUZXh0TGluaz5DdXN0b21lciBzZXJ2aWNlIHRlYW0uPC9UZXh0TGluaz4ifQ%3D%3D!"
        )
      ),
"en-PSEUDO": createLanguage(
        require.resolveWeak("./translations.json-en-PSEUDO-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJTaWduIGluIHRvIHlvdXIgYWR2ZXJ0aXNlciBhY2NvdW50IHRvIHJlcGx5IjoiW8Wgw6zDrMOsxKPguIHguLXguYkgw6zDrMOs4LiB4Li14LmJIOG5r8O2w7bDtiDDvcO9w73DtsO2w7bHmseax5rFmSDEg8SDxIPGjOG5veG6veG6veG6vcWZ4bmvw6zDrMOsxaHhur3hur3hur3FmSDEg8SDxIPDp8Onw7bDtsO2x5rHmsea4LiB4Li14LmJ4bmvIOG5r8O2w7bDtiDFmeG6veG6veG6vcalxprDvcO9w71dIiwiVG8gcmVwbHkgdG8gYSByZXZpZXcsIHNpZ24gaW4gdG8geW91ciBhZHZlcnRpc2VyIGFjY291bnQgd2l0aCBhZG1pbiBhY2Nlc3MuIjoiW%2BG5rsO2w7bDtiDFmeG6veG6veG6vcalxprDvcO9w70g4bmvw7bDtsO2IMSDxIPEgyDFmeG6veG6veG6veG5vcOsw6zDrOG6veG6veG6vcW1LCDFocOsw6zDrMSj4LiB4Li14LmJIMOsw6zDrOC4geC4teC5iSDhua%2FDtsO2w7Ygw73DvcO9w7bDtsO2x5rHmseaxZkgxIPEg8SDxozhub3hur3hur3hur3FmeG5r8Osw6zDrMWh4bq94bq94bq9xZkgxIPEg8SDw6fDp8O2w7bDtseax5rHmuC4geC4teC5ieG5ryDFtcOsw6zDrOG5r%2BG4qSDEg8SDxIPGjG3MgsOsw6zDrOC4geC4teC5iSDEg8SDxIPDp8On4bq94bq94bq9xaHFoS5dIiwiSWYgaXQgc3RpbGwgZG9lc24ndCB3b3JrLCByZWFjaCBvdXQgdG8gb3VyIEN1c3RvbWVyIHNlcnZpY2UgdGVhbS4iOiJbw4%2FGkiDDrMOsw6zhua8gxaHhua%2FDrMOsw6zGmsaaIMaMw7bDtsO24bq94bq94bq9xaHguIHguLXguYkn4bmvIMW1w7bDtsO2xZnEtywgxZnhur3hur3hur3Eg8SDxIPDp%2BG4qSDDtsO2w7bHmseax5rhua8g4bmvw7bDtsO2IMO2w7bDtseax5rHmsWZIDxUZXh0TGluaz7Dh8eax5rHmsWh4bmvw7bDtsO2bcyC4bq94bq94bq9xZkgxaHhur3hur3hur3FmeG5vcOsw6zDrMOn4bq94bq94bq9IOG5r%2BG6veG6veG6vcSDxIPEg23Mgi48L1RleHRMaW5rPl0ifQ%3D%3D!"),
        () => import(
          /* webpackChunkName: "en-PSEUDO-translations" */
          "./translations.json-en-PSEUDO-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJTaWduIGluIHRvIHlvdXIgYWR2ZXJ0aXNlciBhY2NvdW50IHRvIHJlcGx5IjoiW8Wgw6zDrMOsxKPguIHguLXguYkgw6zDrMOs4LiB4Li14LmJIOG5r8O2w7bDtiDDvcO9w73DtsO2w7bHmseax5rFmSDEg8SDxIPGjOG5veG6veG6veG6vcWZ4bmvw6zDrMOsxaHhur3hur3hur3FmSDEg8SDxIPDp8Onw7bDtsO2x5rHmsea4LiB4Li14LmJ4bmvIOG5r8O2w7bDtiDFmeG6veG6veG6vcalxprDvcO9w71dIiwiVG8gcmVwbHkgdG8gYSByZXZpZXcsIHNpZ24gaW4gdG8geW91ciBhZHZlcnRpc2VyIGFjY291bnQgd2l0aCBhZG1pbiBhY2Nlc3MuIjoiW%2BG5rsO2w7bDtiDFmeG6veG6veG6vcalxprDvcO9w70g4bmvw7bDtsO2IMSDxIPEgyDFmeG6veG6veG6veG5vcOsw6zDrOG6veG6veG6vcW1LCDFocOsw6zDrMSj4LiB4Li14LmJIMOsw6zDrOC4geC4teC5iSDhua%2FDtsO2w7Ygw73DvcO9w7bDtsO2x5rHmseaxZkgxIPEg8SDxozhub3hur3hur3hur3FmeG5r8Osw6zDrMWh4bq94bq94bq9xZkgxIPEg8SDw6fDp8O2w7bDtseax5rHmuC4geC4teC5ieG5ryDFtcOsw6zDrOG5r%2BG4qSDEg8SDxIPGjG3MgsOsw6zDrOC4geC4teC5iSDEg8SDxIPDp8On4bq94bq94bq9xaHFoS5dIiwiSWYgaXQgc3RpbGwgZG9lc24ndCB3b3JrLCByZWFjaCBvdXQgdG8gb3VyIEN1c3RvbWVyIHNlcnZpY2UgdGVhbS4iOiJbw4%2FGkiDDrMOsw6zhua8gxaHhua%2FDrMOsw6zGmsaaIMaMw7bDtsO24bq94bq94bq9xaHguIHguLXguYkn4bmvIMW1w7bDtsO2xZnEtywgxZnhur3hur3hur3Eg8SDxIPDp%2BG4qSDDtsO2w7bHmseax5rhua8g4bmvw7bDtsO2IMO2w7bDtseax5rHmsWZIDxUZXh0TGluaz7Dh8eax5rHmsWh4bmvw7bDtsO2bcyC4bq94bq94bq9xZkgxaHhur3hur3hur3FmeG5vcOsw6zDrMOn4bq94bq94bq9IOG5r%2BG6veG6veG6vcSDxIPEg23Mgi48L1RleHRMaW5rPl0ifQ%3D%3D!"
        )
      )
      });
  
      export { translations as default };
    