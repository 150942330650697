import { type PropsWithChildren, createContext, useContext } from 'react';

import type { Logger } from '../infrastructure/logging/logger';

type LoggerContextProps =
  | {
      logger: Logger;
    }
  | undefined;

type LoggerProviderProps = PropsWithChildren<LoggerContextProps>;

const LoggerProviderContext = createContext<LoggerContextProps>(undefined);

export const LoggerProvider = ({ logger, children }: LoggerProviderProps) => (
  <LoggerProviderContext.Provider value={{ logger }}>
    {children}
  </LoggerProviderContext.Provider>
);

export const useLogger = () => {
  const context = useContext(LoggerProviderContext);

  if (context === undefined) {
    throw new Error('useLogger must be used within a LoggerProvider');
  }

  return { logger: context.logger };
};
