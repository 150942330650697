import { createValidator } from '@seek/validators-js';

import type { MonthPickerValue } from '../../type';

import translations from './.vocab';

interface ValidatorProps {
  from?: MonthPickerValue;
  to?: MonthPickerValue;
}

export const testIsWorkHistoryValid = (value: ValidatorProps) => {
  if (
    !value?.from?.month ||
    !value?.from?.year ||
    !value?.to?.month ||
    !value?.to?.year
  ) {
    return false;
  }

  return (
    `${value.to.year}${value.to.month.toString().padStart(2, '0')}` >=
    `${value.from.year}${value.from.month.toString().padStart(2, '0')}`
  );
};

export const { isWorkHistoryValid } = createValidator({
  test: ({ value }: { value: ValidatorProps }) => testIsWorkHistoryValid(value),
  formatErrorMessages: (messages) => () =>
    messages['Finish date must be after start date'].format(),
  translations,
  validatorName: 'isWorkHistoryValid',
});
