
      import { createLanguage, createTranslationFile } from '@vocab/webpack/web';
      
    const translations = createTranslationFile({
      "en": createLanguage(
        require.resolveWeak("./translations.json-en-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJPcHRpb25hbCI6Ik9wdGlvbmFsIiwiMSBzdGFyIjoiMSBzdGFyIiwiMiBzdGFycyI6IjIgc3RhcnMiLCIzIHN0YXJzIjoiMyBzdGFycyIsIjQgc3RhcnMiOiI0IHN0YXJzIiwiNSBzdGFycyI6IjUgc3RhcnMifQ%3D%3D!"),
        () => import(
          /* webpackChunkName: "en-translations" */
          "./translations.json-en-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJPcHRpb25hbCI6Ik9wdGlvbmFsIiwiMSBzdGFyIjoiMSBzdGFyIiwiMiBzdGFycyI6IjIgc3RhcnMiLCIzIHN0YXJzIjoiMyBzdGFycyIsIjQgc3RhcnMiOiI0IHN0YXJzIiwiNSBzdGFycyI6IjUgc3RhcnMifQ%3D%3D!"
        )
      ),
"th": createLanguage(
        require.resolveWeak("./translations.json-th-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJPcHRpb25hbCI6IuC5hOC4oeC5iOC4iOC4s%2BC5gOC4m%2BC5h%2BC4meC4leC5ieC4reC4h%2BC4o%2BC4sOC4muC4uCIsIjEgc3RhciI6IjEg4LiU4Liy4LinIiwiMiBzdGFycyI6IjIg4LiU4Liy4LinIiwiMyBzdGFycyI6IjMg4LiU4Liy4LinIiwiNCBzdGFycyI6IjQg4LiU4Liy4LinIiwiNSBzdGFycyI6IjUg4LiU4Liy4LinIn0%3D!"),
        () => import(
          /* webpackChunkName: "th-translations" */
          "./translations.json-th-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJPcHRpb25hbCI6IuC5hOC4oeC5iOC4iOC4s%2BC5gOC4m%2BC5h%2BC4meC4leC5ieC4reC4h%2BC4o%2BC4sOC4muC4uCIsIjEgc3RhciI6IjEg4LiU4Liy4LinIiwiMiBzdGFycyI6IjIg4LiU4Liy4LinIiwiMyBzdGFycyI6IjMg4LiU4Liy4LinIiwiNCBzdGFycyI6IjQg4LiU4Liy4LinIiwiNSBzdGFycyI6IjUg4LiU4Liy4LinIn0%3D!"
        )
      ),
"id": createLanguage(
        require.resolveWeak("./translations.json-id-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJPcHRpb25hbCI6Ik9wc2lvbmFsIiwiMSBzdGFyIjoiMSBiaW50YW5nIiwiMiBzdGFycyI6IjIgYmludGFuZyIsIjMgc3RhcnMiOiIzIGJpbnRhbmciLCI0IHN0YXJzIjoiNCBiaW50YW5nIiwiNSBzdGFycyI6IjUgYmludGFuZyJ9!"),
        () => import(
          /* webpackChunkName: "id-translations" */
          "./translations.json-id-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJPcHRpb25hbCI6Ik9wc2lvbmFsIiwiMSBzdGFyIjoiMSBiaW50YW5nIiwiMiBzdGFycyI6IjIgYmludGFuZyIsIjMgc3RhcnMiOiIzIGJpbnRhbmciLCI0IHN0YXJzIjoiNCBiaW50YW5nIiwiNSBzdGFycyI6IjUgYmludGFuZyJ9!"
        )
      ),
"zh": createLanguage(
        require.resolveWeak("./translations.json-zh-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJPcHRpb25hbCI6Ik9wdGlvbmFsIiwiMSBzdGFyIjoiMSBzdGFyIiwiMiBzdGFycyI6IjIgc3RhcnMiLCIzIHN0YXJzIjoiMyBzdGFycyIsIjQgc3RhcnMiOiI0IHN0YXJzIiwiNSBzdGFycyI6IjUgc3RhcnMifQ%3D%3D!"),
        () => import(
          /* webpackChunkName: "zh-translations" */
          "./translations.json-zh-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJPcHRpb25hbCI6Ik9wdGlvbmFsIiwiMSBzdGFyIjoiMSBzdGFyIiwiMiBzdGFycyI6IjIgc3RhcnMiLCIzIHN0YXJzIjoiMyBzdGFycyIsIjQgc3RhcnMiOiI0IHN0YXJzIiwiNSBzdGFycyI6IjUgc3RhcnMifQ%3D%3D!"
        )
      ),
"en-AU": createLanguage(
        require.resolveWeak("./translations.json-en-AU-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJPcHRpb25hbCI6Ik9wdGlvbmFsIiwiMSBzdGFyIjoiMSBzdGFyIiwiMiBzdGFycyI6IjIgc3RhcnMiLCIzIHN0YXJzIjoiMyBzdGFycyIsIjQgc3RhcnMiOiI0IHN0YXJzIiwiNSBzdGFycyI6IjUgc3RhcnMifQ%3D%3D!"),
        () => import(
          /* webpackChunkName: "en-AU-translations" */
          "./translations.json-en-AU-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJPcHRpb25hbCI6Ik9wdGlvbmFsIiwiMSBzdGFyIjoiMSBzdGFyIiwiMiBzdGFycyI6IjIgc3RhcnMiLCIzIHN0YXJzIjoiMyBzdGFycyIsIjQgc3RhcnMiOiI0IHN0YXJzIiwiNSBzdGFycyI6IjUgc3RhcnMifQ%3D%3D!"
        )
      ),
"en-HK": createLanguage(
        require.resolveWeak("./translations.json-en-HK-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJPcHRpb25hbCI6Ik9wdGlvbmFsIiwiMSBzdGFyIjoiMSBzdGFyIiwiMiBzdGFycyI6IjIgc3RhcnMiLCIzIHN0YXJzIjoiMyBzdGFycyIsIjQgc3RhcnMiOiI0IHN0YXJzIiwiNSBzdGFycyI6IjUgc3RhcnMifQ%3D%3D!"),
        () => import(
          /* webpackChunkName: "en-HK-translations" */
          "./translations.json-en-HK-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJPcHRpb25hbCI6Ik9wdGlvbmFsIiwiMSBzdGFyIjoiMSBzdGFyIiwiMiBzdGFycyI6IjIgc3RhcnMiLCIzIHN0YXJzIjoiMyBzdGFycyIsIjQgc3RhcnMiOiI0IHN0YXJzIiwiNSBzdGFycyI6IjUgc3RhcnMifQ%3D%3D!"
        )
      ),
"en-ID": createLanguage(
        require.resolveWeak("./translations.json-en-ID-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJPcHRpb25hbCI6Ik9wdGlvbmFsIiwiMSBzdGFyIjoiMSBzdGFyIiwiMiBzdGFycyI6IjIgc3RhcnMiLCIzIHN0YXJzIjoiMyBzdGFycyIsIjQgc3RhcnMiOiI0IHN0YXJzIiwiNSBzdGFycyI6IjUgc3RhcnMifQ%3D%3D!"),
        () => import(
          /* webpackChunkName: "en-ID-translations" */
          "./translations.json-en-ID-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJPcHRpb25hbCI6Ik9wdGlvbmFsIiwiMSBzdGFyIjoiMSBzdGFyIiwiMiBzdGFycyI6IjIgc3RhcnMiLCIzIHN0YXJzIjoiMyBzdGFycyIsIjQgc3RhcnMiOiI0IHN0YXJzIiwiNSBzdGFycyI6IjUgc3RhcnMifQ%3D%3D!"
        )
      ),
"en-MY": createLanguage(
        require.resolveWeak("./translations.json-en-MY-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJPcHRpb25hbCI6Ik9wdGlvbmFsIiwiMSBzdGFyIjoiMSBzdGFyIiwiMiBzdGFycyI6IjIgc3RhcnMiLCIzIHN0YXJzIjoiMyBzdGFycyIsIjQgc3RhcnMiOiI0IHN0YXJzIiwiNSBzdGFycyI6IjUgc3RhcnMifQ%3D%3D!"),
        () => import(
          /* webpackChunkName: "en-MY-translations" */
          "./translations.json-en-MY-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJPcHRpb25hbCI6Ik9wdGlvbmFsIiwiMSBzdGFyIjoiMSBzdGFyIiwiMiBzdGFycyI6IjIgc3RhcnMiLCIzIHN0YXJzIjoiMyBzdGFycyIsIjQgc3RhcnMiOiI0IHN0YXJzIiwiNSBzdGFycyI6IjUgc3RhcnMifQ%3D%3D!"
        )
      ),
"en-NZ": createLanguage(
        require.resolveWeak("./translations.json-en-NZ-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJPcHRpb25hbCI6Ik9wdGlvbmFsIiwiMSBzdGFyIjoiMSBzdGFyIiwiMiBzdGFycyI6IjIgc3RhcnMiLCIzIHN0YXJzIjoiMyBzdGFycyIsIjQgc3RhcnMiOiI0IHN0YXJzIiwiNSBzdGFycyI6IjUgc3RhcnMifQ%3D%3D!"),
        () => import(
          /* webpackChunkName: "en-NZ-translations" */
          "./translations.json-en-NZ-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJPcHRpb25hbCI6Ik9wdGlvbmFsIiwiMSBzdGFyIjoiMSBzdGFyIiwiMiBzdGFycyI6IjIgc3RhcnMiLCIzIHN0YXJzIjoiMyBzdGFycyIsIjQgc3RhcnMiOiI0IHN0YXJzIiwiNSBzdGFycyI6IjUgc3RhcnMifQ%3D%3D!"
        )
      ),
"en-PH": createLanguage(
        require.resolveWeak("./translations.json-en-PH-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJPcHRpb25hbCI6Ik9wdGlvbmFsIiwiMSBzdGFyIjoiMSBzdGFyIiwiMiBzdGFycyI6IjIgc3RhcnMiLCIzIHN0YXJzIjoiMyBzdGFycyIsIjQgc3RhcnMiOiI0IHN0YXJzIiwiNSBzdGFycyI6IjUgc3RhcnMifQ%3D%3D!"),
        () => import(
          /* webpackChunkName: "en-PH-translations" */
          "./translations.json-en-PH-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJPcHRpb25hbCI6Ik9wdGlvbmFsIiwiMSBzdGFyIjoiMSBzdGFyIiwiMiBzdGFycyI6IjIgc3RhcnMiLCIzIHN0YXJzIjoiMyBzdGFycyIsIjQgc3RhcnMiOiI0IHN0YXJzIiwiNSBzdGFycyI6IjUgc3RhcnMifQ%3D%3D!"
        )
      ),
"en-SG": createLanguage(
        require.resolveWeak("./translations.json-en-SG-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJPcHRpb25hbCI6Ik9wdGlvbmFsIiwiMSBzdGFyIjoiMSBzdGFyIiwiMiBzdGFycyI6IjIgc3RhcnMiLCIzIHN0YXJzIjoiMyBzdGFycyIsIjQgc3RhcnMiOiI0IHN0YXJzIiwiNSBzdGFycyI6IjUgc3RhcnMifQ%3D%3D!"),
        () => import(
          /* webpackChunkName: "en-SG-translations" */
          "./translations.json-en-SG-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJPcHRpb25hbCI6Ik9wdGlvbmFsIiwiMSBzdGFyIjoiMSBzdGFyIiwiMiBzdGFycyI6IjIgc3RhcnMiLCIzIHN0YXJzIjoiMyBzdGFycyIsIjQgc3RhcnMiOiI0IHN0YXJzIiwiNSBzdGFycyI6IjUgc3RhcnMifQ%3D%3D!"
        )
      ),
"en-TH": createLanguage(
        require.resolveWeak("./translations.json-en-TH-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJPcHRpb25hbCI6Ik9wdGlvbmFsIiwiMSBzdGFyIjoiMSBzdGFyIiwiMiBzdGFycyI6IjIgc3RhcnMiLCIzIHN0YXJzIjoiMyBzdGFycyIsIjQgc3RhcnMiOiI0IHN0YXJzIiwiNSBzdGFycyI6IjUgc3RhcnMifQ%3D%3D!"),
        () => import(
          /* webpackChunkName: "en-TH-translations" */
          "./translations.json-en-TH-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJPcHRpb25hbCI6Ik9wdGlvbmFsIiwiMSBzdGFyIjoiMSBzdGFyIiwiMiBzdGFycyI6IjIgc3RhcnMiLCIzIHN0YXJzIjoiMyBzdGFycyIsIjQgc3RhcnMiOiI0IHN0YXJzIiwiNSBzdGFycyI6IjUgc3RhcnMifQ%3D%3D!"
        )
      ),
"th-TH": createLanguage(
        require.resolveWeak("./translations.json-th-TH-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJPcHRpb25hbCI6IuC5hOC4oeC5iOC4iOC4s%2BC5gOC4m%2BC5h%2BC4meC4leC5ieC4reC4h%2BC4o%2BC4sOC4muC4uCIsIjEgc3RhciI6IjEg4LiU4Liy4LinIiwiMiBzdGFycyI6IjIg4LiU4Liy4LinIiwiMyBzdGFycyI6IjMg4LiU4Liy4LinIiwiNCBzdGFycyI6IjQg4LiU4Liy4LinIiwiNSBzdGFycyI6IjUg4LiU4Liy4LinIn0%3D!"),
        () => import(
          /* webpackChunkName: "th-TH-translations" */
          "./translations.json-th-TH-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJPcHRpb25hbCI6IuC5hOC4oeC5iOC4iOC4s%2BC5gOC4m%2BC5h%2BC4meC4leC5ieC4reC4h%2BC4o%2BC4sOC4muC4uCIsIjEgc3RhciI6IjEg4LiU4Liy4LinIiwiMiBzdGFycyI6IjIg4LiU4Liy4LinIiwiMyBzdGFycyI6IjMg4LiU4Liy4LinIiwiNCBzdGFycyI6IjQg4LiU4Liy4LinIiwiNSBzdGFycyI6IjUg4LiU4Liy4LinIn0%3D!"
        )
      ),
"id-ID": createLanguage(
        require.resolveWeak("./translations.json-id-ID-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJPcHRpb25hbCI6Ik9wc2lvbmFsIiwiMSBzdGFyIjoiMSBiaW50YW5nIiwiMiBzdGFycyI6IjIgYmludGFuZyIsIjMgc3RhcnMiOiIzIGJpbnRhbmciLCI0IHN0YXJzIjoiNCBiaW50YW5nIiwiNSBzdGFycyI6IjUgYmludGFuZyJ9!"),
        () => import(
          /* webpackChunkName: "id-ID-translations" */
          "./translations.json-id-ID-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJPcHRpb25hbCI6Ik9wc2lvbmFsIiwiMSBzdGFyIjoiMSBiaW50YW5nIiwiMiBzdGFycyI6IjIgYmludGFuZyIsIjMgc3RhcnMiOiIzIGJpbnRhbmciLCI0IHN0YXJzIjoiNCBiaW50YW5nIiwiNSBzdGFycyI6IjUgYmludGFuZyJ9!"
        )
      ),
"zh-HK": createLanguage(
        require.resolveWeak("./translations.json-zh-HK-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJPcHRpb25hbCI6Ik9wdGlvbmFsIiwiMSBzdGFyIjoiMSBzdGFyIiwiMiBzdGFycyI6IjIgc3RhcnMiLCIzIHN0YXJzIjoiMyBzdGFycyIsIjQgc3RhcnMiOiI0IHN0YXJzIiwiNSBzdGFycyI6IjUgc3RhcnMifQ%3D%3D!"),
        () => import(
          /* webpackChunkName: "zh-HK-translations" */
          "./translations.json-zh-HK-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJPcHRpb25hbCI6Ik9wdGlvbmFsIiwiMSBzdGFyIjoiMSBzdGFyIiwiMiBzdGFycyI6IjIgc3RhcnMiLCIzIHN0YXJzIjoiMyBzdGFycyIsIjQgc3RhcnMiOiI0IHN0YXJzIiwiNSBzdGFycyI6IjUgc3RhcnMifQ%3D%3D!"
        )
      ),
"en-PSEUDO": createLanguage(
        require.resolveWeak("./translations.json-en-PSEUDO-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJPcHRpb25hbCI6IlvDlsal4bmvw6zDrMOsw7bDtsO24LiB4Li14LmJxIPEg8SDxppdIiwiMSBzdGFyIjoiWzEgxaHhua%2FEg8SDxIPEg8WZXSIsIjIgc3RhcnMiOiJbMiDFoeG5r8SDxIPEg8SDxZnFoV0iLCIzIHN0YXJzIjoiWzMgxaHhua%2FEg8SDxIPEg8WZxaFdIiwiNCBzdGFycyI6Ils0IMWh4bmvxIPEg8SDxIPFmcWhXSIsIjUgc3RhcnMiOiJbNSDFoeG5r8SDxIPEg8SDxZnFoV0ifQ%3D%3D!"),
        () => import(
          /* webpackChunkName: "en-PSEUDO-translations" */
          "./translations.json-en-PSEUDO-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJPcHRpb25hbCI6IlvDlsal4bmvw6zDrMOsw7bDtsO24LiB4Li14LmJxIPEg8SDxppdIiwiMSBzdGFyIjoiWzEgxaHhua%2FEg8SDxIPEg8WZXSIsIjIgc3RhcnMiOiJbMiDFoeG5r8SDxIPEg8SDxZnFoV0iLCIzIHN0YXJzIjoiWzMgxaHhua%2FEg8SDxIPEg8WZxaFdIiwiNCBzdGFycyI6Ils0IMWh4bmvxIPEg8SDxIPFmcWhXSIsIjUgc3RhcnMiOiJbNSDFoeG5r8SDxIPEg8SDxZnFoV0ifQ%3D%3D!"
        )
      )
      });
  
      export { translations as default };
    