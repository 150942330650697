
      import { createLanguage, createTranslationFile } from '@vocab/webpack/web';
      
    const translations = createTranslationFile({
      "en": createLanguage(
        require.resolveWeak("./translations.json-en-virtual.json!=!../../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJTaG93aW5nIDxTdHJvbmc%2Be3RvdGFsfTwvU3Ryb25nPiByZXZpZXdzIHNvcnRlZCBieSI6IlNob3dpbmcgPFN0cm9uZz57dG90YWx9PC9TdHJvbmc%2BIHJldmlld3Mgc29ydGVkIGJ5IiwibW9zdCBoZWxwZnVsIjoibW9zdCBoZWxwZnVsIiwibW9zdCByZWNlbnQiOiJtb3N0IHJlY2VudCIsIlNvcnQgYnkiOiJTb3J0IGJ5IiwiU2hvd2luZyA8U3Ryb25nPnt0b3RhbH08L1N0cm9uZz4gcmV2aWV3cyBmb3Ige2xvY2F0aW9uX2NvbnRleHR1YWxfbmFtZX0iOiJTaG93aW5nIDxTdHJvbmc%2Be3RvdGFsfTwvU3Ryb25nPiByZXZpZXdzIGZvciB7bG9jYXRpb25fY29udGV4dHVhbF9uYW1lfSIsIlNlZSByZXZpZXdzIGZyb20gYWxsIGxvY2F0aW9ucyI6IlNlZSByZXZpZXdzIGZyb20gYWxsIGxvY2F0aW9ucyJ9!"),
        () => import(
          /* webpackChunkName: "en-translations" */
          "./translations.json-en-virtual.json!=!../../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJTaG93aW5nIDxTdHJvbmc%2Be3RvdGFsfTwvU3Ryb25nPiByZXZpZXdzIHNvcnRlZCBieSI6IlNob3dpbmcgPFN0cm9uZz57dG90YWx9PC9TdHJvbmc%2BIHJldmlld3Mgc29ydGVkIGJ5IiwibW9zdCBoZWxwZnVsIjoibW9zdCBoZWxwZnVsIiwibW9zdCByZWNlbnQiOiJtb3N0IHJlY2VudCIsIlNvcnQgYnkiOiJTb3J0IGJ5IiwiU2hvd2luZyA8U3Ryb25nPnt0b3RhbH08L1N0cm9uZz4gcmV2aWV3cyBmb3Ige2xvY2F0aW9uX2NvbnRleHR1YWxfbmFtZX0iOiJTaG93aW5nIDxTdHJvbmc%2Be3RvdGFsfTwvU3Ryb25nPiByZXZpZXdzIGZvciB7bG9jYXRpb25fY29udGV4dHVhbF9uYW1lfSIsIlNlZSByZXZpZXdzIGZyb20gYWxsIGxvY2F0aW9ucyI6IlNlZSByZXZpZXdzIGZyb20gYWxsIGxvY2F0aW9ucyJ9!"
        )
      ),
"th": createLanguage(
        require.resolveWeak("./translations.json-th-virtual.json!=!../../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJTaG93aW5nIDxTdHJvbmc%2Be3RvdGFsfTwvU3Ryb25nPiByZXZpZXdzIHNvcnRlZCBieSI6IuC5geC4quC4lOC4hyA8U3Ryb25nPnt0b3RhbH08L1N0cm9uZz4g4Lij4Li14Lin4Li04LinIOC5guC4lOC4ouC4iOC4seC4lOC5gOC4o%2BC4teC4ouC4h%2BC4leC4suC4oSIsIm1vc3QgaGVscGZ1bCI6IuC4oeC4teC4m%2BC4o%2BC4sOC5guC4ouC4iuC4meC5jOC4l%2BC4teC5iOC4quC4uOC4lCIsIm1vc3QgcmVjZW50Ijoi4Lil4LmI4Liy4Liq4Li44LiUIiwiU29ydCBieSI6IuC4iOC4seC4lOC5gOC4o%2BC4teC4ouC4h%2BC4leC4suC4oSIsIlNob3dpbmcgPFN0cm9uZz57dG90YWx9PC9TdHJvbmc%2BIHJldmlld3MgZm9yIHtsb2NhdGlvbl9jb250ZXh0dWFsX25hbWV9Ijoi4LiB4Liz4Lil4Lix4LiH4LmB4Liq4LiU4LiHIDxTdHJvbmc%2Be3RvdGFsfTwvU3Ryb25nPiDguKPguLXguKfguLTguKfguKrguLPguKvguKPguLHguJoge2xvY2F0aW9uX2NvbnRleHR1YWxfbmFtZX0iLCJTZWUgcmV2aWV3cyBmcm9tIGFsbCBsb2NhdGlvbnMiOiLguJTguLnguKPguLXguKfguLTguKfguIjguLLguIHguJfguLjguIHguKrguJbguLLguJnguJfguLXguYgifQ%3D%3D!"),
        () => import(
          /* webpackChunkName: "th-translations" */
          "./translations.json-th-virtual.json!=!../../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJTaG93aW5nIDxTdHJvbmc%2Be3RvdGFsfTwvU3Ryb25nPiByZXZpZXdzIHNvcnRlZCBieSI6IuC5geC4quC4lOC4hyA8U3Ryb25nPnt0b3RhbH08L1N0cm9uZz4g4Lij4Li14Lin4Li04LinIOC5guC4lOC4ouC4iOC4seC4lOC5gOC4o%2BC4teC4ouC4h%2BC4leC4suC4oSIsIm1vc3QgaGVscGZ1bCI6IuC4oeC4teC4m%2BC4o%2BC4sOC5guC4ouC4iuC4meC5jOC4l%2BC4teC5iOC4quC4uOC4lCIsIm1vc3QgcmVjZW50Ijoi4Lil4LmI4Liy4Liq4Li44LiUIiwiU29ydCBieSI6IuC4iOC4seC4lOC5gOC4o%2BC4teC4ouC4h%2BC4leC4suC4oSIsIlNob3dpbmcgPFN0cm9uZz57dG90YWx9PC9TdHJvbmc%2BIHJldmlld3MgZm9yIHtsb2NhdGlvbl9jb250ZXh0dWFsX25hbWV9Ijoi4LiB4Liz4Lil4Lix4LiH4LmB4Liq4LiU4LiHIDxTdHJvbmc%2Be3RvdGFsfTwvU3Ryb25nPiDguKPguLXguKfguLTguKfguKrguLPguKvguKPguLHguJoge2xvY2F0aW9uX2NvbnRleHR1YWxfbmFtZX0iLCJTZWUgcmV2aWV3cyBmcm9tIGFsbCBsb2NhdGlvbnMiOiLguJTguLnguKPguLXguKfguLTguKfguIjguLLguIHguJfguLjguIHguKrguJbguLLguJnguJfguLXguYgifQ%3D%3D!"
        )
      ),
"id": createLanguage(
        require.resolveWeak("./translations.json-id-virtual.json!=!../../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJTaG93aW5nIDxTdHJvbmc%2Be3RvdGFsfTwvU3Ryb25nPiByZXZpZXdzIHNvcnRlZCBieSI6Ik1lbmFtcGlsa2FuIDxTdHJvbmc%2Be3RvdGFsfTwvU3Ryb25nPiB1bGFzYW4sIGRpdXJ1dGthbiBkYXJpIHlhbmciLCJtb3N0IGhlbHBmdWwiOiJQYWxpbmcgbWVtYmFudHUiLCJtb3N0IHJlY2VudCI6IlRlcmJhcnUiLCJTb3J0IGJ5IjoiVXJ1dGthbiBiZXJkYXNhcmthbiIsIlNob3dpbmcgPFN0cm9uZz57dG90YWx9PC9TdHJvbmc%2BIHJldmlld3MgZm9yIHtsb2NhdGlvbl9jb250ZXh0dWFsX25hbWV9IjoiTWVuYW1waWxrYW4gPFN0cm9uZz57dG90YWx9PC9TdHJvbmc%2BIHVsYXNhbiB1bnR1ayB7bG9jYXRpb25fY29udGV4dHVhbF9uYW1lfSIsIlNlZSByZXZpZXdzIGZyb20gYWxsIGxvY2F0aW9ucyI6IkxpaGF0IHVsYXNhbiBkYXJpIHNlbXVhIGxva2FzaSJ9!"),
        () => import(
          /* webpackChunkName: "id-translations" */
          "./translations.json-id-virtual.json!=!../../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJTaG93aW5nIDxTdHJvbmc%2Be3RvdGFsfTwvU3Ryb25nPiByZXZpZXdzIHNvcnRlZCBieSI6Ik1lbmFtcGlsa2FuIDxTdHJvbmc%2Be3RvdGFsfTwvU3Ryb25nPiB1bGFzYW4sIGRpdXJ1dGthbiBkYXJpIHlhbmciLCJtb3N0IGhlbHBmdWwiOiJQYWxpbmcgbWVtYmFudHUiLCJtb3N0IHJlY2VudCI6IlRlcmJhcnUiLCJTb3J0IGJ5IjoiVXJ1dGthbiBiZXJkYXNhcmthbiIsIlNob3dpbmcgPFN0cm9uZz57dG90YWx9PC9TdHJvbmc%2BIHJldmlld3MgZm9yIHtsb2NhdGlvbl9jb250ZXh0dWFsX25hbWV9IjoiTWVuYW1waWxrYW4gPFN0cm9uZz57dG90YWx9PC9TdHJvbmc%2BIHVsYXNhbiB1bnR1ayB7bG9jYXRpb25fY29udGV4dHVhbF9uYW1lfSIsIlNlZSByZXZpZXdzIGZyb20gYWxsIGxvY2F0aW9ucyI6IkxpaGF0IHVsYXNhbiBkYXJpIHNlbXVhIGxva2FzaSJ9!"
        )
      ),
"zh": createLanguage(
        require.resolveWeak("./translations.json-zh-virtual.json!=!../../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJTaG93aW5nIDxTdHJvbmc%2Be3RvdGFsfTwvU3Ryb25nPiByZXZpZXdzIHNvcnRlZCBieSI6IlNob3dpbmcgPFN0cm9uZz57dG90YWx9PC9TdHJvbmc%2BIHJldmlld3Mgc29ydGVkIGJ5IiwibW9zdCBoZWxwZnVsIjoibW9zdCBoZWxwZnVsIiwibW9zdCByZWNlbnQiOiJtb3N0IHJlY2VudCIsIlNvcnQgYnkiOiJTb3J0IGJ5IiwiU2hvd2luZyA8U3Ryb25nPnt0b3RhbH08L1N0cm9uZz4gcmV2aWV3cyBmb3Ige2xvY2F0aW9uX2NvbnRleHR1YWxfbmFtZX0iOiJTaG93aW5nIDxTdHJvbmc%2Be3RvdGFsfTwvU3Ryb25nPiByZXZpZXdzIGZvciB7bG9jYXRpb25fY29udGV4dHVhbF9uYW1lfSIsIlNlZSByZXZpZXdzIGZyb20gYWxsIGxvY2F0aW9ucyI6IlNlZSByZXZpZXdzIGZyb20gYWxsIGxvY2F0aW9ucyJ9!"),
        () => import(
          /* webpackChunkName: "zh-translations" */
          "./translations.json-zh-virtual.json!=!../../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJTaG93aW5nIDxTdHJvbmc%2Be3RvdGFsfTwvU3Ryb25nPiByZXZpZXdzIHNvcnRlZCBieSI6IlNob3dpbmcgPFN0cm9uZz57dG90YWx9PC9TdHJvbmc%2BIHJldmlld3Mgc29ydGVkIGJ5IiwibW9zdCBoZWxwZnVsIjoibW9zdCBoZWxwZnVsIiwibW9zdCByZWNlbnQiOiJtb3N0IHJlY2VudCIsIlNvcnQgYnkiOiJTb3J0IGJ5IiwiU2hvd2luZyA8U3Ryb25nPnt0b3RhbH08L1N0cm9uZz4gcmV2aWV3cyBmb3Ige2xvY2F0aW9uX2NvbnRleHR1YWxfbmFtZX0iOiJTaG93aW5nIDxTdHJvbmc%2Be3RvdGFsfTwvU3Ryb25nPiByZXZpZXdzIGZvciB7bG9jYXRpb25fY29udGV4dHVhbF9uYW1lfSIsIlNlZSByZXZpZXdzIGZyb20gYWxsIGxvY2F0aW9ucyI6IlNlZSByZXZpZXdzIGZyb20gYWxsIGxvY2F0aW9ucyJ9!"
        )
      ),
"en-AU": createLanguage(
        require.resolveWeak("./translations.json-en-AU-virtual.json!=!../../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJTaG93aW5nIDxTdHJvbmc%2Be3RvdGFsfTwvU3Ryb25nPiByZXZpZXdzIHNvcnRlZCBieSI6IlNob3dpbmcgPFN0cm9uZz57dG90YWx9PC9TdHJvbmc%2BIHJldmlld3Mgc29ydGVkIGJ5IiwibW9zdCBoZWxwZnVsIjoibW9zdCBoZWxwZnVsIiwibW9zdCByZWNlbnQiOiJtb3N0IHJlY2VudCIsIlNvcnQgYnkiOiJTb3J0IGJ5IiwiU2hvd2luZyA8U3Ryb25nPnt0b3RhbH08L1N0cm9uZz4gcmV2aWV3cyBmb3Ige2xvY2F0aW9uX2NvbnRleHR1YWxfbmFtZX0iOiJTaG93aW5nIDxTdHJvbmc%2Be3RvdGFsfTwvU3Ryb25nPiByZXZpZXdzIGZvciB7bG9jYXRpb25fY29udGV4dHVhbF9uYW1lfSIsIlNlZSByZXZpZXdzIGZyb20gYWxsIGxvY2F0aW9ucyI6IlNlZSByZXZpZXdzIGZyb20gYWxsIGxvY2F0aW9ucyJ9!"),
        () => import(
          /* webpackChunkName: "en-AU-translations" */
          "./translations.json-en-AU-virtual.json!=!../../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJTaG93aW5nIDxTdHJvbmc%2Be3RvdGFsfTwvU3Ryb25nPiByZXZpZXdzIHNvcnRlZCBieSI6IlNob3dpbmcgPFN0cm9uZz57dG90YWx9PC9TdHJvbmc%2BIHJldmlld3Mgc29ydGVkIGJ5IiwibW9zdCBoZWxwZnVsIjoibW9zdCBoZWxwZnVsIiwibW9zdCByZWNlbnQiOiJtb3N0IHJlY2VudCIsIlNvcnQgYnkiOiJTb3J0IGJ5IiwiU2hvd2luZyA8U3Ryb25nPnt0b3RhbH08L1N0cm9uZz4gcmV2aWV3cyBmb3Ige2xvY2F0aW9uX2NvbnRleHR1YWxfbmFtZX0iOiJTaG93aW5nIDxTdHJvbmc%2Be3RvdGFsfTwvU3Ryb25nPiByZXZpZXdzIGZvciB7bG9jYXRpb25fY29udGV4dHVhbF9uYW1lfSIsIlNlZSByZXZpZXdzIGZyb20gYWxsIGxvY2F0aW9ucyI6IlNlZSByZXZpZXdzIGZyb20gYWxsIGxvY2F0aW9ucyJ9!"
        )
      ),
"en-HK": createLanguage(
        require.resolveWeak("./translations.json-en-HK-virtual.json!=!../../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJTaG93aW5nIDxTdHJvbmc%2Be3RvdGFsfTwvU3Ryb25nPiByZXZpZXdzIHNvcnRlZCBieSI6IlNob3dpbmcgPFN0cm9uZz57dG90YWx9PC9TdHJvbmc%2BIHJldmlld3Mgc29ydGVkIGJ5IiwibW9zdCBoZWxwZnVsIjoibW9zdCBoZWxwZnVsIiwibW9zdCByZWNlbnQiOiJtb3N0IHJlY2VudCIsIlNvcnQgYnkiOiJTb3J0IGJ5IiwiU2hvd2luZyA8U3Ryb25nPnt0b3RhbH08L1N0cm9uZz4gcmV2aWV3cyBmb3Ige2xvY2F0aW9uX2NvbnRleHR1YWxfbmFtZX0iOiJTaG93aW5nIDxTdHJvbmc%2Be3RvdGFsfTwvU3Ryb25nPiByZXZpZXdzIGZvciB7bG9jYXRpb25fY29udGV4dHVhbF9uYW1lfSIsIlNlZSByZXZpZXdzIGZyb20gYWxsIGxvY2F0aW9ucyI6IlNlZSByZXZpZXdzIGZyb20gYWxsIGxvY2F0aW9ucyJ9!"),
        () => import(
          /* webpackChunkName: "en-HK-translations" */
          "./translations.json-en-HK-virtual.json!=!../../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJTaG93aW5nIDxTdHJvbmc%2Be3RvdGFsfTwvU3Ryb25nPiByZXZpZXdzIHNvcnRlZCBieSI6IlNob3dpbmcgPFN0cm9uZz57dG90YWx9PC9TdHJvbmc%2BIHJldmlld3Mgc29ydGVkIGJ5IiwibW9zdCBoZWxwZnVsIjoibW9zdCBoZWxwZnVsIiwibW9zdCByZWNlbnQiOiJtb3N0IHJlY2VudCIsIlNvcnQgYnkiOiJTb3J0IGJ5IiwiU2hvd2luZyA8U3Ryb25nPnt0b3RhbH08L1N0cm9uZz4gcmV2aWV3cyBmb3Ige2xvY2F0aW9uX2NvbnRleHR1YWxfbmFtZX0iOiJTaG93aW5nIDxTdHJvbmc%2Be3RvdGFsfTwvU3Ryb25nPiByZXZpZXdzIGZvciB7bG9jYXRpb25fY29udGV4dHVhbF9uYW1lfSIsIlNlZSByZXZpZXdzIGZyb20gYWxsIGxvY2F0aW9ucyI6IlNlZSByZXZpZXdzIGZyb20gYWxsIGxvY2F0aW9ucyJ9!"
        )
      ),
"en-ID": createLanguage(
        require.resolveWeak("./translations.json-en-ID-virtual.json!=!../../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJTaG93aW5nIDxTdHJvbmc%2Be3RvdGFsfTwvU3Ryb25nPiByZXZpZXdzIHNvcnRlZCBieSI6IlNob3dpbmcgPFN0cm9uZz57dG90YWx9PC9TdHJvbmc%2BIHJldmlld3Mgc29ydGVkIGJ5IiwibW9zdCBoZWxwZnVsIjoibW9zdCBoZWxwZnVsIiwibW9zdCByZWNlbnQiOiJtb3N0IHJlY2VudCIsIlNvcnQgYnkiOiJTb3J0IGJ5IiwiU2hvd2luZyA8U3Ryb25nPnt0b3RhbH08L1N0cm9uZz4gcmV2aWV3cyBmb3Ige2xvY2F0aW9uX2NvbnRleHR1YWxfbmFtZX0iOiJTaG93aW5nIDxTdHJvbmc%2Be3RvdGFsfTwvU3Ryb25nPiByZXZpZXdzIGZvciB7bG9jYXRpb25fY29udGV4dHVhbF9uYW1lfSIsIlNlZSByZXZpZXdzIGZyb20gYWxsIGxvY2F0aW9ucyI6IlNlZSByZXZpZXdzIGZyb20gYWxsIGxvY2F0aW9ucyJ9!"),
        () => import(
          /* webpackChunkName: "en-ID-translations" */
          "./translations.json-en-ID-virtual.json!=!../../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJTaG93aW5nIDxTdHJvbmc%2Be3RvdGFsfTwvU3Ryb25nPiByZXZpZXdzIHNvcnRlZCBieSI6IlNob3dpbmcgPFN0cm9uZz57dG90YWx9PC9TdHJvbmc%2BIHJldmlld3Mgc29ydGVkIGJ5IiwibW9zdCBoZWxwZnVsIjoibW9zdCBoZWxwZnVsIiwibW9zdCByZWNlbnQiOiJtb3N0IHJlY2VudCIsIlNvcnQgYnkiOiJTb3J0IGJ5IiwiU2hvd2luZyA8U3Ryb25nPnt0b3RhbH08L1N0cm9uZz4gcmV2aWV3cyBmb3Ige2xvY2F0aW9uX2NvbnRleHR1YWxfbmFtZX0iOiJTaG93aW5nIDxTdHJvbmc%2Be3RvdGFsfTwvU3Ryb25nPiByZXZpZXdzIGZvciB7bG9jYXRpb25fY29udGV4dHVhbF9uYW1lfSIsIlNlZSByZXZpZXdzIGZyb20gYWxsIGxvY2F0aW9ucyI6IlNlZSByZXZpZXdzIGZyb20gYWxsIGxvY2F0aW9ucyJ9!"
        )
      ),
"en-MY": createLanguage(
        require.resolveWeak("./translations.json-en-MY-virtual.json!=!../../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJTaG93aW5nIDxTdHJvbmc%2Be3RvdGFsfTwvU3Ryb25nPiByZXZpZXdzIHNvcnRlZCBieSI6IlNob3dpbmcgPFN0cm9uZz57dG90YWx9PC9TdHJvbmc%2BIHJldmlld3Mgc29ydGVkIGJ5IiwibW9zdCBoZWxwZnVsIjoibW9zdCBoZWxwZnVsIiwibW9zdCByZWNlbnQiOiJtb3N0IHJlY2VudCIsIlNvcnQgYnkiOiJTb3J0IGJ5IiwiU2hvd2luZyA8U3Ryb25nPnt0b3RhbH08L1N0cm9uZz4gcmV2aWV3cyBmb3Ige2xvY2F0aW9uX2NvbnRleHR1YWxfbmFtZX0iOiJTaG93aW5nIDxTdHJvbmc%2Be3RvdGFsfTwvU3Ryb25nPiByZXZpZXdzIGZvciB7bG9jYXRpb25fY29udGV4dHVhbF9uYW1lfSIsIlNlZSByZXZpZXdzIGZyb20gYWxsIGxvY2F0aW9ucyI6IlNlZSByZXZpZXdzIGZyb20gYWxsIGxvY2F0aW9ucyJ9!"),
        () => import(
          /* webpackChunkName: "en-MY-translations" */
          "./translations.json-en-MY-virtual.json!=!../../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJTaG93aW5nIDxTdHJvbmc%2Be3RvdGFsfTwvU3Ryb25nPiByZXZpZXdzIHNvcnRlZCBieSI6IlNob3dpbmcgPFN0cm9uZz57dG90YWx9PC9TdHJvbmc%2BIHJldmlld3Mgc29ydGVkIGJ5IiwibW9zdCBoZWxwZnVsIjoibW9zdCBoZWxwZnVsIiwibW9zdCByZWNlbnQiOiJtb3N0IHJlY2VudCIsIlNvcnQgYnkiOiJTb3J0IGJ5IiwiU2hvd2luZyA8U3Ryb25nPnt0b3RhbH08L1N0cm9uZz4gcmV2aWV3cyBmb3Ige2xvY2F0aW9uX2NvbnRleHR1YWxfbmFtZX0iOiJTaG93aW5nIDxTdHJvbmc%2Be3RvdGFsfTwvU3Ryb25nPiByZXZpZXdzIGZvciB7bG9jYXRpb25fY29udGV4dHVhbF9uYW1lfSIsIlNlZSByZXZpZXdzIGZyb20gYWxsIGxvY2F0aW9ucyI6IlNlZSByZXZpZXdzIGZyb20gYWxsIGxvY2F0aW9ucyJ9!"
        )
      ),
"en-NZ": createLanguage(
        require.resolveWeak("./translations.json-en-NZ-virtual.json!=!../../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJTaG93aW5nIDxTdHJvbmc%2Be3RvdGFsfTwvU3Ryb25nPiByZXZpZXdzIHNvcnRlZCBieSI6IlNob3dpbmcgPFN0cm9uZz57dG90YWx9PC9TdHJvbmc%2BIHJldmlld3Mgc29ydGVkIGJ5IiwibW9zdCBoZWxwZnVsIjoibW9zdCBoZWxwZnVsIiwibW9zdCByZWNlbnQiOiJtb3N0IHJlY2VudCIsIlNvcnQgYnkiOiJTb3J0IGJ5IiwiU2hvd2luZyA8U3Ryb25nPnt0b3RhbH08L1N0cm9uZz4gcmV2aWV3cyBmb3Ige2xvY2F0aW9uX2NvbnRleHR1YWxfbmFtZX0iOiJTaG93aW5nIDxTdHJvbmc%2Be3RvdGFsfTwvU3Ryb25nPiByZXZpZXdzIGZvciB7bG9jYXRpb25fY29udGV4dHVhbF9uYW1lfSIsIlNlZSByZXZpZXdzIGZyb20gYWxsIGxvY2F0aW9ucyI6IlNlZSByZXZpZXdzIGZyb20gYWxsIGxvY2F0aW9ucyJ9!"),
        () => import(
          /* webpackChunkName: "en-NZ-translations" */
          "./translations.json-en-NZ-virtual.json!=!../../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJTaG93aW5nIDxTdHJvbmc%2Be3RvdGFsfTwvU3Ryb25nPiByZXZpZXdzIHNvcnRlZCBieSI6IlNob3dpbmcgPFN0cm9uZz57dG90YWx9PC9TdHJvbmc%2BIHJldmlld3Mgc29ydGVkIGJ5IiwibW9zdCBoZWxwZnVsIjoibW9zdCBoZWxwZnVsIiwibW9zdCByZWNlbnQiOiJtb3N0IHJlY2VudCIsIlNvcnQgYnkiOiJTb3J0IGJ5IiwiU2hvd2luZyA8U3Ryb25nPnt0b3RhbH08L1N0cm9uZz4gcmV2aWV3cyBmb3Ige2xvY2F0aW9uX2NvbnRleHR1YWxfbmFtZX0iOiJTaG93aW5nIDxTdHJvbmc%2Be3RvdGFsfTwvU3Ryb25nPiByZXZpZXdzIGZvciB7bG9jYXRpb25fY29udGV4dHVhbF9uYW1lfSIsIlNlZSByZXZpZXdzIGZyb20gYWxsIGxvY2F0aW9ucyI6IlNlZSByZXZpZXdzIGZyb20gYWxsIGxvY2F0aW9ucyJ9!"
        )
      ),
"en-PH": createLanguage(
        require.resolveWeak("./translations.json-en-PH-virtual.json!=!../../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJTaG93aW5nIDxTdHJvbmc%2Be3RvdGFsfTwvU3Ryb25nPiByZXZpZXdzIHNvcnRlZCBieSI6IlNob3dpbmcgPFN0cm9uZz57dG90YWx9PC9TdHJvbmc%2BIHJldmlld3Mgc29ydGVkIGJ5IiwibW9zdCBoZWxwZnVsIjoibW9zdCBoZWxwZnVsIiwibW9zdCByZWNlbnQiOiJtb3N0IHJlY2VudCIsIlNvcnQgYnkiOiJTb3J0IGJ5IiwiU2hvd2luZyA8U3Ryb25nPnt0b3RhbH08L1N0cm9uZz4gcmV2aWV3cyBmb3Ige2xvY2F0aW9uX2NvbnRleHR1YWxfbmFtZX0iOiJTaG93aW5nIDxTdHJvbmc%2Be3RvdGFsfTwvU3Ryb25nPiByZXZpZXdzIGZvciB7bG9jYXRpb25fY29udGV4dHVhbF9uYW1lfSIsIlNlZSByZXZpZXdzIGZyb20gYWxsIGxvY2F0aW9ucyI6IlNlZSByZXZpZXdzIGZyb20gYWxsIGxvY2F0aW9ucyJ9!"),
        () => import(
          /* webpackChunkName: "en-PH-translations" */
          "./translations.json-en-PH-virtual.json!=!../../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJTaG93aW5nIDxTdHJvbmc%2Be3RvdGFsfTwvU3Ryb25nPiByZXZpZXdzIHNvcnRlZCBieSI6IlNob3dpbmcgPFN0cm9uZz57dG90YWx9PC9TdHJvbmc%2BIHJldmlld3Mgc29ydGVkIGJ5IiwibW9zdCBoZWxwZnVsIjoibW9zdCBoZWxwZnVsIiwibW9zdCByZWNlbnQiOiJtb3N0IHJlY2VudCIsIlNvcnQgYnkiOiJTb3J0IGJ5IiwiU2hvd2luZyA8U3Ryb25nPnt0b3RhbH08L1N0cm9uZz4gcmV2aWV3cyBmb3Ige2xvY2F0aW9uX2NvbnRleHR1YWxfbmFtZX0iOiJTaG93aW5nIDxTdHJvbmc%2Be3RvdGFsfTwvU3Ryb25nPiByZXZpZXdzIGZvciB7bG9jYXRpb25fY29udGV4dHVhbF9uYW1lfSIsIlNlZSByZXZpZXdzIGZyb20gYWxsIGxvY2F0aW9ucyI6IlNlZSByZXZpZXdzIGZyb20gYWxsIGxvY2F0aW9ucyJ9!"
        )
      ),
"en-SG": createLanguage(
        require.resolveWeak("./translations.json-en-SG-virtual.json!=!../../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJTaG93aW5nIDxTdHJvbmc%2Be3RvdGFsfTwvU3Ryb25nPiByZXZpZXdzIHNvcnRlZCBieSI6IlNob3dpbmcgPFN0cm9uZz57dG90YWx9PC9TdHJvbmc%2BIHJldmlld3Mgc29ydGVkIGJ5IiwibW9zdCBoZWxwZnVsIjoibW9zdCBoZWxwZnVsIiwibW9zdCByZWNlbnQiOiJtb3N0IHJlY2VudCIsIlNvcnQgYnkiOiJTb3J0IGJ5IiwiU2hvd2luZyA8U3Ryb25nPnt0b3RhbH08L1N0cm9uZz4gcmV2aWV3cyBmb3Ige2xvY2F0aW9uX2NvbnRleHR1YWxfbmFtZX0iOiJTaG93aW5nIDxTdHJvbmc%2Be3RvdGFsfTwvU3Ryb25nPiByZXZpZXdzIGZvciB7bG9jYXRpb25fY29udGV4dHVhbF9uYW1lfSIsIlNlZSByZXZpZXdzIGZyb20gYWxsIGxvY2F0aW9ucyI6IlNlZSByZXZpZXdzIGZyb20gYWxsIGxvY2F0aW9ucyJ9!"),
        () => import(
          /* webpackChunkName: "en-SG-translations" */
          "./translations.json-en-SG-virtual.json!=!../../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJTaG93aW5nIDxTdHJvbmc%2Be3RvdGFsfTwvU3Ryb25nPiByZXZpZXdzIHNvcnRlZCBieSI6IlNob3dpbmcgPFN0cm9uZz57dG90YWx9PC9TdHJvbmc%2BIHJldmlld3Mgc29ydGVkIGJ5IiwibW9zdCBoZWxwZnVsIjoibW9zdCBoZWxwZnVsIiwibW9zdCByZWNlbnQiOiJtb3N0IHJlY2VudCIsIlNvcnQgYnkiOiJTb3J0IGJ5IiwiU2hvd2luZyA8U3Ryb25nPnt0b3RhbH08L1N0cm9uZz4gcmV2aWV3cyBmb3Ige2xvY2F0aW9uX2NvbnRleHR1YWxfbmFtZX0iOiJTaG93aW5nIDxTdHJvbmc%2Be3RvdGFsfTwvU3Ryb25nPiByZXZpZXdzIGZvciB7bG9jYXRpb25fY29udGV4dHVhbF9uYW1lfSIsIlNlZSByZXZpZXdzIGZyb20gYWxsIGxvY2F0aW9ucyI6IlNlZSByZXZpZXdzIGZyb20gYWxsIGxvY2F0aW9ucyJ9!"
        )
      ),
"en-TH": createLanguage(
        require.resolveWeak("./translations.json-en-TH-virtual.json!=!../../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJTaG93aW5nIDxTdHJvbmc%2Be3RvdGFsfTwvU3Ryb25nPiByZXZpZXdzIHNvcnRlZCBieSI6IlNob3dpbmcgPFN0cm9uZz57dG90YWx9PC9TdHJvbmc%2BIHJldmlld3Mgc29ydGVkIGJ5IiwibW9zdCBoZWxwZnVsIjoibW9zdCBoZWxwZnVsIiwibW9zdCByZWNlbnQiOiJtb3N0IHJlY2VudCIsIlNvcnQgYnkiOiJTb3J0IGJ5IiwiU2hvd2luZyA8U3Ryb25nPnt0b3RhbH08L1N0cm9uZz4gcmV2aWV3cyBmb3Ige2xvY2F0aW9uX2NvbnRleHR1YWxfbmFtZX0iOiJTaG93aW5nIDxTdHJvbmc%2Be3RvdGFsfTwvU3Ryb25nPiByZXZpZXdzIGZvciB7bG9jYXRpb25fY29udGV4dHVhbF9uYW1lfSIsIlNlZSByZXZpZXdzIGZyb20gYWxsIGxvY2F0aW9ucyI6IlNlZSByZXZpZXdzIGZyb20gYWxsIGxvY2F0aW9ucyJ9!"),
        () => import(
          /* webpackChunkName: "en-TH-translations" */
          "./translations.json-en-TH-virtual.json!=!../../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJTaG93aW5nIDxTdHJvbmc%2Be3RvdGFsfTwvU3Ryb25nPiByZXZpZXdzIHNvcnRlZCBieSI6IlNob3dpbmcgPFN0cm9uZz57dG90YWx9PC9TdHJvbmc%2BIHJldmlld3Mgc29ydGVkIGJ5IiwibW9zdCBoZWxwZnVsIjoibW9zdCBoZWxwZnVsIiwibW9zdCByZWNlbnQiOiJtb3N0IHJlY2VudCIsIlNvcnQgYnkiOiJTb3J0IGJ5IiwiU2hvd2luZyA8U3Ryb25nPnt0b3RhbH08L1N0cm9uZz4gcmV2aWV3cyBmb3Ige2xvY2F0aW9uX2NvbnRleHR1YWxfbmFtZX0iOiJTaG93aW5nIDxTdHJvbmc%2Be3RvdGFsfTwvU3Ryb25nPiByZXZpZXdzIGZvciB7bG9jYXRpb25fY29udGV4dHVhbF9uYW1lfSIsIlNlZSByZXZpZXdzIGZyb20gYWxsIGxvY2F0aW9ucyI6IlNlZSByZXZpZXdzIGZyb20gYWxsIGxvY2F0aW9ucyJ9!"
        )
      ),
"th-TH": createLanguage(
        require.resolveWeak("./translations.json-th-TH-virtual.json!=!../../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJTaG93aW5nIDxTdHJvbmc%2Be3RvdGFsfTwvU3Ryb25nPiByZXZpZXdzIHNvcnRlZCBieSI6IuC5geC4quC4lOC4hyA8U3Ryb25nPnt0b3RhbH08L1N0cm9uZz4g4Lij4Li14Lin4Li04LinIOC5guC4lOC4ouC4iOC4seC4lOC5gOC4o%2BC4teC4ouC4h%2BC4leC4suC4oSIsIm1vc3QgaGVscGZ1bCI6IuC4oeC4teC4m%2BC4o%2BC4sOC5guC4ouC4iuC4meC5jOC4l%2BC4teC5iOC4quC4uOC4lCIsIm1vc3QgcmVjZW50Ijoi4Lil4LmI4Liy4Liq4Li44LiUIiwiU29ydCBieSI6IuC4iOC4seC4lOC5gOC4o%2BC4teC4ouC4h%2BC4leC4suC4oSIsIlNob3dpbmcgPFN0cm9uZz57dG90YWx9PC9TdHJvbmc%2BIHJldmlld3MgZm9yIHtsb2NhdGlvbl9jb250ZXh0dWFsX25hbWV9Ijoi4LiB4Liz4Lil4Lix4LiH4LmB4Liq4LiU4LiHIDxTdHJvbmc%2Be3RvdGFsfTwvU3Ryb25nPiDguKPguLXguKfguLTguKfguKrguLPguKvguKPguLHguJoge2xvY2F0aW9uX2NvbnRleHR1YWxfbmFtZX0iLCJTZWUgcmV2aWV3cyBmcm9tIGFsbCBsb2NhdGlvbnMiOiLguJTguLnguKPguLXguKfguLTguKfguIjguLLguIHguJfguLjguIHguKrguJbguLLguJnguJfguLXguYgifQ%3D%3D!"),
        () => import(
          /* webpackChunkName: "th-TH-translations" */
          "./translations.json-th-TH-virtual.json!=!../../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJTaG93aW5nIDxTdHJvbmc%2Be3RvdGFsfTwvU3Ryb25nPiByZXZpZXdzIHNvcnRlZCBieSI6IuC5geC4quC4lOC4hyA8U3Ryb25nPnt0b3RhbH08L1N0cm9uZz4g4Lij4Li14Lin4Li04LinIOC5guC4lOC4ouC4iOC4seC4lOC5gOC4o%2BC4teC4ouC4h%2BC4leC4suC4oSIsIm1vc3QgaGVscGZ1bCI6IuC4oeC4teC4m%2BC4o%2BC4sOC5guC4ouC4iuC4meC5jOC4l%2BC4teC5iOC4quC4uOC4lCIsIm1vc3QgcmVjZW50Ijoi4Lil4LmI4Liy4Liq4Li44LiUIiwiU29ydCBieSI6IuC4iOC4seC4lOC5gOC4o%2BC4teC4ouC4h%2BC4leC4suC4oSIsIlNob3dpbmcgPFN0cm9uZz57dG90YWx9PC9TdHJvbmc%2BIHJldmlld3MgZm9yIHtsb2NhdGlvbl9jb250ZXh0dWFsX25hbWV9Ijoi4LiB4Liz4Lil4Lix4LiH4LmB4Liq4LiU4LiHIDxTdHJvbmc%2Be3RvdGFsfTwvU3Ryb25nPiDguKPguLXguKfguLTguKfguKrguLPguKvguKPguLHguJoge2xvY2F0aW9uX2NvbnRleHR1YWxfbmFtZX0iLCJTZWUgcmV2aWV3cyBmcm9tIGFsbCBsb2NhdGlvbnMiOiLguJTguLnguKPguLXguKfguLTguKfguIjguLLguIHguJfguLjguIHguKrguJbguLLguJnguJfguLXguYgifQ%3D%3D!"
        )
      ),
"id-ID": createLanguage(
        require.resolveWeak("./translations.json-id-ID-virtual.json!=!../../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJTaG93aW5nIDxTdHJvbmc%2Be3RvdGFsfTwvU3Ryb25nPiByZXZpZXdzIHNvcnRlZCBieSI6Ik1lbmFtcGlsa2FuIDxTdHJvbmc%2Be3RvdGFsfTwvU3Ryb25nPiB1bGFzYW4sIGRpdXJ1dGthbiBkYXJpIHlhbmciLCJtb3N0IGhlbHBmdWwiOiJQYWxpbmcgbWVtYmFudHUiLCJtb3N0IHJlY2VudCI6IlRlcmJhcnUiLCJTb3J0IGJ5IjoiVXJ1dGthbiBiZXJkYXNhcmthbiIsIlNob3dpbmcgPFN0cm9uZz57dG90YWx9PC9TdHJvbmc%2BIHJldmlld3MgZm9yIHtsb2NhdGlvbl9jb250ZXh0dWFsX25hbWV9IjoiTWVuYW1waWxrYW4gPFN0cm9uZz57dG90YWx9PC9TdHJvbmc%2BIHVsYXNhbiB1bnR1ayB7bG9jYXRpb25fY29udGV4dHVhbF9uYW1lfSIsIlNlZSByZXZpZXdzIGZyb20gYWxsIGxvY2F0aW9ucyI6IkxpaGF0IHVsYXNhbiBkYXJpIHNlbXVhIGxva2FzaSJ9!"),
        () => import(
          /* webpackChunkName: "id-ID-translations" */
          "./translations.json-id-ID-virtual.json!=!../../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJTaG93aW5nIDxTdHJvbmc%2Be3RvdGFsfTwvU3Ryb25nPiByZXZpZXdzIHNvcnRlZCBieSI6Ik1lbmFtcGlsa2FuIDxTdHJvbmc%2Be3RvdGFsfTwvU3Ryb25nPiB1bGFzYW4sIGRpdXJ1dGthbiBkYXJpIHlhbmciLCJtb3N0IGhlbHBmdWwiOiJQYWxpbmcgbWVtYmFudHUiLCJtb3N0IHJlY2VudCI6IlRlcmJhcnUiLCJTb3J0IGJ5IjoiVXJ1dGthbiBiZXJkYXNhcmthbiIsIlNob3dpbmcgPFN0cm9uZz57dG90YWx9PC9TdHJvbmc%2BIHJldmlld3MgZm9yIHtsb2NhdGlvbl9jb250ZXh0dWFsX25hbWV9IjoiTWVuYW1waWxrYW4gPFN0cm9uZz57dG90YWx9PC9TdHJvbmc%2BIHVsYXNhbiB1bnR1ayB7bG9jYXRpb25fY29udGV4dHVhbF9uYW1lfSIsIlNlZSByZXZpZXdzIGZyb20gYWxsIGxvY2F0aW9ucyI6IkxpaGF0IHVsYXNhbiBkYXJpIHNlbXVhIGxva2FzaSJ9!"
        )
      ),
"zh-HK": createLanguage(
        require.resolveWeak("./translations.json-zh-HK-virtual.json!=!../../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJTaG93aW5nIDxTdHJvbmc%2Be3RvdGFsfTwvU3Ryb25nPiByZXZpZXdzIHNvcnRlZCBieSI6IlNob3dpbmcgPFN0cm9uZz57dG90YWx9PC9TdHJvbmc%2BIHJldmlld3Mgc29ydGVkIGJ5IiwibW9zdCBoZWxwZnVsIjoibW9zdCBoZWxwZnVsIiwibW9zdCByZWNlbnQiOiJtb3N0IHJlY2VudCIsIlNvcnQgYnkiOiJTb3J0IGJ5IiwiU2hvd2luZyA8U3Ryb25nPnt0b3RhbH08L1N0cm9uZz4gcmV2aWV3cyBmb3Ige2xvY2F0aW9uX2NvbnRleHR1YWxfbmFtZX0iOiJTaG93aW5nIDxTdHJvbmc%2Be3RvdGFsfTwvU3Ryb25nPiByZXZpZXdzIGZvciB7bG9jYXRpb25fY29udGV4dHVhbF9uYW1lfSIsIlNlZSByZXZpZXdzIGZyb20gYWxsIGxvY2F0aW9ucyI6IlNlZSByZXZpZXdzIGZyb20gYWxsIGxvY2F0aW9ucyJ9!"),
        () => import(
          /* webpackChunkName: "zh-HK-translations" */
          "./translations.json-zh-HK-virtual.json!=!../../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJTaG93aW5nIDxTdHJvbmc%2Be3RvdGFsfTwvU3Ryb25nPiByZXZpZXdzIHNvcnRlZCBieSI6IlNob3dpbmcgPFN0cm9uZz57dG90YWx9PC9TdHJvbmc%2BIHJldmlld3Mgc29ydGVkIGJ5IiwibW9zdCBoZWxwZnVsIjoibW9zdCBoZWxwZnVsIiwibW9zdCByZWNlbnQiOiJtb3N0IHJlY2VudCIsIlNvcnQgYnkiOiJTb3J0IGJ5IiwiU2hvd2luZyA8U3Ryb25nPnt0b3RhbH08L1N0cm9uZz4gcmV2aWV3cyBmb3Ige2xvY2F0aW9uX2NvbnRleHR1YWxfbmFtZX0iOiJTaG93aW5nIDxTdHJvbmc%2Be3RvdGFsfTwvU3Ryb25nPiByZXZpZXdzIGZvciB7bG9jYXRpb25fY29udGV4dHVhbF9uYW1lfSIsIlNlZSByZXZpZXdzIGZyb20gYWxsIGxvY2F0aW9ucyI6IlNlZSByZXZpZXdzIGZyb20gYWxsIGxvY2F0aW9ucyJ9!"
        )
      ),
"en-PSEUDO": createLanguage(
        require.resolveWeak("./translations.json-en-PSEUDO-virtual.json!=!../../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJTaG93aW5nIDxTdHJvbmc%2Be3RvdGFsfTwvU3Ryb25nPiByZXZpZXdzIHNvcnRlZCBieSI6IlvFoOG4qcO2w7bDtsW1w6zDrMOs4LiB4Li14LmJxKMgPFN0cm9uZz57dG90YWx9PC9TdHJvbmc%2BIMWZ4bq94bq94bq94bm9w6zDrMOs4bq94bq94bq9xbXFoSDFocO2w7bDtsWZ4bmv4bq94bq94bq9xowgw5%2FDvcO9w71dIiwibW9zdCBoZWxwZnVsIjoiW23MgsO2w7bDtsWh4bmvIOG4qeG6veG6veG6vcaaxqXGkseax5rHmsaaXSIsIm1vc3QgcmVjZW50IjoiW23MgsO2w7bDtsWh4bmvIMWZ4bq94bq94bq9w6fhur3hur3hur3guIHguLXguYnhua9dIiwiU29ydCBieSI6IlvFoMO2w7bDtsWZ4bmvIMOfw73DvcO9XSIsIlNob3dpbmcgPFN0cm9uZz57dG90YWx9PC9TdHJvbmc%2BIHJldmlld3MgZm9yIHtsb2NhdGlvbl9jb250ZXh0dWFsX25hbWV9IjoiW8Wg4bipw7bDtsO2xbXDrMOsw6zguIHguLXguYnEoyA8U3Ryb25nPnt0b3RhbH08L1N0cm9uZz4gxZnhur3hur3hur3hub3DrMOsw6zhur3hur3hur3FtcWhIMaSw7bDtsO2xZkge2xvY2F0aW9uX2NvbnRleHR1YWxfbmFtZX1dIiwiU2VlIHJldmlld3MgZnJvbSBhbGwgbG9jYXRpb25zIjoiW8Wg4bq94bq94bq94bq94bq94bq9IMWZ4bq94bq94bq94bm9w6zDrMOs4bq94bq94bq9xbXFoSDGksWZw7bDtsO2bcyCIMSDxIPEg8aaxpogxprDtsO2w7bDp8SDxIPEg%2BG5r8Osw6zDrMO2w7bDtuC4geC4teC5icWhXSJ9!"),
        () => import(
          /* webpackChunkName: "en-PSEUDO-translations" */
          "./translations.json-en-PSEUDO-virtual.json!=!../../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJTaG93aW5nIDxTdHJvbmc%2Be3RvdGFsfTwvU3Ryb25nPiByZXZpZXdzIHNvcnRlZCBieSI6IlvFoOG4qcO2w7bDtsW1w6zDrMOs4LiB4Li14LmJxKMgPFN0cm9uZz57dG90YWx9PC9TdHJvbmc%2BIMWZ4bq94bq94bq94bm9w6zDrMOs4bq94bq94bq9xbXFoSDFocO2w7bDtsWZ4bmv4bq94bq94bq9xowgw5%2FDvcO9w71dIiwibW9zdCBoZWxwZnVsIjoiW23MgsO2w7bDtsWh4bmvIOG4qeG6veG6veG6vcaaxqXGkseax5rHmsaaXSIsIm1vc3QgcmVjZW50IjoiW23MgsO2w7bDtsWh4bmvIMWZ4bq94bq94bq9w6fhur3hur3hur3guIHguLXguYnhua9dIiwiU29ydCBieSI6IlvFoMO2w7bDtsWZ4bmvIMOfw73DvcO9XSIsIlNob3dpbmcgPFN0cm9uZz57dG90YWx9PC9TdHJvbmc%2BIHJldmlld3MgZm9yIHtsb2NhdGlvbl9jb250ZXh0dWFsX25hbWV9IjoiW8Wg4bipw7bDtsO2xbXDrMOsw6zguIHguLXguYnEoyA8U3Ryb25nPnt0b3RhbH08L1N0cm9uZz4gxZnhur3hur3hur3hub3DrMOsw6zhur3hur3hur3FtcWhIMaSw7bDtsO2xZkge2xvY2F0aW9uX2NvbnRleHR1YWxfbmFtZX1dIiwiU2VlIHJldmlld3MgZnJvbSBhbGwgbG9jYXRpb25zIjoiW8Wg4bq94bq94bq94bq94bq94bq9IMWZ4bq94bq94bq94bm9w6zDrMOs4bq94bq94bq9xbXFoSDGksWZw7bDtsO2bcyCIMSDxIPEg8aaxpogxprDtsO2w7bDp8SDxIPEg%2BG5r8Osw6zDrMO2w7bDtuC4geC4teC5icWhXSJ9!"
        )
      )
      });
  
      export { translations as default };
    