import type { Zone } from '@seek/libs-shared';
import { useTranslations } from '@vocab/react';
import {
  Autosuggest,
  filterSuggestions,
  IconSearch,
} from 'braid-design-system';
import { useEffect, useState } from 'react';
import { useDebounce } from 'use-debounce';

import type { LocationSuggestion } from '../../../../shared/services/locationService/locationService';

import type { QuestionProps } from '../../../type';

import translations from './.vocab';

export interface LocationQuestionProps
  extends QuestionProps<LocationSuggestion> {
  onValueSelected: () => void;
  searchLocations: (query: string) => Promise<LocationSuggestion[]>;
  zone: Zone;
  text?: string;
}

interface AutosuggestValue {
  text: string;
  value?: number;
}

export const LocationQuestion = ({
  message = '',
  value,
  tone,
  onChange,
  onValueSelected,
  searchLocations,
}: LocationQuestionProps) => {
  const MINIMUM_CHARACTERS_SEARCH = 3;
  const [suggestions, setSuggestions] = useState<AutosuggestValue[]>([]);
  const [searchTerm, setSearchTerm] = useState<AutosuggestValue>({
    value: value?.id,
    text: value?.text || '',
  });
  const [debouncedSearchTerm] = useDebounce(searchTerm, 400);
  const { t } = useTranslations(translations);

  const handleOnChangeAutosuggest = (event: AutosuggestValue) => {
    setSearchTerm(event);
    if (!event.text || event.text.length < MINIMUM_CHARACTERS_SEARCH) {
      setSuggestions([]);
      onChange({});
      return;
    }
    onValueSelected();
    onChange({});
  };

  const onClear = () => {
    setSearchTerm({ value: undefined, text: '' });
    onChange({});
    setSuggestions([]);
  };

  useEffect(() => {
    const fetchLocations = async () => {
      const suggestionList = await searchLocations(
        debouncedSearchTerm.text.trim(),
      );

      const formattedLocationList =
        suggestionList?.map(
          (item) =>
            ({
              text: item.text,
              value: item.id,
            }) as AutosuggestValue,
        ) || [];

      setSuggestions(formattedLocationList);

      const item = formattedLocationList.find(
        (option) => option.text.toLowerCase() === searchTerm.text.toLowerCase(),
      );

      if (item) {
        onChange({
          text: item.text,
          id: item.value,
        });
      }
    };

    if (debouncedSearchTerm.text.trim().length >= MINIMUM_CHARACTERS_SEARCH) {
      fetchLocations();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedSearchTerm]);

  return (
    <Autosuggest
      id="location"
      label={t('Location')}
      aria-label={t('Location')}
      icon={<IconSearch />}
      onChange={handleOnChangeAutosuggest}
      suggestions={filterSuggestions(suggestions)}
      onClear={onClear}
      value={searchTerm}
      tone={tone}
      message={message}
      suggestionHighlight="matching"
      reserveMessageSpace={true}
      automaticSelection={
        suggestions[0]?.text.toLowerCase() === searchTerm.text.toLowerCase()
      }
    />
  );
};
