import { PageLoader } from '@seek/libs-shared';
import { useRouteLoaderData } from 'react-router';
import { CompanyProfilesPage } from '../../../paths';
import { SeoStructuredData } from '../../components/seoStructuredData/SeoStructuredData';
import { mapToSeoData } from '../../components/seoStructuredData/mapper';
import { AboutPage } from '../../pages/about/AboutPage';
import type { AboutModel } from './aboutModel';

export function AboutPageContainer({
  trackRandomCompanyProfileLinkClicked,
  trackReviewsClicked,
  trackSeeAllPhotosButtonClicked,
  trackWebsiteClicked,
  trackWriteAReviewClicked,
  onActiveTabChanged,
  getCompanyProfilesPageUrl,
}: {
  getCompanyProfilesPageUrl: (p: CompanyProfilesPage) => string;
  onActiveTabChanged: (item: string | undefined) => void;
  trackRandomCompanyProfileLinkClicked: ({
    linkedCompanyId,
  }: {
    linkedCompanyId: string;
  }) => void;
  trackReviewsClicked: () => void;
  trackSeeAllPhotosButtonClicked: () => void;
  trackWebsiteClicked: () => void;
  trackWriteAReviewClicked: () => void;
}) {
  const aboutData = useRouteLoaderData(CompanyProfilesPage.About) as AboutModel;

  return aboutData ? (
    <>
      <SeoStructuredData
        data={mapToSeoData(aboutData)}
        disableIndexing={false}
      />
      <AboutPage
        model={{ ...aboutData }}
        onActiveTabChanged={onActiveTabChanged}
        getCompanyProfilesPageUrl={getCompanyProfilesPageUrl}
        trackRandomCompanyProfileLinkClicked={
          trackRandomCompanyProfileLinkClicked
        }
        trackWriteAReviewClicked={trackWriteAReviewClicked}
        trackReviewsClicked={trackReviewsClicked}
        trackSeeAllPhotosButtonClicked={trackSeeAllPhotosButtonClicked}
        trackWebsiteClicked={trackWebsiteClicked}
      />
    </>
  ) : (
    <PageLoader />
  );
}
