import 'braid-design-system/reset';

import { InMemoryCache } from '@apollo/client';
import { hydrateRoot } from 'react-dom/client';
import { createBrowserRouter } from 'react-router';
import { RouterProvider } from 'react-router/dom';
import { loadableReady } from 'sku/@loadable/component';

import {
  createCandidateGraphqlClient,
  createServices,
  createRoutes,
} from '@seek/libs-candidate';
import { createClientLogger } from '@seek/libs-shared';
import { datadogRum } from '@datadog/browser-rum';

loadableReady(async () => {
  const container = document.getElementById('app');

  if (!container) {
    throw Error('App container (#app) not found.');
  }

  const config = window.__CONFIG_STATE__;

  const client = createCandidateGraphqlClient(config, {
    cache: new InMemoryCache({
      typePolicies: {
        IOntologyRoleTitleSeniority: {
          keyFields: ['id', 'normalisedText'],
        },
      },
    }).restore(window.__APOLLO_STATE__),
  });

  const logger = createClientLogger();

  const services = createServices({ client, config, logger });

  logger.debug('Client-side app starting up.', config);

  const router = createBrowserRouter(
    createRoutes({ client, config, services, logger }),
  );

  if (config.version !== 'local') {
    datadogRum.init({
      applicationId: '17f235cd-ef53-4d5f-9af9-623116a8a386',
      clientToken: 'pub5f934636ad84ac907989cf2ffaf550fc',
      site: 'datadoghq.com',
      service: 'company-profiles',
      env: config.environment,
      version: config.version,
      sessionSampleRate: 5,
      sessionReplaySampleRate: 0,
      trackUserInteractions: false,
      trackResources: true,
      trackLongTasks: false,
      defaultPrivacyLevel: 'mask-user-input',
    });
  }

  hydrateRoot(container, <RouterProvider router={router} />);
});
