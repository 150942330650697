import React, { useState } from 'react';

import type { CompanyProfileAction } from '../models/CompanyProfile';

type AppContextProps =
  | {
      advertiserId?: string;
      companyId?: string;
      companyName?: string;
      companyProfileAction?: CompanyProfileAction | undefined;
      seekId?: string;
      setAdvertiserId?: (advertiserId: string) => void;
      setCompanyId?: (companyId?: string) => void;
      setCompanyName?: (companyName: string) => void;
      setCompanyProfileAction?: (companyAction: CompanyProfileAction) => void;
      setSeekId?: (seekId: string) => void;
      setUserId?: (userId?: string) => void;
      userId?: string;
    }
  | undefined;

type AppContextProviderProps = React.PropsWithChildren<AppContextProps>;

const AppContextProviderContext =
  React.createContext<AppContextProps>(undefined);

export const AppContextProvider = ({ children }: AppContextProviderProps) => {
  const [advertiserIdInternal, setAdvertiserIdInternal] = useState<string>();
  const [userIdInternal, setUserIdInternal] = useState<string>();
  const [seekIdInternal, setSeekIdInternal] = useState<string>();
  const [companyIdInternal, setCompanyIdInternal] = useState<string>();
  const [companyNameInternal, setCompanyNameInternal] = useState<string>();
  const [companyProfileActionInternal, setCompanyProfileActionInternal] =
    useState<CompanyProfileAction | undefined>(undefined);

  const setAdvertiserId = (advertiserId: string) => {
    setAdvertiserIdInternal(advertiserId);
  };
  const setUserId = (userId?: string) => {
    setUserIdInternal(userId);
  };
  const setSeekId = (userId?: string) => {
    setSeekIdInternal(userId);
  };
  const setCompanyId = (companyId?: string) => {
    setCompanyIdInternal(companyId);
  };
  const setCompanyName = (companyName: string) => {
    setCompanyNameInternal(companyName);
  };
  const setCompanyProfileAction = (
    companyProfileAction: CompanyProfileAction,
  ) => {
    setCompanyProfileActionInternal(companyProfileAction);
  };

  return (
    <AppContextProviderContext.Provider
      value={{
        companyId: companyIdInternal,
        setCompanyId,
        advertiserId: advertiserIdInternal,
        setAdvertiserId,
        userId: userIdInternal,
        setUserId,
        seekId: seekIdInternal,
        setSeekId,
        companyName: companyNameInternal,
        setCompanyName,
        companyProfileAction: companyProfileActionInternal,
        setCompanyProfileAction,
      }}
    >
      {children}
    </AppContextProviderContext.Provider>
  );
};

export const useAppContext = () => {
  const context = React.useContext(AppContextProviderContext);

  if (context === undefined) {
    throw new Error('useAppContext must be used within a AppContextProvider');
  }

  return {
    companyId: context.companyId ?? '',
    setCompanyId: context.setCompanyId,
    advertiserId: context.advertiserId ?? '',
    setAdvertiserId: context.setAdvertiserId,
    userId: context.userId ?? '',
    setUserId: context.setUserId,
    seekId: context.seekId ?? '',
    setSeekId: context.setSeekId,
    companyName: context.companyName ?? '',
    setCompanyName: context.setCompanyName,
    companyProfileAction: context.companyProfileAction,
    setCompanyProfileAction: context.setCompanyProfileAction,
  };
};
