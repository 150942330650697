import type { Country, Language } from '@seek/melways-sites';

//* 'zh' language from Melways is unsupported.
export const getLanguagesByCountryCode = (countryCode: Country) => {
  switch (countryCode) {
    case 'th':
      return ['en', 'th'] as const satisfies Exclude<Language, 'zh'>[];
    case 'id':
      return ['en', 'id'] as const satisfies Exclude<Language, 'zh'>[];
    default:
      return ['en'] as const satisfies Exclude<Language, 'zh'>[];
  }
};
