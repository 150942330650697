import {
  buildCandidateCompanyProfilesURI,
  buildCandidateCompanyProfileURI,
  useConfig,
} from '@seek/libs-shared';
import { useTranslations } from '@vocab/react';
import {
  Box,
  ButtonLink,
  Heading,
  Inline,
  Stack,
  Text,
} from 'braid-design-system';
import { useEffect } from 'react';
import {
  trackClickSubmissionPageGoToCompanyProfile,
  trackDisplaySubmissionPage,
} from '../../shared/tracking/writeAReview/trackingEvents';
import type { ReviewFormContext } from '../type';
import translations from './.vocab';
import { SuccessHiFiveImage } from './SuccessHiFiveImage';

export const SubmitSuccessPage = ({
  companyName = '',
  companyId,
  published,
  reviewFormContext,
}: {
  companyName: string;
  reviewFormContext: ReviewFormContext;
  companyId?: string;
  published?: boolean;
}) => {
  const { t } = useTranslations(translations);
  const config = useConfig();
  const { environment, country, language } = config;

  useEffect(() => {
    trackDisplaySubmissionPage({ config, context: reviewFormContext });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const url = companyId
    ? buildCandidateCompanyProfileURI({
        companyName,
        companyId,
        country,
        language,
        isStaging: environment === 'staging',
      })
    : buildCandidateCompanyProfilesURI({
        country,
        language,
        isStaging: environment === 'staging',
      });

  const handleClick = () => {
    trackClickSubmissionPageGoToCompanyProfile({
      config,
      context: reviewFormContext,
    });
  };

  return (
    <Box
      data={{ testid: 'submit-success-page' }}
      paddingTop={{
        tablet: 'xxlarge',
        mobile: 'large',
      }}
    >
      <Stack space="xlarge" align="center">
        <Heading level="1">{t('Thank you for your review')}</Heading>
        <Text size="standard">
          {t(
            'Youve helped other people understand what its like to work at {companyName} by sharing your experience.',
            { companyName },
          )}
        </Text>

        <SuccessHiFiveImage />

        <Inline space="medium" collapseBelow="tablet">
          {companyId && published ? (
            <ButtonLink
              variant="solid"
              tone="formAccent"
              onClick={handleClick}
              href={url}
              data={{ testid: 'go-to-company-profile' }}
            >
              {t('Go to company profile')}
            </ButtonLink>
          ) : (
            <ButtonLink
              variant="solid"
              tone="formAccent"
              onClick={handleClick}
              href={url}
              data={{ testid: 'go-to-company-profiles' }}
            >
              {t('Go to company profiles')}
            </ButtonLink>
          )}
        </Inline>
      </Stack>
    </Box>
  );
};
