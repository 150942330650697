/* istanbul ignore file */
// this is a mock file for testing

import type {
  CompanyNameSlug as CompanyNameSlugFromGraph,
  CompanyProfile as CompanyProfileFromGraph,
  PostCompanyProfileReviewFlagPayload as ReviewFlagPayloadFromGraph,
  PostCompanyProfileReviewUpvotesPayload as ReviewUpvotesPayloadFromGraph,
} from '@seek/ca-graphql-schema/types';
import {
  type CompanyProfile,
  type Jobs,
  type Reviews,
  type ReviewUpvotes as ReviewUpvotesFromGraph,
  DEFAULT_LOGO_URL,
  ORPHAN_REVIEW_COMPANY_ID,
} from '@seek/libs-shared';
import type { Country } from '@seek/melways-sites';

import type { CompaniesListModel } from '../../../companyProfiles/models/companiesListModel';
import type { AppConfig } from '../../config';

import type { CompanyProfileService } from './companyProfileService';
import { mapCompanyProfileModel } from './mapper';
import { getMockCompanyNameSlug } from './mock/getMockCompanyNameSlug';
import { getMockReviews } from './mock/getMockReviews';
import type {
  SearchCompaniesResult,
  SearchCompanyProfileResult,
} from './types';

const jobs = [
  {
    id: '72846618',
    title: 'Finance Manager',
    url: '/job/72846618',
    advertiser: 'JLL',
    classification: 'Accounting',
    subClassification: 'Financial Managers & Controllers',
    description:
      'Be part of one of our new accounts with potential for future growth! You get to work with key stakeholders to kickstart the account.',
    createdAt: '2024-01-08T22:27:05Z',
    listingDateDisplay: '7d ago',
    bulletPoints: [],
    salaryDisplayFlag: false,
    location: 'Melbourne - CBD & Inner Suburbs',
  },
  {
    id: '72846604',
    title: 'Finance Manager',
    url: '/job/72846604',
    advertiser: 'JLL',
    classification: 'Accounting',
    subClassification: 'Financial Managers & Controllers',
    description:
      'Be part of one of our new accounts with potential for future growth! You get to work with key stakeholders to kickstart the account.',
    createdAt: '2024-01-08T22:26:30Z',
    listingDateDisplay: '7d ago',
    bulletPoints: [],
    salaryDisplayFlag: false,
    location: 'Sydney - CBD, Inner West & Eastern Suburbs',
  },
  {
    id: '73069630',
    title: 'Receptionist',
    url: '/job/73069630',
    advertiser: 'JLL',
    classification: 'Administration & Office Support',
    subClassification: 'Receptionists',
    description:
      'Ensure a seamless and professional experience for all client staff and their visitors. Global investment management client with a CBD location!',
    createdAt: '2024-01-17T01:12:00Z',
    listingDateDisplay: '7d ago',
    bulletPoints: [
      'Great culture and collaborative environment, working with a fun team!',
      'Future growth opportunities and network opportunities to develop your career',
      'Employee development and well-being are prioritised',
    ],
    salaryDisplayFlag: false,
    location: 'Melbourne - CBD & Inner Suburbs',
  },
  {
    id: '73062756',
    title: 'Facilities Coordinator',
    url: '/job/73062756',
    advertiser: 'JLL',
    classification: 'Real Estate & Property',
    subClassification: 'Body Corporate & Facilities Management',
    description:
      'Take the next step in your Facilities career, based at a client site in Melbourne!',
    createdAt: '2024-01-16T19:49:15Z',
    listingDateDisplay: '7d ago',
    bulletPoints: [
      'Your opportunity to innovate and put your expertise into action',
      'Full-time position with long term career opportunities',
      'Become a member of a diverse & supportive team dedicated to the work we do',
    ],
    salaryDisplayFlag: false,
    location: 'Melbourne - CBD & Inner Suburbs',
  },
  {
    id: '73065766',
    title: 'Property Manager - Compliance',
    url: '/job/73065766',
    advertiser: 'JLL',
    classification: 'Real Estate & Property',
    subClassification: 'Commercial Sales, Leasing & Property Mgmt',
    description:
      'Newly created compliance role due to growth! Do you hold experience as a commercial PM, looking to develop your career in premium office assets?',
    createdAt: '2024-01-16T23:10:25Z',
    listingDateDisplay: '7d ago',
    bulletPoints: [],
    salaryDisplayFlag: false,
    location: 'Sydney - CBD, Inner West & Eastern Suburbs',
  },
  {
    id: '72980277',
    title: 'Senior Finance Manager',
    url: '/job/72980277',
    advertiser: 'JLL',
    classification: 'Accounting',
    subClassification: 'Financial Managers & Controllers',
    description:
      'Responsible for two premium commercial sites with view for growth. Based in North Sydney accessible to a new metro station.',
    createdAt: '2024-01-12T11:08:24Z',
    listingDateDisplay: '7d ago',
    bulletPoints: [],
    salaryDisplayFlag: false,
    location: 'Sydney - North Shore & Northern Beaches',
  },
  {
    id: '73066026',
    title: 'Workplace Manager',
    url: '/job/73066026',
    advertiser: 'JLL',
    classification: 'Real Estate & Property',
    subClassification: 'Body Corporate & Facilities Management',
    description:
      'Exciting mixed-use portfolio. Join a dynamic, talented & supportive team, learn from the best! Are you ready to elevate your career in workplace mgt?',
    createdAt: '2024-01-16T23:23:25Z',
    listingDateDisplay: '23h ago',
    bulletPoints: [],
    salaryDisplayFlag: false,
    location: 'Brisbane - CBD & Inner Suburbs',
  },
  {
    id: '73034969',
    title: 'Receptionist',
    url: '/job/73034969',
    advertiser: 'JLL',
    classification: 'Administration & Office Support',
    subClassification: 'Receptionists',
    description:
      'Provide our globally recognised client with superior and efficient front of house service, providing a warm and energetic workplace experience!',
    createdAt: '2024-01-15T23:47:02Z',
    listingDateDisplay: '29d ago',
    bulletPoints: [
      'Great culture and collaborative environment, working with a fun team!',
      'Future growth opportunities and network opportunities to develop your career',
      "JLL is Australia's largest Commercial Real Estate Company",
    ],
    salaryDisplayFlag: false,
    location: 'Sydney - CBD, Inner West & Eastern Suburbs',
  },
  {
    id: '73014383',
    title: 'Regional Facilities Manager',
    url: '/job/73014383',
    advertiser: 'JLL',
    classification: 'Real Estate & Property',
    subClassification: 'Body Corporate & Facilities Management',
    description:
      'Manage the day-to-day Facilities Activities across the client portfolio in Australia and New Zealand.',
    createdAt: '2024-01-15T04:19:26Z',
    listingDateDisplay: '29d ago',
    bulletPoints: [
      'Great culture and collaborative environment, working with a fun team!',
      'Future growth opportunities and network opportunities to develop your career',
      'Develop and implement a building strategy for tomorrow and the future!',
    ],
    salaryDisplayFlag: false,
    location: 'Sydney - CBD, Inner West & Eastern Suburbs',
  },
  {
    id: '72941839',
    title: 'Receptionist',
    url: '/job/72941839',
    advertiser: 'JLL',
    classification: 'Administration & Office Support',
    subClassification: 'Receptionists',
    description:
      "We're searching for a skilled, energetic and proactive receptionist to join our team, working with a tech giant, Google! Melb CBD location.",
    createdAt: '2024-01-11T08:31:22Z',
    listingDateDisplay: '2h ago',
    bulletPoints: [
      'Great culture and collaborative environment, working with a fun team!',
      'Part of an active, visible workplace team that is engaging, proactive and agile',
      'Endless snacks throughout the day! Free brekky AND lunch!',
    ],
    salaryDisplayFlag: false,
    location: 'Melbourne - CBD & Inner Suburbs',
  },
  {
    id: '72942839',
    title: 'Receptionist',
    url: '/job/72941839',
    advertiser: 'JLL',
    classification: 'Administration & Office Support',
    subClassification: 'Receptionists',
    description:
      "We're searching for a skilled, energetic and proactive receptionist to join our team, working with a tech giant, Google! Melb CBD location.",
    createdAt: '2024-01-11T08:31:22Z',
    listingDateDisplay: '15d ago',
    bulletPoints: [
      'Great culture and collaborative environment, working with a fun team!',
      'Part of an active, visible workplace team that is engaging, proactive and agile',
      'Endless snacks throughout the day! Free brekky AND lunch!',
    ],
    salaryDisplayFlag: false,
    location: 'Melbourne - CBD & Inner Suburbs',
  },
  {
    id: '72941840',
    title: 'Receptionist',
    url: '/job/72941839',
    advertiser: 'JLL',
    classification: 'Administration & Office Support',
    subClassification: 'Receptionists',
    description:
      "We're searching for a skilled, energetic and proactive receptionist to join our team, working with a tech giant, Google! Melb CBD location.",
    createdAt: '2024-01-11T08:31:22Z',
    listingDateDisplay: '11d ago',
    bulletPoints: [
      'Great culture and collaborative environment, working with a fun team!',
      'Part of an active, visible workplace team that is engaging, proactive and agile',
      'Endless snacks throughout the day! Free brekky AND lunch!',
    ],
    salaryDisplayFlag: false,
    location: 'Melbourne - CBD & Inner Suburbs',
  },
  {
    id: '72941841',
    title: 'Receptionist',
    url: '/job/72941839',
    advertiser: 'JLL',
    classification: 'Administration & Office Support',
    subClassification: 'Receptionists',
    description:
      "We're searching for a skilled, energetic and proactive receptionist to join our team, working with a tech giant, Google! Melb CBD location.",
    createdAt: '2024-01-11T08:31:22Z',
    listingDateDisplay: '4d ago',
    bulletPoints: [
      'Great culture and collaborative environment, working with a fun team!',
      'Part of an active, visible workplace team that is engaging, proactive and agile',
      'Endless snacks throughout the day! Free brekky AND lunch!',
    ],
    salaryDisplayFlag: false,
    location: 'Melbourne - CBD & Inner Suburbs',
  },
  {
    id: '72941842',
    title: 'Receptionist',
    url: '/job/72941839',
    advertiser: 'JLL',
    classification: 'Administration & Office Support',
    subClassification: 'Receptionists',
    description:
      "We're searching for a skilled, energetic and proactive receptionist to join our team, working with a tech giant, Google! Melb CBD location.",
    createdAt: '2024-01-11T08:31:22Z',
    listingDateDisplay: '2d ago',
    bulletPoints: [
      'Great culture and collaborative environment, working with a fun team!',
      'Part of an active, visible workplace team that is engaging, proactive and agile',
      'Endless snacks throughout the day! Free brekky AND lunch!',
    ],
    salaryDisplayFlag: false,
    location: 'Melbourne - CBD & Inner Suburbs',
  },
];

export const createCompanyProfileServiceMock = (_props: {
  config: AppConfig;
}): CompanyProfileService => {
  const searchCompanies = async ({
    searchTerm,
    _page = 1,
  }: {
    searchTerm: string;
    _page?: number;
  }): Promise<SearchCompaniesResult> => {
    await new Promise((resolve) => setTimeout(resolve, 300));
    if (searchTerm === 'nothing') {
      return { companies: [], totalCompanies: 0 };
    }
    return {
      companies: [
        {
          name: 'SEE Civil',
          slug: 'see-civil-813341',
          logoUrl:
            'https://cpp-prod-seek-company-image-uploads.s3.ap-southeast-2.amazonaws.com/813341/logo/db370df0-cd44-11ea-bfc5-69c296d2dc49.jpeg',
          overallRating: {
            count: 21,
            average: 3.1904761904761907,
          },
        },
        {
          name: 'Seed Force',
          slug: 'seed-force-438910',
          logoUrl:
            'https://cpp-prod-seek-company-image-uploads.s3.ap-southeast-2.amazonaws.com/438910/logo/6482c990-bcca-11ea-86d1-e52bae5cc086.jpeg',
          overallRating: { count: 0, average: 0 },
        },
        {
          name: 'Seed Heritage',
          slug: 'seed-heritage-434802',
          logoUrl:
            'https://cpp-prod-seek-company-image-uploads.s3.ap-southeast-2.amazonaws.com/434802/logo/69929050-bcca-11ea-86d1-e52bae5cc086.png',
          overallRating: {
            count: 104,
            average: 2.6634615384615383,
          },
        },
        {
          name: 'SEEK',
          slug: 'seek-432600',
          logoUrl: DEFAULT_LOGO_URL,
          overallRating: {
            count: 184,
            average: 4.635869565217392,
          },
        },
        {
          name: 'Seeley International',
          slug: 'seeley-international-815091',
          logoUrl:
            'https://cpp-prod-seek-company-image-uploads.s3.ap-southeast-2.amazonaws.com/815091/logo/659e4bb0-bcca-11ea-86d1-e52bae5cc086.png',
          overallRating: {
            count: 17,
            average: 3.235294117647059,
          },
        },
        {
          name: 'Seeing Machines',
          slug: 'seeing-machines-861102',
          logoUrl:
            'https://cpp-prod-seek-company-image-uploads.s3.ap-southeast-2.amazonaws.com/861102/logo/d3883460-becf-11ed-89b1-51c93452decb.png?1678403533581',
          overallRating: { count: 5, average: 2.8 },
        },
        {
          name: 'We R Finance',
          slug: 'we-r-finance-814755',
          logoUrl:
            'https://cpp-prod-seek-company-image-uploads.s3.ap-southeast-2.amazonaws.com/814755/logo/658b38e0-bcca-11ea-86d1-e52bae5cc086.jpeg',
          overallRating: { count: 1, average: 1 },
        },
        {
          name: 'We Are Social and here is a very long company name to test what the wrapping looks like',
          slug: 'we-are-social-831431',
          logoUrl:
            'https://cpp-prod-seek-company-image-uploads.s3.ap-southeast-2.amazonaws.com/831431/logo/65bb4990-bcca-11ea-86d1-e52bae5cc086.jpeg',
          overallRating: { count: 1, average: 5 },
        },
        {
          name: 'Western Union',
          slug: 'western-union-435916',
          logoUrl: DEFAULT_LOGO_URL,
          overallRating: { count: 4, average: 2.75 },
        },
        {
          name: 'Wesfarmers Industrial & Safety Here Is More Text Very Long Company Name',
          slug: 'wesfarmers-industrial-n-safety-436143',
          logoUrl: DEFAULT_LOGO_URL,
          overallRating: { count: 25, average: 2.6 },
        },
        {
          name: 'Lululemon',
          slug: 'lululemon-434894',
          logoUrl: DEFAULT_LOGO_URL,
          overallRating: { count: 23, average: 3.7 },
        },
      ],
      totalCompanies: 123,
    };
  };

  const getCompanyProfileForeignId = async (
    companyId: string,
  ): Promise<string> => `99${companyId}`;

  const getJobs = async (companyId: string, page?: number): Promise<Jobs> => ({
    jobs: jobs.slice((page || 1) * 10 - 10, (page || 1) * 10),
    totalJobs: jobs.length,
  });

  const getCompanyProfile = async (
    companyId: string,
    isDraft?: boolean,
  ): Promise<CompanyProfile> => {
    const data: CompanyProfileFromGraph = {
      isBeta: false,
      shouldDisplayReviews: false,
      areReviewsEnabled: true,
      overview: {
        description: {
          full: 'SEEK’s portfolio of diverse businesses make a positive impact on a truly global scale. Our purpose is to help people live more fulfilling and productive working lives and help organisations succeed. We create world-class technology solutions to connect more people to relevant employment, education, small business and volunteer opportunities.\nWe have a culture of high-performance in our workplaces and celebrate the diversity of our employees who contribute to the success of our organisation.\n<ul>\n<li>Coffee</li>\n<li>Tea</li>\n<li>Milk</li>\n</ul>',
          paragraphs: [
            'SEEK’s portfolio of diverse businesses make a positive impact on a truly global scale. Our purpose is to help people live more fulfilling and productive working lives and help organisations succeed. We create world-class technology solutions to connect more people to relevant employment, education, small business and volunteer opportunities.',
            'We have a culture of high-performance in our workplaces and celebrate the diversity of our employees who contribute to the success of our organisation.',
            '<ul>',
            '<li>Coffee</li>',
            '<li>Tea</li>',
            '<li>Milk</li>',
            '</ul>',
          ],
        },
        industry: 'Advertising, Arts & Media',
        primaryLocation: 'Melbourne, Victoria',
        size: {
          description: '101-5,000 employees',
        },
        specialities: 'Online Employment Classifieds, Job Boards, Education',
        website: {
          url: 'https://www.seek.melbourne',
          displayName: 'seek.melbourne',
        },
      },
      awards: [
        {
          imageUrl:
            'https://image-service-cdn.staging.seek.com.au/e120d605b28c091d53f459357bf492afb07b9128',
          description: 'Best Places to work 2021',
          name: 'Best Places to work',
          year: 2021,
        },
        {
          imageUrl:
            'https://image-service-cdn.staging.seek.com.au/616f7e0d8c4ff2b3611a5229d7420a792b2a0562',
          description: 'Carbon Neutral Organisation 2022',
          name: 'Carbon Neutral Organisation',
          year: 2022,
        },
      ],
      branding: {
        logo: DEFAULT_LOGO_URL,
        cover: {
          aspectRatio: 2.8,
          url: 'https://image-service-cdn.staging.seek.com.au/73a9ac271ce110bfdc91562ba0619b37f4630a5f/42bf52e76925e2211c3d956332bf254f2157d3de',
        },
      },
      cultureAndValues: {
        values: [
          {
            title: 'We always... Seek better',
            description:
              "We don't settle for 'good'. Innately curious and creative, we actively look for new and better ways to do things that deliver improved customer outcomes.",
          },
          {
            title: 'We always... Bite the bullet',
            description:
              "We do what's right, not what's easy. Brave but considered, we don't shy away from doing the hard stuff if we believe. Bring about better outcomes for our customers or our business.",
          },
          {
            title: 'We always... Think for tomorrow',
            description:
              'We never rest on our laurels. Relentlessly forward-thinking and strategic, we play the long game without losing sight of the here and now.',
          },
          {
            title: 'We always... Share the good stuff',
            description:
              'We share so others grow. Always ready and willing to help, we share our time, knowledge and experience to make work better for people, companies and each other.',
          },
        ],
        title: 'Our beliefs',
        imageUrl:
          'https://image-service-cdn.staging.seek.com.au/544e754c8fb297ec4b74024a01a086706528417d',
        description:
          'The beliefs are SEEK’s secret recipe to success. They state the way we do things at SEEK and are based around the fundamental things the organisation was built on.',
      },
      customStatement: {
        title: 'Our approach to diversity, equity, and inclusion',
        description: {
          full: 'We recognise and respect qualities which are unique to individuals such as gender, language, ethnicity, age, religion, disability and sexual orientation. We are recognised as having an approach to diversity and inclusion which is genuine and embedded within our culture.',
          paragraphs: [
            'We recognise and respect qualities which are unique to individuals such as gender, language, ethnicity, age, religion, disability and sexual orientation. We are recognised as having an approach to diversity and inclusion which is genuine and embedded within our culture.',
          ],
        },
      },
      featured: {
        title: 'Best places 2021 awards',
        videoUrl: null,
        imageUrl:
          'https://image-service-cdn.staging.seek.com.au/82c608d175d14bfb9e6d79d23de1600acdf17b52',
        description: {
          full: "Our people are what make SEEK the company it is today, and we're honoured to be recognised as the Best Place to Work Overall and the #1 Best Place to Work - Tech Industry in The Australian Financial Review #BestPlaces2021 Awards. We wouldn't be where we are today without the SEEKers who make this company what it is - thank you!",
          paragraphs: [
            "Our people are what make SEEK the company it is today, and we're honoured to be recognised as the Best Place to Work Overall and the #1 Best Place to Work - Tech Industry in The Australian Financial Review #BestPlaces2021 Awards. We wouldn't be where we are today without the SEEKers who make this company what it is - thank you!",
          ],
        },
      },
      isCompanyProfile: true,
      missionStatement: {
        description: {
          paragraphs: [
            'We help people live more fulfilling and productive working lives and help organisations succeed.',
            'As a global airline, Singapore Airlines is dedicated to providing air transportation services of the highest quality, and to maximising returns for the benefit of our shareholders and employees.',
            "With a commitment to fleet modernisation, product and service innovation and market leadership, Singapore Airlines has evolved into one of the world's most respected travel brands.",
            "Today, Singapore Airlines is internationally recognised as the world's leading airline and operates a modern passenger fleet of more than 100 aircraft.  Its network covers more than 60 cities in more than 30 countries.",
          ],
          full: "We help people live more fulfilling and productive working lives and help organisations succeed.\nAs a global airline, Singapore Airlines is dedicated to providing air transportation services of the highest quality, and to maximising returns for the benefit of our shareholders and employees.\nWith a commitment to fleet modernisation, product and service innovation and market leadership, Singapore Airlines has evolved into one of the world's most respected travel brands.\nToday, Singapore Airlines is internationally recognised as the world's leading airline and operates a modern passenger fleet of more than 100 aircraft.  Its network covers more than 60 cities in more than 30 countries.",
        },
      },
      name: `SEEK${isDraft ? ' (Draft)' : ''}`,
      perksAndBenefits: [
        {
          title: 'Family focus',
          description:
            '14 weeks paid primary carer leave with Parental coaching program Flexible working',
        },
        {
          title: 'Diversity',
          description:
            'Initiatives to cultivate a diverse and inclusive environment',
        },
        {
          title: 'Learning & Development',
          description: 'Supporting our people to reach their full potential',
        },
        {
          title: 'Flexible working',
          description:
            'Pick ups? Drop offs? Long commute? No worries! We work flexibly at SEEK',
        },
        {
          title: 'Free fuel',
          description: 'Free coffee and breakfast, everyday!',
        },
        {
          title: 'Community impact',
          description: 'Paid volunteer opportunities with our charity partners',
        },
      ],
      reviewsSummary: {
        overallRating: {
          value: 4.2083,
          numberOfReviews: {
            value: 24,
            description: '24',
          },
        },
        communityGuideline: {
          url: 'https://help.seek.com.au/s/article/Company-reviews-community-guidelines',
        },
        employeeRecommendation: {
          value: 87.5,
          rating: '88%',
          description: 'employees recommend this employer to friends',
        },
        ratings: [
          {
            description: 'Work/Life balance',
            rating: 4.2083,
            value: 4.2083,
          },
          {
            description: 'Career development',
            rating: 3.7917,
            value: 3.7917,
          },
          {
            description: 'Benefits & perks',
            rating: 3.9583,
            value: 3.9583,
          },
          {
            description: 'Management',
            rating: 3.9167,
            value: 3.9167,
          },
          {
            description: 'Working environment',
            rating: 4.2917,
            value: 4.2917,
          },
          {
            description: 'Diversity & equal opportunity',
            rating: 3.7222,
            value: 3.7222,
          },
        ],
        salaryRating: {
          value: 87.5,
          rating: '88%',
          description: 'rate salary as high or average',
        },
      },
      id: '1217901',
      reviews: {
        data: [
          {
            overallRating: 4,
            ratings: [
              {
                description: 'Benefits & perks',
                rating: 3,
                value: 3,
              },
              {
                description: 'Career development',
                rating: 3,
                value: 3,
              },
              {
                description: 'Work/Life balance',
                rating: 3,
                value: 3,
              },
              {
                description: 'Working environment',
                rating: 3,
                value: 3,
              },
              {
                description: 'Management',
                rating: 3,
                value: 3,
              },
              {
                description: 'Diversity & equal opportunity',
                rating: 3,
                value: 3,
              },
            ],
            id: '1294474',
            createdAt: {
              label: '7 Apr 2017',
              shortLabel: '7 Apr 2017',
              longLabel: '7 April 2017',
              shortAbsoluteLabel: '7 Apr 2017',
              longAbsoluteLabel: '7 April 2017',
              dateTime: '2017-04-07T03:05:42.000+00:00',
              dateTimeUtc: '2017-04-07T03:05:42.000Z',
              seekMaxPollLabel: '',
            },

            title: 'Let you work to your best abilities',
            pros: 'Work-life balance',
            cons: 'Access to mgmt',
            companyId: '432311',
            jobTitle: 'Cloud Architect',
            employmentStatus: '5 to 6 years in the role, current employee',
            workLocation: 'Melbourne, Victoria, Australia',
            salarySummary: 'High',
            recommended: {
              description: 'Recommended',
              value: true,
            },
            upvotes: {
              count: 18,
              description: '18 people found this helpful',
              userIds: ['1', '2', '3'],
            },
            reply: null,
          },
          {
            overallRating: 2,
            ratings: [
              {
                description: 'Benefits & perks',
                rating: 3,
                value: 3,
              },
              {
                description: 'Career development',
                rating: 3,
                value: 3,
              },
              {
                description: 'Work/Life balance',
                rating: 3,
                value: 3,
              },
              {
                description: 'Working environment',
                rating: 3,
                value: 3,
              },
              {
                description: 'Management',
                rating: 3,
                value: 3,
              },
              {
                description: 'Diversity & equal opportunity',
                rating: 3,
                value: 3,
              },
            ],
            id: '1294472',
            createdAt: {
              label: '7 Apr 2017',
              shortLabel: '7 Apr 2017',
              longLabel: '7 April 2017',
              shortAbsoluteLabel: '7 Apr 2017',
              longAbsoluteLabel: '7 April 2017',
              dateTime: '2017-04-07T03:05:21.000+00:00',
              dateTimeUtc: '2017-04-07T03:05:21.000Z',
              seekMaxPollLabel: '',
            },
            companyId: '1217901',
            jobTitle: 'test job title',
            employmentStatus: null,
            workLocation: 'Melbourne VIC',
            salarySummary: 'Not specified',
            recommended: {
              description: 'Not recommended',
              value: false,
            },
            title: 'test',
            pros: 'test pro',
            cons: 'test con',
            upvotes: {
              count: 16,
              description: '16 people found this helpful',
              userIds: ['1', '2', '3'],
            },
            reply: null,
          },
        ],
        paging: {
          page: 1,
          perPage: 10,
          total: 2,
          totalPages: 1,
        },
      },
      tracking: {
        isCompanyProfile: true,
      },
      companyNameSlug: '',
      profileUrl: null,
      reviewsUrl: null,
    };

    const companyReviewRatingSummary = {
      companyId: '432378',
      company: {
        count: 217,
        percentage: 62,
        rating: 3.1,
      },
      diversity: {
        count: 217,
        percentage: 73,
        rating: 3.6,
      },
      executiveManagement: {
        count: 217,
        percentage: 56,
        rating: 2.8,
      },
      perksAndBenefits: {
        count: 217,
        percentage: 55,
        rating: 2.8,
      },
      careerOpportunity: {
        count: 217,
        percentage: 67,
        rating: 3.4,
      },
      workEnvironment: {
        count: 217,
        percentage: 63,
        rating: 3.1,
      },
      workLifeBalance: {
        count: 217,
        percentage: 58,
        rating: 2.9,
      },
      companyRating1Star: {
        count: 45,
      },
      companyRating2Star: {
        count: 29,
      },
      companyRating3Star: {
        count: 47,
      },
      companyRating4Star: {
        count: 52,
      },
      companyRating5Star: {
        count: 44,
      },
      companyRecommended: {
        percentage: 55,
        count: 217,
      },
      salaryFair: {
        percentage: 49,
        count: 107,
      },
      salaryGenerous: {
        percentage: 10,
        count: 21,
      },
      salaryPoor: {
        percentage: 41,
        count: 89,
      },
    };
    const legacyCompanyProfile = {
      address: '60 Cremorne St, Cremorne, VIC 3121',
      assets: {
        cover: {
          strategies: {
            jdp_cover:
              'https://image-service-cdn.seek.com.au/73a9ac271ce110bfdc91562ba0619b37f4630a5f/42bf52e76925e2211c3d956332bf254f2157d3de',
          },
          id: '73a9ac271ce110bfdc91562ba0619b37f4630a5f',
          url: 'https://image-service-cdn.seek.com.au/73a9ac271ce110bfdc91562ba0619b37f4630a5f',
        },
        logo: {
          strategies: {
            jdp_logo: DEFAULT_LOGO_URL,
          },
          id: '065e5cdc21f0bd6bdb63f543be9ea6db8bb92b70',
          url: 'https://image-service-cdn.seek.com.au/065e5cdc21f0bd6bdb63f543be9ea6db8bb92b70',
        },
      },
      company_id: '432600',
      company_name: 'SEEK',
      country_iso: 'au' as Country,
      culture_values: {
        heading: 'Our SEEK',
        values: [
          {
            text: 'We are passionate about our purpose, our customers and our community.',
            title: 'PASSION',
          },
          {
            text: 'We care about each other and collaborate to achieve together.',
            title: 'TEAM',
          },
          {
            text: 'We execute with excellence and achieve great results.',
            title: 'DELIVERY',
          },
          {
            text: 'We think and act for the long-term.',
            title: 'FUTURE',
          },
        ],
        photo:
          'https://image-service-cdn.seek.com.au/173a452b8e53d9edf217588aaa1c2feee8db9906',
        text: 'Our SEEK is a statement that describes our culture, and what we know to be true for success at SEEK. By using Our SEEK everyday, we deliver on our Purpose, create great value for our customers, and make SEEK an even better place to work.',
      },
      custom_statement: {
        text: [
          'At SEEK we are energised about making our communities even better. In keeping with our purpose of helping people live more fulfilling and productive working lives and to help organisations succeed, we are committed to operating sustainably and to always think and act with long-term goals in mind.',
          'SEEK approaches sustainability via an Environment, Social and Governance (ESG) Framework, with a focus on priorities that deliver social and environmental value for our communities and our people. We support the United Nations Sustainable Development Goals as a blueprint to achieve a better and more sustainable future for all.',
          'For more information see https://www.seek.com.au/about/sustainability/',
        ],
        title: 'Our approach to sustainability',
      },
      featured: {
        sub_heading: 'Best places 2022 awards',
        link: 'https://image-service-cdn.seek.com.au/5a283cb51a07b72a18bd648d0fbdd2e12090bb61',
        text: [
          "Our people are what make SEEK the company it is today, and we're honoured to be recognised as a top 10 Best Places to Work in Tech 2022 by The Australian Financial Review. In 2021, SEEK was named the Best Place to Work Overall and the #1 Best Place to Work in Tech.",
        ],
        link_is_video: false,
      },
      foreign_company_id: '3987',
      gallery: {
        photo_video_gallery: {
          photos: [
            {
              date: 1657861531,
              link: 'https://image-service-cdn.seek.com.au/8050efd72ed53e3c231764909ef04bd5d84e782f',
              title: 'Our new office in Cremorne',
            },
            {
              date: 1657861507,
              link: 'https://image-service-cdn.seek.com.au/9707bf991663ffb09c611fe16f1e3cc142796f5d',
              title: 'SEEK HQ',
            },
            {
              date: 1657861497,
              link: 'https://image-service-cdn.seek.com.au/f725757bfaadb123933eee461a3b7537c7be16f0',
              title: 'SEEK HQ',
            },
            {
              date: 1657861484,
              link: 'https://image-service-cdn.seek.com.au/c5a941b4a9bab2dc94cb1c1189dde2210f5d02af',
              title: 'SEEK HQ',
            },
            {
              date: 1657861458,
              link: 'https://image-service-cdn.seek.com.au/ed212d5e4dcb2d5a3ae8ef137677d688c280f031',
              title: 'Midsumma Pride March 2020',
            },
            {
              date: 1657861445,
              link: 'https://image-service-cdn.seek.com.au/10620888e059667cfa2902357d3f17d60c75ced0',
              title: 'Midsumma Pride March 2020',
            },
            {
              date: 1657860643,
              link: 'https://image-service-cdn.seek.com.au/88136ec8aa79c555211ee720ed7c9cd3babe824f',
              title: 'Hackathon 2022',
            },
            {
              date: 1657860630,
              link: 'https://image-service-cdn.seek.com.au/816aa8e1f313927b0b92cce65a6bde5a50560d1f',
              title: 'Hackathon 2022',
            },
            {
              date: 1657860617,
              link: 'https://image-service-cdn.seek.com.au/8e23642ca5822decdc08d591d7c694ed36fb5c4d',
              title: 'Hackathon 2022',
            },
            {
              date: 1657860600,
              link: 'https://image-service-cdn.seek.com.au/1392686cc727fab65ffc42cd3dc526b3a70f15dd',
              title: 'Hackathon 2022',
            },
            {
              date: 1657860588,
              link: 'https://image-service-cdn.seek.com.au/b2bbd878dbede8712dae31e91434c90c07b7fb97',
              title: 'Hackathon 2022',
            },
            {
              date: 1657860575,
              link: 'https://image-service-cdn.seek.com.au/028a8d7f7c1dcaf6aeef8c26bb6f340362965e8e',
              title: 'Hackathon 2022',
            },
            {
              date: 1641365894,
              link: 'https://image-service-cdn.seek.com.au/39d3238cda46d494c5ff6a0bb3a4fc948b9032df',
              title: 'Celebrations at SEEK!',
            },
            {
              date: 1641365871,
              link: 'https://image-service-cdn.seek.com.au/6dd0f6686bdf37f882c34b468804c8354221e1db',
              title: 'Hackathon 2019',
            },
            {
              date: 1641365864,
              link: 'https://image-service-cdn.seek.com.au/0f4d29c5bf5b151c16f2b9e201e197c44ba01621',
              title: 'Hackathon 2019',
            },
            {
              date: 1641365856,
              link: 'https://image-service-cdn.seek.com.au/f87b48e81a81ec51ce94c44d265ece5b0d26352e',
              title: 'Hackathon 2019',
            },
            {
              date: 1641365847,
              link: 'https://image-service-cdn.seek.com.au/6d9e37276d63066d08dba63a8da0e184cd50e858',
              title: 'Hackathon 2019',
            },
            {
              date: 1641365829,
              link: 'https://image-service-cdn.seek.com.au/df5b83687d4e01fd550d46d7dda3c5ae7cf740d2',
              title: 'Hackathon 2019',
            },
            {
              date: 1641365771,
              link: 'https://image-service-cdn.seek.com.au/b8877331ddfa7a6dbb0c26924a53daaf6867ae6c',
              title: 'Hackathon 2019',
            },
          ],
        },
      },
      industry: 'Advertising, Arts & Media',
      mission_statement: [
        'Our purpose is to help people live more fulfilling and productive working lives and help organisations succeed.',
      ],
      number_of_employees_range: '1,001-5,000',
      overview: [
        'SEEK is a global leader in employment marketplaces, solving real problems for our customers and connecting the right work with the right people. Our platforms are used by approximately 50 million candidates worldwide, we have relationships with over 300,000 hirers and exposure to around 900 million people, which means we really know the markets we are operating in.',
        'With an international presence that spans Australia, New Zealand, South-East Asia, Brazil and Mexico, your career opportunities are many at SEEK.',
      ],
      perks_benefits: [
        {
          text: 'Free kick-start breakfast every morning and free coffee, tea and fresh fruit are available all day in our offices.',
          title: 'Free Breakfast',
        },
        {
          text: '14 weeks paid primary carer leave with parental coaching program and support to transition back to work.',
          title: 'Support for parents',
        },
        {
          text: 'Paid volunteer opportunities with our charity partners, and salary deductions direct to our Small Change workplace giving program.',
          title: 'Community Impact',
        },
        {
          text: 'A range of internal and external opportunities to support SEEKers to reach their full potential.',
          title: 'Learning & Development:',
        },
      ],
      should_display_profile: true,
      specialities: [
        'Online Employment Classifieds',
        'Job Boards',
        'Education',
      ],
      website_url: 'https://seek.com.au',
      awards: [
        {
          year: 2022,
          name: 'Best Places to work',
          link: 'https://image-service-cdn.seek.com.au/d396661325772a167de0cd248d7fc4ecdc3c0403',
        },
        {
          year: 2022,
          name: 'Certified carbon neutral for ANZ, Asia & Latin America business operations FY',
          link: 'https://image-service-cdn.seek.com.au/e54df8f98facd86b4e5d76154188b9f4bdf98349',
        },
      ],
      logo_url: DEFAULT_LOGO_URL,
    };
    return mapCompanyProfileModel({
      companyProfileFromGraph: data,
      companyReviewRatingSummary,
      legacyCompanyProfile,
    });
  };

  const getReviews = async ({
    companyId,
    page,
  }: {
    companyId: string;
    page?: number;
  }): Promise<Reviews> => getMockReviews(companyId, page);

  const getCompanyNameSlug = async (
    companySlug: string,
    isLegacyUrl: boolean,
  ): Promise<CompanyNameSlugFromGraph> =>
    getMockCompanyNameSlug(companySlug, isLegacyUrl);

  const getUpvoteIds = async (): Promise<ReviewUpvotesFromGraph> => ({
    upvotes: ['205163'],
  });

  const postUpvoteReview = async (): Promise<
    ReviewUpvotesPayloadFromGraph | undefined
  > => ({
    success: true,
  });

  const postFlagReview = async (): Promise<
    ReviewFlagPayloadFromGraph | undefined
  > => ({
    success: true,
  });

  const getGroupedCompanies = async (): Promise<CompaniesListModel> => [
    {
      name: 'Optus',
      slug: 'optus-432323',
      id: '432323',
    },
    {
      name: 'ANZ',
      slug: 'anz-432303',
      id: '432303',
    },
    {
      name: 'Seek',
      slug: 'seek-432600',
      id: '432600',
    },
    {
      name: 'MYOB',
      slug: 'myob-432833',
      id: '432833',
    },
    {
      name: 'Microsoft',
      slug: 'microsoft-432456',
      id: '432456',
    },
    {
      name: 'UBS',
      slug: 'ubs-432955',
      id: '432955',
    },
    {
      name: 'TAFE NSW',
      slug: 'tafe-nsw-432512',
      id: '432512',
    },
    {
      name: 'Coles Group',
      slug: 'coles-group-433124',
      id: '433124',
    },
    {
      name: 'Here is a company with a pretty long name',
      slug: 'bendigo-n-adelaide-bank-432953',
      id: '432953',
    },
    {
      name: 'HammondCare',
      slug: 'hammondcare-434584',
      id: '434584',
    },
    {
      name: 'Findex',
      slug: 'findex-813706',
      id: '813706',
    },
    {
      name: 'Domain Group',
      slug: 'domain-group-753299',
      id: '753299',
    },
    {
      name: 'Dell',
      slug: 'dell-432499',
      id: '432499',
    },
    {
      name: 'Aruma',
      slug: 'aruma-432975',
      id: '432975',
    },
    {
      name: 'Scentre Group',
      slug: 'scentre-group-752989',
      id: '752989',
    },
  ];

  const getCompanyProfilesByName = async (
    name: string,
    _includeBranding = false,
  ): Promise<SearchCompanyProfileResult> => {
    const profiles = [
      {
        companyId: '432323',
        organisationId: '1111',
        data: {
          name: 'Optus',
          published: true,
        },
        zone: 'anz-1',
      },
      {
        companyId: '432303',
        organisationId: '2222',
        data: {
          name: 'ANZ',
          published: true,
        },
        zone: 'anz-1',
      },
      {
        companyId: '432600',
        organisationId: '3333',
        data: {
          name: 'Seek',
          published: true,
        },
        zone: 'anz-1',
      },
      {
        companyId: '432600',
        organisationId: '3987',
        zone: 'anz-1',
        data: {
          branding: {
            logoImageUrl:
              'https://image-service-cdn.staging.seek.com.au/test-c53f230660ad2d5819cc7fa6d50a6962cbc77586/f3c5292cec0e05e4272d9bf9146f390d366481d0',
          },
          name: 'Seek with logo',
          overview: {
            description: ['Lorem ipsum - test'],
            industry: 'Information & Communication Technology',
            size: '11-50',
            primaryLocation: 'Level 6 541 St Kilda Road, Melbourne, Victoria',
            specialities: [
              'technology',
              'marketing',
              'eCommerce',
              'business',
              'sdasdsada',
            ],
          },
          published: true,
        },
      },
      {
        companyId: ORPHAN_REVIEW_COMPANY_ID,
        organisationId: '358922',
        zone: 'anz-1',
        data: {
          name: 'Seeka',
          overview: {},
          published: false,
        },
      },
      {
        companyId: '438952',
        organisationId: '4202',
        zone: 'anz-1',
        data: {
          branding: {
            logoImageUrl:
              'https://d2ojpxxtu63wzl.cloudfront.net/static/06d998de2cf4dcc2b11aab2654b805ad_384b032e1f2cb592a65ff1ab38cba8ad747b1b12b1ec6becb3cfa822c8ec23d7',
          },
          name: 'Seek Support',
          overview: {
            description: [
              '•Labour Hire •HR & HSEQ Specialists •Audit Preparation •Site and Office Inspections •Recruitment •Marketing Strategies •Admin Support •Certification Support •',
            ],
            website: {
              url: 'www.seeksupport.com.au',
            },
            primaryLocation: 'SE 3\nANTIGUA ST 2\n6169\nSAFETY BAY\nAustralia',
          },
          published: true,
        },
      },
    ];

    const sortedProfiles = profiles.sort((a, b) => {
      const aMatches = a.data.name.toLowerCase() === name.toLowerCase();
      const bMatches = b.data.name.toLowerCase() === name.toLowerCase();

      if (aMatches && !bMatches) return -1;
      if (!aMatches && bMatches) return 1;
      return 0;
    });

    return sortedProfiles as SearchCompanyProfileResult;
  };

  return {
    getCompanyNameSlug,
    getCompanyProfile,
    getCompanyProfileForeignId,
    getJobs,
    getReviews,
    getUpvoteIds,
    postUpvoteReview,
    postFlagReview,
    searchCompanies,
    getGroupedCompanies,
    getCompanyProfilesByName,
  };
};
