import { useEffect, useState } from 'react';
import { useRouteLoaderData, useNavigate, useParams } from 'react-router';
import { AUTHENTICATION_TYPES } from '@seek/apac-candidate-header-footer/types';
import { useMelwaysLink } from '@seek/melways-react';

import {
  PageLoader,
  type ReviewUpvotes,
  type Reviews,
  type SortOrder,
  useConfig,
} from '@seek/libs-shared';

import { useService, type FlagReviewPayload } from '../../../';
import { useAuth } from '../../../shared/providers/authProvider';
import { CompanyProfilesPage } from '../../../paths';
import { ReviewsPage } from './ReviewsPage';
import type { ReviewsTabModel } from './reviewsTabModel';
import { ReviewReplyNotice } from '../../components/reviewReplyNotice/ReviewReplyNotice';
import { SeoStructuredData } from '../../components/seoStructuredData/SeoStructuredData';
import { mapToSeoData } from '../../components/seoStructuredData/mapper';
import { isLocationBasedReviewRoute } from '../../../shared/utils/isLocationBasedReviewRoute';
import type { AppConfig } from '../../../shared/config';
import { mapCompanyReviewLocationsModel } from './mappers';

export function ReviewsPageContainer({
  showReviewReplyNotice,
  trackWriteAReviewClicked,
  trackReviewsPaginationClicked,
  trackSortOrderChanged,
  trackSignInClicked,
  trackRegisterClicked,
}: {
  showReviewReplyNotice: boolean;
  trackRegisterClicked: () => void;
  trackReviewsPaginationClicked: ({
    pageNumber,
  }: {
    pageNumber: number;
  }) => void;
  trackSignInClicked: () => void;
  trackSortOrderChanged: ({ sortOrder }: { sortOrder: SortOrder }) => void;
  trackWriteAReviewClicked: () => void;
}) {
  // TODO: This needs investigation to fix
  const model =
    useRouteLoaderData<ReviewsTabModel>(CompanyProfilesPage.Reviews) ??
    // eslint-disable-next-line react-hooks/rules-of-hooks
    useRouteLoaderData<ReviewsTabModel>(CompanyProfilesPage.ReviewsLocation);

  const { companyProfileService, locationService } = useService();
  const { authenticationStatus, ssoLogin } = useAuth();
  const [newModel, setNewModel] = useState<ReviewsTabModel | undefined>(model);
  const navigate = useNavigate();
  const localize = useMelwaysLink();
  const config = useConfig<AppConfig>();
  const params = useParams();
  const isLocationRoute = isLocationBasedReviewRoute({
    locationSlug: params.locationSlug,
    locationId: config.reviewLocation.details?.locationId,
  });

  const getReviews = async ({
    companyId,
    page,
    sortOrder,
    perPage,
    locationId,
  }: {
    companyId: string;
    page: number;
    sortOrder: SortOrder;
    locationId?: number;
    perPage?: number;
  }): Promise<Reviews> => {
    const resp = await companyProfileService.getReviews({
      companyId,
      page,
      sortOrder,
      perPage,
      locationId,
    });
    return resp;
  };

  const getCompanyReviewLocations = async (variables: {
    companyId: string;
    sort?: boolean;
  }) =>
    mapCompanyReviewLocationsModel(
      await locationService.getCompanyReviewLocations(variables),
    );

  const upvoteReview = async ({
    reviewId,
    companyId,
  }: {
    companyId: string;
    reviewId: string;
  }): Promise<void> => {
    if (
      authenticationStatus === AUTHENTICATION_TYPES.UNAUTHENTICATED &&
      typeof ssoLogin === 'function'
    ) {
      ssoLogin();
    }
    await companyProfileService.postUpvoteReview({
      companyId,
      reviewId,
    });
  };

  const flagReview = async (
    companyId: string,
    reviewId: string,
    payload: FlagReviewPayload,
  ): Promise<boolean> => {
    if (
      authenticationStatus === AUTHENTICATION_TYPES.UNAUTHENTICATED &&
      typeof ssoLogin === 'function'
    ) {
      ssoLogin();
    }

    const response = await companyProfileService.postFlagReview({
      companyId,
      reviewId,
      details: payload.details,
      reason: payload.reason,
    });
    return response?.success || false;
  };

  useEffect(() => {
    if (isLocationRoute && !model?.reviews?.reviews?.length) {
      /**
       ** Navigate to all-company-reviews page if there are no location-based reviews.
       */
      navigate(
        localize({
          language: config.language,
          path: `/companies/${params.companySlug}/reviews${window.location.search}${window.location.hash}`,
        }),
      );
      return;
    }

    const getUpvoteIds = async (companyId: string): Promise<ReviewUpvotes> => {
      let upvotedIds = {} as ReviewUpvotes;

      try {
        upvotedIds = await companyProfileService.getUpvoteIds({
          companyId,
        });
      } catch {
        /* empty */
      }
      return upvotedIds;
    };

    const loadUpvoteIds = async () => {
      const reviews = model?.reviews;
      if (
        authenticationStatus === AUTHENTICATION_TYPES.AUTHENTICATED &&
        reviews
      ) {
        const companyId = reviews.companyId;
        const upvotedIds = await getUpvoteIds(companyId);
        reviews.upvotedIds = upvotedIds;
        setNewModel({ ...model, reviews });
      }
    };
    loadUpvoteIds();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authenticationStatus, companyProfileService, model]);

  if (showReviewReplyNotice) {
    return <ReviewReplyNotice />;
  }

  /**
   ** disable indexing ONLY for location-based reviews page if:
   **  1. There are no reviews.
   **  2. OR Location language is different from site language.
   */
  const disableIndexing =
    isLocationRoute &&
    (!model?.reviews?.reviews?.length ||
      !config.reviewLocation.matchesSiteLanguage);

  return model || newModel ? (
    <>
      <SeoStructuredData
        disableIndexing={disableIndexing}
        data={mapToSeoData(newModel || model)}
      />
      <ReviewsPage
        model={newModel || model}
        showReviewReplyNotice={showReviewReplyNotice}
        getReviews={getReviews}
        getCompanyReviewLocations={getCompanyReviewLocations}
        upvoteReview={upvoteReview}
        flagReview={flagReview}
        trackWriteAReviewClicked={trackWriteAReviewClicked}
        trackReviewsPaginationClicked={trackReviewsPaginationClicked}
        trackSortOrderChanged={trackSortOrderChanged}
        trackSignInClicked={trackSignInClicked}
        trackRegisterClicked={trackRegisterClicked}
        isLocationRoute={isLocationRoute}
      />
    </>
  ) : (
    <PageLoader />
  );
}
