import { v4 as uuid } from 'uuid';

import type { BaseConfig } from '..';

// RFC Required headers
const SEEK_REQUEST_EXPERIENCE_HEADER = 'seek-request-experience';
const SEEK_REQUEST_BRAND_HEADER = 'seek-request-brand';
const SEEK_REQUEST_COUNTRY_HEADER = 'seek-request-country';
const SEEK_REQUEST_SITE_HEADER = 'seek-request-site';
const SEEK_REQUEST_ZONE_HEADER = 'seek-request-zone';
const X_REQUEST_ID = 'x-request-id';

// Candidate GQL header
const X_REAL_IP = 'x-real-ip';

// User-Agent header
const USER_AGENT = 'User-Agent';

export const getHeaders =
  (config: BaseConfig, graphHeaders = true) =>
  (): Record<string, string> => {
    const headers: Record<string, string> = {};

    headers[SEEK_REQUEST_EXPERIENCE_HEADER] = config.experience;
    headers[SEEK_REQUEST_BRAND_HEADER] = config.brand;
    headers[SEEK_REQUEST_COUNTRY_HEADER] = config.country.toLocaleUpperCase();
    headers[SEEK_REQUEST_SITE_HEADER] = config.name;
    headers[SEEK_REQUEST_ZONE_HEADER] = config.zone;
    headers[X_REQUEST_ID] = config.requestId || uuid();
    if (config.clientIp) {
      headers[X_REAL_IP] = config.clientIp;
    }
    if (graphHeaders) {
      if (config.userAgent) {
        headers[USER_AGENT] = config.userAgent;
      }
    }
    return headers;
  };
