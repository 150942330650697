import type { ApolloClient, NormalizedCacheObject } from '@apollo/client';
import type { CompanyReviewsAISummary } from '@seek/libs-shared';

import type { AppConfig } from '../../config';

import { GET_COMPANY_REVIEWS_AI_SUMMARY_QUERY } from './graphqlQueries';

export type CompanyDetailsService = ReturnType<
  typeof createCompanyDetailsService
>;

export const createCompanyDetailsService = ({
  config,
  client,
}: {
  client: ApolloClient<NormalizedCacheObject>;
  config: AppConfig;
}) => {
  const getCompanyReviewsAISummaryById = async (
    companyId: string,
  ): Promise<CompanyReviewsAISummary | null> => {
    const response = await client.query<{
      companyDetails: {
        companyReviewsAISummary: CompanyReviewsAISummary;
      };
    }>({
      query: GET_COMPANY_REVIEWS_AI_SUMMARY_QUERY,
      variables: {
        companyId,
        locale: config.locale,
      },
    });

    return response?.data?.companyDetails?.companyReviewsAISummary ?? null;
  };

  return {
    getCompanyReviewsAISummaryById,
  };
};
