import { useTranslations } from '@vocab/react';

import {
  Box,
  Divider,
  IconAI,
  IconInfo,
  Inline,
  Stack,
  Text,
  TooltipRenderer,
} from 'braid-design-system';
import type { CompanyReviewsAISummary } from '../../../models/CompanyReviewsAISummary';
import translations from './.vocab';
import { useLayoutEffect, useState } from 'react';

interface AIGenSectionProps {
  companyName: string;
  companyReviewsAISummary?: CompanyReviewsAISummary;
}

const getDisplayYearRange = (fromYear?: number, toYear?: number) => {
  if (!fromYear || !toYear) {
    return '';
  } else if (fromYear === toYear) {
    return `(${fromYear})`;
  } else {
    return `(${fromYear} - ${toYear})`;
  }
};

export const AIReviewsSummary = ({
  companyName,
  companyReviewsAISummary,
}: AIGenSectionProps) => {
  useLayoutEffect(() => {
    setMounted(true);
  }, []);

  const { t } = useTranslations(translations);
  const [mounted, setMounted] = useState(false);

  if (!mounted || !companyReviewsAISummary) {
    return null;
  }

  const { reviewsCount, reviewsDateFrom, reviewsDateTo, aISummaryContent } =
    companyReviewsAISummary;

  const { positiveAndChallengesSummary } = aISummaryContent || {};

  const fromYear = reviewsDateFrom
    ? new Date(reviewsDateFrom).getFullYear()
    : undefined;

  const toYear = reviewsDateTo
    ? new Date(reviewsDateTo).getFullYear()
    : undefined;

  return (
    <Stack space="large">
      <Divider />
      <Stack space="small">
        <Text size="large" weight="strong">
          {t('What do people say ...', { companyName })}
        </Text>
        <TooltipRenderer
          id="tooltip-ai-gen-summary"
          placement="bottom"
          tooltip={
            <Text>
              {t('AI summary tooltips', {
                reviewsCount: reviewsCount.toString(),
                reviewsDateRange: getDisplayYearRange(fromYear, toYear),
              })}
            </Text>
          }
        >
          {({ triggerProps }) => (
            <Inline space="xxsmall" alignY="center">
              <Text size="small" weight="strong" tone="promote">
                <IconAI />
                {t('AI summary of recent reviews')}
              </Text>
              <Box aria-label="Info" {...triggerProps}>
                <IconInfo size="xsmall" tone="promote" />
              </Box>
            </Inline>
          )}
        </TooltipRenderer>
        <Box>
          <Text weight="regular" size="standard">
            <p
              dangerouslySetInnerHTML={{
                __html: positiveAndChallengesSummary as string,
              }}
            />
          </Text>
        </Box>
      </Stack>
    </Stack>
  );
};
