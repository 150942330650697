import { useTranslations } from '@vocab/react';
import {
  Badge,
  Box,
  Column,
  Columns,
  Heading,
  Hidden,
  Stack,
  Text,
} from 'braid-design-system';
import translations from './.vocab';

import { useConfig } from '@seek/libs-shared';
import { CompanySearchBar } from '../companySearchBar/CompanySearchBar';
import * as styles from './HomePageBanner.css';

export function HomePageBanner() {
  const { t } = useTranslations(translations);

  const { country } = useConfig();

  return (
    <Box
      className={styles.wrapper}
      borderRadius={['none', 'xlarge']}
      background="brand"
      padding="xlarge"
    >
      <Columns
        space={{ mobile: 'none', tablet: 'xxlarge' }}
        alignY="center"
        collapseBelow="desktop"
      >
        <Column>
          <Box className={styles.innerWrapper}>
            <Stack space="large">
              <Heading level="1">{t('Find the right company for you')}</Heading>
              <Text size="large">
                {t(
                  'Everything you need to know about a company, all in one place',
                )}
              </Text>
              <CompanySearchBar />
              {country === 'nz' && (
                <Columns space={{ mobile: 'small' }} alignY={'center'}>
                  <Column width="content">
                    <Badge>BETA</Badge>
                  </Column>
                  <Column>
                    <Text size="large">{t('More companies added daily')}</Text>
                  </Column>
                </Columns>
              )}
            </Stack>
          </Box>
        </Column>
        <Hidden below="desktop">
          <Column>
            <Box display={{ mobile: 'none', desktop: 'flex' }}>
              <img
                src="https://image-service-cdn.seek.com.au/309fe98168ffe880c202528ddf1f363d98f039ba/"
                alt="landing page"
                width="100%"
              />
            </Box>
          </Column>
        </Hidden>
      </Columns>
    </Box>
  );
}
