import {
  sendViewEvent,
  type BaseConfig,
  EventNames,
  CurrentPage,
  SiteSection,
  CurrentPageSection,
} from '@seek/libs-shared';

interface CommonInputType {
  config: BaseConfig;
  context: AppContext;
}
interface SendEventInputType extends CommonInputType {
  data: Record<string, unknown>;
  eventName: string;
}

interface AppContext {
  advertiserId?: string;
  companyId?: string;
  companyName?: string;
  isLoggedIn?: boolean;
  userId?: string;
}

const sendEvent = ({
  config,
  context,
  eventName,
  data,
}: SendEventInputType) => {
  sendViewEvent(eventName, config, {
    ...data,
    isLoggedIn: Boolean(context.userId),
    ...(context.userId ? { loginId: context.userId } : {}),
  });
};

const homePage = ({ config, context }: CommonInputType) => {
  sendEvent({
    config,
    context,
    eventName: EventNames.COMPANY_PROFILE_DISPLAYED,
    data: {
      currentPage: CurrentPage.COMPANY_OVERVIEW,
      siteSection: SiteSection.DISCOVER,
    },
  });
};

interface SearchResultsPageInputType extends CommonInputType {
  companyCount: number;
  searchKeyword: string;
}

const searchResultsPage = ({
  config,
  context,
  searchKeyword,
  companyCount,
}: SearchResultsPageInputType) => {
  sendEvent({
    config,
    context,
    eventName: EventNames.SEARCH_RESULTS_DISPLAYED,
    data: {
      currentPage: CurrentPage.COMPANY_OVERVIEW_SEARCH_RESULTS,
      siteSection: SiteSection.DISCOVER,
      searchKeyword,
      companyCount,
    },
  });
};

interface aboutInputType extends CommonInputType {
  isAiReviewSummaryShown: boolean;
}
const aboutPage = ({ config, context, isAiReviewSummaryShown }: aboutInputType) => {
  sendEvent({
    config,
    context,
    eventName: EventNames.COMPANY_PROFILE_DISPLAYED,
    data: {
      currentPage: CurrentPage.COMPANY_PROFILE,
      currentPageSection: CurrentPageSection.ABOUT,
      siteSection: SiteSection.COMPANY_PROFILE,
      companyId: context.companyId,
      companyName: context.companyName,
      isAiReviewSummaryShown,
    },
  });
};

const culturePage = ({ config, context }: CommonInputType) => {
  sendEvent({
    config,
    context,
    eventName: EventNames.COMPANY_PROFILE_DISPLAYED,
    data: {
      currentPage: CurrentPage.COMPANY_PROFILE,
      currentPageSection: CurrentPageSection.CULTURE,
      siteSection: SiteSection.COMPANY_PROFILE,
      companyId: context.companyId,
      companyName: context.companyName,
    },
  });
};

interface JobsInputType extends CommonInputType {
  searchTotalCount: number;
}

const jobsPage = ({ config, context, searchTotalCount }: JobsInputType) => {
  sendEvent({
    config,
    context,
    eventName: EventNames.COMPANY_PROFILE_DISPLAYED,
    data: {
      currentPage: CurrentPage.COMPANY_PROFILE,
      currentPageSection: CurrentPageSection.JOBS,
      siteSection: SiteSection.COMPANY_PROFILE,
      companyId: context.companyId,
      companyName: context.companyName,
      searchTotalCount,
    },
  });
};

const reviewsPage = ({ config, context }: CommonInputType) => {
  sendEvent({
    config,
    context,
    eventName: EventNames.COMPANY_PROFILE_DISPLAYED,
    data: {
      currentPage: CurrentPage.COMPANY_PROFILE,
      currentPageSection: CurrentPageSection.REVIEWS,
      siteSection: SiteSection.COMPANY_PROFILE,
      companyId: context.companyId,
      companyName: context.companyName,
    },
  });
};

export const trackViewEvents = {
  homePage,
  searchResultsPage,
  aboutPage,
  culturePage,
  jobsPage,
  reviewsPage,
};
