import { useTranslations } from '@vocab/react';
import {
  StarSelector,
  type Rating,
} from '../../components/StarSelector/StarSelector';
import type { QuestionProps } from '../../type';
import translations from './.vocab';

export const ExecutiveManagementRatingQuestion = (
  props: QuestionProps<Rating>,
) => {
  const { t } = useTranslations(translations);
  return (
    <StarSelector
      id="executiveManagementRating"
      {...props}
      title={t('Management')}
    />
  );
};
