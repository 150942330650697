import { useTranslations } from '@vocab/react';
import { Card, Loader, Stack, Text, TextLink } from 'braid-design-system';

import {
  buildCommunityGuidelinesURI,
  type CompanyProfile,
  ReviewSummaryBreakdown,
  Section,
  useConfig,
} from '@seek/libs-shared';
import translations from './.vocab';

import { ReviewsEmptyState } from '../reviewsEmptyState/ReviewsEmptyState';

import { ReviewsSummary } from '../reviewsSummary/ReviewsSummary';

export type ReviewsSummarySectionModel = Pick<
  CompanyProfile,
  | 'companyReviewSummaryBreakdown'
  | 'employeeRecommendationRating'
  | 'name'
  | 'overallRating'
  | 'ratingBreakdown'
  | 'salaryRating'
>;
interface Props {
  model: ReviewsSummarySectionModel;
  trackWriteAReviewClicked: () => void;
  link?: string;
  loading?: boolean;
}

export function ReviewsSummarySection({
  model,
  loading,
  trackWriteAReviewClicked,
}: Props) {
  const { t } = useTranslations(translations);
  const { locale, zone, experience } = useConfig();
  if (loading || !model) {
    return <Loader />;
  }

  const communityGuidelineURL = buildCommunityGuidelinesURI({
    locale,
    zone,
    experience,
  });
  const hasMinimumReviews = model?.overallRating;

  if (!hasMinimumReviews) {
    return (
      <ReviewsEmptyState
        companyName={model.name}
        wrapInCard
        trackWriteAReviewClicked={trackWriteAReviewClicked}
        type="NO_SUMMARY"
      />
    );
  }
  return (
    <Section
      loading={loading}
      heading={t('Working at {company_name}', { company_name: model.name })}
    >
      <Stack space="large">
        <Stack space="medium">
          <Stack space="small">
            <Card>
              <ReviewsSummary model={model} />
            </Card>
            <Card>
              <ReviewSummaryBreakdown
                model={model.companyReviewSummaryBreakdown}
              />
            </Card>
          </Stack>
          <Text align="left" size="xsmall">
            {t('Community guideline description', {
              companyName: model.name,
              TextLink: (v: string) => (
                <TextLink href={communityGuidelineURL} rel="nofollow">
                  {v}
                </TextLink>
              ),
            })}
          </Text>
        </Stack>
      </Stack>
    </Section>
  );
}
