import {
  list as allLocationList,
  type LocationLanguage,
} from '@seek/location-data';
import { isMelwaysCountry } from '@seek/melways-sites';


import { getLanguagesByCountryCode } from '../../utils/getLanguagesByCountryCode';
import { slugifyLocation } from '../../utils/reviewLocationSlug';

import { isTargetLocation } from './helpers/isTargetLocation';

export interface LocationDetails {
  contextualNames: {
    en: string;
    id?: string;
    th?: string;
  };
  locationId: number;
  locationLanguage: LocationLanguage;
  slugs: {
    en: string;
    id?: string;
    th?: string;
  };
}

type LocationSlugs = Record<string, LocationDetails>;

const locationSlugFactory = () => {
  let locationSlugs: LocationSlugs | undefined;

  const generateLocationSlugs = (): LocationSlugs => {
    if (!locationSlugs) {
      locationSlugs = {} as unknown as LocationSlugs;
      for (const location of allLocationList) {
        const countryCode = location.country?.countryCode?.toLowerCase();

        if (!countryCode || !isMelwaysCountry(countryCode)) {
          continue;
        }

        if (
          !isTargetLocation({
            locationKind: location.kind,
            country: countryCode,
          })
        ) {
          continue;
        }

        const locationLanguages = getLanguagesByCountryCode(countryCode);

        for (const outerLanguage of locationLanguages) {
          const term = location.contextualName(
            outerLanguage,
            countryCode.toUpperCase(),
          );

          const slug = slugifyLocation({
            locationContextualName: term,
            mustBeDecoded: true,
          });
          locationSlugs[slug] = {
            locationId: location.shortId,
            locationLanguage: outerLanguage,
            contextualNames: { en: '' },
            slugs: { en: '' },
          };
          for (const innerLanguage of locationLanguages) {
            const innerTerm = location.contextualName(
              innerLanguage,
              countryCode.toUpperCase(),
            );
            const innerSlug = slugifyLocation({
              locationContextualName: innerTerm,
              mustBeDecoded: true,
            });

            locationSlugs[slug] = {
              ...(locationSlugs[slug] ?? {}),
              contextualNames: {
                ...(locationSlugs[slug]?.contextualNames ?? {}),
                [innerLanguage]: innerTerm,
              },
              slugs: {
                ...(locationSlugs[slug]?.slugs ?? {}),
                [innerLanguage]: innerSlug,
              },
            };
          }
        }
      }
    }

    return locationSlugs;
  };

  return { generateLocationSlugs };
};

export const createLocationSlugService = () => {
  const factory = locationSlugFactory();
  const locationSlugs = factory.generateLocationSlugs();

  const getLocationByLocationSlug = (
    locationSlug: string,
  ): LocationDetails | undefined => locationSlugs[locationSlug] ?? undefined;

  return { getLocationByLocationSlug };
};

export type LocationSlugService = ReturnType<typeof createLocationSlugService>;
