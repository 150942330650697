
      import { createLanguage, createTranslationFile } from '@vocab/webpack/web';
      
    const translations = createTranslationFile({
      "en": createLanguage(
        require.resolveWeak("./translations.json-en-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJJbnZhbGlkIHdvcmsgaGlzdG9yeSI6IkludmFsaWQgd29yayBoaXN0b3J5IiwiU2VsZWN0IGFuIGFtb3VudCI6IlNlbGVjdCBhbiBhbW91bnQiLCJGaW5pc2ggZGF0ZSBtdXN0IGJlIGFmdGVyIHN0YXJ0IGRhdGUiOiJGaW5pc2ggZGF0ZSBtdXN0IGJlIGFmdGVyIHN0YXJ0IGRhdGUiLCJFbnRlciBhIG1vbnRoIGFuZCB5ZWFyIjoiRW50ZXIgYSBtb250aCBhbmQgeWVhciIsIk1ha2UgYSBzZWxlY3Rpb24iOiJNYWtlIGEgc2VsZWN0aW9uIiwiUmVtb3ZlIHRoZSBmb2xsb3dpbmcgaW5hcHByb3ByaWF0ZSB3b3Jkczoge3dvcmRzfSI6IlJlbW92ZSB0aGUgZm9sbG93aW5nIGluYXBwcm9wcmlhdGUgd29yZHM6IHt3b3Jkc30ifQ%3D%3D!"),
        () => import(
          /* webpackChunkName: "en-translations" */
          "./translations.json-en-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJJbnZhbGlkIHdvcmsgaGlzdG9yeSI6IkludmFsaWQgd29yayBoaXN0b3J5IiwiU2VsZWN0IGFuIGFtb3VudCI6IlNlbGVjdCBhbiBhbW91bnQiLCJGaW5pc2ggZGF0ZSBtdXN0IGJlIGFmdGVyIHN0YXJ0IGRhdGUiOiJGaW5pc2ggZGF0ZSBtdXN0IGJlIGFmdGVyIHN0YXJ0IGRhdGUiLCJFbnRlciBhIG1vbnRoIGFuZCB5ZWFyIjoiRW50ZXIgYSBtb250aCBhbmQgeWVhciIsIk1ha2UgYSBzZWxlY3Rpb24iOiJNYWtlIGEgc2VsZWN0aW9uIiwiUmVtb3ZlIHRoZSBmb2xsb3dpbmcgaW5hcHByb3ByaWF0ZSB3b3Jkczoge3dvcmRzfSI6IlJlbW92ZSB0aGUgZm9sbG93aW5nIGluYXBwcm9wcmlhdGUgd29yZHM6IHt3b3Jkc30ifQ%3D%3D!"
        )
      ),
"th": createLanguage(
        require.resolveWeak("./translations.json-th-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJJbnZhbGlkIHdvcmsgaGlzdG9yeSI6IuC4m%2BC4o%2BC4sOC4p%2BC4seC4leC4tOC4geC4suC4o%2BC4l%2BC4s%2BC4h%2BC4suC4meC4l%2BC4teC5iOC5hOC4oeC5iOC4luC4ueC4geC4leC5ieC4reC4hyIsIlNlbGVjdCBhbiBhbW91bnQiOiLguYDguKXguLfguK3guIHguIjguLPguJnguKfguJnguYDguIfguLTguJkiLCJGaW5pc2ggZGF0ZSBtdXN0IGJlIGFmdGVyIHN0YXJ0IGRhdGUiOiLguKfguLHguJnguJfguLXguYjguKrguLTguYnguJnguKrguLjguJTguJXguYnguK3guIfguK3guKLguLnguYjguKvguKXguLHguIfguIjguLLguIHguKfguLHguJnguJfguLXguYjguYDguKPguLTguYjguKHguJXguYnguJkiLCJFbnRlciBhIG1vbnRoIGFuZCB5ZWFyIjoi4LiB4Lij4Li44LiT4Liy4Lij4Liw4Lia4Li44LmA4LiU4Li34Lit4LiZ4LmB4Lil4Liw4Lib4Li1IiwiTWFrZSBhIHNlbGVjdGlvbiI6IuC4geC4o%2BC4uOC4k%2BC4suC5gOC4peC4t%2BC4reC4gSIsIlJlbW92ZSB0aGUgZm9sbG93aW5nIGluYXBwcm9wcmlhdGUgd29yZHM6IHt3b3Jkc30iOiLguKXguJrguITguLPguJfguLXguYjguYTguKHguYjguYDguKvguKHguLLguLDguKrguKHguJXguYjguK3guYTguJvguJnguLXguYk6IHt3b3Jkc30ifQ%3D%3D!"),
        () => import(
          /* webpackChunkName: "th-translations" */
          "./translations.json-th-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJJbnZhbGlkIHdvcmsgaGlzdG9yeSI6IuC4m%2BC4o%2BC4sOC4p%2BC4seC4leC4tOC4geC4suC4o%2BC4l%2BC4s%2BC4h%2BC4suC4meC4l%2BC4teC5iOC5hOC4oeC5iOC4luC4ueC4geC4leC5ieC4reC4hyIsIlNlbGVjdCBhbiBhbW91bnQiOiLguYDguKXguLfguK3guIHguIjguLPguJnguKfguJnguYDguIfguLTguJkiLCJGaW5pc2ggZGF0ZSBtdXN0IGJlIGFmdGVyIHN0YXJ0IGRhdGUiOiLguKfguLHguJnguJfguLXguYjguKrguLTguYnguJnguKrguLjguJTguJXguYnguK3guIfguK3guKLguLnguYjguKvguKXguLHguIfguIjguLLguIHguKfguLHguJnguJfguLXguYjguYDguKPguLTguYjguKHguJXguYnguJkiLCJFbnRlciBhIG1vbnRoIGFuZCB5ZWFyIjoi4LiB4Lij4Li44LiT4Liy4Lij4Liw4Lia4Li44LmA4LiU4Li34Lit4LiZ4LmB4Lil4Liw4Lib4Li1IiwiTWFrZSBhIHNlbGVjdGlvbiI6IuC4geC4o%2BC4uOC4k%2BC4suC5gOC4peC4t%2BC4reC4gSIsIlJlbW92ZSB0aGUgZm9sbG93aW5nIGluYXBwcm9wcmlhdGUgd29yZHM6IHt3b3Jkc30iOiLguKXguJrguITguLPguJfguLXguYjguYTguKHguYjguYDguKvguKHguLLguLDguKrguKHguJXguYjguK3guYTguJvguJnguLXguYk6IHt3b3Jkc30ifQ%3D%3D!"
        )
      ),
"id": createLanguage(
        require.resolveWeak("./translations.json-id-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJJbnZhbGlkIHdvcmsgaGlzdG9yeSI6IlJpd2F5YXQgcGVrZXJqYWFuIHRpZGFrIHZhbGlkIiwiU2VsZWN0IGFuIGFtb3VudCI6IlBpbGloIG5vbWluYWwiLCJGaW5pc2ggZGF0ZSBtdXN0IGJlIGFmdGVyIHN0YXJ0IGRhdGUiOiJUYW5nZ2FsIHNlbGVzYWkgaGFydXMgc2V0ZWxhaCB0YW5nZ2FsIG11bGFpIiwiRW50ZXIgYSBtb250aCBhbmQgeWVhciI6Ik1hc3Vra2FuIGJ1bGFuIGRhbiB0YWh1biIsIk1ha2UgYSBzZWxlY3Rpb24iOiJTaWxha2FuIHBpbGloIiwiUmVtb3ZlIHRoZSBmb2xsb3dpbmcgaW5hcHByb3ByaWF0ZSB3b3Jkczoge3dvcmRzfSI6Ik1vaG9uIGhhcHVzIGthdGEta2F0YSBrdXJhbmcgcGFudGFzIGJlcmlrdXQ6IHt3b3Jkc30ifQ%3D%3D!"),
        () => import(
          /* webpackChunkName: "id-translations" */
          "./translations.json-id-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJJbnZhbGlkIHdvcmsgaGlzdG9yeSI6IlJpd2F5YXQgcGVrZXJqYWFuIHRpZGFrIHZhbGlkIiwiU2VsZWN0IGFuIGFtb3VudCI6IlBpbGloIG5vbWluYWwiLCJGaW5pc2ggZGF0ZSBtdXN0IGJlIGFmdGVyIHN0YXJ0IGRhdGUiOiJUYW5nZ2FsIHNlbGVzYWkgaGFydXMgc2V0ZWxhaCB0YW5nZ2FsIG11bGFpIiwiRW50ZXIgYSBtb250aCBhbmQgeWVhciI6Ik1hc3Vra2FuIGJ1bGFuIGRhbiB0YWh1biIsIk1ha2UgYSBzZWxlY3Rpb24iOiJTaWxha2FuIHBpbGloIiwiUmVtb3ZlIHRoZSBmb2xsb3dpbmcgaW5hcHByb3ByaWF0ZSB3b3Jkczoge3dvcmRzfSI6Ik1vaG9uIGhhcHVzIGthdGEta2F0YSBrdXJhbmcgcGFudGFzIGJlcmlrdXQ6IHt3b3Jkc30ifQ%3D%3D!"
        )
      ),
"zh": createLanguage(
        require.resolveWeak("./translations.json-zh-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJJbnZhbGlkIHdvcmsgaGlzdG9yeSI6IkludmFsaWQgd29yayBoaXN0b3J5IiwiU2VsZWN0IGFuIGFtb3VudCI6IlNlbGVjdCBhbiBhbW91bnQiLCJGaW5pc2ggZGF0ZSBtdXN0IGJlIGFmdGVyIHN0YXJ0IGRhdGUiOiJGaW5pc2ggZGF0ZSBtdXN0IGJlIGFmdGVyIHN0YXJ0IGRhdGUiLCJFbnRlciBhIG1vbnRoIGFuZCB5ZWFyIjoiRW50ZXIgYSBtb250aCBhbmQgeWVhciIsIk1ha2UgYSBzZWxlY3Rpb24iOiJNYWtlIGEgc2VsZWN0aW9uIiwiUmVtb3ZlIHRoZSBmb2xsb3dpbmcgaW5hcHByb3ByaWF0ZSB3b3Jkczoge3dvcmRzfSI6IlJlbW92ZSB0aGUgZm9sbG93aW5nIGluYXBwcm9wcmlhdGUgd29yZHM6IHt3b3Jkc30ifQ%3D%3D!"),
        () => import(
          /* webpackChunkName: "zh-translations" */
          "./translations.json-zh-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJJbnZhbGlkIHdvcmsgaGlzdG9yeSI6IkludmFsaWQgd29yayBoaXN0b3J5IiwiU2VsZWN0IGFuIGFtb3VudCI6IlNlbGVjdCBhbiBhbW91bnQiLCJGaW5pc2ggZGF0ZSBtdXN0IGJlIGFmdGVyIHN0YXJ0IGRhdGUiOiJGaW5pc2ggZGF0ZSBtdXN0IGJlIGFmdGVyIHN0YXJ0IGRhdGUiLCJFbnRlciBhIG1vbnRoIGFuZCB5ZWFyIjoiRW50ZXIgYSBtb250aCBhbmQgeWVhciIsIk1ha2UgYSBzZWxlY3Rpb24iOiJNYWtlIGEgc2VsZWN0aW9uIiwiUmVtb3ZlIHRoZSBmb2xsb3dpbmcgaW5hcHByb3ByaWF0ZSB3b3Jkczoge3dvcmRzfSI6IlJlbW92ZSB0aGUgZm9sbG93aW5nIGluYXBwcm9wcmlhdGUgd29yZHM6IHt3b3Jkc30ifQ%3D%3D!"
        )
      ),
"en-AU": createLanguage(
        require.resolveWeak("./translations.json-en-AU-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJJbnZhbGlkIHdvcmsgaGlzdG9yeSI6IkludmFsaWQgd29yayBoaXN0b3J5IiwiU2VsZWN0IGFuIGFtb3VudCI6IlNlbGVjdCBhbiBhbW91bnQiLCJGaW5pc2ggZGF0ZSBtdXN0IGJlIGFmdGVyIHN0YXJ0IGRhdGUiOiJGaW5pc2ggZGF0ZSBtdXN0IGJlIGFmdGVyIHN0YXJ0IGRhdGUiLCJFbnRlciBhIG1vbnRoIGFuZCB5ZWFyIjoiRW50ZXIgYSBtb250aCBhbmQgeWVhciIsIk1ha2UgYSBzZWxlY3Rpb24iOiJNYWtlIGEgc2VsZWN0aW9uIiwiUmVtb3ZlIHRoZSBmb2xsb3dpbmcgaW5hcHByb3ByaWF0ZSB3b3Jkczoge3dvcmRzfSI6IlJlbW92ZSB0aGUgZm9sbG93aW5nIGluYXBwcm9wcmlhdGUgd29yZHM6IHt3b3Jkc30ifQ%3D%3D!"),
        () => import(
          /* webpackChunkName: "en-AU-translations" */
          "./translations.json-en-AU-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJJbnZhbGlkIHdvcmsgaGlzdG9yeSI6IkludmFsaWQgd29yayBoaXN0b3J5IiwiU2VsZWN0IGFuIGFtb3VudCI6IlNlbGVjdCBhbiBhbW91bnQiLCJGaW5pc2ggZGF0ZSBtdXN0IGJlIGFmdGVyIHN0YXJ0IGRhdGUiOiJGaW5pc2ggZGF0ZSBtdXN0IGJlIGFmdGVyIHN0YXJ0IGRhdGUiLCJFbnRlciBhIG1vbnRoIGFuZCB5ZWFyIjoiRW50ZXIgYSBtb250aCBhbmQgeWVhciIsIk1ha2UgYSBzZWxlY3Rpb24iOiJNYWtlIGEgc2VsZWN0aW9uIiwiUmVtb3ZlIHRoZSBmb2xsb3dpbmcgaW5hcHByb3ByaWF0ZSB3b3Jkczoge3dvcmRzfSI6IlJlbW92ZSB0aGUgZm9sbG93aW5nIGluYXBwcm9wcmlhdGUgd29yZHM6IHt3b3Jkc30ifQ%3D%3D!"
        )
      ),
"en-HK": createLanguage(
        require.resolveWeak("./translations.json-en-HK-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJJbnZhbGlkIHdvcmsgaGlzdG9yeSI6IkludmFsaWQgd29yayBoaXN0b3J5IiwiU2VsZWN0IGFuIGFtb3VudCI6IlNlbGVjdCBhbiBhbW91bnQiLCJGaW5pc2ggZGF0ZSBtdXN0IGJlIGFmdGVyIHN0YXJ0IGRhdGUiOiJGaW5pc2ggZGF0ZSBtdXN0IGJlIGFmdGVyIHN0YXJ0IGRhdGUiLCJFbnRlciBhIG1vbnRoIGFuZCB5ZWFyIjoiRW50ZXIgYSBtb250aCBhbmQgeWVhciIsIk1ha2UgYSBzZWxlY3Rpb24iOiJNYWtlIGEgc2VsZWN0aW9uIiwiUmVtb3ZlIHRoZSBmb2xsb3dpbmcgaW5hcHByb3ByaWF0ZSB3b3Jkczoge3dvcmRzfSI6IlJlbW92ZSB0aGUgZm9sbG93aW5nIGluYXBwcm9wcmlhdGUgd29yZHM6IHt3b3Jkc30ifQ%3D%3D!"),
        () => import(
          /* webpackChunkName: "en-HK-translations" */
          "./translations.json-en-HK-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJJbnZhbGlkIHdvcmsgaGlzdG9yeSI6IkludmFsaWQgd29yayBoaXN0b3J5IiwiU2VsZWN0IGFuIGFtb3VudCI6IlNlbGVjdCBhbiBhbW91bnQiLCJGaW5pc2ggZGF0ZSBtdXN0IGJlIGFmdGVyIHN0YXJ0IGRhdGUiOiJGaW5pc2ggZGF0ZSBtdXN0IGJlIGFmdGVyIHN0YXJ0IGRhdGUiLCJFbnRlciBhIG1vbnRoIGFuZCB5ZWFyIjoiRW50ZXIgYSBtb250aCBhbmQgeWVhciIsIk1ha2UgYSBzZWxlY3Rpb24iOiJNYWtlIGEgc2VsZWN0aW9uIiwiUmVtb3ZlIHRoZSBmb2xsb3dpbmcgaW5hcHByb3ByaWF0ZSB3b3Jkczoge3dvcmRzfSI6IlJlbW92ZSB0aGUgZm9sbG93aW5nIGluYXBwcm9wcmlhdGUgd29yZHM6IHt3b3Jkc30ifQ%3D%3D!"
        )
      ),
"en-ID": createLanguage(
        require.resolveWeak("./translations.json-en-ID-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJJbnZhbGlkIHdvcmsgaGlzdG9yeSI6IkludmFsaWQgd29yayBoaXN0b3J5IiwiU2VsZWN0IGFuIGFtb3VudCI6IlNlbGVjdCBhbiBhbW91bnQiLCJGaW5pc2ggZGF0ZSBtdXN0IGJlIGFmdGVyIHN0YXJ0IGRhdGUiOiJGaW5pc2ggZGF0ZSBtdXN0IGJlIGFmdGVyIHN0YXJ0IGRhdGUiLCJFbnRlciBhIG1vbnRoIGFuZCB5ZWFyIjoiRW50ZXIgYSBtb250aCBhbmQgeWVhciIsIk1ha2UgYSBzZWxlY3Rpb24iOiJNYWtlIGEgc2VsZWN0aW9uIiwiUmVtb3ZlIHRoZSBmb2xsb3dpbmcgaW5hcHByb3ByaWF0ZSB3b3Jkczoge3dvcmRzfSI6IlJlbW92ZSB0aGUgZm9sbG93aW5nIGluYXBwcm9wcmlhdGUgd29yZHM6IHt3b3Jkc30ifQ%3D%3D!"),
        () => import(
          /* webpackChunkName: "en-ID-translations" */
          "./translations.json-en-ID-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJJbnZhbGlkIHdvcmsgaGlzdG9yeSI6IkludmFsaWQgd29yayBoaXN0b3J5IiwiU2VsZWN0IGFuIGFtb3VudCI6IlNlbGVjdCBhbiBhbW91bnQiLCJGaW5pc2ggZGF0ZSBtdXN0IGJlIGFmdGVyIHN0YXJ0IGRhdGUiOiJGaW5pc2ggZGF0ZSBtdXN0IGJlIGFmdGVyIHN0YXJ0IGRhdGUiLCJFbnRlciBhIG1vbnRoIGFuZCB5ZWFyIjoiRW50ZXIgYSBtb250aCBhbmQgeWVhciIsIk1ha2UgYSBzZWxlY3Rpb24iOiJNYWtlIGEgc2VsZWN0aW9uIiwiUmVtb3ZlIHRoZSBmb2xsb3dpbmcgaW5hcHByb3ByaWF0ZSB3b3Jkczoge3dvcmRzfSI6IlJlbW92ZSB0aGUgZm9sbG93aW5nIGluYXBwcm9wcmlhdGUgd29yZHM6IHt3b3Jkc30ifQ%3D%3D!"
        )
      ),
"en-MY": createLanguage(
        require.resolveWeak("./translations.json-en-MY-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJJbnZhbGlkIHdvcmsgaGlzdG9yeSI6IkludmFsaWQgd29yayBoaXN0b3J5IiwiU2VsZWN0IGFuIGFtb3VudCI6IlNlbGVjdCBhbiBhbW91bnQiLCJGaW5pc2ggZGF0ZSBtdXN0IGJlIGFmdGVyIHN0YXJ0IGRhdGUiOiJGaW5pc2ggZGF0ZSBtdXN0IGJlIGFmdGVyIHN0YXJ0IGRhdGUiLCJFbnRlciBhIG1vbnRoIGFuZCB5ZWFyIjoiRW50ZXIgYSBtb250aCBhbmQgeWVhciIsIk1ha2UgYSBzZWxlY3Rpb24iOiJNYWtlIGEgc2VsZWN0aW9uIiwiUmVtb3ZlIHRoZSBmb2xsb3dpbmcgaW5hcHByb3ByaWF0ZSB3b3Jkczoge3dvcmRzfSI6IlJlbW92ZSB0aGUgZm9sbG93aW5nIGluYXBwcm9wcmlhdGUgd29yZHM6IHt3b3Jkc30ifQ%3D%3D!"),
        () => import(
          /* webpackChunkName: "en-MY-translations" */
          "./translations.json-en-MY-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJJbnZhbGlkIHdvcmsgaGlzdG9yeSI6IkludmFsaWQgd29yayBoaXN0b3J5IiwiU2VsZWN0IGFuIGFtb3VudCI6IlNlbGVjdCBhbiBhbW91bnQiLCJGaW5pc2ggZGF0ZSBtdXN0IGJlIGFmdGVyIHN0YXJ0IGRhdGUiOiJGaW5pc2ggZGF0ZSBtdXN0IGJlIGFmdGVyIHN0YXJ0IGRhdGUiLCJFbnRlciBhIG1vbnRoIGFuZCB5ZWFyIjoiRW50ZXIgYSBtb250aCBhbmQgeWVhciIsIk1ha2UgYSBzZWxlY3Rpb24iOiJNYWtlIGEgc2VsZWN0aW9uIiwiUmVtb3ZlIHRoZSBmb2xsb3dpbmcgaW5hcHByb3ByaWF0ZSB3b3Jkczoge3dvcmRzfSI6IlJlbW92ZSB0aGUgZm9sbG93aW5nIGluYXBwcm9wcmlhdGUgd29yZHM6IHt3b3Jkc30ifQ%3D%3D!"
        )
      ),
"en-NZ": createLanguage(
        require.resolveWeak("./translations.json-en-NZ-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJJbnZhbGlkIHdvcmsgaGlzdG9yeSI6IkludmFsaWQgd29yayBoaXN0b3J5IiwiU2VsZWN0IGFuIGFtb3VudCI6IlNlbGVjdCBhbiBhbW91bnQiLCJGaW5pc2ggZGF0ZSBtdXN0IGJlIGFmdGVyIHN0YXJ0IGRhdGUiOiJGaW5pc2ggZGF0ZSBtdXN0IGJlIGFmdGVyIHN0YXJ0IGRhdGUiLCJFbnRlciBhIG1vbnRoIGFuZCB5ZWFyIjoiRW50ZXIgYSBtb250aCBhbmQgeWVhciIsIk1ha2UgYSBzZWxlY3Rpb24iOiJNYWtlIGEgc2VsZWN0aW9uIiwiUmVtb3ZlIHRoZSBmb2xsb3dpbmcgaW5hcHByb3ByaWF0ZSB3b3Jkczoge3dvcmRzfSI6IlJlbW92ZSB0aGUgZm9sbG93aW5nIGluYXBwcm9wcmlhdGUgd29yZHM6IHt3b3Jkc30ifQ%3D%3D!"),
        () => import(
          /* webpackChunkName: "en-NZ-translations" */
          "./translations.json-en-NZ-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJJbnZhbGlkIHdvcmsgaGlzdG9yeSI6IkludmFsaWQgd29yayBoaXN0b3J5IiwiU2VsZWN0IGFuIGFtb3VudCI6IlNlbGVjdCBhbiBhbW91bnQiLCJGaW5pc2ggZGF0ZSBtdXN0IGJlIGFmdGVyIHN0YXJ0IGRhdGUiOiJGaW5pc2ggZGF0ZSBtdXN0IGJlIGFmdGVyIHN0YXJ0IGRhdGUiLCJFbnRlciBhIG1vbnRoIGFuZCB5ZWFyIjoiRW50ZXIgYSBtb250aCBhbmQgeWVhciIsIk1ha2UgYSBzZWxlY3Rpb24iOiJNYWtlIGEgc2VsZWN0aW9uIiwiUmVtb3ZlIHRoZSBmb2xsb3dpbmcgaW5hcHByb3ByaWF0ZSB3b3Jkczoge3dvcmRzfSI6IlJlbW92ZSB0aGUgZm9sbG93aW5nIGluYXBwcm9wcmlhdGUgd29yZHM6IHt3b3Jkc30ifQ%3D%3D!"
        )
      ),
"en-PH": createLanguage(
        require.resolveWeak("./translations.json-en-PH-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJJbnZhbGlkIHdvcmsgaGlzdG9yeSI6IkludmFsaWQgd29yayBoaXN0b3J5IiwiU2VsZWN0IGFuIGFtb3VudCI6IlNlbGVjdCBhbiBhbW91bnQiLCJGaW5pc2ggZGF0ZSBtdXN0IGJlIGFmdGVyIHN0YXJ0IGRhdGUiOiJGaW5pc2ggZGF0ZSBtdXN0IGJlIGFmdGVyIHN0YXJ0IGRhdGUiLCJFbnRlciBhIG1vbnRoIGFuZCB5ZWFyIjoiRW50ZXIgYSBtb250aCBhbmQgeWVhciIsIk1ha2UgYSBzZWxlY3Rpb24iOiJNYWtlIGEgc2VsZWN0aW9uIiwiUmVtb3ZlIHRoZSBmb2xsb3dpbmcgaW5hcHByb3ByaWF0ZSB3b3Jkczoge3dvcmRzfSI6IlJlbW92ZSB0aGUgZm9sbG93aW5nIGluYXBwcm9wcmlhdGUgd29yZHM6IHt3b3Jkc30ifQ%3D%3D!"),
        () => import(
          /* webpackChunkName: "en-PH-translations" */
          "./translations.json-en-PH-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJJbnZhbGlkIHdvcmsgaGlzdG9yeSI6IkludmFsaWQgd29yayBoaXN0b3J5IiwiU2VsZWN0IGFuIGFtb3VudCI6IlNlbGVjdCBhbiBhbW91bnQiLCJGaW5pc2ggZGF0ZSBtdXN0IGJlIGFmdGVyIHN0YXJ0IGRhdGUiOiJGaW5pc2ggZGF0ZSBtdXN0IGJlIGFmdGVyIHN0YXJ0IGRhdGUiLCJFbnRlciBhIG1vbnRoIGFuZCB5ZWFyIjoiRW50ZXIgYSBtb250aCBhbmQgeWVhciIsIk1ha2UgYSBzZWxlY3Rpb24iOiJNYWtlIGEgc2VsZWN0aW9uIiwiUmVtb3ZlIHRoZSBmb2xsb3dpbmcgaW5hcHByb3ByaWF0ZSB3b3Jkczoge3dvcmRzfSI6IlJlbW92ZSB0aGUgZm9sbG93aW5nIGluYXBwcm9wcmlhdGUgd29yZHM6IHt3b3Jkc30ifQ%3D%3D!"
        )
      ),
"en-SG": createLanguage(
        require.resolveWeak("./translations.json-en-SG-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJJbnZhbGlkIHdvcmsgaGlzdG9yeSI6IkludmFsaWQgd29yayBoaXN0b3J5IiwiU2VsZWN0IGFuIGFtb3VudCI6IlNlbGVjdCBhbiBhbW91bnQiLCJGaW5pc2ggZGF0ZSBtdXN0IGJlIGFmdGVyIHN0YXJ0IGRhdGUiOiJGaW5pc2ggZGF0ZSBtdXN0IGJlIGFmdGVyIHN0YXJ0IGRhdGUiLCJFbnRlciBhIG1vbnRoIGFuZCB5ZWFyIjoiRW50ZXIgYSBtb250aCBhbmQgeWVhciIsIk1ha2UgYSBzZWxlY3Rpb24iOiJNYWtlIGEgc2VsZWN0aW9uIiwiUmVtb3ZlIHRoZSBmb2xsb3dpbmcgaW5hcHByb3ByaWF0ZSB3b3Jkczoge3dvcmRzfSI6IlJlbW92ZSB0aGUgZm9sbG93aW5nIGluYXBwcm9wcmlhdGUgd29yZHM6IHt3b3Jkc30ifQ%3D%3D!"),
        () => import(
          /* webpackChunkName: "en-SG-translations" */
          "./translations.json-en-SG-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJJbnZhbGlkIHdvcmsgaGlzdG9yeSI6IkludmFsaWQgd29yayBoaXN0b3J5IiwiU2VsZWN0IGFuIGFtb3VudCI6IlNlbGVjdCBhbiBhbW91bnQiLCJGaW5pc2ggZGF0ZSBtdXN0IGJlIGFmdGVyIHN0YXJ0IGRhdGUiOiJGaW5pc2ggZGF0ZSBtdXN0IGJlIGFmdGVyIHN0YXJ0IGRhdGUiLCJFbnRlciBhIG1vbnRoIGFuZCB5ZWFyIjoiRW50ZXIgYSBtb250aCBhbmQgeWVhciIsIk1ha2UgYSBzZWxlY3Rpb24iOiJNYWtlIGEgc2VsZWN0aW9uIiwiUmVtb3ZlIHRoZSBmb2xsb3dpbmcgaW5hcHByb3ByaWF0ZSB3b3Jkczoge3dvcmRzfSI6IlJlbW92ZSB0aGUgZm9sbG93aW5nIGluYXBwcm9wcmlhdGUgd29yZHM6IHt3b3Jkc30ifQ%3D%3D!"
        )
      ),
"en-TH": createLanguage(
        require.resolveWeak("./translations.json-en-TH-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJJbnZhbGlkIHdvcmsgaGlzdG9yeSI6IkludmFsaWQgd29yayBoaXN0b3J5IiwiU2VsZWN0IGFuIGFtb3VudCI6IlNlbGVjdCBhbiBhbW91bnQiLCJGaW5pc2ggZGF0ZSBtdXN0IGJlIGFmdGVyIHN0YXJ0IGRhdGUiOiJGaW5pc2ggZGF0ZSBtdXN0IGJlIGFmdGVyIHN0YXJ0IGRhdGUiLCJFbnRlciBhIG1vbnRoIGFuZCB5ZWFyIjoiRW50ZXIgYSBtb250aCBhbmQgeWVhciIsIk1ha2UgYSBzZWxlY3Rpb24iOiJNYWtlIGEgc2VsZWN0aW9uIiwiUmVtb3ZlIHRoZSBmb2xsb3dpbmcgaW5hcHByb3ByaWF0ZSB3b3Jkczoge3dvcmRzfSI6IlJlbW92ZSB0aGUgZm9sbG93aW5nIGluYXBwcm9wcmlhdGUgd29yZHM6IHt3b3Jkc30ifQ%3D%3D!"),
        () => import(
          /* webpackChunkName: "en-TH-translations" */
          "./translations.json-en-TH-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJJbnZhbGlkIHdvcmsgaGlzdG9yeSI6IkludmFsaWQgd29yayBoaXN0b3J5IiwiU2VsZWN0IGFuIGFtb3VudCI6IlNlbGVjdCBhbiBhbW91bnQiLCJGaW5pc2ggZGF0ZSBtdXN0IGJlIGFmdGVyIHN0YXJ0IGRhdGUiOiJGaW5pc2ggZGF0ZSBtdXN0IGJlIGFmdGVyIHN0YXJ0IGRhdGUiLCJFbnRlciBhIG1vbnRoIGFuZCB5ZWFyIjoiRW50ZXIgYSBtb250aCBhbmQgeWVhciIsIk1ha2UgYSBzZWxlY3Rpb24iOiJNYWtlIGEgc2VsZWN0aW9uIiwiUmVtb3ZlIHRoZSBmb2xsb3dpbmcgaW5hcHByb3ByaWF0ZSB3b3Jkczoge3dvcmRzfSI6IlJlbW92ZSB0aGUgZm9sbG93aW5nIGluYXBwcm9wcmlhdGUgd29yZHM6IHt3b3Jkc30ifQ%3D%3D!"
        )
      ),
"th-TH": createLanguage(
        require.resolveWeak("./translations.json-th-TH-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJJbnZhbGlkIHdvcmsgaGlzdG9yeSI6IuC4m%2BC4o%2BC4sOC4p%2BC4seC4leC4tOC4geC4suC4o%2BC4l%2BC4s%2BC4h%2BC4suC4meC4l%2BC4teC5iOC5hOC4oeC5iOC4luC4ueC4geC4leC5ieC4reC4hyIsIlNlbGVjdCBhbiBhbW91bnQiOiLguYDguKXguLfguK3guIHguIjguLPguJnguKfguJnguYDguIfguLTguJkiLCJGaW5pc2ggZGF0ZSBtdXN0IGJlIGFmdGVyIHN0YXJ0IGRhdGUiOiLguKfguLHguJnguJfguLXguYjguKrguLTguYnguJnguKrguLjguJTguJXguYnguK3guIfguK3guKLguLnguYjguKvguKXguLHguIfguIjguLLguIHguKfguLHguJnguJfguLXguYjguYDguKPguLTguYjguKHguJXguYnguJkiLCJFbnRlciBhIG1vbnRoIGFuZCB5ZWFyIjoi4LiB4Lij4Li44LiT4Liy4Lij4Liw4Lia4Li44LmA4LiU4Li34Lit4LiZ4LmB4Lil4Liw4Lib4Li1IiwiTWFrZSBhIHNlbGVjdGlvbiI6IuC4geC4o%2BC4uOC4k%2BC4suC5gOC4peC4t%2BC4reC4gSIsIlJlbW92ZSB0aGUgZm9sbG93aW5nIGluYXBwcm9wcmlhdGUgd29yZHM6IHt3b3Jkc30iOiLguKXguJrguITguLPguJfguLXguYjguYTguKHguYjguYDguKvguKHguLLguLDguKrguKHguJXguYjguK3guYTguJvguJnguLXguYk6IHt3b3Jkc30ifQ%3D%3D!"),
        () => import(
          /* webpackChunkName: "th-TH-translations" */
          "./translations.json-th-TH-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJJbnZhbGlkIHdvcmsgaGlzdG9yeSI6IuC4m%2BC4o%2BC4sOC4p%2BC4seC4leC4tOC4geC4suC4o%2BC4l%2BC4s%2BC4h%2BC4suC4meC4l%2BC4teC5iOC5hOC4oeC5iOC4luC4ueC4geC4leC5ieC4reC4hyIsIlNlbGVjdCBhbiBhbW91bnQiOiLguYDguKXguLfguK3guIHguIjguLPguJnguKfguJnguYDguIfguLTguJkiLCJGaW5pc2ggZGF0ZSBtdXN0IGJlIGFmdGVyIHN0YXJ0IGRhdGUiOiLguKfguLHguJnguJfguLXguYjguKrguLTguYnguJnguKrguLjguJTguJXguYnguK3guIfguK3guKLguLnguYjguKvguKXguLHguIfguIjguLLguIHguKfguLHguJnguJfguLXguYjguYDguKPguLTguYjguKHguJXguYnguJkiLCJFbnRlciBhIG1vbnRoIGFuZCB5ZWFyIjoi4LiB4Lij4Li44LiT4Liy4Lij4Liw4Lia4Li44LmA4LiU4Li34Lit4LiZ4LmB4Lil4Liw4Lib4Li1IiwiTWFrZSBhIHNlbGVjdGlvbiI6IuC4geC4o%2BC4uOC4k%2BC4suC5gOC4peC4t%2BC4reC4gSIsIlJlbW92ZSB0aGUgZm9sbG93aW5nIGluYXBwcm9wcmlhdGUgd29yZHM6IHt3b3Jkc30iOiLguKXguJrguITguLPguJfguLXguYjguYTguKHguYjguYDguKvguKHguLLguLDguKrguKHguJXguYjguK3guYTguJvguJnguLXguYk6IHt3b3Jkc30ifQ%3D%3D!"
        )
      ),
"id-ID": createLanguage(
        require.resolveWeak("./translations.json-id-ID-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJJbnZhbGlkIHdvcmsgaGlzdG9yeSI6IlJpd2F5YXQgcGVrZXJqYWFuIHRpZGFrIHZhbGlkIiwiU2VsZWN0IGFuIGFtb3VudCI6IlBpbGloIG5vbWluYWwiLCJGaW5pc2ggZGF0ZSBtdXN0IGJlIGFmdGVyIHN0YXJ0IGRhdGUiOiJUYW5nZ2FsIHNlbGVzYWkgaGFydXMgc2V0ZWxhaCB0YW5nZ2FsIG11bGFpIiwiRW50ZXIgYSBtb250aCBhbmQgeWVhciI6Ik1hc3Vra2FuIGJ1bGFuIGRhbiB0YWh1biIsIk1ha2UgYSBzZWxlY3Rpb24iOiJTaWxha2FuIHBpbGloIiwiUmVtb3ZlIHRoZSBmb2xsb3dpbmcgaW5hcHByb3ByaWF0ZSB3b3Jkczoge3dvcmRzfSI6Ik1vaG9uIGhhcHVzIGthdGEta2F0YSBrdXJhbmcgcGFudGFzIGJlcmlrdXQ6IHt3b3Jkc30ifQ%3D%3D!"),
        () => import(
          /* webpackChunkName: "id-ID-translations" */
          "./translations.json-id-ID-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJJbnZhbGlkIHdvcmsgaGlzdG9yeSI6IlJpd2F5YXQgcGVrZXJqYWFuIHRpZGFrIHZhbGlkIiwiU2VsZWN0IGFuIGFtb3VudCI6IlBpbGloIG5vbWluYWwiLCJGaW5pc2ggZGF0ZSBtdXN0IGJlIGFmdGVyIHN0YXJ0IGRhdGUiOiJUYW5nZ2FsIHNlbGVzYWkgaGFydXMgc2V0ZWxhaCB0YW5nZ2FsIG11bGFpIiwiRW50ZXIgYSBtb250aCBhbmQgeWVhciI6Ik1hc3Vra2FuIGJ1bGFuIGRhbiB0YWh1biIsIk1ha2UgYSBzZWxlY3Rpb24iOiJTaWxha2FuIHBpbGloIiwiUmVtb3ZlIHRoZSBmb2xsb3dpbmcgaW5hcHByb3ByaWF0ZSB3b3Jkczoge3dvcmRzfSI6Ik1vaG9uIGhhcHVzIGthdGEta2F0YSBrdXJhbmcgcGFudGFzIGJlcmlrdXQ6IHt3b3Jkc30ifQ%3D%3D!"
        )
      ),
"zh-HK": createLanguage(
        require.resolveWeak("./translations.json-zh-HK-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJJbnZhbGlkIHdvcmsgaGlzdG9yeSI6IkludmFsaWQgd29yayBoaXN0b3J5IiwiU2VsZWN0IGFuIGFtb3VudCI6IlNlbGVjdCBhbiBhbW91bnQiLCJGaW5pc2ggZGF0ZSBtdXN0IGJlIGFmdGVyIHN0YXJ0IGRhdGUiOiJGaW5pc2ggZGF0ZSBtdXN0IGJlIGFmdGVyIHN0YXJ0IGRhdGUiLCJFbnRlciBhIG1vbnRoIGFuZCB5ZWFyIjoiRW50ZXIgYSBtb250aCBhbmQgeWVhciIsIk1ha2UgYSBzZWxlY3Rpb24iOiJNYWtlIGEgc2VsZWN0aW9uIiwiUmVtb3ZlIHRoZSBmb2xsb3dpbmcgaW5hcHByb3ByaWF0ZSB3b3Jkczoge3dvcmRzfSI6IlJlbW92ZSB0aGUgZm9sbG93aW5nIGluYXBwcm9wcmlhdGUgd29yZHM6IHt3b3Jkc30ifQ%3D%3D!"),
        () => import(
          /* webpackChunkName: "zh-HK-translations" */
          "./translations.json-zh-HK-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJJbnZhbGlkIHdvcmsgaGlzdG9yeSI6IkludmFsaWQgd29yayBoaXN0b3J5IiwiU2VsZWN0IGFuIGFtb3VudCI6IlNlbGVjdCBhbiBhbW91bnQiLCJGaW5pc2ggZGF0ZSBtdXN0IGJlIGFmdGVyIHN0YXJ0IGRhdGUiOiJGaW5pc2ggZGF0ZSBtdXN0IGJlIGFmdGVyIHN0YXJ0IGRhdGUiLCJFbnRlciBhIG1vbnRoIGFuZCB5ZWFyIjoiRW50ZXIgYSBtb250aCBhbmQgeWVhciIsIk1ha2UgYSBzZWxlY3Rpb24iOiJNYWtlIGEgc2VsZWN0aW9uIiwiUmVtb3ZlIHRoZSBmb2xsb3dpbmcgaW5hcHByb3ByaWF0ZSB3b3Jkczoge3dvcmRzfSI6IlJlbW92ZSB0aGUgZm9sbG93aW5nIGluYXBwcm9wcmlhdGUgd29yZHM6IHt3b3Jkc30ifQ%3D%3D!"
        )
      ),
"en-PSEUDO": createLanguage(
        require.resolveWeak("./translations.json-en-PSEUDO-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJJbnZhbGlkIHdvcmsgaGlzdG9yeSI6IlvDj%2BC4geC4teC5ieG5vcSDxIPEg8aaw6zDrMOsxowgxbXDtsO2w7bFmcS3IOG4qcOsw6zDrMWh4bmvw7bDtsO2xZnDvcO9w71dIiwiU2VsZWN0IGFuIGFtb3VudCI6IlvFoOG6veG6veG6vcaa4bq94bq94bq9w6fhua8gxIPEg8SD4LiB4Li14LmJIMSDxIPEg23MgsO2w7bDtseax5rHmuC4geC4teC5ieG5r10iLCJGaW5pc2ggZGF0ZSBtdXN0IGJlIGFmdGVyIHN0YXJ0IGRhdGUiOiJbxpHDrMOsw6zguIHguLXguYnDrMOsw6zFoeG4qSDGjMSDxIPEg%2BG5r%2BG6veG6veG6vSBtzILHmseax5rFoeG5ryDDn%2BG6veG6veG6vSDEg8SDxIPGkuG5r%2BG6veG6veG6vcWZIMWh4bmvxIPEg8SDxZnhua8gxozEg8SDxIPhua%2Fhur3hur3hur1dIiwiRW50ZXIgYSBtb250aCBhbmQgeWVhciI6IlvDi%2BC4geC4teC5ieG5r%2BG6veG6veG6vcWZIMSDxIPEgyBtzILDtsO2w7bguIHguLXguYnhua%2FhuKkgxIPEg8SD4LiB4Li14LmJxowgw73DvcO94bq94bq94bq9xIPEg8SDxZldIiwiTWFrZSBhIHNlbGVjdGlvbiI6IlvhuYLEg8SDxLfhur3hur0gxIPEgyDFoeG6veG6vcaa4bq94bq9w6fhua%2FDrMOsw7bDtuC4geC4teC5iV0iLCJSZW1vdmUgdGhlIGZvbGxvd2luZyBpbmFwcHJvcHJpYXRlIHdvcmRzOiB7d29yZHN9IjoiW8WY4bq94bq94bq9bcyCw7bDtsO24bm94bq94bq94bq9IOG5r%2BG4qeG6veG6veG6vSDGksO2w7bDtsaaxprDtsO2w7bFtcOsw6zDrOC4geC4teC5icSjIMOsw6zDrOC4geC4teC5icSDxIPEg8alxqXFmcO2w7bDtsalxZnDrMOsw6zEg8SDxIPhua%2Fhur3hur3hur0gxbXDtsO2w7bFmcaMxaE6IHt3b3Jkc31dIn0%3D!"),
        () => import(
          /* webpackChunkName: "en-PSEUDO-translations" */
          "./translations.json-en-PSEUDO-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJJbnZhbGlkIHdvcmsgaGlzdG9yeSI6IlvDj%2BC4geC4teC5ieG5vcSDxIPEg8aaw6zDrMOsxowgxbXDtsO2w7bFmcS3IOG4qcOsw6zDrMWh4bmvw7bDtsO2xZnDvcO9w71dIiwiU2VsZWN0IGFuIGFtb3VudCI6IlvFoOG6veG6veG6vcaa4bq94bq94bq9w6fhua8gxIPEg8SD4LiB4Li14LmJIMSDxIPEg23MgsO2w7bDtseax5rHmuC4geC4teC5ieG5r10iLCJGaW5pc2ggZGF0ZSBtdXN0IGJlIGFmdGVyIHN0YXJ0IGRhdGUiOiJbxpHDrMOsw6zguIHguLXguYnDrMOsw6zFoeG4qSDGjMSDxIPEg%2BG5r%2BG6veG6veG6vSBtzILHmseax5rFoeG5ryDDn%2BG6veG6veG6vSDEg8SDxIPGkuG5r%2BG6veG6veG6vcWZIMWh4bmvxIPEg8SDxZnhua8gxozEg8SDxIPhua%2Fhur3hur3hur1dIiwiRW50ZXIgYSBtb250aCBhbmQgeWVhciI6IlvDi%2BC4geC4teC5ieG5r%2BG6veG6veG6vcWZIMSDxIPEgyBtzILDtsO2w7bguIHguLXguYnhua%2FhuKkgxIPEg8SD4LiB4Li14LmJxowgw73DvcO94bq94bq94bq9xIPEg8SDxZldIiwiTWFrZSBhIHNlbGVjdGlvbiI6IlvhuYLEg8SDxLfhur3hur0gxIPEgyDFoeG6veG6vcaa4bq94bq9w6fhua%2FDrMOsw7bDtuC4geC4teC5iV0iLCJSZW1vdmUgdGhlIGZvbGxvd2luZyBpbmFwcHJvcHJpYXRlIHdvcmRzOiB7d29yZHN9IjoiW8WY4bq94bq94bq9bcyCw7bDtsO24bm94bq94bq94bq9IOG5r%2BG4qeG6veG6veG6vSDGksO2w7bDtsaaxprDtsO2w7bFtcOsw6zDrOC4geC4teC5icSjIMOsw6zDrOC4geC4teC5icSDxIPEg8alxqXFmcO2w7bDtsalxZnDrMOsw6zEg8SDxIPhua%2Fhur3hur3hur0gxbXDtsO2w7bFmcaMxaE6IHt3b3Jkc31dIn0%3D!"
        )
      )
      });
  
      export { translations as default };
    