
      import { createLanguage, createTranslationFile } from '@vocab/webpack/web';
      
    const translations = createTranslationFile({
      "en": createLanguage(
        require.resolveWeak("./translations.json-en-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJMaWZlIGFuZCBDdWx0dXJlIGF0IGNvbXBhbnkgbmFtZSI6IkxpZmUgYW5kIEN1bHR1cmUgYXQge2NvbXBhbnlOYW1lfSJ9!"),
        () => import(
          /* webpackChunkName: "en-translations" */
          "./translations.json-en-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJMaWZlIGFuZCBDdWx0dXJlIGF0IGNvbXBhbnkgbmFtZSI6IkxpZmUgYW5kIEN1bHR1cmUgYXQge2NvbXBhbnlOYW1lfSJ9!"
        )
      ),
"th": createLanguage(
        require.resolveWeak("./translations.json-th-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJMaWZlIGFuZCBDdWx0dXJlIGF0IGNvbXBhbnkgbmFtZSI6IuC4iuC4teC4p%2BC4tOC4leC5geC4peC4sOC4p%2BC4seC4kuC4meC4mOC4o%2BC4o%2BC4oeC4l%2BC4teC5iCB7Y29tcGFueU5hbWV9In0%3D!"),
        () => import(
          /* webpackChunkName: "th-translations" */
          "./translations.json-th-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJMaWZlIGFuZCBDdWx0dXJlIGF0IGNvbXBhbnkgbmFtZSI6IuC4iuC4teC4p%2BC4tOC4leC5geC4peC4sOC4p%2BC4seC4kuC4meC4mOC4o%2BC4o%2BC4oeC4l%2BC4teC5iCB7Y29tcGFueU5hbWV9In0%3D!"
        )
      ),
"id": createLanguage(
        require.resolveWeak("./translations.json-id-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJMaWZlIGFuZCBDdWx0dXJlIGF0IGNvbXBhbnkgbmFtZSI6IkxpbmdrdW5nYW4gZGFuIEJ1ZGF5YSBkaSB7Y29tcGFueU5hbWV9In0%3D!"),
        () => import(
          /* webpackChunkName: "id-translations" */
          "./translations.json-id-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJMaWZlIGFuZCBDdWx0dXJlIGF0IGNvbXBhbnkgbmFtZSI6IkxpbmdrdW5nYW4gZGFuIEJ1ZGF5YSBkaSB7Y29tcGFueU5hbWV9In0%3D!"
        )
      ),
"zh": createLanguage(
        require.resolveWeak("./translations.json-zh-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJMaWZlIGFuZCBDdWx0dXJlIGF0IGNvbXBhbnkgbmFtZSI6IkxpZmUgYW5kIEN1bHR1cmUgYXQge2NvbXBhbnlOYW1lfSJ9!"),
        () => import(
          /* webpackChunkName: "zh-translations" */
          "./translations.json-zh-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJMaWZlIGFuZCBDdWx0dXJlIGF0IGNvbXBhbnkgbmFtZSI6IkxpZmUgYW5kIEN1bHR1cmUgYXQge2NvbXBhbnlOYW1lfSJ9!"
        )
      ),
"en-AU": createLanguage(
        require.resolveWeak("./translations.json-en-AU-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJMaWZlIGFuZCBDdWx0dXJlIGF0IGNvbXBhbnkgbmFtZSI6IkxpZmUgYW5kIEN1bHR1cmUgYXQge2NvbXBhbnlOYW1lfSJ9!"),
        () => import(
          /* webpackChunkName: "en-AU-translations" */
          "./translations.json-en-AU-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJMaWZlIGFuZCBDdWx0dXJlIGF0IGNvbXBhbnkgbmFtZSI6IkxpZmUgYW5kIEN1bHR1cmUgYXQge2NvbXBhbnlOYW1lfSJ9!"
        )
      ),
"en-HK": createLanguage(
        require.resolveWeak("./translations.json-en-HK-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJMaWZlIGFuZCBDdWx0dXJlIGF0IGNvbXBhbnkgbmFtZSI6IkxpZmUgYW5kIEN1bHR1cmUgYXQge2NvbXBhbnlOYW1lfSJ9!"),
        () => import(
          /* webpackChunkName: "en-HK-translations" */
          "./translations.json-en-HK-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJMaWZlIGFuZCBDdWx0dXJlIGF0IGNvbXBhbnkgbmFtZSI6IkxpZmUgYW5kIEN1bHR1cmUgYXQge2NvbXBhbnlOYW1lfSJ9!"
        )
      ),
"en-ID": createLanguage(
        require.resolveWeak("./translations.json-en-ID-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJMaWZlIGFuZCBDdWx0dXJlIGF0IGNvbXBhbnkgbmFtZSI6IkxpZmUgYW5kIEN1bHR1cmUgYXQge2NvbXBhbnlOYW1lfSJ9!"),
        () => import(
          /* webpackChunkName: "en-ID-translations" */
          "./translations.json-en-ID-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJMaWZlIGFuZCBDdWx0dXJlIGF0IGNvbXBhbnkgbmFtZSI6IkxpZmUgYW5kIEN1bHR1cmUgYXQge2NvbXBhbnlOYW1lfSJ9!"
        )
      ),
"en-MY": createLanguage(
        require.resolveWeak("./translations.json-en-MY-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJMaWZlIGFuZCBDdWx0dXJlIGF0IGNvbXBhbnkgbmFtZSI6IkxpZmUgYW5kIEN1bHR1cmUgYXQge2NvbXBhbnlOYW1lfSJ9!"),
        () => import(
          /* webpackChunkName: "en-MY-translations" */
          "./translations.json-en-MY-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJMaWZlIGFuZCBDdWx0dXJlIGF0IGNvbXBhbnkgbmFtZSI6IkxpZmUgYW5kIEN1bHR1cmUgYXQge2NvbXBhbnlOYW1lfSJ9!"
        )
      ),
"en-NZ": createLanguage(
        require.resolveWeak("./translations.json-en-NZ-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJMaWZlIGFuZCBDdWx0dXJlIGF0IGNvbXBhbnkgbmFtZSI6IkxpZmUgYW5kIEN1bHR1cmUgYXQge2NvbXBhbnlOYW1lfSJ9!"),
        () => import(
          /* webpackChunkName: "en-NZ-translations" */
          "./translations.json-en-NZ-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJMaWZlIGFuZCBDdWx0dXJlIGF0IGNvbXBhbnkgbmFtZSI6IkxpZmUgYW5kIEN1bHR1cmUgYXQge2NvbXBhbnlOYW1lfSJ9!"
        )
      ),
"en-PH": createLanguage(
        require.resolveWeak("./translations.json-en-PH-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJMaWZlIGFuZCBDdWx0dXJlIGF0IGNvbXBhbnkgbmFtZSI6IkxpZmUgYW5kIEN1bHR1cmUgYXQge2NvbXBhbnlOYW1lfSJ9!"),
        () => import(
          /* webpackChunkName: "en-PH-translations" */
          "./translations.json-en-PH-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJMaWZlIGFuZCBDdWx0dXJlIGF0IGNvbXBhbnkgbmFtZSI6IkxpZmUgYW5kIEN1bHR1cmUgYXQge2NvbXBhbnlOYW1lfSJ9!"
        )
      ),
"en-SG": createLanguage(
        require.resolveWeak("./translations.json-en-SG-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJMaWZlIGFuZCBDdWx0dXJlIGF0IGNvbXBhbnkgbmFtZSI6IkxpZmUgYW5kIEN1bHR1cmUgYXQge2NvbXBhbnlOYW1lfSJ9!"),
        () => import(
          /* webpackChunkName: "en-SG-translations" */
          "./translations.json-en-SG-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJMaWZlIGFuZCBDdWx0dXJlIGF0IGNvbXBhbnkgbmFtZSI6IkxpZmUgYW5kIEN1bHR1cmUgYXQge2NvbXBhbnlOYW1lfSJ9!"
        )
      ),
"en-TH": createLanguage(
        require.resolveWeak("./translations.json-en-TH-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJMaWZlIGFuZCBDdWx0dXJlIGF0IGNvbXBhbnkgbmFtZSI6IkxpZmUgYW5kIEN1bHR1cmUgYXQge2NvbXBhbnlOYW1lfSJ9!"),
        () => import(
          /* webpackChunkName: "en-TH-translations" */
          "./translations.json-en-TH-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJMaWZlIGFuZCBDdWx0dXJlIGF0IGNvbXBhbnkgbmFtZSI6IkxpZmUgYW5kIEN1bHR1cmUgYXQge2NvbXBhbnlOYW1lfSJ9!"
        )
      ),
"th-TH": createLanguage(
        require.resolveWeak("./translations.json-th-TH-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJMaWZlIGFuZCBDdWx0dXJlIGF0IGNvbXBhbnkgbmFtZSI6IuC4iuC4teC4p%2BC4tOC4leC5geC4peC4sOC4p%2BC4seC4kuC4meC4mOC4o%2BC4o%2BC4oeC4l%2BC4teC5iCB7Y29tcGFueU5hbWV9In0%3D!"),
        () => import(
          /* webpackChunkName: "th-TH-translations" */
          "./translations.json-th-TH-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJMaWZlIGFuZCBDdWx0dXJlIGF0IGNvbXBhbnkgbmFtZSI6IuC4iuC4teC4p%2BC4tOC4leC5geC4peC4sOC4p%2BC4seC4kuC4meC4mOC4o%2BC4o%2BC4oeC4l%2BC4teC5iCB7Y29tcGFueU5hbWV9In0%3D!"
        )
      ),
"id-ID": createLanguage(
        require.resolveWeak("./translations.json-id-ID-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJMaWZlIGFuZCBDdWx0dXJlIGF0IGNvbXBhbnkgbmFtZSI6IkxpbmdrdW5nYW4gZGFuIEJ1ZGF5YSBkaSB7Y29tcGFueU5hbWV9In0%3D!"),
        () => import(
          /* webpackChunkName: "id-ID-translations" */
          "./translations.json-id-ID-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJMaWZlIGFuZCBDdWx0dXJlIGF0IGNvbXBhbnkgbmFtZSI6IkxpbmdrdW5nYW4gZGFuIEJ1ZGF5YSBkaSB7Y29tcGFueU5hbWV9In0%3D!"
        )
      ),
"zh-HK": createLanguage(
        require.resolveWeak("./translations.json-zh-HK-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJMaWZlIGFuZCBDdWx0dXJlIGF0IGNvbXBhbnkgbmFtZSI6IkxpZmUgYW5kIEN1bHR1cmUgYXQge2NvbXBhbnlOYW1lfSJ9!"),
        () => import(
          /* webpackChunkName: "zh-HK-translations" */
          "./translations.json-zh-HK-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJMaWZlIGFuZCBDdWx0dXJlIGF0IGNvbXBhbnkgbmFtZSI6IkxpZmUgYW5kIEN1bHR1cmUgYXQge2NvbXBhbnlOYW1lfSJ9!"
        )
      ),
"en-PSEUDO": createLanguage(
        require.resolveWeak("./translations.json-en-PSEUDO-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJMaWZlIGFuZCBDdWx0dXJlIGF0IGNvbXBhbnkgbmFtZSI6IlvFgcOsw6zDrMaS4bq94bq94bq9IMSDxIPEg%2BC4geC4teC5icaMIMOHx5rHmseaxprhua%2FHmseax5rFmeG6veG6veG6vSDEg8SDxIPhua8ge2NvbXBhbnlOYW1lfV0ifQ%3D%3D!"),
        () => import(
          /* webpackChunkName: "en-PSEUDO-translations" */
          "./translations.json-en-PSEUDO-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJMaWZlIGFuZCBDdWx0dXJlIGF0IGNvbXBhbnkgbmFtZSI6IlvFgcOsw6zDrMaS4bq94bq94bq9IMSDxIPEg%2BC4geC4teC5icaMIMOHx5rHmseaxprhua%2FHmseax5rFmeG6veG6veG6vSDEg8SDxIPhua8ge2NvbXBhbnlOYW1lfV0ifQ%3D%3D!"
        )
      )
      });
  
      export { translations as default };
    