import profanity from './badWords';

export const getProfanities = (textToCheck: string): string[] => {
  const splitOnSpaces = textToCheck.replace(/\n/g, ' ').split(' ');
  const lowerCased = splitOnSpaces.map((w) =>
    w.toLowerCase().replace(/[^a-zA-z0-9|$|@|!|#|%|&|*]|\^/g, ''),
  );

  const uniqueOnly = Array.from(new Set(lowerCased));
  const badWords = uniqueOnly.filter((word) => profanity.includes(word));

  return badWords;
};
