import { useTranslations } from '@vocab/react';
import translations from './.vocab';

import type { QuestionProps, SalaryRating } from '../../type';

import { SegmentedButtonSelector } from '@seek/branding-assets';

export const RateYourSalaryQuestion = ({
  value,
  message,
  onChange,
}: QuestionProps<SalaryRating>) => {
  const { t } = useTranslations(translations);

  return (
    <SegmentedButtonSelector
      label={t('How would you rate your salary?')}
      value={value}
      options={[
        { value: 'Below Par', text: t('Low') },
        { value: 'Fair', text: t('Average') },
        { value: 'Generous', text: t('High') },
      ]}
      onChange={onChange}
      message={message}
    />
  );
};
