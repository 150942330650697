
      import { createLanguage, createTranslationFile } from '@vocab/webpack/web';
      
    const translations = createTranslationFile({
      "en": createLanguage(
        require.resolveWeak("./translations.json-en-virtual.json!=!../../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJDb21wYW55IG5hbWUiOiJDb21wYW55IG5hbWUiLCJDb21wYW55IG5vdCB2ZXJpZmllZCI6IkNvbXBhbnkgbm90IHZlcmlmaWVkIiwiV2VsbCBhZGQgdGhpcyBjb21wYW55IHRvIHlvdXIgU0VFSyBQcm9maWxlIjoiV2UnbGwgYWRkIHRoaXMgY29tcGFueSB0byB5b3VyIFNFRUsgUHJvZmlsZSJ9!"),
        () => import(
          /* webpackChunkName: "en-translations" */
          "./translations.json-en-virtual.json!=!../../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJDb21wYW55IG5hbWUiOiJDb21wYW55IG5hbWUiLCJDb21wYW55IG5vdCB2ZXJpZmllZCI6IkNvbXBhbnkgbm90IHZlcmlmaWVkIiwiV2VsbCBhZGQgdGhpcyBjb21wYW55IHRvIHlvdXIgU0VFSyBQcm9maWxlIjoiV2UnbGwgYWRkIHRoaXMgY29tcGFueSB0byB5b3VyIFNFRUsgUHJvZmlsZSJ9!"
        )
      ),
"th": createLanguage(
        require.resolveWeak("./translations.json-th-virtual.json!=!../../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJDb21wYW55IG5hbWUiOiLguIrguLfguYjguK3guJrguKPguLTguKnguLHguJciLCJDb21wYW55IG5vdCB2ZXJpZmllZCI6IuC4muC4o%2BC4tOC4qeC4seC4l%2BC4ouC4seC4h%2BC5hOC4oeC5iOC5hOC4lOC5ieC4o%2BC4seC4muC4geC4suC4o%2BC4leC4o%2BC4p%2BC4iOC4quC4reC4miIsIldlbGwgYWRkIHRoaXMgY29tcGFueSB0byB5b3VyIFNFRUsgUHJvZmlsZSI6IuC5gOC4o%2BC4suC4iOC4sOC5gOC4nuC4tOC5iOC4oeC4muC4o%2BC4tOC4qeC4seC4l%2BC4meC4teC5ieC5hOC4m%2BC4ouC4seC4h%2BC5guC4m%2BC4o%2BC5hOC4n%2BC4peC5jCBTRUVLIOC4guC4reC4h%2BC4hOC4uOC4kyJ9!"),
        () => import(
          /* webpackChunkName: "th-translations" */
          "./translations.json-th-virtual.json!=!../../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJDb21wYW55IG5hbWUiOiLguIrguLfguYjguK3guJrguKPguLTguKnguLHguJciLCJDb21wYW55IG5vdCB2ZXJpZmllZCI6IuC4muC4o%2BC4tOC4qeC4seC4l%2BC4ouC4seC4h%2BC5hOC4oeC5iOC5hOC4lOC5ieC4o%2BC4seC4muC4geC4suC4o%2BC4leC4o%2BC4p%2BC4iOC4quC4reC4miIsIldlbGwgYWRkIHRoaXMgY29tcGFueSB0byB5b3VyIFNFRUsgUHJvZmlsZSI6IuC5gOC4o%2BC4suC4iOC4sOC5gOC4nuC4tOC5iOC4oeC4muC4o%2BC4tOC4qeC4seC4l%2BC4meC4teC5ieC5hOC4m%2BC4ouC4seC4h%2BC5guC4m%2BC4o%2BC5hOC4n%2BC4peC5jCBTRUVLIOC4guC4reC4h%2BC4hOC4uOC4kyJ9!"
        )
      ),
"id": createLanguage(
        require.resolveWeak("./translations.json-id-virtual.json!=!../../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJDb21wYW55IG5hbWUiOiJOYW1hIHBlcnVzYWhhYW4geWFuZyBBbmRhIG5pbGFpIiwiQ29tcGFueSBub3QgdmVyaWZpZWQiOiJQZXJ1c2FoYWFuIGJlbHVtIHRlcnZlcmlmaWthc2kiLCJXZWxsIGFkZCB0aGlzIGNvbXBhbnkgdG8geW91ciBTRUVLIFByb2ZpbGUiOiJQZXJ1c2FoYWFuIGluaSBha2FuIGRpdGFtYmFoa2FuIGtlIHByb2ZpbCBTRUVLIEFuZGEifQ%3D%3D!"),
        () => import(
          /* webpackChunkName: "id-translations" */
          "./translations.json-id-virtual.json!=!../../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJDb21wYW55IG5hbWUiOiJOYW1hIHBlcnVzYWhhYW4geWFuZyBBbmRhIG5pbGFpIiwiQ29tcGFueSBub3QgdmVyaWZpZWQiOiJQZXJ1c2FoYWFuIGJlbHVtIHRlcnZlcmlmaWthc2kiLCJXZWxsIGFkZCB0aGlzIGNvbXBhbnkgdG8geW91ciBTRUVLIFByb2ZpbGUiOiJQZXJ1c2FoYWFuIGluaSBha2FuIGRpdGFtYmFoa2FuIGtlIHByb2ZpbCBTRUVLIEFuZGEifQ%3D%3D!"
        )
      ),
"zh": createLanguage(
        require.resolveWeak("./translations.json-zh-virtual.json!=!../../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJDb21wYW55IG5hbWUiOiJDb21wYW55IG5hbWUiLCJDb21wYW55IG5vdCB2ZXJpZmllZCI6IkNvbXBhbnkgbm90IHZlcmlmaWVkIiwiV2VsbCBhZGQgdGhpcyBjb21wYW55IHRvIHlvdXIgU0VFSyBQcm9maWxlIjoiV2UnbGwgYWRkIHRoaXMgY29tcGFueSB0byB5b3VyIFNFRUsgUHJvZmlsZSJ9!"),
        () => import(
          /* webpackChunkName: "zh-translations" */
          "./translations.json-zh-virtual.json!=!../../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJDb21wYW55IG5hbWUiOiJDb21wYW55IG5hbWUiLCJDb21wYW55IG5vdCB2ZXJpZmllZCI6IkNvbXBhbnkgbm90IHZlcmlmaWVkIiwiV2VsbCBhZGQgdGhpcyBjb21wYW55IHRvIHlvdXIgU0VFSyBQcm9maWxlIjoiV2UnbGwgYWRkIHRoaXMgY29tcGFueSB0byB5b3VyIFNFRUsgUHJvZmlsZSJ9!"
        )
      ),
"en-AU": createLanguage(
        require.resolveWeak("./translations.json-en-AU-virtual.json!=!../../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJDb21wYW55IG5hbWUiOiJDb21wYW55IG5hbWUiLCJDb21wYW55IG5vdCB2ZXJpZmllZCI6IkNvbXBhbnkgbm90IHZlcmlmaWVkIiwiV2VsbCBhZGQgdGhpcyBjb21wYW55IHRvIHlvdXIgU0VFSyBQcm9maWxlIjoiV2UnbGwgYWRkIHRoaXMgY29tcGFueSB0byB5b3VyIFNFRUsgUHJvZmlsZSJ9!"),
        () => import(
          /* webpackChunkName: "en-AU-translations" */
          "./translations.json-en-AU-virtual.json!=!../../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJDb21wYW55IG5hbWUiOiJDb21wYW55IG5hbWUiLCJDb21wYW55IG5vdCB2ZXJpZmllZCI6IkNvbXBhbnkgbm90IHZlcmlmaWVkIiwiV2VsbCBhZGQgdGhpcyBjb21wYW55IHRvIHlvdXIgU0VFSyBQcm9maWxlIjoiV2UnbGwgYWRkIHRoaXMgY29tcGFueSB0byB5b3VyIFNFRUsgUHJvZmlsZSJ9!"
        )
      ),
"en-HK": createLanguage(
        require.resolveWeak("./translations.json-en-HK-virtual.json!=!../../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJDb21wYW55IG5hbWUiOiJDb21wYW55IG5hbWUiLCJDb21wYW55IG5vdCB2ZXJpZmllZCI6IkNvbXBhbnkgbm90IHZlcmlmaWVkIiwiV2VsbCBhZGQgdGhpcyBjb21wYW55IHRvIHlvdXIgU0VFSyBQcm9maWxlIjoiV2UnbGwgYWRkIHRoaXMgY29tcGFueSB0byB5b3VyIFNFRUsgUHJvZmlsZSJ9!"),
        () => import(
          /* webpackChunkName: "en-HK-translations" */
          "./translations.json-en-HK-virtual.json!=!../../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJDb21wYW55IG5hbWUiOiJDb21wYW55IG5hbWUiLCJDb21wYW55IG5vdCB2ZXJpZmllZCI6IkNvbXBhbnkgbm90IHZlcmlmaWVkIiwiV2VsbCBhZGQgdGhpcyBjb21wYW55IHRvIHlvdXIgU0VFSyBQcm9maWxlIjoiV2UnbGwgYWRkIHRoaXMgY29tcGFueSB0byB5b3VyIFNFRUsgUHJvZmlsZSJ9!"
        )
      ),
"en-ID": createLanguage(
        require.resolveWeak("./translations.json-en-ID-virtual.json!=!../../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJDb21wYW55IG5hbWUiOiJDb21wYW55IG5hbWUiLCJDb21wYW55IG5vdCB2ZXJpZmllZCI6IkNvbXBhbnkgbm90IHZlcmlmaWVkIiwiV2VsbCBhZGQgdGhpcyBjb21wYW55IHRvIHlvdXIgU0VFSyBQcm9maWxlIjoiV2UnbGwgYWRkIHRoaXMgY29tcGFueSB0byB5b3VyIFNFRUsgUHJvZmlsZSJ9!"),
        () => import(
          /* webpackChunkName: "en-ID-translations" */
          "./translations.json-en-ID-virtual.json!=!../../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJDb21wYW55IG5hbWUiOiJDb21wYW55IG5hbWUiLCJDb21wYW55IG5vdCB2ZXJpZmllZCI6IkNvbXBhbnkgbm90IHZlcmlmaWVkIiwiV2VsbCBhZGQgdGhpcyBjb21wYW55IHRvIHlvdXIgU0VFSyBQcm9maWxlIjoiV2UnbGwgYWRkIHRoaXMgY29tcGFueSB0byB5b3VyIFNFRUsgUHJvZmlsZSJ9!"
        )
      ),
"en-MY": createLanguage(
        require.resolveWeak("./translations.json-en-MY-virtual.json!=!../../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJDb21wYW55IG5hbWUiOiJDb21wYW55IG5hbWUiLCJDb21wYW55IG5vdCB2ZXJpZmllZCI6IkNvbXBhbnkgbm90IHZlcmlmaWVkIiwiV2VsbCBhZGQgdGhpcyBjb21wYW55IHRvIHlvdXIgU0VFSyBQcm9maWxlIjoiV2UnbGwgYWRkIHRoaXMgY29tcGFueSB0byB5b3VyIFNFRUsgUHJvZmlsZSJ9!"),
        () => import(
          /* webpackChunkName: "en-MY-translations" */
          "./translations.json-en-MY-virtual.json!=!../../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJDb21wYW55IG5hbWUiOiJDb21wYW55IG5hbWUiLCJDb21wYW55IG5vdCB2ZXJpZmllZCI6IkNvbXBhbnkgbm90IHZlcmlmaWVkIiwiV2VsbCBhZGQgdGhpcyBjb21wYW55IHRvIHlvdXIgU0VFSyBQcm9maWxlIjoiV2UnbGwgYWRkIHRoaXMgY29tcGFueSB0byB5b3VyIFNFRUsgUHJvZmlsZSJ9!"
        )
      ),
"en-NZ": createLanguage(
        require.resolveWeak("./translations.json-en-NZ-virtual.json!=!../../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJDb21wYW55IG5hbWUiOiJDb21wYW55IG5hbWUiLCJDb21wYW55IG5vdCB2ZXJpZmllZCI6IkNvbXBhbnkgbm90IHZlcmlmaWVkIiwiV2VsbCBhZGQgdGhpcyBjb21wYW55IHRvIHlvdXIgU0VFSyBQcm9maWxlIjoiV2UnbGwgYWRkIHRoaXMgY29tcGFueSB0byB5b3VyIFNFRUsgUHJvZmlsZSJ9!"),
        () => import(
          /* webpackChunkName: "en-NZ-translations" */
          "./translations.json-en-NZ-virtual.json!=!../../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJDb21wYW55IG5hbWUiOiJDb21wYW55IG5hbWUiLCJDb21wYW55IG5vdCB2ZXJpZmllZCI6IkNvbXBhbnkgbm90IHZlcmlmaWVkIiwiV2VsbCBhZGQgdGhpcyBjb21wYW55IHRvIHlvdXIgU0VFSyBQcm9maWxlIjoiV2UnbGwgYWRkIHRoaXMgY29tcGFueSB0byB5b3VyIFNFRUsgUHJvZmlsZSJ9!"
        )
      ),
"en-PH": createLanguage(
        require.resolveWeak("./translations.json-en-PH-virtual.json!=!../../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJDb21wYW55IG5hbWUiOiJDb21wYW55IG5hbWUiLCJDb21wYW55IG5vdCB2ZXJpZmllZCI6IkNvbXBhbnkgbm90IHZlcmlmaWVkIiwiV2VsbCBhZGQgdGhpcyBjb21wYW55IHRvIHlvdXIgU0VFSyBQcm9maWxlIjoiV2UnbGwgYWRkIHRoaXMgY29tcGFueSB0byB5b3VyIFNFRUsgUHJvZmlsZSJ9!"),
        () => import(
          /* webpackChunkName: "en-PH-translations" */
          "./translations.json-en-PH-virtual.json!=!../../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJDb21wYW55IG5hbWUiOiJDb21wYW55IG5hbWUiLCJDb21wYW55IG5vdCB2ZXJpZmllZCI6IkNvbXBhbnkgbm90IHZlcmlmaWVkIiwiV2VsbCBhZGQgdGhpcyBjb21wYW55IHRvIHlvdXIgU0VFSyBQcm9maWxlIjoiV2UnbGwgYWRkIHRoaXMgY29tcGFueSB0byB5b3VyIFNFRUsgUHJvZmlsZSJ9!"
        )
      ),
"en-SG": createLanguage(
        require.resolveWeak("./translations.json-en-SG-virtual.json!=!../../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJDb21wYW55IG5hbWUiOiJDb21wYW55IG5hbWUiLCJDb21wYW55IG5vdCB2ZXJpZmllZCI6IkNvbXBhbnkgbm90IHZlcmlmaWVkIiwiV2VsbCBhZGQgdGhpcyBjb21wYW55IHRvIHlvdXIgU0VFSyBQcm9maWxlIjoiV2UnbGwgYWRkIHRoaXMgY29tcGFueSB0byB5b3VyIFNFRUsgUHJvZmlsZSJ9!"),
        () => import(
          /* webpackChunkName: "en-SG-translations" */
          "./translations.json-en-SG-virtual.json!=!../../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJDb21wYW55IG5hbWUiOiJDb21wYW55IG5hbWUiLCJDb21wYW55IG5vdCB2ZXJpZmllZCI6IkNvbXBhbnkgbm90IHZlcmlmaWVkIiwiV2VsbCBhZGQgdGhpcyBjb21wYW55IHRvIHlvdXIgU0VFSyBQcm9maWxlIjoiV2UnbGwgYWRkIHRoaXMgY29tcGFueSB0byB5b3VyIFNFRUsgUHJvZmlsZSJ9!"
        )
      ),
"en-TH": createLanguage(
        require.resolveWeak("./translations.json-en-TH-virtual.json!=!../../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJDb21wYW55IG5hbWUiOiJDb21wYW55IG5hbWUiLCJDb21wYW55IG5vdCB2ZXJpZmllZCI6IkNvbXBhbnkgbm90IHZlcmlmaWVkIiwiV2VsbCBhZGQgdGhpcyBjb21wYW55IHRvIHlvdXIgU0VFSyBQcm9maWxlIjoiV2UnbGwgYWRkIHRoaXMgY29tcGFueSB0byB5b3VyIFNFRUsgUHJvZmlsZSJ9!"),
        () => import(
          /* webpackChunkName: "en-TH-translations" */
          "./translations.json-en-TH-virtual.json!=!../../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJDb21wYW55IG5hbWUiOiJDb21wYW55IG5hbWUiLCJDb21wYW55IG5vdCB2ZXJpZmllZCI6IkNvbXBhbnkgbm90IHZlcmlmaWVkIiwiV2VsbCBhZGQgdGhpcyBjb21wYW55IHRvIHlvdXIgU0VFSyBQcm9maWxlIjoiV2UnbGwgYWRkIHRoaXMgY29tcGFueSB0byB5b3VyIFNFRUsgUHJvZmlsZSJ9!"
        )
      ),
"th-TH": createLanguage(
        require.resolveWeak("./translations.json-th-TH-virtual.json!=!../../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJDb21wYW55IG5hbWUiOiLguIrguLfguYjguK3guJrguKPguLTguKnguLHguJciLCJDb21wYW55IG5vdCB2ZXJpZmllZCI6IuC4muC4o%2BC4tOC4qeC4seC4l%2BC4ouC4seC4h%2BC5hOC4oeC5iOC5hOC4lOC5ieC4o%2BC4seC4muC4geC4suC4o%2BC4leC4o%2BC4p%2BC4iOC4quC4reC4miIsIldlbGwgYWRkIHRoaXMgY29tcGFueSB0byB5b3VyIFNFRUsgUHJvZmlsZSI6IuC5gOC4o%2BC4suC4iOC4sOC5gOC4nuC4tOC5iOC4oeC4muC4o%2BC4tOC4qeC4seC4l%2BC4meC4teC5ieC5hOC4m%2BC4ouC4seC4h%2BC5guC4m%2BC4o%2BC5hOC4n%2BC4peC5jCBTRUVLIOC4guC4reC4h%2BC4hOC4uOC4kyJ9!"),
        () => import(
          /* webpackChunkName: "th-TH-translations" */
          "./translations.json-th-TH-virtual.json!=!../../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJDb21wYW55IG5hbWUiOiLguIrguLfguYjguK3guJrguKPguLTguKnguLHguJciLCJDb21wYW55IG5vdCB2ZXJpZmllZCI6IuC4muC4o%2BC4tOC4qeC4seC4l%2BC4ouC4seC4h%2BC5hOC4oeC5iOC5hOC4lOC5ieC4o%2BC4seC4muC4geC4suC4o%2BC4leC4o%2BC4p%2BC4iOC4quC4reC4miIsIldlbGwgYWRkIHRoaXMgY29tcGFueSB0byB5b3VyIFNFRUsgUHJvZmlsZSI6IuC5gOC4o%2BC4suC4iOC4sOC5gOC4nuC4tOC5iOC4oeC4muC4o%2BC4tOC4qeC4seC4l%2BC4meC4teC5ieC5hOC4m%2BC4ouC4seC4h%2BC5guC4m%2BC4o%2BC5hOC4n%2BC4peC5jCBTRUVLIOC4guC4reC4h%2BC4hOC4uOC4kyJ9!"
        )
      ),
"id-ID": createLanguage(
        require.resolveWeak("./translations.json-id-ID-virtual.json!=!../../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJDb21wYW55IG5hbWUiOiJOYW1hIHBlcnVzYWhhYW4geWFuZyBBbmRhIG5pbGFpIiwiQ29tcGFueSBub3QgdmVyaWZpZWQiOiJQZXJ1c2FoYWFuIGJlbHVtIHRlcnZlcmlmaWthc2kiLCJXZWxsIGFkZCB0aGlzIGNvbXBhbnkgdG8geW91ciBTRUVLIFByb2ZpbGUiOiJQZXJ1c2FoYWFuIGluaSBha2FuIGRpdGFtYmFoa2FuIGtlIHByb2ZpbCBTRUVLIEFuZGEifQ%3D%3D!"),
        () => import(
          /* webpackChunkName: "id-ID-translations" */
          "./translations.json-id-ID-virtual.json!=!../../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJDb21wYW55IG5hbWUiOiJOYW1hIHBlcnVzYWhhYW4geWFuZyBBbmRhIG5pbGFpIiwiQ29tcGFueSBub3QgdmVyaWZpZWQiOiJQZXJ1c2FoYWFuIGJlbHVtIHRlcnZlcmlmaWthc2kiLCJXZWxsIGFkZCB0aGlzIGNvbXBhbnkgdG8geW91ciBTRUVLIFByb2ZpbGUiOiJQZXJ1c2FoYWFuIGluaSBha2FuIGRpdGFtYmFoa2FuIGtlIHByb2ZpbCBTRUVLIEFuZGEifQ%3D%3D!"
        )
      ),
"zh-HK": createLanguage(
        require.resolveWeak("./translations.json-zh-HK-virtual.json!=!../../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJDb21wYW55IG5hbWUiOiJDb21wYW55IG5hbWUiLCJDb21wYW55IG5vdCB2ZXJpZmllZCI6IkNvbXBhbnkgbm90IHZlcmlmaWVkIiwiV2VsbCBhZGQgdGhpcyBjb21wYW55IHRvIHlvdXIgU0VFSyBQcm9maWxlIjoiV2UnbGwgYWRkIHRoaXMgY29tcGFueSB0byB5b3VyIFNFRUsgUHJvZmlsZSJ9!"),
        () => import(
          /* webpackChunkName: "zh-HK-translations" */
          "./translations.json-zh-HK-virtual.json!=!../../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJDb21wYW55IG5hbWUiOiJDb21wYW55IG5hbWUiLCJDb21wYW55IG5vdCB2ZXJpZmllZCI6IkNvbXBhbnkgbm90IHZlcmlmaWVkIiwiV2VsbCBhZGQgdGhpcyBjb21wYW55IHRvIHlvdXIgU0VFSyBQcm9maWxlIjoiV2UnbGwgYWRkIHRoaXMgY29tcGFueSB0byB5b3VyIFNFRUsgUHJvZmlsZSJ9!"
        )
      ),
"en-PSEUDO": createLanguage(
        require.resolveWeak("./translations.json-en-PSEUDO-virtual.json!=!../../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJDb21wYW55IG5hbWUiOiJbw4fDtsO2bcyCxqXEg8SD4LiB4Li14LmJw73DvSDguIHguLXguYnEg8SDbcyC4bq94bq9XSIsIkNvbXBhbnkgbm90IHZlcmlmaWVkIjoiW8OHw7bDtm3MgsalxIPEg%2BC4geC4teC5icO9w70g4LiB4Li14LmJw7bDtuG5ryDhub3hur3hur3FmcOsw6zGksOsw6zhur3hur3GjF0iLCJXZWxsIGFkZCB0aGlzIGNvbXBhbnkgdG8geW91ciBTRUVLIFByb2ZpbGUiOiJbxbThur3hur3hur0nxprGmiDEg8SDxIPGjMaMIOG5r%2BG4qcOsw6zDrMWhIMOnw7bDtsO2bcyCxqXEg8SDxIPguIHguLXguYnDvcO9w70g4bmvw7bDtsO2IMO9w73DvcO2w7bDtseax5rHmsWZIMWgw4vDi%2BG4sCDGpMWZw7bDtsO2xpLDrMOsw6zGmuG6veG6veG6vV0ifQ%3D%3D!"),
        () => import(
          /* webpackChunkName: "en-PSEUDO-translations" */
          "./translations.json-en-PSEUDO-virtual.json!=!../../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJDb21wYW55IG5hbWUiOiJbw4fDtsO2bcyCxqXEg8SD4LiB4Li14LmJw73DvSDguIHguLXguYnEg8SDbcyC4bq94bq9XSIsIkNvbXBhbnkgbm90IHZlcmlmaWVkIjoiW8OHw7bDtm3MgsalxIPEg%2BC4geC4teC5icO9w70g4LiB4Li14LmJw7bDtuG5ryDhub3hur3hur3FmcOsw6zGksOsw6zhur3hur3GjF0iLCJXZWxsIGFkZCB0aGlzIGNvbXBhbnkgdG8geW91ciBTRUVLIFByb2ZpbGUiOiJbxbThur3hur3hur0nxprGmiDEg8SDxIPGjMaMIOG5r%2BG4qcOsw6zDrMWhIMOnw7bDtsO2bcyCxqXEg8SDxIPguIHguLXguYnDvcO9w70g4bmvw7bDtsO2IMO9w73DvcO2w7bDtseax5rHmsWZIMWgw4vDi%2BG4sCDGpMWZw7bDtsO2xpLDrMOsw6zGmuG6veG6veG6vV0ifQ%3D%3D!"
        )
      )
      });
  
      export { translations as default };
    