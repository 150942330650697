import type { LoaderFunction } from 'react-router';

import type { RouteProps } from '../../../shared/RouteProps';
import { getLocationId } from '../../../shared/utils/getLocationId';
import { mapReviewModel } from './mappers';

const REVIEWS_PER_PAGE = 30;

const extractCompanyIdFromSlug = (input?: string): string | undefined => {
  if (!input) {
    return undefined;
  }
  const parts = input.split('-');
  return parts[parts.length - 1];
};

export const reviewsLoader =
  ({ services, config }: RouteProps): LoaderFunction =>
  async ({ params, request }) => {
    const companyId = extractCompanyIdFromSlug(params.companySlug);
    if (companyId === undefined) {
      throw new Error('Could not find companyId from params', params);
    }

    const url = new URL(request.url);
    const isDraft = url.searchParams.get('draft');

    const companyProfile =
      await services.companyProfileService.getCompanyProfile(
        companyId,
        Boolean(isDraft),
      );
    const locationId = getLocationId({
      locationSlug: params.locationSlug,
      reviewLocation: config.reviewLocation,
    });
    const reviews = await services.companyProfileService.getReviews({
      companyId,
      page: 1,
      sortOrder: 'most recent',
      perPage: REVIEWS_PER_PAGE,
      locationId,
    });

    const companyReviewLocations =
      await services.locationService.getCompanyReviewLocations({
        companyId,
        sort: true,
      });

    return mapReviewModel(companyProfile, reviews, companyReviewLocations);
  };
