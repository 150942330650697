import React, { useEffect } from 'react';
import { MainLayout } from '../../companyProfiles/layouts/mainLayout/MainLayout';

import { useAuth } from '../../shared/providers/authProvider';
import { AUTHENTICATION_TYPES } from '@seek/apac-candidate-header-footer/types';
import { Loader } from 'braid-design-system';
import { WriteAReviewContainer } from '../pages/WriteAReviewContainer';

type Props = React.PropsWithChildren;

export function WriteAReviewLayout(_props: Props) {
  const { authenticationStatus, ssoLogin } = useAuth();

  useEffect(() => {
    if (
      authenticationStatus === AUTHENTICATION_TYPES.UNAUTHENTICATED &&
      ssoLogin
    ) {
      ssoLogin();
    }
  }, [authenticationStatus, ssoLogin]);

  return (
    <MainLayout pageWidth="medium" showHeaderDivider>
      {authenticationStatus === AUTHENTICATION_TYPES.AUTH_PENDING ? (
        <Loader />
      ) : (
        <WriteAReviewContainer />
      )}
    </MainLayout>
  );
}
