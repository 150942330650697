import * as styles from './FittedPhoto.css';

interface FittedPhotoProps {
  alt: string;
  link: string;
}

const FittedPhoto = ({ link, alt }: FittedPhotoProps) => (
  <img src={link} className={styles.image} alt={alt} />
);

export default FittedPhoto;
