import { fromNow, useConfig, type Reply } from '@seek/libs-shared';
import { Box, Column, Columns, Stack, Text } from 'braid-design-system';

export const ReviewReply = ({ reply }: { reply: Reply }) => {
  const { language } = useConfig();
  return (
    <Box paddingY="small">
      <Columns space="gutter" collapseBelow="tablet">
        <Column width="content">
          <Box style={{ maxWidth: '80px' }}>
            {reply?.companyLogoUrl && (
              <img src={reply?.companyLogoUrl} alt="" width="100%" />
            )}
          </Box>
        </Column>
        <Column>
          <Stack space="small">
            <Text weight="strong">{reply?.from}</Text>
            <Text>{reply?.text}</Text>
          </Stack>
        </Column>
        <Column width="content">
          <Text tone="secondary" size="small">
            {fromNow(reply?.createdAt, language)}
          </Text>
        </Column>
      </Columns>
    </Box>
  );
};
