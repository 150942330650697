import { useEffect, useState } from 'react';
import { Box, Heading, HiddenVisually, Stack } from 'braid-design-system';
import { AUTHENTICATION_TYPES } from '@seek/apac-candidate-header-footer/types';

import {
  type Reviews,
  useConfig,
  useAppContext,
  type SortOrder,
  Section,
} from '@seek/libs-shared';

import { trackViewEvents } from '../../../shared/tracking/candidateApp/trackViewEvents';
import type { FlagReviewPayload } from '../../../';
import { ReviewsEmptyState } from '../../components/companyReviews/reviewsEmptyState/ReviewsEmptyState';
import { ReviewsSection } from '../../components/companyReviews/reviewsSection/ReviewsSection';
import { ReviewsSummarySection } from '../../components/companyReviews/reviewsSummarySection/ReviewsSummarySection';
import { useAuth } from '../../../shared/providers/authProvider';
import type { ReviewsTabModel } from './reviewsTabModel';
import { useTranslations } from '@vocab/react';
import translations from './.vocab';
import { SeoList } from '../../components/seoList/SeoList';

interface ReviewsPageProps {
  flagReview: (
    companyId: string,
    reviewId: string,
    payload: FlagReviewPayload,
  ) => Promise<boolean>;
  getCompanyReviewLocations: (variables: {
    companyId: string;
    sort?: boolean;
  }) => Promise<ReviewsTabModel['locations']>;
  getReviews: ({
    companyId,
    page,
    sortOrder,
    perPage,
  }: {
    companyId: string;
    page: number;
    sortOrder: SortOrder;
    perPage?: number;
  }) => Promise<Reviews>;
  isLocationRoute: boolean;
  showReviewReplyNotice: boolean;
  trackRegisterClicked: () => void;
  trackReviewsPaginationClicked: ({
    pageNumber,
  }: {
    pageNumber: number;
  }) => void;
  trackSignInClicked: () => void;
  trackSortOrderChanged: ({ sortOrder }: { sortOrder: SortOrder }) => void;
  trackWriteAReviewClicked: () => void;
  upvoteReview: ({
    reviewId,
    companyId,
  }: {
    companyId: string;
    reviewId: string;
  }) => Promise<void>;
  model?: ReviewsTabModel;
}

export function ReviewsPage({
  model,
  getReviews,
  upvoteReview,
  flagReview,
  trackWriteAReviewClicked,
  trackReviewsPaginationClicked,
  trackSortOrderChanged,
  trackSignInClicked,
  trackRegisterClicked,
  isLocationRoute,
  getCompanyReviewLocations,
}: ReviewsPageProps) {
  const [locationsModel, setLocationsModel] = useState<
    ReviewsTabModel['locations']
  >(model?.locations);
  const config = useConfig();
  const { companyId, companyName, ...context } = useAppContext();
  const { authenticationStatus } = useAuth();
  const { t } = useTranslations(translations);

  useEffect(() => {
    if (
      companyId &&
      companyName &&
      authenticationStatus !== AUTHENTICATION_TYPES.AUTH_PENDING
    )
      trackViewEvents.reviewsPage({
        config,
        context: { ...context, companyId, companyName },
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [companyId, companyName, authenticationStatus]);

  useEffect(() => {
    const loadLocations = async () => {
      if (!locationsModel) {
        const response = await getCompanyReviewLocations({
          companyId,
          sort: true,
        });
        setLocationsModel(response);
      }
    };
    loadLocations();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [locationsModel]);
  if (!model?.reviews?.paging?.total || model?.reviews?.paging?.total === 0) {
    return (
      <Box paddingY="xxlarge" paddingX={['medium', 'none']}>
        <ReviewsEmptyState
          companyName={model?.reviewSummary?.name}
          trackWriteAReviewClicked={trackWriteAReviewClicked}
          type={'NO_LIST'}
        />
      </Box>
    );
  }

  const shouldDisplayLocationsFooter =
    !isLocationRoute && locationsModel && Boolean(locationsModel?.length);

  return (
    <>
      <HiddenVisually>
        <Heading level="1">
          {t('Company name Company Reviews', {
            companyName,
          })}
        </Heading>
      </HiddenVisually>
      <Stack space={['none', 'large']}>
        {model?.reviewSummary && (
          <ReviewsSummarySection
            model={model.reviewSummary}
            trackWriteAReviewClicked={trackWriteAReviewClicked}
          />
        )}
        {model?.reviews && (
          <ReviewsSection
            model={model.reviews}
            getReviews={getReviews}
            upvoteReview={upvoteReview}
            flagReview={flagReview}
            trackWriteAReviewClicked={trackWriteAReviewClicked}
            trackReviewsPaginationClicked={trackReviewsPaginationClicked}
            trackSortOrderChanged={trackSortOrderChanged}
            trackSignInClicked={trackSignInClicked}
            trackRegisterClicked={trackRegisterClicked}
            mode="view"
          />
        )}
        {shouldDisplayLocationsFooter && (
          <Section mode="view">
            <SeoList
              title={t('Reviews by location')}
              seoList={locationsModel}
            />
          </Section>
        )}
      </Stack>
    </>
  );
}
