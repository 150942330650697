import type { CompanyProfile } from '@seek/libs-shared';
import type { LoaderFunction } from 'react-router';

import type { RouteProps } from '../../../shared/RouteProps';

import type { CultureModel } from './cultureModel';

const mapCultureModel = (companyProfile: CompanyProfile): CultureModel => ({
  perksAndBenefits: companyProfile.perksAndBenefits?.length
    ? companyProfile.perksAndBenefits.map((pb) => ({
        title: pb.title,
        description: pb.description,
      }))
    : undefined,
  cultureAndValues: companyProfile.cultureAndValues
    ? companyProfile.cultureAndValues
    : undefined,
  customStatement: {
    title: companyProfile.customStatement?.title ?? '',
    description: companyProfile.customStatement?.description ?? [],
  },
  gallery: companyProfile.gallery,
  companyName: companyProfile.name,
});

const extractCompanyIdFromSlug = (input?: string): string | undefined => {
  if (!input) {
    return undefined;
  }
  const parts = input.split('-');
  return parts[parts.length - 1];
};

export const cultureLoader =
  ({ services }: RouteProps): LoaderFunction =>
  async ({ params, request }): Promise<CultureModel> => {
    const companyId = extractCompanyIdFromSlug(params.companySlug);

    if (companyId === undefined) {
      throw new Error('Could not find companyId from params', params);
    }

    const url = new URL(request.url);
    const isDraft = url.searchParams.get('draft');

    const companyProfile =
      await services.companyProfileService.getCompanyProfile(
        companyId,
        Boolean(isDraft),
      );

    return mapCultureModel(companyProfile);
  };
