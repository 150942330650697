const mockMarqueeCompanies = [
  {
    id: '1',
    title: 'see-civil',
    logo: 'https://cpp-prod-seek-company-image-uploads.s3.ap-southeast-2.amazonaws.com/813654/logo/65483c70-bcca-11ea-86d1-e52bae5cc086.png',
    reviews: {
      rating: 4.8,
      count: 48,
    },
    companyNameSlug: 'see-civil-813341',
    jobCount: 58,
  },
  {
    id: '2',
    title: 'Seek',
    logo: 'https://cpp-prod-seek-company-image-uploads.s3.ap-southeast-2.amazonaws.com/814881/logo/6592b2f1-bcca-11ea-86d1-e52bae5cc086.jpeg',
    reviews: {
      rating: 4.8,
      count: 48,
    },
    companyNameSlug: 'seek-432600',
    jobCount: 58,
  },
  {
    id: '3',
    title: 'BritishPetroleumCorporation',
    logo: 'https://image-service-cdn.seek.com.au/1122710e7591045ef87bf65bebb6405cf9e2db3a/f3c5292cec0e05e4272d9bf9146f390d366481d0',
    reviews: {
      rating: 4.8,
      count: 48,
    },
    companyNameSlug: 'title-3',
    jobCount: 58,
  },
  {
    id: '4',
    title: 'title 4',
    logo: 'https://cpp-prod-seek-company-image-uploads.s3.ap-southeast-2.amazonaws.com/432321/logo/6b2a1fa0-baae-11ea-a206-b94fc8450258.png',
    reviews: {
      rating: 4.8,
      count: 48,
    },
    companyNameSlug: 'title-4',
    jobCount: 58,
  },
  {
    id: '5',
    title: 'title 5',
    logo: 'https://image-service-cdn.seek.com.au/71a9d0b3fd872700e1996c7736b8c7df0f6cc07e',
    reviews: {
      rating: 4.8,
      count: 48,
    },
    companyNameSlug: 'title-5',
    jobCount: 58,
  },
  {
    id: '6',
    title: 'title 6',
    logo: 'https://cpp-prod-seek-company-image-uploads.s3.ap-southeast-2.amazonaws.com/813654/logo/65483c70-bcca-11ea-86d1-e52bae5cc086.png',
    reviews: {
      rating: 4.8,
      count: 48,
    },
    companyNameSlug: 'title-6',
    jobCount: 58,
  },
  {
    id: '7',
    title: 'title 7',
    logo: 'https://cpp-prod-seek-company-image-uploads.s3.ap-southeast-2.amazonaws.com/813654/logo/65483c70-bcca-11ea-86d1-e52bae5cc086.png',
    reviews: {
      rating: 4.8,
      count: 48,
    },
    companyNameSlug: 'title-7',
    jobCount: 58,
  },
  {
    id: '8',
    title: 'title 8',
    logo: 'https://cpp-prod-seek-company-image-uploads.s3.ap-southeast-2.amazonaws.com/813654/logo/65483c70-bcca-11ea-86d1-e52bae5cc086.png',
    reviews: {
      rating: 4.8,
      count: 48,
    },
    companyNameSlug: 'title-8',
    jobCount: 58,
  },
  {
    id: '9',
    title: 'title 9',
    logo: 'https://cpp-prod-seek-company-image-uploads.s3.ap-southeast-2.amazonaws.com/813654/logo/65483c70-bcca-11ea-86d1-e52bae5cc086.png',
    reviews: {
      rating: 4.8,
      count: 48,
    },
    companyNameSlug: 'title-9',
    jobCount: 58,
  },
  {
    id: '10',
    title: 'title 10',
    logo: 'https://cpp-prod-seek-company-image-uploads.s3.ap-southeast-2.amazonaws.com/813654/logo/65483c70-bcca-11ea-86d1-e52bae5cc086.png',
    reviews: {
      rating: 4.8,
      count: 48,
    },
    companyNameSlug: 'title-10',
    jobCount: 58,
  },
  {
    id: '11',
    title: 'title 11',
    logo: 'https://cpp-prod-seek-company-image-uploads.s3.ap-southeast-2.amazonaws.com/813654/logo/65483c70-bcca-11ea-86d1-e52bae5cc086.png',
    reviews: {
      rating: 4.8,
      count: 48,
    },
    companyNameSlug: 'title-11',
    jobCount: 58,
  },
  {
    id: '12',
    title: 'title 12',
    logo: 'https://cpp-prod-seek-company-image-uploads.s3.ap-southeast-2.amazonaws.com/813654/logo/65483c70-bcca-11ea-86d1-e52bae5cc086.png',
    reviews: {
      rating: 4.8,
      count: 48,
    },
    companyNameSlug: 'title-12',
    jobCount: 58,
  },
  {
    id: '13',
    title: 'title 13',
    logo: 'https://cpp-prod-seek-company-image-uploads.s3.ap-southeast-2.amazonaws.com/813654/logo/65483c70-bcca-11ea-86d1-e52bae5cc086.png',
    reviews: {
      rating: 4.8,
      count: 48,
    },
    companyNameSlug: 'title-13',
    jobCount: 58,
  },
  {
    id: '14',
    title: 'title 14',
    logo: 'https://cpp-prod-seek-company-image-uploads.s3.ap-southeast-2.amazonaws.com/813654/logo/65483c70-bcca-11ea-86d1-e52bae5cc086.png',
    reviews: {
      rating: 4.8,
      count: 48,
    },
    companyNameSlug: 'title-14',
    jobCount: 58,
  },
  {
    id: '15',
    title: 'title 15',
    logo: 'https://cpp-prod-seek-company-image-uploads.s3.ap-southeast-2.amazonaws.com/813654/logo/65483c70-bcca-11ea-86d1-e52bae5cc086.png',
    reviews: {
      rating: 4.7,
      count: 555,
    },
    companyNameSlug: 'title-15',
    jobCount: 58,
  },
];

const Query = {
  marqueeCompanies: async () => mockMarqueeCompanies,
};

export { Query };
