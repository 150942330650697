import { useTranslations } from '@vocab/react';
import {
  StarSelector,
  type Rating,
} from '../../components/StarSelector/StarSelector';
import type { QuestionProps } from '../../type';
import translations from './.vocab';

export const OverallRatingQuestion = (props: QuestionProps<Rating>) => {
  const { t } = useTranslations(translations);
  return (
    <StarSelector
      id="overallRating"
      {...props}
      isRequired={true}
      title={t('How was your overall experience?')}
    />
  );
};
