import type { LoaderFunction } from 'react-router';

import type { RouteProps } from '../../../shared/RouteProps';

import type { HomePageModel } from './homePageModel';

export const homePageLoader =
  ({ services }: RouteProps): LoaderFunction =>
  async ({ params: _params, request: _request }): Promise<HomePageModel> => {
    const topCompanies =
      await services.companyProfileService.getGroupedCompanies({
        groupBy: {
          group: 'INDUSTRY',
          value: [],
        },
        perPage: 1,
        sortBy: 'REVIEWS_COUNT',
      });
    return { topCompanies };
  };
