export default [
  'laho',
  'tarawera',
  'tutae',
  'pokokohua',
  'teko',
  'tiko',
  'gestapo',
  'snatch',
  'collude',
  'colluding',
  'abuse',
  'abused',
  'abuses',
  'abusive',
  'ass',
  'asses',
  'backstab',
  'backstabbed',
  'balls',
  'bigot',
  'bigotries',
  'bigotry',
  'bullied',
  'bullies',
  'bully',
  'bullying',
  'bum',
  'cokehead',
  'discriminate',
  'discriminated',
  'discrimination',
  'druggie',
  'druggies',
  'dumb',
  'harass',
  'harassed',
  'harasses',
  'harassing',
  'harassment',
  'homophobia',
  'homophobic',
  'illegal',
  'illegally',
  'intimidate',
  'intimidating',
  'jerk',
  'junkie',
  'junkies',
  'muff',
  'pothead',
  'racism',
  'racist',
  'racists',
  'sex',
  'sexism',
  'sexist',
  'sexual',
  'slave',
  'suicidal',
  'suicide',
  'suicided',
  'suicides',
  'tard',
  'transphobia',
  'transphobic',
  'anal',
  'anus',
  'arse',
  'arsehole',
  'assbag',
  'assbandit',
  'assbanger',
  'assbite',
  'assclown',
  'asscock',
  'asscracker',
  'assface',
  'assfuck',
  'assfucker',
  'assgoblin',
  'asshat',
  'ass-hat',
  'asshead',
  'asshole',
  'asshopper',
  'assjacker',
  'asslick',
  'asslicker',
  'assmonkey',
  'assmunch',
  'assmuncher',
  'assnigger',
  'asspirate',
  'ass-pirate',
  'assshit',
  'assshole',
  'asssucker',
  'asswad',
  'asswipe',
  'ballsack',
  'bampot',
  'bastard',
  'beaner',
  'biatch',
  'bitch',
  'bitchass',
  'bitches',
  'bitchtits',
  'bitchy',
  'blowjob',
  'bollock',
  'bollocks',
  'bollok',
  'bollox',
  'boob',
  'brotherfucker',
  'bullshit',
  'bumblefuck',
  'buttfucka',
  'buttfucker',
  'butt-pirate',
  'buttplug',
  'carpetmuncher',
  'chinc',
  'chink',
  'choad',
  'chode',
  'clit',
  'clitface',
  'clitfuck',
  'clitoris',
  'clusterfuck',
  'cockass',
  'cockbite',
  'cockburger',
  'cockface',
  'cockfucker',
  'cockhead',
  'cockjockey',
  'cockknoker',
  'cockmaster',
  'cockmongler',
  'cockmongruel',
  'cockmonkey',
  'cockmuncher',
  'cocknose',
  'cocknugget',
  'cockshit',
  'cocksmith',
  'cocksmoker',
  'cocksucker',
  'coochie',
  'coochy',
  'crap',
  'cum',
  'cumbubble',
  'cumdumpster',
  'cumguzzler',
  'cumjockey',
  'cumslut',
  'cumtart',
  'cunnie',
  'cunnilingus',
  'cunt',
  'cuntface',
  'cunthole',
  'cuntlicker',
  'cuntrag',
  'cuntslut',
  'dago',
  'deggo',
  'dickbag',
  'dickbeaters',
  'dickface',
  'dickfuck',
  'dickfucker',
  'dickhead',
  'dickhole',
  'dickjuice',
  'dickmilk',
  'dickmonger',
  'dickslap',
  'dicksucker',
  'dicksucking',
  'dickwad',
  'dickweasel',
  'dickweed',
  'dickwod',
  'dildo',
  'dipshit',
  'doochbag',
  'fag',
  'fagbag',
  'fagfucker',
  'faggit',
  'faggot',
  'faggotcock',
  'fagtard',
  'fatass',
  'feck',
  'felching',
  'fellate',
  'fellatio',
  'feltch',
  'flamer',
  'fuck',
  'fuckass',
  'fuckbag',
  'fuckboy',
  'fuckbrain',
  'fuckbutt',
  'fucked',
  'fucker',
  'fuckersucker',
  'fuckface',
  'fuckhead',
  'fuckhole',
  'fuckin',
  'fucking',
  'fucknut',
  'fucknutt',
  'fuckoff',
  'fucks',
  'fuckstick',
  'fucktard',
  'fuckup',
  'fuckwad',
  'fuckwit',
  'fuckwitt',
  'fudgepacker',
  'gayass',
  'gaybob',
  'gaydo',
  'gayfuck',
  'gayfuckist',
  'gaytard',
  'gaywad',
  'goddamnit',
  'gringo',
  'handjob',
  'homo',
  'homodumbshit',
  'honkey',
  'humping',
  'inbred',
  'inbreds',
  'jackass',
  'jigaboo',
  'jizz',
  'junglebunny',
  'kike',
  'knobend',
  'kooch',
  'kootch',
  'kunt',
  'kyke',
  'labia',
  'lesbo',
  'lezzie',
  'lmfao',
  'mcfagget',
  'mothafucka',
  'motherfucker',
  'motherfucking',
  'muffdiver',
  'munging',
  'nigga',
  'nigger',
  'niggers',
  'niglet',
  'nutsack',
  'panooch',
  'pecker',
  'peckerhead',
  'penis',
  'penisfucker',
  'penispuffer',
  'piss',
  'pissed',
  'pissflaps',
  'polesmoker',
  'poonani',
  'poonany',
  'poontang',
  'poop',
  'porchmonkey',
  'prick',
  'pube',
  'punanny',
  'pussies',
  'pussy',
  'pussylicking',
  'queef',
  'queerbait',
  'queerhole',
  'renob',
  'rimjob',
  'ruski',
  'sandnigger',
  'schlong',
  'scrote',
  'scrotum',
  'sexworker',
  'sh1t',
  'shit',
  'shitass',
  'shitbag',
  'shitbagger',
  'shitbrains',
  'shitbreath',
  'shitcunt',
  'shitdick',
  'shitface',
  'shitfaced',
  'shithead',
  'shithole',
  'shithouse',
  'shitspitter',
  'shitstain',
  'shitter',
  'shittiest',
  'shitting',
  'shitty',
  'shiznit',
  'skullfuck',
  'slut',
  'slutbag',
  'smeg',
  'smegma',
  'sodamised',
  'sodamized',
  'sodomised',
  'sodomized',
  'spic',
  'splooge',
  'spook',
  'spunk',
  'testicle',
  'thundercunt',
  'tit',
  'titfuck',
  'tits',
  'tittyfuck',
  'tosser',
  'turd',
  'twat',
  'twatlips',
  'twats',
  'twatwaffle',
  'unclefucker',
  'vagina',
  'va-j-j',
  'vjayjay',
  'wank',
  'wetback',
  'whore',
  'whorebag',
  'whoreface',
  'wop',
  'wtf',
  '2g1c',
  '4r5e',
  '5h1t',
  '5hit',
  'a_s_s',
  'a54',
  'a55',
  'a55hole',
  'abbo',
  'abo',
  'abortion',
  'acrotomophilia',
  'addict',
  'addicts',
  'aeolus',
  'ahole',
  'alcoholic',
  'allah',
  'alligatorbait',
  'analannie',
  'analphabet',
  'analprobe',
  'analsex',
  'anarchist',
  'anilingus',
  'ape',
  'apeshit',
  'ar5e',
  'areola',
  'areole',
  'argie',
  'arian',
  'aroused',
  'arrse',
  'arselicker',
  'aryan',
  'assassin',
  'assassinate',
  'assassination',
  'assault',
  'assbagger',
  'assbang',
  'assbanged',
  'assbangs',
  'assblaster',
  'asscowboy',
  'assfaces',
  'ass-fucker',
  'assfukka',
  'assh0le',
  'assho1e',
  'assholes',
  'asshore',
  'ass-jabber',
  'assjockey',
  'asskiss',
  'asskisser',
  'ass-kisser',
  'assklown',
  'asslover',
  'assman',
  'assmaster',
  'assmucus',
  'ass-nugget',
  'asspacker',
  'asspuppies',
  'assranger',
  'asswhole',
  'asswhore',
  'ass-wipe',
  'asswipes',
  'autoerotic',
  'axwound',
  'azazel',
  'azz',
  'b!tch',
  'b00bs',
  'b17ch',
  'b1tch',
  'babeland',
  'babes',
  'backdoorman',
  'backwoodsman',
  'badfuck',
  'ballbag',
  'balllicker',
  'bandit',
  'bangbros',
  'banger',
  'barbar',
  'bareback',
  'barenaked',
  'barf',
  'barface',
  'barfface',
  'bastardo',
  'bastards',
  'bastinado',
  'bawdy',
  'bazongas',
  'bazooms',
  'bbw',
  'bdsm',
  'beaners',
  'beardedclam',
  'beastial',
  'beastiality',
  'beatch',
  'beatoff',
  'beat-off',
  'beatyourmeat',
  'beavis',
  'beeyotch',
  'bellend',
  'bender',
  'beotch',
  'bescumber',
  'bestial',
  'bestiality',
  'bi+ch',
  'bicurious',
  'biest',
  'bigass',
  'bigbastard',
  'bigbutt',
  'bigtits',
  'bimbo',
  'bimbos',
  'bint',
  'birdlock',
  'bisexual',
  'bi-sexual',
  'bitched',
  'bitcher',
  'bitchers',
  'bitchez',
  'bitchin',
  'bitching',
  'bitchslap',
  'biteme',
  'blacks',
  'bloodclaat',
  'blowjobs',
  'bludger',
  'blumpkin',
  'blumpkin',
  'boang',
  'bogan',
  'bogeyman',
  'bohunk',
  'boink',
  'boiolas',
  'bollick',
  'bombing',
  'bombs',
  'bomd',
  'bondage',
  'boner',
  'boners',
  'bong',
  'boobies',
  'boobs',
  'booby',
  'booger',
  'bookie',
  'boong',
  'boonga',
  'boonie',
  'booobs',
  'boooobs',
  'booooobs',
  'booooooobs',
  'bootee',
  'bootie',
  'booty',
  'bootycall',
  'booze',
  'boozer',
  'boozy',
  'bosom',
  'bosomy',
  'bountybar',
  'bowel',
  'bozo',
  'brain-fart',
  'brea5t',
  'breast',
  'breastjob',
  'breastlover',
  'breastman',
  'breasts',
  'breeder',
  'brontosaurus',
  'brothel',
  'brownie',
  'buceta',
  'buggery',
  'bukkake',
  'bullcrap',
  'bulldike',
  'bulldyke',
  'bulloks',
  'bullshits',
  'bullshitted',
  'bullturds',
  'bumclat',
  'bumfuck',
  'bum-fucker',
  'bumtoucher',
  'buncombe',
  'bung',
  'bunga',
  'bunghole',
  'busty',
  'butchbabes',
  'butchdike',
  'butchdyke',
  'butt',
  'buttbang',
  'butt-bang',
  'buttcheeks',
  'buttface',
  'buttfuck',
  'butt-fuck',
  'butt-fucker',
  'buttfuckers',
  'butt-fuckers',
  'butthead',
  'butthole',
  'buttman',
  'buttmuch',
  'buttmunch',
  'buttmuncher',
  'buttpirate',
  'buttstain',
  'byatch',
  'c.0.c.k',
  'c.o.c.k.',
  'c.u.n.t',
  'c0ck',
  'c-0-c-k',
  'c0cksucker',
  'caca',
  'cacafuego',
  'cacker',
  'cahone',
  'callboy',
  'callgirl',
  'cameljockey',
  'cameltoe',
  'camgirl',
  'camslut',
  'camwhore',
  'cannibal',
  'carruth',
  'cawk',
  'cervix',
  'chaavanist',
  'chaot',
  'chauvi',
  'chav',
  'cheater',
  'cherrypopper',
  'chesticle',
  'chickslick',
  'child-fucker',
  'chincs',
  'chinky',
  'choade',
  'chodes',
  'cipa',
  'circlejerk',
  'cl1t',
  'clamdigger',
  'clamdiver',
  'climax',
  'clitorus',
  'clits',
  'clitty',
  'clogwog',
  'clunge',
  'cnut',
  'cocain',
  'cocaine',
  'coccydynia',
  'cock',
  'c-o-c-k',
  'cockblock',
  'cockblocker',
  'cockboy',
  'cockcowboy',
  'cockeye',
  'cockfight',
  'cockholster',
  'cockknob',
  'cockknocker',
  'cocklicker',
  'cocklover',
  'cocklump',
  'cockmunch',
  'cocknob',
  'cockqueen',
  'cockrider',
  'cocks',
  'cocksman',
  'cocksmoke',
  'cocksniffer',
  'cocksucer',
  'cocksuck',
  'cocksucked',
  'cock-sucker',
  'cocksuckers',
  'cocksucking',
  'cocksucks',
  'cocksuka',
  'cocksukka',
  'cocktease',
  'cockwaffle',
  'cocky',
  'cohee',
  'coital',
  'coitus',
  'cok',
  'cokmuncher',
  'coksucka',
  'coky',
  'commie',
  'communist',
  'condom',
  'con-man',
  'coolie',
  'cooly',
  'coon',
  'coondog',
  'coonnass',
  'coons',
  'cooter',
  'coprolagnia',
  'coprophilia',
  'copulate',
  'corksucker',
  'cornhole',
  'cornhole',
  'corpulent',
  'corruption',
  'cow',
  'cra5h',
  'crackpipe',
  'crackwhore',
  'crack-whore',
  'crapola',
  'crapper',
  'creampie',
  'creep',
  'cretin',
  'crikey',
  'criminal',
  'criminals',
  'crotch',
  'crotchjockey',
  'crotchmonkey',
  'crotchrot',
  'crotte',
  'cumdump',
  'cumfest',
  'cumm',
  'cummer',
  'cummin',
  'cumming',
  'cumquat',
  'cumqueen',
  'cums',
  'cumshot',
  'cumshots',
  'cumstain',
  'cunilingus',
  'cunillingus',
  'cunn',
  'cunntt',
  'cunny',
  'c-u-n-t',
  'cuntass',
  'cuntbag',
  'cunteyed',
  'cuntfuck',
  'cuntfucker',
  'cunthunter',
  'cuntlick',
  'cuntlicking',
  'cunts',
  'cuntsicle',
  'cunt-struck',
  'cuntsucker',
  'cyalis',
  'cyberfuc',
  'cyberfuck',
  'cyberfucked',
  'cyberfucker',
  'cyberfuckers',
  'cyberfucking',
  'cybersex',
  'cyberslimer',
  'd0ng',
  'd0uch3',
  'd0uche',
  'd1ck',
  'd1ld0',
  'd1ldo',
  'dagos',
  'dahmer',
  'dammit',
  'damnit',
  'darkie',
  'darky',
  'daterape',
  'datnigga',
  'dawgie-style',
  'daywalker',
  'deapthroat',
  'death',
  'deathlord',
  'deepthroat',
  'defecate',
  'dego',
  'dendrophilia',
  'desperado',
  'deth',
  'devilworshipper',
  'dick',
  'dickbrain',
  'dickdipper',
  'dickflipper',
  'dickforbrains',
  'dickheads',
  'dickish',
  'dick-ish',
  'dickless',
  'dicklick',
  'dicklicker',
  'dickman',
  'dickmilk',
  'dickripper',
  'dicks',
  'dicksipper',
  'dick-sneeze',
  'dicktickler',
  'dickwhipper',
  'dickzipper',
  'diddle',
  'dike',
  'dildos',
  'diligaf',
  'dillweed',
  'dimwit',
  'dingaling',
  'dingle',
  'dingleberries',
  'dingleberry',
  'dink',
  'dinks',
  'dipship',
  'dipstick',
  'dirsa',
  'dix',
  'dixiedike',
  'dixiedyke',
  'dlck',
  'do-do',
  'dog-fucker',
  'doggiestyle',
  'doggie-style',
  'doggin',
  'doggystyle',
  'doggy-style',
  'dogshit',
  'dolcett',
  'dominatrix',
  'dommes',
  'dong',
  'donga',
  'donkey',
  'donkeypunch',
  'donkeyribber',
  'doodoo',
  'doo-doo',
  'doofus',
  'dookie',
  'doosh',
  'doublelift',
  'douch3',
  'douche',
  'douchebag',
  'douchebags',
  'douche-fag',
  'douchewaffle',
  'douchey',
  'dragqueen',
  'dragqween',
  'drakula',
  'dreamer',
  'drinker',
  'dripdick',
  'drunkard',
  'duche',
  'dufus',
  'dulles',
  'dumass',
  'dumbass',
  'dumbasses',
  'dumbbitch',
  'dumbcunt',
  'dumbfuck',
  'dumbo',
  'dumbshit',
  'dumpy',
  'dumshit',
  'dvda',
  'dyefly',
  'dyke',
  'dykes',
  'easyslut',
  'eatballs',
  'eatme',
  'eatpussy',
  'ecchi',
  'ecstacy',
  'egoist',
  'ejaculate',
  'ejaculated',
  'ejaculates',
  'ejaculating',
  'ejaculatings',
  'ejaculation',
  'ejakulate',
  'enema',
  'erect',
  'ero',
  'erotic',
  'erotism',
  'escort',
  'essohbee',
  'eunuch',
  'evl',
  'excrement',
  'exhibitionist',
  'extacy',
  'extasy',
  'f.u.c.k',
  'f_u_c_k',
  'f4nny',
  'facefucker',
  'facial',
  'fack',
  'faeces',
  'fagg',
  'fagged',
  'fagging',
  'faggitt',
  'faggots',
  'faggs',
  'fagot',
  'fagots',
  'fags',
  'faig',
  'faigt',
  'fanny',
  'fannybandit',
  'fannyflaps',
  'fannyfucker',
  'fanyy',
  'fart',
  'farted',
  'farting',
  'fartknocker',
  'farty',
  'fastfuck',
  'fatah',
  'fatfuck',
  'fatfucker',
  'fatso',
  'fckcum',
  'fcuk',
  'fcuker',
  'fcuking',
  'fecal',
  'feces',
  'fecker',
  'feist',
  'felatio',
  'felch',
  'felcher',
  'feltcher',
  'feltching',
  'femdom',
  'fenian',
  'fetish',
  'fibber',
  'fice',
  'figging',
  'filipina',
  'filipino',
  'fingerbang',
  'fingerfood',
  'fingerfuck',
  'fingerfucked',
  'fingerfucker',
  'fingerfuckers',
  'fingerfucking',
  'fingerfucks',
  'fingering',
  'fister',
  'fistfuck',
  'fistfucked',
  'fistfucker',
  'fistfuckers',
  'fistfucking',
  'fistfuckings',
  'fistfucks',
  'fisting',
  'fisty',
  'fixer',
  'flake',
  'flaps',
  'flasher',
  'flatulence',
  'fleshflute',
  'floo',
  'floozy',
  'flydie',
  'flydye',
  'foad',
  'foah',
  'fok',
  'fondle',
  'foobar',
  'fook',
  'fooker',
  'footaction',
  'footfuck',
  'footfucker',
  'footjob',
  'footlicker',
  'footstar',
  'foreskin',
  'forni',
  'fornicate',
  'foursome',
  'fourtwenty',
  'freak',
  'freakfuck',
  'freakyfucker',
  'freefuck',
  'freex',
  'frenchify',
  'frigg',
  'frigga',
  'frog',
  'frotting',
  'fu',
  'fubar',
  'fuc',
  'fucck',
  'f-u-c-k',
  'fuck',
  'fucka',
  'fuckable',
  'fuck-ass',
  'fuck-bitch',
  'fuckbuddy',
  'fuckbutter',
  'fuckedup',
  'fuckers',
  'fuckfest',
  'fuckfreak',
  'fuckfriend',
  'fuckheads',
  'fuckher',
  'fuckina',
  'fuckingbitch',
  'fuckings',
  'fuckingshitmotherfucker',
  'fuckinnuts',
  'fuckinright',
  'fuckit',
  'fuckknob',
  'fuckme',
  'fuckmeat',
  'fuckmehard',
  'fuckmonkey',
  'fucknuckle',
  'fucknugget',
  'fuckpig',
  'fuck-tard',
  'fucktards',
  'fucktart',
  'fucktoy',
  'fucktwat',
  'fuckwhit',
  'fuckwhore',
  'fuckyou',
  'fudge-packer',
  'fugly',
  'fuk',
  'fuker',
  'fukker',
  'fukkers',
  'fukkin',
  'fuks',
  'fukwhit',
  'fukwit',
  'funfuck',
  'fungus',
  'fuq',
  'futanari',
  'fuuck',
  'fux',
  'fux0r',
  'fvck',
  'fxck',
  'gae',
  'gangbang',
  'gang-bang',
  'gangbanged',
  'gangbanger',
  'gangbangs',
  'gangsta',
  'ganja',
  'gash',
  'gatorbait',
  'gay',
  'gaylord',
  'gaymuthafuckinwhore',
  'gays',
  'gaysex',
  'geezer',
  'geni',
  'genital',
  'genitals',
  'getiton',
  'gfy',
  'ghey',
  'gigolo',
  'ginzo',
  'gipp',
  'gippo',
  'givehead',
  'glans',
  'glazeddonut',
  'goatcx',
  'goatse',
  'godammit',
  'godamn',
  'godamnit',
  'goddam',
  'god-dam',
  'goddamit',
  'goddammit',
  'goddamned',
  'god-damned',
  'goddamnes',
  'goddamnmuthafucker',
  'godsdamn',
  'gokkun',
  'goldenshower',
  'golliwog',
  'gonad',
  'gonads',
  'gonorrehea',
  'gonzagas',
  'gooch',
  'goodpoop',
  'gook',
  'gooks',
  'goose',
  'goregasm',
  'gorilla',
  'gotohell',
  'goyim',
  'greaseball',
  'groe',
  'gronk',
  'grope',
  'grostulation',
  'grouch',
  'gspot',
  'g-spot',
  'gtfo',
  'gubba',
  'guido',
  'gummer',
  'guro',
  'gyp',
  'gypo',
  'gypp',
  'gyppie',
  'gyppo',
  'gyppy',
  'h0m0',
  'h0mo',
  'hamas',
  'hapa',
  'hardcoresex',
  'hardon',
  'harem',
  'he11',
  'headfuck',
  'headlights',
  'hebe',
  'heeb',
  'hemp',
  'henhouse',
  'hentai',
  'heroin',
  'herp',
  'herpes',
  'herpy',
  'he-she',
  'heterosexual',
  'hijacker',
  'hijacking',
  'hillbillies',
  'hillbilly',
  'hindoo',
  'hippie',
  'hircismus',
  'hiscock',
  'hitler',
  'hitlerism',
  'hitlerist',
  'hoar',
  'hoare',
  'hobag',
  'hodgie',
  'hoer',
  'hoes',
  'holestuffer',
  'hom0',
  'homicide',
  'homobangers',
  'homoerotic',
  'homoey',
  'homosexual',
  'honger',
  'honkers',
  'honky',
  'hooch',
  'hookah',
  'hooker',
  'hookers',
  'hooligan',
  'hoor',
  'hootch',
  'hooter',
  'hore',
  'hork',
  'horney',
  'horniest',
  'horny',
  'horseshit',
  'hosejob',
  'hoser',
  'hostage',
  'hotdamn',
  'hotpussy',
  'hotsex',
  'hottotrot',
  'humped',
  'hun',
  'husky',
  'hussy',
  'hustler',
  'hymen',
  'hymie',
  'iblowu',
  'idiot',
  'ignoramus',
  'ikey',
  'incest',
  'injun',
  'insest',
  'intercourse',
  'interracial',
  'intheass',
  'inthebuff',
  'j3rk0ff',
  'jack-ass',
  'jackasses',
  'jackhole',
  'jackoff',
  'jack-off',
  'jackshit',
  'jacktheripper',
  'jaggi',
  'jagoff',
  'jailbait',
  'jap',
  'japcrap',
  'japs',
  'jebus',
  'jeez',
  'jerk0ff',
  'jerkass',
  'jerked',
  'jerkoff',
  'jerk-off',
  'jesus',
  'jiga',
  'jigg',
  'jigga',
  'jiggabo',
  'jiggaboo',
  'jigger',
  'jiggerboo',
  'jiggy',
  'jihad',
  'jijjiboo',
  'jimfish',
  'jism',
  'jiz',
  'jizim',
  'jizjuice',
  'jizm',
  'jizzed',
  'jizzim',
  'jizzum',
  'jock',
  'joker',
  'jonas',
  'juggalo',
  'juggs',
  'jugs',
  'junkey',
  'junky',
  'kaffer',
  'kaffir',
  'kaffre',
  'kafir',
  'kanake',
  'kawk',
  'kigger',
  'kikes',
  'kinbaku',
  'kink',
  'kinkster',
  'kinky',
  'klan',
  'knob',
  'knobbing',
  'knobead',
  'knobed',
  'knobhead',
  'knobjocky',
  'knobjokey',
  'knockers',
  'kock',
  'kondum',
  'kondums',
  'kooches',
  'koon',
  'kotex',
  'krap',
  'krappy',
  'kraut',
  'kum',
  'kumbubble',
  'kumbullbe',
  'kummer',
  'kumming',
  'kumquat',
  'kums',
  'kunilingus',
  'kunja',
  'kunnilingus',
  'kwif',
  'ky',
  'l3i+ch',
  'l3itch',
  'lactate',
  'lameass',
  'lapdance',
  'lardass',
  'lech',
  'len',
  'lesbain',
  'lesbayn',
  'lesbian',
  'lesbians',
  'lesbin',
  'lesbos',
  'lez',
  'lezbe',
  'lezbefriends',
  'lezbian',
  'lezbians',
  'lezbo',
  'lezbos',
  'lezz',
  'lezza/lesbo',
  'lezzies',
  'lezzo',
  'lezzy',
  'libido',
  'licker',
  'lickme',
  'limey',
  'limpdick',
  'limy',
  'lingerie',
  'livesex',
  'lmao',
  'loadedgun',
  'loins',
  'lolita',
  'looney',
  'looser',
  'lotion',
  'lovebone',
  'lovegoo',
  'lovegun',
  'lovejuice',
  'lovemaking',
  'lovemuscle',
  'lovepistol',
  'loverocket',
  'lowlife',
  'lsd',
  'lubejob',
  'lucifer',
  'luckycammeltoe',
  'lugan',
  'lumpy',
  'lusting',
  'luzifer',
  'm0f0',
  'm0fo',
  'm45terbate',
  'ma5terb8',
  'ma5terbate',
  'macaca',
  'macker',
  'mafia',
  'mafugly',
  'magicwand',
  'mams',
  'manhater',
  'manpaste',
  'marijuana',
  'masochist',
  'massa',
  'mastabate',
  'mastabater',
  'masterb8',
  'masterbat*',
  'masterbat3',
  'masterbate',
  'master-bate',
  'masterbating',
  'masterbation',
  'masterbations',
  'masterblaster',
  'mastrabator',
  'masturbate',
  'masturbating',
  'masturbation',
  'mattressprincess',
  'meataxe',
  'meatbeatter',
  'meatrack',
  'menses',
  'menstruate',
  'menstruation',
  'meth',
  'm-fucking',
  'mgger',
  'mggor',
  'mickeyfinn',
  'microphallus',
  'mideast',
  'midget',
  'milf',
  'minge',
  'minger',
  'minx',
  'mockey',
  'mockie',
  'mocky',
  'mof0',
  'mofo',
  'mo-fo',
  'moky',
  'molest',
  'molestation',
  'molester',
  'molestor',
  'moneyshot',
  'monkey',
  'monster',
  'moolie',
  'mooncricket',
  'moooo',
  'mormon',
  'moslem',
  'mosshead',
  'mothafuck',
  'mothafuckas',
  'mothafuckaz',
  'mothafucked',
  'mothafucker',
  'mothafuckers',
  'mothafuckin',
  'mothafucking',
  'mothafuckings',
  'mothafucks',
  'motherfuck',
  'motherfucka',
  'motherfucked',
  'motherfuckers',
  'motherfuckin',
  'motherfuckings',
  'motherfuckka',
  'motherfucks',
  'motherlovebone',
  'mtherfucker',
  'mthrfucker',
  'mthrfucking',
  'muffdive',
  'muffdiving',
  'muffindiver',
  'mufflikcer',
  'mulatto',
  'munt',
  'munter',
  'murder',
  'murderer',
  'mut',
  'mutant',
  'mutha',
  'muthafecker',
  'muthafuckaz',
  'muthafucker',
  'muthafuckker',
  'muther',
  'mutherfucker',
  'mutherfucking',
  'muthrfucking',
  'n1gga',
  'n1gger',
  'nads',
  'naked',
  'nambla',
  'napalm',
  'narcotic',
  'nastybitch',
  'nastyho',
  'nastyslut',
  'nastywhore',
  'nawashi',
  'nazi',
  'nazis',
  "nazi's",
  'nazism',
  'necro',
  'negro',
  'negroes',
  'negroid',
  "negro's",
  'neonazi',
  'nerfhearder',
  'nig',
  'nigaboo',
  'niger',
  'nigerian',
  'nigerians',
  'nigg',
  'nigg3r',
  'nigg4h',
  'niggah',
  'niggaracci',
  'niggard',
  'niggarded',
  'niggarding',
  'niggardliness',
  "niggardliness's",
  'niggardly',
  'niggards',
  "niggard's",
  'niggas',
  'niggaz',
  'niggerhead',
  'niggerhole',
  "nigger's",
  'niggle',
  'niggled',
  'nigglings',
  'niggor',
  'niggur',
  'nignog',
  'nig-nog',
  'nigr',
  'nigra',
  'nigre',
  'nimphomania',
  'nimrod',
  'ninny',
  'ninnyhammer',
  'nipple',
  'nipplering',
  'nipples',
  'nittit',
  'nlgger',
  'nlggor',
  'nobhead',
  'nobjocky',
  'nobjokey',
  'nofuckingway',
  'nonce',
  'nookey',
  'nookie',
  'nooky',
  'noonan',
  'nooner',
  'norks',
  'nude',
  'nudger',
  'nudity',
  'nuke',
  'numbnuts',
  'nurd',
  'nutfucker',
  'nymph',
  'nympho',
  'nymphomania',
  'octopussy',
  'oger',
  'omorashi',
  'ontherag',
  'opiate',
  'opium',
  'orally',
  'orang-uthan',
  'orga',
  'orgasim',
  'orgasims',
  'orgasm',
  'orgasmic',
  'orgasms',
  'orgies',
  'orgy',
  'osama',
  'ovary',
  'ovum',
  'ovums',
  'p.u.s.s.y.',
  'p0rn',
  'paedophile',
  'paki',
  'palesimian',
  'pansies',
  'pansy',
  'panti',
  'pantie',
  'panties',
  'panty',
  'pastie',
  'pavian',
  'payo',
  'pcp',
  'pearlnecklace',
  'peckerwood',
  'pedo',
  'pedobear',
  'pedophile',
  'pedophilia',
  'pedophiliac',
  'pee',
  'peehole',
  'peepee',
  'pee-pee',
  'peepshow',
  'peepshpw',
  'pegging',
  'pendy',
  'peni5',
  'penial',
  'penile',
  'penisbanger',
  'penises',
  'penthouse',
  'perv',
  'perversion',
  'pervert',
  'peyote',
  'phalli',
  'phallic',
  'phonesex',
  'phuck',
  'phuk',
  'phuked',
  'phuking',
  'phukked',
  'phukking',
  'phuks',
  'phungky',
  'phuq',
  'pi55',
  'picaninny',
  'piccaninny',
  'pickaninny',
  'pig',
  'pigfucker',
  'piggy-wiggy',
  'piker',
  'pikey',
  'piky',
  'pillowbiter',
  'pimp',
  'pimped',
  'pimper',
  'pimpis',
  'pimpjuic',
  'pimpjuice',
  'pimpsimp',
  'pindick',
  'pinko',
  'pirate',
  'pisser',
  'pissers',
  'pisses',
  'pisshead',
  'pissin',
  'pissing',
  'pissoff',
  'piss-off',
  'pisspig',
  'pistol',
  'pixie',
  'pixy',
  'playboy',
  'playgirl',
  'pocha',
  'pocho',
  'pocketpool',
  'pohm',
  'polack',
  'polecat',
  'pollock',
  'pom',
  'pommie',
  'pommy',
  'ponyplay',
  'poo',
  'poof',
  'poofter',
  'poon',
  'poopchute',
  'pooper',
  'pooperscooper',
  'pooping',
  'poopuncher',
  'poo-pusher',
  'poorwhitetrash',
  'popimp',
  'porn',
  'pornflick',
  'pornking',
  'porno',
  'pornofreak',
  'pornography',
  'pornos',
  'pornprincess',
  'potty',
  'pric',
  'prickhead',
  'pricks',
  'prickteaser',
  'prig',
  'primetime',
  'prolet',
  'pron',
  'prostitute',
  'protestant',
  'psycho',
  'pthc',
  'pu55i',
  'pu55y',
  'pubes',
  'pubic',
  'pubiclice',
  'pubis',
  'pudboy',
  'pudd',
  'puddboy',
  'puke',
  'punani',
  'punany',
  'punkass',
  'punky',
  'punta',
  'puntang',
  'purinapricness',
  'puss',
  'pusse',
  'pussi',
  'pussie',
  'pussycat',
  'pussyeater',
  'pussyfucker',
  'pussylicker',
  'pussylips',
  'pussylover',
  'pussypounder',
  'pussys',
  'pust',
  'pusy',
  'puto',
  'quashie',
  'queaf',
  'queaf',
  'queer',
  'queero',
  'queers',
  'querulant',
  'quicky',
  'quim',
  'ra8s',
  'rabbi',
  'racial',
  'radicals',
  'raghead',
  'randy',
  'rape',
  'raped',
  'raper',
  'rapey',
  'raping',
  'rapist',
  'rat-fink',
  'raunch',
  'rearend',
  'rearentry',
  'rectal',
  'rectum',
  'rectus',
  'redlight',
  'redneck',
  'reestie',
  'reetard',
  'rentafuck',
  'rere',
  'retard',
  'retarded',
  'ribbed',
  'riff-raff',
  'rimjaw',
  'rimming',
  'ripper',
  'ritard',
  'roach',
  'robber',
  'roboter',
  'root',
  'roundeye',
  'rowdy',
  'rowl',
  'rtard',
  'r-tard',
  'rufian',
  'rump',
  'rumprammer',
  'russki',
  'russkie',
  's&m',
  's.h.i.t.',
  's.o.b.',
  's_h_i_t',
  's0b',
  'sadis',
  'sadism',
  'sadist',
  'sadom',
  'sambo',
  'samckdaddy',
  'sandbar',
  'sandler',
  'sandm',
  'sanger',
  'santorum',
  'saprophyt',
  'satan',
  'scag',
  'scallywag',
  'scantily',
  'scarab',
  'scat',
  'schfincter',
  'schizo',
  'scissoring',
  'screwyou',
  'scroat',
  'scrog',
  'scrot',
  'scrud',
  'scum',
  'seamen',
  'seks',
  'semen',
  'seppo',
  'sexed',
  'sexfarm',
  'sexhound',
  'sexhouse',
  'sexing',
  'sexkitten',
  'sexo',
  'sexpot',
  'sexslave',
  'sextogo',
  'sextoy',
  'sextoys',
  'sexually',
  'sexwhore',
  'sexy',
  'sexymoma',
  'sexy-slim',
  'sh!+',
  'sh!t',
  's-h-1-t',
  'shag',
  'shagger',
  'shaggin',
  'shagging',
  'shamedame',
  'shark',
  'shat',
  'shav',
  'shawtypimp',
  'sheeney',
  'shemale',
  'shhit',
  'shi+',
  'shibari',
  'shinola',
  's-h-i-t',
  'shitblimp',
  'shitcan',
  'shitcanned',
  'shite',
  'shiteater',
  'shited',
  'shitey',
  'shitfit',
  'shitforbrains',
  'shitfuck',
  'shitfucker',
  'shitfull',
  'shithapens',
  'shithappens',
  'shitheads',
  'shiting',
  'shitings',
  'shitlist',
  'shitola',
  'shitoutofluck',
  'shits',
  'shitt',
  'shitted',
  'shitters',
  'shittier',
  'shittings',
  'shiz',
  'shortfuck',
  'shota',
  'showtime',
  'shrimping',
  'simulant',
  'sissy',
  'sixsixsix',
  'sixtynine',
  'sixtyniner',
  'skag',
  'skank',
  'skankbitch',
  'skankfuck',
  'skankwhore',
  'skanky',
  'skankybitch',
  'skankywhore',
  'skeet',
  'skinflute',
  'skum',
  'skumbag',
  'skunk',
  'slag',
  'slager',
  'slant',
  'slanteye',
  'slapper',
  'slav',
  'slavedriver',
  'sleaze',
  'sleazy',
  'sleeze',
  'sleezebag',
  'sleezeball',
  'slideitin',
  'slime',
  'slimeball',
  'slimebucket',
  'slimer',
  'slope',
  'slopehead',
  'slopey',
  'slopy',
  'slutdumper',
  'slutkiss',
  'sluts',
  'slutt',
  'slutting',
  'slutty',
  'slutwear',
  'slutwhore',
  'smackthemonkey',
  'smartass',
  'smartasses',
  'smut',
  'smutty',
  'snatchpatch',
  'snigger',
  'sniggered',
  'sniggers',
  "snigger's",
  'sniper',
  'snob',
  'snot',
  'snowback',
  'snowballing',
  'snownigger',
  's-o-b',
  'sodom',
  'sodomise',
  'sodomite',
  'sodomize',
  'sodomy',
  'sonofabitch',
  'son-of-a-bitch',
  'sonofbitch',
  'sooty',
  'souse',
  'soused',
  'spac',
  'spade',
  'spaghettibender',
  'spaghettinigger',
  'spank',
  'spankthemonkey',
  'sperm',
  'spermacide',
  'spermbag',
  'spermhearder',
  'spermherder',
  'spick',
  'spig',
  'spigotty',
  'spik',
  'spiks',
  'spitter',
  'splittail',
  'spooge',
  'spreadeagle',
  'spunky',
  'squaw',
  'steamy',
  'stfu',
  'stiffy',
  'stinker',
  'stoned',
  'strapon',
  'strappado',
  'stripclub',
  'stripper',
  'stunk',
  'stupidfuck',
  'stupidfucker',
  'suckass',
  'suckdick',
  'sucker',
  'suckme',
  'suckmyass',
  'suckmydick',
  'suckmytit',
  'suckoff',
  'sumofabiatch',
  'swallower',
  'swalow',
  'swastika',
  'sweetness',
  'swindler',
  'swine',
  'swinger',
  'syphilis',
  't1t',
  't1tt1e5',
  't1tties',
  'taig',
  'tampon',
  'tang',
  'tantra',
  'tarbaby',
  'tawdry',
  'teabagging',
  'teets',
  'teez',
  'teletubby',
  'terd',
  'terrorist',
  'testee',
  'testes',
  'testical',
  'testicles',
  'testis',
  'thicklips',
  'thief',
  'thirdeye',
  'thirdleg',
  'threesome',
  'threeway',
  'throating',
  'timbernigger',
  'tinkle',
  'titbitnipply',
  'titfucker',
  'titfuckin',
  'titi',
  'tities',
  'titjob',
  'titlicker',
  'titlover',
  'titt',
  'tittie',
  'tittie5',
  'tittiefucker',
  'titties',
  'titty',
  'tittyfucker',
  'tittywank',
  'titwank',
  'tongethruster',
  'tonguethrust',
  'tonguetramp',
  'toots',
  'topless',
  'tortur',
  'torture',
  'towelhead',
  'trailertrash',
  'tramp',
  'trannie',
  'tranny',
  'transexual',
  'transsexual',
  'transvestite',
  'tribadism',
  'triplex',
  'trisexual',
  'trots',
  'tubgirl',
  'tuckahoe',
  'tunneloflove',
  'turnon',
  'tush',
  'tushy',
  'tussi',
  'tw4t',
  'twathead',
  'twatty',
  'twink',
  'twinkie',
  'twobitwhore',
  'twunt',
  'twunter',
  'typ',
  'uck',
  'undressing',
  'unfuckable',
  'unwed',
  'upskirt',
  'uptheass',
  'upthebutt',
  'urinal',
  'urinary',
  'urinate',
  'urine',
  'urophilia',
  'usama',
  'uterus',
  'uzi',
  'v14gra',
  'v1gra',
  'vag',
  'vaginal',
  'vajayjay',
  'vampir',
  'vandale',
  'varmit',
  'vatican',
  'veqtable',
  'viagra',
  'vibrater',
  'vibrator',
  'vietcong',
  'violence',
  'virginbreaker',
  'vixen',
  'vorarephilia',
  'voyeur',
  'vulva',
  'w00se',
  'wab',
  'wang',
  'wanker',
  'wanking',
  'wankjob',
  'wanky',
  'waysted',
  'wazoo',
  'wedgie',
  'weed',
  'weenie',
  'weewee',
  'weiner',
  'welcher',
  'wench',
  'wetb',
  'wetspot',
  'wh0re',
  'wh0reface',
  'whacker',
  'whash',
  'whigger',
  'whiskeydick',
  'whiskydick',
  'whitenigger',
  'whites',
  'whitetrash',
  'whitey',
  'whiz',
  'whoar',
  'whop',
  'whoralicious',
  'whorealicious',
  'whored',
  'whorefucker',
  'whorehopper',
  'whorehouse',
  'whores',
  'whoring',
  'wierdo',
  'wigger',
  'willie',
  'willies',
  'williewanker',
  'wino',
  'wiseass',
  'wiseasses',
  'witch',
  'wog',
  'womanizer',
  'womb',
  'woody',
  'worm',
  'wuss',
  'wuzzie',
  'xena',
  'xenophebe',
  'xenophobe',
  'xrated',
  'x-rated',
  'xtc',
  'yak',
  'yankee',
  'yaoi',
  'yeasty',
  'yellowman',
  'yeti',
  'yid',
  'yiffy',
  'yobbo',
  'zibbi',
  'zigabo',
  'zipperhead',
  'zoophile',
  'zoophilia',
  'zubb',
  '@$$hole',
  '455',
  '4555h0l3',
  '455c0ck',
  '455cl0wn',
  '455cr4ck3r',
  '455fuck',
  '455fuck3r',
  '455-fuck3r',
  '455fukk4',
  '455h0l3',
  '455k155',
  '455k1553r',
  '455-k1553r',
  '455munch',
  '455munch3r',
  '5amckd4ddy',
  '5h17',
  '5h47',
  '5h4v',
  '5k4nkfuck',
  '5kullfuck',
  '5kumb4g',
  '5kunk5l4g',
  '5l4v',
  '5lu7',
  '5lu75',
  '5lu771n6',
  '5lu77y',
  '5m36',
  '5m36m4',
  '5m4r745s',
  '5p1c',
  '5p4nk',
  '7175',
  '7177yfuck',
  '717fuck',
  '717fuck3r',
  '7337s',
  '733z',
  '73rd',
  '7urd',
  '8008y',
  'b00b',
  'b00by',
  'b00dy',
  'b00ng',
  'b00zerb00zy',
  'b050m',
  'b0d',
  'b0ll0ck',
  'b0ll0ck5',
  'b0ll0k',
  'b0ll0x',
  'b0md',
  'b0n3d',
  'b0n3r',
  'b147ch',
  'b17ch35',
  'b17ch455',
  'b17ch7175',
  'b17chy',
  'b34n3r',
  'b4574rd',
  'b4ll54ck',
  'bcith',
  'beeh',
  'bfg',
  'bl0wj0b',
  'bobyag',
  'bolowjb',
  'br07h3rfuck3r',
  'br3457',
  'bs',
  'bu77fuck3r',
  'bu77fuck4',
  'bu77plu6',
  'bulcpirts',
  'bull5h17',
  'bumbl3fuck',
  'ccok',
  'cl17fuck',
  'clu573rfuck',
  'clun63',
  'clusterf#@k',
  'clusterf#@ked',
  'clusterf#@king',
  'cun7',
  'dcik',
  'f#@k',
  'f#@ked',
  'f#@ker',
  'f#@king',
  'fuck0ff',
  'fuck1n',
  'fuck3d',
  'fuck3r',
  'fuck455',
  'fuck4ss',
  'fuck5',
  'fuckb0y',
  'fuckb46',
  'fuckbu77',
  'fuckf4c3',
  'fuckh0l3',
  'fucknu7',
  'fucknu77',
  'fuckw17',
  'fuckw177',
  'fuckw4d',
  'fukk1n',
  'hu5ky',
  'humm3r',
  'hump1n6',
  'm07h3rfuck1n6',
  'm07h3rfuck3d',
  'm07h3rfuck3r',
  'm07h3rfuck4',
  'm07h4fuck',
  'm07h4fuck1n',
  'm07h4fuck3rs',
  'm07h4fuck4',
  'm0ck13',
  'm0r0n',
  'm0rm0n',
  'maddog',
  'mad-dog',
  'p00p',
  'p155',
  'p1550ff',
  'pu551',
  'pu55135',
  'pu551e',
  'pub3',
  'pub35',
  'pudb0y',
  'puddb0y',
  'puk3',
  'tunc',
  'uncl3fuck3r',
  'wh0p',
  'wh0r3',
  'wh0r35',
  'wh0red',
  'wh0refucker',
  'fuckwitts',
  'fuckwits',
  'fuckwit',
  'fuckwitt',
  'aholes',
  'ahole',
  'fkn',
  'fuckkkkkkkk',
  'shitful',
  'bitchness',
  'shitx',
  'wankers',
  'arseholes',
  'bullshited',
  'milfs',
  'shitpac',
  'asshole',
  'bulshit',
  'fuckall',
  'scumbags',
  'fuckprimo',
  'bitchey',
  'fuckups',
  'fuckup',
  'rednecks',
  'redneck',
  'shit!!!',
  'shit!',
  'bu!sh!t',
  'cr@p',
  'crappy',
  'crappiest',
  'sh*t',
  'shit!',
  'fuckwhites',
  'shitkicker',
  'arsed',
  'pissup',
  'tight-arses',
  'tight-arse',
  'tightarses',
  'tightarse',
  'scumsucker',
  'vaginas',
  'wtf!!',
  'wtf!',
  'nonefucked',
  'absofuckinglutely',
  'shite',
  'f***ing',
  'f***',
  'f**k',
  'effing',
  'fucken',
  'c*nt',
  'arsekisser',
  'asskisser',
  'sht',
  'sh&t',
];
