
      import { createLanguage, createTranslationFile } from '@vocab/webpack/web';
      
    const translations = createTranslationFile({
      "en": createLanguage(
        require.resolveWeak("./translations.json-en-virtual.json!=!../../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJXZSBkb24ndCBoYXZlIGVub3VnaCByZXZpZXdzIGZvciB7Y29tcGFueU5hbWV9IHlldCB0byBzaG93IGFuIGF2ZXJhZ2UgcmF0aW5nLiI6IldlIGRvbid0IGhhdmUgZW5vdWdoIHJldmlld3MgZm9yIHtjb21wYW55TmFtZX0geWV0IHRvIHNob3cgYW4gYXZlcmFnZSByYXRpbmcuIiwiVGhlcmUgYXJlbid0IGFueSBtYXRjaGluZyByZXZpZXdzLiBXb3VsZCB5b3UgbGlrZSB0byB3cml0ZSBvbmU%2FIjoiVGhlcmUgYXJlbid0IGFueSBtYXRjaGluZyByZXZpZXdzLiBXb3VsZCB5b3UgbGlrZSB0byB3cml0ZSBvbmU%2FIiwiTGV0IG90aGVycyBrbm93IHdoYXQgaXQncyBsaWtlIHRvIHdvcmsgYXQge2NvbXBhbnlOYW1lfS4iOiJMZXQgb3RoZXJzIGtub3cgd2hhdCBpdCdzIGxpa2UgdG8gd29yayBhdCB7Y29tcGFueU5hbWV9LiIsIldyaXRlIGEgcmV2aWV3IjoiV3JpdGUgYSByZXZpZXciLCJIYXZlIHlvdXIgc2F5IjoiSGF2ZSB5b3VyIHNheSJ9!"),
        () => import(
          /* webpackChunkName: "en-translations" */
          "./translations.json-en-virtual.json!=!../../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJXZSBkb24ndCBoYXZlIGVub3VnaCByZXZpZXdzIGZvciB7Y29tcGFueU5hbWV9IHlldCB0byBzaG93IGFuIGF2ZXJhZ2UgcmF0aW5nLiI6IldlIGRvbid0IGhhdmUgZW5vdWdoIHJldmlld3MgZm9yIHtjb21wYW55TmFtZX0geWV0IHRvIHNob3cgYW4gYXZlcmFnZSByYXRpbmcuIiwiVGhlcmUgYXJlbid0IGFueSBtYXRjaGluZyByZXZpZXdzLiBXb3VsZCB5b3UgbGlrZSB0byB3cml0ZSBvbmU%2FIjoiVGhlcmUgYXJlbid0IGFueSBtYXRjaGluZyByZXZpZXdzLiBXb3VsZCB5b3UgbGlrZSB0byB3cml0ZSBvbmU%2FIiwiTGV0IG90aGVycyBrbm93IHdoYXQgaXQncyBsaWtlIHRvIHdvcmsgYXQge2NvbXBhbnlOYW1lfS4iOiJMZXQgb3RoZXJzIGtub3cgd2hhdCBpdCdzIGxpa2UgdG8gd29yayBhdCB7Y29tcGFueU5hbWV9LiIsIldyaXRlIGEgcmV2aWV3IjoiV3JpdGUgYSByZXZpZXciLCJIYXZlIHlvdXIgc2F5IjoiSGF2ZSB5b3VyIHNheSJ9!"
        )
      ),
"th": createLanguage(
        require.resolveWeak("./translations.json-th-virtual.json!=!../../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJXZSBkb24ndCBoYXZlIGVub3VnaCByZXZpZXdzIGZvciB7Y29tcGFueU5hbWV9IHlldCB0byBzaG93IGFuIGF2ZXJhZ2UgcmF0aW5nLiI6IuC5gOC4o%2BC4suC4ouC4seC4h%2BC4oeC4teC4o%2BC4teC4p%2BC4tOC4p%2BC4quC4s%2BC4q%2BC4o%2BC4seC4miB7Y29tcGFueU5hbWV9IOC5hOC4oeC5iOC5gOC4nuC4teC4ouC4h%2BC4nuC4reC4l%2BC4teC5iOC4iOC4sOC5geC4quC4lOC4h%2BC4hOC4sOC5geC4meC4meC5gOC4ieC4peC4teC5iOC4oiIsIlRoZXJlIGFyZW4ndCBhbnkgbWF0Y2hpbmcgcmV2aWV3cy4gV291bGQgeW91IGxpa2UgdG8gd3JpdGUgb25lPyI6IuC5hOC4oeC5iOC4nuC4muC4o%2BC4teC4p%2BC4tOC4p%2BC4l%2BC4teC5iOC4leC4o%2BC4h%2BC4geC4seC4muC4geC4suC4o%2BC4hOC5ieC4meC4q%2BC4siDguITguLjguJPguJXguYnguK3guIfguIHguLLguKPguYDguILguLXguKLguJnguKPguLXguKfguLTguKfguKvguKPguLfguK3guYTguKHguYgiLCJMZXQgb3RoZXJzIGtub3cgd2hhdCBpdCdzIGxpa2UgdG8gd29yayBhdCB7Y29tcGFueU5hbWV9LiI6IuC5gOC4peC5iOC4suC5g%2BC4q%2BC5ieC4n%2BC4seC4h%2BC4p%2BC5iOC4suC4geC4suC4o%2BC4l%2BC4s%2BC4h%2BC4suC4meC4l%2BC4teC5iCB7Y29tcGFueU5hbWV9IOC5gOC4m%2BC5h%2BC4meC4reC4ouC5iOC4suC4h%2BC5hOC4oyIsIldyaXRlIGEgcmV2aWV3Ijoi4LmA4LiC4Li14Lii4LiZ4Lij4Li14Lin4Li04LinIiwiSGF2ZSB5b3VyIHNheSI6IuC5geC4quC4lOC4h%2BC4hOC4p%2BC4suC4oeC4hOC4tOC4lOC5gOC4q%2BC5h%2BC4mSJ9!"),
        () => import(
          /* webpackChunkName: "th-translations" */
          "./translations.json-th-virtual.json!=!../../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJXZSBkb24ndCBoYXZlIGVub3VnaCByZXZpZXdzIGZvciB7Y29tcGFueU5hbWV9IHlldCB0byBzaG93IGFuIGF2ZXJhZ2UgcmF0aW5nLiI6IuC5gOC4o%2BC4suC4ouC4seC4h%2BC4oeC4teC4o%2BC4teC4p%2BC4tOC4p%2BC4quC4s%2BC4q%2BC4o%2BC4seC4miB7Y29tcGFueU5hbWV9IOC5hOC4oeC5iOC5gOC4nuC4teC4ouC4h%2BC4nuC4reC4l%2BC4teC5iOC4iOC4sOC5geC4quC4lOC4h%2BC4hOC4sOC5geC4meC4meC5gOC4ieC4peC4teC5iOC4oiIsIlRoZXJlIGFyZW4ndCBhbnkgbWF0Y2hpbmcgcmV2aWV3cy4gV291bGQgeW91IGxpa2UgdG8gd3JpdGUgb25lPyI6IuC5hOC4oeC5iOC4nuC4muC4o%2BC4teC4p%2BC4tOC4p%2BC4l%2BC4teC5iOC4leC4o%2BC4h%2BC4geC4seC4muC4geC4suC4o%2BC4hOC5ieC4meC4q%2BC4siDguITguLjguJPguJXguYnguK3guIfguIHguLLguKPguYDguILguLXguKLguJnguKPguLXguKfguLTguKfguKvguKPguLfguK3guYTguKHguYgiLCJMZXQgb3RoZXJzIGtub3cgd2hhdCBpdCdzIGxpa2UgdG8gd29yayBhdCB7Y29tcGFueU5hbWV9LiI6IuC5gOC4peC5iOC4suC5g%2BC4q%2BC5ieC4n%2BC4seC4h%2BC4p%2BC5iOC4suC4geC4suC4o%2BC4l%2BC4s%2BC4h%2BC4suC4meC4l%2BC4teC5iCB7Y29tcGFueU5hbWV9IOC5gOC4m%2BC5h%2BC4meC4reC4ouC5iOC4suC4h%2BC5hOC4oyIsIldyaXRlIGEgcmV2aWV3Ijoi4LmA4LiC4Li14Lii4LiZ4Lij4Li14Lin4Li04LinIiwiSGF2ZSB5b3VyIHNheSI6IuC5geC4quC4lOC4h%2BC4hOC4p%2BC4suC4oeC4hOC4tOC4lOC5gOC4q%2BC5h%2BC4mSJ9!"
        )
      ),
"id": createLanguage(
        require.resolveWeak("./translations.json-id-virtual.json!=!../../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJXZSBkb24ndCBoYXZlIGVub3VnaCByZXZpZXdzIGZvciB7Y29tcGFueU5hbWV9IHlldCB0byBzaG93IGFuIGF2ZXJhZ2UgcmF0aW5nLiI6IlBlbmlsYWlhbiB1bnR1ayB7Y29tcGFueU5hbWV9IGFrYW4gdGVyc2VkaWEgc2V0ZWxhaCBhZGEgbGViaWggYmFueWFrIHVsYXNhbiIsIlRoZXJlIGFyZW4ndCBhbnkgbWF0Y2hpbmcgcmV2aWV3cy4gV291bGQgeW91IGxpa2UgdG8gd3JpdGUgb25lPyI6IkJlbHVtIGFkYSB1bGFzYW4gdW50dWsgcGVydXNhaGFhbiBpbmkuIEluZ2luIG1lbmFtYmFoa2FuPyIsIkxldCBvdGhlcnMga25vdyB3aGF0IGl0J3MgbGlrZSB0byB3b3JrIGF0IHtjb21wYW55TmFtZX0uIjoiQ2VyaXRha2FuIHBlbmdhbGFtYW4gc2FhdCBiZWtlcmphIGRpIHtjb21wYW55TmFtZX0uIiwiV3JpdGUgYSByZXZpZXciOiJUdWxpcyB1bGFzYW4iLCJIYXZlIHlvdXIgc2F5IjoiQmFnaWthbiBwZW5kYXBhdCBBbmRhIn0%3D!"),
        () => import(
          /* webpackChunkName: "id-translations" */
          "./translations.json-id-virtual.json!=!../../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJXZSBkb24ndCBoYXZlIGVub3VnaCByZXZpZXdzIGZvciB7Y29tcGFueU5hbWV9IHlldCB0byBzaG93IGFuIGF2ZXJhZ2UgcmF0aW5nLiI6IlBlbmlsYWlhbiB1bnR1ayB7Y29tcGFueU5hbWV9IGFrYW4gdGVyc2VkaWEgc2V0ZWxhaCBhZGEgbGViaWggYmFueWFrIHVsYXNhbiIsIlRoZXJlIGFyZW4ndCBhbnkgbWF0Y2hpbmcgcmV2aWV3cy4gV291bGQgeW91IGxpa2UgdG8gd3JpdGUgb25lPyI6IkJlbHVtIGFkYSB1bGFzYW4gdW50dWsgcGVydXNhaGFhbiBpbmkuIEluZ2luIG1lbmFtYmFoa2FuPyIsIkxldCBvdGhlcnMga25vdyB3aGF0IGl0J3MgbGlrZSB0byB3b3JrIGF0IHtjb21wYW55TmFtZX0uIjoiQ2VyaXRha2FuIHBlbmdhbGFtYW4gc2FhdCBiZWtlcmphIGRpIHtjb21wYW55TmFtZX0uIiwiV3JpdGUgYSByZXZpZXciOiJUdWxpcyB1bGFzYW4iLCJIYXZlIHlvdXIgc2F5IjoiQmFnaWthbiBwZW5kYXBhdCBBbmRhIn0%3D!"
        )
      ),
"zh": createLanguage(
        require.resolveWeak("./translations.json-zh-virtual.json!=!../../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJXZSBkb24ndCBoYXZlIGVub3VnaCByZXZpZXdzIGZvciB7Y29tcGFueU5hbWV9IHlldCB0byBzaG93IGFuIGF2ZXJhZ2UgcmF0aW5nLiI6IldlIGRvbid0IGhhdmUgZW5vdWdoIHJldmlld3MgZm9yIHtjb21wYW55TmFtZX0geWV0IHRvIHNob3cgYW4gYXZlcmFnZSByYXRpbmcuIiwiVGhlcmUgYXJlbid0IGFueSBtYXRjaGluZyByZXZpZXdzLiBXb3VsZCB5b3UgbGlrZSB0byB3cml0ZSBvbmU%2FIjoiVGhlcmUgYXJlbid0IGFueSBtYXRjaGluZyByZXZpZXdzLiBXb3VsZCB5b3UgbGlrZSB0byB3cml0ZSBvbmU%2FIiwiTGV0IG90aGVycyBrbm93IHdoYXQgaXQncyBsaWtlIHRvIHdvcmsgYXQge2NvbXBhbnlOYW1lfS4iOiJMZXQgb3RoZXJzIGtub3cgd2hhdCBpdCdzIGxpa2UgdG8gd29yayBhdCB7Y29tcGFueU5hbWV9LiIsIldyaXRlIGEgcmV2aWV3IjoiV3JpdGUgYSByZXZpZXciLCJIYXZlIHlvdXIgc2F5IjoiSGF2ZSB5b3VyIHNheSJ9!"),
        () => import(
          /* webpackChunkName: "zh-translations" */
          "./translations.json-zh-virtual.json!=!../../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJXZSBkb24ndCBoYXZlIGVub3VnaCByZXZpZXdzIGZvciB7Y29tcGFueU5hbWV9IHlldCB0byBzaG93IGFuIGF2ZXJhZ2UgcmF0aW5nLiI6IldlIGRvbid0IGhhdmUgZW5vdWdoIHJldmlld3MgZm9yIHtjb21wYW55TmFtZX0geWV0IHRvIHNob3cgYW4gYXZlcmFnZSByYXRpbmcuIiwiVGhlcmUgYXJlbid0IGFueSBtYXRjaGluZyByZXZpZXdzLiBXb3VsZCB5b3UgbGlrZSB0byB3cml0ZSBvbmU%2FIjoiVGhlcmUgYXJlbid0IGFueSBtYXRjaGluZyByZXZpZXdzLiBXb3VsZCB5b3UgbGlrZSB0byB3cml0ZSBvbmU%2FIiwiTGV0IG90aGVycyBrbm93IHdoYXQgaXQncyBsaWtlIHRvIHdvcmsgYXQge2NvbXBhbnlOYW1lfS4iOiJMZXQgb3RoZXJzIGtub3cgd2hhdCBpdCdzIGxpa2UgdG8gd29yayBhdCB7Y29tcGFueU5hbWV9LiIsIldyaXRlIGEgcmV2aWV3IjoiV3JpdGUgYSByZXZpZXciLCJIYXZlIHlvdXIgc2F5IjoiSGF2ZSB5b3VyIHNheSJ9!"
        )
      ),
"en-AU": createLanguage(
        require.resolveWeak("./translations.json-en-AU-virtual.json!=!../../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJXZSBkb24ndCBoYXZlIGVub3VnaCByZXZpZXdzIGZvciB7Y29tcGFueU5hbWV9IHlldCB0byBzaG93IGFuIGF2ZXJhZ2UgcmF0aW5nLiI6IldlIGRvbid0IGhhdmUgZW5vdWdoIHJldmlld3MgZm9yIHtjb21wYW55TmFtZX0geWV0IHRvIHNob3cgYW4gYXZlcmFnZSByYXRpbmcuIiwiVGhlcmUgYXJlbid0IGFueSBtYXRjaGluZyByZXZpZXdzLiBXb3VsZCB5b3UgbGlrZSB0byB3cml0ZSBvbmU%2FIjoiVGhlcmUgYXJlbid0IGFueSBtYXRjaGluZyByZXZpZXdzLiBXb3VsZCB5b3UgbGlrZSB0byB3cml0ZSBvbmU%2FIiwiTGV0IG90aGVycyBrbm93IHdoYXQgaXQncyBsaWtlIHRvIHdvcmsgYXQge2NvbXBhbnlOYW1lfS4iOiJMZXQgb3RoZXJzIGtub3cgd2hhdCBpdCdzIGxpa2UgdG8gd29yayBhdCB7Y29tcGFueU5hbWV9LiIsIldyaXRlIGEgcmV2aWV3IjoiV3JpdGUgYSByZXZpZXciLCJIYXZlIHlvdXIgc2F5IjoiSGF2ZSB5b3VyIHNheSJ9!"),
        () => import(
          /* webpackChunkName: "en-AU-translations" */
          "./translations.json-en-AU-virtual.json!=!../../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJXZSBkb24ndCBoYXZlIGVub3VnaCByZXZpZXdzIGZvciB7Y29tcGFueU5hbWV9IHlldCB0byBzaG93IGFuIGF2ZXJhZ2UgcmF0aW5nLiI6IldlIGRvbid0IGhhdmUgZW5vdWdoIHJldmlld3MgZm9yIHtjb21wYW55TmFtZX0geWV0IHRvIHNob3cgYW4gYXZlcmFnZSByYXRpbmcuIiwiVGhlcmUgYXJlbid0IGFueSBtYXRjaGluZyByZXZpZXdzLiBXb3VsZCB5b3UgbGlrZSB0byB3cml0ZSBvbmU%2FIjoiVGhlcmUgYXJlbid0IGFueSBtYXRjaGluZyByZXZpZXdzLiBXb3VsZCB5b3UgbGlrZSB0byB3cml0ZSBvbmU%2FIiwiTGV0IG90aGVycyBrbm93IHdoYXQgaXQncyBsaWtlIHRvIHdvcmsgYXQge2NvbXBhbnlOYW1lfS4iOiJMZXQgb3RoZXJzIGtub3cgd2hhdCBpdCdzIGxpa2UgdG8gd29yayBhdCB7Y29tcGFueU5hbWV9LiIsIldyaXRlIGEgcmV2aWV3IjoiV3JpdGUgYSByZXZpZXciLCJIYXZlIHlvdXIgc2F5IjoiSGF2ZSB5b3VyIHNheSJ9!"
        )
      ),
"en-HK": createLanguage(
        require.resolveWeak("./translations.json-en-HK-virtual.json!=!../../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJXZSBkb24ndCBoYXZlIGVub3VnaCByZXZpZXdzIGZvciB7Y29tcGFueU5hbWV9IHlldCB0byBzaG93IGFuIGF2ZXJhZ2UgcmF0aW5nLiI6IldlIGRvbid0IGhhdmUgZW5vdWdoIHJldmlld3MgZm9yIHtjb21wYW55TmFtZX0geWV0IHRvIHNob3cgYW4gYXZlcmFnZSByYXRpbmcuIiwiVGhlcmUgYXJlbid0IGFueSBtYXRjaGluZyByZXZpZXdzLiBXb3VsZCB5b3UgbGlrZSB0byB3cml0ZSBvbmU%2FIjoiVGhlcmUgYXJlbid0IGFueSBtYXRjaGluZyByZXZpZXdzLiBXb3VsZCB5b3UgbGlrZSB0byB3cml0ZSBvbmU%2FIiwiTGV0IG90aGVycyBrbm93IHdoYXQgaXQncyBsaWtlIHRvIHdvcmsgYXQge2NvbXBhbnlOYW1lfS4iOiJMZXQgb3RoZXJzIGtub3cgd2hhdCBpdCdzIGxpa2UgdG8gd29yayBhdCB7Y29tcGFueU5hbWV9LiIsIldyaXRlIGEgcmV2aWV3IjoiV3JpdGUgYSByZXZpZXciLCJIYXZlIHlvdXIgc2F5IjoiSGF2ZSB5b3VyIHNheSJ9!"),
        () => import(
          /* webpackChunkName: "en-HK-translations" */
          "./translations.json-en-HK-virtual.json!=!../../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJXZSBkb24ndCBoYXZlIGVub3VnaCByZXZpZXdzIGZvciB7Y29tcGFueU5hbWV9IHlldCB0byBzaG93IGFuIGF2ZXJhZ2UgcmF0aW5nLiI6IldlIGRvbid0IGhhdmUgZW5vdWdoIHJldmlld3MgZm9yIHtjb21wYW55TmFtZX0geWV0IHRvIHNob3cgYW4gYXZlcmFnZSByYXRpbmcuIiwiVGhlcmUgYXJlbid0IGFueSBtYXRjaGluZyByZXZpZXdzLiBXb3VsZCB5b3UgbGlrZSB0byB3cml0ZSBvbmU%2FIjoiVGhlcmUgYXJlbid0IGFueSBtYXRjaGluZyByZXZpZXdzLiBXb3VsZCB5b3UgbGlrZSB0byB3cml0ZSBvbmU%2FIiwiTGV0IG90aGVycyBrbm93IHdoYXQgaXQncyBsaWtlIHRvIHdvcmsgYXQge2NvbXBhbnlOYW1lfS4iOiJMZXQgb3RoZXJzIGtub3cgd2hhdCBpdCdzIGxpa2UgdG8gd29yayBhdCB7Y29tcGFueU5hbWV9LiIsIldyaXRlIGEgcmV2aWV3IjoiV3JpdGUgYSByZXZpZXciLCJIYXZlIHlvdXIgc2F5IjoiSGF2ZSB5b3VyIHNheSJ9!"
        )
      ),
"en-ID": createLanguage(
        require.resolveWeak("./translations.json-en-ID-virtual.json!=!../../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJXZSBkb24ndCBoYXZlIGVub3VnaCByZXZpZXdzIGZvciB7Y29tcGFueU5hbWV9IHlldCB0byBzaG93IGFuIGF2ZXJhZ2UgcmF0aW5nLiI6IldlIGRvbid0IGhhdmUgZW5vdWdoIHJldmlld3MgZm9yIHtjb21wYW55TmFtZX0geWV0IHRvIHNob3cgYW4gYXZlcmFnZSByYXRpbmcuIiwiVGhlcmUgYXJlbid0IGFueSBtYXRjaGluZyByZXZpZXdzLiBXb3VsZCB5b3UgbGlrZSB0byB3cml0ZSBvbmU%2FIjoiVGhlcmUgYXJlbid0IGFueSBtYXRjaGluZyByZXZpZXdzLiBXb3VsZCB5b3UgbGlrZSB0byB3cml0ZSBvbmU%2FIiwiTGV0IG90aGVycyBrbm93IHdoYXQgaXQncyBsaWtlIHRvIHdvcmsgYXQge2NvbXBhbnlOYW1lfS4iOiJMZXQgb3RoZXJzIGtub3cgd2hhdCBpdCdzIGxpa2UgdG8gd29yayBhdCB7Y29tcGFueU5hbWV9LiIsIldyaXRlIGEgcmV2aWV3IjoiV3JpdGUgYSByZXZpZXciLCJIYXZlIHlvdXIgc2F5IjoiSGF2ZSB5b3VyIHNheSJ9!"),
        () => import(
          /* webpackChunkName: "en-ID-translations" */
          "./translations.json-en-ID-virtual.json!=!../../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJXZSBkb24ndCBoYXZlIGVub3VnaCByZXZpZXdzIGZvciB7Y29tcGFueU5hbWV9IHlldCB0byBzaG93IGFuIGF2ZXJhZ2UgcmF0aW5nLiI6IldlIGRvbid0IGhhdmUgZW5vdWdoIHJldmlld3MgZm9yIHtjb21wYW55TmFtZX0geWV0IHRvIHNob3cgYW4gYXZlcmFnZSByYXRpbmcuIiwiVGhlcmUgYXJlbid0IGFueSBtYXRjaGluZyByZXZpZXdzLiBXb3VsZCB5b3UgbGlrZSB0byB3cml0ZSBvbmU%2FIjoiVGhlcmUgYXJlbid0IGFueSBtYXRjaGluZyByZXZpZXdzLiBXb3VsZCB5b3UgbGlrZSB0byB3cml0ZSBvbmU%2FIiwiTGV0IG90aGVycyBrbm93IHdoYXQgaXQncyBsaWtlIHRvIHdvcmsgYXQge2NvbXBhbnlOYW1lfS4iOiJMZXQgb3RoZXJzIGtub3cgd2hhdCBpdCdzIGxpa2UgdG8gd29yayBhdCB7Y29tcGFueU5hbWV9LiIsIldyaXRlIGEgcmV2aWV3IjoiV3JpdGUgYSByZXZpZXciLCJIYXZlIHlvdXIgc2F5IjoiSGF2ZSB5b3VyIHNheSJ9!"
        )
      ),
"en-MY": createLanguage(
        require.resolveWeak("./translations.json-en-MY-virtual.json!=!../../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJXZSBkb24ndCBoYXZlIGVub3VnaCByZXZpZXdzIGZvciB7Y29tcGFueU5hbWV9IHlldCB0byBzaG93IGFuIGF2ZXJhZ2UgcmF0aW5nLiI6IldlIGRvbid0IGhhdmUgZW5vdWdoIHJldmlld3MgZm9yIHtjb21wYW55TmFtZX0geWV0IHRvIHNob3cgYW4gYXZlcmFnZSByYXRpbmcuIiwiVGhlcmUgYXJlbid0IGFueSBtYXRjaGluZyByZXZpZXdzLiBXb3VsZCB5b3UgbGlrZSB0byB3cml0ZSBvbmU%2FIjoiVGhlcmUgYXJlbid0IGFueSBtYXRjaGluZyByZXZpZXdzLiBXb3VsZCB5b3UgbGlrZSB0byB3cml0ZSBvbmU%2FIiwiTGV0IG90aGVycyBrbm93IHdoYXQgaXQncyBsaWtlIHRvIHdvcmsgYXQge2NvbXBhbnlOYW1lfS4iOiJMZXQgb3RoZXJzIGtub3cgd2hhdCBpdCdzIGxpa2UgdG8gd29yayBhdCB7Y29tcGFueU5hbWV9LiIsIldyaXRlIGEgcmV2aWV3IjoiV3JpdGUgYSByZXZpZXciLCJIYXZlIHlvdXIgc2F5IjoiSGF2ZSB5b3VyIHNheSJ9!"),
        () => import(
          /* webpackChunkName: "en-MY-translations" */
          "./translations.json-en-MY-virtual.json!=!../../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJXZSBkb24ndCBoYXZlIGVub3VnaCByZXZpZXdzIGZvciB7Y29tcGFueU5hbWV9IHlldCB0byBzaG93IGFuIGF2ZXJhZ2UgcmF0aW5nLiI6IldlIGRvbid0IGhhdmUgZW5vdWdoIHJldmlld3MgZm9yIHtjb21wYW55TmFtZX0geWV0IHRvIHNob3cgYW4gYXZlcmFnZSByYXRpbmcuIiwiVGhlcmUgYXJlbid0IGFueSBtYXRjaGluZyByZXZpZXdzLiBXb3VsZCB5b3UgbGlrZSB0byB3cml0ZSBvbmU%2FIjoiVGhlcmUgYXJlbid0IGFueSBtYXRjaGluZyByZXZpZXdzLiBXb3VsZCB5b3UgbGlrZSB0byB3cml0ZSBvbmU%2FIiwiTGV0IG90aGVycyBrbm93IHdoYXQgaXQncyBsaWtlIHRvIHdvcmsgYXQge2NvbXBhbnlOYW1lfS4iOiJMZXQgb3RoZXJzIGtub3cgd2hhdCBpdCdzIGxpa2UgdG8gd29yayBhdCB7Y29tcGFueU5hbWV9LiIsIldyaXRlIGEgcmV2aWV3IjoiV3JpdGUgYSByZXZpZXciLCJIYXZlIHlvdXIgc2F5IjoiSGF2ZSB5b3VyIHNheSJ9!"
        )
      ),
"en-NZ": createLanguage(
        require.resolveWeak("./translations.json-en-NZ-virtual.json!=!../../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJXZSBkb24ndCBoYXZlIGVub3VnaCByZXZpZXdzIGZvciB7Y29tcGFueU5hbWV9IHlldCB0byBzaG93IGFuIGF2ZXJhZ2UgcmF0aW5nLiI6IldlIGRvbid0IGhhdmUgZW5vdWdoIHJldmlld3MgZm9yIHtjb21wYW55TmFtZX0geWV0IHRvIHNob3cgYW4gYXZlcmFnZSByYXRpbmcuIiwiVGhlcmUgYXJlbid0IGFueSBtYXRjaGluZyByZXZpZXdzLiBXb3VsZCB5b3UgbGlrZSB0byB3cml0ZSBvbmU%2FIjoiVGhlcmUgYXJlbid0IGFueSBtYXRjaGluZyByZXZpZXdzLiBXb3VsZCB5b3UgbGlrZSB0byB3cml0ZSBvbmU%2FIiwiTGV0IG90aGVycyBrbm93IHdoYXQgaXQncyBsaWtlIHRvIHdvcmsgYXQge2NvbXBhbnlOYW1lfS4iOiJMZXQgb3RoZXJzIGtub3cgd2hhdCBpdCdzIGxpa2UgdG8gd29yayBhdCB7Y29tcGFueU5hbWV9LiIsIldyaXRlIGEgcmV2aWV3IjoiV3JpdGUgYSByZXZpZXciLCJIYXZlIHlvdXIgc2F5IjoiSGF2ZSB5b3VyIHNheSJ9!"),
        () => import(
          /* webpackChunkName: "en-NZ-translations" */
          "./translations.json-en-NZ-virtual.json!=!../../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJXZSBkb24ndCBoYXZlIGVub3VnaCByZXZpZXdzIGZvciB7Y29tcGFueU5hbWV9IHlldCB0byBzaG93IGFuIGF2ZXJhZ2UgcmF0aW5nLiI6IldlIGRvbid0IGhhdmUgZW5vdWdoIHJldmlld3MgZm9yIHtjb21wYW55TmFtZX0geWV0IHRvIHNob3cgYW4gYXZlcmFnZSByYXRpbmcuIiwiVGhlcmUgYXJlbid0IGFueSBtYXRjaGluZyByZXZpZXdzLiBXb3VsZCB5b3UgbGlrZSB0byB3cml0ZSBvbmU%2FIjoiVGhlcmUgYXJlbid0IGFueSBtYXRjaGluZyByZXZpZXdzLiBXb3VsZCB5b3UgbGlrZSB0byB3cml0ZSBvbmU%2FIiwiTGV0IG90aGVycyBrbm93IHdoYXQgaXQncyBsaWtlIHRvIHdvcmsgYXQge2NvbXBhbnlOYW1lfS4iOiJMZXQgb3RoZXJzIGtub3cgd2hhdCBpdCdzIGxpa2UgdG8gd29yayBhdCB7Y29tcGFueU5hbWV9LiIsIldyaXRlIGEgcmV2aWV3IjoiV3JpdGUgYSByZXZpZXciLCJIYXZlIHlvdXIgc2F5IjoiSGF2ZSB5b3VyIHNheSJ9!"
        )
      ),
"en-PH": createLanguage(
        require.resolveWeak("./translations.json-en-PH-virtual.json!=!../../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJXZSBkb24ndCBoYXZlIGVub3VnaCByZXZpZXdzIGZvciB7Y29tcGFueU5hbWV9IHlldCB0byBzaG93IGFuIGF2ZXJhZ2UgcmF0aW5nLiI6IldlIGRvbid0IGhhdmUgZW5vdWdoIHJldmlld3MgZm9yIHtjb21wYW55TmFtZX0geWV0IHRvIHNob3cgYW4gYXZlcmFnZSByYXRpbmcuIiwiVGhlcmUgYXJlbid0IGFueSBtYXRjaGluZyByZXZpZXdzLiBXb3VsZCB5b3UgbGlrZSB0byB3cml0ZSBvbmU%2FIjoiVGhlcmUgYXJlbid0IGFueSBtYXRjaGluZyByZXZpZXdzLiBXb3VsZCB5b3UgbGlrZSB0byB3cml0ZSBvbmU%2FIiwiTGV0IG90aGVycyBrbm93IHdoYXQgaXQncyBsaWtlIHRvIHdvcmsgYXQge2NvbXBhbnlOYW1lfS4iOiJMZXQgb3RoZXJzIGtub3cgd2hhdCBpdCdzIGxpa2UgdG8gd29yayBhdCB7Y29tcGFueU5hbWV9LiIsIldyaXRlIGEgcmV2aWV3IjoiV3JpdGUgYSByZXZpZXciLCJIYXZlIHlvdXIgc2F5IjoiSGF2ZSB5b3VyIHNheSJ9!"),
        () => import(
          /* webpackChunkName: "en-PH-translations" */
          "./translations.json-en-PH-virtual.json!=!../../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJXZSBkb24ndCBoYXZlIGVub3VnaCByZXZpZXdzIGZvciB7Y29tcGFueU5hbWV9IHlldCB0byBzaG93IGFuIGF2ZXJhZ2UgcmF0aW5nLiI6IldlIGRvbid0IGhhdmUgZW5vdWdoIHJldmlld3MgZm9yIHtjb21wYW55TmFtZX0geWV0IHRvIHNob3cgYW4gYXZlcmFnZSByYXRpbmcuIiwiVGhlcmUgYXJlbid0IGFueSBtYXRjaGluZyByZXZpZXdzLiBXb3VsZCB5b3UgbGlrZSB0byB3cml0ZSBvbmU%2FIjoiVGhlcmUgYXJlbid0IGFueSBtYXRjaGluZyByZXZpZXdzLiBXb3VsZCB5b3UgbGlrZSB0byB3cml0ZSBvbmU%2FIiwiTGV0IG90aGVycyBrbm93IHdoYXQgaXQncyBsaWtlIHRvIHdvcmsgYXQge2NvbXBhbnlOYW1lfS4iOiJMZXQgb3RoZXJzIGtub3cgd2hhdCBpdCdzIGxpa2UgdG8gd29yayBhdCB7Y29tcGFueU5hbWV9LiIsIldyaXRlIGEgcmV2aWV3IjoiV3JpdGUgYSByZXZpZXciLCJIYXZlIHlvdXIgc2F5IjoiSGF2ZSB5b3VyIHNheSJ9!"
        )
      ),
"en-SG": createLanguage(
        require.resolveWeak("./translations.json-en-SG-virtual.json!=!../../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJXZSBkb24ndCBoYXZlIGVub3VnaCByZXZpZXdzIGZvciB7Y29tcGFueU5hbWV9IHlldCB0byBzaG93IGFuIGF2ZXJhZ2UgcmF0aW5nLiI6IldlIGRvbid0IGhhdmUgZW5vdWdoIHJldmlld3MgZm9yIHtjb21wYW55TmFtZX0geWV0IHRvIHNob3cgYW4gYXZlcmFnZSByYXRpbmcuIiwiVGhlcmUgYXJlbid0IGFueSBtYXRjaGluZyByZXZpZXdzLiBXb3VsZCB5b3UgbGlrZSB0byB3cml0ZSBvbmU%2FIjoiVGhlcmUgYXJlbid0IGFueSBtYXRjaGluZyByZXZpZXdzLiBXb3VsZCB5b3UgbGlrZSB0byB3cml0ZSBvbmU%2FIiwiTGV0IG90aGVycyBrbm93IHdoYXQgaXQncyBsaWtlIHRvIHdvcmsgYXQge2NvbXBhbnlOYW1lfS4iOiJMZXQgb3RoZXJzIGtub3cgd2hhdCBpdCdzIGxpa2UgdG8gd29yayBhdCB7Y29tcGFueU5hbWV9LiIsIldyaXRlIGEgcmV2aWV3IjoiV3JpdGUgYSByZXZpZXciLCJIYXZlIHlvdXIgc2F5IjoiSGF2ZSB5b3VyIHNheSJ9!"),
        () => import(
          /* webpackChunkName: "en-SG-translations" */
          "./translations.json-en-SG-virtual.json!=!../../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJXZSBkb24ndCBoYXZlIGVub3VnaCByZXZpZXdzIGZvciB7Y29tcGFueU5hbWV9IHlldCB0byBzaG93IGFuIGF2ZXJhZ2UgcmF0aW5nLiI6IldlIGRvbid0IGhhdmUgZW5vdWdoIHJldmlld3MgZm9yIHtjb21wYW55TmFtZX0geWV0IHRvIHNob3cgYW4gYXZlcmFnZSByYXRpbmcuIiwiVGhlcmUgYXJlbid0IGFueSBtYXRjaGluZyByZXZpZXdzLiBXb3VsZCB5b3UgbGlrZSB0byB3cml0ZSBvbmU%2FIjoiVGhlcmUgYXJlbid0IGFueSBtYXRjaGluZyByZXZpZXdzLiBXb3VsZCB5b3UgbGlrZSB0byB3cml0ZSBvbmU%2FIiwiTGV0IG90aGVycyBrbm93IHdoYXQgaXQncyBsaWtlIHRvIHdvcmsgYXQge2NvbXBhbnlOYW1lfS4iOiJMZXQgb3RoZXJzIGtub3cgd2hhdCBpdCdzIGxpa2UgdG8gd29yayBhdCB7Y29tcGFueU5hbWV9LiIsIldyaXRlIGEgcmV2aWV3IjoiV3JpdGUgYSByZXZpZXciLCJIYXZlIHlvdXIgc2F5IjoiSGF2ZSB5b3VyIHNheSJ9!"
        )
      ),
"en-TH": createLanguage(
        require.resolveWeak("./translations.json-en-TH-virtual.json!=!../../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJXZSBkb24ndCBoYXZlIGVub3VnaCByZXZpZXdzIGZvciB7Y29tcGFueU5hbWV9IHlldCB0byBzaG93IGFuIGF2ZXJhZ2UgcmF0aW5nLiI6IldlIGRvbid0IGhhdmUgZW5vdWdoIHJldmlld3MgZm9yIHtjb21wYW55TmFtZX0geWV0IHRvIHNob3cgYW4gYXZlcmFnZSByYXRpbmcuIiwiVGhlcmUgYXJlbid0IGFueSBtYXRjaGluZyByZXZpZXdzLiBXb3VsZCB5b3UgbGlrZSB0byB3cml0ZSBvbmU%2FIjoiVGhlcmUgYXJlbid0IGFueSBtYXRjaGluZyByZXZpZXdzLiBXb3VsZCB5b3UgbGlrZSB0byB3cml0ZSBvbmU%2FIiwiTGV0IG90aGVycyBrbm93IHdoYXQgaXQncyBsaWtlIHRvIHdvcmsgYXQge2NvbXBhbnlOYW1lfS4iOiJMZXQgb3RoZXJzIGtub3cgd2hhdCBpdCdzIGxpa2UgdG8gd29yayBhdCB7Y29tcGFueU5hbWV9LiIsIldyaXRlIGEgcmV2aWV3IjoiV3JpdGUgYSByZXZpZXciLCJIYXZlIHlvdXIgc2F5IjoiSGF2ZSB5b3VyIHNheSJ9!"),
        () => import(
          /* webpackChunkName: "en-TH-translations" */
          "./translations.json-en-TH-virtual.json!=!../../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJXZSBkb24ndCBoYXZlIGVub3VnaCByZXZpZXdzIGZvciB7Y29tcGFueU5hbWV9IHlldCB0byBzaG93IGFuIGF2ZXJhZ2UgcmF0aW5nLiI6IldlIGRvbid0IGhhdmUgZW5vdWdoIHJldmlld3MgZm9yIHtjb21wYW55TmFtZX0geWV0IHRvIHNob3cgYW4gYXZlcmFnZSByYXRpbmcuIiwiVGhlcmUgYXJlbid0IGFueSBtYXRjaGluZyByZXZpZXdzLiBXb3VsZCB5b3UgbGlrZSB0byB3cml0ZSBvbmU%2FIjoiVGhlcmUgYXJlbid0IGFueSBtYXRjaGluZyByZXZpZXdzLiBXb3VsZCB5b3UgbGlrZSB0byB3cml0ZSBvbmU%2FIiwiTGV0IG90aGVycyBrbm93IHdoYXQgaXQncyBsaWtlIHRvIHdvcmsgYXQge2NvbXBhbnlOYW1lfS4iOiJMZXQgb3RoZXJzIGtub3cgd2hhdCBpdCdzIGxpa2UgdG8gd29yayBhdCB7Y29tcGFueU5hbWV9LiIsIldyaXRlIGEgcmV2aWV3IjoiV3JpdGUgYSByZXZpZXciLCJIYXZlIHlvdXIgc2F5IjoiSGF2ZSB5b3VyIHNheSJ9!"
        )
      ),
"th-TH": createLanguage(
        require.resolveWeak("./translations.json-th-TH-virtual.json!=!../../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJXZSBkb24ndCBoYXZlIGVub3VnaCByZXZpZXdzIGZvciB7Y29tcGFueU5hbWV9IHlldCB0byBzaG93IGFuIGF2ZXJhZ2UgcmF0aW5nLiI6IuC5gOC4o%2BC4suC4ouC4seC4h%2BC4oeC4teC4o%2BC4teC4p%2BC4tOC4p%2BC4quC4s%2BC4q%2BC4o%2BC4seC4miB7Y29tcGFueU5hbWV9IOC5hOC4oeC5iOC5gOC4nuC4teC4ouC4h%2BC4nuC4reC4l%2BC4teC5iOC4iOC4sOC5geC4quC4lOC4h%2BC4hOC4sOC5geC4meC4meC5gOC4ieC4peC4teC5iOC4oiIsIlRoZXJlIGFyZW4ndCBhbnkgbWF0Y2hpbmcgcmV2aWV3cy4gV291bGQgeW91IGxpa2UgdG8gd3JpdGUgb25lPyI6IuC5hOC4oeC5iOC4nuC4muC4o%2BC4teC4p%2BC4tOC4p%2BC4l%2BC4teC5iOC4leC4o%2BC4h%2BC4geC4seC4muC4geC4suC4o%2BC4hOC5ieC4meC4q%2BC4siDguITguLjguJPguJXguYnguK3guIfguIHguLLguKPguYDguILguLXguKLguJnguKPguLXguKfguLTguKfguKvguKPguLfguK3guYTguKHguYgiLCJMZXQgb3RoZXJzIGtub3cgd2hhdCBpdCdzIGxpa2UgdG8gd29yayBhdCB7Y29tcGFueU5hbWV9LiI6IuC5gOC4peC5iOC4suC5g%2BC4q%2BC5ieC4n%2BC4seC4h%2BC4p%2BC5iOC4suC4geC4suC4o%2BC4l%2BC4s%2BC4h%2BC4suC4meC4l%2BC4teC5iCB7Y29tcGFueU5hbWV9IOC5gOC4m%2BC5h%2BC4meC4reC4ouC5iOC4suC4h%2BC5hOC4oyIsIldyaXRlIGEgcmV2aWV3Ijoi4LmA4LiC4Li14Lii4LiZ4Lij4Li14Lin4Li04LinIiwiSGF2ZSB5b3VyIHNheSI6IuC5geC4quC4lOC4h%2BC4hOC4p%2BC4suC4oeC4hOC4tOC4lOC5gOC4q%2BC5h%2BC4mSJ9!"),
        () => import(
          /* webpackChunkName: "th-TH-translations" */
          "./translations.json-th-TH-virtual.json!=!../../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJXZSBkb24ndCBoYXZlIGVub3VnaCByZXZpZXdzIGZvciB7Y29tcGFueU5hbWV9IHlldCB0byBzaG93IGFuIGF2ZXJhZ2UgcmF0aW5nLiI6IuC5gOC4o%2BC4suC4ouC4seC4h%2BC4oeC4teC4o%2BC4teC4p%2BC4tOC4p%2BC4quC4s%2BC4q%2BC4o%2BC4seC4miB7Y29tcGFueU5hbWV9IOC5hOC4oeC5iOC5gOC4nuC4teC4ouC4h%2BC4nuC4reC4l%2BC4teC5iOC4iOC4sOC5geC4quC4lOC4h%2BC4hOC4sOC5geC4meC4meC5gOC4ieC4peC4teC5iOC4oiIsIlRoZXJlIGFyZW4ndCBhbnkgbWF0Y2hpbmcgcmV2aWV3cy4gV291bGQgeW91IGxpa2UgdG8gd3JpdGUgb25lPyI6IuC5hOC4oeC5iOC4nuC4muC4o%2BC4teC4p%2BC4tOC4p%2BC4l%2BC4teC5iOC4leC4o%2BC4h%2BC4geC4seC4muC4geC4suC4o%2BC4hOC5ieC4meC4q%2BC4siDguITguLjguJPguJXguYnguK3guIfguIHguLLguKPguYDguILguLXguKLguJnguKPguLXguKfguLTguKfguKvguKPguLfguK3guYTguKHguYgiLCJMZXQgb3RoZXJzIGtub3cgd2hhdCBpdCdzIGxpa2UgdG8gd29yayBhdCB7Y29tcGFueU5hbWV9LiI6IuC5gOC4peC5iOC4suC5g%2BC4q%2BC5ieC4n%2BC4seC4h%2BC4p%2BC5iOC4suC4geC4suC4o%2BC4l%2BC4s%2BC4h%2BC4suC4meC4l%2BC4teC5iCB7Y29tcGFueU5hbWV9IOC5gOC4m%2BC5h%2BC4meC4reC4ouC5iOC4suC4h%2BC5hOC4oyIsIldyaXRlIGEgcmV2aWV3Ijoi4LmA4LiC4Li14Lii4LiZ4Lij4Li14Lin4Li04LinIiwiSGF2ZSB5b3VyIHNheSI6IuC5geC4quC4lOC4h%2BC4hOC4p%2BC4suC4oeC4hOC4tOC4lOC5gOC4q%2BC5h%2BC4mSJ9!"
        )
      ),
"id-ID": createLanguage(
        require.resolveWeak("./translations.json-id-ID-virtual.json!=!../../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJXZSBkb24ndCBoYXZlIGVub3VnaCByZXZpZXdzIGZvciB7Y29tcGFueU5hbWV9IHlldCB0byBzaG93IGFuIGF2ZXJhZ2UgcmF0aW5nLiI6IlBlbmlsYWlhbiB1bnR1ayB7Y29tcGFueU5hbWV9IGFrYW4gdGVyc2VkaWEgc2V0ZWxhaCBhZGEgbGViaWggYmFueWFrIHVsYXNhbiIsIlRoZXJlIGFyZW4ndCBhbnkgbWF0Y2hpbmcgcmV2aWV3cy4gV291bGQgeW91IGxpa2UgdG8gd3JpdGUgb25lPyI6IkJlbHVtIGFkYSB1bGFzYW4gdW50dWsgcGVydXNhaGFhbiBpbmkuIEluZ2luIG1lbmFtYmFoa2FuPyIsIkxldCBvdGhlcnMga25vdyB3aGF0IGl0J3MgbGlrZSB0byB3b3JrIGF0IHtjb21wYW55TmFtZX0uIjoiQ2VyaXRha2FuIHBlbmdhbGFtYW4gc2FhdCBiZWtlcmphIGRpIHtjb21wYW55TmFtZX0uIiwiV3JpdGUgYSByZXZpZXciOiJUdWxpcyB1bGFzYW4iLCJIYXZlIHlvdXIgc2F5IjoiQmFnaWthbiBwZW5kYXBhdCBBbmRhIn0%3D!"),
        () => import(
          /* webpackChunkName: "id-ID-translations" */
          "./translations.json-id-ID-virtual.json!=!../../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJXZSBkb24ndCBoYXZlIGVub3VnaCByZXZpZXdzIGZvciB7Y29tcGFueU5hbWV9IHlldCB0byBzaG93IGFuIGF2ZXJhZ2UgcmF0aW5nLiI6IlBlbmlsYWlhbiB1bnR1ayB7Y29tcGFueU5hbWV9IGFrYW4gdGVyc2VkaWEgc2V0ZWxhaCBhZGEgbGViaWggYmFueWFrIHVsYXNhbiIsIlRoZXJlIGFyZW4ndCBhbnkgbWF0Y2hpbmcgcmV2aWV3cy4gV291bGQgeW91IGxpa2UgdG8gd3JpdGUgb25lPyI6IkJlbHVtIGFkYSB1bGFzYW4gdW50dWsgcGVydXNhaGFhbiBpbmkuIEluZ2luIG1lbmFtYmFoa2FuPyIsIkxldCBvdGhlcnMga25vdyB3aGF0IGl0J3MgbGlrZSB0byB3b3JrIGF0IHtjb21wYW55TmFtZX0uIjoiQ2VyaXRha2FuIHBlbmdhbGFtYW4gc2FhdCBiZWtlcmphIGRpIHtjb21wYW55TmFtZX0uIiwiV3JpdGUgYSByZXZpZXciOiJUdWxpcyB1bGFzYW4iLCJIYXZlIHlvdXIgc2F5IjoiQmFnaWthbiBwZW5kYXBhdCBBbmRhIn0%3D!"
        )
      ),
"zh-HK": createLanguage(
        require.resolveWeak("./translations.json-zh-HK-virtual.json!=!../../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJXZSBkb24ndCBoYXZlIGVub3VnaCByZXZpZXdzIGZvciB7Y29tcGFueU5hbWV9IHlldCB0byBzaG93IGFuIGF2ZXJhZ2UgcmF0aW5nLiI6IldlIGRvbid0IGhhdmUgZW5vdWdoIHJldmlld3MgZm9yIHtjb21wYW55TmFtZX0geWV0IHRvIHNob3cgYW4gYXZlcmFnZSByYXRpbmcuIiwiVGhlcmUgYXJlbid0IGFueSBtYXRjaGluZyByZXZpZXdzLiBXb3VsZCB5b3UgbGlrZSB0byB3cml0ZSBvbmU%2FIjoiVGhlcmUgYXJlbid0IGFueSBtYXRjaGluZyByZXZpZXdzLiBXb3VsZCB5b3UgbGlrZSB0byB3cml0ZSBvbmU%2FIiwiTGV0IG90aGVycyBrbm93IHdoYXQgaXQncyBsaWtlIHRvIHdvcmsgYXQge2NvbXBhbnlOYW1lfS4iOiJMZXQgb3RoZXJzIGtub3cgd2hhdCBpdCdzIGxpa2UgdG8gd29yayBhdCB7Y29tcGFueU5hbWV9LiIsIldyaXRlIGEgcmV2aWV3IjoiV3JpdGUgYSByZXZpZXciLCJIYXZlIHlvdXIgc2F5IjoiSGF2ZSB5b3VyIHNheSJ9!"),
        () => import(
          /* webpackChunkName: "zh-HK-translations" */
          "./translations.json-zh-HK-virtual.json!=!../../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJXZSBkb24ndCBoYXZlIGVub3VnaCByZXZpZXdzIGZvciB7Y29tcGFueU5hbWV9IHlldCB0byBzaG93IGFuIGF2ZXJhZ2UgcmF0aW5nLiI6IldlIGRvbid0IGhhdmUgZW5vdWdoIHJldmlld3MgZm9yIHtjb21wYW55TmFtZX0geWV0IHRvIHNob3cgYW4gYXZlcmFnZSByYXRpbmcuIiwiVGhlcmUgYXJlbid0IGFueSBtYXRjaGluZyByZXZpZXdzLiBXb3VsZCB5b3UgbGlrZSB0byB3cml0ZSBvbmU%2FIjoiVGhlcmUgYXJlbid0IGFueSBtYXRjaGluZyByZXZpZXdzLiBXb3VsZCB5b3UgbGlrZSB0byB3cml0ZSBvbmU%2FIiwiTGV0IG90aGVycyBrbm93IHdoYXQgaXQncyBsaWtlIHRvIHdvcmsgYXQge2NvbXBhbnlOYW1lfS4iOiJMZXQgb3RoZXJzIGtub3cgd2hhdCBpdCdzIGxpa2UgdG8gd29yayBhdCB7Y29tcGFueU5hbWV9LiIsIldyaXRlIGEgcmV2aWV3IjoiV3JpdGUgYSByZXZpZXciLCJIYXZlIHlvdXIgc2F5IjoiSGF2ZSB5b3VyIHNheSJ9!"
        )
      ),
"en-PSEUDO": createLanguage(
        require.resolveWeak("./translations.json-en-PSEUDO-virtual.json!=!../../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJXZSBkb24ndCBoYXZlIGVub3VnaCByZXZpZXdzIGZvciB7Y29tcGFueU5hbWV9IHlldCB0byBzaG93IGFuIGF2ZXJhZ2UgcmF0aW5nLiI6IlvFtOG6veG6veG6vSDGjMO2w7bDtuC4geC4teC5iSfhua8g4bipxIPEg8SD4bm94bq94bq94bq9IOG6veG6veG6veC4geC4teC5icO2w7bDtseax5rHmsSj4bipIMWZ4bq94bq94bq94bm9w6zDrMOs4bq94bq94bq9xbXFoSDGksO2w7bDtsWZIHtjb21wYW55TmFtZX0gw73DvcO94bq94bq94bq94bmvIOG5r8O2w7bDtiDFoeG4qcO2w7bDtsW1IMSDxIPEg%2BC4geC4teC5iSDEg8SDxIPhub3hur3hur3hur3FmcSDxIPEg8Sj4bq94bq94bq9IMWZxIPEg8SD4bmvw6zDrMOs4LiB4Li14LmJxKMuXSIsIlRoZXJlIGFyZW4ndCBhbnkgbWF0Y2hpbmcgcmV2aWV3cy4gV291bGQgeW91IGxpa2UgdG8gd3JpdGUgb25lPyI6Ilvhua7huKnhur3hur3hur3FmeG6veG6veG6vSDEg8SDxIPFmeG6veG6veG6veC4geC4teC5iSfhua8gxIPEg8SD4LiB4Li14LmJw73DvcO9IG3MgsSDxIPEg%2BG5r8On4bipw6zDrMOs4LiB4Li14LmJxKMgxZnhur3hur3hur3hub3DrMOsw6zhur3hur3hur3FtcWhLiDFtMO2w7bDtseax5rHmsaaxowgw73DvcO9w7bDtsO2x5rHmseaIMaaw6zDrMOsxLfhur3hur3hur0g4bmvw7bDtsO2IMW1xZnDrMOsw6zhua%2Fhur3hur3hur0gw7bDtsO24LiB4Li14LmJ4bq94bq94bq9P10iLCJMZXQgb3RoZXJzIGtub3cgd2hhdCBpdCdzIGxpa2UgdG8gd29yayBhdCB7Y29tcGFueU5hbWV9LiI6IlvFgeG6veG6veG6veG5ryDDtsO2w7bhua%2FhuKnhur3hur3hur3FmcWhIMS34LiB4Li14LmJw7bDtsO2xbUgxbXhuKnEg8SDxIPhua8gw6zDrMOs4bmvJ8WhIMaaw6zDrMOsxLfhur3hur3hur0g4bmvw7bDtsO2IMW1w7bDtsO2xZnEtyDEg8SDxIPhua8ge2NvbXBhbnlOYW1lfS5dIiwiV3JpdGUgYSByZXZpZXciOiJbxbTFmcOsw6zhua%2Fhur3hur0gxIPEgyDFmeG6veG6veG5vcOsw6zhur3hur3FtV0iLCJIYXZlIHlvdXIgc2F5IjoiW%2BG4qMSDxIPhub3hur3hur0gw73DvcO2w7bHmseaxZkgxaHEg8SDw73DvV0ifQ%3D%3D!"),
        () => import(
          /* webpackChunkName: "en-PSEUDO-translations" */
          "./translations.json-en-PSEUDO-virtual.json!=!../../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJXZSBkb24ndCBoYXZlIGVub3VnaCByZXZpZXdzIGZvciB7Y29tcGFueU5hbWV9IHlldCB0byBzaG93IGFuIGF2ZXJhZ2UgcmF0aW5nLiI6IlvFtOG6veG6veG6vSDGjMO2w7bDtuC4geC4teC5iSfhua8g4bipxIPEg8SD4bm94bq94bq94bq9IOG6veG6veG6veC4geC4teC5icO2w7bDtseax5rHmsSj4bipIMWZ4bq94bq94bq94bm9w6zDrMOs4bq94bq94bq9xbXFoSDGksO2w7bDtsWZIHtjb21wYW55TmFtZX0gw73DvcO94bq94bq94bq94bmvIOG5r8O2w7bDtiDFoeG4qcO2w7bDtsW1IMSDxIPEg%2BC4geC4teC5iSDEg8SDxIPhub3hur3hur3hur3FmcSDxIPEg8Sj4bq94bq94bq9IMWZxIPEg8SD4bmvw6zDrMOs4LiB4Li14LmJxKMuXSIsIlRoZXJlIGFyZW4ndCBhbnkgbWF0Y2hpbmcgcmV2aWV3cy4gV291bGQgeW91IGxpa2UgdG8gd3JpdGUgb25lPyI6Ilvhua7huKnhur3hur3hur3FmeG6veG6veG6vSDEg8SDxIPFmeG6veG6veG6veC4geC4teC5iSfhua8gxIPEg8SD4LiB4Li14LmJw73DvcO9IG3MgsSDxIPEg%2BG5r8On4bipw6zDrMOs4LiB4Li14LmJxKMgxZnhur3hur3hur3hub3DrMOsw6zhur3hur3hur3FtcWhLiDFtMO2w7bDtseax5rHmsaaxowgw73DvcO9w7bDtsO2x5rHmseaIMaaw6zDrMOsxLfhur3hur3hur0g4bmvw7bDtsO2IMW1xZnDrMOsw6zhua%2Fhur3hur3hur0gw7bDtsO24LiB4Li14LmJ4bq94bq94bq9P10iLCJMZXQgb3RoZXJzIGtub3cgd2hhdCBpdCdzIGxpa2UgdG8gd29yayBhdCB7Y29tcGFueU5hbWV9LiI6IlvFgeG6veG6veG6veG5ryDDtsO2w7bhua%2FhuKnhur3hur3hur3FmcWhIMS34LiB4Li14LmJw7bDtsO2xbUgxbXhuKnEg8SDxIPhua8gw6zDrMOs4bmvJ8WhIMaaw6zDrMOsxLfhur3hur3hur0g4bmvw7bDtsO2IMW1w7bDtsO2xZnEtyDEg8SDxIPhua8ge2NvbXBhbnlOYW1lfS5dIiwiV3JpdGUgYSByZXZpZXciOiJbxbTFmcOsw6zhua%2Fhur3hur0gxIPEgyDFmeG6veG6veG5vcOsw6zhur3hur3FtV0iLCJIYXZlIHlvdXIgc2F5IjoiW%2BG4qMSDxIPhub3hur3hur0gw73DvcO2w7bHmseaxZkgxaHEg8SDw73DvV0ifQ%3D%3D!"
        )
      )
      });
  
      export { translations as default };
    