import { creatRestClient, type Jobs, type Logger } from '@seek/libs-shared';
import type { Locale } from '@seek/melways-sites';

import type { AppConfig } from '../../config';

import { buildJobSearchUrlForSearch, mapJobsModel } from './mapper';
import type { GetJobsListResponse } from './types';

const DEFAULT_PAGE_SIZE = 10;

interface GetJobsListPayload {
  country: string;
  locale: Locale;
  organisationId: string;
  page: number;
}

export type JobService = ReturnType<typeof createJobService>;

export const createJobService = ({
  config,
  logger,
}: {
  config: AppConfig;
  logger: Logger;
}) => {
  const { searchApiBaseUrl } = config;

  const headers: Record<string, string> = {
    'User-Agent': 'bx-frontends',
  };
  if (config.clientIp) {
    headers['x-real-ip'] = config.clientIp;
  }

  const { get } = creatRestClient(searchApiBaseUrl, logger, headers);

  const getJobsList = async ({
    organisationId,
    page,
    country,
    locale,
  }: GetJobsListPayload): Promise<Jobs> => {
    const jobsUrl = buildJobSearchUrlForSearch({
      organisationId,
      page,
      pageSize: DEFAULT_PAGE_SIZE,
      country,
      source: 'COMPANY',
      locale,
    });
    try {
      const res = await get<GetJobsListResponse>(jobsUrl);

      return mapJobsModel(res?.data);
    } catch (error) {
      logger.error(
        { organisationId, page, country, locale, error },
        'Failed to get jobs list',
      );
    }

    return {
      jobs: [],
      totalJobs: 0,
    };
  };

  return {
    getJobsList,
  };
};
