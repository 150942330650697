import { useRouteError } from 'react-router';

import type { Logger, BaseConfig } from '../../../';
import { ErrorBoundary } from './ErrorBoundary';

interface Props {
  config: BaseConfig;
  logger: Logger;
}

type ErrorObject = Error & {
  graphQLErrors: unknown[];
  networkError: {
    result: unknown[];
  };
  status: number;
  cause?: {
    result?: string;
    statusCode?: number;
  };
};

export function ErrorBoundaryContainer({ logger, config }: Props) {
  const error = useRouteError() as ErrorObject;

  const routeError = {
    ...error,
    graphQLErrorsStringified: JSON.stringify(error.graphQLErrors || []),
    networkError: {
      ...error.networkError,
      result: JSON.stringify(error.networkError?.result || []),
    },
  };

  // If its a 404 response, we don't want to log it as an error
  // If its a 429 response, we don't want to log it as an error as this is a potential DDOS attack or scraping that candidate graph is blocking
  // If its a 400 and result: Query request complexity limit has been reached. Then we don't want to log it as an error
  if (
    error.status === 404 ||
    error.status === 429 ||
    error.cause?.statusCode === 429 ||
    (error.cause?.statusCode === 400 &&
      error.cause?.result?.includes(
        'Query request complexity limit has been reached.',
      ))
  ) {
    logger.warn(
      {
        ...routeError,
      },
      'ErrorBoundary caught an warning',
    );
  } else {
    logger.error(
      {
        ...routeError,
      },
      'ErrorBoundary caught an error',
    );
  }

  return <ErrorBoundary config={config} />;
}
