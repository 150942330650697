import type { CompanyReviewLocation } from '@seek/ca-graphql-schema/types';

import type { Reviews, CompanyProfile } from '@seek/libs-shared';

import type { ReviewsTabModel } from './reviewsTabModel';

export const mapCompanyReviewLocationsModel = (
  locations?: CompanyReviewLocation[],
): ReviewsTabModel['locations'] =>
  locations?.length
    ? locations.map((location) => ({
        url: location.relativeUrl,
        name: location.contextualName,
      }))
    : undefined;

export const mapReviewModel = (
  companyProfile: CompanyProfile,
  reviews: Reviews,
  companyReviewLocations?: CompanyReviewLocation[],
): ReviewsTabModel => ({
  reviewSummary: {
    name: companyProfile.name,
    ratingBreakdown: companyProfile.ratingBreakdown,
    salaryRating: companyProfile.salaryRating,
    employeeRecommendationRating: companyProfile.employeeRecommendationRating,
    companyReviewSummaryBreakdown: companyProfile.companyReviewSummaryBreakdown,
    ...(companyProfile.overallRating
      ? {
          overallRating: {
            average: companyProfile.overallRating?.average,
            count: companyProfile.overallRating?.count,
          },
        }
      : {}),
  },
  reviews: {
    ...reviews,
    companyId: companyProfile.id,
  },
  name: companyProfile.name,
  overview: companyProfile.overview,
  branding: companyProfile.branding,
  locations: mapCompanyReviewLocationsModel(companyReviewLocations),
});
