import { buildMelwaysUrlResolverURI } from '@seek/libs-shared';
import type { Language } from '@seek/melways-sites';
import type { Params } from 'react-router';


import type { AppConfig } from '../../../shared/config';
import { isLocationBasedReviewRoute } from '../../../shared/utils/isLocationBasedReviewRoute';

export const getLanguageUrl = (options: {
  config: AppConfig;
  selectedLanguage: Language;
  urlParams: Readonly<Params<'locationSlug'>>;
  absoluteUrl?: string;
}): string => {
  const { urlParams, config, selectedLanguage } = options;
  const { locationSlug } = urlParams;
  const { reviewLocation, language, siteName, country, environment } = config;
  const absoluteUrl = new URL(
    // [priority] client-side url
    options.absoluteUrl ||
      // server-side url
      config.absoluteUrl,
  );
  const path = absoluteUrl.pathname;

  const isLocationRoute = isLocationBasedReviewRoute({
    locationSlug,
    locationId: reviewLocation.details?.locationId,
  });

  const splitPathByLanguage =
    language !== 'en'
      ? path.split(`/${language}/companies`)
      : path.split('/companies');
  const defaultPath = `/companies${splitPathByLanguage[1].length ? `${splitPathByLanguage[1]}` : ''}`;

  const splitPathByLocationSlug = isLocationRoute
    ? defaultPath.split(`/${encodeURIComponent(locationSlug || '')}`)
    : [defaultPath];

  const newLocationSlug = isLocationRoute
    ? reviewLocation.details?.slugs[
        selectedLanguage === 'zh' ? 'en' : selectedLanguage
      ] ||
      locationSlug ||
      ''
    : '';
  const newPath = `${splitPathByLocationSlug[0]}${newLocationSlug.length ? `/${newLocationSlug}` : ''}`;

  const queryParams = Object.fromEntries(absoluteUrl.searchParams.entries());

  return decodeURIComponent(
    buildMelwaysUrlResolverURI({
      site: siteName,
      language: selectedLanguage,
      country,
      path: newPath,
      staging: environment === 'staging',
      queryParams,
    }),
  );
};

export const getLanguageSwitcher = (options: {
  config: AppConfig;
  urlParams: Readonly<Params<'locationSlug'>>;
}): Partial<Record<Language, { url: string }>> => {
  const { country } = options.config;

  switch (country) {
    case 'id':
      return {
        en: { url: getLanguageUrl({ ...options, selectedLanguage: 'en' }) },
        id: { url: getLanguageUrl({ ...options, selectedLanguage: 'id' }) },
      };
    case 'th':
      return {
        en: { url: getLanguageUrl({ ...options, selectedLanguage: 'en' }) },
        th: { url: getLanguageUrl({ ...options, selectedLanguage: 'th' }) },
      };
    default:
      return {};
  }
};
