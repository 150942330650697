import { Box, Loader, Stack, Text } from 'braid-design-system';

import { type ReviewRowModel, ReviewRowRating } from '@seek/libs-shared';
import { absoluteDateFormatter } from '@seek/unified-display-date-time';

interface Props {
  model: ReviewRowModel;
  showBreakdown?: boolean;
}

export const ReviewRowRatingSummary = ({
  model,
  showBreakdown = true,
}: Props) => {
  const { createdAt } = model;
  const unifiedCreatedAt = absoluteDateFormatter({
    date: new Date(createdAt),
    format: 'shortMonthYear',
  });

  if (!model) {
    return <Loader />;
  }

  const { employmentStatus, jobTitle, workLocation } = model;

  return (
    <Stack space="medium">
      <Stack space="small">
        {model && (
          <ReviewRowRating model={model} showBreakdown={showBreakdown} />
        )}
        <Box display="inlineBlock" width="full">
          <Text size="small" weight="strong">
            {jobTitle}
          </Text>
        </Box>
        <Text tone="secondary" size="small">
          {unifiedCreatedAt.isValid ? unifiedCreatedAt.formattedDate : ''}
        </Text>
      </Stack>
      <Stack space="xsmall">
        <Text size="small" tone="neutral">
          {workLocation}
        </Text>
        <Text size="small" tone="neutral">
          {employmentStatus}
        </Text>
      </Stack>
    </Stack>
  );
};
