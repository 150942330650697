import { gql } from '@apollo/client';

export const POST_COMPANY_REVIEW_MUTATE = gql`
  mutation Mutation($input: PostCompanyReviewInput!) {
    postCompanyReview(input: $input) {
      ... on PostCompanyReviewSuccess {
        reviewId
      }
      ... on PostCompanyReviewFailure {
        errors {
          message
        }
      }
    }
  }
`;
