import { useTranslations } from '@vocab/react';
import { Pagination, Stack, Tiles } from 'braid-design-system';
import { useState } from 'react';
import { PhotoGalleryModal } from './PhotoGalleryModal/PhotoGalleryModal';
import { PhotoTile } from './PhotoTile/PhotoTile';
import translations from './.vocab';
import { toPageInfo } from '../../../utils/pagination';
import type { Gallery } from '../../../models/CompanyProfile';

export type GalleryPaginationType = 'pageNumber' | 'next' | 'previous';

interface PhotoTilesProps {
  gallery: Gallery;
  modalTitle: string;
  showPagination: boolean;
  mode?: 'view' | 'edit';
  scrollToGallery?: () => void;
  tilesPerPage?: number;
  trackPhotosPaginationClicked?: ({
    pageNumber,
    type,
  }: {
    pageNumber: number;
    type: GalleryPaginationType;
  }) => void;
}

export const PhotoTiles = ({
  gallery,
  modalTitle,
  scrollToGallery,
  trackPhotosPaginationClicked,
  mode = 'view',
  tilesPerPage = 16,
  showPagination = true,
}: PhotoTilesProps) => {
  const [currentPage, setCurrentPage] = useState(1);
  const [modalOpen, setModalOpen] = useState(false);
  const [currentPhotoIndex, setCurrentPhotoIndex] = useState(0);

  const { t } = useTranslations(translations);

  const pageInfo = toPageInfo(gallery.photos, tilesPerPage, currentPage);

  const toggleModal = () => {
    setModalOpen(!modalOpen);
  };

  const handleImageClick =
    (index: number) => (e: React.MouseEvent<HTMLElement>) => {
      e.currentTarget.blur();
      setCurrentPhotoIndex(tilesPerPage * (pageInfo.pageNumber - 1) + index);
      setModalOpen(true);
    };

  const onPaginationLinkClick =
    ({
      pageNumber,
      type,
    }: {
      pageNumber: number;
      type: GalleryPaginationType;
    }) =>
    (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
      e.preventDefault();
      if (typeof scrollToGallery === 'function') {
        setTimeout(scrollToGallery, 300);
      }
      setCurrentPage(pageNumber);
      if (trackPhotosPaginationClicked) {
        trackPhotosPaginationClicked({
          pageNumber,
          type,
        });
      }
    };

  return (
    <>
      {modalOpen ? (
        <PhotoGalleryModal
          currentPhotoIndex={currentPhotoIndex}
          photos={gallery.photos}
          open={modalOpen}
          onClose={toggleModal}
          title={modalTitle}
        />
      ) : null}
      <Stack space="xlarge">
        <Tiles columns={[2, 3, 4]} space="small">
          {pageInfo.items.map((photo, index) => (
            <PhotoTile
              key={index}
              link={photo.link}
              title={photo.title}
              date={photo.date}
              onClick={handleImageClick(index)}
              mode={mode}
            />
          ))}
        </Tiles>
        {showPagination && pageInfo.pagesCount > 1 && (
          <Pagination
            page={currentPage}
            total={pageInfo.pagesCount}
            label={t('Pagination of results')}
            linkProps={({ page, type }) => ({
              href: `#${page}`,
              onClick: onPaginationLinkClick({
                pageNumber: page,
                type,
              }),
            })}
            nextLabel={t('Next')}
            previousLabel={t('Previous')}
          />
        )}
      </Stack>
    </>
  );
};
