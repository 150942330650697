
      import { createLanguage, createTranslationFile } from '@vocab/webpack/web';
      
    const translations = createTranslationFile({
      "en": createLanguage(
        require.resolveWeak("./translations.json-en-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJXaGF0IGRvIHBlb3BsZSBzYXkgLi4uIjoiV2hhdCBkbyBwZW9wbGUgc2F5IGFib3V0IHdvcmtpbmcgYXQge2NvbXBhbnlOYW1lfT8iLCJBSSBzdW1tYXJ5IG9mIHJlY2VudCByZXZpZXdzIjoiQUkgc3VtbWFyeSBvZiByZWNlbnQgcmV2aWV3cyIsIkFJIHN1bW1hcnkgdG9vbHRpcHMiOiIoQmV0YSkgQUkgZ2VuZXJhdGVkIHN1bW1hcnkgYmFzZWQgb24gdGhlIHtyZXZpZXdzQ291bnR9IG1vc3QgcmVjZW50IHJldmlld3MgZm9yIHRoaXMgY29tcGFueSB7cmV2aWV3c0RhdGVSYW5nZX0uIFRoZSBzdW1tYXJ5IGlzIHJlZnJlc2hlZCBlYWNoIHRpbWUgYSBuZXcgcmV2aWV3IGlzIGFkZGVkLiJ9!"),
        () => import(
          /* webpackChunkName: "en-translations" */
          "./translations.json-en-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJXaGF0IGRvIHBlb3BsZSBzYXkgLi4uIjoiV2hhdCBkbyBwZW9wbGUgc2F5IGFib3V0IHdvcmtpbmcgYXQge2NvbXBhbnlOYW1lfT8iLCJBSSBzdW1tYXJ5IG9mIHJlY2VudCByZXZpZXdzIjoiQUkgc3VtbWFyeSBvZiByZWNlbnQgcmV2aWV3cyIsIkFJIHN1bW1hcnkgdG9vbHRpcHMiOiIoQmV0YSkgQUkgZ2VuZXJhdGVkIHN1bW1hcnkgYmFzZWQgb24gdGhlIHtyZXZpZXdzQ291bnR9IG1vc3QgcmVjZW50IHJldmlld3MgZm9yIHRoaXMgY29tcGFueSB7cmV2aWV3c0RhdGVSYW5nZX0uIFRoZSBzdW1tYXJ5IGlzIHJlZnJlc2hlZCBlYWNoIHRpbWUgYSBuZXcgcmV2aWV3IGlzIGFkZGVkLiJ9!"
        )
      ),
"th": createLanguage(
        require.resolveWeak("./translations.json-th-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJXaGF0IGRvIHBlb3BsZSBzYXkgLi4uIjoi4Lic4Li54LmJ4LiE4LiZ4Lie4Li54LiU4LiW4Li24LiH4LiB4Liy4Lij4LiX4Liz4LiH4Liy4LiZ4LiX4Li14LmIIHtjb21wYW55TmFtZX0g4Lit4Lii4LmI4Liy4LiH4LmE4Lij4Lia4LmJ4Liy4LiHPyIsIkFJIHN1bW1hcnkgb2YgcmVjZW50IHJldmlld3MiOiLguJrguJfguKrguKPguLjguJvguIjguLLguIHguKPguLXguKfguLTguKfguYPguJnguIrguYjguKfguIfguKXguYjguLLguKrguLjguJTguYLguJTguKIgQUkiLCJBSSBzdW1tYXJ5IHRvb2x0aXBzIjoiKOC5gOC4p%2BC4reC4o%2BC5jOC4iuC4seC4meC4l%2BC4lOC4peC4reC4hykg4Liq4Lij4Li44Lib4LiB4Liy4Lij4Lij4Li14Lin4Li04Lin4LmC4LiU4LiiIEFJIOC4i%2BC4tuC5iOC4h%2BC4reC4tOC4h%2BC4iOC4suC4geC4geC4suC4o%2BC4o%2BC4teC4p%2BC4tOC4pyB7cmV2aWV3c0NvdW50fSDguITguKPguLHguYnguIfguKXguYjguLLguKrguLjguJTguYDguIHguLXguYjguKLguKfguIHguLHguJrguJrguKPguLTguKnguLHguJfguJnguLXguYkge3Jldmlld3NEYXRlUmFuZ2V9IOC5guC4lOC4ouC5gOC4oeC4t%2BC5iOC4reC4oeC4teC4geC4suC4o%2BC4o%2BC4teC4p%2BC4tOC4p%2BC5g%2BC4q%2BC4oeC5iOC5gOC4nuC4tOC5iOC4oeC5gOC4guC5ieC4suC4oeC4siDguIjguLDguKHguLXguIHguLLguKPguK3guLHguJvguYDguJTguJXguKrguKPguLjguJvguYPguKvguKHguYjguYDguKrguKHguK0ifQ%3D%3D!"),
        () => import(
          /* webpackChunkName: "th-translations" */
          "./translations.json-th-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJXaGF0IGRvIHBlb3BsZSBzYXkgLi4uIjoi4Lic4Li54LmJ4LiE4LiZ4Lie4Li54LiU4LiW4Li24LiH4LiB4Liy4Lij4LiX4Liz4LiH4Liy4LiZ4LiX4Li14LmIIHtjb21wYW55TmFtZX0g4Lit4Lii4LmI4Liy4LiH4LmE4Lij4Lia4LmJ4Liy4LiHPyIsIkFJIHN1bW1hcnkgb2YgcmVjZW50IHJldmlld3MiOiLguJrguJfguKrguKPguLjguJvguIjguLLguIHguKPguLXguKfguLTguKfguYPguJnguIrguYjguKfguIfguKXguYjguLLguKrguLjguJTguYLguJTguKIgQUkiLCJBSSBzdW1tYXJ5IHRvb2x0aXBzIjoiKOC5gOC4p%2BC4reC4o%2BC5jOC4iuC4seC4meC4l%2BC4lOC4peC4reC4hykg4Liq4Lij4Li44Lib4LiB4Liy4Lij4Lij4Li14Lin4Li04Lin4LmC4LiU4LiiIEFJIOC4i%2BC4tuC5iOC4h%2BC4reC4tOC4h%2BC4iOC4suC4geC4geC4suC4o%2BC4o%2BC4teC4p%2BC4tOC4pyB7cmV2aWV3c0NvdW50fSDguITguKPguLHguYnguIfguKXguYjguLLguKrguLjguJTguYDguIHguLXguYjguKLguKfguIHguLHguJrguJrguKPguLTguKnguLHguJfguJnguLXguYkge3Jldmlld3NEYXRlUmFuZ2V9IOC5guC4lOC4ouC5gOC4oeC4t%2BC5iOC4reC4oeC4teC4geC4suC4o%2BC4o%2BC4teC4p%2BC4tOC4p%2BC5g%2BC4q%2BC4oeC5iOC5gOC4nuC4tOC5iOC4oeC5gOC4guC5ieC4suC4oeC4siDguIjguLDguKHguLXguIHguLLguKPguK3guLHguJvguYDguJTguJXguKrguKPguLjguJvguYPguKvguKHguYjguYDguKrguKHguK0ifQ%3D%3D!"
        )
      ),
"id": createLanguage(
        require.resolveWeak("./translations.json-id-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJXaGF0IGRvIHBlb3BsZSBzYXkgLi4uIjoiQXBhIGthdGEgbWVyZWthIHRlbnRhbmcgYmVrZXJqYSBkaSB7Y29tcGFueU5hbWV9PyIsIkFJIHN1bW1hcnkgb2YgcmVjZW50IHJldmlld3MiOiJSaW5na2FzYW4gdWxhc2FuIHRlcmJhcnUgZGFyaSBBSSIsIkFJIHN1bW1hcnkgdG9vbHRpcHMiOiIoQmV0YSkgUmluZ2thc2FuIGluaSBkaWJ1YXQgb2xlaCBBSSBiZXJkYXNhcmthbiB7cmV2aWV3c0NvdW50fSB1bGFzYW4gdGVyYmFydSBwZXJ1c2FoYWFuIHtyZXZpZXdzRGF0ZVJhbmdlfS4gU2VsYWx1IGRpcGVyYmFydWkgc2V0aWFwIGFkYSB1bGFzYW4gYmFydS4ifQ%3D%3D!"),
        () => import(
          /* webpackChunkName: "id-translations" */
          "./translations.json-id-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJXaGF0IGRvIHBlb3BsZSBzYXkgLi4uIjoiQXBhIGthdGEgbWVyZWthIHRlbnRhbmcgYmVrZXJqYSBkaSB7Y29tcGFueU5hbWV9PyIsIkFJIHN1bW1hcnkgb2YgcmVjZW50IHJldmlld3MiOiJSaW5na2FzYW4gdWxhc2FuIHRlcmJhcnUgZGFyaSBBSSIsIkFJIHN1bW1hcnkgdG9vbHRpcHMiOiIoQmV0YSkgUmluZ2thc2FuIGluaSBkaWJ1YXQgb2xlaCBBSSBiZXJkYXNhcmthbiB7cmV2aWV3c0NvdW50fSB1bGFzYW4gdGVyYmFydSBwZXJ1c2FoYWFuIHtyZXZpZXdzRGF0ZVJhbmdlfS4gU2VsYWx1IGRpcGVyYmFydWkgc2V0aWFwIGFkYSB1bGFzYW4gYmFydS4ifQ%3D%3D!"
        )
      ),
"zh": createLanguage(
        require.resolveWeak("./translations.json-zh-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJXaGF0IGRvIHBlb3BsZSBzYXkgLi4uIjoiV2hhdCBkbyBwZW9wbGUgc2F5IGFib3V0IHdvcmtpbmcgYXQge2NvbXBhbnlOYW1lfT8iLCJBSSBzdW1tYXJ5IG9mIHJlY2VudCByZXZpZXdzIjoiQUkgc3VtbWFyeSBvZiByZWNlbnQgcmV2aWV3cyIsIkFJIHN1bW1hcnkgdG9vbHRpcHMiOiIoQmV0YSkgQUkgZ2VuZXJhdGVkIHN1bW1hcnkgYmFzZWQgb24gdGhlIHtyZXZpZXdzQ291bnR9IG1vc3QgcmVjZW50IHJldmlld3MgZm9yIHRoaXMgY29tcGFueSB7cmV2aWV3c0RhdGVSYW5nZX0uIFRoZSBzdW1tYXJ5IGlzIHJlZnJlc2hlZCBlYWNoIHRpbWUgYSBuZXcgcmV2aWV3IGlzIGFkZGVkLiJ9!"),
        () => import(
          /* webpackChunkName: "zh-translations" */
          "./translations.json-zh-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJXaGF0IGRvIHBlb3BsZSBzYXkgLi4uIjoiV2hhdCBkbyBwZW9wbGUgc2F5IGFib3V0IHdvcmtpbmcgYXQge2NvbXBhbnlOYW1lfT8iLCJBSSBzdW1tYXJ5IG9mIHJlY2VudCByZXZpZXdzIjoiQUkgc3VtbWFyeSBvZiByZWNlbnQgcmV2aWV3cyIsIkFJIHN1bW1hcnkgdG9vbHRpcHMiOiIoQmV0YSkgQUkgZ2VuZXJhdGVkIHN1bW1hcnkgYmFzZWQgb24gdGhlIHtyZXZpZXdzQ291bnR9IG1vc3QgcmVjZW50IHJldmlld3MgZm9yIHRoaXMgY29tcGFueSB7cmV2aWV3c0RhdGVSYW5nZX0uIFRoZSBzdW1tYXJ5IGlzIHJlZnJlc2hlZCBlYWNoIHRpbWUgYSBuZXcgcmV2aWV3IGlzIGFkZGVkLiJ9!"
        )
      ),
"en-AU": createLanguage(
        require.resolveWeak("./translations.json-en-AU-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJXaGF0IGRvIHBlb3BsZSBzYXkgLi4uIjoiV2hhdCBkbyBwZW9wbGUgc2F5IGFib3V0IHdvcmtpbmcgYXQge2NvbXBhbnlOYW1lfT8iLCJBSSBzdW1tYXJ5IG9mIHJlY2VudCByZXZpZXdzIjoiQUkgc3VtbWFyeSBvZiByZWNlbnQgcmV2aWV3cyIsIkFJIHN1bW1hcnkgdG9vbHRpcHMiOiIoQmV0YSkgQUkgZ2VuZXJhdGVkIHN1bW1hcnkgYmFzZWQgb24gdGhlIHtyZXZpZXdzQ291bnR9IG1vc3QgcmVjZW50IHJldmlld3MgZm9yIHRoaXMgY29tcGFueSB7cmV2aWV3c0RhdGVSYW5nZX0uIFRoZSBzdW1tYXJ5IGlzIHJlZnJlc2hlZCBlYWNoIHRpbWUgYSBuZXcgcmV2aWV3IGlzIGFkZGVkLiJ9!"),
        () => import(
          /* webpackChunkName: "en-AU-translations" */
          "./translations.json-en-AU-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJXaGF0IGRvIHBlb3BsZSBzYXkgLi4uIjoiV2hhdCBkbyBwZW9wbGUgc2F5IGFib3V0IHdvcmtpbmcgYXQge2NvbXBhbnlOYW1lfT8iLCJBSSBzdW1tYXJ5IG9mIHJlY2VudCByZXZpZXdzIjoiQUkgc3VtbWFyeSBvZiByZWNlbnQgcmV2aWV3cyIsIkFJIHN1bW1hcnkgdG9vbHRpcHMiOiIoQmV0YSkgQUkgZ2VuZXJhdGVkIHN1bW1hcnkgYmFzZWQgb24gdGhlIHtyZXZpZXdzQ291bnR9IG1vc3QgcmVjZW50IHJldmlld3MgZm9yIHRoaXMgY29tcGFueSB7cmV2aWV3c0RhdGVSYW5nZX0uIFRoZSBzdW1tYXJ5IGlzIHJlZnJlc2hlZCBlYWNoIHRpbWUgYSBuZXcgcmV2aWV3IGlzIGFkZGVkLiJ9!"
        )
      ),
"en-HK": createLanguage(
        require.resolveWeak("./translations.json-en-HK-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJXaGF0IGRvIHBlb3BsZSBzYXkgLi4uIjoiV2hhdCBkbyBwZW9wbGUgc2F5IGFib3V0IHdvcmtpbmcgYXQge2NvbXBhbnlOYW1lfT8iLCJBSSBzdW1tYXJ5IG9mIHJlY2VudCByZXZpZXdzIjoiQUkgc3VtbWFyeSBvZiByZWNlbnQgcmV2aWV3cyIsIkFJIHN1bW1hcnkgdG9vbHRpcHMiOiIoQmV0YSkgQUkgZ2VuZXJhdGVkIHN1bW1hcnkgYmFzZWQgb24gdGhlIHtyZXZpZXdzQ291bnR9IG1vc3QgcmVjZW50IHJldmlld3MgZm9yIHRoaXMgY29tcGFueSB7cmV2aWV3c0RhdGVSYW5nZX0uIFRoZSBzdW1tYXJ5IGlzIHJlZnJlc2hlZCBlYWNoIHRpbWUgYSBuZXcgcmV2aWV3IGlzIGFkZGVkLiJ9!"),
        () => import(
          /* webpackChunkName: "en-HK-translations" */
          "./translations.json-en-HK-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJXaGF0IGRvIHBlb3BsZSBzYXkgLi4uIjoiV2hhdCBkbyBwZW9wbGUgc2F5IGFib3V0IHdvcmtpbmcgYXQge2NvbXBhbnlOYW1lfT8iLCJBSSBzdW1tYXJ5IG9mIHJlY2VudCByZXZpZXdzIjoiQUkgc3VtbWFyeSBvZiByZWNlbnQgcmV2aWV3cyIsIkFJIHN1bW1hcnkgdG9vbHRpcHMiOiIoQmV0YSkgQUkgZ2VuZXJhdGVkIHN1bW1hcnkgYmFzZWQgb24gdGhlIHtyZXZpZXdzQ291bnR9IG1vc3QgcmVjZW50IHJldmlld3MgZm9yIHRoaXMgY29tcGFueSB7cmV2aWV3c0RhdGVSYW5nZX0uIFRoZSBzdW1tYXJ5IGlzIHJlZnJlc2hlZCBlYWNoIHRpbWUgYSBuZXcgcmV2aWV3IGlzIGFkZGVkLiJ9!"
        )
      ),
"en-ID": createLanguage(
        require.resolveWeak("./translations.json-en-ID-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJXaGF0IGRvIHBlb3BsZSBzYXkgLi4uIjoiV2hhdCBkbyBwZW9wbGUgc2F5IGFib3V0IHdvcmtpbmcgYXQge2NvbXBhbnlOYW1lfT8iLCJBSSBzdW1tYXJ5IG9mIHJlY2VudCByZXZpZXdzIjoiQUkgc3VtbWFyeSBvZiByZWNlbnQgcmV2aWV3cyIsIkFJIHN1bW1hcnkgdG9vbHRpcHMiOiIoQmV0YSkgQUkgZ2VuZXJhdGVkIHN1bW1hcnkgYmFzZWQgb24gdGhlIHtyZXZpZXdzQ291bnR9IG1vc3QgcmVjZW50IHJldmlld3MgZm9yIHRoaXMgY29tcGFueSB7cmV2aWV3c0RhdGVSYW5nZX0uIFRoZSBzdW1tYXJ5IGlzIHJlZnJlc2hlZCBlYWNoIHRpbWUgYSBuZXcgcmV2aWV3IGlzIGFkZGVkLiJ9!"),
        () => import(
          /* webpackChunkName: "en-ID-translations" */
          "./translations.json-en-ID-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJXaGF0IGRvIHBlb3BsZSBzYXkgLi4uIjoiV2hhdCBkbyBwZW9wbGUgc2F5IGFib3V0IHdvcmtpbmcgYXQge2NvbXBhbnlOYW1lfT8iLCJBSSBzdW1tYXJ5IG9mIHJlY2VudCByZXZpZXdzIjoiQUkgc3VtbWFyeSBvZiByZWNlbnQgcmV2aWV3cyIsIkFJIHN1bW1hcnkgdG9vbHRpcHMiOiIoQmV0YSkgQUkgZ2VuZXJhdGVkIHN1bW1hcnkgYmFzZWQgb24gdGhlIHtyZXZpZXdzQ291bnR9IG1vc3QgcmVjZW50IHJldmlld3MgZm9yIHRoaXMgY29tcGFueSB7cmV2aWV3c0RhdGVSYW5nZX0uIFRoZSBzdW1tYXJ5IGlzIHJlZnJlc2hlZCBlYWNoIHRpbWUgYSBuZXcgcmV2aWV3IGlzIGFkZGVkLiJ9!"
        )
      ),
"en-MY": createLanguage(
        require.resolveWeak("./translations.json-en-MY-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJXaGF0IGRvIHBlb3BsZSBzYXkgLi4uIjoiV2hhdCBkbyBwZW9wbGUgc2F5IGFib3V0IHdvcmtpbmcgYXQge2NvbXBhbnlOYW1lfT8iLCJBSSBzdW1tYXJ5IG9mIHJlY2VudCByZXZpZXdzIjoiQUkgc3VtbWFyeSBvZiByZWNlbnQgcmV2aWV3cyIsIkFJIHN1bW1hcnkgdG9vbHRpcHMiOiIoQmV0YSkgQUkgZ2VuZXJhdGVkIHN1bW1hcnkgYmFzZWQgb24gdGhlIHtyZXZpZXdzQ291bnR9IG1vc3QgcmVjZW50IHJldmlld3MgZm9yIHRoaXMgY29tcGFueSB7cmV2aWV3c0RhdGVSYW5nZX0uIFRoZSBzdW1tYXJ5IGlzIHJlZnJlc2hlZCBlYWNoIHRpbWUgYSBuZXcgcmV2aWV3IGlzIGFkZGVkLiJ9!"),
        () => import(
          /* webpackChunkName: "en-MY-translations" */
          "./translations.json-en-MY-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJXaGF0IGRvIHBlb3BsZSBzYXkgLi4uIjoiV2hhdCBkbyBwZW9wbGUgc2F5IGFib3V0IHdvcmtpbmcgYXQge2NvbXBhbnlOYW1lfT8iLCJBSSBzdW1tYXJ5IG9mIHJlY2VudCByZXZpZXdzIjoiQUkgc3VtbWFyeSBvZiByZWNlbnQgcmV2aWV3cyIsIkFJIHN1bW1hcnkgdG9vbHRpcHMiOiIoQmV0YSkgQUkgZ2VuZXJhdGVkIHN1bW1hcnkgYmFzZWQgb24gdGhlIHtyZXZpZXdzQ291bnR9IG1vc3QgcmVjZW50IHJldmlld3MgZm9yIHRoaXMgY29tcGFueSB7cmV2aWV3c0RhdGVSYW5nZX0uIFRoZSBzdW1tYXJ5IGlzIHJlZnJlc2hlZCBlYWNoIHRpbWUgYSBuZXcgcmV2aWV3IGlzIGFkZGVkLiJ9!"
        )
      ),
"en-NZ": createLanguage(
        require.resolveWeak("./translations.json-en-NZ-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJXaGF0IGRvIHBlb3BsZSBzYXkgLi4uIjoiV2hhdCBkbyBwZW9wbGUgc2F5IGFib3V0IHdvcmtpbmcgYXQge2NvbXBhbnlOYW1lfT8iLCJBSSBzdW1tYXJ5IG9mIHJlY2VudCByZXZpZXdzIjoiQUkgc3VtbWFyeSBvZiByZWNlbnQgcmV2aWV3cyIsIkFJIHN1bW1hcnkgdG9vbHRpcHMiOiIoQmV0YSkgQUkgZ2VuZXJhdGVkIHN1bW1hcnkgYmFzZWQgb24gdGhlIHtyZXZpZXdzQ291bnR9IG1vc3QgcmVjZW50IHJldmlld3MgZm9yIHRoaXMgY29tcGFueSB7cmV2aWV3c0RhdGVSYW5nZX0uIFRoZSBzdW1tYXJ5IGlzIHJlZnJlc2hlZCBlYWNoIHRpbWUgYSBuZXcgcmV2aWV3IGlzIGFkZGVkLiJ9!"),
        () => import(
          /* webpackChunkName: "en-NZ-translations" */
          "./translations.json-en-NZ-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJXaGF0IGRvIHBlb3BsZSBzYXkgLi4uIjoiV2hhdCBkbyBwZW9wbGUgc2F5IGFib3V0IHdvcmtpbmcgYXQge2NvbXBhbnlOYW1lfT8iLCJBSSBzdW1tYXJ5IG9mIHJlY2VudCByZXZpZXdzIjoiQUkgc3VtbWFyeSBvZiByZWNlbnQgcmV2aWV3cyIsIkFJIHN1bW1hcnkgdG9vbHRpcHMiOiIoQmV0YSkgQUkgZ2VuZXJhdGVkIHN1bW1hcnkgYmFzZWQgb24gdGhlIHtyZXZpZXdzQ291bnR9IG1vc3QgcmVjZW50IHJldmlld3MgZm9yIHRoaXMgY29tcGFueSB7cmV2aWV3c0RhdGVSYW5nZX0uIFRoZSBzdW1tYXJ5IGlzIHJlZnJlc2hlZCBlYWNoIHRpbWUgYSBuZXcgcmV2aWV3IGlzIGFkZGVkLiJ9!"
        )
      ),
"en-PH": createLanguage(
        require.resolveWeak("./translations.json-en-PH-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJXaGF0IGRvIHBlb3BsZSBzYXkgLi4uIjoiV2hhdCBkbyBwZW9wbGUgc2F5IGFib3V0IHdvcmtpbmcgYXQge2NvbXBhbnlOYW1lfT8iLCJBSSBzdW1tYXJ5IG9mIHJlY2VudCByZXZpZXdzIjoiQUkgc3VtbWFyeSBvZiByZWNlbnQgcmV2aWV3cyIsIkFJIHN1bW1hcnkgdG9vbHRpcHMiOiIoQmV0YSkgQUkgZ2VuZXJhdGVkIHN1bW1hcnkgYmFzZWQgb24gdGhlIHtyZXZpZXdzQ291bnR9IG1vc3QgcmVjZW50IHJldmlld3MgZm9yIHRoaXMgY29tcGFueSB7cmV2aWV3c0RhdGVSYW5nZX0uIFRoZSBzdW1tYXJ5IGlzIHJlZnJlc2hlZCBlYWNoIHRpbWUgYSBuZXcgcmV2aWV3IGlzIGFkZGVkLiJ9!"),
        () => import(
          /* webpackChunkName: "en-PH-translations" */
          "./translations.json-en-PH-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJXaGF0IGRvIHBlb3BsZSBzYXkgLi4uIjoiV2hhdCBkbyBwZW9wbGUgc2F5IGFib3V0IHdvcmtpbmcgYXQge2NvbXBhbnlOYW1lfT8iLCJBSSBzdW1tYXJ5IG9mIHJlY2VudCByZXZpZXdzIjoiQUkgc3VtbWFyeSBvZiByZWNlbnQgcmV2aWV3cyIsIkFJIHN1bW1hcnkgdG9vbHRpcHMiOiIoQmV0YSkgQUkgZ2VuZXJhdGVkIHN1bW1hcnkgYmFzZWQgb24gdGhlIHtyZXZpZXdzQ291bnR9IG1vc3QgcmVjZW50IHJldmlld3MgZm9yIHRoaXMgY29tcGFueSB7cmV2aWV3c0RhdGVSYW5nZX0uIFRoZSBzdW1tYXJ5IGlzIHJlZnJlc2hlZCBlYWNoIHRpbWUgYSBuZXcgcmV2aWV3IGlzIGFkZGVkLiJ9!"
        )
      ),
"en-SG": createLanguage(
        require.resolveWeak("./translations.json-en-SG-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJXaGF0IGRvIHBlb3BsZSBzYXkgLi4uIjoiV2hhdCBkbyBwZW9wbGUgc2F5IGFib3V0IHdvcmtpbmcgYXQge2NvbXBhbnlOYW1lfT8iLCJBSSBzdW1tYXJ5IG9mIHJlY2VudCByZXZpZXdzIjoiQUkgc3VtbWFyeSBvZiByZWNlbnQgcmV2aWV3cyIsIkFJIHN1bW1hcnkgdG9vbHRpcHMiOiIoQmV0YSkgQUkgZ2VuZXJhdGVkIHN1bW1hcnkgYmFzZWQgb24gdGhlIHtyZXZpZXdzQ291bnR9IG1vc3QgcmVjZW50IHJldmlld3MgZm9yIHRoaXMgY29tcGFueSB7cmV2aWV3c0RhdGVSYW5nZX0uIFRoZSBzdW1tYXJ5IGlzIHJlZnJlc2hlZCBlYWNoIHRpbWUgYSBuZXcgcmV2aWV3IGlzIGFkZGVkLiJ9!"),
        () => import(
          /* webpackChunkName: "en-SG-translations" */
          "./translations.json-en-SG-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJXaGF0IGRvIHBlb3BsZSBzYXkgLi4uIjoiV2hhdCBkbyBwZW9wbGUgc2F5IGFib3V0IHdvcmtpbmcgYXQge2NvbXBhbnlOYW1lfT8iLCJBSSBzdW1tYXJ5IG9mIHJlY2VudCByZXZpZXdzIjoiQUkgc3VtbWFyeSBvZiByZWNlbnQgcmV2aWV3cyIsIkFJIHN1bW1hcnkgdG9vbHRpcHMiOiIoQmV0YSkgQUkgZ2VuZXJhdGVkIHN1bW1hcnkgYmFzZWQgb24gdGhlIHtyZXZpZXdzQ291bnR9IG1vc3QgcmVjZW50IHJldmlld3MgZm9yIHRoaXMgY29tcGFueSB7cmV2aWV3c0RhdGVSYW5nZX0uIFRoZSBzdW1tYXJ5IGlzIHJlZnJlc2hlZCBlYWNoIHRpbWUgYSBuZXcgcmV2aWV3IGlzIGFkZGVkLiJ9!"
        )
      ),
"en-TH": createLanguage(
        require.resolveWeak("./translations.json-en-TH-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJXaGF0IGRvIHBlb3BsZSBzYXkgLi4uIjoiV2hhdCBkbyBwZW9wbGUgc2F5IGFib3V0IHdvcmtpbmcgYXQge2NvbXBhbnlOYW1lfT8iLCJBSSBzdW1tYXJ5IG9mIHJlY2VudCByZXZpZXdzIjoiQUkgc3VtbWFyeSBvZiByZWNlbnQgcmV2aWV3cyIsIkFJIHN1bW1hcnkgdG9vbHRpcHMiOiIoQmV0YSkgQUkgZ2VuZXJhdGVkIHN1bW1hcnkgYmFzZWQgb24gdGhlIHtyZXZpZXdzQ291bnR9IG1vc3QgcmVjZW50IHJldmlld3MgZm9yIHRoaXMgY29tcGFueSB7cmV2aWV3c0RhdGVSYW5nZX0uIFRoZSBzdW1tYXJ5IGlzIHJlZnJlc2hlZCBlYWNoIHRpbWUgYSBuZXcgcmV2aWV3IGlzIGFkZGVkLiJ9!"),
        () => import(
          /* webpackChunkName: "en-TH-translations" */
          "./translations.json-en-TH-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJXaGF0IGRvIHBlb3BsZSBzYXkgLi4uIjoiV2hhdCBkbyBwZW9wbGUgc2F5IGFib3V0IHdvcmtpbmcgYXQge2NvbXBhbnlOYW1lfT8iLCJBSSBzdW1tYXJ5IG9mIHJlY2VudCByZXZpZXdzIjoiQUkgc3VtbWFyeSBvZiByZWNlbnQgcmV2aWV3cyIsIkFJIHN1bW1hcnkgdG9vbHRpcHMiOiIoQmV0YSkgQUkgZ2VuZXJhdGVkIHN1bW1hcnkgYmFzZWQgb24gdGhlIHtyZXZpZXdzQ291bnR9IG1vc3QgcmVjZW50IHJldmlld3MgZm9yIHRoaXMgY29tcGFueSB7cmV2aWV3c0RhdGVSYW5nZX0uIFRoZSBzdW1tYXJ5IGlzIHJlZnJlc2hlZCBlYWNoIHRpbWUgYSBuZXcgcmV2aWV3IGlzIGFkZGVkLiJ9!"
        )
      ),
"th-TH": createLanguage(
        require.resolveWeak("./translations.json-th-TH-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJXaGF0IGRvIHBlb3BsZSBzYXkgLi4uIjoi4Lic4Li54LmJ4LiE4LiZ4Lie4Li54LiU4LiW4Li24LiH4LiB4Liy4Lij4LiX4Liz4LiH4Liy4LiZ4LiX4Li14LmIIHtjb21wYW55TmFtZX0g4Lit4Lii4LmI4Liy4LiH4LmE4Lij4Lia4LmJ4Liy4LiHPyIsIkFJIHN1bW1hcnkgb2YgcmVjZW50IHJldmlld3MiOiLguJrguJfguKrguKPguLjguJvguIjguLLguIHguKPguLXguKfguLTguKfguYPguJnguIrguYjguKfguIfguKXguYjguLLguKrguLjguJTguYLguJTguKIgQUkiLCJBSSBzdW1tYXJ5IHRvb2x0aXBzIjoiKOC5gOC4p%2BC4reC4o%2BC5jOC4iuC4seC4meC4l%2BC4lOC4peC4reC4hykg4Liq4Lij4Li44Lib4LiB4Liy4Lij4Lij4Li14Lin4Li04Lin4LmC4LiU4LiiIEFJIOC4i%2BC4tuC5iOC4h%2BC4reC4tOC4h%2BC4iOC4suC4geC4geC4suC4o%2BC4o%2BC4teC4p%2BC4tOC4pyB7cmV2aWV3c0NvdW50fSDguITguKPguLHguYnguIfguKXguYjguLLguKrguLjguJTguYDguIHguLXguYjguKLguKfguIHguLHguJrguJrguKPguLTguKnguLHguJfguJnguLXguYkge3Jldmlld3NEYXRlUmFuZ2V9IOC5guC4lOC4ouC5gOC4oeC4t%2BC5iOC4reC4oeC4teC4geC4suC4o%2BC4o%2BC4teC4p%2BC4tOC4p%2BC5g%2BC4q%2BC4oeC5iOC5gOC4nuC4tOC5iOC4oeC5gOC4guC5ieC4suC4oeC4siDguIjguLDguKHguLXguIHguLLguKPguK3guLHguJvguYDguJTguJXguKrguKPguLjguJvguYPguKvguKHguYjguYDguKrguKHguK0ifQ%3D%3D!"),
        () => import(
          /* webpackChunkName: "th-TH-translations" */
          "./translations.json-th-TH-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJXaGF0IGRvIHBlb3BsZSBzYXkgLi4uIjoi4Lic4Li54LmJ4LiE4LiZ4Lie4Li54LiU4LiW4Li24LiH4LiB4Liy4Lij4LiX4Liz4LiH4Liy4LiZ4LiX4Li14LmIIHtjb21wYW55TmFtZX0g4Lit4Lii4LmI4Liy4LiH4LmE4Lij4Lia4LmJ4Liy4LiHPyIsIkFJIHN1bW1hcnkgb2YgcmVjZW50IHJldmlld3MiOiLguJrguJfguKrguKPguLjguJvguIjguLLguIHguKPguLXguKfguLTguKfguYPguJnguIrguYjguKfguIfguKXguYjguLLguKrguLjguJTguYLguJTguKIgQUkiLCJBSSBzdW1tYXJ5IHRvb2x0aXBzIjoiKOC5gOC4p%2BC4reC4o%2BC5jOC4iuC4seC4meC4l%2BC4lOC4peC4reC4hykg4Liq4Lij4Li44Lib4LiB4Liy4Lij4Lij4Li14Lin4Li04Lin4LmC4LiU4LiiIEFJIOC4i%2BC4tuC5iOC4h%2BC4reC4tOC4h%2BC4iOC4suC4geC4geC4suC4o%2BC4o%2BC4teC4p%2BC4tOC4pyB7cmV2aWV3c0NvdW50fSDguITguKPguLHguYnguIfguKXguYjguLLguKrguLjguJTguYDguIHguLXguYjguKLguKfguIHguLHguJrguJrguKPguLTguKnguLHguJfguJnguLXguYkge3Jldmlld3NEYXRlUmFuZ2V9IOC5guC4lOC4ouC5gOC4oeC4t%2BC5iOC4reC4oeC4teC4geC4suC4o%2BC4o%2BC4teC4p%2BC4tOC4p%2BC5g%2BC4q%2BC4oeC5iOC5gOC4nuC4tOC5iOC4oeC5gOC4guC5ieC4suC4oeC4siDguIjguLDguKHguLXguIHguLLguKPguK3guLHguJvguYDguJTguJXguKrguKPguLjguJvguYPguKvguKHguYjguYDguKrguKHguK0ifQ%3D%3D!"
        )
      ),
"id-ID": createLanguage(
        require.resolveWeak("./translations.json-id-ID-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJXaGF0IGRvIHBlb3BsZSBzYXkgLi4uIjoiQXBhIGthdGEgbWVyZWthIHRlbnRhbmcgYmVrZXJqYSBkaSB7Y29tcGFueU5hbWV9PyIsIkFJIHN1bW1hcnkgb2YgcmVjZW50IHJldmlld3MiOiJSaW5na2FzYW4gdWxhc2FuIHRlcmJhcnUgZGFyaSBBSSIsIkFJIHN1bW1hcnkgdG9vbHRpcHMiOiIoQmV0YSkgUmluZ2thc2FuIGluaSBkaWJ1YXQgb2xlaCBBSSBiZXJkYXNhcmthbiB7cmV2aWV3c0NvdW50fSB1bGFzYW4gdGVyYmFydSBwZXJ1c2FoYWFuIHtyZXZpZXdzRGF0ZVJhbmdlfS4gU2VsYWx1IGRpcGVyYmFydWkgc2V0aWFwIGFkYSB1bGFzYW4gYmFydS4ifQ%3D%3D!"),
        () => import(
          /* webpackChunkName: "id-ID-translations" */
          "./translations.json-id-ID-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJXaGF0IGRvIHBlb3BsZSBzYXkgLi4uIjoiQXBhIGthdGEgbWVyZWthIHRlbnRhbmcgYmVrZXJqYSBkaSB7Y29tcGFueU5hbWV9PyIsIkFJIHN1bW1hcnkgb2YgcmVjZW50IHJldmlld3MiOiJSaW5na2FzYW4gdWxhc2FuIHRlcmJhcnUgZGFyaSBBSSIsIkFJIHN1bW1hcnkgdG9vbHRpcHMiOiIoQmV0YSkgUmluZ2thc2FuIGluaSBkaWJ1YXQgb2xlaCBBSSBiZXJkYXNhcmthbiB7cmV2aWV3c0NvdW50fSB1bGFzYW4gdGVyYmFydSBwZXJ1c2FoYWFuIHtyZXZpZXdzRGF0ZVJhbmdlfS4gU2VsYWx1IGRpcGVyYmFydWkgc2V0aWFwIGFkYSB1bGFzYW4gYmFydS4ifQ%3D%3D!"
        )
      ),
"zh-HK": createLanguage(
        require.resolveWeak("./translations.json-zh-HK-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJXaGF0IGRvIHBlb3BsZSBzYXkgLi4uIjoiV2hhdCBkbyBwZW9wbGUgc2F5IGFib3V0IHdvcmtpbmcgYXQge2NvbXBhbnlOYW1lfT8iLCJBSSBzdW1tYXJ5IG9mIHJlY2VudCByZXZpZXdzIjoiQUkgc3VtbWFyeSBvZiByZWNlbnQgcmV2aWV3cyIsIkFJIHN1bW1hcnkgdG9vbHRpcHMiOiIoQmV0YSkgQUkgZ2VuZXJhdGVkIHN1bW1hcnkgYmFzZWQgb24gdGhlIHtyZXZpZXdzQ291bnR9IG1vc3QgcmVjZW50IHJldmlld3MgZm9yIHRoaXMgY29tcGFueSB7cmV2aWV3c0RhdGVSYW5nZX0uIFRoZSBzdW1tYXJ5IGlzIHJlZnJlc2hlZCBlYWNoIHRpbWUgYSBuZXcgcmV2aWV3IGlzIGFkZGVkLiJ9!"),
        () => import(
          /* webpackChunkName: "zh-HK-translations" */
          "./translations.json-zh-HK-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJXaGF0IGRvIHBlb3BsZSBzYXkgLi4uIjoiV2hhdCBkbyBwZW9wbGUgc2F5IGFib3V0IHdvcmtpbmcgYXQge2NvbXBhbnlOYW1lfT8iLCJBSSBzdW1tYXJ5IG9mIHJlY2VudCByZXZpZXdzIjoiQUkgc3VtbWFyeSBvZiByZWNlbnQgcmV2aWV3cyIsIkFJIHN1bW1hcnkgdG9vbHRpcHMiOiIoQmV0YSkgQUkgZ2VuZXJhdGVkIHN1bW1hcnkgYmFzZWQgb24gdGhlIHtyZXZpZXdzQ291bnR9IG1vc3QgcmVjZW50IHJldmlld3MgZm9yIHRoaXMgY29tcGFueSB7cmV2aWV3c0RhdGVSYW5nZX0uIFRoZSBzdW1tYXJ5IGlzIHJlZnJlc2hlZCBlYWNoIHRpbWUgYSBuZXcgcmV2aWV3IGlzIGFkZGVkLiJ9!"
        )
      ),
"en-PSEUDO": createLanguage(
        require.resolveWeak("./translations.json-en-PSEUDO-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJXaGF0IGRvIHBlb3BsZSBzYXkgLi4uIjoiW8W04bipxIPEg8SD4bmvIMaMw7bDtsO2IMal4bq94bq94bq9w7bDtsO2xqXGmuG6veG6veG6vSDFocSDxIPEg8O9w73DvSDEg8SDxIPDn8O2w7bDtseax5rHmuG5ryDFtcO2w7bDtsWZxLfDrMOsw6zguIHguLXguYnEoyDEg8SDxIPhua8ge2NvbXBhbnlOYW1lfT9dIiwiQUkgc3VtbWFyeSBvZiByZWNlbnQgcmV2aWV3cyI6IlvhuqzDjyDFoceax5rHmm3Mgm3MgsSDxIPEg8WZw73DvcO9IMO2w7bDtsaSIMWZ4bq94bq94bq9w6fhur3hur3hur3guIHguLXguYnhua8gxZnhur3hur3hur3hub3DrMOsw6zhur3hur3hur3FtcWhXSIsIkFJIHN1bW1hcnkgdG9vbHRpcHMiOiJbKOG6nuG6veG6veG6veG5r8SDxIPEgykg4bqsw48gxKPhur3hur3hur3guIHguLXguYnhur3hur3hur3FmcSDxIPEg%2BG5r%2BG6veG6veG6vcaMIMWhx5rHmseabcyCbcyCxIPEg8SDxZnDvcO9w70gw5%2FEg8SDxIPFoeG6veG6veG6vcaMIMO2w7bDtuC4geC4teC5iSDhua%2FhuKnhur3hur3hur0ge3Jldmlld3NDb3VudH0gbcyCw7bDtsO2xaHhua8gxZnhur3hur3hur3Dp%2BG6veG6veG6veC4geC4teC5ieG5ryDFmeG6veG6veG6veG5vcOsw6zDrOG6veG6veG6vcW1xaEgxpLDtsO2w7bFmSDhua%2FhuKnDrMOsw6zFoSDDp8O2w7bDtm3MgsalxIPEg8SD4LiB4Li14LmJw73DvcO9IHtyZXZpZXdzRGF0ZVJhbmdlfS4g4bmu4bip4bq94bq94bq9IMWhx5rHmseabcyCbcyCxIPEg8SDxZnDvcO9w70gw6zDrMOsxaEgxZnhur3hur3hur3GksWZ4bq94bq94bq9xaHhuKnhur3hur3hur3GjCDhur3hur3hur3Eg8SDxIPDp%2BG4qSDhua%2FDrMOsw6xtzILhur3hur3hur0gxIPEg8SDIOC4geC4teC5ieG6veG6veG6vcW1IMWZ4bq94bq94bq94bm9w6zDrMOs4bq94bq94bq9xbUgw6zDrMOsxaEgxIPEg8SDxozGjOG6veG6veG6vcaMLl0ifQ%3D%3D!"),
        () => import(
          /* webpackChunkName: "en-PSEUDO-translations" */
          "./translations.json-en-PSEUDO-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJXaGF0IGRvIHBlb3BsZSBzYXkgLi4uIjoiW8W04bipxIPEg8SD4bmvIMaMw7bDtsO2IMal4bq94bq94bq9w7bDtsO2xqXGmuG6veG6veG6vSDFocSDxIPEg8O9w73DvSDEg8SDxIPDn8O2w7bDtseax5rHmuG5ryDFtcO2w7bDtsWZxLfDrMOsw6zguIHguLXguYnEoyDEg8SDxIPhua8ge2NvbXBhbnlOYW1lfT9dIiwiQUkgc3VtbWFyeSBvZiByZWNlbnQgcmV2aWV3cyI6IlvhuqzDjyDFoceax5rHmm3Mgm3MgsSDxIPEg8WZw73DvcO9IMO2w7bDtsaSIMWZ4bq94bq94bq9w6fhur3hur3hur3guIHguLXguYnhua8gxZnhur3hur3hur3hub3DrMOsw6zhur3hur3hur3FtcWhXSIsIkFJIHN1bW1hcnkgdG9vbHRpcHMiOiJbKOG6nuG6veG6veG6veG5r8SDxIPEgykg4bqsw48gxKPhur3hur3hur3guIHguLXguYnhur3hur3hur3FmcSDxIPEg%2BG5r%2BG6veG6veG6vcaMIMWhx5rHmseabcyCbcyCxIPEg8SDxZnDvcO9w70gw5%2FEg8SDxIPFoeG6veG6veG6vcaMIMO2w7bDtuC4geC4teC5iSDhua%2FhuKnhur3hur3hur0ge3Jldmlld3NDb3VudH0gbcyCw7bDtsO2xaHhua8gxZnhur3hur3hur3Dp%2BG6veG6veG6veC4geC4teC5ieG5ryDFmeG6veG6veG6veG5vcOsw6zDrOG6veG6veG6vcW1xaEgxpLDtsO2w7bFmSDhua%2FhuKnDrMOsw6zFoSDDp8O2w7bDtm3MgsalxIPEg8SD4LiB4Li14LmJw73DvcO9IHtyZXZpZXdzRGF0ZVJhbmdlfS4g4bmu4bip4bq94bq94bq9IMWhx5rHmseabcyCbcyCxIPEg8SDxZnDvcO9w70gw6zDrMOsxaEgxZnhur3hur3hur3GksWZ4bq94bq94bq9xaHhuKnhur3hur3hur3GjCDhur3hur3hur3Eg8SDxIPDp%2BG4qSDhua%2FDrMOsw6xtzILhur3hur3hur0gxIPEg8SDIOC4geC4teC5ieG6veG6veG6vcW1IMWZ4bq94bq94bq94bm9w6zDrMOs4bq94bq94bq9xbUgw6zDrMOsxaEgxIPEg8SDxozGjOG6veG6veG6vcaMLl0ifQ%3D%3D!"
        )
      )
      });
  
      export { translations as default };
    