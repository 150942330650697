import { useConfig, useValidation, type Rules } from '@seek/libs-shared';
import { useTranslations } from '@vocab/react';
import { Stack } from 'braid-design-system';
import { useEffect } from 'react';
import type { LocationSuggestion } from '../../shared/services/locationService/locationService';
import type {
  ConfirmedRoles,
  RoleTitle,
} from '../../shared/services/roleService/roleService';
import {
  trackClickYourRolePageContinue,
  trackClickYourRoleSubSection,
  trackDisplayYourRolePage,
  trackValidationErrorYourRolePage,
} from '../../shared/tracking/writeAReview/trackingEvents';
import { ReviewPageLayout } from '../components/ReviewPageLayout/ReviewPageLayout';
import {
  JobTitleQuestion,
  type JobTitleValue,
} from '../questions/AutoSuggestionQuestions/JobTitleQuestions/JobTitleQuestion';
import { LocationQuestion } from '../questions/AutoSuggestionQuestions/LocationQuestion/LocationQuestion';
import { FinishQuestion } from '../questions/GroupedQuestions/WorkHistoryQuestion/FinishQuestion';
import { StartQuestion } from '../questions/GroupedQuestions/WorkHistoryQuestion/StartQuestion';
import { WorkTypeQuestion } from '../questions/SimpleQuestions/WorkTypeQuestion';
import type { MonthPickerValue, PageProps, ReviewPayload } from '../type';
import { mapErrorMessage } from '../utils/mapErrorMessage';
import { validationRules } from '../validation/validationRules';
import { testWorkHistoryRequired } from '../validation/validator/workHistoryRequired';
import translations from './.vocab';

export interface YourRolePageProps extends PageProps {
  getRoleTitles: (query: string) => Promise<RoleTitle[]>;
  onUpdateRole: (role: ConfirmedRoles) => void;
  searchLocations: (query: string) => Promise<LocationSuggestion[]>;
}

export const YourRolePage = ({
  review,
  reviewFormContext,
  setPage,
  setter,
  getRoleTitles,
  searchLocations,
  onUpdateRole,
  pageNumber,
}: YourRolePageProps) => {
  const { t } = useTranslations(translations);
  const config = useConfig();
  const { language, zone } = config;

  const prefilled = Boolean(review.isWorkHistoryPrefilled);

  useEffect(() => {
    trackDisplayYourRolePage({
      config,
      context: reviewFormContext,
      prefilled,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fields = {
    workType: review?.workType,
    jobTitleText: review?.jobTitleText,
    fullLocation: review?.fullLocation,
    workHistoryFrom: review?.workHistoryFrom,
    workHistoryTo: review?.workHistoryTo,
    workHistory: {
      from: review?.workHistoryFrom,
      to: review?.workHistoryTo,
    },
  };

  const {
    workType,
    jobTitleText,
    fullLocation,
    workHistoryFrom,
    workHistoryTo,
    workHistory,
  } = validationRules(language);

  const rules = {
    workType,
    jobTitleText,
    fullLocation,
    workHistoryFrom,
    ...(!review.isStillWorkHere
      ? {
          workHistoryTo,
          workHistory,
        }
      : {}),
  } as Rules<typeof fields>;

  const {
    validate,
    validateWithErrors,
    getTone,
    getMessage,
    handleValueChangeWithValidation,
  } = useValidation<ReviewPayload, 'workHistory'>(fields, rules);

  const handleOnContinue = () => {
    const { isValid, errors } = validateWithErrors();

    trackClickYourRolePageContinue({
      config,
      context: reviewFormContext,
      prefilled,
    });

    if (!isValid) {
      trackValidationErrorYourRolePage({
        config,
        context: reviewFormContext,
        errorMessage: errors.map((error) => mapErrorMessage(error.fieldName)),
        errorText: errors.map((error) => error.message),
        prefilled,
      });

      return;
    }
    onUpdateRole({
      id: review.roleId,
      ...(review.companyName
        ? {
            company: {
              text: review.companyName,
            },
          }
        : {}),
      title: {
        text: review.jobTitleText ?? '',
      },
      from: review.workHistoryFrom as MonthPickerValue,
      to: review.workHistoryTo as MonthPickerValue,
    });
    setPage(pageNumber + 1);
  };

  const handleOnBack = () => {
    setPage(pageNumber - 1);
  };

  const handleJobTitleQuestionChange = (value: JobTitleValue) => {
    handleValueChangeWithValidation({
      setter,
      validatorProperty: 'jobTitleText',
      previousValue: review,
    })(value.text);

    setter((prev) => ({
      ...prev,
      jobTitleId: value.id,
    }));
  };

  const handleLocationChange = (value: LocationSuggestion) => {
    handleValueChangeWithValidation({
      setter,
      validatorProperty: 'fullLocation',
      previousValue: review,
    })(value.text);

    setter((prev) => ({
      ...prev,
      locationId: value.id,
    }));
  };

  const handleWorkTypeChange = (value: string) => {
    handleValueChangeWithValidation({
      setter,
      validatorProperty: 'workType',
      previousValue: review,
    })(value);

    trackClickYourRoleSubSection({
      currentPageSubsection: 'work_type',
      config,
      context: reviewFormContext,
      prefilled,
    });
  };

  const handleStartQuestionChange = (value: MonthPickerValue) => {
    setter({
      ...review,
      workHistoryFrom: value,
      workHistory: {
        ...review.workHistory,
        from: value,
      },
    });

    const shouldTestWorkHistory =
      testWorkHistoryRequired(value) &&
      review?.workHistoryTo &&
      testWorkHistoryRequired(review?.workHistoryTo);

    if (!review.isStillWorkHere) {
      validate({
        workHistoryFrom: value,
        ...(shouldTestWorkHistory
          ? {
              workHistory: {
                from: value,
                to: review?.workHistoryTo,
              },
            }
          : {}),
      });
    }

    trackClickYourRoleSubSection({
      currentPageSubsection: 'start_date',
      config,
      context: reviewFormContext,
      prefilled,
    });
  };

  const handleFinishQuestionChange = (value: MonthPickerValue) => {
    setter({
      ...review,
      workHistoryTo: value,
      workHistory: {
        ...review.workHistory,
        to: value,
      },
    });

    const shouldTestWorkHistory =
      testWorkHistoryRequired(value) &&
      review?.workHistoryFrom &&
      testWorkHistoryRequired(review?.workHistoryFrom);

    if (!review.isStillWorkHere) {
      validate({
        workHistoryTo: value,
        ...(shouldTestWorkHistory
          ? {
              workHistory: {
                from: review?.workHistoryFrom,
                to: value,
              },
            }
          : {}),
      });
    }

    trackClickYourRoleSubSection({
      currentPageSubsection: 'end_date',
      config,
      context: reviewFormContext,
      prefilled,
    });
  };

  const handleIsStillWorkHereChange = () => {
    setter({ ...review, isStillWorkHere: !review.isStillWorkHere });
    trackClickYourRoleSubSection({
      currentPageSubsection: 'i_still_work_here',
      config,
      context: reviewFormContext,
      prefilled,
    });
  };

  return (
    <ReviewPageLayout
      progress={pageNumber}
      companyName={review?.companyName}
      subtitle={t(
        'This information will be shared with your review. You can make the job title more generic to protect your identity.',
      )}
      onContinue={handleOnContinue}
      onBack={handleOnBack}
      testid="your-role-page"
    >
      <Stack space="small">
        <JobTitleQuestion
          onChange={handleJobTitleQuestionChange}
          value={{ text: review?.jobTitleText, id: review?.jobTitleId }}
          tone={getTone('jobTitleText')}
          message={getMessage('jobTitleText')}
          getRoleTitles={getRoleTitles}
          onValueSelected={() => {
            trackClickYourRoleSubSection({
              currentPageSubsection: 'job_title',
              config,
              context: reviewFormContext,
              prefilled,
            });
          }}
        />
        <LocationQuestion
          onChange={handleLocationChange}
          searchLocations={searchLocations}
          value={{
            text: review?.fullLocation,
            id: review?.locationId,
          }}
          zone={zone}
          tone={getTone('fullLocation')}
          message={getMessage('fullLocation')}
          onValueSelected={() => {
            trackClickYourRoleSubSection({
              currentPageSubsection: 'location_selected',
              config,
              context: reviewFormContext,
              prefilled,
            });
          }}
        />
        <WorkTypeQuestion
          onChange={handleWorkTypeChange}
          value={review?.workType}
          tone={getTone('workType')}
          message={getMessage('workType')}
        />
        <StartQuestion
          value={review.workHistoryFrom}
          onChange={handleStartQuestionChange}
          tone={getTone('workHistoryFrom')}
          message={getMessage('workHistoryFrom')}
        />
        <FinishQuestion
          value={review.workHistoryTo}
          onChange={handleFinishQuestionChange}
          isStillWorkHere={Boolean(review.isStillWorkHere)}
          onIsStillWorkHereChange={handleIsStillWorkHereChange}
          tone={
            getTone('workHistoryTo') === 'critical'
              ? getTone('workHistoryTo')
              : getTone('workHistory')
          }
          message={getMessage('workHistoryTo') || getMessage('workHistory')}
        />
      </Stack>
    </ReviewPageLayout>
  );
};
