import { useTranslations } from '@vocab/react';
import {
  Actions,
  Box,
  ButtonLink,
  Card,
  Column,
  Columns,
  Divider,
  Heading,
  IconArrow,
  Inline,
  Stack,
  Text,
  TextLink,
} from 'braid-design-system';

import {
  AIGenSection,
  buildCommunityGuidelinesURI,
  Section,
  useConfig,
} from '@seek/libs-shared';
import type { AboutModel } from '../../../pages/about/aboutModel';
import { ReviewReply } from '../reviewRow/ReviewReply';
import { ReviewRowRatingDetails } from '../reviewRow/ReviewRowRatingDetails';
import { ReviewRowRatingSummary } from '../reviewRow/ReviewRowRatingSummary';
import { ReviewsEmptyState } from '../reviewsEmptyState/ReviewsEmptyState';
import { ReviewsSummary } from '../reviewsSummary/ReviewsSummary';
import translations from './.vocab';
import { FeedbackAISummary } from './FeedbackAISummary';

interface CompanyReviewSectionProps {
  model: AboutModel;
  onActiveTabChanged: (item: string | undefined) => void;
  shouldDisplayAiGenSection: boolean;
  trackReviewsClicked: () => void;
  trackWriteAReviewClicked: () => void;
  link?: string;
}

const REVIEWS_TO_SHOW = 3;

export const ReviewsSummarySectionWithDetails = ({
  model,
  link,
  onActiveTabChanged,
  trackReviewsClicked,
  trackWriteAReviewClicked,
  shouldDisplayAiGenSection,
}: CompanyReviewSectionProps) => {
  const { t } = useTranslations(translations);
  const { locale, zone, experience } = useConfig();
  const communityGuidelineURL = buildCommunityGuidelinesURI({
    locale,
    zone,
    experience,
  });

  // Overall rating is only exist when the minimum review count is met
  // If there is no overall rating, show the empty state
  const isOverallRatingNotExist = !model?.reviewSummary?.overallRating;

  const { reviews } = model;
  const reviewsList = reviews?.reviews || [];
  const reviewsListToShow = reviewsList.slice(0, REVIEWS_TO_SHOW);

  return (
    <Section paddingBottom="none" heading={t('Title')}>
      <Stack space="medium">
        {isOverallRatingNotExist ? (
          <Card>
            <ReviewsEmptyState
              companyName={model.name}
              wrapInCard={false}
              trackWriteAReviewClicked={trackWriteAReviewClicked}
              type="NO_SUMMARY"
            />
          </Card>
        ) : (
          <Stack space="xxsmall">
            <Card>
              <Stack space="medium">
                <ReviewsSummary
                  model={{
                    employeeRecommendationRating:
                      model.reviewSummary?.employeeRecommendationRating,
                    overallRating: model.reviewSummary?.overallRating,
                    ratingBreakdown: model.reviewSummary?.ratingBreakdown,
                    salaryRating: model.reviewSummary?.salaryRating,
                  }}
                />

                {shouldDisplayAiGenSection && (
                  <AIGenSection
                    companyName={model.name}
                    companyReviewsAISummary={model.companyReviewsAISummary}
                  />
                )}
              </Stack>
            </Card>
            {shouldDisplayAiGenSection && <FeedbackAISummary />}
          </Stack>
        )}
        {reviewsListToShow?.length > 0 && (
          <>
            <Box paddingBottom={'xxsmall'} paddingTop={'medium'}>
              <Heading level="3">{t('Secondary title')}</Heading>
            </Box>
            <Stack space="small">
              {reviewsListToShow?.map((review, index) => (
                <Box id={`review-card-${index}`}>
                  <Card>
                    <Columns space="medium" collapseBelow="tablet">
                      <Column width="1/4">
                        <ReviewRowRatingSummary
                          model={review}
                          showBreakdown={false}
                        />
                      </Column>
                      <Column width="3/4">
                        <Stack space="large">
                          <ReviewRowRatingDetails
                            model={review}
                            liteTitleMode={true}
                            isReplyReviewAccessCodeValid={false}
                          />
                          {review.reply && (
                            <>
                              <Divider />
                              <ReviewReply reply={review.reply} />
                            </>
                          )}
                        </Stack>
                      </Column>
                    </Columns>
                  </Card>
                </Box>
              ))}
            </Stack>
            <Text align="left" size="xsmall">
              {t('Community guideline description', {
                companyName: model.name,
                TextLink: (v: string) => (
                  <TextLink href={communityGuidelineURL} rel="nofollow">
                    {v}
                  </TextLink>
                ),
              })}
            </Text>
            <Actions>
              <Inline space="none">
                <ButtonLink
                  href={link || '#'}
                  onClick={() => {
                    if (trackReviewsClicked) {
                      trackReviewsClicked();
                    }
                    if (onActiveTabChanged) {
                      onActiveTabChanged('reviews');
                    }
                  }}
                  icon={<IconArrow direction="right" />}
                  iconPosition="trailing"
                >
                  {t('See all reviews')}
                </ButtonLink>
              </Inline>
            </Actions>
          </>
        )}
      </Stack>
    </Section>
  );
};
