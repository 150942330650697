import { creatRestClient, getHeaders, type Logger } from '@seek/libs-shared';

import type { AppConfig } from '../../config';

import type {
  LegacyCompanyProfile,
  LegacySearchCompaniesResult,
} from './types';

export interface LegacyCompanyProfileServiceOptions {
  companyProfilesApiBaseUrl: string;
  config: AppConfig;
  logger: Logger;
}

export const createLegacyCompanyProfileService = ({
  companyProfilesApiBaseUrl,
  config,
  logger,
}: LegacyCompanyProfileServiceOptions) => {
  const commonHeaders = getHeaders(config, false)();
  // omit 'x-real-ip' header from headers
  delete commonHeaders['x-real-ip'];

  const { get } = creatRestClient(companyProfilesApiBaseUrl, logger, {
    ...commonHeaders,

    'x-country-iso': config.country,
  });

  const getLegacyCompanyProfile = async (companyId: string) => {
    const response = await get<LegacyCompanyProfile>(
      `/companies/${companyId}/profile`,
    );

    return response?.data;
  };

  const getLegacyCompanyProfilePreview = async (companyId: string) => {
    const response = await get<LegacyCompanyProfile>(
      `/companies/${companyId}/profile/preview`,
    );

    return response?.data;
  };

  const getCompanyProfileForeignId = async (
    companyId: string,
  ): Promise<string | undefined> => {
    const response = await get<LegacyCompanyProfile>(
      `/companies/${companyId}/profile`,
    );

    return response?.data?.foreign_company_id;
  };

  const searchCompanies = async ({
    searchTerm,
  }: {
    searchTerm: string;
  }): Promise<LegacySearchCompaniesResult> => {
    const queryParam = new URLSearchParams({
      company_name: searchTerm,
      page: '1',
      per_page: '6',
      output_format: 'search',
    });

    const response = await get<LegacySearchCompaniesResult>(
      `/companies?${queryParam.toString()}`,
    );
    return (
      response?.data ?? {
        data: [],
        paging: {
          page: 1,
          per_page: 0,
          total: 0,
        },
      }
    );
  };

  return {
    getCompanyProfileForeignId,
    getLegacyCompanyProfile,
    getLegacyCompanyProfilePreview,
    searchCompanies,
  };
};
