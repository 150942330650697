
      import { createLanguage, createTranslationFile } from '@vocab/webpack/web';
      
    const translations = createTranslationFile({
      "en": createLanguage(
        require.resolveWeak("./translations.json-en-virtual.json!=!../../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJVbmRlciB7c2FsYXJ5VGV4dH0iOiJVbmRlciB7c2FsYXJ5VGV4dH0iLCJNb3JlIHRoYW4ge3NhbGFyeVRleHR9IjoiTW9yZSB0aGFuIHtzYWxhcnlUZXh0fSIsIkkgYWxzbyByZWNlaXZlIGJvbnVzZXMgb3IgY29tbWlzc2lvbiI6IkkgYWxzbyByZWNlaXZlIGJvbnVzZXMgb3IgY29tbWlzc2lvbiIsIldoYXQgaXMgeW91ciBzYWxhcnk%2FIjoiV2hhdCBpcyB5b3VyIHNhbGFyeT8iLCJVc2VkIHRvIGNhbGN1bGF0ZSBhdmVyYWdlIGFuZCB3b24ndCBiZSBpbmNsdWRlZCBpbiB5b3VyIHJldmlldyI6IlVzZWQgdG8gY2FsY3VsYXRlIGF2ZXJhZ2UgYW5kIHdvbid0IGJlIGluY2x1ZGVkIGluIHlvdXIgcmV2aWV3IiwiQ3VycmVuY3kiOiJDdXJyZW5jeSIsIkFtb3VudCI6IkFtb3VudCIsImV4Y2wuIHN1cGVyIjoiZXhjbC4gc3VwZXIifQ%3D%3D!"),
        () => import(
          /* webpackChunkName: "en-translations" */
          "./translations.json-en-virtual.json!=!../../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJVbmRlciB7c2FsYXJ5VGV4dH0iOiJVbmRlciB7c2FsYXJ5VGV4dH0iLCJNb3JlIHRoYW4ge3NhbGFyeVRleHR9IjoiTW9yZSB0aGFuIHtzYWxhcnlUZXh0fSIsIkkgYWxzbyByZWNlaXZlIGJvbnVzZXMgb3IgY29tbWlzc2lvbiI6IkkgYWxzbyByZWNlaXZlIGJvbnVzZXMgb3IgY29tbWlzc2lvbiIsIldoYXQgaXMgeW91ciBzYWxhcnk%2FIjoiV2hhdCBpcyB5b3VyIHNhbGFyeT8iLCJVc2VkIHRvIGNhbGN1bGF0ZSBhdmVyYWdlIGFuZCB3b24ndCBiZSBpbmNsdWRlZCBpbiB5b3VyIHJldmlldyI6IlVzZWQgdG8gY2FsY3VsYXRlIGF2ZXJhZ2UgYW5kIHdvbid0IGJlIGluY2x1ZGVkIGluIHlvdXIgcmV2aWV3IiwiQ3VycmVuY3kiOiJDdXJyZW5jeSIsIkFtb3VudCI6IkFtb3VudCIsImV4Y2wuIHN1cGVyIjoiZXhjbC4gc3VwZXIifQ%3D%3D!"
        )
      ),
"th": createLanguage(
        require.resolveWeak("./translations.json-th-virtual.json!=!../../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJVbmRlciB7c2FsYXJ5VGV4dH0iOiLguJXguYjguLPguIHguKfguYjguLIge3NhbGFyeVRleHR9IiwiTW9yZSB0aGFuIHtzYWxhcnlUZXh0fSI6IuC4quC4ueC4h%2BC4geC4p%2BC5iOC4siB7c2FsYXJ5VGV4dH0iLCJJIGFsc28gcmVjZWl2ZSBib251c2VzIG9yIGNvbW1pc3Npb24iOiLguInguLHguJnguYTguJTguYnguKPguLHguJrguYDguIfguLTguJnguYLguJrguJnguLHguKrguKvguKPguLfguK3guITguK3guKHguKHguLTguIrguIrguLHguJkiLCJXaGF0IGlzIHlvdXIgc2FsYXJ5PyI6IuC5gOC4h%2BC4tOC4meC5gOC4lOC4t%2BC4reC4meC4guC4reC4h%2BC4hOC4uOC4k%2BC4hOC4t%2BC4reC5gOC4l%2BC5iOC4suC5hOC4q%2BC4o%2BC5iCIsIlVzZWQgdG8gY2FsY3VsYXRlIGF2ZXJhZ2UgYW5kIHdvbid0IGJlIGluY2x1ZGVkIGluIHlvdXIgcmV2aWV3Ijoi4LiC4LmJ4Lit4Lih4Li54Lil4LiZ4Li14LmJ4LmD4LiK4LmJ4LmA4Lie4Li34LmI4Lit4LiE4Liz4LiZ4Lin4LiT4LiE4LmI4Liy4LmA4LiJ4Lil4Li14LmI4Lii4LmA4LiH4Li04LiZ4LmA4LiU4Li34Lit4LiZ4LmA4LiX4LmI4Liy4LiZ4Lix4LmJ4LiZIOC5geC4peC4sOC4iOC4sOC5hOC4oeC5iOC4luC4ueC4geC5gOC4m%2BC4tOC4lOC5gOC4nOC4ouC5g%2BC4meC4geC4suC4o%2BC4o%2BC4teC4p%2BC4tOC4p%2BC4guC4reC4h%2BC4hOC4uOC4kyIsIkN1cnJlbmN5Ijoi4Liq4LiB4Li44Lil4LmA4LiH4Li04LiZIiwiQW1vdW50Ijoi4LiI4Liz4LiZ4Lin4LiZ4LmA4LiH4Li04LiZIiwiZXhjbC4gc3VwZXIiOiLguYTguKHguYjguKPguKfguKHguYDguIfguLTguJnguKrguKHguJfguJrguIHguK3guIfguJfguLjguJnguKrguLPguKPguK3guIfguYDguKXguLXguYnguKLguIfguIrguLXguJ4ifQ%3D%3D!"),
        () => import(
          /* webpackChunkName: "th-translations" */
          "./translations.json-th-virtual.json!=!../../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJVbmRlciB7c2FsYXJ5VGV4dH0iOiLguJXguYjguLPguIHguKfguYjguLIge3NhbGFyeVRleHR9IiwiTW9yZSB0aGFuIHtzYWxhcnlUZXh0fSI6IuC4quC4ueC4h%2BC4geC4p%2BC5iOC4siB7c2FsYXJ5VGV4dH0iLCJJIGFsc28gcmVjZWl2ZSBib251c2VzIG9yIGNvbW1pc3Npb24iOiLguInguLHguJnguYTguJTguYnguKPguLHguJrguYDguIfguLTguJnguYLguJrguJnguLHguKrguKvguKPguLfguK3guITguK3guKHguKHguLTguIrguIrguLHguJkiLCJXaGF0IGlzIHlvdXIgc2FsYXJ5PyI6IuC5gOC4h%2BC4tOC4meC5gOC4lOC4t%2BC4reC4meC4guC4reC4h%2BC4hOC4uOC4k%2BC4hOC4t%2BC4reC5gOC4l%2BC5iOC4suC5hOC4q%2BC4o%2BC5iCIsIlVzZWQgdG8gY2FsY3VsYXRlIGF2ZXJhZ2UgYW5kIHdvbid0IGJlIGluY2x1ZGVkIGluIHlvdXIgcmV2aWV3Ijoi4LiC4LmJ4Lit4Lih4Li54Lil4LiZ4Li14LmJ4LmD4LiK4LmJ4LmA4Lie4Li34LmI4Lit4LiE4Liz4LiZ4Lin4LiT4LiE4LmI4Liy4LmA4LiJ4Lil4Li14LmI4Lii4LmA4LiH4Li04LiZ4LmA4LiU4Li34Lit4LiZ4LmA4LiX4LmI4Liy4LiZ4Lix4LmJ4LiZIOC5geC4peC4sOC4iOC4sOC5hOC4oeC5iOC4luC4ueC4geC5gOC4m%2BC4tOC4lOC5gOC4nOC4ouC5g%2BC4meC4geC4suC4o%2BC4o%2BC4teC4p%2BC4tOC4p%2BC4guC4reC4h%2BC4hOC4uOC4kyIsIkN1cnJlbmN5Ijoi4Liq4LiB4Li44Lil4LmA4LiH4Li04LiZIiwiQW1vdW50Ijoi4LiI4Liz4LiZ4Lin4LiZ4LmA4LiH4Li04LiZIiwiZXhjbC4gc3VwZXIiOiLguYTguKHguYjguKPguKfguKHguYDguIfguLTguJnguKrguKHguJfguJrguIHguK3guIfguJfguLjguJnguKrguLPguKPguK3guIfguYDguKXguLXguYnguKLguIfguIrguLXguJ4ifQ%3D%3D!"
        )
      ),
"id": createLanguage(
        require.resolveWeak("./translations.json-id-virtual.json!=!../../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJVbmRlciB7c2FsYXJ5VGV4dH0iOiJEaSBiYXdhaCB7c2FsYXJ5VGV4dH0iLCJNb3JlIHRoYW4ge3NhbGFyeVRleHR9IjoiRGkgYXRhcyB7c2FsYXJ5VGV4dH0iLCJJIGFsc28gcmVjZWl2ZSBib251c2VzIG9yIGNvbW1pc3Npb24iOiJTYXlhIGp1Z2EgbWVuZGFwYXQgYm9udXMgZGFuIGtvbWlzaSIsIldoYXQgaXMgeW91ciBzYWxhcnk%2FIjoiQmVyYXBhIGdhamkgQW5kYT8iLCJVc2VkIHRvIGNhbGN1bGF0ZSBhdmVyYWdlIGFuZCB3b24ndCBiZSBpbmNsdWRlZCBpbiB5b3VyIHJldmlldyI6IkhhbnlhIHVudHVrIG1lbmdoaXR1bmcgcmF0YS1yYXRhIGRhbiB0aWRhayBha2FuIGRpdGFtcGlsa2FuIiwiQ3VycmVuY3kiOiJNYXRhIHVhbmciLCJBbW91bnQiOiJOb21pbmFsIiwiZXhjbC4gc3VwZXIiOiJkaSBsdWFyIHR1bmphbmdhbiJ9!"),
        () => import(
          /* webpackChunkName: "id-translations" */
          "./translations.json-id-virtual.json!=!../../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJVbmRlciB7c2FsYXJ5VGV4dH0iOiJEaSBiYXdhaCB7c2FsYXJ5VGV4dH0iLCJNb3JlIHRoYW4ge3NhbGFyeVRleHR9IjoiRGkgYXRhcyB7c2FsYXJ5VGV4dH0iLCJJIGFsc28gcmVjZWl2ZSBib251c2VzIG9yIGNvbW1pc3Npb24iOiJTYXlhIGp1Z2EgbWVuZGFwYXQgYm9udXMgZGFuIGtvbWlzaSIsIldoYXQgaXMgeW91ciBzYWxhcnk%2FIjoiQmVyYXBhIGdhamkgQW5kYT8iLCJVc2VkIHRvIGNhbGN1bGF0ZSBhdmVyYWdlIGFuZCB3b24ndCBiZSBpbmNsdWRlZCBpbiB5b3VyIHJldmlldyI6IkhhbnlhIHVudHVrIG1lbmdoaXR1bmcgcmF0YS1yYXRhIGRhbiB0aWRhayBha2FuIGRpdGFtcGlsa2FuIiwiQ3VycmVuY3kiOiJNYXRhIHVhbmciLCJBbW91bnQiOiJOb21pbmFsIiwiZXhjbC4gc3VwZXIiOiJkaSBsdWFyIHR1bmphbmdhbiJ9!"
        )
      ),
"zh": createLanguage(
        require.resolveWeak("./translations.json-zh-virtual.json!=!../../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJVbmRlciB7c2FsYXJ5VGV4dH0iOiJVbmRlciB7c2FsYXJ5VGV4dH0iLCJNb3JlIHRoYW4ge3NhbGFyeVRleHR9IjoiTW9yZSB0aGFuIHtzYWxhcnlUZXh0fSIsIkkgYWxzbyByZWNlaXZlIGJvbnVzZXMgb3IgY29tbWlzc2lvbiI6IkkgYWxzbyByZWNlaXZlIGJvbnVzZXMgb3IgY29tbWlzc2lvbiIsIldoYXQgaXMgeW91ciBzYWxhcnk%2FIjoiV2hhdCBpcyB5b3VyIHNhbGFyeT8iLCJVc2VkIHRvIGNhbGN1bGF0ZSBhdmVyYWdlIGFuZCB3b24ndCBiZSBpbmNsdWRlZCBpbiB5b3VyIHJldmlldyI6IlVzZWQgdG8gY2FsY3VsYXRlIGF2ZXJhZ2UgYW5kIHdvbid0IGJlIGluY2x1ZGVkIGluIHlvdXIgcmV2aWV3IiwiQ3VycmVuY3kiOiJDdXJyZW5jeSIsIkFtb3VudCI6IkFtb3VudCIsImV4Y2wuIHN1cGVyIjoiZXhjbC4gc3VwZXIifQ%3D%3D!"),
        () => import(
          /* webpackChunkName: "zh-translations" */
          "./translations.json-zh-virtual.json!=!../../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJVbmRlciB7c2FsYXJ5VGV4dH0iOiJVbmRlciB7c2FsYXJ5VGV4dH0iLCJNb3JlIHRoYW4ge3NhbGFyeVRleHR9IjoiTW9yZSB0aGFuIHtzYWxhcnlUZXh0fSIsIkkgYWxzbyByZWNlaXZlIGJvbnVzZXMgb3IgY29tbWlzc2lvbiI6IkkgYWxzbyByZWNlaXZlIGJvbnVzZXMgb3IgY29tbWlzc2lvbiIsIldoYXQgaXMgeW91ciBzYWxhcnk%2FIjoiV2hhdCBpcyB5b3VyIHNhbGFyeT8iLCJVc2VkIHRvIGNhbGN1bGF0ZSBhdmVyYWdlIGFuZCB3b24ndCBiZSBpbmNsdWRlZCBpbiB5b3VyIHJldmlldyI6IlVzZWQgdG8gY2FsY3VsYXRlIGF2ZXJhZ2UgYW5kIHdvbid0IGJlIGluY2x1ZGVkIGluIHlvdXIgcmV2aWV3IiwiQ3VycmVuY3kiOiJDdXJyZW5jeSIsIkFtb3VudCI6IkFtb3VudCIsImV4Y2wuIHN1cGVyIjoiZXhjbC4gc3VwZXIifQ%3D%3D!"
        )
      ),
"en-AU": createLanguage(
        require.resolveWeak("./translations.json-en-AU-virtual.json!=!../../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJVbmRlciB7c2FsYXJ5VGV4dH0iOiJVbmRlciB7c2FsYXJ5VGV4dH0iLCJNb3JlIHRoYW4ge3NhbGFyeVRleHR9IjoiTW9yZSB0aGFuIHtzYWxhcnlUZXh0fSIsIkkgYWxzbyByZWNlaXZlIGJvbnVzZXMgb3IgY29tbWlzc2lvbiI6IkkgYWxzbyByZWNlaXZlIGJvbnVzZXMgb3IgY29tbWlzc2lvbiIsIldoYXQgaXMgeW91ciBzYWxhcnk%2FIjoiV2hhdCBpcyB5b3VyIHNhbGFyeT8iLCJVc2VkIHRvIGNhbGN1bGF0ZSBhdmVyYWdlIGFuZCB3b24ndCBiZSBpbmNsdWRlZCBpbiB5b3VyIHJldmlldyI6IlVzZWQgdG8gY2FsY3VsYXRlIGF2ZXJhZ2UgYW5kIHdvbid0IGJlIGluY2x1ZGVkIGluIHlvdXIgcmV2aWV3IiwiQ3VycmVuY3kiOiJDdXJyZW5jeSIsIkFtb3VudCI6IkFtb3VudCIsImV4Y2wuIHN1cGVyIjoiZXhjbC4gc3VwZXIifQ%3D%3D!"),
        () => import(
          /* webpackChunkName: "en-AU-translations" */
          "./translations.json-en-AU-virtual.json!=!../../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJVbmRlciB7c2FsYXJ5VGV4dH0iOiJVbmRlciB7c2FsYXJ5VGV4dH0iLCJNb3JlIHRoYW4ge3NhbGFyeVRleHR9IjoiTW9yZSB0aGFuIHtzYWxhcnlUZXh0fSIsIkkgYWxzbyByZWNlaXZlIGJvbnVzZXMgb3IgY29tbWlzc2lvbiI6IkkgYWxzbyByZWNlaXZlIGJvbnVzZXMgb3IgY29tbWlzc2lvbiIsIldoYXQgaXMgeW91ciBzYWxhcnk%2FIjoiV2hhdCBpcyB5b3VyIHNhbGFyeT8iLCJVc2VkIHRvIGNhbGN1bGF0ZSBhdmVyYWdlIGFuZCB3b24ndCBiZSBpbmNsdWRlZCBpbiB5b3VyIHJldmlldyI6IlVzZWQgdG8gY2FsY3VsYXRlIGF2ZXJhZ2UgYW5kIHdvbid0IGJlIGluY2x1ZGVkIGluIHlvdXIgcmV2aWV3IiwiQ3VycmVuY3kiOiJDdXJyZW5jeSIsIkFtb3VudCI6IkFtb3VudCIsImV4Y2wuIHN1cGVyIjoiZXhjbC4gc3VwZXIifQ%3D%3D!"
        )
      ),
"en-HK": createLanguage(
        require.resolveWeak("./translations.json-en-HK-virtual.json!=!../../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJVbmRlciB7c2FsYXJ5VGV4dH0iOiJVbmRlciB7c2FsYXJ5VGV4dH0iLCJNb3JlIHRoYW4ge3NhbGFyeVRleHR9IjoiTW9yZSB0aGFuIHtzYWxhcnlUZXh0fSIsIkkgYWxzbyByZWNlaXZlIGJvbnVzZXMgb3IgY29tbWlzc2lvbiI6IkkgYWxzbyByZWNlaXZlIGJvbnVzZXMgb3IgY29tbWlzc2lvbiIsIldoYXQgaXMgeW91ciBzYWxhcnk%2FIjoiV2hhdCBpcyB5b3VyIHNhbGFyeT8iLCJVc2VkIHRvIGNhbGN1bGF0ZSBhdmVyYWdlIGFuZCB3b24ndCBiZSBpbmNsdWRlZCBpbiB5b3VyIHJldmlldyI6IlVzZWQgdG8gY2FsY3VsYXRlIGF2ZXJhZ2UgYW5kIHdvbid0IGJlIGluY2x1ZGVkIGluIHlvdXIgcmV2aWV3IiwiQ3VycmVuY3kiOiJDdXJyZW5jeSIsIkFtb3VudCI6IkFtb3VudCIsImV4Y2wuIHN1cGVyIjoiZXhjbC4gc3VwZXIifQ%3D%3D!"),
        () => import(
          /* webpackChunkName: "en-HK-translations" */
          "./translations.json-en-HK-virtual.json!=!../../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJVbmRlciB7c2FsYXJ5VGV4dH0iOiJVbmRlciB7c2FsYXJ5VGV4dH0iLCJNb3JlIHRoYW4ge3NhbGFyeVRleHR9IjoiTW9yZSB0aGFuIHtzYWxhcnlUZXh0fSIsIkkgYWxzbyByZWNlaXZlIGJvbnVzZXMgb3IgY29tbWlzc2lvbiI6IkkgYWxzbyByZWNlaXZlIGJvbnVzZXMgb3IgY29tbWlzc2lvbiIsIldoYXQgaXMgeW91ciBzYWxhcnk%2FIjoiV2hhdCBpcyB5b3VyIHNhbGFyeT8iLCJVc2VkIHRvIGNhbGN1bGF0ZSBhdmVyYWdlIGFuZCB3b24ndCBiZSBpbmNsdWRlZCBpbiB5b3VyIHJldmlldyI6IlVzZWQgdG8gY2FsY3VsYXRlIGF2ZXJhZ2UgYW5kIHdvbid0IGJlIGluY2x1ZGVkIGluIHlvdXIgcmV2aWV3IiwiQ3VycmVuY3kiOiJDdXJyZW5jeSIsIkFtb3VudCI6IkFtb3VudCIsImV4Y2wuIHN1cGVyIjoiZXhjbC4gc3VwZXIifQ%3D%3D!"
        )
      ),
"en-ID": createLanguage(
        require.resolveWeak("./translations.json-en-ID-virtual.json!=!../../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJVbmRlciB7c2FsYXJ5VGV4dH0iOiJVbmRlciB7c2FsYXJ5VGV4dH0iLCJNb3JlIHRoYW4ge3NhbGFyeVRleHR9IjoiTW9yZSB0aGFuIHtzYWxhcnlUZXh0fSIsIkkgYWxzbyByZWNlaXZlIGJvbnVzZXMgb3IgY29tbWlzc2lvbiI6IkkgYWxzbyByZWNlaXZlIGJvbnVzZXMgb3IgY29tbWlzc2lvbiIsIldoYXQgaXMgeW91ciBzYWxhcnk%2FIjoiV2hhdCBpcyB5b3VyIHNhbGFyeT8iLCJVc2VkIHRvIGNhbGN1bGF0ZSBhdmVyYWdlIGFuZCB3b24ndCBiZSBpbmNsdWRlZCBpbiB5b3VyIHJldmlldyI6IlVzZWQgdG8gY2FsY3VsYXRlIGF2ZXJhZ2UgYW5kIHdvbid0IGJlIGluY2x1ZGVkIGluIHlvdXIgcmV2aWV3IiwiQ3VycmVuY3kiOiJDdXJyZW5jeSIsIkFtb3VudCI6IkFtb3VudCIsImV4Y2wuIHN1cGVyIjoiZXhjbC4gc3VwZXIifQ%3D%3D!"),
        () => import(
          /* webpackChunkName: "en-ID-translations" */
          "./translations.json-en-ID-virtual.json!=!../../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJVbmRlciB7c2FsYXJ5VGV4dH0iOiJVbmRlciB7c2FsYXJ5VGV4dH0iLCJNb3JlIHRoYW4ge3NhbGFyeVRleHR9IjoiTW9yZSB0aGFuIHtzYWxhcnlUZXh0fSIsIkkgYWxzbyByZWNlaXZlIGJvbnVzZXMgb3IgY29tbWlzc2lvbiI6IkkgYWxzbyByZWNlaXZlIGJvbnVzZXMgb3IgY29tbWlzc2lvbiIsIldoYXQgaXMgeW91ciBzYWxhcnk%2FIjoiV2hhdCBpcyB5b3VyIHNhbGFyeT8iLCJVc2VkIHRvIGNhbGN1bGF0ZSBhdmVyYWdlIGFuZCB3b24ndCBiZSBpbmNsdWRlZCBpbiB5b3VyIHJldmlldyI6IlVzZWQgdG8gY2FsY3VsYXRlIGF2ZXJhZ2UgYW5kIHdvbid0IGJlIGluY2x1ZGVkIGluIHlvdXIgcmV2aWV3IiwiQ3VycmVuY3kiOiJDdXJyZW5jeSIsIkFtb3VudCI6IkFtb3VudCIsImV4Y2wuIHN1cGVyIjoiZXhjbC4gc3VwZXIifQ%3D%3D!"
        )
      ),
"en-MY": createLanguage(
        require.resolveWeak("./translations.json-en-MY-virtual.json!=!../../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJVbmRlciB7c2FsYXJ5VGV4dH0iOiJVbmRlciB7c2FsYXJ5VGV4dH0iLCJNb3JlIHRoYW4ge3NhbGFyeVRleHR9IjoiTW9yZSB0aGFuIHtzYWxhcnlUZXh0fSIsIkkgYWxzbyByZWNlaXZlIGJvbnVzZXMgb3IgY29tbWlzc2lvbiI6IkkgYWxzbyByZWNlaXZlIGJvbnVzZXMgb3IgY29tbWlzc2lvbiIsIldoYXQgaXMgeW91ciBzYWxhcnk%2FIjoiV2hhdCBpcyB5b3VyIHNhbGFyeT8iLCJVc2VkIHRvIGNhbGN1bGF0ZSBhdmVyYWdlIGFuZCB3b24ndCBiZSBpbmNsdWRlZCBpbiB5b3VyIHJldmlldyI6IlVzZWQgdG8gY2FsY3VsYXRlIGF2ZXJhZ2UgYW5kIHdvbid0IGJlIGluY2x1ZGVkIGluIHlvdXIgcmV2aWV3IiwiQ3VycmVuY3kiOiJDdXJyZW5jeSIsIkFtb3VudCI6IkFtb3VudCIsImV4Y2wuIHN1cGVyIjoiZXhjbC4gc3VwZXIifQ%3D%3D!"),
        () => import(
          /* webpackChunkName: "en-MY-translations" */
          "./translations.json-en-MY-virtual.json!=!../../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJVbmRlciB7c2FsYXJ5VGV4dH0iOiJVbmRlciB7c2FsYXJ5VGV4dH0iLCJNb3JlIHRoYW4ge3NhbGFyeVRleHR9IjoiTW9yZSB0aGFuIHtzYWxhcnlUZXh0fSIsIkkgYWxzbyByZWNlaXZlIGJvbnVzZXMgb3IgY29tbWlzc2lvbiI6IkkgYWxzbyByZWNlaXZlIGJvbnVzZXMgb3IgY29tbWlzc2lvbiIsIldoYXQgaXMgeW91ciBzYWxhcnk%2FIjoiV2hhdCBpcyB5b3VyIHNhbGFyeT8iLCJVc2VkIHRvIGNhbGN1bGF0ZSBhdmVyYWdlIGFuZCB3b24ndCBiZSBpbmNsdWRlZCBpbiB5b3VyIHJldmlldyI6IlVzZWQgdG8gY2FsY3VsYXRlIGF2ZXJhZ2UgYW5kIHdvbid0IGJlIGluY2x1ZGVkIGluIHlvdXIgcmV2aWV3IiwiQ3VycmVuY3kiOiJDdXJyZW5jeSIsIkFtb3VudCI6IkFtb3VudCIsImV4Y2wuIHN1cGVyIjoiZXhjbC4gc3VwZXIifQ%3D%3D!"
        )
      ),
"en-NZ": createLanguage(
        require.resolveWeak("./translations.json-en-NZ-virtual.json!=!../../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJVbmRlciB7c2FsYXJ5VGV4dH0iOiJVbmRlciB7c2FsYXJ5VGV4dH0iLCJNb3JlIHRoYW4ge3NhbGFyeVRleHR9IjoiTW9yZSB0aGFuIHtzYWxhcnlUZXh0fSIsIkkgYWxzbyByZWNlaXZlIGJvbnVzZXMgb3IgY29tbWlzc2lvbiI6IkkgYWxzbyByZWNlaXZlIGJvbnVzZXMgb3IgY29tbWlzc2lvbiIsIldoYXQgaXMgeW91ciBzYWxhcnk%2FIjoiV2hhdCBpcyB5b3VyIHNhbGFyeT8iLCJVc2VkIHRvIGNhbGN1bGF0ZSBhdmVyYWdlIGFuZCB3b24ndCBiZSBpbmNsdWRlZCBpbiB5b3VyIHJldmlldyI6IlVzZWQgdG8gY2FsY3VsYXRlIGF2ZXJhZ2UgYW5kIHdvbid0IGJlIGluY2x1ZGVkIGluIHlvdXIgcmV2aWV3IiwiQ3VycmVuY3kiOiJDdXJyZW5jeSIsIkFtb3VudCI6IkFtb3VudCIsImV4Y2wuIHN1cGVyIjoiZXhjbC4gc3VwZXIifQ%3D%3D!"),
        () => import(
          /* webpackChunkName: "en-NZ-translations" */
          "./translations.json-en-NZ-virtual.json!=!../../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJVbmRlciB7c2FsYXJ5VGV4dH0iOiJVbmRlciB7c2FsYXJ5VGV4dH0iLCJNb3JlIHRoYW4ge3NhbGFyeVRleHR9IjoiTW9yZSB0aGFuIHtzYWxhcnlUZXh0fSIsIkkgYWxzbyByZWNlaXZlIGJvbnVzZXMgb3IgY29tbWlzc2lvbiI6IkkgYWxzbyByZWNlaXZlIGJvbnVzZXMgb3IgY29tbWlzc2lvbiIsIldoYXQgaXMgeW91ciBzYWxhcnk%2FIjoiV2hhdCBpcyB5b3VyIHNhbGFyeT8iLCJVc2VkIHRvIGNhbGN1bGF0ZSBhdmVyYWdlIGFuZCB3b24ndCBiZSBpbmNsdWRlZCBpbiB5b3VyIHJldmlldyI6IlVzZWQgdG8gY2FsY3VsYXRlIGF2ZXJhZ2UgYW5kIHdvbid0IGJlIGluY2x1ZGVkIGluIHlvdXIgcmV2aWV3IiwiQ3VycmVuY3kiOiJDdXJyZW5jeSIsIkFtb3VudCI6IkFtb3VudCIsImV4Y2wuIHN1cGVyIjoiZXhjbC4gc3VwZXIifQ%3D%3D!"
        )
      ),
"en-PH": createLanguage(
        require.resolveWeak("./translations.json-en-PH-virtual.json!=!../../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJVbmRlciB7c2FsYXJ5VGV4dH0iOiJVbmRlciB7c2FsYXJ5VGV4dH0iLCJNb3JlIHRoYW4ge3NhbGFyeVRleHR9IjoiTW9yZSB0aGFuIHtzYWxhcnlUZXh0fSIsIkkgYWxzbyByZWNlaXZlIGJvbnVzZXMgb3IgY29tbWlzc2lvbiI6IkkgYWxzbyByZWNlaXZlIGJvbnVzZXMgb3IgY29tbWlzc2lvbiIsIldoYXQgaXMgeW91ciBzYWxhcnk%2FIjoiV2hhdCBpcyB5b3VyIHNhbGFyeT8iLCJVc2VkIHRvIGNhbGN1bGF0ZSBhdmVyYWdlIGFuZCB3b24ndCBiZSBpbmNsdWRlZCBpbiB5b3VyIHJldmlldyI6IlVzZWQgdG8gY2FsY3VsYXRlIGF2ZXJhZ2UgYW5kIHdvbid0IGJlIGluY2x1ZGVkIGluIHlvdXIgcmV2aWV3IiwiQ3VycmVuY3kiOiJDdXJyZW5jeSIsIkFtb3VudCI6IkFtb3VudCIsImV4Y2wuIHN1cGVyIjoiZXhjbC4gc3VwZXIifQ%3D%3D!"),
        () => import(
          /* webpackChunkName: "en-PH-translations" */
          "./translations.json-en-PH-virtual.json!=!../../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJVbmRlciB7c2FsYXJ5VGV4dH0iOiJVbmRlciB7c2FsYXJ5VGV4dH0iLCJNb3JlIHRoYW4ge3NhbGFyeVRleHR9IjoiTW9yZSB0aGFuIHtzYWxhcnlUZXh0fSIsIkkgYWxzbyByZWNlaXZlIGJvbnVzZXMgb3IgY29tbWlzc2lvbiI6IkkgYWxzbyByZWNlaXZlIGJvbnVzZXMgb3IgY29tbWlzc2lvbiIsIldoYXQgaXMgeW91ciBzYWxhcnk%2FIjoiV2hhdCBpcyB5b3VyIHNhbGFyeT8iLCJVc2VkIHRvIGNhbGN1bGF0ZSBhdmVyYWdlIGFuZCB3b24ndCBiZSBpbmNsdWRlZCBpbiB5b3VyIHJldmlldyI6IlVzZWQgdG8gY2FsY3VsYXRlIGF2ZXJhZ2UgYW5kIHdvbid0IGJlIGluY2x1ZGVkIGluIHlvdXIgcmV2aWV3IiwiQ3VycmVuY3kiOiJDdXJyZW5jeSIsIkFtb3VudCI6IkFtb3VudCIsImV4Y2wuIHN1cGVyIjoiZXhjbC4gc3VwZXIifQ%3D%3D!"
        )
      ),
"en-SG": createLanguage(
        require.resolveWeak("./translations.json-en-SG-virtual.json!=!../../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJVbmRlciB7c2FsYXJ5VGV4dH0iOiJVbmRlciB7c2FsYXJ5VGV4dH0iLCJNb3JlIHRoYW4ge3NhbGFyeVRleHR9IjoiTW9yZSB0aGFuIHtzYWxhcnlUZXh0fSIsIkkgYWxzbyByZWNlaXZlIGJvbnVzZXMgb3IgY29tbWlzc2lvbiI6IkkgYWxzbyByZWNlaXZlIGJvbnVzZXMgb3IgY29tbWlzc2lvbiIsIldoYXQgaXMgeW91ciBzYWxhcnk%2FIjoiV2hhdCBpcyB5b3VyIHNhbGFyeT8iLCJVc2VkIHRvIGNhbGN1bGF0ZSBhdmVyYWdlIGFuZCB3b24ndCBiZSBpbmNsdWRlZCBpbiB5b3VyIHJldmlldyI6IlVzZWQgdG8gY2FsY3VsYXRlIGF2ZXJhZ2UgYW5kIHdvbid0IGJlIGluY2x1ZGVkIGluIHlvdXIgcmV2aWV3IiwiQ3VycmVuY3kiOiJDdXJyZW5jeSIsIkFtb3VudCI6IkFtb3VudCIsImV4Y2wuIHN1cGVyIjoiZXhjbC4gc3VwZXIifQ%3D%3D!"),
        () => import(
          /* webpackChunkName: "en-SG-translations" */
          "./translations.json-en-SG-virtual.json!=!../../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJVbmRlciB7c2FsYXJ5VGV4dH0iOiJVbmRlciB7c2FsYXJ5VGV4dH0iLCJNb3JlIHRoYW4ge3NhbGFyeVRleHR9IjoiTW9yZSB0aGFuIHtzYWxhcnlUZXh0fSIsIkkgYWxzbyByZWNlaXZlIGJvbnVzZXMgb3IgY29tbWlzc2lvbiI6IkkgYWxzbyByZWNlaXZlIGJvbnVzZXMgb3IgY29tbWlzc2lvbiIsIldoYXQgaXMgeW91ciBzYWxhcnk%2FIjoiV2hhdCBpcyB5b3VyIHNhbGFyeT8iLCJVc2VkIHRvIGNhbGN1bGF0ZSBhdmVyYWdlIGFuZCB3b24ndCBiZSBpbmNsdWRlZCBpbiB5b3VyIHJldmlldyI6IlVzZWQgdG8gY2FsY3VsYXRlIGF2ZXJhZ2UgYW5kIHdvbid0IGJlIGluY2x1ZGVkIGluIHlvdXIgcmV2aWV3IiwiQ3VycmVuY3kiOiJDdXJyZW5jeSIsIkFtb3VudCI6IkFtb3VudCIsImV4Y2wuIHN1cGVyIjoiZXhjbC4gc3VwZXIifQ%3D%3D!"
        )
      ),
"en-TH": createLanguage(
        require.resolveWeak("./translations.json-en-TH-virtual.json!=!../../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJVbmRlciB7c2FsYXJ5VGV4dH0iOiJVbmRlciB7c2FsYXJ5VGV4dH0iLCJNb3JlIHRoYW4ge3NhbGFyeVRleHR9IjoiTW9yZSB0aGFuIHtzYWxhcnlUZXh0fSIsIkkgYWxzbyByZWNlaXZlIGJvbnVzZXMgb3IgY29tbWlzc2lvbiI6IkkgYWxzbyByZWNlaXZlIGJvbnVzZXMgb3IgY29tbWlzc2lvbiIsIldoYXQgaXMgeW91ciBzYWxhcnk%2FIjoiV2hhdCBpcyB5b3VyIHNhbGFyeT8iLCJVc2VkIHRvIGNhbGN1bGF0ZSBhdmVyYWdlIGFuZCB3b24ndCBiZSBpbmNsdWRlZCBpbiB5b3VyIHJldmlldyI6IlVzZWQgdG8gY2FsY3VsYXRlIGF2ZXJhZ2UgYW5kIHdvbid0IGJlIGluY2x1ZGVkIGluIHlvdXIgcmV2aWV3IiwiQ3VycmVuY3kiOiJDdXJyZW5jeSIsIkFtb3VudCI6IkFtb3VudCIsImV4Y2wuIHN1cGVyIjoiZXhjbC4gc3VwZXIifQ%3D%3D!"),
        () => import(
          /* webpackChunkName: "en-TH-translations" */
          "./translations.json-en-TH-virtual.json!=!../../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJVbmRlciB7c2FsYXJ5VGV4dH0iOiJVbmRlciB7c2FsYXJ5VGV4dH0iLCJNb3JlIHRoYW4ge3NhbGFyeVRleHR9IjoiTW9yZSB0aGFuIHtzYWxhcnlUZXh0fSIsIkkgYWxzbyByZWNlaXZlIGJvbnVzZXMgb3IgY29tbWlzc2lvbiI6IkkgYWxzbyByZWNlaXZlIGJvbnVzZXMgb3IgY29tbWlzc2lvbiIsIldoYXQgaXMgeW91ciBzYWxhcnk%2FIjoiV2hhdCBpcyB5b3VyIHNhbGFyeT8iLCJVc2VkIHRvIGNhbGN1bGF0ZSBhdmVyYWdlIGFuZCB3b24ndCBiZSBpbmNsdWRlZCBpbiB5b3VyIHJldmlldyI6IlVzZWQgdG8gY2FsY3VsYXRlIGF2ZXJhZ2UgYW5kIHdvbid0IGJlIGluY2x1ZGVkIGluIHlvdXIgcmV2aWV3IiwiQ3VycmVuY3kiOiJDdXJyZW5jeSIsIkFtb3VudCI6IkFtb3VudCIsImV4Y2wuIHN1cGVyIjoiZXhjbC4gc3VwZXIifQ%3D%3D!"
        )
      ),
"th-TH": createLanguage(
        require.resolveWeak("./translations.json-th-TH-virtual.json!=!../../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJVbmRlciB7c2FsYXJ5VGV4dH0iOiLguJXguYjguLPguIHguKfguYjguLIge3NhbGFyeVRleHR9IiwiTW9yZSB0aGFuIHtzYWxhcnlUZXh0fSI6IuC4quC4ueC4h%2BC4geC4p%2BC5iOC4siB7c2FsYXJ5VGV4dH0iLCJJIGFsc28gcmVjZWl2ZSBib251c2VzIG9yIGNvbW1pc3Npb24iOiLguInguLHguJnguYTguJTguYnguKPguLHguJrguYDguIfguLTguJnguYLguJrguJnguLHguKrguKvguKPguLfguK3guITguK3guKHguKHguLTguIrguIrguLHguJkiLCJXaGF0IGlzIHlvdXIgc2FsYXJ5PyI6IuC5gOC4h%2BC4tOC4meC5gOC4lOC4t%2BC4reC4meC4guC4reC4h%2BC4hOC4uOC4k%2BC4hOC4t%2BC4reC5gOC4l%2BC5iOC4suC5hOC4q%2BC4o%2BC5iCIsIlVzZWQgdG8gY2FsY3VsYXRlIGF2ZXJhZ2UgYW5kIHdvbid0IGJlIGluY2x1ZGVkIGluIHlvdXIgcmV2aWV3Ijoi4LiC4LmJ4Lit4Lih4Li54Lil4LiZ4Li14LmJ4LmD4LiK4LmJ4LmA4Lie4Li34LmI4Lit4LiE4Liz4LiZ4Lin4LiT4LiE4LmI4Liy4LmA4LiJ4Lil4Li14LmI4Lii4LmA4LiH4Li04LiZ4LmA4LiU4Li34Lit4LiZ4LmA4LiX4LmI4Liy4LiZ4Lix4LmJ4LiZIOC5geC4peC4sOC4iOC4sOC5hOC4oeC5iOC4luC4ueC4geC5gOC4m%2BC4tOC4lOC5gOC4nOC4ouC5g%2BC4meC4geC4suC4o%2BC4o%2BC4teC4p%2BC4tOC4p%2BC4guC4reC4h%2BC4hOC4uOC4kyIsIkN1cnJlbmN5Ijoi4Liq4LiB4Li44Lil4LmA4LiH4Li04LiZIiwiQW1vdW50Ijoi4LiI4Liz4LiZ4Lin4LiZ4LmA4LiH4Li04LiZIiwiZXhjbC4gc3VwZXIiOiLguYTguKHguYjguKPguKfguKHguYDguIfguLTguJnguKrguKHguJfguJrguIHguK3guIfguJfguLjguJnguKrguLPguKPguK3guIfguYDguKXguLXguYnguKLguIfguIrguLXguJ4ifQ%3D%3D!"),
        () => import(
          /* webpackChunkName: "th-TH-translations" */
          "./translations.json-th-TH-virtual.json!=!../../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJVbmRlciB7c2FsYXJ5VGV4dH0iOiLguJXguYjguLPguIHguKfguYjguLIge3NhbGFyeVRleHR9IiwiTW9yZSB0aGFuIHtzYWxhcnlUZXh0fSI6IuC4quC4ueC4h%2BC4geC4p%2BC5iOC4siB7c2FsYXJ5VGV4dH0iLCJJIGFsc28gcmVjZWl2ZSBib251c2VzIG9yIGNvbW1pc3Npb24iOiLguInguLHguJnguYTguJTguYnguKPguLHguJrguYDguIfguLTguJnguYLguJrguJnguLHguKrguKvguKPguLfguK3guITguK3guKHguKHguLTguIrguIrguLHguJkiLCJXaGF0IGlzIHlvdXIgc2FsYXJ5PyI6IuC5gOC4h%2BC4tOC4meC5gOC4lOC4t%2BC4reC4meC4guC4reC4h%2BC4hOC4uOC4k%2BC4hOC4t%2BC4reC5gOC4l%2BC5iOC4suC5hOC4q%2BC4o%2BC5iCIsIlVzZWQgdG8gY2FsY3VsYXRlIGF2ZXJhZ2UgYW5kIHdvbid0IGJlIGluY2x1ZGVkIGluIHlvdXIgcmV2aWV3Ijoi4LiC4LmJ4Lit4Lih4Li54Lil4LiZ4Li14LmJ4LmD4LiK4LmJ4LmA4Lie4Li34LmI4Lit4LiE4Liz4LiZ4Lin4LiT4LiE4LmI4Liy4LmA4LiJ4Lil4Li14LmI4Lii4LmA4LiH4Li04LiZ4LmA4LiU4Li34Lit4LiZ4LmA4LiX4LmI4Liy4LiZ4Lix4LmJ4LiZIOC5geC4peC4sOC4iOC4sOC5hOC4oeC5iOC4luC4ueC4geC5gOC4m%2BC4tOC4lOC5gOC4nOC4ouC5g%2BC4meC4geC4suC4o%2BC4o%2BC4teC4p%2BC4tOC4p%2BC4guC4reC4h%2BC4hOC4uOC4kyIsIkN1cnJlbmN5Ijoi4Liq4LiB4Li44Lil4LmA4LiH4Li04LiZIiwiQW1vdW50Ijoi4LiI4Liz4LiZ4Lin4LiZ4LmA4LiH4Li04LiZIiwiZXhjbC4gc3VwZXIiOiLguYTguKHguYjguKPguKfguKHguYDguIfguLTguJnguKrguKHguJfguJrguIHguK3guIfguJfguLjguJnguKrguLPguKPguK3guIfguYDguKXguLXguYnguKLguIfguIrguLXguJ4ifQ%3D%3D!"
        )
      ),
"id-ID": createLanguage(
        require.resolveWeak("./translations.json-id-ID-virtual.json!=!../../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJVbmRlciB7c2FsYXJ5VGV4dH0iOiJEaSBiYXdhaCB7c2FsYXJ5VGV4dH0iLCJNb3JlIHRoYW4ge3NhbGFyeVRleHR9IjoiRGkgYXRhcyB7c2FsYXJ5VGV4dH0iLCJJIGFsc28gcmVjZWl2ZSBib251c2VzIG9yIGNvbW1pc3Npb24iOiJTYXlhIGp1Z2EgbWVuZGFwYXQgYm9udXMgZGFuIGtvbWlzaSIsIldoYXQgaXMgeW91ciBzYWxhcnk%2FIjoiQmVyYXBhIGdhamkgQW5kYT8iLCJVc2VkIHRvIGNhbGN1bGF0ZSBhdmVyYWdlIGFuZCB3b24ndCBiZSBpbmNsdWRlZCBpbiB5b3VyIHJldmlldyI6IkhhbnlhIHVudHVrIG1lbmdoaXR1bmcgcmF0YS1yYXRhIGRhbiB0aWRhayBha2FuIGRpdGFtcGlsa2FuIiwiQ3VycmVuY3kiOiJNYXRhIHVhbmciLCJBbW91bnQiOiJOb21pbmFsIiwiZXhjbC4gc3VwZXIiOiJkaSBsdWFyIHR1bmphbmdhbiJ9!"),
        () => import(
          /* webpackChunkName: "id-ID-translations" */
          "./translations.json-id-ID-virtual.json!=!../../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJVbmRlciB7c2FsYXJ5VGV4dH0iOiJEaSBiYXdhaCB7c2FsYXJ5VGV4dH0iLCJNb3JlIHRoYW4ge3NhbGFyeVRleHR9IjoiRGkgYXRhcyB7c2FsYXJ5VGV4dH0iLCJJIGFsc28gcmVjZWl2ZSBib251c2VzIG9yIGNvbW1pc3Npb24iOiJTYXlhIGp1Z2EgbWVuZGFwYXQgYm9udXMgZGFuIGtvbWlzaSIsIldoYXQgaXMgeW91ciBzYWxhcnk%2FIjoiQmVyYXBhIGdhamkgQW5kYT8iLCJVc2VkIHRvIGNhbGN1bGF0ZSBhdmVyYWdlIGFuZCB3b24ndCBiZSBpbmNsdWRlZCBpbiB5b3VyIHJldmlldyI6IkhhbnlhIHVudHVrIG1lbmdoaXR1bmcgcmF0YS1yYXRhIGRhbiB0aWRhayBha2FuIGRpdGFtcGlsa2FuIiwiQ3VycmVuY3kiOiJNYXRhIHVhbmciLCJBbW91bnQiOiJOb21pbmFsIiwiZXhjbC4gc3VwZXIiOiJkaSBsdWFyIHR1bmphbmdhbiJ9!"
        )
      ),
"zh-HK": createLanguage(
        require.resolveWeak("./translations.json-zh-HK-virtual.json!=!../../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJVbmRlciB7c2FsYXJ5VGV4dH0iOiJVbmRlciB7c2FsYXJ5VGV4dH0iLCJNb3JlIHRoYW4ge3NhbGFyeVRleHR9IjoiTW9yZSB0aGFuIHtzYWxhcnlUZXh0fSIsIkkgYWxzbyByZWNlaXZlIGJvbnVzZXMgb3IgY29tbWlzc2lvbiI6IkkgYWxzbyByZWNlaXZlIGJvbnVzZXMgb3IgY29tbWlzc2lvbiIsIldoYXQgaXMgeW91ciBzYWxhcnk%2FIjoiV2hhdCBpcyB5b3VyIHNhbGFyeT8iLCJVc2VkIHRvIGNhbGN1bGF0ZSBhdmVyYWdlIGFuZCB3b24ndCBiZSBpbmNsdWRlZCBpbiB5b3VyIHJldmlldyI6IlVzZWQgdG8gY2FsY3VsYXRlIGF2ZXJhZ2UgYW5kIHdvbid0IGJlIGluY2x1ZGVkIGluIHlvdXIgcmV2aWV3IiwiQ3VycmVuY3kiOiJDdXJyZW5jeSIsIkFtb3VudCI6IkFtb3VudCIsImV4Y2wuIHN1cGVyIjoiZXhjbC4gc3VwZXIifQ%3D%3D!"),
        () => import(
          /* webpackChunkName: "zh-HK-translations" */
          "./translations.json-zh-HK-virtual.json!=!../../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJVbmRlciB7c2FsYXJ5VGV4dH0iOiJVbmRlciB7c2FsYXJ5VGV4dH0iLCJNb3JlIHRoYW4ge3NhbGFyeVRleHR9IjoiTW9yZSB0aGFuIHtzYWxhcnlUZXh0fSIsIkkgYWxzbyByZWNlaXZlIGJvbnVzZXMgb3IgY29tbWlzc2lvbiI6IkkgYWxzbyByZWNlaXZlIGJvbnVzZXMgb3IgY29tbWlzc2lvbiIsIldoYXQgaXMgeW91ciBzYWxhcnk%2FIjoiV2hhdCBpcyB5b3VyIHNhbGFyeT8iLCJVc2VkIHRvIGNhbGN1bGF0ZSBhdmVyYWdlIGFuZCB3b24ndCBiZSBpbmNsdWRlZCBpbiB5b3VyIHJldmlldyI6IlVzZWQgdG8gY2FsY3VsYXRlIGF2ZXJhZ2UgYW5kIHdvbid0IGJlIGluY2x1ZGVkIGluIHlvdXIgcmV2aWV3IiwiQ3VycmVuY3kiOiJDdXJyZW5jeSIsIkFtb3VudCI6IkFtb3VudCIsImV4Y2wuIHN1cGVyIjoiZXhjbC4gc3VwZXIifQ%3D%3D!"
        )
      ),
"en-PSEUDO": createLanguage(
        require.resolveWeak("./translations.json-en-PSEUDO-virtual.json!=!../../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJVbmRlciB7c2FsYXJ5VGV4dH0iOiJbx5nguIHguLXguYnGjOG6veG6veG6veG6vcWZIHtzYWxhcnlUZXh0fV0iLCJNb3JlIHRoYW4ge3NhbGFyeVRleHR9IjoiW%2BG5gsO2w7bDtsWZ4bq94bq94bq9IOG5r%2BG4qcSDxIPEg%2BC4geC4teC5iSB7c2FsYXJ5VGV4dH1dIiwiSSBhbHNvIHJlY2VpdmUgYm9udXNlcyBvciBjb21taXNzaW9uIjoiW8OPIMSDxIPEg8aaxaHDtsO2w7YgxZnhur3hur3hur3Dp%2BG6veG6veG6vcOsw6zDrOG5veG6veG6veG6vSDDn8O2w7bDtuC4geC4teC5iceax5rHmsWh4bq94bq94bq9xaEgw7bDtsO2xZkgw6fDtsO2w7ZtzIJtzILDrMOsw6zFocWhw6zDrMOsw7bDtsO24LiB4Li14LmJXSIsIldoYXQgaXMgeW91ciBzYWxhcnk%2FIjoiW8W04bipxIPEg%2BG5ryDDrMOsxaEgw73DvcO2w7bHmseaxZkgxaHEg8SDxprEg8SDxZnDvcO9P10iLCJVc2VkIHRvIGNhbGN1bGF0ZSBhdmVyYWdlIGFuZCB3b24ndCBiZSBpbmNsdWRlZCBpbiB5b3VyIHJldmlldyI6IlvHmcWh4bq94bq94bq9xowg4bmvw7bDtsO2IMOnxIPEg8SDxprDp8eax5rHmsaaxIPEg8SD4bmv4bq94bq94bq9IMSDxIPEg%2BG5veG6veG6veG6vcWZxIPEg8SDxKPhur3hur3hur0gxIPEg8SD4LiB4Li14LmJxowgxbXDtsO2w7bguIHguLXguYkn4bmvIMOf4bq94bq94bq9IMOsw6zDrOC4geC4teC5icOnxprHmseax5rGjOG6veG6veG6vcaMIMOsw6zDrOC4geC4teC5iSDDvcO9w73DtsO2w7bHmseax5rFmSDFmeG6veG6veG6veG5vcOsw6zDrOG6veG6veG6vcW1XSIsIkN1cnJlbmN5IjoiW8OHx5rHmseaxZnFmeG6veG6veG6veC4geC4teC5icOnw73DvcO9XSIsIkFtb3VudCI6IlvhuqxtzILDtsO2w7bHmseax5rguIHguLXguYnhua9dIiwiZXhjbC4gc3VwZXIiOiJb4bq94bq94bq96q2Vw6fGmi4gxaHHmseax5rGpeG6veG6veG6vcWZXSJ9!"),
        () => import(
          /* webpackChunkName: "en-PSEUDO-translations" */
          "./translations.json-en-PSEUDO-virtual.json!=!../../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJVbmRlciB7c2FsYXJ5VGV4dH0iOiJbx5nguIHguLXguYnGjOG6veG6veG6veG6vcWZIHtzYWxhcnlUZXh0fV0iLCJNb3JlIHRoYW4ge3NhbGFyeVRleHR9IjoiW%2BG5gsO2w7bDtsWZ4bq94bq94bq9IOG5r%2BG4qcSDxIPEg%2BC4geC4teC5iSB7c2FsYXJ5VGV4dH1dIiwiSSBhbHNvIHJlY2VpdmUgYm9udXNlcyBvciBjb21taXNzaW9uIjoiW8OPIMSDxIPEg8aaxaHDtsO2w7YgxZnhur3hur3hur3Dp%2BG6veG6veG6vcOsw6zDrOG5veG6veG6veG6vSDDn8O2w7bDtuC4geC4teC5iceax5rHmsWh4bq94bq94bq9xaEgw7bDtsO2xZkgw6fDtsO2w7ZtzIJtzILDrMOsw6zFocWhw6zDrMOsw7bDtsO24LiB4Li14LmJXSIsIldoYXQgaXMgeW91ciBzYWxhcnk%2FIjoiW8W04bipxIPEg%2BG5ryDDrMOsxaEgw73DvcO2w7bHmseaxZkgxaHEg8SDxprEg8SDxZnDvcO9P10iLCJVc2VkIHRvIGNhbGN1bGF0ZSBhdmVyYWdlIGFuZCB3b24ndCBiZSBpbmNsdWRlZCBpbiB5b3VyIHJldmlldyI6IlvHmcWh4bq94bq94bq9xowg4bmvw7bDtsO2IMOnxIPEg8SDxprDp8eax5rHmsaaxIPEg8SD4bmv4bq94bq94bq9IMSDxIPEg%2BG5veG6veG6veG6vcWZxIPEg8SDxKPhur3hur3hur0gxIPEg8SD4LiB4Li14LmJxowgxbXDtsO2w7bguIHguLXguYkn4bmvIMOf4bq94bq94bq9IMOsw6zDrOC4geC4teC5icOnxprHmseax5rGjOG6veG6veG6vcaMIMOsw6zDrOC4geC4teC5iSDDvcO9w73DtsO2w7bHmseax5rFmSDFmeG6veG6veG6veG5vcOsw6zDrOG6veG6veG6vcW1XSIsIkN1cnJlbmN5IjoiW8OHx5rHmseaxZnFmeG6veG6veG6veC4geC4teC5icOnw73DvcO9XSIsIkFtb3VudCI6IlvhuqxtzILDtsO2w7bHmseax5rguIHguLXguYnhua9dIiwiZXhjbC4gc3VwZXIiOiJb4bq94bq94bq96q2Vw6fGmi4gxaHHmseax5rGpeG6veG6veG6vcWZXSJ9!"
        )
      )
      });
  
      export { translations as default };
    