import type {
  CompanyProfile as CompanyProfileFromGraph,
  GroupedCompanies,
  Reviews as ReviewsFromGraph,
  ReviewsRatingSummary,
} from '@seek/ca-graphql-schema/types';
import {
  type CompanyProfile,
  type CompanyProfileRatingBreakdown,
  DEFAULT_LOGO_URL,
  type Jobs,
  type Reviews,
} from '@seek/libs-shared';
import type { Country } from '@seek/melways-sites';

import type { CompaniesListModel } from '../../../companyProfiles/models/companiesListModel';
import { getMelwaysZoneByCountryCode } from '../../utils/zoneCountryCodeMapping';
import type {
  LegacyCompanyProfile,
  LegacySearchCompaniesResult,
} from '../legacyCompanyProfileService/types';

import type {
  SearchCompaniesResult,
  SearchCompanyProfileApiResult,
  SearchCompanyProfileResult,
} from './types';

/**
 * This function returns no reviews data
 */
export const mapCompanyProfileModelFromDraft = (
  legacyCompanyProfile: LegacyCompanyProfile,
  companyReviewRatingSummary?: ReviewsRatingSummary,
): CompanyProfile => ({
  id: legacyCompanyProfile.company_id,
  name: legacyCompanyProfile.company_name,
  overview: {
    website: legacyCompanyProfile.website_url
      ? {
          url: legacyCompanyProfile.website_url,
          displayName: legacyCompanyProfile.company_name,
        }
      : undefined,
    industry: legacyCompanyProfile.industry || undefined,
    companySize: legacyCompanyProfile.number_of_employees_range,
    primaryLocation: legacyCompanyProfile.address || undefined,
    specialities: legacyCompanyProfile.specialities || undefined,
    description: legacyCompanyProfile.overview || undefined,
  },
  foreignCompanyId: legacyCompanyProfile.foreign_company_id,
  zone: getMelwaysZoneByCountryCode(
    legacyCompanyProfile.country_iso.toLocaleUpperCase() as Uppercase<Country>,
  ),
  branding: {
    logoImageUrl: legacyCompanyProfile.assets?.logo?.url || DEFAULT_LOGO_URL,
    coverImageUrl:
      legacyCompanyProfile.assets?.cover?.strategies.jdp_cover || undefined,
  },
  rating:
    companyReviewRatingSummary?.company?.rating &&
    companyReviewRatingSummary.company.count
      ? {
          average: companyReviewRatingSummary.company.rating,
          count: companyReviewRatingSummary.company.count,
        }
      : undefined,
  overallRating:
    companyReviewRatingSummary?.company?.rating &&
    companyReviewRatingSummary.company.count
      ? {
          average: companyReviewRatingSummary.company.rating,
          count: companyReviewRatingSummary.company.count,
        }
      : undefined,
  awards: legacyCompanyProfile.awards?.map((award) => ({
    imageUrl: award.link,
    name: award.name,
    year: award.year,
  })),
  featured: legacyCompanyProfile.featured?.text
    ? {
        description: legacyCompanyProfile.featured?.text,
        imageUrl: !legacyCompanyProfile.featured?.link_is_video
          ? legacyCompanyProfile.featured?.link
          : undefined,
        title: legacyCompanyProfile.featured?.sub_heading,
        videoUrl: legacyCompanyProfile.featured?.link_is_video
          ? legacyCompanyProfile.featured?.link
          : undefined,
      }
    : undefined,
  missionStatement: legacyCompanyProfile.mission_statement
    ? {
        description: legacyCompanyProfile.mission_statement,
      }
    : undefined,
  customStatement: legacyCompanyProfile.custom_statement
    ? {
        title: legacyCompanyProfile.custom_statement?.title || '',
        description: legacyCompanyProfile.custom_statement?.text || [],
      }
    : undefined,
  gallery: {
    name: legacyCompanyProfile.company_name,
    photos: legacyCompanyProfile?.gallery?.photo_video_gallery?.photos ?? [],
  },
  cultureAndValues: legacyCompanyProfile.culture_values
    ? {
        title: legacyCompanyProfile.culture_values?.heading,
        description: legacyCompanyProfile.culture_values?.text,
        imageUrl: legacyCompanyProfile.culture_values?.photo,
        values: legacyCompanyProfile.culture_values?.values?.map((value) => ({
          title: value.title,
          description: value.text,
        })),
      }
    : undefined,
  perksAndBenefits: legacyCompanyProfile.perks_benefits?.map((value) => ({
    title: value.title,
    description: value.text,
  })),
  salaryRating:
    (companyReviewRatingSummary?.salaryGenerous?.percentage ?? 0) +
    (companyReviewRatingSummary?.salaryFair?.percentage ?? 0),
  employeeRecommendationRating:
    companyReviewRatingSummary?.companyRecommended?.percentage ?? 0,
  companyReviewSummaryBreakdown: [
    {
      rating: companyReviewRatingSummary?.perksAndBenefits?.rating ?? 0,
      description: 'Benefits & perks',
    },
    {
      rating: companyReviewRatingSummary?.careerOpportunity?.rating ?? 0,
      description: 'Career development',
    },
    {
      rating: companyReviewRatingSummary?.workLifeBalance?.rating ?? 0,
      description: 'Work/Life balance',
    },
    {
      rating: companyReviewRatingSummary?.workEnvironment?.rating ?? 0,
      description: 'Working environment',
    },
    {
      rating: companyReviewRatingSummary?.executiveManagement?.rating ?? 0,
      description: 'Management',
    },
    {
      rating: companyReviewRatingSummary?.diversity?.rating ?? 0,
      description: 'Diversity & equal opportunity',
    },
  ],
  ...(companyReviewRatingSummary
    ? {
        ratingBreakdown: mapRatingSummaryBreakdown(companyReviewRatingSummary),
      }
    : {}),
});

export const mapCompanyProfileModel = ({
  companyProfileFromGraph,
  companyReviewRatingSummary,
  legacyCompanyProfile,
}: {
  companyProfileFromGraph: CompanyProfileFromGraph;
  legacyCompanyProfile: LegacyCompanyProfile;
  companyReviewRatingSummary?: ReviewsRatingSummary;
}): CompanyProfile => ({
  id: companyProfileFromGraph.id,
  name: companyProfileFromGraph.name,
  zone: getMelwaysZoneByCountryCode(
    legacyCompanyProfile.country_iso.toLocaleUpperCase() as Uppercase<Country>,
  ),
  overview: {
    website: companyProfileFromGraph.overview?.website?.url
      ? {
          url: companyProfileFromGraph.overview?.website?.url,
          displayName:
            companyProfileFromGraph.overview?.website?.displayName ??
            companyProfileFromGraph.name,
        }
      : undefined,
    industry: companyProfileFromGraph.overview?.industry || undefined,
    companySize: companyProfileFromGraph.overview?.size?.description,
    primaryLocation:
      companyProfileFromGraph.overview?.primaryLocation || undefined,
    specialities: companyProfileFromGraph.overview?.specialities?.split(','),
    description:
      companyProfileFromGraph.overview?.description?.paragraphs || undefined,
  },
  foreignCompanyId: legacyCompanyProfile.foreign_company_id,
  branding: {
    logoImageUrl: companyProfileFromGraph.branding?.logo || DEFAULT_LOGO_URL,
    coverImageUrl: companyProfileFromGraph.branding?.cover?.url || undefined,
  },
  rating:
    companyReviewRatingSummary?.company?.rating &&
    companyReviewRatingSummary.company.count
      ? {
          average: companyReviewRatingSummary.company.rating,
          count: companyReviewRatingSummary.company.count,
        }
      : undefined,
  overallRating:
    companyReviewRatingSummary?.company?.rating &&
    companyReviewRatingSummary.company.count
      ? {
          average: companyReviewRatingSummary.company.rating,
          count: companyReviewRatingSummary.company.count,
        }
      : undefined,
  awards: companyProfileFromGraph.awards,
  featured: companyProfileFromGraph.featured
    ? {
        description: companyProfileFromGraph.featured.description?.paragraphs,
        imageUrl: companyProfileFromGraph.featured.imageUrl || undefined,
        title: companyProfileFromGraph.featured.title,
        videoUrl: companyProfileFromGraph.featured.videoUrl || undefined,
      }
    : undefined,
  missionStatement: legacyCompanyProfile.mission_statement
    ? {
        description: legacyCompanyProfile.mission_statement,
      }
    : undefined,
  customStatement: legacyCompanyProfile.custom_statement
    ? {
        title: legacyCompanyProfile.custom_statement?.title || '',
        description: legacyCompanyProfile.custom_statement?.text || [],
      }
    : undefined,
  gallery: {
    name: companyProfileFromGraph.name,
    photos: legacyCompanyProfile?.gallery?.photo_video_gallery?.photos ?? [],
  },
  cultureAndValues: companyProfileFromGraph.cultureAndValues || undefined,
  perksAndBenefits: companyProfileFromGraph.perksAndBenefits || undefined,
  salaryRating: companyProfileFromGraph.reviewsSummary?.salaryRating.value,
  employeeRecommendationRating:
    companyProfileFromGraph.reviewsSummary?.employeeRecommendation.value,
  companyReviewSummaryBreakdown:
    companyProfileFromGraph.reviewsSummary?.ratings,
  ...(companyReviewRatingSummary
    ? {
        ratingBreakdown: mapRatingSummaryBreakdown(companyReviewRatingSummary),
      }
    : {}),
});

export const mapReviewsModel = (
  companyProfile: CompanyProfileFromGraph,
  companyReviews: ReviewsFromGraph,
): Reviews => ({
  name: companyProfile.name,
  companyId: companyProfile.id,
  rating: companyProfile.reviewsSummary?.overallRating
    ? {
        average: companyProfile.reviewsSummary.overallRating.value,
        count:
          companyProfile.reviewsSummary.overallRating.numberOfReviews.value,
      }
    : undefined,
  reviews: companyReviews?.data?.map((r) => ({
    title: r.title || undefined,
    createdAt: r.createdAt.shortAbsoluteLabel,
    pros: r.pros,
    cons: r.cons,
    overallRating: r.overallRating,
    id: r.id,
    companyId: r.companyId,
    jobTitle: r.jobTitle || undefined,
    employmentStatus: r.employmentStatus || undefined,
    workLocation: r.workLocation || undefined,
    salarySummary: r.salarySummary,
    upvotes: r.upvotes || undefined,
    recommended: r.recommended,
    ratings: r.ratings,
    reply: r.reply
      ? {
          companyLogoUrl: companyProfile.branding?.logo || undefined,
          createdAt: r.reply.createdAt.dateTime,
          from: r.reply.from,
          text: r.reply.text,
          user: {
            userId: r.reply.userId,
          },
        }
      : undefined,
  })),
  paging: {
    page: companyReviews?.paging?.page,
    perPage: companyReviews?.paging?.perPage,
    total: companyReviews?.paging?.total,
    totalPages: companyReviews?.paging?.totalPages,
  },
});

const mapRatingSummaryBreakdown = (
  model: ReviewsRatingSummary,
): CompanyProfileRatingBreakdown => {
  const companyRating1StarCount = model?.companyRating1Star?.count ?? 0;
  const companyRating2StarCount = model?.companyRating2Star?.count ?? 0;
  const companyRating3StarCount = model?.companyRating3Star?.count ?? 0;
  const companyRating4StarCount = model?.companyRating4Star?.count ?? 0;
  const companyRating5StarCount = model?.companyRating5Star?.count ?? 0;

  const totalRatingCount =
    companyRating1StarCount +
    companyRating2StarCount +
    companyRating3StarCount +
    companyRating4StarCount +
    companyRating5StarCount;

  return {
    companyRating1StarCount,
    companyRating1StarPercentage:
      (companyRating1StarCount / totalRatingCount) * 100,
    companyRating2StarCount,
    companyRating2StarPercentage:
      (companyRating2StarCount / totalRatingCount) * 100,
    companyRating3StarCount,
    companyRating3StarPercentage:
      (companyRating3StarCount / totalRatingCount) * 100,
    companyRating4StarCount,
    companyRating4StarPercentage:
      (companyRating4StarCount / totalRatingCount) * 100,
    companyRating5StarCount,
    companyRating5StarPercentage:
      (companyRating5StarCount / totalRatingCount) * 100,
  };
};

// Legacy Company Profile Service does the mapping for now
export const mapJobsModel = (jobs: Jobs): Jobs => jobs;

export const mapSearchCompanyModel = (
  companySearchResults: LegacySearchCompaniesResult,
): SearchCompaniesResult => ({
  companies: companySearchResults?.data?.map((company) => ({
    logoUrl: company.logo_url,
    name: company.company_name,
    slug: company.company_name_slug,
    overallRating: {
      average: company.average_company_rating,
      count: company.reviews_count,
    },
  })),
  totalCompanies: companySearchResults.paging.total,
});

export const mapCompanyGroupsToFlatCompanies = (
  groupedCompanies: GroupedCompanies[],
): CompaniesListModel =>
  groupedCompanies.flatMap((group) =>
    group.companies.map((company) => ({
      name: company.name,
      slug: company.slug,
      id: company.id,
    })),
  );

export const mapSearchCompanyApiResultToSearchCompanyResult = (
  searchCompanyProfileApiResult: SearchCompanyProfileApiResult,
): SearchCompanyProfileResult =>
  searchCompanyProfileApiResult.map((companyProfile) => ({
    ...companyProfile,
    data: {
      ...companyProfile.data,
      branding: {
        brandId: companyProfile.data.branding?.brandId,
        coverImageUrl: companyProfile.data.branding?.cover,
        logoImageUrl: companyProfile.data.branding?.logo,
      },
    },
  }));
