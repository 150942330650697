import { Helmet } from 'react-helmet';
import type { MetaData } from './meta';
import type { Locale } from '@seek/melways-sites';

export const MetaTag = ({
  title,
  description,
  companyProfileImage,
  shouldDisableIndexing,
  canonicalUrl,
  alternateUrls,
}: MetaData) => {
  const alternateUrlLinks: { locale: Locale | 'x-default'; url: string }[] = [];

  if (alternateUrls) {
    alternateUrlLinks.push(...alternateUrls);
    alternateUrlLinks.push({
      locale: 'x-default',
      url:
        alternateUrls?.find((item) => item.locale.startsWith('en'))?.url || '',
    });
  }

  return (
    <Helmet>
      <title>{title}</title>
      <meta name="description" content={description} />
      <meta property="og:title" content={title} />
      <meta property="og:description" content={description} />
      {companyProfileImage && (
        <meta property="og:image" content={companyProfileImage} />
      )}
      <meta name="twitter:title" content={title} />
      <meta name="twitter:description" content={description} />
      {shouldDisableIndexing && (
        <meta name="robots" content="noindex, noarchive" />
      )}
      {canonicalUrl && <link rel="canonical" href={canonicalUrl} />}

      {alternateUrlLinks &&
        alternateUrlLinks.map((item, index) => (
          <link
            rel="alternate"
            key={index}
            hrefLang={item.locale}
            href={item.url}
          />
        ))}
    </Helmet>
  );
};
