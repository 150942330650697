import { type PropsWithChildren, createContext, useContext } from 'react';

import type { Services } from '../services';

type ServiceContextProps =
  | {
      services: Services;
    }
  | undefined;

type ServiceProviderProps = PropsWithChildren<ServiceContextProps>;

const ServiceProviderContext = createContext<ServiceContextProps>(undefined);

export const ServiceProvider = ({
  services,
  children,
}: ServiceProviderProps) => (
  <ServiceProviderContext.Provider value={{ services }}>
    {children}
  </ServiceProviderContext.Provider>
);

export const useService = () => {
  const context = useContext(ServiceProviderContext);

  if (context === undefined) {
    throw new Error('useService must be used within a ServiceProvider');
  }

  return { ...context.services };
};
