import { useEffect, useRef, useState } from 'react';
import { useTranslations } from '@vocab/react';
import { useParams } from 'react-router';
import { Box, Pagination, Stack, Text } from 'braid-design-system';

import {
  type Reviews,
  Section,
  SHOW_REVIEWS_LIST_MINIMUM_REVIEWS,
  type SortOrder,
  useConfig,
} from '@seek/libs-shared';

import type { FlagReviewPayload } from '../../../../';
import {
  ReviewsEmptyState,
  ReviewsEmptyStateType,
} from '../reviewsEmptyState/ReviewsEmptyState';
import type { AppConfig } from '../../../../shared/config';
import { getLocationId } from '../../../../shared/utils/getLocationId';
import { getLocationContextualName } from '../../../../shared/utils/getLocationContextualName';
import { ReviewsList } from '../reviewsList/ReviewsList';
import translations from './.vocab';
import * as styles from './ReviewsSection.css';

interface Props {
  flagReview: (
    companyId: string,
    reviewId: string,
    payload: FlagReviewPayload,
  ) => Promise<boolean>;
  getReviews: ({
    companyId,
    page,
    sortOrder,
    perPage,
    locationId,
  }: {
    companyId: string;
    page: number;
    sortOrder: SortOrder;
    locationId?: number;
    perPage?: number;
  }) => Promise<Reviews>;
  mode: 'view' | 'edit';
  model: Reviews;
  trackRegisterClicked: () => void;
  trackReviewsPaginationClicked: ({
    pageNumber,
  }: {
    pageNumber: number;
  }) => void;
  trackSignInClicked: () => void;
  trackSortOrderChanged: ({ sortOrder }: { sortOrder: SortOrder }) => void;
  trackWriteAReviewClicked: () => void;
  upvoteReview: ({
    reviewId,
    companyId,
  }: {
    companyId: string;
    reviewId: string;
  }) => Promise<void>;
}

export function ReviewsSection({
  mode,
  model,
  getReviews,
  upvoteReview,
  flagReview,
  trackWriteAReviewClicked,
  trackReviewsPaginationClicked,
  trackSortOrderChanged,
  trackSignInClicked,
  trackRegisterClicked,
}: Props) {
  const { t } = useTranslations(translations);
  const config = useConfig<AppConfig>();
  const urlParams = useParams();
  const { brand } = config;
  const [currentPage, setCurrentPage] = useState(1);
  const [sortOrder, setSortOrder] = useState<SortOrder>('most recent');
  const [reviewsModel, setReviewsModel] = useState<Reviews>(model);
  const scrollRef = useRef<HTMLInputElement>(null);
  const [showReviewsList, setShowReviewsList] = useState(
    Boolean(Number(model?.paging?.total) >= SHOW_REVIEWS_LIST_MINIMUM_REVIEWS),
  );
  const REVIEWS_PER_PAGE = 30;

  const scrollToRef = () =>
    scrollRef.current?.scrollIntoView({ behavior: 'smooth' });

  const totalPages = reviewsModel?.paging?.totalPages || 1;
  const reviewsCount = reviewsModel?.paging?.total || 0;

  const locationId = getLocationId({
    locationSlug: urlParams.locationSlug,
    reviewLocation: config.reviewLocation,
  });
  const locationContextualName = getLocationContextualName({
    locationSlug: urlParams.locationSlug,
    config,
  });

  const [reviewsLoaded, setReviewsLoaded] = useState(false);

  useEffect(() => {
    async function loadReviews() {
      try {
        if (model.companyId) {
          const reviews = await getReviews({
            companyId: model.companyId,
            page: currentPage,
            sortOrder,
            perPage: REVIEWS_PER_PAGE,
            locationId,
          });

          setReviewsModel({ ...reviews, upvotedIds: model.upvotedIds });
          setReviewsLoaded(true);
        }
      } catch {
        setReviewsModel(model);
      }
    }
    loadReviews();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage, getReviews, model, sortOrder]);

  const handleSortOrderChanged = (value: SortOrder) => {
    setSortOrder(value);
    trackSortOrderChanged({ sortOrder });
    setCurrentPage(1);
  };

  useEffect(() => {
    if (reviewsCount >= SHOW_REVIEWS_LIST_MINIMUM_REVIEWS) {
      setShowReviewsList(true);
    }
  }, [reviewsCount]);

  const onPaginationLinkClick =
    (pageNumber: number) =>
    (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
      e.preventDefault();
      trackReviewsPaginationClicked({ pageNumber });
      setCurrentPage(pageNumber);
      setTimeout(scrollToRef, 300);
    };

  if (!showReviewsList)
    return (
      <Box paddingY="xxlarge">
        <ReviewsEmptyState
          companyName={model.name}
          type={ReviewsEmptyStateType.NO_LIST}
          trackWriteAReviewClicked={trackWriteAReviewClicked}
        />
      </Box>
    );

  return (
    <Section
      mode={mode}
      paddingBottom="none"
      heading={
        locationContextualName
          ? t('<Box>{location_contextual_name}</Box> reviews', {
              Box: (children) => (
                <Box
                  component="span"
                  className={styles.locationBasedReviewsHeadingSection}
                  aria-hidden
                >
                  {children}
                </Box>
              ),
              location_contextual_name: locationContextualName,
            })
          : t('Reviews')
      }
    >
      <Box ref={scrollRef}>
        {showReviewsList && (
          <Stack space="xlarge">
            <ReviewsList
              model={reviewsModel}
              upvoteReview={upvoteReview}
              flagReview={flagReview}
              sortOrder={sortOrder}
              onSortOrderChange={handleSortOrderChanged}
              reviewsLoaded={reviewsLoaded}
              trackSignInClicked={trackSignInClicked}
              trackRegisterClicked={trackRegisterClicked}
              locationContextualName={locationContextualName}
            />
            {totalPages > 1 && reviewsCount > REVIEWS_PER_PAGE ? (
              <Pagination
                page={currentPage}
                total={totalPages}
                linkProps={({ page }) => ({
                  href: `#${page}`,
                  onClick: onPaginationLinkClick(page),
                })}
                label={t('Reviews List Pagination')}
                nextLabel={t('Next')}
                previousLabel={t('Previous')}
              />
            ) : null}

            <Text tone="secondary" size="small">
              {t('Reviews disclaimer', {
                brand:
                  brand === 'seek'
                    ? 'SEEK'
                    : brand.charAt(0).toUpperCase() + brand.slice(1),
              })}
            </Text>
          </Stack>
        )}
      </Box>
    </Section>
  );
}
