/* istanbul ignore file */
// this is a mock file for testing

import type { AppConfig } from '../../config';

import type { SitemapService } from './sitemapService';

const sitemapContentMock = `
    <sitemapindex xmlns="http://www.sitemaps.org/schemas/sitemap/0.9">
        <sitemap>
            <loc>https://www.seek.com.au/companies/sitemap-seek-au-en-browse.xml</loc>
            <lastmod>2024-01-05T01:15:10.160Z</lastmod>
        </sitemap>
        <sitemap>
            <loc>https://www.seek.com.au/companies/sitemap-seek-au-en-1.xml</loc>
            <lastmod>2024-01-05T01:15:10.160Z</lastmod>
        </sitemap>
    </sitemapindex>
`;

export const createSitemapServiceMock = (_props: {
  config: AppConfig;
}): SitemapService => {
  const getSitemapFileContent = async (_sitemapFileName: string) => ({
    content: sitemapContentMock,
    status: 200,
  });

  return { getSitemapFileContent };
};
