import { useState, useRef, useEffect } from 'react';
import { Box, Tiles, Pagination, Stack } from 'braid-design-system';
import { useTranslations } from '@vocab/react';

import { JobCard } from './JobCard';
import type { PaginationType } from '../../../analytics/types';
import { Section, type Jobs } from '../../../';
import translations from './.vocab';

interface Props {
  getPaginatedJobs: (companyId: string, page?: number) => Promise<Jobs>;
  mode: 'view' | 'edit';
  model: Jobs;
  trackJobsPaginationClicked: ({
    searchTotalCount,
    pageNumber,
  }: {
    pageNumber: number;
    searchTotalCount?: number | undefined;
  }) => void;
}

export function JobsSection({
  mode,
  model,
  getPaginatedJobs,
  trackJobsPaginationClicked,
}: Props) {
  const [currentPage, setCurrentPage] = useState(1);
  const scrollRef = useRef<HTMLInputElement>(null);
  const [jobs, setJobs] = useState(model?.jobs || []);
  const [totalJobs, setTotalJobs] = useState(0);
  const { t } = useTranslations(translations);

  const scrollToRef = () =>
    scrollRef.current?.scrollIntoView({ behavior: 'smooth' });

  useEffect(() => {
    async function loadJobs() {
      try {
        const companyId = model?.companyId || '';

        if (companyId) {
          const { jobs: result, totalJobs: totalJobCount } =
            await getPaginatedJobs(`${companyId}`, currentPage);
          setJobs(result);
          setTotalJobs(totalJobCount);
        } else {
          setJobs(model?.jobs);
        }
      } catch {
        setJobs(model?.jobs);
      }
    }
    loadJobs();
  }, [currentPage, getPaginatedJobs, model, model?.jobs]);

  const totalPageCount = Math.ceil(totalJobs / model?.jobs.length || 1);

  const onPaginationLinkClick =
    (_: PaginationType, pageNumber: number) =>
    (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
      e.preventDefault();
      trackJobsPaginationClicked({
        pageNumber,
        searchTotalCount: totalJobs,
      });
      setCurrentPage(pageNumber);
      setTimeout(scrollToRef, 300);
    };

  return (
    <Section mode={mode} padding="none" paddingBottom="none">
      <Box paddingX={['none', 'none', 'large']} ref={scrollRef}>
        <Stack space="xlarge">
          <Tiles columns={[1, 2]} space="small">
            {jobs.map((job) => (
              <JobCard {...job} key={job.id} />
            ))}
          </Tiles>
          {totalPageCount > 1 && (
            <Pagination
              page={currentPage}
              total={totalPageCount}
              linkProps={({ page, type }) => ({
                href: `#`,
                onClick: onPaginationLinkClick(type, page),
              })}
              label={t('Jobs pagination')}
              nextLabel={t('Next')}
              previousLabel={t('Previous')}
            />
          )}
        </Stack>
      </Box>
    </Section>
  );
}
