import { Stack, Secondary } from 'braid-design-system';
import { Section } from '../../common/section/Section';
import { UpdateSuccessAlert, type Statement } from '../../../';
import { useTranslations } from '@vocab/react';
import translations from './.vocab';
import { ReadMore } from '../../common/ReadMore/ReadMore';

interface Props {
  mode: 'view' | 'edit';
  model: Statement;
  isMissionStatement?: boolean;
  onEditClick?: () => void;
  onHideUpdateSuccess?: () => void;
  showUpdateSuccess?: boolean;
}

export function StatementSection({
  mode,
  model,
  isMissionStatement = false,
  showUpdateSuccess,
  onEditClick,
  onHideUpdateSuccess,
}: Props) {
  const { t } = useTranslations(translations);
  const { title = '', description = [] } = model;

  let heading;

  if (isMissionStatement) {
    heading = t('Our mission statement');
  } else if (title.length) {
    heading = title;
  } else {
    heading = t('Custom statement');
  }

  return (
    <Section
      mode={mode}
      heading={heading}
      onEditClick={onEditClick}
      dataEmpty={
        description.length === 0 || (!isMissionStatement && title.length === 0)
      }
      addDataHint={
        isMissionStatement
          ? t('Highlight the overall purpose of your company.')
          : t('Highlight what sets your company apart from others.')
      }
    >
      <Stack space="gutter">
        {showUpdateSuccess && (
          <UpdateSuccessAlert
            onClose={() => {
              if (onHideUpdateSuccess) onHideUpdateSuccess();
            }}
            text={
              isMissionStatement
                ? t('Mission statement updated')
                : t('Custom statement updated')
            }
          />
        )}
        <ReadMore
          moreLabel={t('Show more')}
          lessLabel={t('Show less')}
          linesToPreview={3}
        >
          <Stack space="gutter">
            {description.map((paragraph, index) => (
              <Secondary key={index}>{paragraph}</Secondary>
            ))}
          </Stack>
        </ReadMore>
      </Stack>
    </Section>
  );
}
