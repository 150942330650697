import type { ConfirmedRoles, RoleService, RoleTitle } from './roleService';

export const createRoleServiceMock = (): RoleService => {
  const getRoleTitles = async (
    query: string,
    _sessionId: string,
  ): Promise<RoleTitle[]> => {
    const title = [
      {
        id: '1',
        normalisedText: 'Software Engineer',
      },
      {
        id: '2',
        normalisedText: 'Software Developer',
      },
      {
        id: '3',
        normalisedText: 'Software Architect',
      },
      {
        id: '4',
        normalisedText: 'Teacher',
      },
    ];

    const sortedTitle = title.sort((a, b) => {
      const aMatches = a.normalisedText.toLowerCase() === query.toLowerCase();
      const bMatches = b.normalisedText.toLowerCase() === query.toLowerCase();

      if (aMatches && !bMatches) return -1;
      if (!aMatches && bMatches) return 1;
      return 0;
    });

    return sortedTitle;
  };

  const getConfirmedRoles = async (): Promise<ConfirmedRoles[]> => [
      {
        id: '3eb25d06-0332-41a6-b2fe-ddf636c5bdd8',
        title: {
          text: 'job 308',
        },
        company: {
          text: 'TESA Mining',
        },
        from: {
          year: 2024,
          month: 1,
        },
      },
      {
        id: '40c80147-0c2b-4d79-a54b-2eb0bd6c24bc',
        title: {
          text: 'job 401',
        },
        company: {
          text: 'TESA Mining',
        },
        from: {
          year: 2024,
          month: 1,
        },
      },
      {
        id: '4fb77834-bc9a-464f-8075-37cf219cde93',
        title: {
          text: 'Tester',
        },
        company: {
          text: 'SEEK',
        },
        from: {
          year: 2024,
          month: 1,
        },
      },
    ];

  const updateRole = async (_role: ConfirmedRoles) => true;

  return { getRoleTitles, getConfirmedRoles, updateRole };
};
