import type { ApolloClient, NormalizedCacheObject } from '@apollo/client';
import type {
  PostCompanyReviewInput,
  PostCompanyReviewPayload,
} from '@seek/ca-graphql-schema/types';

import { POST_COMPANY_REVIEW_MUTATE } from './graphqlQueries';

export type CompanyReviewService = ReturnType<
  typeof createCompanyReviewService
>;

export const createCompanyReviewService = ({
  client,
}: {
  client: ApolloClient<NormalizedCacheObject>;
}) => {
  const submitReview = async (
    input: PostCompanyReviewInput,
  ): Promise<PostCompanyReviewPayload | undefined> => {
    const response = await client.mutate<{
      postCompanyReview: PostCompanyReviewPayload;
    }>({
      mutation: POST_COMPANY_REVIEW_MUTATE,
      variables: {
        input,
      },
    });

    return response?.data?.postCompanyReview;
  };

  return {
    submitReview,
  };
};
