import type {
  MkLocationSuggestion,
  CompanyReviewLocation,
} from '@seek/ca-graphql-schema/types';

import type { LocationService } from './locationService';

export const createLocationServiceMock = (): LocationService => {
  const getLocations = async (
    query: string,
    _sessionId: string,
  ): Promise<MkLocationSuggestion[]> => {
    const locations = [
      {
        id: 4,
        text: 'Bendigo',
        countryCode: 'AU',
      },
      {
        id: 1,
        text: 'Sydney',
        countryCode: 'AU',
      },
      {
        id: 2,
        text: 'Melbourne',
        countryCode: 'AU',
      },
      {
        id: 3,
        text: 'Brisbane',
        countryCode: 'AU',
      },
    ];
    const sortedLocations = locations.sort((a, b) => {
      const aMatches = a.text.toLowerCase() === query.toLowerCase();
      const bMatches = b.text.toLowerCase() === query.toLowerCase();

      if (aMatches && !bMatches) return -1;
      if (!aMatches && bMatches) return 1;
      return 0;
    });
    return sortedLocations as MkLocationSuggestion[];
  };

  const getCompanyReviewLocations = async (_variables: {
    companyId: string;
    sort?: boolean;
  }): Promise<CompanyReviewLocation[] | undefined> => {
    return Promise.resolve([
      {
        relativeUrl: '/companies/seek-3213/reviews/locations/All-Melbourne-VIC',
        contextualName: 'All Melbourne VIC',
      },
      {
        relativeUrl: '/companies/seek-3213/reviews/locations/All-Sydney-NSW',
        contextualName: 'All Sydney NSW',
      },
      {
        relativeUrl: '/companies/seek-3213/reviews/locations/All-Melbourne-VIC',
        contextualName: 'All Melbourne VIC 2',
      },
      {
        relativeUrl: '/companies/seek-3213/reviews/locations/All-Sydney-NSW',
        contextualName: 'All Sydney NSW 2',
      },
      {
        relativeUrl: '/companies/seek-3213/reviews/locations/All-Melbourne-VIC',
        contextualName: 'All Melbourne VIC 3',
      },
      {
        relativeUrl: '/companies/seek-3213/reviews/locations/All-Sydney-NSW',
        contextualName: 'All Sydney NSW 3',
      },
      {
        relativeUrl: '/companies/seek-3213/reviews/locations/All-Melbourne-VIC',
        contextualName: 'All Melbourne VIC 4',
      },
      {
        relativeUrl: '/companies/seek-3213/reviews/locations/All-Sydney-NSW',
        contextualName: 'All Sydney NSW 4',
      },
    ]);
  };

  return { getLocations, getCompanyReviewLocations };
};
