import { gql } from '@apollo/client';

export const LOCATION_SEARCH_QUERY = gql`
  query MKLocationSuggestions(
    $searchText: String!
    $session: String!
    $kind: [String!]!
    $zone: Zone!
    $languageCode: LanguageCodeIso
    $countries: [CountryCodeIso2!]
  ) {
    mkLocationSuggestions(
      searchText: $searchText
      session: $session
      kind: $kind
      zone: $zone
      languageCode: $languageCode
      countries: $countries
    ) {
      text
      id
      countryCode
    }
  }
`;

export const COMPANY_REVIEW_LOCATIONS_QUERY = gql`
  query CompanyReviewLocations(
    $companyDetailsId: CompanyId!
    $locale: Locale!
    $zone: Zone!
    $sort: Boolean
  ) {
    companyDetails(id: $companyDetailsId) {
      companyReviewLocations(locale: $locale, zone: $zone, sort: $sort) {
        contextualName
        relativeUrl
      }
    }
  }
`;
