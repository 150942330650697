import { useTranslations } from '@vocab/react';
import translations from './.vocab';

import type { QuestionProps } from '../../type';

import { SegmentedButtonSelector } from '@seek/branding-assets';

export const RecommendationQuestion = ({
  value,
  message,
  onChange,
}: QuestionProps<boolean>) => {
  const { t } = useTranslations(translations);

  return (
    <SegmentedButtonSelector<boolean>
      label={t('Would you recommend working here to a friend?')}
      value={value}
      options={[
        { value: true, text: t('Yes') },
        { value: false, text: t('No') },
      ]}
      onChange={onChange}
      message={message}
    />
  );
};
